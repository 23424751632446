import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { makeStyles, createStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useLongPress } from 'use-long-press';
import logo from '../../assets/logo.png';
import patientLibLogo from '../../assets/patientLibLogo.png';
import mediathequeLogo from '../../assets/mediathequeLogo.svg';
import backButton from '../../assets/backButton.png';
import homeButton from '../../assets/homeButton.png';
// eslint-disable-next-line import/no-cycle
import { UserContext } from '../../providers/UserProvider';
import { getImageKitUrlFrom } from '../../utilities/utils';
import { UserDataProp } from '../../models/userDataProp';

const useStyles = makeStyles(() =>
    createStyles({
        img: {
            marginTop: '10px',
            height: '27px',
            paddingBottom: '0%'
        },
        logoBack: {
            marginTop: '15px',
            marginLeft: '5%',
            float: 'left'
        },
        profileImage: {
            height: '56px',
            width: '56px',
            float: 'right',
            marginRight: '5%',
            marginTop: '5px',
            marginBottom: '5px',
            paddingBottom: '0%',
            objectFit: 'cover'
        },
        currentUserInitials: {
            display: 'flex',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            margin: '0'
        }
    })
);

const Logo = styled.img`
    height: 27px;
`;

const ProfileLogo = styled.img`
    height: 27px;
    border-radius: 50%;
`;

const LogoBack = styled.img`
    height: 40px;
    background-color: #fbfbfb;
    border: 1px solid #e3e6e8;
    padding: 12px;
    border-radius: 5px;
    cursor: pointer;
`;

const StimulinAppBar = styled(AppBar)({
    background: '#fff',
    height: '78px'
});

const AppToolBar = styled(Toolbar)({
    justifyContent: 'space-between'
});

const DefaultLogo = styled.div`
    border-radius: 50%;
    background: #efe7d7;
    height: 27px;
`;

interface CentralLogoProps {
    hasHomeButton?: boolean;
    hasBackButton?: boolean;
    hasCentralLogo?: boolean;
    hasPatientLibLogo?: boolean;
    hasMediathequeLogo?: boolean;
    hasProfilePhoto?: boolean;
    setSelectCard?: Function;
    isYoutubeLink?: Function;
}
export function CentralLogo({
    hasHomeButton,
    hasBackButton,
    hasCentralLogo,
    hasPatientLibLogo,
    hasMediathequeLogo,
    hasProfilePhoto,
    setSelectCard,
    isYoutubeLink
}: CentralLogoProps): JSX.Element {
    const history = useHistory();
    const classes = useStyles();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [initials, setInitials] = useState<string>('');

    useEffect(() => {
        const topOfPage = document.getElementById('top');
        if (topOfPage) {
            topOfPage.scrollIntoView();
        }
    }, []);

    useEffect(() => {
        if (user && user.firstName && user.lastName) {
            setInitials(
                user.firstName.charAt(0).toUpperCase() + user.lastName.charAt(0).toUpperCase()
            );
        }
    }, [user]);

    const backButtonAction = (): void => {
        if (setSelectCard) {
            setSelectCard(null);
        } else if (isYoutubeLink) {
            isYoutubeLink(false);
        } else {
            history.goBack();
        }
    };

    const bindLongPressHome = useLongPress(
        () => {
            history.push('/home');
        },
        { threshold: 1000 }
    );

    return (
        <Box id="top">
            <StimulinAppBar position="static" style={{ justifyContent: 'center' }}>
                <AppToolBar>
                    <Box>
                        {hasHomeButton && (
                            <IconButton
                                size="large"
                                edge="start"
                                color="secondary"
                                aria-label="menu"
                                sx={{ height: '54px' }}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...bindLongPressHome()}
                                onClick={(): void => {
                                    history.push('/home');
                                }}>
                                <LogoBack
                                    src={homeButton}
                                    alt="HomeButton"
                                    className={classes.logoBack}
                                    style={{ padding: '5px', marginBottom: '15px' }}
                                />
                            </IconButton>
                        )}
                        {hasBackButton && (
                            <IconButton
                                size="large"
                                edge="start"
                                color="secondary"
                                aria-label="menu"
                                onClick={(): void => backButtonAction()}>
                                <LogoBack src={backButton} alt="BackButton" />
                            </IconButton>
                        )}
                    </Box>
                    {hasCentralLogo && (
                        <Logo src={logo} alt="logo" style={{ borderRadius: '0%' }} />
                    )}
                    {hasPatientLibLogo && <Logo src={patientLibLogo} alt="LibLogo" />}
                    {hasMediathequeLogo && <Logo src={mediathequeLogo} alt="MediathequeLogo" />}
                    <Box>
                        {hasProfilePhoto && (
                            <IconButton
                                size="large"
                                edge="start"
                                color="secondary"
                                aria-label="menu"
                                onClick={(): void => history.push('/profile')}>
                                {user && !user.profilePhoto && (
                                    <DefaultLogo className={classes.profileImage}>
                                        <div>
                                            <Typography
                                                variant="subtitle1"
                                                sx={{ fontWeight: 'bold', color: '#374653' }}
                                                className={classes.currentUserInitials}>
                                                {initials}
                                            </Typography>
                                        </div>
                                    </DefaultLogo>
                                )}
                                {user &&
                                    user.profilePhoto &&
                                    typeof user.profilePhoto === 'string' && (
                                        <ProfileLogo
                                            src={getImageKitUrlFrom(user.profilePhoto)}
                                            alt="logo"
                                            className={classes.profileImage}
                                        />
                                    )}
                            </IconButton>
                        )}
                    </Box>
                </AppToolBar>
            </StimulinAppBar>
        </Box>
    );
}
