import { logEvent, setUserProperties } from 'firebase/analytics';
import { Roles } from '../models/Roles';
import { User } from '../models/User';
import { analytics } from './firebase';

const userParams = (user: User): void => {
    if (user.role === Roles.FAMILY) {
        setUserProperties(analytics, { role: 'family', community_code: user.familyCode });
    } else {
        setUserProperties(analytics, {
            role: 'therapist',
            community_code: user.establishmentCode
        });
    }
};

export const clickAnalytics = (locationClick: string, user: User): void => {
    userParams(user);
    logEvent(analytics, locationClick);
};

export const downloadData = (typeOfContent: string, user: User, size: number): void => {
    userParams(user);
    if (typeOfContent === 'new_video') {
        logEvent(analytics, typeOfContent, { video_size: size });
    } else {
        logEvent(analytics, typeOfContent, { picture_size: size });
    }
};

// export const viewPage = (location: string): void => {
//     logEvent(analytics, 'screen_view', {
//         firebase_screen: location,
//         firebase_screen_class: location
//     });
// };
