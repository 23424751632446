/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
import global from '../../global';
import { GRID } from '../../typings';
// eslint-disable-next-line import/no-useless-path-segments
import { copyGrid, getRandomIndex, identifySquare, solveGrid } from '../../utils';

/**
 * Removes numbers from a full grid to create a sudoku puzzle
 * @param grid : 9x9 sudoku grid
 * @param attempts : number of attempts to solve (higher means more difficult) - default is 5
 * @param difficultyLevel : difficulty level of the sudoku puzzle
 */
function removeNumbers(grid: GRID, attempts = 10): GRID {
    // while (attempts > 0) {
    // let row = getRandomIndex();
    // let col = getRandomIndex();

    // while (grid[row][col] === 0) {
    //     row = getRandomIndex();
    //     col = getRandomIndex();
    // }

    // const backup = grid[row][col];
    // grid[row][col] = 0;

    // // copy grid
    // const gridCopy = copyGrid(grid);
    // // set a global counter
    // global.counter = 0;

    // // attempt to solve the grid
    // solveGrid(gridCopy);

    // // if global counter is not 1
    // // grid[row][col] = backup
    // // decrement attempts
    // if (global.counter !== 1) {
    //     grid[row][col] = backup;
    //     // eslint-disable-next-line no-plusplus
    //     attempts--;
    // }
    // }
    const calc0percell = (rowNow: number, colNow: number, gridNow: GRID): number => {
        let nb = 0;
        const square = identifySquare({ col: colNow, grid: gridNow, row: rowNow });
        for (let i = 0; i < 3; i++) {
            for (let j = 0; j < 3; j++) {
                if (square[i][j] === 0) nb++;
            }
        }
        return nb;
    };

    // console.log('difficultyLevel', global.difficultyLevel);

    let nbmax = 0;
    let nbmaxpercell = 0;

    switch (global.difficultyLevel) {
        case 1:
            nbmax = 10;
            nbmaxpercell = 2;
            break;
        case 2:
            nbmax = 15;
            nbmaxpercell = 2;
            break;
        case 3:
            nbmax = 20;
            nbmaxpercell = 3;
            break;
        case 4:
            nbmax = 25;
            nbmaxpercell = 3;
            break;
        case 5:
            nbmax = 30;
            nbmaxpercell = 4;
            break;
        case 6:
            nbmax = 35;
            nbmaxpercell = 4;
            break;
        case 7:
            nbmax = 45;
            nbmaxpercell = 5;
            break;
        case 8:
            nbmax = 55;
            nbmaxpercell = 7;
            break;
        case 9:
            nbmax = 65;
            nbmaxpercell = 8;
            break;
        case 10:
            nbmax = 70;
            nbmaxpercell = 8;
            break;
        default:
            nbmax = 10;
            nbmaxpercell = 2;
            break;
    }

    for (let i = 0; i < nbmax; i++) {
        let row = getRandomIndex();
        let col = getRandomIndex();
        let nb = 1;
        while (grid[row][col] === 0 || nbmaxpercell <= calc0percell(row, col, grid)) {
            row = getRandomIndex();
            col = getRandomIndex();
            nb++;
        }
        grid[row][col] = 0;
    }

    return grid;
}

// eslint-disable-next-line import/no-default-export
export default removeNumbers;
