import React, { useEffect, useState, useContext } from 'react';
import { Box, Container, Typography, Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { makeStyles, createStyles } from '@mui/styles';
import { Timestamp } from 'firebase/firestore';
import { UserContext } from '../../providers/UserProvider';
import editIcon from '../../assets/editIcon.png';
import { CentralLogo } from '../../components/Headers/CentralLogo';
import { SelectComponent } from '../../components/SelectComponent';
import { DeactiveModal } from '../../components/DeactiveModal';
import { ChangePassword } from '../../components/ChangePassword';
import { logOut } from '../../services/firebaseAuth';
import { Therapist } from '../../models/Therapist';
import { getTherapistUser, saveTherapistProfile } from '../../services/cloudFirestore';
import { isPhoneNumberValidate } from '../../utilities/utils';
import { DatePickerCustom } from '../../components/DatePickerCustom';
import { UserDataProp } from '../../models/userDataProp';
import { Logo } from '../../components/FormComponents/Logo';
import { InputTextDisabled, InputTextNotDisabled } from '../../components/FormComponents/InputText';
import { InputLabel } from '../../components/FormComponents/InputLabel';
import { TitleFormPart } from '../../components/FormComponents/TitleFormPart';

const useStyles = makeStyles(() =>
    createStyles({
        selectLayout: {
            width: '100%',
            marginTop: '0px',
            color: '#004680 !important'
        },
        selectPlaceholder: {
            width: '100%',
            marginTop: '0px',
            color: 'rgba(172, 188, 199, 1)'
        },
        fontBlue: {
            '& .MuiOutlinedInput-input': {
                color: '#004680 !important'
            }
        },
        layout: {
            '@media (max-width: 598px)': {
                display: 'flex',
                flexDirection: 'column'
            },
            '@media (min-width: 599px)': {
                display: 'flex',
                flexDirection: 'row'
            }
        },
        formLayout: {
            '@media (max-width: 598px)': {
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                paddingTop: '0% !important'
            },
            '@media (min-width: 599px)': {
                width: '70%',
                margin: 'auto',
                display: 'flex'
            }
        },
        navLayout: {
            '@media (max-width: 598px)': {
                width: '100%',
                paddingBottom: '5%'
            },
            '@media (min-width: 599px)': {
                width: '30%',
                height: '100vh'
            }
        },
        logoutBtnTablet: {
            '@media (min-width: 599px)': {
                display: 'none'
            }
        },
        logoutBtnMobile: {
            '@media (max-width: 598px)': {
                display: 'none'
            }
        },
        groupBtnLayout: {
            '@media (max-width: 598px)': {
                display: 'flex',
                flexDirection: 'column'
            },
            '@media (min-width: 599px)': {
                display: 'flex',
                justifyContent: 'space-between',
                paddingTop: '2%'
            }
        }
    })
);

const RoleOfUser = styled(Typography)(() => ({
    width: '60%',
    margin: 'auto',
    backgroundColor: '#D5CDFF',
    border: '1px solid #7563CD',
    borderRadius: '3px',
    color: '#7563CD'
}));

const LogoutBtn = styled(Button)(() => ({
    margin: '2% 0% 30%',
    padding: '3% 0% 3% 2%',
    fontSize: '16px',
    lineHeight: '19px',
    border: 'none',
    fontWeight: 500,
    borderTop: '2px solid #e3e6e8',
    borderBottom: '2px solid #e3e6e8',
    borderRadius: '0px'
}));

const DeleteAndEditBtn = styled(Button)(() => ({
    marginTop: '5%',
    width: '45%',
    fontSize: '16px',
    lineHeight: '19px',
    borderRadius: '3px',
    '@media (max-width: 598px)': {
        width: '100%'
    }
}));

export function TherapistScreenSettings(): JSX.Element {
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const { t } = useTranslation();
    const classes = useStyles();
    const [deactive, setDeactive] = useState<boolean>(false);
    const [logout, setLogout] = useState<boolean>(false);
    const [isChangePassword, setIsChangePassword] = useState<boolean>(false);
    const [data, setData] = useState<Therapist>({
        firstName: undefined,
        lastName: undefined,
        emailId: undefined,
        dob: undefined,
        profilePhoto: undefined,
        fonction: undefined,
        establishmentName: undefined,
        establishmentCode: undefined,
        mobile: undefined,
        startDate: undefined,
        endDate: undefined,
        firstStepFormFields: [],
        secondStepFormFields: []
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [photo, setPhoto] = useState<string | undefined>();

    const fonctionOptionList = [
        t('establishmentDirector'),
        t('neuroPsychologist'),
        t('neurologist'),
        t('coordinatingDoctor'),
        t('geriatrician'),
        t('psychiatrist'),
        t('nurseHealthmanager'),
        t('careAssistantInGerontologyAMP'),
        t('cccupationalTherapist'),
        t('gerontologyFacilitator'),
        t('other')
    ];

    useEffect(() => {
        if (
            data.firstName ||
            data.lastName ||
            data.emailId ||
            data.dob ||
            data.fonction ||
            data.establishmentName ||
            (data.mobile && isPhoneNumberValidate(data.mobile))
        ) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [data]);

    // const deactivateAccount = async (): Promise<void> => {
    //     if (user && user.id) {
    //         await deactiveUserAccount(user.id);
    //         logOut(user);
    //     }
    // };

    useEffect(() => {
        const getTherapist = async (): Promise<void> => {
            if (user && user.id) {
                const therapist = await getTherapistUser(user.id);
                if (therapist) {
                    if (typeof therapist.profilePhoto === 'string') {
                        setPhoto(therapist.profilePhoto);
                    }
                    setData({
                        firstName: therapist.firstName,
                        lastName: therapist.lastName,
                        emailId: therapist.emailId,
                        dob: therapist.dob,
                        profilePhoto: therapist.profilePhoto,
                        fonction: therapist.fonction,
                        establishmentName: therapist.establishmentName,
                        establishmentCode: therapist.establishmentCode,
                        mobile: therapist.mobile,
                        startDate: therapist.startDate,
                        endDate: therapist.endDate,
                        firstStepFormFields: [],
                        secondStepFormFields: []
                    });
                }
            }
        };
        getTherapist();
    }, []);

    const handleChange = (
        state: string,
        value: string | number | undefined | Timestamp | null | File
    ): void => {
        setData({ ...data, [state]: value });
    };

    const saveDetails = async (): Promise<void> => {
        setLoading(true);
        if (user && user.id) {
            await saveTherapistProfile(user.id, data);
        }
        setLoading(false);
        window.location.reload();
    };

    const updateImage = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.files) {
            handleChange('profilePhoto', event.target.files[0]);
            setPhoto(URL.createObjectURL(event.target.files[0]));
        }
    };

    const inputComponent = (
        position: string,
        placeholder: string,
        dataToShow: string | null
    ): JSX.Element => {
        return (
            <InputTextNotDisabled
                side={position}
                placeholder={t(placeholder)}
                data={dataToShow}
                labelPresent
                change={(value: string): void => handleChange(placeholder, value)}
            />
        );
    };

    const drawer = (
        <>
            <div>
                <Logo
                    photo={photo}
                    firstName={user?.firstName ?? ''}
                    lastName={user?.lastName ?? ''}
                    updateImage={(event: React.ChangeEvent<HTMLInputElement>): void =>
                        updateImage(event)
                    }
                />
                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                    {user && user.firstName} {user && user.lastName}
                </Typography>
                <RoleOfUser variant="subtitle1">{t('therapist')}</RoleOfUser>
            </div>
            <div className={classes.logoutBtnMobile}>
                <LogoutBtn
                    fullWidth
                    variant="outlined"
                    size="large"
                    sx={{
                        textAlign: 'left'
                    }}
                    onClick={(): void => {
                        setLogout(true);
                    }}>
                    {t('logOut')}
                </LogoutBtn>
            </div>
        </>
    );

    const settingsScreen = (
        <div>
            <Box sx={{ position: 'absolute', zIndex: 2, width: '100%' }}>
                <CentralLogo hasHomeButton hasCentralLogo hasProfilePhoto />
            </Box>
            <Box flex={1} width="100%" margin="auto" className={classes.layout}>
                <Container
                    className={classes.navLayout}
                    sx={{
                        margin: '0%',
                        borderRight: '2px solid #e3e6e8',
                        backgroundColor: 'rgba(195, 204, 210, 0.18)',
                        paddingTop: '150px',
                        display: { xs: 'none', sm: 'flex', md: 'flex', lg: 'flex' },
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        paddingLeft: '0px !important',
                        paddingRight: '0px !important'
                    }}>
                    {drawer}
                </Container>
                <Box
                    px={2}
                    className={classes.formLayout}
                    sx={{
                        height: '100vh',
                        margin: '0%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        paddingTop: '150px',
                        flexDirection: 'column',
                        overflowY: 'auto',
                        paddingLeft: '0px !important',
                        paddingRight: '0px !important'
                    }}>
                    <Container
                        className={classes.navLayout}
                        sx={{
                            margin: '0%',
                            borderRight: '2px solid #e3e6e8',
                            backgroundColor: '#fff',
                            paddingTop: '150px',
                            display: { xs: 'flex', sm: 'none', md: 'none', lg: 'none' },
                            justifyContent: 'space-between',
                            flexDirection: 'column',
                            paddingLeft: '0px !important',
                            paddingRight: '0px !important'
                        }}>
                        {drawer}
                    </Container>
                    <Container>
                        <TitleFormPart text={t('profile')} />

                        <InputLabel position="left" label="firstName" />
                        <InputLabel position="right" label="name" />
                        {inputComponent('left', 'firstName', data.firstName ?? null)}
                        {inputComponent('right', 'lastName', data.lastName ?? null)}
                        <InputLabel position="left" label="dateOfBirth" />
                        <InputLabel position="right" label="function" />
                        <DatePickerCustom
                            sx={{ marginRight: '6%', width: '47%' }}
                            value={typeof data?.dob === 'object' ? data?.dob?.toDate() : null}
                            onDateChange={(date: Date): void =>
                                handleChange('dob', Timestamp.fromDate(date))
                            }
                            classReceived={data.dob !== null ? classes.fontBlue : ''}
                        />
                        <div style={{ display: 'inline-block', width: '47%' }}>
                            <SelectComponent
                                dropdownList={fonctionOptionList}
                                placeholder={t('function')}
                                onTextChange={(value: string): void =>
                                    handleChange('fonction', value)
                                }
                                value={data.fonction ?? null}
                                classCustom={classes.selectLayout}
                                placeholderStyling={classes.selectPlaceholder}
                            />
                        </div>
                        <InputLabel position="left" label="institutionName" />
                        <InputLabel position="right" label="mobile" />
                        <InputTextDisabled
                            placeholder={t('institutionName')}
                            data={data.establishmentName ?? null}
                            labelPresent
                            side="left"
                        />
                        {inputComponent('right', 'mobile', data.mobile ?? null)}
                        <div style={{ textAlign: 'left' }}>
                            <InputLabel position="left" label="email" />
                            <InputTextDisabled
                                placeholder={t('email')}
                                data={data.emailId ?? null}
                                labelPresent
                                side="left"
                            />
                        </div>

                        <TitleFormPart text={t('mySuscription')} />

                        <InputLabel position="left" label="startSuscription" />
                        <InputLabel position="right" label="endSuscription" />
                        <DatePickerCustom
                            disabled
                            sx={{ marginRight: '6%', width: '47%' }}
                            value={
                                typeof data?.startDate === 'object'
                                    ? data?.startDate?.toDate()
                                    : null
                            }
                            onDateChange={(date: Date): void => {}}
                            classReceived={data.dob !== null ? classes.fontBlue : ''}
                        />
                        <div style={{ display: 'inline-block', width: '47%' }}>
                            <DatePickerCustom
                                disabled
                                sx={{ marginRight: '6%', width: '100%' }}
                                value={
                                    typeof data?.endDate === 'object'
                                        ? data?.endDate?.toDate()
                                        : null
                                }
                                onDateChange={(date: Date): void => {}}
                                classReceived={data.dob !== null ? classes.fontBlue : ''}
                            />
                        </div>
                    </Container>

                    <Container sx={{ paddingBottom: '5%' }}>
                        <div className={classes.groupBtnLayout}>
                            {/* <DeleteAndEditBtn
                                variant="outlined"
                                size="large"
                                sx={{
                                    marginRight: '6%',
                                    backgroundColor: 'rgba(244, 224, 235, 1)'
                                }}
                                onClick={(): void => setDeactive(true)}>
                                <Typography variant="subtitle1">{t('deleteMyAccount')}</Typography>
                                <img
                                    src={deleteIcon}
                                    alt="DeleteIcon"
                                    style={{ marginLeft: '10%' }}
                                />
                            </DeleteAndEditBtn> */}
                            <DeleteAndEditBtn
                                variant="outlined"
                                size="large"
                                color="secondary"
                                sx={{
                                    backgroundColor: 'rgba(51, 135, 204, 0.1)'
                                }}
                                onClick={(): void => {
                                    setIsChangePassword(true);
                                }}>
                                <Typography variant="subtitle1">{t('changeMyPassword')}</Typography>
                                <img src={editIcon} alt="EditIcon" style={{ marginLeft: '10%' }} />
                            </DeleteAndEditBtn>
                            <DeleteAndEditBtn
                                variant="outlined"
                                size="large"
                                color="secondary"
                                sx={{
                                    backgroundColor: !loading ? 'rgba(51, 135, 204, 0.1)' : '',
                                    float: 'right',
                                    border: loading ? 'none' : ''
                                }}
                                onClick={saveDetails}
                                disabled={disabled}>
                                {loading ? (
                                    <div className="loading">
                                        <CircularProgress
                                            style={{
                                                marginTop: '20px',
                                                float: 'right',
                                                marginRight: '19%'
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <Typography variant="subtitle1">{t('modifier')}</Typography>
                                )}
                            </DeleteAndEditBtn>
                        </div>
                        <div className={classes.logoutBtnTablet}>
                            <LogoutBtn
                                fullWidth
                                variant="outlined"
                                size="large"
                                sx={{
                                    textAlign: 'left',
                                    border: 'none !important'
                                }}
                                onClick={(): void => {
                                    setLogout(true);
                                }}>
                                {t('logOut')}
                            </LogoutBtn>
                        </div>
                    </Container>
                </Box>
            </Box>
        </div>
    );

    return (
        <div>
            <ChangePassword
                isModelOpen={isChangePassword}
                setModelOpen={setIsChangePassword}
                onCancel={(): void => setIsChangePassword(false)}
                onButtonClick={(): void => {
                    setIsChangePassword(false);
                    logOut(user);
                }}
            />
            {/* <DeactiveModal
                isModelOpen={deactive}
                setModelOpen={setDeactive}
                title={t('wantToDeactivateAccount?')}
                bodyText={t('deactivateAccountDisclaimer')}
                buttonText={t('deleteAccount')}
                onButtonClick={deactivateAccount}
            /> */}
            <DeactiveModal
                isModelOpen={logout}
                setModelOpen={setLogout}
                title={t('logout')}
                bodyText={t('confirmLogout')}
                buttonText={t('logout')}
                onButtonClick={(): void => {
                    logOut(user);
                }}
            />
            {settingsScreen}
        </div>
    );
}
