import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CsvDownloader from 'react-csv-downloader';
import { Box, Typography } from '@mui/material';
import { DataGrid, GridRenderCellParams, GridRowData } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';
import {
    getEstablishmentExport,
    getUsersFromEstablishmentList
} from '../../services/cloudFirestore';
import { EstablishmentTableViewData } from '../../models/EstablishmentTableViewData';
import { exportCsvData } from '../../utilities/utils';
import { ExportData } from '../../models/ExportData';

interface EstablishmentListProps {
    rows: GridRowData[];
}

const useStyles = makeStyles(() => ({
    exportButton: {
        height: '38px',
        color: '#5C6670',
        fontSize: '12px',
        fontWeight: 700,
        cursor: 'pointer',
        border: '1px solid #E3E6E8',
        padding: '7px 27px'
    }
}));

export function EstablishmentList({ rows }: EstablishmentListProps): JSX.Element {
    const classes = useStyles();
    const { t } = useTranslation();
    const [establishmentData, setEstablishmentData] = useState<EstablishmentTableViewData[]>();
    const [exportDetails, setExportDetails] = useState<ExportData[]>([]);

    const columns = [
        { field: 'establishmentName', minWidth: 250, flex: 1, headerName: t('instituitionName') },
        { field: 'patientsCount', minWidth: 80, flex: 1, headerName: t('patients') },
        { field: 'therapistsCount', minWidth: 110, flex: 1, headerName: t('therapists') },
        { field: 'famillesCount', minWidth: 80, flex: 1, headerName: t('familles') },
        {
            field: 'exportCsv',
            minWidth: 150,
            flex: 1,
            headerName: t('exportButton'),
            // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            renderCell: (params: GridRenderCellParams) => {
                const data = exportDetails.find((item) => item.id === params.row.id);
                return (
                    <strong>
                        <CsvDownloader
                            className={classes.exportButton}
                            datas={exportCsvData(data ? data.establishmentData : [])}
                            filename="establishmentData"
                            extension=".csv"
                            separator=";"
                            wrapColumnChar=""
                            text={t('export')}
                        />
                    </strong>
                );
            }
        }
    ];

    useEffect(() => {
        const getUserList = async (): Promise<void> => {
            const data = await getUsersFromEstablishmentList();
            setEstablishmentData(data);
            const tableExport = await getEstablishmentExport(data);
            setExportDetails(tableExport);
        };
        getUserList();
    }, []);

    return (
        <Box m={1} flexGrow={1}>
            <Box display="flex" py={2} justifyContent="space-between">
                <Typography variant="h4">{t('establishmentListTitle')}</Typography>
            </Box>
            <div style={{ height: 280, width: '100%' }}>
                <div style={{ display: 'flex', height: '100%' }}>
                    <div style={{ flexGrow: 2 }}>
                        <DataGrid
                            columns={columns}
                            rows={
                                establishmentData === undefined || establishmentData === null
                                    ? []
                                    : establishmentData
                            }
                        />
                    </div>
                </div>
            </div>
        </Box>
    );
}
