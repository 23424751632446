/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dispatch, AnyAction } from 'redux';

import { IReducer, selectBlock } from '../../../store';
import { INDEX, N } from '../../../typings';

import { Container } from './styles';

interface IProps {
    colIndex: INDEX;
    rowIndex: INDEX;
}

interface IState {
    // isError: boolean;
    isActive: boolean;
    isPuzzle: boolean;
    value: N;
    isError: boolean;
}

const Block: FC<IProps> = ({ colIndex, rowIndex }) => {
    const state = useSelector<IReducer, IState>(
        ({ challengeGrid, selectedBlock, workingGrid, isError }) => ({
            isActive: selectedBlock
                ? selectedBlock[0] === rowIndex && selectedBlock[1] === colIndex
                : false,
            // eslint-disable-next-line no-unneeded-ternary
            isPuzzle: challengeGrid && challengeGrid[rowIndex][colIndex] !== 0 ? true : false,
            value: workingGrid ? workingGrid[rowIndex][colIndex] : 0,
            isError: isError ? isError : false
            // isError:
            //     challengeGrid &&
            //     workingGrid &&
            //     challengeGrid[rowIndex][colIndex] === workingGrid[rowIndex][colIndex] &&
            //     challengeGrid[rowIndex][colIndex] === 0
            //         ? true
            //         : false
            // value: 0,
        })
    );
    const dispatch = useDispatch<Dispatch<AnyAction>>();

    function handleClick() {
        if (!state.isActive) dispatch(selectBlock([rowIndex, colIndex]));
    }

    return (
        <Container
            active={state.isActive}
            data-cy={`block-${rowIndex}-${colIndex}`}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={handleClick}
            style={{
                fontFamily: 'Luciole-Bold',
                fontSize: '25px'
                // backgroundColor: state.isError && state.isActive ? 'red' : 'transparent'
            }}
            error={state.isError && state.isActive}
            puzzle={state.isPuzzle}>
            {state.value === 0 ? '' : state.value}
        </Container>
    );
};

// eslint-disable-next-line import/no-default-export
export default Block;
