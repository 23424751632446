import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../providers/UserProvider';
import { CentralLogo } from '../../components/Headers/CentralLogo';
import { PatientSelector } from '../../components/PatientSelector';
import { PatientProfile } from './PatientProfile';
import { SearchBox } from '../../components/SearchBox';
import { getPatientFromEstablishment, getTherapistUser } from '../../services/cloudFirestore';
import { Patient } from '../../models/Patient';
import { UserDataProp } from '../../models/userDataProp';
import { clickAnalytics } from '../../services/analyticsFunction';

export function ViewPatient(): JSX.Element {
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const { t } = useTranslation();
    const [patientsListArray, setPatientsList] = useState<Patient[]>();
    const [patientsAllListArray, setAllPatientsList] = useState<Patient[]>([]);
    const [selectedPatients, setSelectCard] = useState<Patient | null>(null);
    const [searchText, setSearchText] = useState<string>('');

    useEffect(() => {
        const getPatientList = async (): Promise<void> => {
            if (user && user.id) {
                const therapistUser = await getTherapistUser(user.id);
                if (therapistUser && therapistUser.establishmentCode) {
                    const patients = await getPatientFromEstablishment(
                        therapistUser.establishmentCode
                    );
                    if (patients) {
                        setPatientsList(patients);
                        setAllPatientsList(patients);
                    }
                }
            }
        };
        getPatientList();
    }, [selectedPatients]);

    const selectPatient = (value: Patient): void => {
        if (user) {
            clickAnalytics('click_on_patient_patientheque', user);
        }
        setSelectCard(value);
    };

    useEffect(() => {
        if (searchText && searchText.length > 0) {
            const searchDataFirstName = patientsAllListArray.filter((element) => {
                if (element.firstName) {
                    return element.firstName.toLowerCase().includes(searchText.toLowerCase());
                }
                return null;
            });
            const searchDataLastName = patientsAllListArray.filter((element) => {
                if (element.lastName) {
                    return element.lastName.toLowerCase().includes(searchText.toLowerCase());
                }
                return null;
            });
            const combineList = searchDataFirstName.concat(searchDataLastName);
            const uniqueList = combineList.filter((item, pos, self) => self.indexOf(item) === pos);
            setPatientsList(uniqueList);
        } else {
            setPatientsList(patientsAllListArray);
        }
    }, [searchText]);

    const getHeader = (): JSX.Element => {
        return (
            <Box>
                <CentralLogo hasBackButton hasPatientLibLogo hasProfilePhoto />
            </Box>
        );
    };

    const getSearchBox = (): JSX.Element => {
        return (
            <Box sx={{ padding: '10px' }}>
                <SearchBox
                    placeholder={t('toSearch')}
                    onSearchChange={(value: string): void => setSearchText(value)}
                    hasSearchIcon
                />
            </Box>
        );
    };

    const getPatients = (): JSX.Element[] | null => {
        if (patientsListArray) {
            if (patientsListArray.length > 0) {
                return patientsListArray.map((item) => {
                    return (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                                padding: '1%'
                            }}
                            key={item.id}>
                            <PatientSelector
                                patientsArray={item}
                                selectPatient={selectPatient}
                                viewPatient
                                isChecked={false}
                            />
                        </Grid>
                    );
                });
            }
        }
        return null;
    };

    const patientSettingsScreen = (
        <div>
            {getHeader()}
            <Box sx={{ padding: '0%' }}>{getSearchBox()}</Box>
            <Grid container sx={{ width: '100%', margin: 'auto' }}>
                {getPatients()}
            </Grid>
        </div>
    );

    return (
        <div>
            {selectedPatients === null ? (
                patientSettingsScreen
            ) : (
                <PatientProfile patientDataProps={selectedPatients} setSelectCard={setSelectCard} />
            )}
        </div>
    );
}
