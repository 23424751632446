import { Box, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactApexChart from 'react-apexcharts';
import { getCompletedExercises } from '../../services/cloudFirestore';

export function CompletedExercises(): React.ReactElement {
    const { t } = useTranslation();
    const [completedMemoryExercise, setCompletedMemoryExercise] = useState<number>(0);
    const [completedPuzzleExercise, setCompletedPuzzleExercise] = useState<number>(0);
    const [completedAllExercise, setCompletedAllExercise] = useState<number>(0);

    useEffect(() => {
        const getUserList = async (): Promise<void> => {
            const data = await getCompletedExercises();
            setCompletedMemoryExercise(data[0]);
            setCompletedPuzzleExercise(data[1]);
            setCompletedAllExercise(data[0] + data[1]);
        };
        getUserList();
    }, []);

    const chart = {
        series: [completedMemoryExercise, completedPuzzleExercise],
        chartOptions: {
            colors: ['#8200E8', '#00D1FF'],
            labels: [t('memory'), t('puzzleTitle')],
            dataLabels: {
                style: {
                    fontSize: '9.5px'
                }
            },
            plotOptions: {
                pie: {
                    donut: {
                        size: '55%'
                    }
                }
            }
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                textAlign: 'left',
                paddingTop: '30px',
                '& > :not(style)': {
                    m: 1,
                    height: 211,
                    width: 400
                }
            }}>
            <Paper elevation={1} sx={{ padding: '13px', width: '100%' }}>
                <Typography variant="h5">
                    {t('exercisesCompleted', { count: completedAllExercise })}
                </Typography>
                <Box mx={1} flexGrow={1}>
                    <ReactApexChart
                        type="donut"
                        series={chart.series}
                        options={chart.chartOptions}
                        width="100%"
                        height={160}
                    />
                </Box>
            </Paper>
        </Box>
    );
}
