import { Box, Button, Typography } from '@mui/material';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { DeactiveModall } from './ContainerModal';

const ModalButtons = styled(Button)(() => ({
    '@media (max-width: 598px)': {
        width: '100%',
        margin: '2% 0%'
    },
    '@media (min-width: 598px)': {
        width: '30%'
    }
}));

const ButtonContainer = styled(Box)({
    '@media (max-width: 598px)': {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    '@media (min-width: 598px)': {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row'
    }
});

interface ModalInterface {
    openModalAlert: boolean;
    setOpenModalAlert: Function;
    setMedia: Function;
    thumbnails?: boolean;
    numberExceedLimit?: boolean;
}

export function AlertModal({
    openModalAlert,
    setOpenModalAlert,
    setMedia,
    thumbnails,
    numberExceedLimit
}: ModalInterface): JSX.Element {
    const { t } = useTranslation();
    const inputFile = useRef<HTMLInputElement>(null);

    const triggerImageUpload = (): void => {
        if (inputFile && inputFile.current) {
            inputFile.current.click();
        }
    };

    const updatePhoto = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.files) {
            if (event.target.files.length <= 10) {
                // const value = event.target.files[0];
                const value = Array.from(event.target.files).map((file) => {
                    return file;
                });
                // add "&& value.size <= 10000000" to restore size limit
                if (value !== undefined) {
                    setOpenModalAlert(false);
                    setMedia(value);
                }
                // if (value !== undefined && value.size > 10000000) {
                //     setOpenModalAlert(true);
                // }
            }
        }
    };

    const updateVideo = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.files) {
            // const value = event.target.files[0];
            const value = Array.from(event.target.files).map((file) => {
                return file;
            });
            // add "&& value.size <= 50000000" to restore size limit
            if (value !== undefined) {
                setOpenModalAlert(false);
                setMedia(value);
            }
            // if (value !== undefined && value.size > 50000000) {
            //     setOpenModalAlert(true);
            // }
        }
    };

    const alertModal = (
        <div style={{ width: '90%', margin: 'auto' }}>
            <Typography variant="h3" color="#374653" sx={{ textAlign: 'center' }}>
                {numberExceedLimit && thumbnails && t('oupsLimitExceed')}
                {numberExceedLimit && !thumbnails && t('oupsLimitExceedVideo')}
                {!numberExceedLimit && !thumbnails && t('oups')}
                {!numberExceedLimit && thumbnails && t('oupsPicture')}
            </Typography>
            <Typography
                variant="subtitle1"
                color="#374653"
                mt={2}
                sx={{ textAlign: 'center', fontWeight: 500 }}>
                {numberExceedLimit && thumbnails && t('oupsLimitExceedDesc')}
                {numberExceedLimit && !thumbnails && t('oupsLimitExceedDescVideo')}
                {!numberExceedLimit && !thumbnails && t('download50MO')}
                {!numberExceedLimit && thumbnails && t('download10MOPicture')}
            </Typography>
            <ButtonContainer mt={3} mb={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                {!numberExceedLimit && (
                    <ModalButtons
                        variant="outlined"
                        sx={{
                            border: '1px solid #374653',
                            boxShadow: '0px 0px 0px 2px rgba(92, 102, 112, 0.6)',
                            borderRadius: '12px',
                            width: '30%',
                            marginRight: '5%'
                        }}
                        onClick={(): void => setOpenModalAlert(false)}>
                        <Typography variant="subtitle1" color="#374653">
                            {t('noDownload')}
                        </Typography>
                    </ModalButtons>
                )}
                <ModalButtons
                    variant="outlined"
                    sx={{
                        border: '1px solid #8B004B',
                        boxShadow: '0px 0px 0px 2px rgba(208, 0, 112, 0.4)',
                        borderRadius: '12px',
                        width: '30%'
                    }}
                    onClick={triggerImageUpload}>
                    {!thumbnails ? (
                        <input
                            type="file"
                            ref={inputFile}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                                updateVideo(event)
                            }
                            accept="video/mp4,video/x-m4v"
                            multiple
                            style={{ display: 'none' }}
                        />
                    ) : (
                        <input
                            type="file"
                            ref={inputFile}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                                updatePhoto(event)
                            }
                            accept="image/*"
                            multiple
                            style={{ display: 'none' }}
                        />
                    )}
                    {numberExceedLimit ? (
                        <Typography variant="subtitle1" color="#D00070">
                            {t('oupsLimitExceedBtn')}
                        </Typography>
                    ) : (
                        <Typography variant="subtitle1" color="#D00070">
                            {!thumbnails ? t('downloadVideo') : t('downloadPicture')}
                        </Typography>
                    )}
                </ModalButtons>
            </ButtonContainer>
        </div>
    );

    return (
        <DeactiveModall
            isModelOpen={openModalAlert}
            setModelOpen={setOpenModalAlert}
            body={alertModal}
            circleIcon
            onCancel={(): void => {
                setOpenModalAlert(false);
            }}
        />
    );
}
