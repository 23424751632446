import dotenv from 'dotenv';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';
import { getFunctions } from 'firebase/functions';
import { initializeApp } from 'firebase/app';

dotenv.config();

export const app = initializeApp(JSON.parse(process.env.REACT_APP_CONFIG!));

export const fireAuth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);
export const analytics = getAnalytics(app);
