/* eslint-disable no-console */
import firebase from 'firebase/compat';
import { httpsCallable } from 'firebase/functions';
import { v4 } from 'uuid';
import { Timestamp } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes, getStorage, deleteObject } from 'firebase/storage';
import { functions, storage } from './firebase';

export const uploadImageV2 = async (
    file: File,
    filename: string,
    metadata?: { contentType: string }
): Promise<string> => {
    const store = getStorage();
    const mediaRef = ref(store, filename);
    if (metadata) {
        return uploadBytes(mediaRef, file, metadata)
            .then(async (snapshot) => {
                return getDownloadURL(snapshot.ref)
                    .then((url) => {
                        return url;
                    })
                    .catch((error) => {
                        return '';
                    });
            })
            .catch((error) => {
                return '';
            });
    }
    return uploadBytes(mediaRef, file)
        .then(async (snapshot) => {
            return getDownloadURL(snapshot.ref)
                .then((url) => {
                    return url;
                })
                .catch((error) => {
                    return '';
                });
        })
        .catch((error) => {
            return '';
        });
};

export const uploadImage = async (
    file: File,
    pathStorage: string,
    video?: boolean
): Promise<string> => {
    const metadata = { contentType: 'video/mp4' };
    const extension = file.type.split('/').pop();
    const filename = `${pathStorage}/${v4()}.${extension}`;
    console.log(file);
    console.log(filename);
    console.log(extension);
    const storageRef = ref(storage, filename);
    console.log(storageRef);
    if (video && extension !== 'mp4') {
        return uploadBytes(storageRef, file, metadata)
            .then(async (snapshot) => {
                return getDownloadURL(snapshot.ref);
            })
            .catch((error) => {
                return uploadImageV2(file, filename, metadata);
            });
    }
    return uploadBytes(storageRef, file)
        .then((snapshot) => {
            return getDownloadURL(snapshot.ref);
        })
        .catch((error) => {
            return uploadImageV2(file, filename);
        });
};

export const deleteMedia = async (file: string): Promise<string> => {
    let pathFile = file.slice(file.indexOf('.com/o/'));
    pathFile = pathFile.substring(7, pathFile.indexOf('?alt'));
    pathFile = pathFile.replace('%2F', '/');
    const desertRef = ref(storage, pathFile);
    return deleteObject(desertRef)
        .then(() => {
            return 'success';
        })
        .catch((error) => {
            return 'error';
        });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addPatient = async (data: any): Promise<string> => {
    const content = data;
    content.createdDate = Timestamp.now();
    const upload: firebase.functions.HttpsCallable = httpsCallable(functions, 'createPatientUser');
    return upload(content)
        .then((result) => {
            return result.data;
        })
        .catch((error) => {
            return error;
        });
};

// export const sendErrorMail = async (
//     fileType: string,
//     fileName: string,
//     extension: string
// ): Promise<string> => {
//     const data = {
//         fileType,
//         fileName,
//         extension
//     };
//     const upload: firebase.functions.HttpsCallable = httpsCallable(functions, 'sendErrorEmail');
//     return upload(data)
//         .then((result) => {
//             return result.data;
//         })
//         .catch((error) => {
//             return error;
//         });
// };

export const sendThankYouMail = async (emailId: string, firstName: string): Promise<string> => {
    const data = {
        emailId,
        firstName
    };
    const upload: firebase.functions.HttpsCallable = httpsCallable(functions, 'sendThankYouEmail');
    return upload(data)
        .then((result) => {
            return result.data;
        })
        .catch((error) => {
            return error;
        });
};

export const sendWelcomeEmailFamily = async (
    firstNameFamilyMember: string,
    lastNameFamilyMember: string,
    firstNamePatient: string,
    lastNamePatient: string,
    patientEmail: string,
    lovedPassword: string,
    emailId: string,
    familyCode: string,
    emailFamilyRef: string
): Promise<string> => {
    const data = {
        firstNameFamilyMember,
        lastNameFamilyMember,
        firstNamePatient,
        lastNamePatient,
        patientEmail,
        lovedPassword,
        emailId,
        familyCode,
        emailFamilyRef
    };
    const upload: firebase.functions.HttpsCallable = httpsCallable(
        functions,
        'sendWelcomeEmailFamily'
    );
    return upload(data)
        .then((result) => {
            return result.data;
        })
        .catch((error) => {
            return error;
        });
};

export const sendResetInfoPatient = async (
    typeForgot: 'password' | 'email' | 'passwordEmail',
    emailToReset: string,
    emailsToSend: string[],
    variables: {
        firstName: string;
        firstNamePatient: string;
        lastNamePatient: string;
    },
    emailPatient: string
): Promise<string> => {
    const data = {
        typeForgot,
        emailToReset,
        // eslint-disable-next-line array-callback-return, consistent-return
        emailsToSend: emailsToSend.map((email) => {
            return { Email: email.toLowerCase() };
        }),
        variables,
        emailPatient
    };
    const upload: firebase.functions.HttpsCallable = httpsCallable(functions, 'sendResetEmail');
    return upload(data)
        .then((result) => {
            console.log(result);
            console.log(JSON.stringify(result));
            console.log(result.toString());
            return result;
        })
        .catch((error) => {
            return error;
        });
};
