import React, { useContext, useEffect, useState } from 'react';
import { blue } from '@mui/material/colors';
import { Avatar, Box, CardActionArea, Grid, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLongPress } from 'use-long-press';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { styled, makeStyles, createStyles } from '@mui/styles';
import greenTickIcon from '../../assets/greenTickIcon.png';
import { ExerciseHeader } from '../../components/ExerciseHeader';
import { ReviewExerciseAlerts } from '../common/ReviewExerciseAlerts';
import { Exercise } from '../../models/Exercise';
import { ExerciseItem } from '../../models/ExerciseItem';
import { getExerciseItem, getPatientUser, getTherapistUser } from '../../services/cloudFirestore';
import { determineDifficultyLevel, getImageKitUrlFrom, randomPicture } from '../../utilities/utils';
import { UserContext } from '../../providers/UserProvider';
import { Patient } from '../../models/Patient';
import { CompletedMemoryExercise } from '../../models/CompletedMemoryExercise';
import { Roles } from '../../models/Roles';
import { Therapist } from '../../models/Therapist';
import { UserDataProp } from '../../models/userDataProp';
import { ExerciseType } from '../../models/ExerciseType';

const useStyles = makeStyles(() =>
    createStyles({
        profileImage: {
            display: 'inline',
            marginRight: '10px',
            width: '70px',
            height: '70px',
            alignSelf: 'center',
            justifySelf: 'center',
            objectFit: 'cover'
        },
        currentUserInitials: {
            display: 'flex',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            margin: '0'
        }
    })
);

const ImageBox = styled(Box)({
    position: 'relative'
});

const ImageContainer = styled('img')({
    width: '100%',
    objectFit: 'cover'
});

const OverlayContainer = styled('img')({
    borderRadius: '9px',
    position: 'absolute',
    height: '98%',
    width: '98%',
    objectFit: 'cover',
    verticalAlign: 'center',
    top: '29%',
    left: '30%'
});

const Root = styled(Typography)({
    display: 'flex',
    alignContent: 'space-around',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 0
});

const DefaultLogo = styled('div')({
    borderRadius: '50%',
    background: '#efe7d7',
    position: 'relative'
});

interface LocationState {
    exercises: Exercise;
    exercisesItem: ExerciseItem[];
    random: number;
}

export function MemoryExercise1(): JSX.Element {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const location = useLocation<LocationState>();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [initials, setInitials] = useState<string>('');
    const [exercise, setExercise] = useState<Exercise>();
    const [author, setAuthor] = useState<Therapist>({
        firstName: undefined,
        lastName: undefined,
        emailId: undefined,
        dob: undefined,
        fonction: undefined,
        establishmentName: undefined,
        mobile: undefined,
        firstStepFormFields: [],
        secondStepFormFields: []
    });
    const [patient, setPatient] = useState<Patient>({
        firstName: undefined,
        lastName: undefined,
        emailId: undefined,
        dob: undefined,
        sex: undefined,
        level: undefined,
        category: undefined,
        familyCode: undefined,
        legalReferent: undefined,
        establishmentName: undefined,
        establishmentCode: undefined,
        startDate: undefined,
        endDate: undefined,
        address: undefined,
        pincode: undefined,
        city: undefined,
        familyMembers: undefined,
        memoryDifficultyLevel: undefined,
        puzzleDifficultyLevel: undefined,
        completedExerciseList: undefined,
        firstStepFormFields: [],
        secondStepFormFields: []
    });
    const [completedExercises, setCompletedExercises] = useState<string[] | null>();
    const [openReview, setOpenReview] = useState(false);
    const [exerciseItemList, setExerciseItemList] = useState<ExerciseItem[]>([]);
    const [exerciseItemIdList, setExerciseItemIdList] = useState<string[]>([]);
    const [submittedCorrectAnswers, setSubmittedCorrectAnswers] = useState<(string | undefined)[]>(
        []
    );

    useEffect(() => {
        if (completedExercises && completedExercises?.length > 0) {
            const list: (string | undefined)[] = [];
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < exerciseItemList.length; i += 1) {
                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (let j = 0; j < completedExercises.length; j += 1) {
                    if (
                        completedExercises[j] === exerciseItemList[i].id &&
                        exerciseItemList[i].answer
                    ) {
                        list.push(exerciseItemList[i].answer);
                    }
                }
            }
            setSubmittedCorrectAnswers(list);
        }
    }, [exerciseItemList]);

    useEffect(() => {
        if (user?.role === Roles.PATIENT) {
            const startTimer = localStorage.getItem('startTime');
            const count = localStorage.getItem('countErrorAndClue');
            if (!startTimer) {
                localStorage.setItem('startTime', new Date().toString());
            }
            if (!count) {
                localStorage.setItem('countErrorAndClue', '0');
            }
        }
    }, []);

    const getItemList = async (globalExercise: Exercise, patientData?: Patient): Promise<void> => {
        let listArray: number[] = [];
        const list: ExerciseItem[] = [];
        const listIds: string[] = [];
        const level: number =
            patientData && patientData.memoryDifficultyLevel
                ? patientData.memoryDifficultyLevel
                : 4;
        listArray = randomPicture(level, location.state.random, ExerciseType.MEMORY);
        if (globalExercise.exerciseImages) {
            await Promise.all(
                listArray.map(async (element) => {
                    if (globalExercise.exerciseImages && globalExercise.exerciseImages[element]) {
                        const string = globalExercise.exerciseImages[element];
                        if (string) {
                            const item = await getExerciseItem(string);
                            if (user && user.role === Roles.PATIENT && item && item.id) {
                                list.push(item);
                                listIds.push(item.id);
                            }
                            if (user && user.role === Roles.THERAPIST && item && item.id) {
                                list.push(item);
                                listIds.push(item.id);
                            }
                        }
                    }
                })
            );
            setExerciseItemList(list);
            setExerciseItemIdList(listIds);
        }
    };

    useEffect(() => {
        const getExerciseList = async (): Promise<void> => {
            if (user && user.id) {
                const patientData = await getPatientUser(user.id);
                if (patientData) {
                    setPatient(patientData);
                }
                let listData: CompletedMemoryExercise[] = [];
                if (patientData?.completedExerciseList) {
                    listData = listData.concat(patientData?.completedExerciseList);
                    const presentExercise = listData.filter((obj) => {
                        return (
                            id && obj && obj.completedExerciseId && obj.completedExerciseId === id
                        );
                    });
                    if (
                        presentExercise &&
                        presentExercise[0] &&
                        presentExercise[0].completedExerciseItemList
                    ) {
                        setCompletedExercises(presentExercise[0].completedExerciseItemList);
                    }
                }
                const globalExercise = location.state.exercises;
                setExercise(globalExercise);
                if (user && user.role === Roles.THERAPIST) {
                    await getItemList(globalExercise);
                } else if (user.role === Roles.PATIENT) {
                    if (
                        id !== undefined &&
                        id !== null &&
                        location.state.random !== undefined &&
                        patientData !== null
                    ) {
                        await getItemList(globalExercise, patientData);
                        if (globalExercise && globalExercise.authorId) {
                            const authorData = await getTherapistUser(globalExercise.authorId);
                            if (authorData) {
                                setAuthor(authorData);
                                if (authorData.firstName && authorData.lastName) {
                                    setInitials(
                                        authorData.firstName.charAt(0).toUpperCase() +
                                            authorData.lastName.charAt(0).toUpperCase()
                                    );
                                }
                            }
                        }
                    } else {
                        const list: string[] = [];
                        setExerciseItemList(location.state.exercisesItem);
                        location.state.exercisesItem.forEach((element) => {
                            if (element.id) {
                                list.push(element.id);
                            }
                        });
                        if (globalExercise && globalExercise.authorId) {
                            const authorData = await getTherapistUser(globalExercise.authorId);
                            if (authorData) {
                                setAuthor(authorData);
                                if (authorData.firstName && authorData.lastName) {
                                    setInitials(
                                        authorData.firstName.charAt(0).toUpperCase() +
                                            authorData.lastName.charAt(0).toUpperCase()
                                    );
                                }
                            }
                        }
                        setExerciseItemIdList(list);
                    }
                }
            }
        };
        getExerciseList();
    }, [id]);

    const checker = (completedExerciseList: string[], exerciseIdList: string[]): boolean => {
        return exerciseIdList.every((v) => completedExerciseList.includes(v));
    };

    useEffect(() => {
        if (
            exerciseItemIdList &&
            completedExercises &&
            completedExercises.length > 0 &&
            exerciseItemIdList.length > 0 &&
            checker(completedExercises, exerciseItemIdList)
        ) {
            setOpenReview(true);
            setCompletedExercises([]);
        }
    }, [completedExercises, exerciseItemIdList]);

    const isHeightOfXS = (): boolean => {
        const { innerWidth: width, innerHeight: height } = window;
        if (width < 550 || height < 550) {
            return true;
        }
        if (height <= 768 && height > 551) {
            return true;
        }
        return false;
    };

    const isWidthOfXS = (): boolean => {
        const { innerWidth: width, innerHeight: height } = window;
        if (width < 1000 || height < 800) {
            return true;
        }
        return false;
    };

    const onImageClick = (item: ExerciseItem): void => {
        if (item.id) {
            if (!completedExercises || completedExercises?.indexOf(item.id?.toString()) === -1) {
                history.push({
                    pathname: `/souven'in/exerciseItem/${item.id}`,
                    state: {
                        author,
                        memoryLevel: patient.memoryDifficultyLevel,
                        exerciseItemList,
                        currentExercise: item,
                        exercise,
                        difficultyLevel: determineDifficultyLevel(
                            patient && patient.memoryDifficultyLevel
                                ? patient.memoryDifficultyLevel
                                : 0
                        ),
                        completedExercises,
                        submittedCorrectAnswers
                    }
                });
            }
        }
    };

    const bindLongPress = useLongPress(
        (e, index) => {
            onImageClick(exerciseItemList[Number(index.context)]);
        },
        { threshold: 1000 }
    );

    const getExercises = (): JSX.Element[] | null => {
        if (exerciseItemList) {
            if (exerciseItemList.length === 3) {
                return exerciseItemList.map((item, index) => {
                    return (
                        <Grid
                            item
                            xs={4}
                            key={item.id}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...bindLongPress(index)}
                            onClick={(): void => onImageClick(item)}>
                            <CardActionArea>
                                <ImageBox>
                                    <ImageContainer
                                        src={
                                            typeof item.exerciseImage === 'string'
                                                ? getImageKitUrlFrom(item.exerciseImage)
                                                : ''
                                        }
                                        alt="selectedImage"
                                    />
                                    {completedExercises &&
                                        item &&
                                        item.id &&
                                        completedExercises.indexOf(item.id) !== -1 && (
                                            <Box
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    display: 'block',
                                                    width: '100%',
                                                    height: '100%',
                                                    backgroundColor: '#0F004678',
                                                    opacity: 0.6
                                                }}
                                            />
                                        )}
                                    <OverlayContainer
                                        src={greenTickIcon}
                                        alt="puzzleOverlay"
                                        style={{
                                            display:
                                                completedExercises &&
                                                item &&
                                                item.id &&
                                                completedExercises?.indexOf(item.id) !== -1
                                                    ? 'inline'
                                                    : 'none',
                                            width: '40%',
                                            height: '40%'
                                        }}
                                    />
                                </ImageBox>
                            </CardActionArea>
                        </Grid>
                    );
                });
            }
            return exerciseItemList.map((item, index) => {
                return (
                    <Grid
                        item
                        xs={exerciseItemList.length === 4 ? 12 : 4}
                        gridColumn="span 1"
                        key={item.id}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...bindLongPress(index)}
                        onClick={(): void => onImageClick(item)}>
                        <CardActionArea>
                            <ImageBox>
                                <ImageContainer
                                    src={
                                        typeof item.exerciseImage === 'string'
                                            ? getImageKitUrlFrom(item.exerciseImage)
                                            : ''
                                    }
                                    alt="selectedImage"
                                    style={{
                                        opacity:
                                            completedExercises &&
                                            item &&
                                            item.id &&
                                            completedExercises.indexOf(item.id) !== -1
                                                ? 0.6
                                                : 1.0
                                    }}
                                />
                                <OverlayContainer
                                    src={greenTickIcon}
                                    alt="puzzleOverlay"
                                    style={{
                                        display:
                                            completedExercises &&
                                            item &&
                                            item.id &&
                                            completedExercises?.indexOf(item.id) !== -1
                                                ? 'inline'
                                                : 'none',
                                        width: '39%',
                                        height: '39%'
                                    }}
                                />
                            </ImageBox>
                        </CardActionArea>
                    </Grid>
                );
            });
        }
        return null;
    };

    return (
        <Box flex={1} display="flex" flexDirection="column" height="60vh">
            {openReview && (
                <ReviewExerciseAlerts
                    exercise={exercise}
                    exerciseItemList={exerciseItemList}
                    exerciseType={ExerciseType.MEMORY}
                    difficultyLevel={
                        patient.memoryDifficultyLevel ? patient.memoryDifficultyLevel : 0
                    }
                />
            )}
            <ExerciseHeader
                exerciseId={exercise?.id}
                exerciseName={exercise?.exerciseName}
                difficultyLevel={determineDifficultyLevel(
                    patient && patient.memoryDifficultyLevel ? patient.memoryDifficultyLevel : 0
                )}
                level={patient && patient.memoryDifficultyLevel ? patient.memoryDifficultyLevel : 0}
                handleClueClick={(): void => {}}
                exerciseItemList={exerciseItemList}
                exercise={exercise}
                completedExercises={completedExercises}
            />
            <Paper
                variant="outlined"
                sx={{
                    backgroundColor: blue[50],
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '6px',
                    marginTop: '100px',
                    marginRight: '2%',
                    marginLeft: '2%',
                    marginBottom: '2vh',
                    height: isHeightOfXS() ? '10vh' : '7vh'
                }}>
                {author &&
                    author.role !== undefined &&
                    !author.profilePhoto &&
                    author.role !== Roles.ADMIN && (
                        <DefaultLogo
                            className={classes.profileImage}
                            style={{
                                height: isHeightOfXS() ? '12vh' : '9vh',
                                width: isHeightOfXS() ? '12vh' : '9vh'
                            }}>
                            <div style={{ height: '5vmin' }}>
                                <Typography
                                    variant="h2"
                                    sx={{ fontWeight: 'bold', color: '#374653' }}
                                    className={classes.currentUserInitials}>
                                    {initials}
                                </Typography>
                            </div>
                        </DefaultLogo>
                    )}
                {author &&
                    author.role !== undefined &&
                    author.profilePhoto &&
                    typeof author.profilePhoto === 'string' &&
                    author.role !== Roles.ADMIN && (
                        <Avatar
                            alt="userIcon"
                            src={getImageKitUrlFrom(author.profilePhoto)}
                            className={classes.profileImage}
                        />
                    )}
                <Typography
                    variant="h4"
                    my={5}
                    color="#3387CC"
                    sx={{
                        marginTop: '25px',
                        marginBottom: '25px',
                        fontFamily: user?.role === Roles.PATIENT ? 'Luciole-Regular' : ''
                    }}>
                    {exercise?.authorType === Roles.FAMILY
                        ? t('placeFingerOnPhotoFamily')
                        : t('placeFingerOnPhoto')}
                </Typography>
            </Paper>
            {exerciseItemList && exerciseItemList.length === 3 ? (
                <Box margin="auto" px={3} pt={5}>
                    <Root>
                        <Grid
                            container
                            spacing={1}
                            sx={{
                                justifyContent: 'center',
                                alignItems: 'stretch',
                                marginBottom: '1%'
                            }}>
                            {getExercises()}
                        </Grid>
                    </Root>
                </Box>
            ) : (
                <Box maxWidth={isWidthOfXS() ? 900 : 1000} margin="auto">
                    <Root>
                        <Grid
                            container
                            spacing={1}
                            // xs={12}
                            display={exerciseItemList.length === 4 ? 'grid' : 'flex'}
                            // gridTemplateColumns={`repeat(${
                            //     exerciseItemList.length === 4 ? '2' : '3'
                            // }, 1fr)`}
                            gridTemplateColumns="repeat(2, 1fr)"
                            // gridTemplateRows="repeat(2, 1fr)"
                            sx={{
                                justifyContent: 'center',
                                alignItems: 'stretch',
                                marginBottom: '1%',
                                width: exerciseItemList.length === 4 ? '70%' : '100%'
                            }}>
                            {getExercises()}
                        </Grid>
                    </Root>
                </Box>
            )}
        </Box>
    );
}
