import React from 'react';
import styled from 'styled-components';
import { Button, CircularProgress, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import backIconBlack from '../../assets/backIconBlack.png';

const useStyles = makeStyles(() => ({
    groupButtonLayout: {
        '@media (max-width: 598px)': {
            width: '95%',
            margin: 'auto',
            paddingBottom: '5%'
        },
        '@media (min-width: 598px)': {
            width: '75%',
            margin: 'auto',
            display: 'flex',
            justifyContent: 'space-between'
        }
    },
    buttonLayout: {
        '@media (max-width: 598px)': {
            margin: '2% 0%'
        },
        '@media (min-width: 598px)': {
            marginTop: '7px'
        }
    }
}));

const NextStepButton = styled(Button)(() => ({
    margin: '2% 0%',
    border: '1px solid #8B004B',
    boxShadow: '0px 0px 0px 2px rgba(208, 0, 112, 0.4)',
    '@media (min-width: 598px)': {
        width: '40%',
        padding: '0px'
    }
}));

const PreviousStepButton = styled(Button)(() => ({
    margin: '2% 0%',
    color: 'rgba(55, 70, 83, 1)',
    border: '1px solid #374653',
    boxShadow: '0px 0px 0px 2px rgba(92, 102, 112, 0.6)',
    '@media (min-width: 598px)': {
        width: '40%',
        padding: '0px'
    }
}));

interface ButtonsCreateAndEditProps {
    disabled: boolean;
    nextText: string;
    nextClick: Function;
    previousClick: Function;
    loading?: boolean;
    lastStep?: boolean;
    revertButtonIsSmallWidth?: boolean;
}

export function ButtonsCreateAndEdit({
    disabled,
    nextText,
    nextClick,
    previousClick,
    loading,
    lastStep,
    revertButtonIsSmallWidth = false
}: ButtonsCreateAndEditProps): JSX.Element {
    const isSmallWidth = !useMediaQuery('(min-width:598px)');
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div>
            {isSmallWidth ? (
                <div className={classes.groupButtonLayout}>
                    {revertButtonIsSmallWidth && (
                        <PreviousStepButton
                            fullWidth
                            variant="outlined"
                            size="large"
                            className={classes.buttonLayout}
                            onClick={(): void => previousClick()}>
                            {!lastStep && (
                                <img
                                    src={backIconBlack}
                                    alt="EditIcon"
                                    style={{ height: '20px', margin: '0% 5%' }}
                                />
                            )}
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                {!lastStep ? t('previousStep') : t('modify')}
                            </Typography>
                        </PreviousStepButton>
                    )}
                    {loading ? (
                        <div className="loading">
                            <CircularProgress
                                style={{
                                    marginTop: '20px'
                                }}
                            />
                        </div>
                    ) : (
                        <NextStepButton
                            sx={{ backgroundColor: lastStep ? '#D00070' : '' }}
                            disabled={disabled}
                            fullWidth
                            variant="outlined"
                            size="large"
                            className={classes.buttonLayout}
                            onClick={(): void => nextClick()}>
                            <Typography
                                variant="subtitle1"
                                sx={{ fontWeight: 'bold', color: lastStep ? 'white' : '' }}>
                                {nextText}
                            </Typography>
                        </NextStepButton>
                    )}
                    {!revertButtonIsSmallWidth && (
                        <PreviousStepButton
                            fullWidth
                            variant="outlined"
                            size="large"
                            className={classes.buttonLayout}
                            onClick={(): void => previousClick()}>
                            {!lastStep && (
                                <img
                                    src={backIconBlack}
                                    alt="EditIcon"
                                    style={{ height: '20px', margin: '0% 5%' }}
                                />
                            )}
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                {!lastStep ? t('previousStep') : t('modify')}
                            </Typography>
                        </PreviousStepButton>
                    )}
                </div>
            ) : (
                <div className={classes.groupButtonLayout}>
                    <PreviousStepButton
                        fullWidth
                        variant="outlined"
                        size="large"
                        className={classes.buttonLayout}
                        onClick={(): void => previousClick()}>
                        {!lastStep && (
                            <img
                                src={backIconBlack}
                                alt="EditIcon"
                                style={{ height: '20px', margin: '0% 5%' }}
                            />
                        )}
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                            {!lastStep ? t('previousStep') : t('modify')}
                        </Typography>
                    </PreviousStepButton>
                    {loading ? (
                        <div className="loading">
                            <CircularProgress
                                style={{
                                    marginTop: '20px'
                                }}
                            />
                        </div>
                    ) : (
                        <NextStepButton
                            sx={{ backgroundColor: lastStep ? '#D00070' : '' }}
                            disabled={disabled}
                            fullWidth
                            variant="outlined"
                            size="large"
                            className={classes.buttonLayout}
                            onClick={(): void => nextClick()}>
                            <Typography
                                variant="subtitle1"
                                sx={{ fontWeight: 'bold', color: lastStep ? 'white' : '' }}>
                                {nextText}
                            </Typography>
                        </NextStepButton>
                    )}
                </div>
            )}
        </div>
    );
}
