/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import { lightBlue } from '@mui/material/colors';
import {
    AppBar,
    Avatar,
    Button,
    Rating,
    Slide,
    Toolbar,
    Typography,
    useScrollTrigger
} from '@mui/material';
import ExtensionIcon from '@mui/icons-material/Extension';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import { useLongPress } from 'use-long-press';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isUndefined } from 'lodash';
import { BrainSvgIcon } from './BrainSvgIcon';
import { TocSvgIcon } from './TocSvgIcon';
import { SudokuSvgIcon } from './SudokuSvgIcon';
import { PongSvgIcon } from './PongSvgIcon';
import { UserContext } from '../providers/UserProvider';
import redMemory from '../assets/redMemory.png';
import { Roles } from '../models/Roles';
import { DeactiveModall } from './ContainerModal';
import { countGiveUp, resetCompletedExercises, updateClueCount } from '../services/cloudFirestore';
import { UserDataProp } from '../models/userDataProp';
import { ExerciseItem } from '../models/ExerciseItem';
import { Exercise } from '../models/Exercise';
import { ExerciseType } from '../models/ExerciseType';

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: '#FF0089'
    }
});

const StimulinAppBar = styled(AppBar)({
    background: '#fff',
    height: '78px'
});

const AppToolBar = styled(Toolbar)({
    justifyContent: 'space-between'
});

const ModalButtons = styled(Button)(() => ({
    '@media (max-width: 598px)': {
        width: '100%',
        margin: '2% 0%'
    },
    '@media (min-width: 598px)': {
        width: '30%'
    }
}));

const ButtonContainer = styled(Box)({
    '@media (max-width: 598px)': {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    '@media (min-width: 598px)': {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row'
    }
});

interface HeaderProps {
    isPuzzle?: boolean;
    isPuzzleInitial?: boolean;
    level: number;
    difficultyLevel: number;
    handleClueClick: Function;
    isExerciseSelected?: boolean;
    isMemorin?: boolean;
    exerciseName?: string;
    exerciseId?: string;
    isLastOption?: boolean;
    exerciseItemList?: ExerciseItem[] | undefined;
    exercise?: Exercise | undefined;
    completedExercises?: string[] | null;
    alreadyUpdateCount?: boolean;
    setAlreadyUpdateCount?: Function;
    exerciseType?: ExerciseType;
    handleGoBack?: Function;
    displayBack?: boolean;
}

interface Props {
    window?: () => Window;
    children: React.ReactElement;
}

function HideOnScroll(props: Props): JSX.Element {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

export function ExerciseHeader(
    {
        isPuzzle,
        isPuzzleInitial,
        level,
        difficultyLevel,
        handleClueClick,
        isExerciseSelected,
        isMemorin,
        exerciseName,
        exerciseId,
        isLastOption,
        exerciseItemList,
        exercise,
        completedExercises,
        alreadyUpdateCount,
        setAlreadyUpdateCount,
        exerciseType,
        handleGoBack,
        displayBack
    }: HeaderProps,
    props: Props
): JSX.Element {
    const history = useHistory();
    const { t } = useTranslation();
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
    const [buttonClickedCount, setButtonClickedCount] = useState<number>(0);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [timerId, setTimerId] = useState<NodeJS.Timeout>();

    const bindLongPressCancel = useLongPress(
        (event) => {
            setOpenModal(false);
        },
        { threshold: 1000 }
    );

    useEffect(() => {
        if (isLastOption) {
            setIsButtonDisabled(true);
            if (timerId) {
                clearTimeout(timerId);
            }
        }
    }, [isLastOption]);

    // const [alreadyUpdateCount, setAlreadyUpdateCount] = useState<boolean>(false);
    const updateCount = async (): Promise<void> => {
        if (
            !alreadyUpdateCount &&
            exerciseId &&
            user &&
            user.id &&
            user.role === Roles.PATIENT &&
            exercise &&
            exercise.exerciseType &&
            !isUndefined(setAlreadyUpdateCount)
        ) {
            setAlreadyUpdateCount(true);
            await updateClueCount(exerciseId, user.id, exercise.exerciseType);
            const countErrorAndClue = localStorage.getItem('countErrorAndClue');
            if (countErrorAndClue) {
                const newCountErrorAndClue = Number(countErrorAndClue) + 1;
                localStorage.setItem('countErrorAndClue', newCountErrorAndClue.toString());
            }
        }
    };

    const handleClick = (): void => {
        let tmpTimerId;

        updateCount();
        setIsButtonDisabled(true);
        handleClueClick();
        setButtonClickedCount(buttonClickedCount + 1);
        if (!isLastOption) {
            tmpTimerId = setTimeout(() => {
                setIsButtonDisabled(false);
            }, 10000);
            setTimerId(tmpTimerId);
        }
    };

    const bindLongPressClue = useLongPress(
        (event) => {
            handleClick();
        },
        { threshold: 1000 }
    );

    const resetExerciseItems = async (): Promise<void> => {
        if (user && exercise && exerciseItemList && completedExercises) {
            await countGiveUp(user, exercise);
            await resetCompletedExercises(user, exercise);
        } else if (user && exercise && exercise.exerciseType === ExerciseType.ORDER) {
            await countGiveUp(user, exercise);
        }
        history.push('/home');
    };

    const bindLongPressValid = useLongPress(
        (event) => {
            resetExerciseItems();
        },
        { threshold: 1000 }
    );

    const getMemoryHeader = (): JSX.Element => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    paddingRight: !isExerciseSelected ? '0px' : '20px',
                    marginLeft: !isExerciseSelected ? '55px' : 'none'
                }}>
                <Typography
                    variant="h4"
                    color={lightBlue[900]}
                    sx={{
                        fontFamily: user?.role === Roles.PATIENT ? 'Luciole-Regular' : '',
                        fontSize: '2rem'
                    }}>
                    {exerciseName}
                </Typography>
            </Box>
        );
    };

    const getPongHeader = (): JSX.Element => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'absolute',
                    width: 'calc(100% - 16px)',
                    right: '20px'
                }}>
                <Typography
                    variant="h4"
                    color={lightBlue[900]}
                    sx={{
                        fontFamily: user?.role === Roles.PATIENT ? 'Luciole-Regular' : '',
                        fontSize: '2rem'
                    }}>
                    {exerciseName}
                </Typography>
            </Box>
        );
    };

    const getPuzzleHeader = (): JSX.Element => {
        return (
            <Box sx={{ display: 'flex', paddingRight: '0px', marginLeft: '55px' }}>
                {/* <ExtensionIcon
                    sx={{
                        marginRight: '10px',
                        width: '40px',
                        height: '40px',
                        alignSelf: 'center',
                        justifySelf: 'center',
                        color: '#FF0089'
                    }}
                    fontSize="inherit"
                /> */}
                <Typography
                    variant="h4"
                    color={lightBlue[900]}
                    sx={{
                        fontFamily: user?.role === Roles.PATIENT ? 'Luciole-Regular' : '',
                        fontSize: '2rem'
                    }}>
                    {exerciseName}
                </Typography>
            </Box>
        );
    };

    const handleButtonClick = (): void => {
        if (handleGoBack) {
            handleGoBack();
        } else if (isExerciseSelected && !isPuzzle && !isMemorin) {
            history.goBack();
        } else {
            setOpenModal(true);
        }
    };

    const bindLongPress = useLongPress(
        (event) => {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            handleButtonClick();
        },
        { threshold: 1000 }
    );

    const quitExerciseModal = (
        <div style={{ width: '90%', margin: 'auto' }}>
            <Typography variant="h3" color="#374653" sx={{ textAlign: 'center' }}>
                {t('wantToQuitExercise')}
            </Typography>
            <Typography
                variant="subtitle1"
                color="#374653"
                mt={2}
                sx={{ textAlign: 'center', fontWeight: 500 }}>
                {t('disclaimerQuitExercise')}
            </Typography>
            <ButtonContainer mt={3} mb={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                <ModalButtons
                    variant="outlined"
                    sx={{
                        border: '1px solid #374653',
                        boxShadow: '0px 0px 0px 2px rgba(92, 102, 112, 0.6)',
                        borderRadius: '12px',
                        width: '30%',
                        marginRight: '5%'
                    }}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...bindLongPressCancel()}
                    onClick={(): void => setOpenModal(false)}>
                    <Typography variant="subtitle1" color="#374653">
                        {t('toCancel')}
                    </Typography>
                </ModalButtons>
                <ModalButtons
                    variant="outlined"
                    sx={{
                        border: '1px solid #8B004B',
                        boxShadow: '0px 0px 0px 2px rgba(208, 0, 112, 0.4)',
                        borderRadius: '12px',
                        width: '30%'
                    }}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...bindLongPressValid()}
                    onClick={(): Promise<void> => resetExerciseItems()}>
                    <Typography variant="subtitle1" color="#D00070">
                        {t('yes')}
                    </Typography>
                </ModalButtons>
            </ButtonContainer>
        </div>
    );

    return (
        <HideOnScroll {...props}>
            <StimulinAppBar style={{ justifyContent: 'center' }}>
                <AppToolBar sx={{ padding: '10px' }}>
                    <Box>
                        <Button
                            sx={{ height: '40px', zIndex: 100 }}
                            fullWidth
                            disableElevation
                            variant="backbutton"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...bindLongPress()}
                            onClick={(): void => handleButtonClick()}>
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    fontFamily:
                                        user?.role === Roles.PATIENT ? 'Luciole-Regular' : ''
                                }}>
                                {(isExerciseSelected && !isPuzzle && !isMemorin) || displayBack
                                    ? t('backButton')
                                    : t('quitExercise')}
                            </Typography>
                        </Button>
                    </Box>
                    {isPuzzle
                        ? exerciseType === ExerciseType.PONG
                            ? getPongHeader()
                            : getPuzzleHeader()
                        : getMemoryHeader()}
                    <Box sx={{ marginRight: '10px' }}>
                        {isExerciseSelected &&
                            !isButtonDisabled &&
                            exerciseType !== ExerciseType.PONG && (
                                <Button
                                    sx={{
                                        opacity: isButtonDisabled ? '0.5' : '',
                                        display: isPuzzleInitial ? 'none' : 'flex',
                                        // display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                    fullWidth
                                    disableElevation
                                    variant="clue"
                                    size="large"
                                    {...bindLongPressClue()}
                                    onClick={handleClick}>
                                    <HelpOutlinedIcon sx={{ marginRight: '7%', marginTop: '4%' }} />
                                    <Typography
                                        variant="subtitle1"
                                        sx={{
                                            fontFamily:
                                                user?.role === Roles.PATIENT
                                                    ? 'Luciole-Regular'
                                                    : ''
                                        }}>
                                        {t('clue')}
                                    </Typography>
                                </Button>
                            )}
                        {isExerciseSelected &&
                            isButtonDisabled &&
                            exerciseType !== ExerciseType.PONG && (
                                <Button
                                    sx={{ opacity: isButtonDisabled ? '0.5' : '' }}
                                    fullWidth
                                    disableElevation
                                    variant="greyClue"
                                    size="large">
                                    <HelpOutlinedIcon sx={{ marginRight: '7%', marginTop: '4%' }} />
                                    <Typography
                                        variant="subtitle1"
                                        sx={{
                                            fontFamily:
                                                user?.role === Roles.PATIENT
                                                    ? 'Luciole-Regular'
                                                    : ''
                                        }}>
                                        {t('10seconds')}
                                    </Typography>
                                </Button>
                            )}
                        {!isExerciseSelected && (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        fontFamily:
                                            user?.role === Roles.PATIENT ? 'Luciole-Regular' : '',
                                        color: 'rgba(55, 70, 83, 0.61)',
                                        paddingRight: '10px',
                                        fontWeight: '400',
                                        fontSize: '0.7rem'
                                    }}>
                                    {t('difficultyLev', {
                                        level,
                                        max: ExerciseType.MEMORY ? 10 : 6
                                    })}
                                </Typography>
                                {exerciseType === ExerciseType.SUDOKU ? (
                                    <StyledRating
                                        value={1}
                                        max={1}
                                        icon={<SudokuSvgIcon />}
                                        readOnly
                                    />
                                ) : exerciseType === ExerciseType.PONG ? (
                                    <StyledRating
                                        value={1}
                                        max={1}
                                        icon={<PongSvgIcon />}
                                        readOnly
                                    />
                                ) : exerciseType === ExerciseType.ORDER ? (
                                    <StyledRating
                                        value={1}
                                        max={1}
                                        icon={<TocSvgIcon />}
                                        readOnly
                                    />
                                ) : (
                                    <StyledRating
                                        value={1}
                                        max={1}
                                        icon={<BrainSvgIcon />}
                                        readOnly
                                    />
                                )}
                            </div>
                        )}
                        {isPuzzleInitial && (
                            <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        fontFamily:
                                            user?.role === Roles.PATIENT ? 'Luciole-Regular' : '',
                                        color: 'rgba(55, 70, 83, 0.61)',
                                        paddingRight: '10px',
                                        fontWeight: '400',
                                        fontSize: '0.7rem'
                                    }}>
                                    {t('difficultyLev', { level, max: 10 })}
                                </Typography>
                                <StyledRating
                                    value={1}
                                    max={1}
                                    icon={<ExtensionIcon fontSize="inherit" />}
                                    readOnly
                                />
                            </div>
                        )}
                    </Box>
                </AppToolBar>
                <DeactiveModall
                    isModelOpen={openModal}
                    setModelOpen={setOpenModal}
                    body={quitExerciseModal}
                    circleIcon
                    onCancel={(): void => {
                        setOpenModal(false);
                    }}
                />
            </StimulinAppBar>
        </HideOnScroll>
    );
}
