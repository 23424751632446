import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';

const useStyles = makeStyles(() => ({
    input: {
        color: '#004680',
        fontFamily: 'Inter',
        fontSize: '16px',
        alignItems: 'flex-start'
    },
    root: {
        '&$focused $notchedOutline': {
            borderColor: '#D9D9D9'
        }
    },
    focused: {},
    notchedOutline: {}
}));

interface InputProps {
    placeholder: string;
    type: string;
    className: string;
    onTextChange: Function;
    isMultiline: boolean;
    value: string | number | null;
    disabled?: boolean;
    hasSearchIcon?: boolean;
    noOfRows?: number;
    maximumLength?: number;
    readonly?: boolean;
    onBlur?: Function;
    onFocus?: Function;
}

export function InputComponent({
    placeholder,
    type,
    className,
    onTextChange,
    isMultiline,
    value,
    disabled,
    hasSearchIcon,
    noOfRows,
    maximumLength,
    readonly,
    onBlur,
    onFocus
}: InputProps): JSX.Element {
    const [text, setText] = useState<string | number>();
    const [rows, setRows] = useState<number>(0);

    useEffect(() => {
        if (value) {
            setText(value);
        }
    });

    const onTextFieldChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void => {
        onTextChange(event.currentTarget.value);
        setText(event.currentTarget.value);
    };

    const getRows = (): number => {
        if (noOfRows) {
            return noOfRows;
        }
        if (isMultiline) {
            return 2;
        }
        return 0;
    };

    const icon =
        hasSearchIcon === true ? (
            <SearchIcon width="27px" sx={{ transform: 'scale(1.5)', color: '#004680' }} />
        ) : null;

    const classes = useStyles();

    return (
        <div>
            <TextField
                InputProps={{
                    classes: {
                        input: classes.input,
                        root: classes.root,
                        focused: classes.focused,
                        notchedOutline: classes.notchedOutline
                    },
                    inputProps: {
                        min: 0,
                        maxLength: maximumLength,
                        readOnly: readonly ?? false
                    },
                    endAdornment: icon
                }}
                disabled={disabled}
                multiline={isMultiline}
                type={type}
                className={className}
                placeholder={placeholder}
                value={value ?? ''}
                rows={getRows()}
                variant="outlined"
                onChange={(event): void => onTextFieldChange(event)}
                onBlur={(): void => (onBlur ? onBlur() : '')}
                onFocus={(): void => (onFocus ? onFocus() : '')}
                InputLabelProps={{
                    style: { color: '#D9D9D9' }
                }}
                sx={{ width: '100%' }}
            />
        </div>
    );
}
