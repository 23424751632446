import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { TableLayout } from '../../layouts/TableLayout';
import { EstablishmentsExportData } from '../../models/EstablishmentsExportData';
import { TherapistViewData } from '../../models/TherapistViewData';
import { getThearpistList, getTherapistsExportData } from '../../services/cloudFirestore';
import { convertToTitleCase, getDateFromTimestamp } from '../../utilities/utils';

export function Therapist(): JSX.Element {
    const { t } = useTranslation();
    const history = useHistory();
    const [therapistList, setTherapistList] = useState<TherapistViewData[] | null>(null);
    const [therapistExportData, setTherapistExportData] = useState<EstablishmentsExportData[]>([]);

    const columns = [
        { field: 'establishmentName', minWidth: 140, flex: 1, headerName: t('instituitionName') },
        { field: 'firstName', minWidth: 50, flex: 1, headerName: t('firstName') },
        { field: 'lastName', minWidth: 50, flex: 1, headerName: t('name') },
        { field: 'dob', minWidth: 140, flex: 1, headerName: t('dateOfBirth') },
        { field: 'emailId', minWidth: 200, flex: 1, headerName: t('emailPlaceholder') },
        { field: 'fonction', minWidth: 150, flex: 1, headerName: t('function') },
        { field: 'mobile', minWidth: 100, flex: 1, headerName: t('phoneNumber') },
        { field: 'status', minWidth: 120, flex: 1, headerName: t('status') }
    ];

    const onAddButtonClicked = (): void => {
        history.push('/therapist/add');
    };

    const fetchTherapistList = async (): Promise<void> => {
        const therapist = await getThearpistList();
        const therapistViewData: TherapistViewData[] = therapist;
        therapist.forEach((item, index) => {
            if (item.dob) {
                therapistViewData[index].dob = getDateFromTimestamp(item.dob);
            }
            if (item.status) {
                therapistViewData[index].status = convertToTitleCase(item.status);
            }
        });
        setTherapistList(therapistViewData);
        const exportData = await getTherapistsExportData();
        setTherapistExportData(exportData);
    };

    useEffect(() => {
        if (therapistList === null || therapistList === undefined) {
            fetchTherapistList();
        }
    });

    return (
        <TableLayout
            title={t('therapists')}
            addButtonTitle={t('addTherapists')}
            onAddButtonClicked={onAddButtonClicked}
            columns={columns}
            rows={therapistList === undefined || therapistList === null ? [] : therapistList}
            exportData={therapistExportData}
            nameOfFile={t('therapists')}
        />
    );
}
