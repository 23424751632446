import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Roles } from '../../models/Roles';
import messageIcon from '../../assets/messageIcon.png';
import { InputComponent } from '../../components/FormComponents/InputComponent';
// eslint-disable-next-line import/no-cycle
import { UserContext } from '../../providers/UserProvider';
// eslint-disable-next-line import/no-cycle
import { ExerciseMedia } from './ExerciseMedia';
import { Exercise } from '../../models/Exercise';
// eslint-disable-next-line import/no-cycle
import { getPatientUser } from '../../services/cloudFirestore';
import { ExitCustomizationModal } from '../../components/ExitCustomizationModal';
import { UserDataProp } from '../../models/userDataProp';
import { Patient } from '../../models/Patient';
import { Header } from '../../components/CreateAndEdit/Header';
import { TitleAndIntro } from '../../components/CreateAndEdit/TitleAndIntro';
import { ButtonsCreateAndEdit } from '../../components/CreateAndEdit/ButtonsCreateAndEdit';

const useStyles = makeStyles(() => ({
    secondStepContainer: {
        '@media (max-width: 598px)': {
            width: '95%',
            margin: 'auto',
            paddingBottom: '4%'
        },
        '@media (min-width: 598px)': {
            width: '75%',
            margin: 'auto'
        }
    },
    headerGrid: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        marginBottom: '3%'
    }
}));

interface StepperProps {
    content: Exercise;
    currentStep: React.Dispatch<React.SetStateAction<number>>;
    patientArray?: string[] | null;
    relationship?: string;
}

export function ExerciseMessage({
    content,
    currentStep,
    patientArray,
    relationship
}: StepperProps): JSX.Element {
    const isSmallWidth = !useMediaQuery('(min-width:598px)');
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [openModal, setOpenModal] = useState<boolean>(false);
    const { t } = useTranslation();
    const classes = useStyles();
    const [activeStep, setStep] = useState<number>(3);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [patientInfo, setPatientInfo] = useState<Patient | null>(null);
    const [relationshipEstablished, setRelationshipEstablished] = useState<string>('');
    const [data, setData] = useState<Exercise>({
        id: content.id,
        exerciseType: content.exerciseType,
        patientList: content.patientList,
        exerciseName: content.exerciseName,
        authorId: content.authorId,
        exerciseImages: content.exerciseImages,
        review: content.review,
        encouragementMessage: content.encouragementMessage,
        congratulationsMessage: content.congratulationsMessage,
        rewardPhoto: content.rewardPhoto,
        rewardVideo: content.rewardVideo,
        establishmentCode: content.establishmentCode,
        establishmentList: content.establishmentList,
        familyCode: content.familyCode,
        theme: content.theme
    });

    const handleChange = (state: string, value: string | undefined | null | File): void => {
        setData({ ...data, [state]: value });
    };

    const [isKeybord, setIsKeybord] = useState<boolean>(false);
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    );

    const getSenderName = (type: string): string => {
        if (user?.role !== Roles.ADMIN && user) {
            if (type === 'male') {
                return `${t('yourForMale')} ${relationship}, ${user?.firstName} ${user?.lastName}`;
            }
            if (type === 'carer' && user?.role === Roles.THERAPIST) {
                return `${t('your')} ${user.fonction}, ${user?.firstName} ${user?.lastName}`;
            }
            if (type === 'carer') {
                return `${t('your')} ${relationship}, ${user?.firstName} ${user?.lastName}`;
            }
            if (type === 'friend') {
                return `${t('neighbour')}, ${user?.firstName} ${user?.lastName}`;
            }
            return `${t('yourForFemale')} ${relationship}, ${user?.firstName} ${user?.lastName}`;
        }
        if (user?.role === Roles.ADMIN && user) {
            return `${user.firstName}, ${t('stimulinTeam')}`;
        }
        return '';
    };

    const getEncouragement = (type: string, sex?: string): string => {
        if (type === 'carer' && user?.role === Roles.THERAPIST) {
            return `${t('encouragementDefaultMessage')}\n${getSenderName('carer')}`;
        }
        if (type === 'carer' && user?.role === Roles.ADMIN) {
            return `${t('hello')},\n${t('encouragmentMessageByCarer')}. ${t(
                'hopeYouEnjoyByCarer'
            )}.\n${t('seeYouSoon')}\n${getSenderName('carer')}`;
        }
        if (type === 'carer') {
            return `${relationshipEstablished}\n${t('encouragmentMessageByCarer')}. ${t(
                'hopeYouEnjoyByCarer'
            )}.\n${t('seeYouSoon')}\n${getSenderName('carer')}`;
        }
        if (type === 'friend') {
            return `${relationshipEstablished}\n${t('encouragmentMessageByCarer')}. ${t(
                'hopeYouEnjoyByCarer'
            )}.\n${t('seeYouSoon')}\n${getSenderName('friend')}`;
        }
        if (type === 'family' && sex === 'male') {
            return `${relationshipEstablished}\n${t('encouragmentMessageByFamily')}. ${t(
                'hopeYouEnjoyByFamily'
            )}.\n${t('seeYouSoon')}\n${getSenderName('male')}`;
        }
        if (type === 'family' && sex === 'female') {
            return `${relationshipEstablished}\n${t('encouragmentMessageByFamily')}. ${t(
                'hopeYouEnjoyByFamily'
            )}.\n${t('seeYouSoon')}\n${getSenderName('female')}`;
        }
        return '';
    };

    const getCongratulation = (type: string, sex?: string): string => {
        if (type === 'carer' && user?.role === Roles.THERAPIST) {
            return `${t('congratulationDefaultMessage')} \n${getSenderName('carer')}`;
        }
        if (type === 'carer') {
            return `${t('bravoForRealization')} ${relationshipEstablished} ! \n${t(
                'hopeYouLikeByCarer'
            )}\n${t('seeYouSoon')}\n${getSenderName('carer')}`;
        }
        if (type === 'friend') {
            return `${t('bravoForRealization')} ${relationshipEstablished} ! \n${t(
                'hopeYouLikeByCarer'
            )}\n${t('seeYouSoon')}\n${getSenderName('friend')}`;
        }
        if (type === 'family' && sex === 'male') {
            return `${t('bravoForRealization')} ${relationshipEstablished} !\n${t(
                'congratulationMessageByFamily'
            )}\n${t('seeYouSoon')}\n${getSenderName('male')}`;
        }
        if (type === 'family' && sex === 'female') {
            return `${t('bravoForRealization')} ${relationshipEstablished} !\n${t(
                'congratulationMessageByFamily'
            )}\n${t('seeYouSoon')}\n${getSenderName('female')}`;
        }
        return '';
    };

    const getGreeting = async (relation: string): Promise<void> => {
        if (patientArray) {
            const patientData = await getPatientUser(patientArray[0]);
            setPatientInfo(patientData);
            if (patientData) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const relationshipDictionary: any = {
                    'Animateur(trice)': `${patientData?.sex === 'Femme' ? t('madame') : t('sir')} ${
                        patientData.lastName
                    }`,
                    'Arrière-petit-fils': `${
                        patientData?.sex === 'Femme' ? t('grandMother') : t('grandFather')
                    }`,
                    'Arrière-petite-fille': `${
                        patientData?.sex === 'Femme' ? t('grandMother') : t('grandFather')
                    }`,
                    'Autre résident / patient': `${
                        patientData?.sex === 'Femme' ? t('madame') : t('sir')
                    } ${patientData.lastName}`,
                    'Belle-Fille': `${patientData?.firstName}`,
                    'Conjoint(e)': `${patientData?.firstName}`,
                    Cousin: `${patientData?.firstName}`,
                    Cousine: `${patientData?.firstName}`,
                    Fille: `${patientData?.sex === 'Femme' ? t('mom') : t('daddy')}`,
                    Fils: `${patientData?.sex === 'Femme' ? t('mom') : t('daddy')}`,
                    Frère: `${patientData?.firstName}`,
                    'Beau-fils': `${patientData?.firstName}`,
                    Mère: `${patientData?.firstName}`,
                    Neveu: `${patientData?.firstName}`,
                    Nièce: `${patientData?.firstName}`,
                    Père: `${patientData?.firstName}`,
                    'Personnel soignant': `${
                        patientData?.sex === 'Femme' ? t('madame') : t('sir')
                    } ${patientData.lastName}`,
                    'Petit-fils': `${
                        patientData?.sex === 'Femme' ? t('grandMother') : t('grandFather')
                    }`,
                    'Petite-fille': `${
                        patientData?.sex === 'Femme' ? t('grandMother') : t('grandFather')
                    }`,
                    Soeur: `${patientData?.firstName}`,
                    Intervenant: `${patientData?.sex === 'Femme' ? t('madame') : t('sir')} ${
                        patientData.lastName
                    }`
                };
                setRelationshipEstablished(relationshipDictionary[relation]);
            }
        }
    };

    const getEncouragementMessageByFamily = (relation: string): string => {
        let message = '';
        if (patientInfo && relationship) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const relationshipDictionary: any = {
                'Animateur(trice)': getEncouragement('carer'),
                'Arrière-petit-fils': getEncouragement('family', 'male'),
                'Arrière-petite-fille': getEncouragement('family', 'male'),
                'Autre résident / patient': getEncouragement('friend'),
                'Belle-Fille': getEncouragement('carer'),
                'Conjoint(e)': getEncouragement('family', 'female'),
                Cousin: getEncouragement('family', 'male'),
                Cousine: getEncouragement('family', 'female'),
                Fille: getEncouragement('family', 'female'),
                Fils: getEncouragement('family', 'male'),
                Frère: getEncouragement('family', 'male'),
                'Beau-fils': getEncouragement('carer'),
                Mère: getEncouragement('family', 'female'),
                Neveu: getEncouragement('family', 'male'),
                Nièce: getEncouragement('family', 'female'),
                Père: getEncouragement('family', 'male'),
                'Personnel soignant': getEncouragement('carer'),
                'Petit-fils': getEncouragement('family', 'male'),
                'Petite-fille': getEncouragement('family', 'female'),
                Soeur: getEncouragement('family', 'female'),
                Intervenant: getEncouragement('carer')
            };
            message = relationshipDictionary[relation];
        }
        return message;
    };

    const getCongratulationMessageByFamily = (relation: string): string => {
        let message = '';
        if (patientInfo) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const relationshipDictionary: any = {
                'Animateur(trice)': getCongratulation('carer'),
                'Arrière-petit-fils': getCongratulation('family', 'male'),
                'Arrière-petite-fille': getCongratulation('family', 'male'),
                'Autre résident / patient': getCongratulation('friend'),
                'Belle-Fille': getCongratulation('carer'),
                'Conjoint(e)': getCongratulation('family', 'female'),
                Cousin: getCongratulation('family', 'male'),
                Cousine: getCongratulation('family', 'female'),
                Fille: getCongratulation('family', 'female'),
                Fils: getCongratulation('family', 'male'),
                Frère: getCongratulation('family', 'male'),
                'Beau-fils': getCongratulation('carer'),
                Mère: getCongratulation('family', 'female'),
                Neveu: getCongratulation('family', 'male'),
                Nièce: getCongratulation('family', 'female'),
                Père: getCongratulation('family', 'male'),
                'Personnel soignant': getCongratulation('carer'),
                'Petit-fils': getCongratulation('family', 'male'),
                'Petite-fille': getCongratulation('family', 'female'),
                Soeur: getCongratulation('family', 'female'),
                Intervenant: getCongratulation('carer')
            };
            message = relationshipDictionary[relation];
        }
        return message;
    };

    useEffect(() => {
        if (data.congratulationsMessage && data.encouragementMessage) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [data.congratulationsMessage, data.encouragementMessage]);

    useEffect(() => {
        if (
            relationshipEstablished !== '' &&
            content &&
            user &&
            user.firstName &&
            user.lastName &&
            patientArray &&
            relationship &&
            patientInfo
        ) {
            const defaultEncouragmentMessageByFamily = `${t(
                'hello'
            )} ${getEncouragementMessageByFamily(relationship)}`;
            const defaultCongratulationMessageByFamily = `${getCongratulationMessageByFamily(
                relationship
            )}`;
            setData({
                ...data,
                patientList:
                    user.role === Roles.FAMILY && patientArray && patientArray.length > 0
                        ? patientArray
                        : content.patientList,
                encouragementMessage:
                    content.encouragementMessage === undefined ||
                    content.encouragementMessage === ''
                        ? defaultEncouragmentMessageByFamily
                        : content.encouragementMessage,
                congratulationsMessage:
                    content.congratulationsMessage === undefined ||
                    content.congratulationsMessage === ''
                        ? defaultCongratulationMessageByFamily
                        : content.congratulationsMessage
            });
        }
    }, [relationshipEstablished]);

    useEffect(() => {
        if (user?.role === Roles.FAMILY) {
            setStep(2);
        }
        if (content && user && user.firstName && user.lastName && patientArray && relationship) {
            getGreeting(relationship);
        } else if (user?.role === Roles.THERAPIST || user?.role === Roles.ADMIN) {
            if (content && user && user.firstName && user.lastName) {
                const defaultEncouragmentMessageByTherapist = getEncouragement('carer');
                const defaultCongratulationMessageByTherapist = getCongratulation('carer');
                setData({
                    ...data,
                    encouragementMessage:
                        content.encouragementMessage === undefined ||
                        content.encouragementMessage === ''
                            ? defaultEncouragmentMessageByTherapist
                            : content.encouragementMessage,
                    congratulationsMessage:
                        content.congratulationsMessage === undefined ||
                        content.congratulationsMessage === ''
                            ? defaultCongratulationMessageByTherapist
                            : content.congratulationsMessage
                });
            }
        }
    }, []);

    const getLabel = (
        firstMessage: string,
        secondMessage: string,
        dataChanged: string | undefined,
        valueToChange: string
    ): JSX.Element => {
        return (
            <div>
                {isSmallWidth ? (
                    <div className={classes.headerGrid}>
                        <Typography color="#ACBCC7">{' - '}</Typography>
                        <Box>
                            <Typography variant="h6" sx={{ fontWeight: 500, lineHeight: '0.7' }}>
                                {firstMessage}
                            </Typography>
                            <Typography
                                variant="body1"
                                color="#ACBCC7"
                                sx={{ fontWeight: 500, fontStyle: 'italic' }}>
                                {secondMessage}
                            </Typography>
                        </Box>
                        <Typography color="#ACBCC7">{' - '}</Typography>
                    </div>
                ) : (
                    <div>
                        <Typography color="#374653" variant="body1" sx={{ textAlign: 'left' }}>
                            {firstMessage}
                        </Typography>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>
                            <Typography
                                color="#ACBCC7"
                                variant="caption"
                                sx={{ textAlign: 'left', fontStyle: 'italic' }}>
                                {secondMessage}
                            </Typography>
                        </div>
                    </div>
                )}
                <InputComponent
                    placeholder=""
                    value={dataChanged ?? null}
                    type="text"
                    isMultiline
                    onTextChange={(value: string): void => handleChange(valueToChange, value)}
                    className=""
                    onBlur={(): void => setIsKeybord(false)}
                    onFocus={(): void => setIsKeybord(true)}
                    noOfRows={6}
                    maximumLength={250}
                />
                <Typography color="#ACBCC7" variant="body1" sx={{ textAlign: 'right' }}>
                    {t('250CharacterMaximum')}
                </Typography>
            </div>
        );
    };

    const getMessagesFields = (): JSX.Element | null => {
        return (
            <Box>
                <Grid container sx={{ width: '100%', margin: 'auto' }}>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                            padding: '1%',
                            marginTop: isSmallWidth ? '4%' : '2%'
                        }}
                        key={1}>
                        {getLabel(
                            t('messageOfEncouragement'),
                            t('deliveredBeforeExercise'),
                            data.encouragementMessage,
                            'encouragementMessage'
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                            padding: '1%',
                            marginTop: isSmallWidth ? '4%' : '2%'
                        }}
                        key={2}>
                        {getLabel(
                            t('congratulationMessage'),
                            t('deliveredAfterExercise'),
                            data.congratulationsMessage,
                            'congratulationsMessage'
                        )}
                    </Grid>
                </Grid>
            </Box>
        );
    };

    const screen = (
        <div>
            <Box sx={{ boxShadow: 'inset 0px -1px 0px #E3E6E8' }}>
                <Header
                    setOpenModal={setOpenModal}
                    type={data.exerciseType}
                    activeStep={activeStep}
                    user={user}
                />
            </Box>
            <Box
                sx={{
                    backgroundColor: '#F8F9F9',
                    boxShadow: 'inset 0px -1px 0px rgba(172, 188, 199, 0.4)'
                }}>
                <TitleAndIntro
                    type={data.exerciseType}
                    activeStep={activeStep}
                    user={user}
                    icon={messageIcon}
                    body={t('attachMessage')}
                    endBody={t('sendMessageToPatient')}
                />
            </Box>
            <div className={classes.secondStepContainer}>{getMessagesFields()}</div>
            <div style={{ height: window.innerWidth < 600 ? '190px' : '81px', width: '100%' }} />
            <Box
                style={{
                    position: isMobile && isKeybord ? 'inherit' : 'fixed',
                    zIndex: '2',
                    bottom: '0',
                    width: '100%',
                    backgroundColor: '#FFFFFF'
                }}>
                <ButtonsCreateAndEdit
                    disabled={disabled}
                    nextText={t('nextStep')}
                    nextClick={(): void => {
                        if (user && user?.role === Roles.FAMILY) {
                            setStep(activeStep + 2);
                        } else {
                            setStep(activeStep + 1);
                        }
                    }}
                    previousClick={(): void => {
                        setStep(activeStep - 1);
                        currentStep(activeStep - 1);
                    }}
                />
            </Box>
        </div>
    );

    return (
        <div>
            {activeStep === 2 && user?.role === Roles.FAMILY && screen}
            {activeStep === 3 &&
                (user?.role === Roles.ADMIN || user?.role === Roles.THERAPIST) &&
                screen}
            {activeStep === 4 ? <ExerciseMedia content={data} currentStep={setStep} /> : null}
            <ExitCustomizationModal openModal={openModal} setOpenModal={setOpenModal} />
        </div>
    );
}
