import React, { useState, useContext } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Button, Box, MenuItem, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';
import { MenuPopover } from '../MenuPopover';
import { logOut } from '../../services/firebaseAuth';
import { UserContext } from '../../providers/UserProvider';
import { DeactiveModal } from '../DeactiveModal';
import { UserDataProp } from '../../models/userDataProp';

// ----------------------------------------------------------------------

export function AccountPopover(): React.ReactElement {
    const { t } = useTranslation();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const username = `${user && user.firstName} ${user && user.lastName}`;
    const [open, setOpen] = useState(false);
    const [logout, setLogout] = useState<boolean>(false);
    const { pathname } = useLocation();

    const handleOpen = (): void => {
        setOpen(true);
    };
    const handleClose = (): void => {
        setOpen(false);
    };

    return (
        <>
            <DeactiveModal
                isModelOpen={logout}
                setModelOpen={setLogout}
                title={t('logout')}
                bodyText={t('confirmLogout')}
                buttonText={t('logout')}
                onButtonClick={(): void => {
                    logOut(user);
                }}
            />
            <Button
                onClick={handleOpen}
                sx={{
                    padding: 0,
                    height: 44,
                    color: '#1E2933',
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            position: 'absolute'
                        }
                    })
                }}>
                <Typography variant="h6" noWrap>
                    {username}
                </Typography>
                <KeyboardArrowDownIcon />
            </Button>

            <MenuPopover open={open} onClose={handleClose}>
                <MenuItem
                    key="profile"
                    to="/profile"
                    component={RouterLink}
                    onClick={handleClose}
                    sx={{ typography: 'body1', py: 1, px: 2.5 }}>
                    {t('profile')}
                </MenuItem>
                <MenuItem
                    key="logout"
                    to={pathname}
                    component={RouterLink}
                    onClick={(): void => {
                        setLogout(true);
                    }}
                    sx={{
                        typography: 'body1',
                        py: 1,
                        px: 2.5,
                        color: '#C22A29'
                    }}>
                    {t('logout')}
                </MenuItem>
            </MenuPopover>
        </>
    );
}
