import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material';
import squareCloseIcon from '../../assets/squareClose.png';

interface IdentifyPersonModalProps {
    isModelOpen: boolean;
    setModelOpen: Function;
    body: JSX.Element;
    onCancel: Function;
}

const Logo = styled('img')({
    objectFit: 'inherit',
    width: '48px',
    height: '48px',
    marginLeft: 'none'
});

const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: '24px',
    boxShadow: 24,
    p: 2
};

const RootModal = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        marginRight: '8%',
        minWidth: '325px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        minWidth: '530px',
        height: '90vh',
        overflow: 'scroll'
    },
    [theme.breakpoints.up('md')]: {
        minWidth: '530px'
    }
}));

export function IdentifyPersonModal({
    isModelOpen,
    setModelOpen,
    body,
    onCancel
}: IdentifyPersonModalProps): JSX.Element {
    const bodyContents = (
        <RootModal sx={style}>
            <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
                <Logo
                    src={squareCloseIcon}
                    alt="squareCloseIcon"
                    onClick={(): void => {
                        onCancel();
                    }}
                />
                {body}
            </Box>
        </RootModal>
    );

    return (
        <div>
            <Modal
                open={isModelOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                {bodyContents}
            </Modal>
        </div>
    );
}
