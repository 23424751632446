import React, { useContext, useEffect, useState } from 'react';
import { Box, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Person } from '@mui/icons-material';
import { styled } from '@mui/styles';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { signIn } from '../services/firebaseAuth';
import { UserContext } from '../providers/UserProvider';
import { UserDataProp } from '../models/userDataProp';
import { Roles } from '../models/Roles';
import { clickAnalytics } from '../services/analyticsFunction';
import { HomeHeader } from '../components/HomeHeader';
import { validateEmail } from '../utilities/utils';

interface LoginState {
    email: string;
    password: string;
    showPassword: boolean;
}

const Title = styled(Typography)({
    display: 'flex',
    alignContent: 'space-around',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 950px) and (max-height: 700px)': {
        marginTop: '6%'
    }
});

export function Login(): JSX.Element {
    const { t } = useTranslation();
    const history = useHistory();

    const [values, setValues] = useState({
        email: '',
        password: '',
        showPassword: false,
        prop: ''
    });
    const [buttonEnabled, setButtonEnabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;

    useEffect(() => {
        if (user && user.role) {
            clickAnalytics('login', user);
            if (user.isChangePasswordRequired && user.role !== Roles.PATIENT) {
                history.push('/change-password');
            } else {
                history.push('/home');
            }
        }
    }, [user]);

    useEffect((): void => {
        if (values.email && values.password) {
            setButtonEnabled(true);
        } else {
            setButtonEnabled(false);
        }
    }, [values]);

    const handleChange = (prop: keyof LoginState) => (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        if (prop === 'email' && !validateEmail(event.target.value.trim())) {
            setValues({ ...values, [prop]: `${event.target.value.trim()}@stimulin.fr` });
        } else {
            setValues({ ...values, [prop]: event.target.value.trim() });
        }
    };

    const handleClickShowPassword = (): void => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const onLogin = (): void => {
        setError(false);
        if (values.email && values.password) {
            setLoading(true);
            signIn(values.email, values.password).catch((): void => {
                setLoading(false);
                setError(true);
            });
        }
    };

    const onForgotPasswordClicked = (): void => {
        history.push('/forgot-password');
    };

    return (
        <Box flex={1} display="flex" flexDirection="column" height="100vh">
            <HomeHeader />
            <Box maxWidth={800} margin="auto" px={2}>
                <Title variant="h3">
                    <Person fontSize="inherit" />
                    <Typography variant="h3" sx={{ marginLeft: '5px' }}>
                        {t('loginTitle')}
                    </Typography>
                </Title>
                <TextField
                    type="email"
                    placeholder={t('emailPlaceholder')}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    error={error}
                    onChange={handleChange('email')}
                />
                <OutlinedInput
                    id="password"
                    fullWidth
                    type={values.showPassword ? 'text' : 'password'}
                    value={values.password}
                    error={error}
                    placeholder={t('password')}
                    onChange={handleChange('password')}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                edge="end">
                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
                <LoadingButton
                    fullWidth
                    loading={loading}
                    variant="outlined"
                    disabled={!buttonEnabled}
                    size="large"
                    sx={{ marginTop: 5 }}
                    onClick={(): void => onLogin()}>
                    {t('logIn')}
                </LoadingButton>
                {error ? (
                    <Typography variant="body1" color="error">
                        {t('loginError')}
                    </Typography>
                ) : null}
                <Button
                    disableElevation
                    sx={{ marginTop: '32px' }}
                    variant="contained"
                    size="medium"
                    onClick={onForgotPasswordClicked}>
                    {t('forgetPassword')}
                </Button>
                <Typography variant="body1" my={4}>
                    <span>{t('noAccountYet')} </span>
                    <span style={{ fontWeight: '700' }}>{t('noAccountYetCodeF')} ?</span>
                    <br />
                    <span>
                        <Typography
                            sx={{ color: '#FF0089', fontWeight: '600' }}
                            onClick={(): void => {
                                history.push('/register');
                            }}>
                            <span>{t('noAccountYetLink1')}</span>
                            <span style={{ textDecoration: 'underline' }}>{t('here')}</span>
                            <span>{t('noAccountYetLink2')}</span>
                        </Typography>
                    </span>
                </Typography>
                <Box sx={{ display: 'flex' }}>
                    <Typography variant="body1" style={{ textAlign: 'left', fontSize: '0.75rem' }}>
                        <span>{t('disclaimer')} </span>
                        <span>
                            <Link
                                color="secondary"
                                href="https://app.stimulin.fr/Cgu.pdf"
                                target="_blank"
                                rel="noreferrer">
                                {t('generalConditions')}
                            </Link>
                        </span>
                        <span> {t('and')} </span>
                        <span>
                            <Link
                                color="secondary"
                                href="https://app.stimulin.fr/Confidential.pdf"
                                target="_blank"
                                rel="noreferrer">
                                {t('confidential')}
                            </Link>
                        </span>
                        <span> {t('stimulinApp')}</span>
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}
