/* eslint-disable import/no-default-export */
/* eslint-disable import/no-useless-path-segments */
import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';

// import { Button } from '../../components';
import { Box, Button, Typography } from '@mui/material';
import { createGrid } from '../../store';
import { Container } from './styles/index';

const NewGameButton: FC = () => {
    const dispatch = useDispatch<Dispatch<Action>>();
    const { t } = useTranslation();

    const createNewGrid = useCallback(() => {
        // if (window.confirm('Are you sure you want to start a new game?')) dispatch(createGrid());
        dispatch(createGrid());
    }, [dispatch]);

    return (
        // <Container>
        //     <Button onClick={createNewGrid}>{t('NewGame')}</Button>
        // </Container>
        <Button
            variant="contained"
            color="primary"
            onClick={createNewGrid}
            sx={{
                width: '100%',
                height: '48px',
                background:
                    'linear-gradient(0deg, rgba(208, 0, 112, 0.02), rgba(208, 0, 112, 0.02)), #FFFFFF',
                border: '0.672131px solid #8B004B',
                boxShadow: '0px 0px 0px 1.34426px rgba(208, 0, 112, 0.4)',
                borderRadius: '8.06557px',
                '&:hover': {
                    background:
                        'linear-gradient(0deg, rgba(208, 0, 112, 0.02), rgba(208, 0, 112, 0.02)), #FFFFFF'
                }
            }}>
            <Typography
                variant="h3"
                sx={{
                    color: '#D00070',
                    fontFamily: 'Luciole-Regular',
                    fontSize: '24px !important',
                    fontWeight: '600 !importants'
                }}>
                {t('NewGame')}
            </Typography>
        </Button>
    );
};

export default NewGameButton;
