import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { TableLayout } from '../../layouts/TableLayout';
import { EstablishmentsExportData } from '../../models/EstablishmentsExportData';
import { FamilyViewData } from '../../models/FamilyViewData';
import {
    getFamilyExportData,
    getFamilyList,
    getPatientFromCode
} from '../../services/cloudFirestore';
import { convertToTitleCase, getDateFromTimestamp, getLegalReferant } from '../../utilities/utils';

export function Families(): JSX.Element {
    const { t } = useTranslation();
    const history = useHistory();
    const [familyList, setFamilyList] = useState<FamilyViewData[] | null>(null);
    const [familyExportData, setFamilyExportData] = useState<EstablishmentsExportData[]>([]);

    const columns = [
        { field: 'establishmentName', minWidth: 140, flex: 1, headerName: t('instituitionName') },
        { field: 'firstName', minWidth: 100, flex: 1, headerName: t('firstName') },
        { field: 'lastName', minWidth: 100, flex: 1, headerName: t('name') },
        { field: 'dob', minWidth: 140, flex: 1, headerName: t('dateOfBirth') },
        { field: 'emailId', minWidth: 200, flex: 1, headerName: t('emailPlaceholder') },
        { field: 'patient', minWidth: 120, flex: 1, headerName: t('patient') },
        { field: 'relationship', minWidth: 140, flex: 1, headerName: t('relationship') },
        { field: 'legalReferent', minWidth: 150, flex: 1, headerName: t('legalReferent') },
        { field: 'familyCode', minWidth: 150, flex: 1, headerName: t('familyCode') },
        { field: 'status', minWidth: 120, flex: 1, headerName: t('status') }
    ];

    const onAddButtonClicked = (): void => {
        history.push('/family/add');
    };

    const fetchFamilyList = async (): Promise<void> => {
        const family = await getFamilyList();
        const familyViewData: FamilyViewData[] = family;
        family.forEach((item, index) => {
            if (item.dob) {
                familyViewData[index].dob = getDateFromTimestamp(item.dob);
            }
            if (item.status) {
                familyViewData[index].status = convertToTitleCase(item.status);
            }
            familyViewData[index].legalReferent = getLegalReferant(item.legalReferent);
        });
        setFamilyList(familyViewData);
        const exportData = await getFamilyExportData();
        setFamilyExportData(exportData);
    };

    useEffect(() => {
        if (familyList === null || familyList === undefined) {
            fetchFamilyList();
        }
    });

    return (
        <TableLayout
            title={t('familles')}
            addButtonTitle={t('addFamily')}
            onAddButtonClicked={onAddButtonClicked}
            columns={columns}
            rows={familyList === undefined || familyList === null ? [] : familyList}
            exportData={familyExportData}
            nameOfFile={t('familles')}
        />
    );
}
