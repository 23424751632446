/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useContext, useEffect, useState } from 'react';
import { blue } from '@mui/material/colors';
import { Avatar, Card, CssBaseline, Grid, Box, Button, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { createStyles, makeStyles, styled } from '@mui/styles';
import { ExerciseHeader } from '../../../components/ExerciseHeader';
import { SuccessModal } from '../../../components/SuccessModal';
import { FormModalAlert } from '../../common/FormModalAlert';
import { ReviewExerciseAlerts } from '../../common/ReviewExerciseAlerts';
import {
    getExerciseItem,
    getPatientUser,
    getTherapistUser
} from '../../../services/cloudFirestore';
import { UserContext } from '../../../providers/UserProvider';
import { Patient } from '../../../models/Patient';
import { Exercise } from '../../../models/Exercise';
import { ExerciseItem } from '../../../models/ExerciseItem';
import { getImageKitUrlFrom } from '../../../utilities/utils';
import { Therapist } from '../../../models/Therapist';
import { Roles } from '../../../models/Roles';
import { UserDataProp } from '../../../models/userDataProp';
import { ImageSlicer } from './reorder1';
import { ExerciseType } from '../../../models/ExerciseType';

const useStyles = makeStyles(() =>
    createStyles({
        profileImage: {
            display: 'inline',
            marginRight: '10px',
            width: '90px',
            height: '90px',
            alignSelf: 'center',
            justifySelf: 'center',
            objectFit: 'cover'
        },
        currentUserInitials: {
            display: 'flex',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            margin: '0'
        },
        itemFadeout: {
            animation:
                '$fadeout 3s linear forwards, $shake 0.82s cubic-bezier(.36,.07,.19,.97) both'
        },
        itemShake: {
            animation: '$shake 0.82s cubic-bezier(.36,.07,.19,.97) both'
        },
        endAnimation: {
            animation: '$endAnimation 6s'
        },
        '@keyframes fadeout': {
            '0%': { opacity: 1 },
            '100%': { opacity: 0 }
        },
        '@keyframes shake': {
            '10%, 90%': {
                transform: 'translate3d(-1px, 0, 0)'
            },
            '20%, 80%': {
                transform: 'translate3d(2px, 0, 0)'
            },
            '30%, 50%, 70%': {
                transform: 'translate3d(-4px, 0, 0)'
            },
            '40%, 60%': {
                transform: 'translate3d(4px, 0, 0)'
            }
        },
        '@keyframes endAnimation': {
            '0%': { width: '0%' },
            '45%': { width: '50%' },
            '55%': { width: '50%' },
            '100%': { width: '0%' }
        }
    })
);

const Root = styled('div')({
    display: 'flex',
    alignContent: 'space-around',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 0,
    marginLeft: '2%',
    marginTop: '2%',
    marginRight: '2%',
    minWidth: '90%',
    maxHeight: '100%'
});

const ImageContainer = styled('img')({
    maxHeight: 'calc(100vh - 120px - 16vh - 16px)',
    maxWidth: 'calc(100vh - 120px - 16vh - 16px)',
    height: 'calc(50vw - 60px - 16px)',
    width: 'calc(50vw - 60px - 16px)',
    objectFit: 'cover'
});

const DefaultLogo = styled('div')({
    borderRadius: '50%',
    background: '#efe7d7',
    position: 'relative'
});

interface LocationState {
    author: Therapist;
    exercises: Exercise;
    difficultyLevel: number;
    exerciseDone?: boolean;
}

export function Reorder(): JSX.Element {
    const classes = useStyles();
    const { t } = useTranslation();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [initials, setInitials] = useState<string>('');
    const location = useLocation<LocationState>();
    const [exercise, setExercise] = useState<Exercise>();
    const [isFirework, setIsFirework] = useState<boolean>(false);
    const [exerciseItem, setExerciseItem] = useState<ExerciseItem>();
    const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
    const [openFormModal, setOpenFormModal] = useState<boolean>(false);
    const [openReview, setOpenReview] = useState<boolean>(false);
    const [reorderImage, setReorderImage] = useState<string>();
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [nbModal, setNbModal] = useState<number>(0);
    const [difficultyLevel, setDifficultyLevel] = useState<number>(1);
    const [isLastOption, setIsLastOption] = useState<boolean>(false);
    const [isMemorization, setIsMemorization] = useState<boolean>(true);
    const [actualNumber, setActualNumber] = useState<number>(0);
    const [endFrame, setEndFrame] = useState<boolean>(false);
    const [validNumber, setValidNumber] = useState<boolean[]>([]);
    const [isPlayingSound, setIsPlayingSound] = useState<boolean>(false);
    const [isGameLoaded, setIsGameLoaded] = useState<boolean>(false);
    const [itemToShake, setItemToShake] = useState<string>('');
    const numberOfSlices = [2, 3, 4, 5, 5, 5, 5, 5, 5, 6];
    const intruderNumber = [0, 0, 0, 0, 1, 2, 3, 4, 4, 5];
    const [slices, setSlices] = useState<
        { key: number; src: string | undefined; alt: string; activeClue: boolean }[]
    >([]);
    const [author, setAuthor] = useState<Therapist>({
        firstName: undefined,
        lastName: undefined,
        emailId: undefined,
        dob: undefined,
        fonction: undefined,
        establishmentName: undefined,
        mobile: undefined,
        firstStepFormFields: [],
        secondStepFormFields: []
    });
    const [patient, setPatient] = useState<Patient>({
        firstName: undefined,
        lastName: undefined,
        emailId: undefined,
        dob: undefined,
        sex: undefined,
        level: undefined,
        category: undefined,
        familyCode: undefined,
        legalReferent: undefined,
        establishmentName: undefined,
        establishmentCode: undefined,
        startDate: undefined,
        endDate: undefined,
        address: undefined,
        pincode: undefined,
        city: undefined,
        familyMembers: undefined,
        memoryDifficultyLevel: undefined,
        puzzleDifficultyLevel: undefined,
        completedExerciseList: undefined,
        firstStepFormFields: [],
        secondStepFormFields: []
    });
    const [reorderError, setReorderError] = useState<number>(0);
    const [reorderClue, setReorderClue] = useState<number>(0);
    const [timerId, setTimerId] = useState<NodeJS.Timeout>();

    const checkLastOption = () => {
        if (
            actualNumber ===
                numberOfSlices[difficultyLevel - 1] - intruderNumber[difficultyLevel - 1] - 1 &&
            difficultyLevel < 8
        ) {
            setIsLastOption(true);
        }
    };

    useEffect(() => {
        let tmpTimerId;
        checkLastOption();

        if (isMemorization) {
            tmpTimerId = setTimeout(() => {
                setIsMemorization(false);
            }, 10000);
            setTimerId(tmpTimerId);
        } else if (timerId && !isMemorization) {
            clearTimeout(timerId);
        }
    }, [isMemorization]);

    useEffect(() => {
        if (user?.role === Roles.PATIENT) {
            const startTimer = localStorage.getItem('startTime');
            const count = localStorage.getItem('countErrorAndClue');
            if (!startTimer) {
                localStorage.setItem('startTime', new Date().toString());
            }
            if (!count) {
                localStorage.setItem('countErrorAndClue', '0');
            }
        }
    }, []);

    useEffect(() => {
        const getExerciseItems = async (): Promise<void> => {
            if (location.state.exercises.authorId) {
                const authorData = await getTherapistUser(location.state.exercises.authorId);
                if (authorData) {
                    setAuthor(authorData);
                    if (authorData.firstName && authorData.lastName) {
                        setInitials(
                            authorData.firstName.charAt(0).toUpperCase() +
                                authorData.lastName.charAt(0).toUpperCase()
                        );
                    }
                }
            }
            if (location.state.difficultyLevel) {
                setDifficultyLevel(location.state.difficultyLevel);
            }
            if (location.state.exercises) {
                setExercise(location.state.exercises);
            }
            if (
                location.state.exercises.exerciseImages &&
                location.state.exercises.exerciseImages[0] &&
                typeof location.state.exercises.exerciseImages[0] === 'string'
            ) {
                const item = await getExerciseItem(location.state.exercises.exerciseImages[0]);
                if (item) {
                    setExerciseItem(item);
                    if (item.exerciseImage && typeof item.exerciseImage === 'string') {
                        setReorderImage(item.exerciseImage);
                    }
                }
            }
        };

        getExerciseItems();
        if (location.state.exerciseDone === true) {
            setOpenReview(true);
            setTimeout(() => {
                setOpenFormModal(false);
            }, 1500);
        }
    }, [location.state]);

    const handleClueClick = (): void => {
        if (difficultyLevel < 8) {
            if (
                actualNumber <
                numberOfSlices[difficultyLevel - 1] - intruderNumber[difficultyLevel - 1] - 1
            ) {
                const newState = slices.map((slice) => {
                    if (slice.key === actualNumber) {
                        return { ...slice, activeClue: true };
                    }
                    return slice;
                });
                setSlices(newState);
                setReorderClue(reorderClue + 1);
            }
        } else {
            const newSlices = [...slices];
            if (newSlices.length > 1) {
                const index = newSlices.findIndex((item) => Math.trunc(item.key / 100) === 1);
                if (newSlices.length === 3) {
                    setIsLastOption(true);
                }

                if (index !== -1) {
                    setItemToShake(slices[index].alt);
                    setTimeout(() => {
                        setItemToShake('');
                        newSlices.splice(index, 1);
                        setReorderClue(reorderClue + 1);
                        setSlices(newSlices);
                    }, 3100);
                }
            }
        }
    };

    useEffect(() => {
        const getExerciseList = async (): Promise<void> => {
            if (user && user?.id) {
                const patientData = await getPatientUser(user?.id);
                if (patientData && patientData.reorderDifficultyLevel) {
                    setPatient(patientData);
                    setDifficultyLevel(patientData.reorderDifficultyLevel);
                }
                if (user?.role === Roles.ADMIN || user.role === Roles.THERAPIST) {
                    setDifficultyLevel(3);
                }
            }
        };
        getExerciseList();
    }, []);

    const handleCloseFormModal = (): void => {
        setOpenFormModal(false);
    };

    const isHeightOfXS = (): boolean => {
        const { innerWidth: width, innerHeight: height } = window;
        if (width < 550 || height < 550) {
            return true;
        }
        if (height <= 768 && height > 551) {
            return true;
        }
        return false;
    };

    function handleGoBackFunction() {
        setIsMemorization(true);
    }

    useEffect(() => {
        checkLastOption();
        if (actualNumber === 1 && difficultyLevel < 8) {
            setIsPlayingSound(true);
            setNbModal(1);
            setTimeout(() => {
                setIsSuccess(true);
                setOpenSuccessModal(true);
                setTimeout(() => {
                    setIsPlayingSound(false);
                    setOpenSuccessModal(false);
                }, 3000);
            }, 1000);
        }

        if (
            actualNumber >=
            numberOfSlices[difficultyLevel - 1] - intruderNumber[difficultyLevel - 1]
        ) {
            setIsPlayingSound(true);
            setNbModal(0);
            setTimeout(() => {
                setIsSuccess(true);
                setIsFirework(true);
                setOpenSuccessModal(true);
                setTimeout(() => {
                    setIsPlayingSound(false);
                    setOpenSuccessModal(false);
                    setIsFirework(false);
                    setEndFrame(true);
                }, 3000);
                setTimeout(() => {
                    setOpenFormModal(true);
                }, 6000);
            }, 1000);
        }
    }, [actualNumber]);

    return (
        <Box flex={1} display="flex" flexDirection="column" height="80vh" justifyItems="center">
            {endFrame === true && (
                <div>
                    <div
                        style={{
                            width: '0%',
                            height: '100%',
                            position: 'absolute',
                            backgroundColor: 'red',
                            background: 'linear-gradient(to right, #002E5D 30%, #003b78 70%)',
                            backgroundSize: '50px 100%',
                            zIndex: '2500'
                        }}
                        className={classes.endAnimation}
                    />
                    <div
                        style={{
                            right: '0%',
                            width: '0%',
                            height: '100%',
                            position: 'absolute',
                            backgroundColor: 'red',
                            background: 'linear-gradient(to right, #002E5D 30%, #003b78 70%)',
                            backgroundSize: '50px 100%',
                            zIndex: '2500'
                        }}
                        className={classes.endAnimation}
                    />
                </div>
            )}
            <audio style={{ display: 'none' }} src="/applause-sound-effect.mp3">
                <track kind="captions" />
            </audio>
            <CssBaseline />
            <SuccessModal
                isModelOpen={openSuccessModal}
                setModelOpen={setOpenSuccessModal}
                isSuccess={isSuccess}
                isFirework={isFirework}
                nbModal={nbModal}
                isReorder
                isPlaySound={isPlayingSound}
            />
            {openFormModal && exerciseItem && (
                <FormModalAlert
                    exerciseReceived={exerciseItem}
                    closeModal={handleCloseFormModal}
                    exercise={exercise}
                    difficultyLevel={difficultyLevel}
                    displayInfo
                />
            )}
            {openReview && (
                <ReviewExerciseAlerts
                    exercise={exercise}
                    exerciseType={ExerciseType.ORDER}
                    difficultyLevel={difficultyLevel}
                    reorderError={reorderError}
                    reorderClue={reorderClue}
                />
            )}
            <ExerciseHeader
                exerciseId={location.state.exercises.id}
                exerciseName={location.state.exercises.exerciseName}
                isExerciseSelected={!isMemorization}
                difficultyLevel={difficultyLevel}
                handleClueClick={handleClueClick}
                isLastOption={isLastOption}
                handleGoBack={
                    !isMemorization
                        ? (): void => {
                              handleGoBackFunction();
                          }
                        : undefined
                }
                displayBack={!isMemorization}
                exercise={exercise}
                level={difficultyLevel}
                exerciseType={ExerciseType.ORDER}
            />
            <Paper
                variant="outlined"
                sx={{
                    backgroundColor: blue[50],
                    display: isMemorization ? 'none' : 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '6px',
                    marginTop: difficultyLevel === 1 ? '120px' : '100px',
                    marginRight: '2%',
                    marginLeft: '2%',
                    marginBottom: '2vh',
                    border: isMemorization ? 'none' : '1px solid rgba(0, 0, 0, 0.12)',
                    height: difficultyLevel >= 5 && difficultyLevel < 8 ? '16vh' : '15vh'
                }}>
                {author &&
                    author.role !== undefined &&
                    author.role !== Roles.ADMIN &&
                    !author.profilePhoto &&
                    !isMemorization && (
                        <DefaultLogo className={classes.profileImage}>
                            <div style={{ height: '5vmin' }}>
                                <Typography
                                    variant="h2"
                                    sx={{
                                        fontWeight: 'bold',
                                        color: '#374653',
                                        fontFamily:
                                            user?.role === Roles.PATIENT ? 'Luciole-Regular' : ''
                                    }}
                                    className={classes.currentUserInitials}>
                                    {initials}
                                </Typography>
                            </div>
                        </DefaultLogo>
                    )}
                {author &&
                    author.role !== undefined &&
                    author.role !== Roles.ADMIN &&
                    author.profilePhoto &&
                    typeof author.profilePhoto === 'string' &&
                    !isMemorization && (
                        <Avatar
                            alt="userIcon"
                            src={getImageKitUrlFrom(author.profilePhoto)}
                            className={classes.profileImage}
                        />
                    )}
                {!isMemorization && (
                    <Typography
                        variant="h4"
                        my={5}
                        color="secondary"
                        sx={{
                            marginTop: '25px',
                            marginBottom: '25px',
                            color: isMemorization ? 'transparent' : '#3387CC',
                            fontFamily: user?.role === Roles.PATIENT ? 'Luciole-Regular' : ''
                        }}>
                        {
                            // eslint-disable-next-line no-nested-ternary
                            exercise?.authorType === Roles.FAMILY
                                ? // eslint-disable-next-line no-nested-ternary
                                  difficultyLevel >= 8
                                    ? t('placeFingerOnFragmentFamilyIntruderLevels')
                                    : // eslint-disable-next-line no-nested-ternary
                                    difficultyLevel >= 5
                                    ? difficultyLevel === 5
                                        ? t('placeFingerOnFragmentFamilyFirstsIntruderLevels', {
                                              intruderNumber: t('toTheIntruder')
                                          })
                                        : t('placeFingerOnFragmentFamilyFirstsIntruderLevels', {
                                              intruderNumber: t('toIntruders')
                                          })
                                    : difficultyLevel > 1
                                    ? t('placeFingerOnFragmentFamily', {
                                          firstPartPosition: t('highestPart'),
                                          otherParts: t('nextParts')
                                      })
                                    : t('placeFingerOnFragmentFamily', {
                                          firstPartPosition: t('superior'),
                                          otherParts: t('inferiorPart')
                                      })
                                : // eslint-disable-next-line no-nested-ternary
                                difficultyLevel >= 8
                                ? t('placeFingerOnFragmentIntruderLevels')
                                : // eslint-disable-next-line no-nested-ternary
                                difficultyLevel >= 5
                                ? difficultyLevel === 5
                                    ? t('placeFingerOnFragmentFirstsIntruderLevels', {
                                          intruderNumber: t('toTheIntruder')
                                      })
                                    : t('placeFingerOnFragmentFirstsIntruderLevels', {
                                          intruderNumber: t('toIntruders')
                                      })
                                : difficultyLevel > 1
                                ? t('placeFingerOnFragment', {
                                      firstPartPosition: t('highestPart'),
                                      otherParts: t('nextParts')
                                  })
                                : t('placeFingerOnFragment', {
                                      firstPartPosition: t('superior'),
                                      otherParts: t('inferiorPart')
                                  })
                        }
                    </Typography>
                )}
            </Paper>
            <Box margin="auto" sx={{ width: '94%', height: 'auto' }}>
                {!isMemorization && reorderImage && (
                    <ImageSlicer
                        imageSrc={reorderImage}
                        numberOfSlices={numberOfSlices[difficultyLevel - 1]}
                        actualNumber={actualNumber}
                        setActualNumber={setActualNumber}
                        validNumber={validNumber}
                        setValidNumber={setValidNumber}
                        difficultyLevel={difficultyLevel}
                        intruderNumber={intruderNumber[difficultyLevel - 1]}
                        setIsSuccess={setIsSuccess}
                        setNbModal={setNbModal}
                        setOpenSuccessModal={setOpenSuccessModal}
                        exercise={exercise}
                        reorderError={reorderError}
                        setReorderError={setReorderError}
                        slices={slices}
                        setSlices={setSlices}
                        itemToShake={itemToShake}
                        setItemToShake={setItemToShake}
                        isGameLoaded={isGameLoaded}
                        setIsGameLoaded={setIsGameLoaded}
                    />
                )}
            </Box>
            <Box
                margin="auto"
                sx={{
                    width: '100vw',
                    height: isMemorization ? '80vh' : '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: isMemorization ? '20vh' : '0',
                    position: 'relative'
                    // HERE
                }}>
                {isMemorization && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '6px',
                            width: '40.5%',
                            margin: 'auto',
                            position: 'absolute',
                            bottom: '0',
                            left: '74.5%',
                            transform: 'translate(-50%, 0)'
                        }}>
                        <Button
                            sx={{
                                backgroundColor: '#ffffff',
                                color: '#D00070',
                                border: '1px solid #D00070',
                                borderRadius: '10px',
                                width: '100%',
                                height: '100%',
                                '&:hover': {
                                    backgroundColor: '#ffffff',
                                    color: '#D00070',
                                    border: '1px solid #D00070'
                                }
                            }}
                            onClick={(): void => setIsMemorization(false)}>
                            <Typography
                                sx={{
                                    fontFamily: 'Luciole-Regular !important',
                                    fontSize: '1.5rem !important',
                                    fontWeight: '400 !important'
                                }}>
                                {t('skipMemorisation')}
                            </Typography>
                        </Button>
                    </Box>
                )}
                {isMemorization && (
                    <Root>
                        <Grid
                            container
                            spacing={1}
                            sx={{
                                justifyContent: 'center',
                                marginRight: '1%',
                                width: '50%',
                                height: '100%'
                            }}>
                            {isMemorization &&
                                exerciseItem &&
                                typeof exerciseItem.exerciseImage === 'string' && (
                                    <Card
                                        sx={{
                                            height: 'calc(50vw - 60px)',
                                            width: 'calc(50vw - 60px)',
                                            maxHeight: 'calc(100vh - 120px - 16vh)',
                                            maxWidth: 'calc(100vh - 120px - 16vh)',
                                            borderRadius: '35px',
                                            boxShadow: '0px 19.3854px 63.0027px #A0BDD6',
                                            border: '8px solid #FFFFFF',
                                            position: 'relative',
                                            backgroundColor: 'transparent !important'
                                        }}>
                                        <ImageContainer src={reorderImage} alt="exercise image" />
                                    </Card>
                                )}
                        </Grid>
                        <Grid
                            direction="column"
                            container
                            sx={{
                                justifyContent: 'center',
                                alignItems: 'stretch',
                                width: '50%',
                                marginLeft: '2%',
                                alignSelf: 'center'
                            }}>
                            {isMemorization && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '6px',
                                        height: '30vh',
                                        width: '85%',
                                        backgroundColor: '#3387CC1A',
                                        margin: 'auto',
                                        position: 'relative'
                                    }}>
                                    {author &&
                                        author.role !== undefined &&
                                        author.role !== Roles.ADMIN &&
                                        author.profilePhoto &&
                                        typeof author.profilePhoto === 'string' && (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    position: 'absolute',
                                                    top: '10px',
                                                    left: '10px',
                                                    transform: 'translate(-50%, -50%)'
                                                }}>
                                                <Avatar
                                                    alt="userIcon"
                                                    src={getImageKitUrlFrom(author.profilePhoto)}
                                                    className={classes.profileImage}
                                                />
                                            </Box>
                                        )}
                                    <Typography
                                        variant="h4"
                                        color="secondary"
                                        sx={{
                                            marginTop: '25px',
                                            marginBottom: '25px',
                                            marginLeft: '10px',
                                            marginRight: '10px',
                                            textAlign: 'left',
                                            fontFamily:
                                                user?.role === Roles.PATIENT
                                                    ? 'Luciole-Regular'
                                                    : ''
                                        }}>
                                        {t('waitMemorizationReorder')}
                                    </Typography>
                                </Box>
                            )}
                        </Grid>
                    </Root>
                )}
            </Box>
        </Box>
    );
}
