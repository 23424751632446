import React from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import { AppBar, Toolbar, Typography } from '@mui/material';
import starIcon from '../assets/star.png';

interface ImageHeaderProps {
    text: string;
}

const Logo = styled.img`
    height: 12px;
    width: 12px;
    margin: 10px;
`;
const StimulinAppBar = styled(AppBar)({
    background: '#fff',
    position: 'static',
    height: '78px'
});

export function ImageHeader({ text }: ImageHeaderProps): JSX.Element {
    return (
        <Box>
            <StimulinAppBar>
                <Toolbar sx={{ justifyContent: 'space-between' }}>
                    <Box />
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {' '}
                        <Logo src={starIcon} alt="logo" />
                        <Typography
                            variant="h4"
                            color="#004680"
                            sx={{
                                fontWeight: 700,
                                textAlign: 'center'
                            }}>
                            {text}
                        </Typography>
                        <Logo src={starIcon} alt="logo" />
                    </Box>
                    <Box />
                </Toolbar>
            </StimulinAppBar>
        </Box>
    );
}
