/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Rating, Typography } from '@mui/material';
import ExtensionIcon from '@mui/icons-material/Extension';
import TocIcon from '@mui/icons-material/Toc';
import { BrainEmptySvgIcon } from '../BrainEmptySvgIcon';
import { BrainSvgIcon } from '../BrainSvgIcon';
import { SudokuEmptySvgIcon } from '../SudokuEmptySvgIcon';
import { SudokuSvgIcon } from '../SudokuSvgIcon';
import { PongEmptySvgIcon } from '../PongEmptySvgIcon';
import { PongSvgIcon } from '../PongSvgIcon';
import { MemorinSvgIcon } from '../MemorinSvgIcon';
import { MemorinEmptySvgIcon } from '../MemorinEmptySvgIcon';
import { Roles } from '../../models/Roles';

const useStyles = makeStyles(() =>
    createStyles({
        leftLayout: {
            marginTop: '16px',
            width: '44%',
            margin: '0px',
            padding: '0px',
            marginRight: '6%',
            display: 'inline-block'
        },
        centerLayout: {
            marginTop: '16px',
            width: '44%',
            margin: '0px',
            padding: '0px',
            marginLeft: '6%',
            display: 'inline-block'
        },
        rightLayout: {
            marginTop: '16px',
            width: '44%',
            margin: '0px',
            padding: '0px',
            display: 'inline-block'
        },
        marginTopLayout: {
            marginTop: '16px'
        },
        difficultyText: {
            textAlign: 'left',
            color: '#004680',
            fontFamily: 'Luciole-Regular',
            marginTop: '4%'
        },
        brainIcon: {
            '& .css-dqr9h-MuiRating-label': {
                paddingRight: '3.8%'
            },
            textAlign: 'left',
            display: 'flex',
            marginTop: '5%'
        },
        puzzleIcon: {
            '& .css-dqr9h-MuiRating-label': {
                paddingRight: '4%'
            },
            textAlign: 'left',
            display: 'flex',
            marginTop: '5%'
        },
        iconFilled: {
            color: '#FF0089'
        },
        iconEmpty: {
            color: '#C4C4C4'
        }
    })
);

interface RatingExerciseInterface {
    type: string;
    level: number;
    side: string;
    downgradeDifficultyLevel: Function;
    max: number;
}

export const RatingExercise = ({
    type,
    level,
    side,
    downgradeDifficultyLevel,
    max
}: RatingExerciseInterface): JSX.Element => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [sideClass, setSideClass] = useState<string>('');

    useEffect(() => {
        if (side) {
            if (side === 'right') {
                setSideClass(classes.rightLayout);
            } else if (side === 'center') {
                setSideClass(classes.centerLayout);
            } else {
                setSideClass(classes.leftLayout);
            }
        }
    }, []);

    const title = {
        "souven'in": t('souvenIn'),
        "memor'in": t('memorin'),
        "sudok'in": t('Sudokin'),
        "puzzl'in": t('puzzlin'),
        "pong'in": t('Pongin'),
        reorder: t('reorder')
    };

    return (
        <div className={sideClass}>
            <Typography variant="h6" className={classes.difficultyText} sx={{ fontWeight: '550' }}>
                {type === "souven'in" ||
                type === "memor'in" ||
                type === "sudok'in" ||
                type === "puzzl'in" ||
                type === "pong'in" ||
                type === 'reorder'
                    ? title[type]
                    : ''}
            </Typography>
            <Rating
                name="simple-controlled"
                value={level ?? null}
                max={max}
                size="large"
                icon={
                    type === "souven'in" ? (
                        <BrainSvgIcon />
                    ) : type === "memor'in" ? (
                        <MemorinSvgIcon />
                    ) : type === "sudok'in" ? (
                        <SudokuSvgIcon />
                    ) : type === "pong'in" ? (
                        <PongSvgIcon />
                    ) : type === 'reorder' ? (
                        <TocIcon className={classes.iconFilled} fontSize="inherit" />
                    ) : (
                        <ExtensionIcon className={classes.iconFilled} fontSize="inherit" />
                    )
                }
                emptyIcon={
                    type === "souven'in" ? (
                        <BrainEmptySvgIcon />
                    ) : type === "memor'in" ? (
                        <MemorinEmptySvgIcon />
                    ) : type === "sudok'in" ? (
                        <SudokuEmptySvgIcon />
                    ) : type === "pong'in" ? (
                        <PongEmptySvgIcon />
                    ) : type === 'reorder' ? (
                        <TocIcon className={classes.iconEmpty} fontSize="inherit" />
                    ) : (
                        <ExtensionIcon className={classes.iconEmpty} fontSize="inherit" />
                    )
                }
                className={type === "souven'in" ? classes.brainIcon : classes.puzzleIcon}
                onChange={(event, value: number | null): Promise<void> =>
                    downgradeDifficultyLevel(value)
                }
            />
        </div>
    );
};

interface RatingTextInterface {
    userType: Roles;
}

export const RatingText = ({ userType }: RatingTextInterface): JSX.Element => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Typography variant="body1" className={classes.difficultyText}>
            <span>{t('youCan')} </span>
            {userType === Roles.THERAPIST && (
                <span style={{ display: 'inline' }}>
                    <span style={{ textDecoration: 'underline' }}>{t('upgrade')}</span>
                    <span> {t('or').toLowerCase()} </span>
                </span>
            )}
            <span style={{ textDecoration: 'underline' }}>{t('reduce')}</span>
            <span> {t('difficulty')}</span>
        </Typography>
    );
};
