import { Box, Button, CircularProgress, FormControl, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/styles';
import { AdminProfileModal } from '../../components/AdminProfileModal';
import { DashboardLayout } from '../../layouts/DashboardLayout';
import { User } from '../../models/User';
import { UserContext } from '../../providers/UserProvider';
import { saveAdminProfile } from '../../services/cloudFirestore';
import { logOut } from '../../services/firebaseAuth';
import { ChangePassword } from '../../components/ChangePassword';
import editIcon from '../../assets/editIcon.png';
import { UserDataProp } from '../../models/userDataProp';
import { InputTextDisabled, InputTextNotDisabled } from '../../components/FormComponents/InputText';

const DeleteAndEditBtn = styled(Button)(() => ({
    marginTop: '5%',
    fontSize: '16px',
    lineHeight: '19px',
    borderRadius: '3px',
    marginLeft: '6px',
    width: '400px'
}));

export function Profile(): JSX.Element {
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const updatedUser = userDataProp?.updateUser;
    const history = useHistory();
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [isChangePassword, setIsChangePassword] = useState<boolean>(false);
    const [successModal, setSuccessModal] = useState<boolean>(false);
    const [data, setData] = useState<User>({
        firstName: undefined,
        lastName: undefined,
        emailId: undefined
    });

    useEffect(() => {
        if (user) {
            setData({
                firstName: user.firstName,
                lastName: user.lastName,
                emailId: user.emailId
            });
        }
    }, [user]);

    const handleChange = (state: string, value: string | number | File | null): void => {
        setData({ ...data, [state]: value });
    };

    const saveDetails = async (): Promise<void> => {
        setLoading(true);
        if (user && user.id) {
            await saveAdminProfile(user.id, data);
            if (typeof updatedUser !== 'undefined') {
                updatedUser();
            }
        }
        setLoading(false);
        setSuccessModal(true);
    };

    return (
        <DashboardLayout>
            <ChangePassword
                isModelOpen={isChangePassword}
                setModelOpen={setIsChangePassword}
                onCancel={(): void => setIsChangePassword(false)}
                onButtonClick={(): void => {
                    setIsChangePassword(false);
                    logOut(user);
                }}
            />
            <AdminProfileModal
                isModelOpen={successModal}
                setModelOpen={setSuccessModal}
                onButtonClick={(): void => history.push('/home')}
            />
            <Box display="flex" p={2} justifyContent="space-between">
                <Typography variant="h2">{t('profile')}</Typography>
                <Box display="flex" justifyContent="space-between">
                    <Button
                        variant="greyBorderButton"
                        sx={{ color: '#5C6670', minHeight: '48px', height: 'auto' }}
                        onClick={(): void => {
                            window.location.reload();
                        }}>
                        {t('discardChanges')}
                    </Button>
                    <Box sx={{ flexGrow: 1 }} width="20px" />
                    <Button
                        variant="primaryContained"
                        color="primary"
                        onClick={saveDetails}
                        sx={{ minHeight: '48px', height: 'auto' }}>
                        {loading && (
                            <div className="loading">
                                <CircularProgress
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        float: 'right',
                                        marginRight: '19%',
                                        color: '#FFFF'
                                    }}
                                />
                            </div>
                        )}
                        {!loading && t('updateMyInformation')}
                    </Button>
                </Box>
            </Box>
            <Typography variant="subtitle2" pt={5} sx={{ m: 1, textAlign: 'left' }}>
                {t('addFormRequiredFields')}
            </Typography>
            <Box sx={{ width: '900px', textAlign: 'left' }}>
                <FormControl sx={{ m: 1, width: '400px' }}>
                    <InputTextNotDisabled
                        placeholder={t('name')}
                        data={data.lastName ?? null}
                        change={(value: string): void => {
                            handleChange('lastName', value);
                        }}
                    />
                </FormControl>
                <FormControl sx={{ m: 1, width: '400px' }}>
                    <InputTextNotDisabled
                        placeholder={t('firstName')}
                        data={data.firstName ?? null}
                        change={(value: string): void => {
                            handleChange('firstName', value);
                        }}
                    />
                </FormControl>
                <FormControl fullWidth sx={{ m: 1, width: '400px', marginRight: '200px' }}>
                    <InputTextDisabled placeholder={t('email')} data={data.emailId ?? null} />
                </FormControl>
                <DeleteAndEditBtn
                    variant="outlined"
                    size="large"
                    color="secondary"
                    sx={{
                        backgroundColor: 'rgba(51, 135, 204, 0.1)'
                    }}
                    onClick={(): void => {
                        setIsChangePassword(true);
                    }}>
                    <Typography variant="subtitle1">{t('changeMyPassword')}</Typography>
                    <img src={editIcon} alt="EditIcon" style={{ marginLeft: '10%' }} />
                </DeleteAndEditBtn>
            </Box>
        </DashboardLayout>
    );
}
