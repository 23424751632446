import firestore from 'firebase/firestore';
import { MediaInformation } from './MediaInformation';
import { Roles } from './Roles';

export class Video {
    communityId?: string | undefined;

    authorType?: Roles | undefined;

    mediaInformation?: MediaInformation[] | undefined;

    constructor(communityId?: string, authorType?: Roles, mediaInformation?: MediaInformation[]) {
        this.communityId = communityId;
        this.authorType = authorType;
        this.mediaInformation = mediaInformation;
    }
}

export const VideoConverter: firestore.FirestoreDataConverter<Video> = {
    toFirestore(video) {
        return {
            communityId: video.communityId,
            authorType: video.authorType,
            mediaInformation: video.mediaInformation
        };
    },
    fromFirestore(snapshot, options): Video {
        const data = snapshot.data(options);
        return new Video(snapshot.id, data.authorType, data.mediaInformation);
    }
};
