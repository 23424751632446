import React, { useContext, useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { Box, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles, createStyles } from '@mui/styles';
import { Timestamp } from 'firebase/firestore';
import cakeIcon from '../../assets/cakeIcon.png';
import { CentralLogo } from '../../components/Headers/CentralLogo';
import { PersonalisedExercises } from '../../components/Admin/PersonalisedExercises';
import { PatientCompletedExercises } from './PatientCompletedExercises';
import { MedianExercise } from '../../components/Admin/MedianExercise';
import { ExerciseAssessment } from '../../components/Admin/ExerciseAssessment';
import { Patient } from '../../models/Patient';
import { Family } from '../../models/Family';
import {
    getLegalReferent,
    getPatientExercisesDetails,
    getRewardReceive,
    getTherapistUser,
    savePatientProfile,
    updatePatientDifficultyLevel
} from '../../services/cloudFirestore';
import { UserContext } from '../../providers/UserProvider';
import { UserDataProp } from '../../models/userDataProp';
import { DatePickerCustom } from '../../components/DatePickerCustom';
import { clickAnalytics } from '../../services/analyticsFunction';
import { InputTextDisabled } from '../../components/FormComponents/InputText';
import { RatingExercise, RatingText } from '../../components/FormComponents/RatingExercise';
import { Roles } from '../../models/Roles';
import { ExerciseType } from '../../models/ExerciseType';
import { TitleFormPart } from '../../components/FormComponents/TitleFormPart';
import { Logo } from '../../components/FormComponents/Logo';
import { InputLabel } from '../../components/FormComponents/InputLabel';

const drawerWidth = 240;

const useStyles = makeStyles(() =>
    createStyles({
        selectedButton: {
            backgroundColor: 'rgba(212, 220, 227, 0.6)'
        },
        averageRateCard: {
            backgroundColor: '#FFFFFF',
            border: '1px solid #E3E6E8',
            boxSizing: 'border-box',
            borderRadius: '8px'
        },
        medianCards: {
            border: '1px solid #E3E6E8',
            borderRadius: '8px',
            marginTop: '35px',
            backgroundColor: '#fff'
        }
    })
);

interface PatientProfileProps {
    patientDataProps: Patient;
    setSelectCard: React.Dispatch<React.SetStateAction<Patient | null>>;
}

export function PatientProfile({
    patientDataProps,
    setSelectCard
}: PatientProfileProps): JSX.Element {
    const { t } = useTranslation();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const classes = useStyles();
    const user = userDataProp?.user;
    const [patientData, setPatientData] = useState(patientDataProps);
    const [profileDetails, setProfileDetails] = useState<boolean>(true);
    const [patientPassword, setPatientPassword] = useState<string>('');
    const [memoryDifficultyLevel, setMemoryDifficultyLevel] = useState<number>(0);
    const [memorinDifficultyLevel, setMemorinDifficultyLevel] = useState<number>(0);
    const [puzzleDifficultyLevel, setPuzzleDifficultyLevel] = useState<number>(0);
    const [sudokuDifficultyLevel, setSudokuDifficultyLevel] = useState<number>(0);
    const [pongDifficultyLevel, setPongDifficultyLevel] = useState<number>(0);
    const [reorderDifficultyLevel, setReorderDifficultyLevel] = useState<number>(0);
    const [avgMemoryTime, setAvgMemoryTime] = useState<number>(0);
    const [avgMemorinTime, setAvgMemorinTime] = useState<number>(0);
    const [avgPuzzleTime, setAvgPuzzleTime] = useState<number>(0);
    const [completeExerciseCount, setCompleteExerciseCount] = useState<number>(0);
    const [startExerciseCount, setStartExerciseCount] = useState<number>(0);
    const [maxMemoryTime, setMaxMemoryTime] = useState<number>(0);
    const [maxMemorinTime, setMaxMemorinTime] = useState<number>(0);
    const [maxPuzzleTime, setMaxPuzzleTime] = useState<number>(0);
    const [memoryCount, setMemoryCount] = useState<number>(0);
    const [memorinCount, setMemorinCount] = useState<number>(0);
    const [minMemoryTime, setMinMemoryTime] = useState<number>(0);
    const [minMemorinTime, setMinMemorinTime] = useState<number>(0);
    const [minPuzzleTime, setMinPuzzleTime] = useState<number>(0);
    const [puzzleCount, setPuzzleCount] = useState<number>(0);
    const [photo, setPhoto] = useState<string | undefined>();
    const [avgClueCount, setAvgClueCount] = useState<number>(0);
    const [avgErrorCount, setAvgErrorCount] = useState<number>(0);
    const [rewardReceive, setRewardReceive] = useState<number>(0);
    const [data, setData] = useState<Patient>({
        firstName: undefined,
        lastName: undefined,
        emailId: undefined,
        dob: undefined,
        profilePhoto: undefined,
        sex: undefined,
        level: undefined,
        category: undefined,
        familyCode: undefined,
        legalReferent: undefined,
        establishmentName: undefined,
        establishmentCode: undefined,
        startDate: undefined,
        endDate: undefined,
        address: undefined,
        pincode: undefined,
        city: undefined,
        familyMembers: undefined,
        memoryDifficultyLevel: undefined,
        memorinDifficultyLevel: undefined,
        puzzleDifficultyLevel: undefined,
        sudokuDifficultyLevel: undefined,
        pongDifficultyLevel: undefined,
        reorderDifficultyLevel: undefined,
        firstStepFormFields: [],
        secondStepFormFields: []
    });
    const [family, setFamily] = useState<Family>({
        firstName: undefined,
        lastName: undefined,
        emailId: undefined,
        familyCode: undefined,
        address: undefined,
        pincode: undefined,
        city: undefined,
        relationship: undefined,
        firstStepFormFields: [],
        secondStepFormFields: [],
        dob: undefined
    });

    useEffect(() => {
        setPatientData(patientDataProps);
        setMemoryDifficultyLevel(patientData.memoryDifficultyLevel ?? 0);
        setMemorinDifficultyLevel(patientData.memorinDifficultyLevel ?? 0);
        setPuzzleDifficultyLevel(patientData.puzzleDifficultyLevel ?? 0);
        setSudokuDifficultyLevel(patientData.sudokuDifficultyLevel ?? 0);
        setPongDifficultyLevel(patientData.pongDifficultyLevel ?? 0);
        setReorderDifficultyLevel(patientData.reorderDifficultyLevel ?? 0);
    }, [patientDataProps]);

    useEffect(() => {
        const getTherapist = async (): Promise<void> => {
            if (user && user.id) {
                const therapist = await getTherapistUser(user.id);
                if (therapist && therapist.patientPassword) {
                    setPatientPassword(therapist.patientPassword);
                }
            }
        };
        getTherapist();
    }, []);

    useEffect(() => {
        const getUserList = async (): Promise<void> => {
            if (patientData && patientData.id) {
                const familyCode = patientData?.familyCode;
                if (familyCode) {
                    const familyData: Family | null = await getLegalReferent(familyCode);
                    if (familyData) {
                        setFamily({
                            ...family,
                            firstName: familyData.firstName,
                            lastName: familyData.lastName,
                            emailId: familyData.emailId,
                            familyCode: familyData.familyCode,
                            address: familyData.address,
                            pincode: familyData.pincode,
                            city: familyData.city,
                            relationship: familyData.relationship,
                            dob: familyData?.dob
                        });
                    }
                }
                setData({
                    firstName: patientData.firstName,
                    lastName: patientData.lastName,
                    emailId: patientData.emailId,
                    dob: patientData.dob,
                    profilePhoto: patientData.profilePhoto,
                    sex: patientData.sex,
                    level: patientData.level,
                    category: patientData.category,
                    familyCode: patientData.familyCode,
                    legalReferent: patientData.legalReferent,
                    establishmentName: patientData.establishmentName,
                    establishmentCode: patientData.establishmentCode,
                    startDate: patientData.startDate,
                    endDate: patientData.endDate,
                    address: patientData.address,
                    pincode: patientData.pincode,
                    city: patientData.city,
                    familyMembers: patientData.familyMembers
                });
                const reward = await getRewardReceive(patientData.id);
                const graphData = await getPatientExercisesDetails(patientData.id);
                if (typeof patientData.profilePhoto === 'string') {
                    setPhoto(patientData.profilePhoto);
                }
                setCompleteExerciseCount(graphData.completeExerciseCount);
                setStartExerciseCount(graphData.startExerciseCount);
                setPuzzleCount(graphData.puzzleCount);
                setMemoryCount(graphData.memoryCount);
                setMemorinCount(graphData.memorinCount);
                setAvgPuzzleTime(graphData.avgPuzzleTime);
                setMinPuzzleTime(graphData.minPuzzleTime);
                setMaxPuzzleTime(graphData.maxPuzzleTime);
                setAvgMemoryTime(graphData.avgMemoryTime);
                setAvgMemorinTime(graphData.avgMemorinTime);
                setMinMemoryTime(graphData.minMemoryTime);
                setMinMemorinTime(graphData.minMemorinTime);
                setMaxMemoryTime(graphData.maxMemoryTime);
                setMaxMemorinTime(graphData.maxMemorinTime);
                setAvgClueCount(graphData.avgClueCount);
                setAvgErrorCount(graphData.avgErrorCount);
                setRewardReceive(reward);
            }
        };
        getUserList();
    }, []);

    const changeDifficultyLevel = async (
        value: number | null,
        type: ExerciseType
    ): Promise<void> => {
        if (patientData && patientData.id && user) {
            if (type === ExerciseType.MEMORY && memoryDifficultyLevel !== value && value !== null) {
                updatePatientDifficultyLevel(
                    value,
                    memorinDifficultyLevel,
                    puzzleDifficultyLevel,
                    sudokuDifficultyLevel,
                    pongDifficultyLevel,
                    reorderDifficultyLevel,
                    patientData.id
                );
                clickAnalytics(`update_souven'in_level`, user);
                setMemoryDifficultyLevel(value);
            }
            if (type === ExerciseType.PUZZLE && puzzleDifficultyLevel !== value && value !== null) {
                updatePatientDifficultyLevel(
                    memoryDifficultyLevel,
                    memorinDifficultyLevel,
                    value,
                    sudokuDifficultyLevel,
                    pongDifficultyLevel,
                    reorderDifficultyLevel,
                    patientData.id
                );
                clickAnalytics(`update_puzzl'in_level`, user);
                setPuzzleDifficultyLevel(value);
            }
            if (
                type === ExerciseType.MEMORIN &&
                memorinDifficultyLevel !== value &&
                value !== null
            ) {
                updatePatientDifficultyLevel(
                    memoryDifficultyLevel,
                    value,
                    puzzleDifficultyLevel,
                    sudokuDifficultyLevel,
                    pongDifficultyLevel,
                    reorderDifficultyLevel,
                    patientData.id
                );
                clickAnalytics(`update_memor'in_level`, user);
                setMemorinDifficultyLevel(value);
            }
            if (type === ExerciseType.SUDOKU && sudokuDifficultyLevel !== value && value !== null) {
                updatePatientDifficultyLevel(
                    memoryDifficultyLevel,
                    memorinDifficultyLevel,
                    puzzleDifficultyLevel,
                    value,
                    pongDifficultyLevel,
                    reorderDifficultyLevel,
                    patientData.id
                );
                clickAnalytics(`update_sudok'in_level`, user);
                setSudokuDifficultyLevel(value);
            }
            if (type === ExerciseType.PONG && pongDifficultyLevel !== value && value !== null) {
                updatePatientDifficultyLevel(
                    memoryDifficultyLevel,
                    memorinDifficultyLevel,
                    puzzleDifficultyLevel,
                    sudokuDifficultyLevel,
                    value,
                    reorderDifficultyLevel,
                    patientData.id
                );
                clickAnalytics(`update_sudok'in_level`, user);
                setPongDifficultyLevel(value);
            }
            if (type === ExerciseType.ORDER && reorderDifficultyLevel !== value && value !== null) {
                updatePatientDifficultyLevel(
                    memoryDifficultyLevel,
                    memorinDifficultyLevel,
                    puzzleDifficultyLevel,
                    sudokuDifficultyLevel,
                    pongDifficultyLevel,
                    value,
                    patientData.id
                );
                clickAnalytics(`update_ordon'in_level`, user);
                setReorderDifficultyLevel(value);
            }
        }
    };

    useEffect(() => {
        if (
            patientData &&
            patientData.id &&
            data.profilePhoto &&
            typeof data.profilePhoto === 'object'
        ) {
            savePatientProfile(patientData.id, data.profilePhoto);
        }
    }, [data.profilePhoto]);

    const handleChange = (state: string, value: string | number | File | null): void => {
        setData({ ...data, [state]: value });
    };

    const handleInformationClick = (): void => {
        setProfileDetails(true);
    };

    const handlePatientFollowUp = (): void => {
        if (user) {
            clickAnalytics('follow_patient_click', user);
        }
        setProfileDetails(false);
    };

    const updateImage = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.files) {
            handleChange('profilePhoto', event.target.files[0]);
            setPhoto(URL.createObjectURL(event.target.files[0]));
        }
    };

    const getDateOfBirth = (): string => {
        if (patientData.dob && typeof patientData.dob === 'object') {
            const inputDate = patientData.dob.toDate();
            const Bday = +new Date(inputDate);
            // eslint-disable-next-line no-bitwise
            const age = `| ${~~((Date.now() - Bday) / 31557600000)}`;
            const formattedDate = new Date(inputDate).toLocaleDateString('fr-FR', {
                day: 'numeric',
                month: 'short',
                year: 'numeric'
            });
            return `${formattedDate} ${age} ${t('yearsOld')}`;
        }
        return '';
    };

    const getHeader = (): JSX.Element => {
        return (
            <Box>
                <CentralLogo
                    hasBackButton
                    hasPatientLibLogo
                    hasProfilePhoto
                    setSelectCard={setSelectCard}
                />
            </Box>
        );
    };

    const inputComponent = (
        position: string,
        placeholder: string,
        value: string | null
    ): JSX.Element => {
        return (
            <InputTextDisabled
                side={position}
                placeholder={t(placeholder)}
                data={value}
                labelPresent
            />
        );
    };

    const inputLabel = (position: string, label: string, width?: string): JSX.Element => {
        return <InputLabel position={position} label={label} width={width} />;
    };

    const dateComponent = (dateData: Timestamp | undefined, width: string): JSX.Element => {
        return (
            <DatePickerCustom
                disabled
                sx={{
                    width,
                    marginRight: width !== '101.5%' ? '6%' : '0',
                    color: data.dob !== null ? 'rgba(0, 47, 85, 1)' : ''
                }}
                onDateChange={(date: Date): void => {}}
                value={typeof dateData === 'object' ? dateData?.toDate() : null}
            />
        );
    };

    const statisticSquare = (text: string, avgStats: number, marginRight?: string): JSX.Element => {
        return (
            <Box className={classes.medianCards} sx={{ marginRight }}>
                <Typography variant="h5" sx={{ fontWeight: 600 }}>
                    {text}
                </Typography>
                <Typography variant="h1" mt={3} sx={{ fontWeight: 600 }}>
                    {avgStats}
                </Typography>
            </Box>
        );
    };

    const getProfileNav = (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100vh',
                marginTop: '5%',
                margin: '0%',
                borderRight: '2px solid #e3e6e8',
                backgroundColor: 'rgba(195, 204, 210, 0.18)',
                padding: '0px !important',
                paddingTop: '5%'
            }}>
            <div style={{ paddingTop: '30%' }}>
                <Logo
                    photo={photo}
                    firstName={patientData.firstName ?? ''}
                    lastName={patientData.lastName ?? ''}
                    updateImage={(event: React.ChangeEvent<HTMLInputElement>): void =>
                        updateImage(event)
                    }
                />
                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                    {patientData.firstName} {patientData.lastName}
                </Typography>
                <Typography variant="body1" color="#92A8B8">
                    <img
                        src={cakeIcon}
                        alt="CakeIcon"
                        style={{ width: '25px', height: '25px', margin: '0px 5px' }}
                    />
                    {getDateOfBirth()}
                </Typography>
            </div>
            <div style={{ padding: '0px' }}>
                <Box
                    className={profileDetails ? classes.selectedButton : ''}
                    onClick={(): void => handleInformationClick()}>
                    <Typography color="#374653" sx={{ textAlign: 'left', padding: '20px' }}>
                        {t('information')}
                    </Typography>
                </Box>
                <Box
                    className={!profileDetails ? classes.selectedButton : ''}
                    onClick={(): void => handlePatientFollowUp()}>
                    <Typography color="#374653" sx={{ textAlign: 'left', padding: '20px' }}>
                        {t('patientFollowUp')}
                    </Typography>
                </Box>
            </div>
        </Container>
    );

    const getRating = (
        <>
            <TitleFormPart text={t('difficultyLevel')} />
            <RatingText userType={Roles.THERAPIST} />
            <RatingExercise
                type="souven'in"
                level={memoryDifficultyLevel}
                side="left"
                downgradeDifficultyLevel={(value: number): void => {
                    changeDifficultyLevel(value, ExerciseType.MEMORY);
                }}
                max={6}
            />
            <RatingExercise
                type="puzzl'in"
                level={puzzleDifficultyLevel}
                side="left"
                downgradeDifficultyLevel={(value: number): void => {
                    changeDifficultyLevel(value, ExerciseType.PUZZLE);
                }}
                max={7}
            />
            <RatingExercise
                type="memor'in"
                level={memorinDifficultyLevel}
                side="left"
                downgradeDifficultyLevel={(value: number): void => {
                    changeDifficultyLevel(value, ExerciseType.MEMORIN);
                }}
                max={6}
            />
        </>
    );

    const getPatientData = (
        <Box
            maxWidth={800}
            px={2}
            sx={{
                margin: '0%',
                marginLeft: 'auto',
                marginRight: 'auto',
                paddingTop: '5%'
            }}>
            <TitleFormPart text={t('patientInformation')} />
            {inputLabel('left', 'firstName')}
            {inputLabel('right', 'name')}
            {inputComponent('left', 'firstName', data.firstName ?? null)}
            {inputComponent('right', 'lastName', data.lastName ?? null)}
            {inputLabel('left', 'dateOfBirth')}
            {inputLabel('right', 'gender')}
            {dateComponent(data.dob, '47%')}
            {inputComponent('right', 'gender', data.sex ?? null)}
            {inputLabel('left', 'levelOfStudy')}
            {inputLabel('right', 'socialProfCategory')}
            {inputComponent('left', 'levelOfStudy', data.level ?? null)}
            {inputComponent('right', 'socialProfCategory', data.category ?? null)}
            {inputLabel('left', 'userId')}
            {inputLabel('right', 'commonPassword')}
            {inputComponent('left', 'userId', data.emailId ?? null)}
            {inputComponent('right', 'commonPassword', patientPassword ?? null)}
            {inputLabel('left', 'startDate')}
            {inputLabel('right', 'endDate')}
            {dateComponent(data.startDate, '47%')}
            <div style={{ display: 'inline-block', width: '47%' }}>
                {dateComponent(data.endDate, '100%')}
            </div>
            <div style={{ width: '47%' }}>
                {inputLabel('left', 'familyCode', '100% !important')}
                {inputComponent('marginTopLayout', 'familyCode', data.familyCode ?? null)}
            </div>
            {getRating}
            <TitleFormPart text={t('familyLegalReferant')} />
            {inputLabel('left', 'firstName')}
            {inputLabel('right', 'lastName')}
            {inputComponent('left', 'firstName', family.firstName ?? null)}
            {inputComponent('right', 'lastName', family.lastName ?? null)}
            {inputLabel('left', 'relationship')}
            {inputLabel('right', 'email')}
            {inputComponent('left', 'relationship', family.relationship ?? null)}
            {inputComponent('right', 'email', family.emailId ?? null)}
            <div style={{ textAlign: 'left' }}>
                {inputLabel('left', 'dateOfBirth')}
                {dateComponent(family.dob, '47%')}
            </div>
        </Box>
    );

    const analysePatientData = (
        <Box
            maxWidth={800}
            px={2}
            sx={{
                paddingTop: '5%'
            }}>
            <TitleFormPart text={t('personalizedExercises')} />
            <Box width="100%" display="flex" flexDirection="column" justifyContent="space-between">
                <PersonalisedExercises patient={patientDataProps} />
            </Box>
            <TitleFormPart text={t('exercisesDone')} />
            <Box>
                <PatientCompletedExercises
                    memoryCount={memoryCount}
                    memorinCount={memorinCount}
                    puzzleCount={puzzleCount}
                    completeExerciseCount={completeExerciseCount}
                    startExerciseCount={startExerciseCount}
                />
            </Box>
            <TitleFormPart text={t('exercisePerformance')} />
            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', width: '51%' }}>
                    {statisticSquare(t('averageNoOfErrorsPerYear'), avgErrorCount, '2%')}
                    {statisticSquare(t('aveageNoOfIndicesPerYear'), avgClueCount, '0%')}
                </div>
                <div style={{ width: '49%' }}>
                    {/* HERE */}
                    <MedianExercise
                        avgMemoryTime={avgMemoryTime}
                        minMemoryTime={minMemoryTime}
                        maxMemoryTime={maxMemoryTime}
                        avgMemorinTime={avgMemorinTime}
                        minMemorinTime={minMemorinTime}
                        maxMemorinTime={maxMemorinTime}
                        avgPuzzleTime={avgPuzzleTime}
                        minPuzzleTime={minPuzzleTime}
                        maxPuzzleTime={maxPuzzleTime}
                    />
                </div>
            </Box>
            <TitleFormPart text={t('exerciseSatisfaction')} />
            <Box width="100%" display="flex" justifyContent="center">
                <ExerciseAssessment user={patientDataProps ?? undefined} />
            </Box>
        </Box>
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" sx={{ zIndex: (theme): number => theme.zIndex.drawer + 1 }}>
                {getHeader()}
            </AppBar>
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' }
                }}>
                <Toolbar />
                {getProfileNav}
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Toolbar />
                {profileDetails ? getPatientData : analysePatientData}
            </Box>
        </Box>
    );
}
