import { PaletteColorOptions, PaletteOptions } from '@mui/material';
import { grey } from '@mui/material/colors';

const PRIMARY: PaletteColorOptions = {
    light: '#FFA4D5',
    main: '#FF0089',
    dark: '#8B004B'
};

const SECONDARY: PaletteColorOptions = {
    light: '#3387CC',
    main: '#3387CC',
    dark: '#002F55'
};

const INFO: PaletteColorOptions = {
    light: '#74CAFF',
    main: '#1890FF',
    dark: '#0C53B7',
    contrastText: '#fff'
};

const SUCCESS: PaletteColorOptions = {
    light: '#AAF27F',
    main: '#54D62C',
    dark: '#229A16',
    contrastText: grey[800]
};
const WARNING: PaletteColorOptions = {
    light: '#FFE16A',
    main: '#FFC107',
    dark: '#B78103',
    contrastText: grey[800]
};
const ERROR: PaletteColorOptions = {
    light: '#FFA48D',
    main: '#FF4842',
    dark: '#B72136',
    contrastText: '#fff'
};

export const palette: PaletteOptions = {
    primary: { ...PRIMARY },
    secondary: { ...SECONDARY },
    success: { ...SUCCESS },
    warning: { ...WARNING },
    error: { ...ERROR },
    background: { paper: '#fff', default: '#FBFBFB' }
};
