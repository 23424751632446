/* eslint-disable react/button-has-type */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { CreateTypes } from 'canvas-confetti';
import ReactCanvasConfetti from './index';

function randomInRange(min: number, max: number): number {
    return Math.random() * (max - min) + min;
}

interface FireworkProps {
    play: boolean;
}

export class Fireworks extends React.Component<FireworkProps> {
    private isAnimationEnabled: boolean;

    private animationInstance: CreateTypes | null = null;

    private intervalId: NodeJS.Timeout | null = null;

    constructor(props: FireworkProps) {
        super(props);
        const { play } = this.props;
        if (play === true) {
            this.startAnimation();
        }
        this.isAnimationEnabled = false;
    }

    componentWillUnmount() {
        this.isAnimationEnabled = false;
        this.intervalId && clearInterval(this.intervalId);
    }

    getInstance = (instance: CreateTypes | null) => {
        this.animationInstance = instance;
    };

    // eslint-disable-next-line class-methods-use-this
    getAnimationSettings(originXA: number, originXB: number) {
        return {
            startVelocity: 50,
            decay: 0.8,
            spread: 360,
            ticks: 100,
            particleCount: 500,
            scalar: 0.9,
            origin: {
                x: randomInRange(originXA, originXB),
                y: Math.random() - 0.2
            }
        };
    }

    handlerClickStart = () => {
        this.startAnimation();
    };

    handlerClickStop = () => {
        this.stopAnimation();
    };

    nextTickAnimation = () => {
        this.animationInstance && this.animationInstance(this.getAnimationSettings(0.1, 0.3));
        this.animationInstance && this.animationInstance(this.getAnimationSettings(0.7, 0.9));
    };

    startAnimation() {
        if (!this.isAnimationEnabled) {
            this.isAnimationEnabled = true;
            this.intervalId = setInterval(this.nextTickAnimation, 1000);
        }
    }

    stopAnimation() {
        this.isAnimationEnabled = false;
        this.animationInstance && this.animationInstance.reset();
        return this.intervalId && clearInterval(this.intervalId);
    }

    render() {
        return (
            <>
                <ReactCanvasConfetti refConfetti={this.getInstance} className="canvas" />
            </>
        );
    }
}
