/* eslint-disable import/no-cycle */
import { Box, Typography, useMediaQuery } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ApartmentIcon from '@mui/icons-material/Apartment';
import communityIcon from '../assets/communityIcon.png';
import arrowSquareIcon from '../assets/arrowSquareIcon.png';
import { CustomCheckbox } from './CustomCheckbox';
import { Patient } from '../models/Patient';
import { getImageKitUrlFrom } from '../utilities/utils';
import { Family } from '../models/Family';
import { getFamilyList, getPatientsData } from '../services/cloudFirestore';
import { Roles } from '../models/Roles';
import { UserContext } from '../providers/UserProvider';
import { UserDataProp } from '../models/userDataProp';

const useStyles = makeStyles(() =>
    createStyles({
        profileImage: {
            height: '75px',
            width: '75px',
            marginRight: '10px'
        },
        currentUserInitials: {
            display: 'flex',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            margin: '0'
        },
        adminLogo: {
            height: 'auto',
            width: '2em',
            marginTop: '12px',
            color: 'orangered'
        }
    })
);

const CustomBox = styled(Box)(() => ({
    height: '100%',
    width: '100%',
    border: '1px solid #E3E6E8',
    borderRadius: '9px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '4%',
    minHeight: '120px'
}));

const Logo = styled.img`
    height: 80px;
    margin: 0px 5px;
    object-fit: cover;
    border-radius: 50%;
    height: 75px;
    width: 75px;
    margin-right: 10px;
`;

const DefaultLogo = styled.div`
    position: relative;
    border-radius: 50%;
    background: #efe7d7;
    height: 27px;
`;

interface StateInterface {
    patientsArray: Patient;
    isChecked: boolean;
    selectPatient: Function;
    viewPatient?: boolean;
    mediatheque?: boolean;
}

export function PatientSelector({
    patientsArray,
    isChecked,
    selectPatient,
    viewPatient,
    mediatheque
}: StateInterface): JSX.Element {
    const isSmallWidth = !useMediaQuery('(min-width:700px)');
    const classes = useStyles();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const { t } = useTranslation();
    const [initials, setInitials] = useState<string>('');
    const [allFamilyUser, setAllFamilyUser] = useState<Family[]>([]);
    const [allPatients, setAllPatients] = useState<Patient[]>([]);

    const getAllFamilyUsers = async (): Promise<void> => {
        const familyList: Family[] = await getFamilyList();
        setAllFamilyUser(familyList);
    };

    const getAllPatients = async (): Promise<void> => {
        const patientList: Patient[] = await getPatientsData();
        setAllPatients(patientList);
    };

    const getTotalAssociates = (code: string): number => {
        if (allFamilyUser && allFamilyUser.length > 0) {
            const list = allFamilyUser.filter((familyUser) => {
                return familyUser.familyCode === code;
            });
            return list.length;
        }
        if (allPatients && allPatients.length > 0) {
            const list = allPatients.filter((patient) => {
                return patient.establishmentCode === code;
            });
            return list.length;
        }
        return 0;
    };

    useEffect(() => {
        if (patientsArray && patientsArray.firstName && patientsArray.lastName) {
            setInitials(
                patientsArray.firstName.charAt(0).toUpperCase() +
                    patientsArray.lastName.charAt(0).toUpperCase()
            );
        }
        if (user && user.role === Roles.THERAPIST) {
            getAllFamilyUsers();
        } else {
            getAllPatients();
        }
    }, [patientsArray]);

    const getImage = (): JSX.Element => {
        return (
            <Box>
                {user && user.role === Roles.ADMIN && (
                    <DefaultLogo className={classes.profileImage}>
                        <ApartmentIcon className={classes.adminLogo} />
                    </DefaultLogo>
                )}
                {user &&
                    user.role === Roles.THERAPIST &&
                    patientsArray &&
                    !patientsArray.profilePhoto && (
                        <DefaultLogo className={classes.profileImage}>
                            <div style={{ height: '5vmin' }}>
                                <Typography
                                    variant="subtitle1"
                                    sx={{ fontWeight: 'bold', color: '#374653' }}
                                    className={classes.currentUserInitials}>
                                    {initials}
                                </Typography>
                            </div>
                        </DefaultLogo>
                    )}
                {user &&
                    user.role === Roles.THERAPIST &&
                    patientsArray &&
                    patientsArray.profilePhoto &&
                    typeof patientsArray.profilePhoto === 'string' && (
                        <Logo src={getImageKitUrlFrom(patientsArray.profilePhoto)} alt="logo" />
                    )}
            </Box>
        );
    };

    const getPatientOrEstablishmentName = (): string => {
        if (user && user.role === Roles.ADMIN && patientsArray.establishmentName) {
            return patientsArray.establishmentName;
        }
        if (user?.role !== Roles.ADMIN && mediatheque) {
            let gender = '';
            if (patientsArray.sex === 'Homme') {
                gender = 'M';
            } else {
                gender = 'Mme';
            }
            return `Famille de ${gender} ${patientsArray.firstName} ${patientsArray.lastName}`;
        }
        return `${patientsArray.firstName} ${patientsArray.lastName}`;
    };

    const getInfo = (): JSX.Element => {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Typography
                    variant="h6"
                    color="#374653"
                    sx={{ fontWeight: 500, textAlign: 'left' }}>
                    {getPatientOrEstablishmentName()}
                </Typography>
                <Box sx={{ display: 'flex' }}>
                    <img
                        src={communityIcon}
                        alt="Community Icon"
                        style={{
                            height: '20px',
                            width: '27px',
                            display: 'inline',
                            marginRight: '5px'
                        }}
                    />
                    {user && user.role === Roles.THERAPIST ? (
                        <Typography variant="subtitle1" sx={{ fontWeight: 500, display: 'inline' }}>
                            {' '}
                            {patientsArray.familyCode
                                ? getTotalAssociates(patientsArray.familyCode)
                                : '0'}{' '}
                            {patientsArray.familyMembers?.length} {t('closeAssociate')}
                        </Typography>
                    ) : (
                        <Typography variant="subtitle1" sx={{ fontWeight: 500, display: 'inline' }}>
                            {' '}
                            {patientsArray.establishmentCode
                                ? getTotalAssociates(patientsArray.establishmentCode)
                                : '0'}{' '}
                            {patientsArray.familyMembers?.length} {t('patientsAssociated')}
                        </Typography>
                    )}
                </Box>
            </Box>
        );
    };

    return (
        <CustomBox
            style={{ cursor: viewPatient ? 'pointer' : 'default' }}
            onClick={(): void => {
                if (viewPatient) {
                    selectPatient(patientsArray);
                }
            }}>
            <Box mt={0.5} sx={{ display: 'flex', justifyContent: 'start' }}>
                {getImage()}
                {getInfo()}
            </Box>
            <Box sx={{ width: '20%', margin: viewPatient ? 'auto' : '' }}>
                {viewPatient ? (
                    <img
                        src={arrowSquareIcon}
                        alt="flèche"
                        style={{ marginLeft: isSmallWidth ? '30px' : '50px' }}
                    />
                ) : (
                    <Box pt={1}>
                        <CustomCheckbox
                            isChecked={isChecked}
                            selectItem={selectPatient}
                            item={patientsArray}
                        />
                    </Box>
                )}
            </Box>
        </CustomBox>
    );
}
