import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Box,
    Button,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Timestamp } from 'firebase/firestore';
import logo from '../assets/logo.png';
import { FormData } from '../models/FormData';
import { FormField } from '../models/FormField';
import adminBackground from '../assets/adminBackground.png';
import {
    createEstablishment,
    addFamily,
    addTherapist,
    getEstablishmentListDropdown,
    getPatientDropdownList,
    getPatientUser,
    getPersonalVideo,
    getEstablishment,
    getPatientFromCode,
    getPatientFromEstablishment
} from '../services/cloudFirestore';
import { DatePickerCustom } from '../components/DatePickerCustom';
import { DropdownData } from '../models/DropdownData';
import { addPatient } from '../services/storageFunctions';
import { CompletedModal } from '../components/CompletedModal';
import { Patient } from '../models/Patient';

interface AdminFormLayoutProps {
    subtitleDescription: string;
    data: FormData;
    backgroundImage: string;
    columnSize: string;
    formFor?: string;
    generatedFamilyCode?: string;
}

const FormContainer = styled.div`
    height: 105vh;
    text-align: left;
    padding: 30px 0 0 80px;
    background: url(${adminBackground});
    background-size: cover;
`;
const Logo = styled.img`
    height: 27px;
`;

export function AdminFormLayout({
    subtitleDescription,
    data,
    backgroundImage,
    columnSize,
    formFor,
    generatedFamilyCode
}: AdminFormLayoutProps): React.ReactElement {
    const BackgroundImage = styled.div`
        position: absolute;
        right: 0;
        bottom: -5%;
        height: 380px;
        width: 580px;
        background: url(${backgroundImage});
        background-size: cover;
    `;
    const { t } = useTranslation();
    const history = useHistory();
    const [currentStep, setCurrentStep] = useState<'01' | '02'>('01');
    const [description, setDescription] = useState(subtitleDescription);
    const [optionsList, setOptionsList] = useState<DropdownData[]>([]);
    const [patientList, setPatientList] = useState<DropdownData[]>([]);
    const [disabled, setDisabled] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [formData, setFormData] = useState<any>({});
    const [hidden, setHidden] = useState<boolean>(true);
    const [redirectData, setRedirectData] = useState<string>('');
    const [dob, setDob] = useState<Date>();
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [firstNamePatient, setFirstNamePatient] = useState<string>('');
    const [lastNamePatient, setLastNamePatient] = useState<string>('');
    const [emailPatient, setEmailPatient] = useState<string>('');

    const handleChange = (
        state: string,
        value: string | Date | null | undefined | Timestamp
    ): void => {
        setFormData({
            ...formData,
            // eslint-disable-next-line no-nested-ternary
            [state]: value === 'true' ? true : value === 'false' ? false : value
        });
    };

    const handleEstablishmentChange = (state: string, value: string | undefined): void => {
        const results = optionsList.filter((item) => item.value === value);
        setFormData({ ...formData, [state]: value, establishmentName: results[0].name });
    };

    useEffect(() => {
        setFormData({ ...data });
    }, []);

    useEffect(() => {
        if (currentStep === '01') {
            setDescription(subtitleDescription);
        } else {
            setDescription(t('addFormDescription'));
        }
    }, [currentStep]);

    const isFormValid = (fields: FormField[]): void => {
        let isValid = true;
        fields.forEach((field) => {
            if (field.required === true) {
                if (
                    !formData[field.field] ||
                    (field.field === 'password' &&
                        formData[field.field] &&
                        formData[field.field].length < 6)
                ) {
                    isValid = false;
                }
            }
        });
        if (isValid) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    };

    useEffect(() => {
        if (currentStep === '01') {
            isFormValid(data.firstStepFormFields!);
        } else {
            isFormValid(data.secondStepFormFields!);
        }
    }, [formData]);

    useEffect(() => {
        if (currentStep === '02') {
            isFormValid(data.secondStepFormFields!);
        }
    }, [currentStep]);

    useEffect(() => {
        if (generatedFamilyCode) {
            handleChange('familyCode', generatedFamilyCode);
        }
    }, [generatedFamilyCode]);

    useEffect(() => {
        const getPatient = async (): Promise<void> => {
            const patient = await getPatientUser(formData.patient);
            if (patient?.firstName && patient?.lastName && patient?.emailId) {
                setFirstNamePatient(patient.firstName);
                setLastNamePatient(patient.lastName);
                setEmailPatient(patient.emailId);
            }
        };
        if (formFor === 'family' && formData.patient) {
            getPatient();
        }
    }, [formData.patient]);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (dob) {
            const timerDob = setTimeout(() => handleChange('dob', Timestamp.fromDate(dob)), 500);
            return (): void => {
                clearTimeout(timerDob);
            };
        }
    }, [dob]);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (startDate) {
            const timerStart = setTimeout(
                () => handleChange('startDate', Timestamp.fromDate(startDate)),
                500
            );
            return (): void => {
                clearTimeout(timerStart);
            };
        }
    }, [startDate]);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (endDate) {
            const timerEnd = setTimeout(
                () => handleChange('endDate', Timestamp.fromDate(endDate)),
                500
            );
            return (): void => {
                clearTimeout(timerEnd);
            };
        }
    }, [endDate]);

    const onNextButtonClicked = (): void => {
        if (!disabled) {
            setCurrentStep('02');
        }
    };

    const onCreateClicked = async (): Promise<void> => {
        if (!disabled && formFor) {
            if (formFor === 'establishment') {
                await createEstablishment(formData);
            } else if (
                formFor === 'family' &&
                firstNamePatient &&
                lastNamePatient &&
                emailPatient
            ) {
                await addFamily(formData, firstNamePatient, lastNamePatient, emailPatient);
            } else if (formFor === 'therapist') {
                await addTherapist(formData);
            } else if (formFor === 'patient') {
                await addPatient(formData);
            }
            setRedirectData(formFor);
            setOpenModal(true);
        }
    };

    const fieldWidth = (width: 'FULL' | 'HALF'): { m: number; width: string } => {
        return width === 'FULL' ? { m: 1, width: '340px' } : { m: 1, width: '162px' };
    };

    const getTextField = (field: FormField): ReactElement => {
        return (
            <TextField
                InputProps={{ inputProps: { min: 0 } }}
                key={field.placeholder}
                fullWidth={field.width === 'FULL'}
                sx={fieldWidth(field.width)}
                label={`${t(field.placeholder!)}${field.required ? '*' : ''}`}
                value={formData[field.field] ?? ''}
                type={field.type ?? 'text'}
                onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                    handleChange(field.field, event.target.value)
                }
            />
        );
    };

    const getPasswordField = (field: FormField): ReactElement => {
        return (
            <TextField
                key={field.placeholder}
                fullWidth={field.width === 'FULL'}
                sx={fieldWidth(field.width)}
                label={`${t(field.placeholder!)}${field.required ? '*' : ''}`}
                type={hidden ? 'password' : 'text'}
                value={formData[field.field] ?? ''}
                onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                    handleChange(field.field, event.target.value);
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={(): void => setHidden(!hidden)}
                                edge="end">
                                {hidden ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        );
    };

    const getDateField = (field: FormField): ReactElement => {
        return (
            <DatePickerCustom
                key={field.placeholder}
                label={`${t(field.placeholder!)}${field.required ? '*' : ''}`}
                value={
                    formData[field.field] ? new Date(formData[field.field].seconds * 1000) : null
                }
                sx={fieldWidth(field.width)}
                onDateChange={(newValue: Date): void => {
                    if (newValue && field.field === 'dob') {
                        setDob(newValue);
                    } else if (newValue && field.field === 'startDate') {
                        setStartDate(newValue);
                    } else if (newValue && field.field === 'endDate') {
                        setEndDate(newValue);
                    } else {
                        handleChange(field.field, null);
                    }
                }}
            />
        );
    };

    useEffect(() => {
        const getUserList = async (): Promise<void> => {
            const options = await getEstablishmentListDropdown();
            setOptionsList(options);
            // const patients = await getPatientDropdownList();
            // setPatientList(patients);
        };
        getUserList();
    }, []);

    useEffect(() => {
        if (currentStep === '02') {
            let establishment;
            let patient: Patient | null;
            const setAll = async (): Promise<void> => {
                establishment = await getEstablishment(formData.establishmentCode);
                if (formData.patient) {
                    patient = await getPatientUser(formData.patient);
                }
                if (patient && establishment) {
                    // add family
                    setFormData({
                        ...formData,
                        startDate: establishment?.startDate,
                        endDate: establishment?.endDate,
                        familyCode: patient?.familyCode
                    });
                } else {
                    // add patient or therapist
                    setFormData({
                        ...formData,
                        startDate: establishment?.startDate,
                        endDate: establishment?.endDate
                    });
                }
            };
            setAll();
        }
    }, [currentStep]);

    // set patient from establishment
    useEffect(() => {
        if (formData.establishmentCode) {
            const setPatientByEstablisment = async (): Promise<void> => {
                const patientListFromEstablisment = await getPatientFromEstablishment(
                    formData.establishmentCode
                );
                if (patientListFromEstablisment) {
                    // let listPatient: { familyCode: string | undefined; name: string | null | undefined; value: string | undefined; }[] = [];
                    const listPatient: DropdownData[] = [];
                    patientListFromEstablisment.forEach((patient) => {
                        listPatient.push(
                            new DropdownData(
                                `${patient.firstName} ${patient.lastName}`,
                                patient.id ? patient.id : '',
                                patient.familyCode ? patient.familyCode : ''
                            )
                        );
                    });
                    setPatientList(listPatient);
                }
            };
            setPatientByEstablisment();
        }
    }, [formData.establishmentCode]);

    const getSelectField = (field: FormField): ReactElement => {
        return (
            <FormControl>
                <InputLabel id={field.title} sx={{ mt: '10px', marginLeft: '10px' }}>{`${t(
                    field.placeholder!
                )}${field.required ? '*' : ''}`}</InputLabel>
                <Select
                    key={field.placeholder}
                    id={field.title}
                    fullWidth={field.width === 'FULL'}
                    sx={fieldWidth(field.width)}
                    label={`${t(field.placeholder!)}${field.required ? '*' : ''}`}
                    value={formData[field.field] ?? ''}
                    onChange={(event): void => {
                        if (field.dropdownOptions === 'establishment') {
                            handleEstablishmentChange(field.field, event.target.value);
                        } else {
                            handleChange(field.field, event.target.value);
                        }
                    }}>
                    {field.dropdownOptions === 'patient' &&
                        patientList.map(
                            (item): JSX.Element => {
                                return (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.name}
                                    </MenuItem>
                                );
                            }
                        )}
                    {field.dropdownOptions === 'establishment' &&
                        optionsList.map(
                            (item): JSX.Element => {
                                return (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.name}
                                    </MenuItem>
                                );
                            }
                        )}
                    {field.options &&
                        field.options.map(
                            (item): JSX.Element => {
                                return (
                                    <MenuItem key={item} value={item}>
                                        {item}
                                    </MenuItem>
                                );
                            }
                        )}
                </Select>
            </FormControl>
        );
    };

    const getFormFields = (fields: FormField[]): React.ReactElement[] => {
        return fields.map(
            (field): ReactElement => {
                if (field.type === 'text' || field.type === 'email' || field.type === 'number') {
                    return getTextField(field);
                }
                if (field.type === 'date') {
                    return getDateField(field);
                }
                if (field.type === 'password') {
                    return getPasswordField(field);
                }
                return getSelectField(field);
            }
        );
    };

    const setupFirstStepFields = (): React.ReactElement => {
        const fields = data.firstStepFormFields;
        return <Box sx={{ width: '715px' }}>{getFormFields(fields!)}</Box>;
    };

    const setupSecondStepFields = (): React.ReactElement => {
        const fields = data.secondStepFormFields;
        return <Box sx={{ width: columnSize }}>{getFormFields(fields!)}</Box>;
    };

    const nextButton = (): ReactElement => {
        return (
            <Button
                variant="primaryContained"
                color="primary"
                sx={{
                    my: 4,
                    width: '340px',
                    backgroundColor: disabled ? '#ffffff' : '',
                    color: disabled ? '#E3E6E8' : ''
                }}
                onClick={onNextButtonClicked}>
                {t('nextButtonTitle')}
            </Button>
        );
    };
    const createButton = (): ReactElement => {
        return (
            <Button
                variant="primaryContained"
                color="primary"
                sx={{
                    my: 5,
                    width: '340px',
                    backgroundColor: disabled ? '#ffffff' : '',
                    color: disabled ? '#E3E6E8' : ''
                }}
                onClick={(): void => {
                    onCreateClicked();
                }}>
                {t('createButtonTitle')}
            </Button>
        );
    };

    return (
        <FormContainer>
            <BackgroundImage />
            <Logo src={logo} alt="logo" />
            <Typography variant="h1" pt={7}>
                {t('addFormTitle')}
            </Typography>
            <Typography variant="body2" pt={1}>
                {t('addFormSubtitle', { step: currentStep, description })}
            </Typography>
            <Typography variant="subtitle2" pt={5} sx={{ color: '#5C6670' }}>
                {t('addFormRequiredFields')}
            </Typography>
            {currentStep === '01' ? setupFirstStepFields() : setupSecondStepFields()}
            {currentStep === '01' ? nextButton() : createButton()}
            <CompletedModal
                isModelOpen={openModal}
                setModelOpen={setOpenModal}
                onButtonClick={(): void => {
                    history.push(`/${redirectData}`);
                }}
            />
        </FormContainer>
    );
}
