import { Box, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { Patient } from '../../models/Patient';
import {
    getAllExerciseCountPerMonth,
    getExercises,
    getPatientExercises
} from '../../services/cloudFirestore';

interface PersonalisedExercisesProps {
    patient?: Patient;
}

export function PersonalisedExercises({ patient }: PersonalisedExercisesProps): React.ReactElement {
    const { t } = useTranslation();
    const [exerciseCount, setExerciseCount] = useState<number>(0);
    const [labelData, setLabelData] = useState<string[]>([]);
    const [startYear, setStartYear] = useState<string>('');
    const [familyListCount, setFamilyListCount] = useState<number[]>([]);
    const [therapistListCount, setTherapistListCount] = useState<number[]>([]);
    const [memoryListCount, setMemoryListCount] = useState<number[]>([]);
    const [memorinListCount, setMemorinListCount] = useState<number[]>([]);
    const [puzzleListCount, setPuzzleListCount] = useState<number[]>([]);
    const [monthToShow, setMonthToShow] = useState<string[]>([]);
    const [shortMonthToShow, setShortMonthToShow] = useState<string[]>([]);
    const [max, setMax] = useState<number>();
    const month = [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Aout',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre'
    ];
    const shortMonths = [
        'JAN',
        'FEV',
        'MAR',
        'AVR',
        'MAI',
        'JUIN',
        'JUIL',
        'AOUT',
        'SEPT',
        'OCT',
        'NOV',
        'DEC'
    ];

    useEffect(() => {
        const getData = async (): Promise<void> => {
            const data = patient ? await getPatientExercises(patient) : await getExercises();
            setExerciseCount(data.length);
            if (data.length < 50) {
                setMax(40);
            }
            if (data.length < 40) {
                setMax(30);
            }
            if (data.length < 30) {
                setMax(20);
            }
            const countList = await getAllExerciseCountPerMonth(data);
            const date = new Date();
            const currentMonth = date.getMonth();
            setMonthToShow(month.slice(0, currentMonth + 2));
            setShortMonthToShow(shortMonths.slice(0, currentMonth + 2));
            setMemoryListCount(countList.memoryListCount.slice(0, currentMonth + 2));
            setMemorinListCount(countList.memorinListCount.slice(0, currentMonth + 2));
            setPuzzleListCount(countList.puzzleListCount.slice(0, currentMonth + 2));
            setFamilyListCount(countList.familyListCount.slice(0, currentMonth + 2));
            setTherapistListCount(countList.therapistListCount.slice(0, currentMonth + 2));
            setStartYear(date.getFullYear().toString());
            const list: string[] = [];
            for (let i = 0; i <= 11; i += 1) {
                list.push(`${date.getFullYear()}/${date.getMonth() + 1}`);
                date.setMonth(date.getMonth() - 1);
            }
            setLabelData(list);
        };
        getData();
    }, []);

    const CHART_DATA = [
        {
            name: 'Thérapeutes',
            type: 'line',
            data: therapistListCount
        },
        {
            name: 'Familles',
            type: 'line',
            data: familyListCount
        },
        {
            name: 'Souven’in',
            type: 'bar',
            data: memoryListCount
        },
        {
            name: 'Puzzl’in',
            type: 'bar',
            data: puzzleListCount
        },
        {
            name: 'Memorin’in',
            type: 'bar',
            data: memorinListCount
        }
    ];

    const chartOptions: ApexCharts.ApexOptions = {
        colors: ['#69DBC8', '#002E5D', '#8200E8', '#00D1FF', '#0F0046'],
        chart: {
            locales: [
                {
                    name: 'fr',
                    options: {
                        months: monthToShow,
                        shortMonths: shortMonthToShow
                    }
                }
            ],
            defaultLocale: 'fr',
            toolbar: { show: false },
            zoom: { enabled: false },
            animations: { enabled: false },
            stacked: true
        },
        dataLabels: { enabled: false },
        labels: shortMonthToShow,
        markers: { size: 5 },
        legend: { show: true, itemMargin: { vertical: 25 } },
        yaxis: {
            max,
            tickAmount: 3
        },
        xaxis: { type: 'category' },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: (y: number) => {
                    if (typeof y !== 'undefined') {
                        return `${y.toFixed(0)}`;
                    }
                    return y;
                }
            }
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexGrow: 1,
                flexWrap: 'wrap',
                textAlign: 'left',
                paddingTop: '30px',
                '& > :not(style)': {
                    m: 1,
                    height: 469
                }
            }}>
            <Paper elevation={1} sx={{ padding: '13px', width: '100%' }}>
                <Typography variant="h5">
                    {t('personalisedExercises', { count: exerciseCount })}
                </Typography>
                <Box>
                    {therapistListCount && (
                        <ReactApexChart
                            series={CHART_DATA}
                            options={chartOptions}
                            width="100%"
                            height={375}
                        />
                    )}
                </Box>
            </Paper>
        </Box>
    );
}
