/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { blue } from '@mui/material/colors';
import { Avatar, Box, Button, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { createStyles, makeStyles, styled } from '@mui/styles';
import { Howl, Howler } from 'howler';
import { useLongPress } from 'use-long-press';
// import { CountdownCircleTimer, useCountdown } from 'react-countdown-circle-timer';
import { ExerciseHeader } from '../../components/ExerciseHeader';
import { Jigsaw } from '../../components/Jigsaw';
import { SuccessModal } from '../../components/SuccessModal';
import { FormModalAlert } from '../common/FormModalAlert';
import clueBtn from '../../assets/clueBtn.svg';
import helpIcon from '../../assets/helpIcon.svg';
import {
    countGiveUp,
    getExerciseItem,
    getPatientUser,
    getTherapistUser,
    updateClueCount
} from '../../services/cloudFirestore';
import { UserContext } from '../../providers/UserProvider';
import { Patient } from '../../models/Patient';
import { Exercise } from '../../models/Exercise';
import { ExerciseItem } from '../../models/ExerciseItem';
import { getImageKitUrlFrom } from '../../utilities/utils';
import { Therapist } from '../../models/Therapist';
import { Roles } from '../../models/Roles';
import { UserDataProp } from '../../models/userDataProp';
import { ExerciseType } from '../../models/ExerciseType';
import { DeactiveModall } from '../../components/ContainerModal';
import { Fireworks } from '../../components/confetti/firework';

const useStyles = makeStyles(() =>
    createStyles({
        profileImage: {
            display: 'inline',
            marginRight: '10px',
            width: '90px',
            height: '90px',
            alignSelf: 'center',
            justifySelf: 'center',
            objectFit: 'cover'
        },
        currentUserInitials: {
            display: 'flex',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            margin: '0'
        }
    })
);

const ModalButtons = styled(Button)(() => ({
    '@media (max-width: 598px)': {
        width: '100%',
        margin: '2% 0%'
    },
    '@media (min-width: 598px)': {
        width: '30%'
    }
}));

const ButtonContainer = styled(Box)({
    '@media (max-width: 598px)': {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    '@media (min-width: 598px)': {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row'
    }
});

const DefaultLogo = styled('div')({
    borderRadius: '50%',
    background: '#efe7d7',
    position: 'relative'
});

interface LocationState {
    exercises: Exercise;
}

export function PuzzleExercise(): JSX.Element {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [initials, setInitials] = useState<string>('');
    const location = useLocation<LocationState>();
    const canvasRef = useRef<HTMLDivElement>(null);
    const mounted = useRef(false);
    const [patient, setPatient] = useState<Patient>();
    const [exercise, setExercise] = useState<Exercise>();
    const [exerciseItem, setExerciseItem] = useState<ExerciseItem>();
    const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
    const [openFormModal, setOpenFormModal] = useState<boolean>(false);
    const [isInitialState, setInitailState] = useState(true);
    const [puzzleImage, setPuzzleImage] = useState<string>();
    const [difficultyLevel, setDifficultyLevel] = useState<number>(0);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [playFirework, setPlayFirework] = useState<boolean>(false);
    const [remainingTime, setRemainingTime] = useState<number>(10);
    const [author, setAuthor] = useState<Therapist>({
        firstName: undefined,
        lastName: undefined,
        emailId: undefined,
        dob: undefined,
        fonction: undefined,
        establishmentName: undefined,
        mobile: undefined,
        firstStepFormFields: [],
        secondStepFormFields: []
    });

    useEffect(() => {
        const getExerciseList = async (): Promise<void> => {
            if (localStorage.getItem('reload') === 'true') {
                localStorage.setItem('reload', 'false');
                window.location.reload();
            }
            if (location.state.exercises) {
                setExercise(location.state.exercises);
                if (location.state.exercises.authorId) {
                    const authorData = await getTherapistUser(location.state.exercises.authorId);
                    if (authorData) {
                        setAuthor(authorData);
                        if (authorData.firstName && authorData.lastName) {
                            setInitials(
                                authorData.firstName.charAt(0).toUpperCase() +
                                    authorData.lastName.charAt(0).toUpperCase()
                            );
                        }
                    }
                }
                if (
                    location.state.exercises.exerciseImages &&
                    location.state.exercises.exerciseImages[0] &&
                    typeof location.state.exercises.exerciseImages[0] === 'string'
                ) {
                    const item = await getExerciseItem(location.state.exercises.exerciseImages[0]);
                    if (item) {
                        setExerciseItem(item);
                        if (item.exerciseImage && typeof item.exerciseImage === 'string') {
                            setPuzzleImage(item.exerciseImage);
                        }
                    }
                }
            }
        };
        getExerciseList();
    }, [location.state]);

    useEffect(() => {
        const getExerciseList = async (): Promise<void> => {
            if (user && user?.id) {
                const patientData = await getPatientUser(user?.id);
                if (patientData && patientData.puzzleDifficultyLevel) {
                    setPatient(patientData);
                    setDifficultyLevel(patientData.puzzleDifficultyLevel);
                }
                if (user?.role === Roles.ADMIN || user.role === Roles.THERAPIST) {
                    setDifficultyLevel(3);
                }
            }
        };
        getExerciseList();
    }, []);

    // const {
    //     path,
    //     pathLength,
    //     stroke,
    //     strokeDashoffset,
    //     remainingTime,
    //     elapsedTime,
    //     size,
    //     strokeWidth
    // } = useCountdown({ isPlaying: true, duration: 10, colors: '#abc' });

    const resetExerciseItems = async (): Promise<void> => {
        if (user && exercise && user.role === Roles.PATIENT) {
            await countGiveUp(user, exercise);
        }
        history.push('/home');
    };

    // const audio = new Audio('/applause-sound-effect.mp3');
    const audio = new Howl({
        src: ['/applause-sound-effect.mp3'],
        html5: true,
        volume: 1
    });

    const start = (): void => {
        if (audio) {
            audio.play();
        }
    };

    const stopAudio = (): void => {
        if (audio) {
            audio.pause();
        }
    };

    // The puzzl'in exercice is done
    const onSuccess = async (): Promise<void> => {
        start();
        setPlayFirework(true);
        setOpenSuccessModal(true);
        setTimeout(() => {
            stopAudio();
            setOpenSuccessModal(false);
            setOpenFormModal(true);
            setPlayFirework(false);
        }, 3000);
    };

    // Update the Clue count if used and reset the count of ExerciseType
    const updateCount = async (): Promise<void> => {
        if (exercise && user && user.id && exercise.id && user.role === Roles.PATIENT) {
            await updateClueCount(exercise.id, user.id, ExerciseType.PUZZLE);
            const countErrorAndClue = localStorage.getItem('countErrorAndClue');
            if (countErrorAndClue) {
                const newCountErrorAndClue = Number(countErrorAndClue) + 1;
                localStorage.setItem('countErrorAndClue', newCountErrorAndClue.toString());
            }
        }
    };

    const renderPuzzle = (): void => {
        if (user?.role === Roles.PATIENT) {
            const startTimer = localStorage.getItem('startTime');
            if (!startTimer) {
                localStorage.setItem('startTime', new Date().toString());
            }
            const count = localStorage.getItem('countErrorAndClue');
            if (!count) {
                localStorage.setItem('countErrorAndClue', '0');
            }
        }
        if (mounted.current && puzzleImage) {
            // setInitailState(false);
            let xPieces;
            let yPieces;
            let width =
                window.innerWidth < window.innerHeight
                    ? window.innerWidth - 400
                    : window.innerHeight - 400;
            let height =
                window.innerWidth < window.innerHeight
                    ? window.innerWidth - 400
                    : window.innerHeight - 400;
            switch (difficultyLevel) {
                case 1:
                    xPieces = 2;
                    yPieces = 2;
                    width = window.innerHeight - 300;
                    height = window.innerHeight - 300;
                    break;
                case 2:
                    xPieces = 3;
                    yPieces = 2;
                    width = window.innerHeight - 300;
                    height = window.innerHeight - 300;
                    break;
                case 3:
                    xPieces = 3;
                    yPieces = 3;
                    width = window.innerHeight - 350;
                    height = window.innerHeight - 350;
                    break;
                case 4:
                    xPieces = 4;
                    yPieces = 3;
                    break;
                case 5:
                    xPieces = 4;
                    yPieces = 4;
                    break;
                case 6:
                    xPieces = 4;
                    yPieces = 5;
                    break;
                case 7:
                    xPieces = 5;
                    yPieces = 5;
                    break;
                case 8:
                    xPieces = 7;
                    yPieces = 7;
                    break;
                case 9:
                    xPieces = 10;
                    yPieces = 10;
                    break;
                case 10:
                    xPieces = 12;
                    yPieces = 12;
                    break;
                default:
                    xPieces = 2;
                    yPieces = 2;
                    break;
            }
            Jigsaw(
                window.innerWidth,
                window.innerHeight,
                'puzzle.jpg',
                getImageKitUrlFrom(puzzleImage, width, height),
                xPieces,
                yPieces,
                onSuccess,
                () => setOpenModal(true),
                () => updateCount(),
                helpIcon
            );
        }
    };

    let remainingTimeCount = 10;

    useEffect((): void => {
        setRemainingTime(remainingTimeCount);
    }, [remainingTimeCount]);

    const decreseTime = (): void => {
        // console.log('test');
        if (remainingTimeCount === 0) {
            setInitailState(false);
            return;
        }
        setTimeout(() => {
            remainingTimeCount -= 1;
            decreseTime();
        }, 1000);
    };

    // useEffect((): void => {
    //     // if (remainingTime === 0) {
    //     //     setInitailState(false);
    //     // } else {
    //     //     setTimeout(() => {
    //     //         setRemainingTime(remainingTime - 1);
    //     //     }, 1000);
    //     // }
    //     console.log('remainingTime', remainingTime);
    // }, [remainingTime]);

    useEffect(() => {
        if (difficultyLevel > 0 && puzzleImage) {
            if (mounted) {
                // setTimeout(() => {
                //     setInitailState(false);
                // }, 10000);
                setTimeout(() => {
                    renderPuzzle();
                }, 1000);
                decreseTime();
                // setTimeout(() => {
                //     setInitailState(false);
                // }, 10000);
                // setTimeout(() => {
                //     setRemainingTime(remainingTime - 1);
                // }, 2000);
            }
        }
        return (): void => {
            const createScript = document.getElementById('ZIM');
            const zimScript = document.getElementById('zimjs');
            createScript?.remove();
            zimScript?.remove();
        };
    }, [difficultyLevel, puzzleImage]);

    const handleCloseFormModal = (): void => {
        setOpenFormModal(false);
    };

    const isHeightOfXS = (): boolean => {
        const { innerWidth: width, innerHeight: height } = window;
        if (width < 550 || height < 550) {
            return true;
        }
        if (height <= 768 && height > 551) {
            return true;
        }
        return false;
    };

    const getPuzzleUI = (): JSX.Element => {
        return (
            <Box
                ref={canvasRef}
                // hidden={isInitialState}
                id="middle"
                width="100vw"
                height="100vh"
                overflow="hidden"
                p={2}
                // zIndex={isInitialState ? -100 : 100}
                // sx={{
                //     display: isInitialState ? 'none' : 'block'
                // }}
            />
        );
    };

    const bindLongPressQuitCancel = useLongPress(
        () => {
            setOpenModal(false);
        },
        { threshold: 1000 }
    );
    const bindLongPressQuit = useLongPress(
        () => {
            resetExerciseItems();
        },
        { threshold: 1000 }
    );

    const quitExerciseModal = (
        <div style={{ width: '90%', margin: 'auto' }}>
            <Typography variant="h3" color="#374653" sx={{ textAlign: 'center' }}>
                {t('wantToQuitExercise')}
            </Typography>
            <Typography
                variant="subtitle1"
                color="#374653"
                mt={2}
                sx={{ textAlign: 'center', fontWeight: 500 }}>
                {t('disclaimerQuitExercise')}
            </Typography>
            <ButtonContainer mt={3} mb={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                <ModalButtons
                    variant="outlined"
                    sx={{
                        border: '1px solid #374653',
                        boxShadow: '0px 0px 0px 2px rgba(92, 102, 112, 0.6)',
                        borderRadius: '12px',
                        width: '30%',
                        marginRight: '5%'
                    }}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...bindLongPressQuitCancel()}
                    onClick={(): void => setOpenModal(false)}>
                    <Typography variant="subtitle1" color="#374653">
                        {t('toCancel')}
                    </Typography>
                </ModalButtons>
                <ModalButtons
                    variant="outlined"
                    sx={{
                        border: '1px solid #8B004B',
                        boxShadow: '0px 0px 0px 2px rgba(208, 0, 112, 0.4)',
                        borderRadius: '12px',
                        width: '30%'
                    }}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...bindLongPressQuit()}
                    onClick={(): Promise<void> => resetExerciseItems()}>
                    <Typography variant="subtitle1" color="#D00070">
                        {t('yes')}
                    </Typography>
                </ModalButtons>
            </ButtonContainer>
        </div>
    );

    const getInitialUI = (): JSX.Element => {
        return (
            <>
                <ExerciseHeader
                    exerciseId={location.state.exercises.id}
                    exerciseName={location.state.exercises.exerciseName}
                    level={
                        patient && patient.puzzleDifficultyLevel ? patient.puzzleDifficultyLevel : 0
                    }
                    isPuzzle
                    isPuzzleInitial
                    isExerciseSelected
                    difficultyLevel={difficultyLevel}
                    handleClueClick={(): void => {}}
                />
                <Paper
                    variant="outlined"
                    sx={{
                        backgroundColor: blue[50],
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '6px',
                        marginTop: '100px',
                        marginRight: '2%',
                        marginLeft: '2%',
                        height: isHeightOfXS() ? '10vh' : '7vh'
                    }}>
                    {author.role !== undefined &&
                        author.role !== Roles.ADMIN &&
                        author &&
                        author &&
                        !author.profilePhoto && (
                            <DefaultLogo className={classes.profileImage}>
                                <div style={{ height: '5vmin' }}>
                                    <Typography
                                        variant="h2"
                                        sx={{ fontWeight: 'bold', color: '#374653' }}
                                        className={classes.currentUserInitials}>
                                        {initials}
                                    </Typography>
                                </div>
                            </DefaultLogo>
                        )}
                    {author &&
                        author.role !== undefined &&
                        author.profilePhoto &&
                        typeof author.profilePhoto === 'string' &&
                        author.role !== Roles.ADMIN && (
                            <Avatar
                                alt="userIcon"
                                src={getImageKitUrlFrom(author.profilePhoto)}
                                className={classes.profileImage}
                            />
                        )}
                    <Typography
                        variant="h4"
                        my={5}
                        color="secondary"
                        sx={{ marginTop: '25px', marginBottom: '25px' }}>
                        {t('puzzleExerciseTitle')}
                        <span
                            style={{
                                fontWeight: 700,
                                fontSize: '29px',
                                color: '#374653',
                                backgroundColor: '#ffffff',
                                border: '1px solid #E3E6E8',
                                borderRadius: '3px',
                                padding: '0px 5px'
                            }}>
                            {/* {remainingTime !== 10 ? remainingTime + 1 : remainingTime} */}
                            {remainingTime}
                        </span>
                        {/* {remainingTime + 1 > 1
                            ? t('puzzleExerciseTitleSecs')
                            : t('puzzleExerciseTitleSec')} */}
                        {t('puzzleExerciseTitleSecs')}
                    </Typography>
                </Paper>
                {puzzleImage && (
                    <Box
                        maxWidth="80%"
                        minWidth="50%"
                        minHeight="50%"
                        maxHeight="75%"
                        margin="auto">
                        <Box
                            component="img"
                            width="calc(100% - 20px)"
                            height="calc(100% - 20px)"
                            sx={{ objectFit: 'contain', marginTop: '10px' }}
                            src={getImageKitUrlFrom(puzzleImage)}
                            alt="Exercise"
                        />
                        {/* timer */}
                        <Box
                            sx={{
                                visibility: 'hidden',
                                position: 'absolute',
                                bottom: '10px',
                                right: 'calc(50% - 90px)',
                                zIndex: '1000',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#FFFFFF',
                                borderRadius: '50%'
                            }}>
                            {/* <CountdownCircleTimer
                                isPlaying
                                onComplete={(): void => {
                                    setInitailState(false);
                                }}
                                duration={10}
                                colors={['#FF0089', '#6B00AD', '#00D7D7', '#68B900']}
                                size={180}
                                colorsTime={[10, 6, 4, 0]}>
                                {({ remainingTime, color }) => (
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            fontFamily: 'Luciole-Regular',
                                            fontWeight: '400',
                                            fontSize: '24px',
                                            color
                                        }}>
                                        {t('...in')}
                                        <br />
                                        {remainingTime}
                                        <br />
                                        {t('seconds')}
                                    </Typography>
                                )}
                            </CountdownCircleTimer> */}
                        </Box>
                    </Box>
                )}
            </>
        );
    };

    return (
        <Box
            ref={mounted}
            flex={1}
            display="flex"
            flexDirection="column"
            height="100vh"
            width="100%">
            {/* {!author.emailId && (
                <Box sx={{ position: 'absolute', zIndex: '1000', bottom: '20px' }}>loading</Box>
            )} */}
            {/* {playFirework === true && <Fireworks play={playFirework} />} */}
            <SuccessModal
                isModelOpen={openSuccessModal}
                setModelOpen={setOpenSuccessModal}
                isSuccess
                isFirework
                nbModal={0}
            />
            {openFormModal && exerciseItem && (
                <FormModalAlert
                    exerciseReceived={exerciseItem}
                    closeModal={handleCloseFormModal}
                    exercise={exercise}
                    difficultyLevel={
                        patient && patient.puzzleDifficultyLevel ? patient.puzzleDifficultyLevel : 0
                    }
                    displayInfo
                />
            )}
            {isInitialState ? getInitialUI() : null}
            <Box sx={{ position: 'absolute', top: 0, zIndex: isInitialState ? -100 : 100 }}>
                {getPuzzleUI()}
                {isInitialState && (
                    <Box
                        sx={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            top: 0,
                            backgroundColor: 'white'
                        }}
                    />
                )}
            </Box>
            <DeactiveModall
                isModelOpen={openModal}
                setModelOpen={setOpenModal}
                body={quitExerciseModal}
                circleIcon
                onCancel={(): void => {
                    setOpenModal(false);
                }}
            />
        </Box>
    );
}
