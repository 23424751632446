/* eslint-disable no-param-reassign */
import { useState } from 'react';
import { AnyAction } from 'redux';
import { GRID } from '../typings';
import { compareArrays, copyGrid, createFullGrid, removeNumbers } from '../utils';

import { IReducer } from './interfaces';
import * as types from './types';
// import { settVar } from '../sudoku';

const initialState: IReducer = {};

function reducer(state = initialState, action: AnyAction): IReducer {
    switch (action.type) {
        case types.CREATE_GRID: {
            const solvedGrid = createFullGrid();
            const gridCopy = copyGrid(solvedGrid);
            const challengeGrid = removeNumbers(
                gridCopy
                // , 5
                // set a number to increase difficulty
            );
            const workingGrid = copyGrid(challengeGrid);
            const errorCount = 0;

            return {
                ...state,
                challengeGrid,
                solvedGrid,
                workingGrid,
                errorCount,
                isError: false
            };
        }

        case types.FILL_BLOCK: {
            if (state.workingGrid && state.solvedGrid) {
                if (state.solvedGrid[action.coords[0]][action.coords[1]] !== action.value) {
                    // alert('Incorrect option!');
                    return {
                        ...state,
                        errorCount: state.errorCount ? state.errorCount + 1 : 1,
                        isError: true
                    };

                    // return { ...state, workingGrid: [...state.workingGrid] as GRID };
                }
                state.workingGrid[action.coords[0]][action.coords[1]] = action.value;
                // if (compareArrays(state.workingGrid, state.solvedGrid)) alert('Yes, you did it!');
                return { ...state, workingGrid: [...state.workingGrid] as GRID, isError: false };
            }
            return state;
        }

        case types.SELECT_BLOCK:
            return {
                ...state,
                selectedBlock: action.coords,
                isError: false
            };
        default:
            return state;
    }
}

// eslint-disable-next-line import/no-default-export
export default reducer;
