/* eslint-disable no-plusplus */
/* eslint-disable curly */
import { GRID, INDEX } from '../../typings';

function copyGrid(grid: GRID): GRID {
    const gridCopy: GRID = [
        [0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0]
    ];

    for (let r: INDEX = 0; r < 9; r++)
        for (let c: INDEX = 0; c < 9; c++) gridCopy[r][c] = grid[r][c];

    return gridCopy;
}

// eslint-disable-next-line import/no-default-export
export default copyGrid;
