/**
 * Return a random sudoku grid index in the 0-8 range
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function getRandomIndex() {
    return Math.floor(Math.random() * Math.floor(9));
}

// eslint-disable-next-line import/no-default-export
export default getRandomIndex;
