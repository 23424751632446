import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import styled from 'styled-components';
import alertIcon from '../../assets/alert_blue.svg';
// eslint-disable-next-line import/no-cycle
import { UserContext } from '../../providers/UserProvider';
import { UserDataProp } from '../../models/userDataProp';

interface HeaderProps {
    text: string;
}

const Title = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#FFFFFF',
    padding: '10px',
    borderBottom: '1px solid #ACBCC7',
    borderTop: '1px solid #ACBCC7',
    width: '100%'
});

const Logo = styled.img`
    height: 18px;
    width: 18px;
    margin-right: 10px;
    object-fit: contain;
`;

const useStyles = makeStyles(() => ({
    spanBox: {
        background: '#C7F2FD',
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'center',
        padding: '15px',
        maxWidth: '800px',
        alignItems: 'center'
    }
}));

export function Header({ text }: HeaderProps): JSX.Element {
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Title>
            <span className={classes.spanBox}>
                <Logo src={alertIcon} alt="CloseButton" />
                <Typography
                    variant="subtitle1"
                    color="#06ACD6"
                    sx={{
                        fontWeight: 400,
                        textAlign: 'left'
                    }}>
                    {t(text)}
                </Typography>
            </span>
        </Title>
    );
}
