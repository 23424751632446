/* eslint-disable import/no-cycle */
import { Box, CircularProgress, Typography, useMediaQuery } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import youtubeLittleIcon from '../../assets/youtubeLittleIcon.png';
import { CentralLogo } from '../../components/Headers/CentralLogo';
import { Roles } from '../../models/Roles';
import { UserContext } from '../../providers/UserProvider';
import { UserDataProp } from '../../models/userDataProp';
import { InputComponent } from '../../components/FormComponents/InputComponent';
import { UsersVideoData } from '../../models/UsersVideoData';
import { addPersonalYoutubeVideo, getTherapistUser } from '../../services/cloudFirestore';
import { IdentifyPersonModal } from '../../components/ImageUpload/IdentifyPersonModal';
import { ImageFrame } from './ImageFrame';
import { SelectPatient } from '../../components/ImageUpload/SelectPatient';
import { clickAnalytics } from '../../services/analyticsFunction';

const useStyles = makeStyles(() => ({
    headerText: {
        '@media (max-width: 598px)': {
            margin: '5% 0'
        },
        '@media (min-width: 598px)': {
            margin: '3% 0'
        },
        display: 'flex'
    },
    headerLayoutMobile: {
        '@media (max-width: 598px)': {
            justifyContent: 'space-between'
        },
        '@media (min-width: 598px)': {
            justifyContent: 'center'
        }
    },
    textField: {
        marginBottom: '15px'
    }
}));

const Logo = styled.img`
    height: 32px;
    width: 32px;
    margin-right: 10px;
    object-fit: contain;
`;

interface AddYoutubeLinkProps {
    isYoutubeLink: Function;
}

export function AddYoutubeLink({ isYoutubeLink }: AddYoutubeLinkProps): JSX.Element {
    const isSmallWidth = !useMediaQuery('(min-width:500px)');
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const { t } = useTranslation();
    const classes = useStyles();
    const [url, setUrl] = useState<string>('');
    const [urlVideo, setUrlVideo] = useState<string>('');
    const [openEndModal, setOpenEndModal] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [stepper, setStepper] = useState<'01' | '02'>('01');
    const [data, setData] = useState<UsersVideoData>({
        authorId: user?.userId ?? undefined,
        title: undefined,
        communityId: undefined,
        role: undefined,
        patientList: []
    });

    useEffect(() => {
        const found = url.match(
            /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
        );
        if (found && found[1] === 'youtu.be/' && found[7] !== undefined) {
            setUrlVideo(url);
        }
        if (found && found[1] === 'watch?' && found[7] !== undefined) {
            setUrlVideo(`https://youtu.be/${found[7]}`);
        }
    }, [url]);

    useEffect(() => {
        if (urlVideo && data.title) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [urlVideo, data.title]);

    useEffect(() => {
        const getCommunityId = async (): Promise<void> => {
            if (user && user.id) {
                if (user.role === Roles.FAMILY && user.familyCode) {
                    setData({ ...data, communityId: user.familyCode, role: Roles.FAMILY });
                }
                if (user.role === Roles.THERAPIST) {
                    const therapistUser = await getTherapistUser(user.id);
                    if (therapistUser && therapistUser.establishmentCode) {
                        setData({
                            ...data,
                            communityId: therapistUser.establishmentCode,
                            role: Roles.THERAPIST
                        });
                    }
                }
                if (user.role === Roles.ADMIN) {
                    setData({
                        ...data,
                        communityId: user.id,
                        role: Roles.ADMIN
                    });
                }
            }
        };
        getCommunityId();
    }, []);

    const handleChange = (state: string, value: string | File | Date | boolean): void => {
        setData({ ...data, [state]: value });
    };

    const saveYoutubeLink = async (): Promise<void> => {
        if (data && urlVideo && user) {
            if (user?.role === Roles.THERAPIST && stepper === '01') {
                setStepper('02');
            } else {
                setLoading(true);
                const response = await addPersonalYoutubeVideo(data, urlVideo);
                if (response === 'success') {
                    clickAnalytics('new_youtube_link', user);
                    setOpenEndModal(true);
                }
            }
        }
    };

    const endModal = (
        <div style={{ padding: isSmallWidth ? '' : '20px 30px 40px 30px' }}>
            <Typography
                variant="h4"
                color="#004680"
                sx={{
                    fontWeight: 700,
                    textAlign: 'center',
                    marginBottom: '20px'
                }}>
                {t('videoImport')}
            </Typography>
            <Typography
                variant="h6"
                color="#004680"
                sx={{
                    fontWeight: 600,
                    textAlign: 'center',
                    paddingBottom: '40px'
                }}>
                {t('returnToMediathequeVideo')}
            </Typography>
            <Button
                fullWidth
                disableElevation
                variant="outlined"
                size="large"
                sx={{ border: '3px solid rgba(179, 4, 98, 0.69)', marginBottom: '10px' }}
                onClick={(): void => {
                    window.location.reload();
                }}>
                <Typography variant="subtitle1" color="#D00070">
                    {t('yes').toUpperCase()}
                </Typography>
            </Button>
            <Button
                fullWidth
                disableElevation
                variant="outlined"
                size="large"
                sx={{ border: '3px solid rgba(57, 139, 206, 0.69)', marginBottom: '10px' }}
                onClick={(): void => {
                    window.location.reload();
                }}>
                <Typography variant="subtitle1" color="#3387CC">
                    {t('noThanks')}
                </Typography>
            </Button>
        </div>
    );

    const stepOne = (): JSX.Element => {
        return (
            <div>
                <Box
                    sx={{
                        backgroundColor: '#F8F9F9',
                        boxShadow: 'inset 0px -1px 0px rgba(172, 188, 199, 0.4)',
                        marginBottom: '2%'
                    }}>
                    <div style={{ width: '100%', margin: 'auto' }}>
                        <div
                            className={classes.headerLayoutMobile}
                            style={{
                                display: 'flex',
                                margin: '0% 2%'
                            }}>
                            <div className={classes.headerText}>
                                <Logo src={youtubeLittleIcon} alt="logo" />
                                <Typography
                                    variant="h6"
                                    color="#374653"
                                    sx={{ fontWeight: 'bold', marginTop: '3px', color: '#6b01ad' }}>
                                    {t('myYoutubeLink')}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </Box>
                <Box
                    sx={{
                        width: isSmallWidth ? '90vw' : '70vw',
                        margin: 'auto'
                    }}>
                    <Button
                        fullWidth
                        variant="outlined"
                        size="large"
                        sx={{
                            margin: '5% 0 2% 0',
                            width: '100%',
                            fontSize: '20px',
                            lineHeight: '24px',
                            backgroundColor: 'rgba(208, 0, 112, 0.02)',
                            boxShadow: '0px 0px 0px 2px rgba(96, 168, 222, 0.9);',
                            border: '1px solid #3387CC',
                            color: '#3387CC'
                        }}
                        onClick={(): void => {
                            window.open('https://www.youtube.com/');
                        }}>
                        <Typography variant="h5">{t('searchOnYoutube')}</Typography>
                    </Button>
                    <div>
                        <Typography
                            variant="subtitle1"
                            color="#374653"
                            sx={{ textAlign: 'left', marginTop: '15px' }}>
                            {t('shortTitleVideoYoutube')}{' '}
                            <span style={{ color: 'red' }}>{t('required')}</span> :
                        </Typography>
                        <Typography
                            variant="body2"
                            color="#ACBCC7"
                            sx={{ textAlign: 'left', marginBottom: '5px' }}>
                            {t('max40Characters')}
                        </Typography>
                        <InputComponent
                            placeholder={t('exampleYoutube')}
                            value={data.title ?? null}
                            maximumLength={40}
                            type="text"
                            className={classes.textField}
                            isMultiline={false}
                            onTextChange={(value: string): void => handleChange('title', value)}
                        />
                    </div>
                    <Typography
                        variant="subtitle1"
                        color="#374653"
                        sx={{ textAlign: 'left', marginTop: '15px' }}>
                        {t('links')} <span style={{ color: 'red' }}>{t('required')}</span> :
                    </Typography>
                    <InputComponent
                        placeholder={t('linksPlaceholder')}
                        value={url ?? null}
                        maximumLength={60}
                        type="text"
                        className={classes.textField}
                        isMultiline={false}
                        onTextChange={(value: string): void => setUrl(value)}
                    />
                    <Typography
                        style={{
                            color: '#3746539C',
                            marginBottom: '12px',
                            textAlign: 'left',
                            fontSize: '0.85rem'
                        }}>
                        <span style={{ color: 'red' }}>{t('required')}</span> {t('champIsrequired')}
                    </Typography>
                    {loading ? (
                        <CircularProgress style={{ marginTop: '3%' }} />
                    ) : (
                        <Button
                            disabled={disabled}
                            fullWidth
                            variant="outlined"
                            size="large"
                            sx={{
                                margin: '3% 0 2%',
                                width: '100%',
                                fontSize: '20px',
                                lineHeight: '24px',
                                backgroundColor: 'rgba(208, 0, 112, 0.02)',
                                boxShadow: '0px 0px 0px 2px rgba(208, 0, 112, 0.4);',
                                border: '1px solid #8B004B'
                            }}
                            onClick={(): void => {
                                saveYoutubeLink();
                            }}>
                            <Typography variant="h5">
                                {user?.role === Roles.THERAPIST
                                    ? t('nextStep')
                                    : t('addLinkToFavorite')}
                            </Typography>
                        </Button>
                    )}
                </Box>
            </div>
        );
    };

    return (
        <div>
            <Box sx={{ boxShadow: 'inset 0px -1px 0px #E3E6E8' }}>
                <IdentifyPersonModal
                    isModelOpen={openEndModal}
                    setModelOpen={setOpenEndModal}
                    body={endModal}
                    onCancel={(): void => {
                        isYoutubeLink(false);
                    }}
                />
                <CentralLogo
                    hasBackButton
                    hasMediathequeLogo
                    hasProfilePhoto
                    isYoutubeLink={isYoutubeLink}
                />
            </Box>
            {stepper === '01' && <div>{stepOne()}</div>}
            {stepper === '02' && user?.role === Roles.THERAPIST && (
                <div style={{ height: '90vh' }}>
                    <ImageFrame
                        maxStep={user?.role === Roles.THERAPIST ? 2 : 1}
                        activeStep={1}
                        title={t('shareVideo')}
                        onBackClick={(): void => setStepper('01')}
                        onNextClick={saveYoutubeLink}
                        isDisabled
                        isSubmitButton
                        isloadButton>
                        <SelectPatient
                            setDataPatient={(content: (string | undefined)[]): void => {
                                if (content) {
                                    setData({
                                        ...data,
                                        patientList: content
                                    });
                                }
                            }}
                        />
                    </ImageFrame>
                </div>
            )}
        </div>
    );
}
