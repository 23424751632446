import * as React from 'react';
import { styled } from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import successTickIcon from '../assets/successTick.png';

const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 360,
    bgcolor: 'background.paper',
    borderRadius: '24px',
    boxShadow: 24,
    p: 4
};

const Logo = styled('img')(() => ({
    objectFit: 'inherit',
    width: '66px',
    height: '66px',
    margin: 'auto'
}));

const RootModal = styled(Box)(() => ({
    '@media (max-width:320px)': {
        width: '130px'
    },
    '@media (min-width:320px)': {
        width: '315px',
        maxHeight: '555px',
        overflowY: 'scroll'
    },
    '@media (min-width:480px)': {
        width: '430px',
        maxHeight: '555px',
        overflowY: 'scroll'
    },
    '@media (min-width:600px)': {
        width: '590px'
    },
    '@media (min-width:1025px)': {
        width: '900px',
        overflow: 'auto'
    },
    '@media (min-width:1281px)': {
        width: '1100px'
    }
}));

interface AdminProfileModalProps {
    isModelOpen: boolean;
    setModelOpen: Function;
    onButtonClick: Function;
}

export function AdminProfileModal({
    isModelOpen,
    setModelOpen,
    onButtonClick
}: AdminProfileModalProps): JSX.Element {
    const { t } = useTranslation();
    const body = (
        <RootModal sx={style}>
            <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
                <Logo src={successTickIcon} alt="circleCloseIcon" />
                <Typography
                    variant="h3"
                    color="#374653"
                    sx={{
                        margin: '20px 0px',
                        textAlign: 'center'
                    }}>
                    {t('updatedInformation')}
                </Typography>
                <Typography
                    variant="subtitle1"
                    color="#374653"
                    sx={{
                        marginBottom: '40px',
                        textAlign: 'center'
                    }}>
                    {t('updatedInformationSubheading')}
                </Typography>
                <Button
                    fullWidth
                    disableElevation
                    variant="outlined"
                    size="large"
                    sx={{ borderColor: '#5C6670', padding: '20px' }}
                    onClick={(): void => onButtonClick()}>
                    <Typography variant="subtitle1" color="#5C6670">
                        {t('goBackToDashboard')}
                    </Typography>
                </Button>
            </Box>
        </RootModal>
    );

    return (
        <div>
            <Modal
                open={isModelOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                {body}
            </Modal>
        </div>
    );
}
