/* eslint-disable no-case-declarations */
/* eslint-disable no-nested-ternary */
import {
    Box,
    Button,
    ButtonBase,
    Card,
    CardActionArea,
    CardContent,
    Grid,
    Skeleton,
    SvgIcon,
    Typography
} from '@mui/material';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Rating from '@mui/material/Rating';
import styled from 'styled-components';
import ExtensionIcon from '@mui/icons-material/Extension';
import TocIcon from '@mui/icons-material/Toc';
import InfiniteScroll from 'react-infinite-scroller';
import { useLongPress } from 'use-long-press';
import _, { indexOf } from 'lodash';
import { Timestamp } from 'firebase/firestore';
import personalVideoIcon from '../../assets/personalVideoIcon.png';
import mediaLittleIcon from '../../assets/mediaLittleIcon.png';
import youtubeLittleIcon from '../../assets/youtubeLittleIcon.png';
import { BrainSvgIcon } from '../../components/BrainSvgIcon';
import { TocSvgIcon } from '../../components/TocSvgIcon';
import { TocSvgIconGrey } from '../../components/TocSvgIconGrey';
import sudokuSvgIcon from '../../assets/sudokuSvgIcon.svg';
import sudokuSvgIconGrey from '../../assets/sudokuSvgIconGrey.svg';
import pongSvgIcon from '../../assets/pongSvgIcon.svg';
import pongSvgIconGrey from '../../assets/pongSvgIconGrey.svg';
import sudokuImg from '../../assets/sudokuImg.jpg';
import MemorinSvgIcon from '../../assets/MemorinSvgIcon.svg';
import MemorinSvgIconGrey from '../../assets/MemorinSvgIconGrey.svg';
import puzzleOverlay from '../../assets/puzzleOverlay.png';
import pinkStars from '../../assets/pinkStars.png';
import carnetMemoire from '../../assets/carnetMemoire.png';
import { ExerciseType } from '../../models/ExerciseType';
import { PatientLayout } from '../../layouts/PatientLayout';
import { TryExerciseAlerts } from '../common/TryExerciseAlerts';
import {
    getExerciseItem,
    getPatientExerciseList,
    getPatientUser,
    getPersonalVideo,
    getPersonalYoutubeVideo,
    getFamilyPicturesForMedia,
    increaseExerciseStarted,
    getPersonalYoutubeVideoFromTherapist,
    getPersonalVideos
} from '../../services/cloudFirestore';
import { Exercise } from '../../models/Exercise';
import { UserContext } from '../../providers/UserProvider';
import { PatientHomeData } from '../../models/PatientHomeData';
import { getImageKitUrlFrom } from '../../utilities/utils';
import { UserDataProp } from '../../models/userDataProp';
import { ExerciseItem } from '../../models/ExerciseItem';
import { MediaInformation } from '../../models/MediaInformation';
import { VideoCards } from '../../components/VideoCards';
import { ThumbnailsCards } from '../../components/ThumbnailsCards';
import { Roles } from '../../models/Roles';
import { ConsultationItem } from './ConsultationItem';
import { BrainSvgIconGrey } from '../../components/BrainSvgIconGrey';
import { LvlChoiseModal } from '../../components/LvlChoiseModal';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 0,
        marginLeft: '80px',
        marginTop: '60px',
        marginRight: '80px',
        marginBottom: '40px',
        justifyContent: 'center'
    },
    myExercises: {
        flexGrow: 0,
        marginRight: '16px',
        marginBottom: '16px',
        height: '280px',
        width: '220px',
        borderRadius: '9px'
    },
    media: {
        height: '200px',
        width: '200px',
        marginTop: '10px',
        marginLeft: '10px',
        marginRight: '10px',
        borderRadius: '6px'
    },
    boxMedia: {
        height: '270px',
        width: '200px',
        marginTop: '10px',
        marginLeft: '10px',
        marginRight: '10px',
        marginBottom: '10px',
        borderRadius: '6px',
        background:
            'linear-gradient(152.58deg, #6B00AD 14.26%, rgba(107, 0, 173, 0.7) 55.11%, rgba(107, 0, 173, 0.5) 94.49%)'
    },
    patientSalutation: {
        fontSize: '30px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '36px',
        letterSpacing: '-0.02em',
        textAlign: 'center',
        color: '#005398',
        borderRadius: '3px',
        marginTop: '25px',
        marginBottom: '20px'
    },
    patientSalutationBackground: {
        background: '#E3E6E8',
        padding: '8px 30px',
        borderRadius: '10px'
    },
    patientSalutationSubheading: {
        fontSize: '28px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '34px',
        letterSpacing: '-0.02em',
        textAlign: 'center',
        color: '#374653'
    },
    exerciseName: {
        fontSize: '22px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '25px',
        height: '54px',
        paddingTop: '3px',
        letterSpacing: '-0.02em',
        textAlign: 'center',
        color: '#005398',
        width: '191px'
    },
    cardContent: {
        height: '69px',
        display: 'grid',
        overflow: 'hidden',
        paddingTop: '3px !important'
    },
    deSelectedCard: {
        width: '100%',
        cursor: 'pointer',
        borderRadius: '9px'
    },
    divImageDisplay: {
        animationName: `$fade-out`,
        animationDuration: '2s'
    },
    '@keyframes fade-out': {
        from: {
            opacity: 1
        },
        to: {
            opacity: 0
        }
    }
}));

const ImageBox = styled(Box)({
    position: 'relative'
});

const ImageContainer = styled('img')({
    height: '210px',
    width: '100%',
    objectFit: 'cover'
});

const OverlayContainer = styled('img')({
    borderRadius: '9px',
    position: 'absolute',
    left: '0px',
    height: '98%',
    width: '100%',
    objectFit: 'cover'
});

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: '#FF0089'
    }
});

const ImageInfoContainer = styled.div`
    position: absolute;
    z-index: 2;
    height: 36px;
    width: 36px;
    margin-top: 15px;
    margin-left: 170px;
    background-color: white;
    border-radius: 50%;
    color: rgba(251, 251, 251, 1);
    max-width: 150px;
    align-items: center;
    display: flex;
`;

const Logo = styled.img`
    height: 32px;
    width: 32px;
    object-fit: contain;
`;

interface ImageCardsProps {
    url: string;
    alt: string;
    bgColor?: string;
}

function ImageCards({ url, alt, bgColor }: ImageCardsProps): JSX.Element {
    const classes = useStyles();
    const [isImgLoaded, setIsImgLoaded] = useState(false);
    return (
        <>
            <ImageContainer
                className={classes.media}
                src={url}
                alt={alt}
                // eslint-disable-next-line no-nested-ternary
                style={{ visibility: !bgColor ? 'visible' : isImgLoaded ? 'visible' : 'hidden' }}
                onLoad={(): void => setIsImgLoaded(true)}
            />
            {bgColor && (
                <Box
                    className={`${classes.media} ${isImgLoaded ? classes.divImageDisplay : null}`}
                    style={{
                        backgroundColor: bgColor,
                        visibility: 'visible',
                        opacity: isImgLoaded ? 0 : 1,
                        position: 'absolute',
                        top: 0,
                        zIndex: 50
                    }}
                />
            )}
        </>
    );
}

export function Home(): JSX.Element {
    const { t } = useTranslation();
    const classes = useStyles();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const history = useHistory();
    const [exerciseListToShow, setExerciseListToShow] = useState<PatientHomeData[]>([]);
    const [exerciseList, setExerciseList] = useState<Exercise[]>([]);
    const [selectedExercise, setSelectedExercise] = useState<Exercise | null>(null);
    const [renderModal, setRenderModal] = useState(false);
    const [memoryDifficultyLevel, setMemoryDifficultyLevel] = useState<number>(0);
    const [memorinDifficultyLevel, setMemorinDifficultyLevel] = useState<number>(0);
    const [sudokuDifficultyLevel, setSudokuDifficultyLevel] = useState<number>(0);
    const [pongDifficultyLevel, setPongDifficultyLevel] = useState<number>(0);
    const [puzzleDifficultyLevel, setPuzzleDifficultyLevel] = useState<number>(0);
    const [reorderDifficultyLevel, setReorderDifficultyLevel] = useState<number>(0);
    const memoryChoixByDificulty = [3, 4, 4, 4, 6, 4, 5, 5, 6, 6];
    const memorinCartesByDificulty = [4, 6, 8, 10, 12, 16, 20, 24, 30, 36];
    const puzzlePiecesByDificulty = [4, 6, 9, 12, 16, 20, 25, 50, 100, 150];
    const sudokuFindByDificulty = [10, 15, 20, 25, 30, 35, 45, 55, 65, 70];
    const pongFindByDificulty = [5, 7, 10, 15, 20, 20, 25, 25, 30, 30];
    const reorderFindByDifficulty = [2, 2, 3, 4, 5, 5, 5, 5, 5, 5];
    const [patientStatistics, setPatientStatistics] = useState<{ [key: string]: number }>({});
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [mediaList, setMediaList] = useState<any[]>([]);
    const [adminListId, setAdminListId] = useState<string[]>([]);
    const [active, setActive] = useState<boolean>(false);
    const [consultationItem, setConsultationItem] = useState<ExerciseItem | MediaInformation>();
    const [randomImage, setRandomImage] = useState<number | undefined>(0);
    const [exerciseTypeToShow, setExerciseTypeToShow] = useState<ExerciseType | undefined>();
    const [mediaTypeToShow, setMediaTypeToShow] = useState<
        'video' | 'picture' | 'videoYT' | undefined
    >();
    // const [timeOldClick, setTimeOldClick] = useState<number | undefined>();
    const [firstClickAll, setFirstClickAll] = useState<boolean>(false);
    const [firstClickMemory, setFirstClickMemory] = useState<boolean>(false);
    const [firstClickMemorin, setFirstClickMemorin] = useState<boolean>(false);
    const [firstClickPuzzle, setFirstClickPuzzle] = useState<boolean>(false);
    const [firstClickAllMedia, setFirstClickAllMedia] = useState<boolean>(false);
    const [firstClickPicture, setFirstClickPicture] = useState<boolean>(false);
    const [firstClickVideo, setFirstClickVideo] = useState<boolean>(false);
    const [firstClickVideoYT, setFirstClickVideoYT] = useState<boolean>(false);
    const [isConsultation, setIsConsultation] = useState<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [mediaListToSend, setMediaListToSend] = useState<any[]>([]);
    const [nbElementMediaToDisplay, setNbElementMediaToDisplay] = useState<number>(6);
    const [nbElementExoToDisplay, setNbElementExoToDisplay] = useState<number>(6);
    const uidToDisable = [
        'fea7072c-0de2-4b12-94b2-f45ae1c1ba9d',
        'd77f15f4-250e-44bc-9c0a-54c40859c634',
        '62f600e2-3293-492a-9d43-aaba73db5fe1',
        '90e2d8d2-a560-4539-aaa0-ded9a43ad82c'
    ];
    const [openLvlModal, setOpenLvlModal] = useState<boolean>(false);
    const [alreaydyOpenLvlModal, setAlreadyOpenLvlModal] = useState<boolean>(false);
    const [dataExercise, setDataExercise] = useState<PatientHomeData>();
    const [lvlArray, setLvlArray] = useState<number[]>([]);

    const handleCloseModal = (): void => {
        setRenderModal(false);
    };

    const getRandomIndex = (noOfItems: number): number => {
        const randomIndex = Math.floor(Math.random() * noOfItems);
        return randomIndex;
    };

    const isApple = /iPhone|iPad|iPod|Macintosh/i.test(navigator.userAgent);

    const getAllVideoItem = async (): Promise<MediaInformation[] | null> => {
        let list: MediaInformation[] = [];
        if (user && user.familyCode && user.establishmentCode) {
            if (adminListId && adminListId.length > 0) {
                adminListId.forEach(async (element) => {
                    const videoPatientFromAdmin = await getPersonalVideo(element);
                    const videoYoutubePatientFromAdmin = await getPersonalYoutubeVideo(element);
                    if (videoPatientFromAdmin && videoPatientFromAdmin.length > 0) {
                        list = list.concat(videoPatientFromAdmin);
                    }
                    if (videoYoutubePatientFromAdmin && videoYoutubePatientFromAdmin.length > 0) {
                        list = list.concat(videoYoutubePatientFromAdmin);
                    }
                });
            }
            const videoPatientFromFamily = await getPersonalVideo(user.familyCode);
            const videoPatientFromEstablishment = await getPersonalVideo(user.establishmentCode);
            const videoYoutubePatientFromFamily = await getPersonalYoutubeVideo(user.familyCode);
            const videoYoutubePatientFromEstablishment = await getPersonalYoutubeVideoFromTherapist(
                user.establishmentCode,
                user.familyCode
            );
            if (videoPatientFromFamily && videoPatientFromFamily.length > 0) {
                list = list.concat(videoPatientFromFamily);
            }
            if (videoPatientFromEstablishment && videoPatientFromEstablishment.length > 0) {
                list = list.concat(videoPatientFromEstablishment);
            }
            if (videoYoutubePatientFromFamily && videoYoutubePatientFromFamily.length > 0) {
                list = list.concat(videoYoutubePatientFromFamily);
            }
            if (
                videoYoutubePatientFromEstablishment &&
                videoYoutubePatientFromEstablishment.length > 0
            ) {
                list = list.concat(videoYoutubePatientFromEstablishment);
            }
        }
        if (list.length > 0) {
            return list;
        }
        return null;
    };

    const checkArrayExerciseItem = (array: ExerciseItem[], itemToCheck: ExerciseItem): boolean => {
        const found = array.some((el) => el.id === itemToCheck.id);
        return found;
    };

    const checkArrayMediaInformation = (
        array: MediaInformation[],
        itemToCheck: string
    ): boolean => {
        const found = array.some((el) => el.videoUrl === itemToCheck);
        return found;
    };

    const getMediaInformation = (
        object: MediaInformation[],
        valueCompare: string
    ): MediaInformation | undefined => {
        const result = Object.entries(object).find(([key, value]) => {
            if (value.videoUrl === valueCompare) {
                return true;
            }
            return false;
        });
        if (result && result[1]) {
            return result[1];
        }
        return undefined;
    };

    let timer: NodeJS.Timeout | null = null;

    useEffect(() => {
        if (consultationItem) {
            timer = setTimeout(() => {
                setConsultationItem(mediaListToSend[mediaListToSend.indexOf(consultationItem) + 1]);
            }, 6000);
        } else {
            if (timer) {
                clearTimeout(timer);
            }
            timer = null;
        }
    }, [consultationItem]);

    const stopTimer = (): void => {
        if (timer) {
            clearTimeout(timer);
        }
        timer = null;
    };

    useEffect(() => {
        const getPatientVideoItem = async (): Promise<void> => {
            if (user && user.familyCode && user.establishmentCode) {
                const youtubeVideoList = await getPersonalVideos(
                    user.familyCode,
                    user.establishmentCode,
                    'youtubeVideo'
                );
                const videoList = await getPersonalVideos(
                    user.familyCode,
                    user.establishmentCode,
                    'video'
                );
                if (youtubeVideoList) {
                    youtubeVideoList.forEach((item) => {
                        videoList?.push(item);
                    });
                }
                const pictureList: {
                    authorType: Roles | undefined;
                    createdDate: Timestamp | null | undefined;
                    data: ExerciseItem;
                }[] = await getFamilyPicturesForMedia(user?.familyCode);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const mediaArray: any = [];
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const tmpMediaArray: any[] = [];

                videoList?.forEach((item) => {
                    tmpMediaArray.push(item);
                });
                pictureList.forEach((item) => {
                    tmpMediaArray.push(item);
                });
                tmpMediaArray.sort(
                    (
                        x: {
                            authorType: Roles | undefined;
                            createdDate: Timestamp | null | undefined;
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            data: any;
                        },
                        y: {
                            authorType: Roles | undefined;
                            createdDate: Timestamp | null | undefined;
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            data: any;
                        }
                    ): number => {
                        if (x.createdDate && y.createdDate) {
                            const date1 = new Date(x.createdDate.toDate()).getTime();
                            const date2 = new Date(y.createdDate.toDate()).getTime();

                            return date2 - date1;
                        }
                        return 0;
                    }
                );
                tmpMediaArray.sort(
                    (
                        x: {
                            authorType: Roles | undefined;
                            createdDate: Timestamp | null | undefined;
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            data: any;
                        },
                        y: {
                            authorType: Roles | undefined;
                            createdDate: Timestamp | null | undefined;
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            data: any;
                        }
                    ): number => {
                        if (x.authorType === Roles.ADMIN && y.authorType !== Roles.ADMIN) {
                            return 1;
                        }
                        if (x.authorType !== Roles.ADMIN && y.authorType === Roles.ADMIN) {
                            return -1;
                        }
                        return 0;
                    }
                );
                tmpMediaArray.forEach((item) => {
                    mediaArray.push(item.data);
                });
                setMediaList(mediaArray);
                setMediaListToSend(mediaArray);
            }
        };

        const getExerciseListToShow = async (): Promise<void> => {
            if (user && user.familyCode && user.establishmentCode) {
                const dataList: PatientHomeData[] = [];
                const adminList: string[] = [];
                await Promise.all(
                    exerciseList.map(async (exercise) => {
                        if (exercise.exerciseImages && exercise.exerciseImages[0]) {
                            if (
                                exercise.exerciseType === ExerciseType.MEMORY ||
                                exercise.exerciseType === ExerciseType.MEMORIN
                            ) {
                                const index = getRandomIndex(exercise.exerciseImages.length);
                                const item = await getExerciseItem(
                                    exercise.exerciseImages[index] ?? exercise.exerciseImages[0]
                                );
                                if (
                                    exercise &&
                                    item &&
                                    typeof item.exerciseImage === 'string' &&
                                    index >= 0
                                ) {
                                    dataList.push({
                                        exercise,
                                        exerciseImage: item.exerciseImage,
                                        random: index
                                    });
                                }
                            }
                            if (
                                exercise.exerciseType === ExerciseType.PUZZLE ||
                                exercise.exerciseType === ExerciseType.SUDOKU ||
                                exercise.exerciseType === ExerciseType.PONG ||
                                exercise.exerciseType === ExerciseType.ORDER
                            ) {
                                const item = await getExerciseItem(exercise.exerciseImages[0]);
                                if (exercise && item && typeof item.exerciseImage === 'string') {
                                    dataList.push({
                                        exercise,
                                        exerciseImage: item.exerciseImage
                                    });
                                }
                            }
                            if (
                                exercise.authorType === Roles.ADMIN &&
                                exercise.authorId &&
                                !adminList.includes(exercise.authorId)
                            ) {
                                adminList.push(exercise.authorId);
                            }
                        }
                    })
                );
                dataList.sort((x: PatientHomeData, y: PatientHomeData): number => {
                    if (
                        x.exercise &&
                        x.exercise.createdDate &&
                        y.exercise &&
                        y.exercise.createdDate
                    ) {
                        const date1 = new Date(x.exercise.createdDate.toDate()).getTime();
                        const date2 = new Date(y.exercise.createdDate.toDate()).getTime();

                        return date2 - date1;
                    }
                    return 0;
                });
                dataList.sort((x: PatientHomeData, y: PatientHomeData): number => {
                    if (
                        x.exercise.authorType === Roles.ADMIN &&
                        y.exercise.authorType !== Roles.ADMIN
                    ) {
                        return 1;
                    }
                    if (
                        x.exercise.authorType !== Roles.ADMIN &&
                        y.exercise.authorType === Roles.ADMIN
                    ) {
                        return -1;
                    }

                    return 0;
                });
                setExerciseListToShow(dataList);
                setAdminListId(adminList);
            }
        };
        if (active !== false && exerciseList && exerciseList.length > 0) {
            getPatientVideoItem();
        }
        if (active === false && exerciseList && exerciseList.length > 0) {
            getExerciseListToShow();
        }
    }, [active, exerciseList]);

    useEffect(() => {
        // set css to body (bug after memorin)
        document.body.style.overflow = 'auto';

        const getExerciseList = async (): Promise<void> => {
            localStorage.clear();
            if (user && user.familyCode && user.establishmentCode) {
                const exercises = await getPatientExerciseList(user);
                if (exercises && exercises.length > 0) {
                    setExerciseList(exercises);
                }
                if (user?.id) {
                    const patientData = await getPatientUser(user?.id);
                    if (patientData?.statistics) {
                        const tempStatistics: { [key: string]: number } = {};
                        // eslint-disable-next-line array-callback-return
                        patientData.statistics.map((stat): void => {
                            tempStatistics[stat.exerciseId] = stat.timeAndLevel.length;
                        });
                        setPatientStatistics(tempStatistics);
                    }
                    if (patientData?.memoryDifficultyLevel) {
                        setMemoryDifficultyLevel(patientData?.memoryDifficultyLevel);
                    }
                    if (patientData?.puzzleDifficultyLevel) {
                        setPuzzleDifficultyLevel(patientData?.puzzleDifficultyLevel);
                    }
                    if (patientData?.memorinDifficultyLevel) {
                        setMemorinDifficultyLevel(patientData?.memorinDifficultyLevel);
                    }
                    if (patientData?.sudokuDifficultyLevel) {
                        setSudokuDifficultyLevel(patientData?.sudokuDifficultyLevel);
                    }
                    if (patientData?.pongDifficultyLevel) {
                        setPongDifficultyLevel(patientData?.pongDifficultyLevel);
                    }
                    if (patientData?.reorderDifficultyLevel) {
                        setReorderDifficultyLevel(patientData?.reorderDifficultyLevel);
                    }
                }
            }
        };
        getExerciseList();
    }, []);

    const onExerciseSelect = async (data: PatientHomeData): Promise<void> => {
        if (alreaydyOpenLvlModal === false) {
            setDataExercise(data);
            setAlreadyOpenLvlModal(true);
            switch (data.exercise.exerciseType) {
                case ExerciseType.MEMORY:
                    setLvlArray(memoryChoixByDificulty);
                    break;
                case ExerciseType.MEMORIN:
                    setLvlArray(memorinCartesByDificulty);
                    break;
                case ExerciseType.PUZZLE:
                    setLvlArray(puzzlePiecesByDificulty);
                    break;
                case ExerciseType.SUDOKU:
                    setLvlArray(sudokuFindByDificulty);
                    break;
                case ExerciseType.PONG:
                    setLvlArray(pongFindByDificulty);
                    break;
                case ExerciseType.ORDER:
                    setLvlArray(reorderFindByDifficulty);
                    break;
                default:
                    break;
            }
            setOpenLvlModal(true);
            return;
        }
        if (
            (data.exercise.exerciseType === ExerciseType.MEMORY ||
                data.exercise.exerciseType === ExerciseType.MEMORIN) &&
            data
        ) {
            const { random } = data;
            setRandomImage(random);
        }
        setSelectedExercise(data.exercise);
        if (
            data.exercise.authorType !== 'ADMIN' &&
            data.exercise.id &&
            (!patientStatistics[data.exercise.id] ||
                (patientStatistics[data.exercise.id] - 1) % 5 === 0)
        ) {
            setRenderModal(true);
            // to correct bug on long click on exercise create by admin
            setRenderModal(true);
        } else {
            if (user && user.role === Roles.PATIENT) {
                await increaseExerciseStarted(user, data.exercise);
            }
            switch (data.exercise.exerciseType) {
                case ExerciseType.MEMORY:
                case ExerciseType.MEMORIN:
                    history.push({
                        pathname: `/${data.exercise.exerciseType.toLowerCase()}/${
                            data.exercise.id
                        }`,
                        state: {
                            exercises: data.exercise,
                            random: data.random,
                            numberOfPlaing:
                                data.exercise.id && patientStatistics[data.exercise.id]
                                    ? patientStatistics[data.exercise.id]
                                    : 0
                        }
                    });
                    break;
                case ExerciseType.PUZZLE:
                    if (data.exercise.id) {
                        localStorage.setItem('reload', 'true');
                    }
                    history.push({ pathname: `/puzzl'in`, state: { exercises: data.exercise } });
                    break;
                case ExerciseType.SUDOKU:
                    history.push({ pathname: `/sudok'in`, state: { exercises: data.exercise } });
                    break;
                case ExerciseType.PONG:
                    history.push({ pathname: `/pong'in`, state: { exercises: data.exercise } });
                    break;
                case ExerciseType.ORDER:
                    history.push({ pathname: `/ordon'in`, state: { exercises: data.exercise } });
                    break;
                default:
                    history.push(`/404`);
            }
        }
    };

    const bindLongPress = useLongPress(
        async (event, index) => {
            const tempExercice = exerciseListToShow[Number(index.context)];
            await onExerciseSelect(tempExercice);
        },
        { threshold: 1000 }
    );

    const isItemDisplay = (item: PatientHomeData): boolean => {
        if (exerciseTypeToShow === undefined) {
            return true;
        }
        if (item.exercise.exerciseType === exerciseTypeToShow) {
            return true;
        }
        return false;
    };

    const getExerciseData = (): JSX.Element[] | null => {
        if (exerciseListToShow) {
            const JSXToReturn = exerciseListToShow
                .slice(0, nbElementExoToDisplay)
                .map((item, index) => {
                    let bgColor;
                    const number = index % 4;
                    switch (number) {
                        case 0:
                            bgColor = '#FF008980';
                            break;
                        case 1:
                            bgColor = '#3DECCF80';
                            break;
                        case 2:
                            bgColor = '#458ff680';
                            break;
                        case 3:
                            bgColor = '#6B00AD80';
                            break;
                        default:
                            break;
                    }
                    return (
                        <Grid
                            item
                            xs
                            sx={{
                                flexGrow: '0 !important',
                                display: isItemDisplay(item) ? 'block' : 'none'
                            }}
                            // eslint-disable-next-line react/no-array-index-key
                            key={`${item.exercise.exerciseName}+${index}`}>
                            <Card
                                className={classes.myExercises}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...bindLongPress(index)}
                                onClick={async (): Promise<void> => onExerciseSelect(item)}>
                                <CardActionArea>
                                    {item.exercise && (
                                        <ImageInfoContainer>
                                            <div style={{ marginTop: '8px', marginLeft: '3px' }}>
                                                {
                                                    // eslint-disable-next-line no-nested-ternary
                                                    item.exercise.exerciseType ===
                                                    ExerciseType.MEMORY ? (
                                                        <div
                                                            style={{
                                                                width: '20px',
                                                                height: '20px',
                                                                marginTop: '-13px',
                                                                marginLeft: '1px'
                                                            }}>
                                                            <BrainSvgIcon />
                                                        </div>
                                                    ) : item.exercise.exerciseType ===
                                                      ExerciseType.MEMORIN ? (
                                                        <img
                                                            src={MemorinSvgIcon}
                                                            alt="Memorin"
                                                            style={{
                                                                width: '20px',
                                                                height: '20px',
                                                                marginTop: '-3px',
                                                                marginLeft: '4.5px'
                                                            }}
                                                        />
                                                    ) : item.exercise.exerciseType ===
                                                      ExerciseType.SUDOKU ? (
                                                        <img
                                                            src={sudokuSvgIcon}
                                                            alt="sudok'in"
                                                            style={{
                                                                width: '20px',
                                                                height: '20px',
                                                                marginTop: '-4.5px',
                                                                marginLeft: '5px'
                                                            }}
                                                        />
                                                    ) : item.exercise.exerciseType ===
                                                      ExerciseType.PONG ? (
                                                        <img
                                                            src={pongSvgIcon}
                                                            alt="pong'in"
                                                            style={{
                                                                width: '20px',
                                                                height: '20px',
                                                                marginTop: '-4.5px',
                                                                marginLeft: '5px'
                                                            }}
                                                        />
                                                    ) : item.exercise.exerciseType ===
                                                      ExerciseType.ORDER ? (
                                                        <div
                                                            style={{
                                                                width: '20px',
                                                                height: '20px',
                                                                marginTop: '-13px',
                                                                marginLeft: '3.5px',
                                                                color: '#FF0089'
                                                            }}>
                                                            <TocIcon />
                                                        </div>
                                                    ) : (
                                                        <div
                                                            style={{
                                                                width: '20px',
                                                                height: '20px',
                                                                marginTop: '-13px',
                                                                marginLeft: '3.5px',
                                                                color: '#FF0089'
                                                            }}>
                                                            <ExtensionIcon />
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </ImageInfoContainer>
                                    )}
                                    <ImageBox>
                                        <ImageCards
                                            url={getImageKitUrlFrom(item.exerciseImage)}
                                            alt="selectedImage"
                                            bgColor={bgColor}
                                        />
                                    </ImageBox>
                                    <CardContent
                                        className={classes.cardContent}
                                        style={{
                                            paddingBottom: '12px'
                                        }}>
                                        <Typography
                                            className={classes.exerciseName}
                                            variant="h4"
                                            sx={{
                                                fontWeight: 500,
                                                fontFamily: 'Luciole-Regular',
                                                display: '-webkit-box',
                                                WebkitBoxOrient: 'vertical',
                                                WebkitLineClamp: '2',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}>
                                            {item.exercise.exerciseName}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    );
                });
            return JSXToReturn;
        }
        return null;
    };

    useEffect(() => {
        if (mediaTypeToShow === undefined) {
            setMediaListToSend(mediaList);
        } else if (mediaTypeToShow === 'picture') {
            const tempArray: React.SetStateAction<unknown[]> = [];
            setMediaListToSend([]);
            // eslint-disable-next-line array-callback-return
            mediaList.map((item) => {
                if (!Object.prototype.hasOwnProperty.call(item, 'videoUrl')) {
                    tempArray.push(item);
                }
            });
            setMediaListToSend(tempArray);
        } else if (mediaTypeToShow === 'video') {
            const tempArray: React.SetStateAction<unknown[]> = [];
            setMediaListToSend([]);
            // eslint-disable-next-line array-callback-return
            mediaList.map((item) => {
                if (
                    Object.prototype.hasOwnProperty.call(item, 'videoUrl') &&
                    item.videoUrl.includes('firebase')
                ) {
                    tempArray.push(item);
                }
            });
            setMediaListToSend(tempArray);
        } else if (mediaTypeToShow === 'videoYT') {
            const tempArray: React.SetStateAction<unknown[]> = [];
            setMediaListToSend([]);
            // eslint-disable-next-line array-callback-return
            mediaList.map((item) => {
                if (
                    Object.prototype.hasOwnProperty.call(item, 'videoUrl') &&
                    !item.videoUrl.includes('firebase')
                ) {
                    tempArray.push(item);
                }
            });
            setMediaListToSend(tempArray);
        }
    }, [mediaTypeToShow]);

    const bindLongPressElement = useLongPress(
        (event, index) => {
            if (index.context === -1) {
                setConsultationItem(mediaListToSend[0]);
            } else {
                setIsConsultation(false);
                setConsultationItem(mediaList[Number(index.context)]);
            }
        },
        { threshold: 1000 }
    );

    const disableItem = (uid: string): boolean => {
        let booleanToReturn = false;
        uidToDisable.forEach((item) => {
            if (item === uid) {
                booleanToReturn = true;
            }
        });
        return booleanToReturn;
    };

    const getConsultationElement = (): JSX.Element[] | null => {
        if (mediaList && mediaList.length > 0) {
            return mediaList.slice(0, nbElementMediaToDisplay).map((item, index) => {
                let bgColor;
                const number = index % 4;
                switch (number) {
                    case 0:
                        bgColor = '#FF008980';
                        break;
                    case 1:
                        bgColor = '#3DECCF80';
                        break;
                    case 2:
                        bgColor = '#458ff680';
                        break;
                    case 3:
                        bgColor = '#6B00AD80';
                        break;
                    default:
                        break;
                }
                return (
                    <Grid
                        item
                        xs
                        sx={{
                            flexGrow: '0 !important',
                            // eslint-disable-next-line no-nested-ternary
                            display: disableItem(item.id)
                                ? 'none'
                                : !mediaTypeToShow
                                ? 'block'
                                : // eslint-disable-next-line no-nested-ternary
                                mediaTypeToShow === 'picture' &&
                                  !Object.prototype.hasOwnProperty.call(item, 'videoUrl')
                                ? 'block'
                                : // eslint-disable-next-line no-nested-ternary
                                mediaTypeToShow === 'video' &&
                                  Object.prototype.hasOwnProperty.call(item, 'videoUrl') &&
                                  item.videoUrl.includes('firebase')
                                ? 'block'
                                : mediaTypeToShow === 'videoYT' &&
                                  Object.prototype.hasOwnProperty.call(item, 'videoUrl') &&
                                  !item.videoUrl.includes('firebase')
                                ? 'block'
                                : 'none'
                        }}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...bindLongPressElement(index)}
                        onClick={(): void => {
                            setIsConsultation(false);
                            setConsultationItem(item);
                        }}
                        key={
                            Object.prototype.hasOwnProperty.call(item, 'videoUrl')
                                ? item.videoUrl
                                : item.id
                        }>
                        {item && !Object.prototype.hasOwnProperty.call(item, 'videoUrl') && (
                            <ThumbnailsCards
                                onCardClicked={(): void => {}}
                                onLeaveCard={(): void => {}}
                                item={item}
                                bgColor={bgColor}
                                classReceived={classes.deSelectedCard}
                            />
                        )}
                        {item &&
                            Object.prototype.hasOwnProperty.call(item, 'videoUrl') &&
                            (mediaTypeToShow === 'videoYT' || mediaTypeToShow === undefined) &&
                            !item.videoUrl.includes('firebase') && (
                                <VideoCards
                                    onCardClicked={(): void => {}}
                                    onLeaveCard={(): void => {}}
                                    item={item}
                                    bgColor={bgColor}
                                    isSelected={false}
                                    youtubePage={!item.videoUrl.includes('firebase')}
                                    mediaTypeToShow={mediaTypeToShow}
                                />
                            )}
                        {item &&
                            Object.prototype.hasOwnProperty.call(item, 'videoUrl') &&
                            (mediaTypeToShow === 'video' || mediaTypeToShow === undefined) &&
                            item.videoUrl.includes('firebase') && (
                                <VideoCards
                                    onCardClicked={(): void => {}}
                                    onLeaveCard={(): void => {}}
                                    item={item}
                                    bgColor={bgColor}
                                    isSelected={false}
                                    youtubePage={!item.videoUrl.includes('firebase')}
                                    mediaTypeToShow={mediaTypeToShow}
                                />
                            )}
                    </Grid>
                );
            });
        }
        return null;
    };

    const loadFuncMedia = (): void => {
        if (nbElementMediaToDisplay + 6 <= mediaList.length) {
            setNbElementMediaToDisplay(nbElementMediaToDisplay + 6);
        } else {
            setNbElementMediaToDisplay(mediaList.length);
        }
    };

    const loadFuncExo = (): void => {
        if (nbElementExoToDisplay + 6 <= exerciseListToShow.length) {
            setNbElementExoToDisplay(nbElementExoToDisplay + 6);
        } else {
            setNbElementExoToDisplay(exerciseListToShow.length);
        }
    };

    const bindLongPressActivity = useLongPress(
        (event, value) => {
            switch (value.context) {
                case 'undefined':
                    setExerciseTypeToShow(undefined);
                    break;
                case 'puzzle':
                    setExerciseTypeToShow(ExerciseType.PUZZLE);
                    break;
                case 'memory':
                    setExerciseTypeToShow(ExerciseType.MEMORY);
                    break;
                case 'memorin':
                    setExerciseTypeToShow(ExerciseType.MEMORIN);
                    break;
                default:
                    setExerciseTypeToShow(undefined);
                    break;
            }
        },
        { threshold: 1000 }
    );

    const bindLongPressMedia = useLongPress(
        (event, value) => {
            switch (value.context) {
                case 'picture':
                    setMediaTypeToShow('picture');
                    break;
                case 'video':
                    setMediaTypeToShow('video');
                    break;
                case 'videoYT':
                    setMediaTypeToShow('videoYT');
                    break;
                default:
                    setMediaTypeToShow(undefined);
                    break;
            }
        },
        { threshold: 1000 }
    );

    const exerciseOrConsultation = (): JSX.Element => {
        if (active === true) {
            return (
                <div>
                    <div className={classes.patientSalutation}>
                        <Typography
                            className={classes.patientSalutationBackground}
                            variant="h3"
                            sx={{
                                fontWeight: '900',
                                fontFamily: 'Luciole-Regular',
                                display: 'inline',
                                color: '#6B00AD'
                            }}>
                            {t('patientHello')}
                            {user?.firstName}
                            {t('exclamation')}
                        </Typography>
                    </div>
                    <Typography
                        className={classes.patientSalutationSubheading}
                        variant="h3"
                        color="#374653"
                        sx={{ fontWeight: 600, fontFamily: 'Luciole-Regular', display: 'inline' }}>
                        {t('consultMedia')}
                    </Typography>
                    <div
                        style={{
                            boxShadow: 'inset 0px -1px 0px #E3E6E8',
                            width: '100%',
                            height: '60px',
                            display: 'flex',
                            verticalAlign: 'middle',
                            marginTop: '40px',
                            backgroundColor: '#FFFFFF'
                        }}>
                        <Button
                            style={{
                                width: '250px',
                                backgroundColor: !mediaTypeToShow ? '#6B00AD1A' : '#84919E1A',
                                marginLeft: isApple ? '15px' : '85px',
                                marginTop: 'auto',
                                marginBottom: 'auto',
                                color: '#6B00AD'
                            }}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...bindLongPressMedia('undefined')}
                            onClick={(): void => {
                                setMediaTypeToShow(undefined);
                            }}>
                            <Typography
                                style={{
                                    color: !mediaTypeToShow ? '#6B00AD' : '#84919E',
                                    fontFamily: 'Luciole-Regular',
                                    fontSize: !mediaTypeToShow ? '20px' : '18px',
                                    fontWeight: !mediaTypeToShow ? '600' : '400'
                                }}>
                                {t('allMedia')}
                            </Typography>
                        </Button>
                        <Button
                            style={{
                                marginLeft: '15px',
                                marginTop: 'auto',
                                marginBottom: 'auto',
                                width: '230px',
                                // eslint-disable-next-line no-nested-ternary
                                backgroundColor: !mediaTypeToShow
                                    ? 'transparent'
                                    : mediaTypeToShow === 'picture'
                                    ? '#6B00AD1A'
                                    : '#84919E1A',
                                color: '#6B00AD'
                            }}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...bindLongPressMedia('picture')}
                            onClick={(): void => {
                                setMediaTypeToShow('picture');
                            }}>
                            <Logo
                                src={mediaLittleIcon}
                                style={{
                                    // eslint-disable-next-line no-nested-ternary
                                    color: !mediaTypeToShow
                                        ? '#6B00AD'
                                        : mediaTypeToShow === 'picture'
                                        ? '#6B00AD'
                                        : '#84919E'
                                }}
                                alt="logoPicture"
                            />
                            <Typography
                                style={{
                                    color: mediaTypeToShow === 'picture' ? '#6B00AD' : '#84919E',
                                    fontFamily: 'Luciole-Regular',
                                    fontSize: mediaTypeToShow === 'picture' ? '20px' : '18px',
                                    fontWeight: mediaTypeToShow === 'picture' ? '600' : '400',
                                    paddingLeft: '5px'
                                }}>
                                {t('thumbnails')}
                            </Typography>
                        </Button>
                        <Button
                            style={{
                                marginLeft: '15px',
                                marginTop: 'auto',
                                marginBottom: 'auto',
                                width: '230px',
                                // eslint-disable-next-line no-nested-ternary
                                backgroundColor: !mediaTypeToShow
                                    ? 'transparent'
                                    : mediaTypeToShow === 'video'
                                    ? '#6B00AD1A'
                                    : '#84919E1A',
                                color: '#6B00AD'
                            }}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...bindLongPressMedia('video')}
                            onClick={(): void => {
                                setMediaTypeToShow('video');
                            }}>
                            <Logo
                                src={personalVideoIcon}
                                style={{
                                    // eslint-disable-next-line no-nested-ternary
                                    color: !mediaTypeToShow
                                        ? '#6B00AD'
                                        : mediaTypeToShow === 'video'
                                        ? '#6B00AD'
                                        : '#84919E'
                                }}
                                alt="logoVideo"
                            />
                            <Typography
                                style={{
                                    color: mediaTypeToShow === 'video' ? '#6B00AD' : '#84919E',
                                    fontFamily: 'Luciole-Regular',
                                    fontSize: mediaTypeToShow === 'video' ? '20px' : '18px',
                                    fontWeight: mediaTypeToShow === 'video' ? '600' : '400',
                                    paddingLeft: '5px'
                                }}>
                                {t('video')}
                            </Typography>
                        </Button>
                        <Button
                            style={{
                                marginLeft: '15px',
                                marginTop: 'auto',
                                marginBottom: 'auto',
                                width: '230px',
                                // eslint-disable-next-line no-nested-ternary
                                backgroundColor: !mediaTypeToShow
                                    ? 'transparent'
                                    : mediaTypeToShow === 'videoYT'
                                    ? '#6B00AD1A'
                                    : '#84919E1A',
                                color: '#6B00AD'
                            }}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...bindLongPressMedia('videoYT')}
                            onClick={(): void => {
                                // if (firstClickVideoYT) {
                                setMediaTypeToShow('videoYT');
                            }}>
                            <Logo
                                src={youtubeLittleIcon}
                                style={{
                                    // eslint-disable-next-line no-nested-ternary
                                    color: !mediaTypeToShow
                                        ? '#6B00AD'
                                        : mediaTypeToShow === 'videoYT'
                                        ? '#6B00AD'
                                        : '#84919E'
                                }}
                                alt="logoVideoYT"
                            />
                            <Typography
                                style={{
                                    color: mediaTypeToShow === 'videoYT' ? '#6B00AD' : '#84919E',
                                    fontFamily: 'Luciole-Regular',
                                    fontSize: mediaTypeToShow === 'videoYT' ? '20px' : '18px',
                                    fontWeight: mediaTypeToShow === 'videoYT' ? '600' : '400',
                                    paddingLeft: '5px'
                                }}>
                                {t('videoYT')}
                            </Typography>
                        </Button>
                    </div>
                    <div className={classes.root}>
                        <InfiniteScroll
                            pageStart={1}
                            loadMore={loadFuncMedia}
                            hasMore={true || false}
                            loader={
                                <div className="loader" key={0}>
                                    {nbElementMediaToDisplay !== mediaList.length
                                        ? t('loading')
                                        : ''}
                                </div>
                            }>
                            {/* {items} // <-- This is the content you want to load */}
                            <Grid
                                container
                                rowSpacing={2}
                                columnSpacing={5}
                                sx={{ justifyContent: 'center' }}>
                                <Grid
                                    item
                                    xs
                                    sx={{
                                        flexGrow: '0 !important',
                                        display: 'block'
                                    }}
                                    key="conslut_media">
                                    <Box>
                                        <Card
                                            sx={{
                                                flexGrow: 0,
                                                marginBottom: '16px',
                                                height: '290px',
                                                width: '220px',
                                                borderRadius: '9px'
                                            }}>
                                            <CardActionArea
                                                // eslint-disable-next-line react/jsx-props-no-spreading
                                                {...bindLongPressElement(-1)}
                                                onClick={(): void => {
                                                    setIsConsultation(true);
                                                    setConsultationItem(mediaListToSend[0]);
                                                }}>
                                                <Box
                                                    sx={{
                                                        position: 'relative'
                                                    }}>
                                                    <Box className={classes.boxMedia}>
                                                        <Box>
                                                            <img
                                                                src={carnetMemoire}
                                                                alt="carnetMemoire"
                                                                style={{
                                                                    width: '100px',
                                                                    height: '100px',
                                                                    marginTop: '10px'
                                                                }}
                                                            />
                                                        </Box>
                                                        <Typography
                                                            sx={{
                                                                color: '#FFFFFF',
                                                                fontFamily: 'Luciole-Regular',
                                                                fontSize: {
                                                                    xs: '1.5rem !important',
                                                                    sm: '1.25rem !important',
                                                                    md: '1.5rem !important',
                                                                    lg: '1.5rem !important'
                                                                }
                                                            }}>
                                                            {mediaTypeToShow === undefined &&
                                                                t('consultMemoireAll')}
                                                            {mediaTypeToShow === 'picture' &&
                                                                t('consultMemoirePicture')}
                                                            {mediaTypeToShow === 'video' &&
                                                                t('consultMemoireVideo')}
                                                            {mediaTypeToShow === 'videoYT' &&
                                                                t('consultMemoireVideoYT')}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </CardActionArea>
                                        </Card>
                                    </Box>
                                </Grid>
                                {getConsultationElement()}
                                {/* {false && */}
                                {mediaList.length === 0 &&
                                    Array.from(Array(6).keys()).map((item) => {
                                        let bgColor;
                                        const number = item % 4;
                                        switch (number) {
                                            case 0:
                                                bgColor = '#FF008980';
                                                break;
                                            case 1:
                                                bgColor = '#3DECCF80';
                                                break;
                                            case 2:
                                                bgColor = '#458ff680';
                                                break;
                                            case 3:
                                                bgColor = '#6B00AD80';
                                                break;
                                            default:
                                                break;
                                        }

                                        return (
                                            <Grid
                                                item
                                                xs
                                                sx={{
                                                    flexGrow: '0 !important',
                                                    display: 'block'
                                                }}>
                                                <Box
                                                    sx={{
                                                        height:
                                                            window.innerWidth < 520
                                                                ? 'calc(100vw / 2 + 20px)'
                                                                : '290px',
                                                        width:
                                                            window.innerWidth < 520
                                                                ? 'calc(100vw / 2 - 20px)'
                                                                : '220px',
                                                        borderRadius: '9px',
                                                        padding: '10px',
                                                        boxShadow:
                                                            '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
                                                    }}>
                                                    <Skeleton
                                                        variant="rectangular"
                                                        style={{
                                                            backgroundColor: bgColor,
                                                            borderRadius: '9px',
                                                            width: '100%',
                                                            height: '100%'
                                                        }}
                                                    />
                                                </Box>
                                            </Grid>
                                        );
                                    })}
                            </Grid>
                        </InfiniteScroll>
                    </div>
                </div>
            );
        }
        return (
            <div>
                <div className={classes.patientSalutation}>
                    <Typography
                        className={classes.patientSalutationBackground}
                        variant="h3"
                        sx={{
                            fontWeight: 900,
                            fontFamily: 'Luciole-Regular',
                            display: 'inline'
                        }}>
                        {t('patientHello')}
                        {user?.firstName}
                        {t('exclamation')}
                    </Typography>
                </div>
                <Typography
                    className={classes.patientSalutationSubheading}
                    variant="h3"
                    color="#374653"
                    sx={{ fontWeight: 600, fontFamily: 'Luciole-Regular', display: 'inline' }}>
                    {t('chooseExercise')}
                </Typography>
                <div
                    style={{
                        boxShadow: 'inset 0px -1px 0px #E3E6E8',
                        width: '100%',
                        height: '60px',
                        display: 'flex',
                        verticalAlign: 'middle',
                        marginTop: '40px',
                        backgroundColor: '#FFFFFF'
                    }}>
                    <Button
                        style={{
                            width: isApple ? '325px' : '230px',
                            backgroundColor: !exerciseTypeToShow ? '#0053981A' : '#84919E1A',
                            marginLeft: isApple ? '1%' : '6%',
                            marginTop: 'auto',
                            marginBottom: 'auto'
                        }}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...bindLongPressActivity('undefined')}
                        onClick={(): void => {
                            setExerciseTypeToShow(undefined);
                        }}>
                        <Typography
                            style={{
                                color: !exerciseTypeToShow ? '#005398' : '#84919E',
                                fontFamily: 'Luciole-Regular',
                                fontSize: !exerciseTypeToShow ? '20px' : '18px',
                                fontWeight: !exerciseTypeToShow ? '600' : '400'
                            }}>
                            {t('allExercise')}
                        </Typography>
                    </Button>
                    <Button
                        style={{
                            marginLeft: '15px',
                            marginTop: 'auto',
                            marginBottom: 'auto',
                            width: '190px',
                            // eslint-disable-next-line no-nested-ternary
                            backgroundColor: !exerciseTypeToShow
                                ? 'transparent'
                                : exerciseTypeToShow === ExerciseType.MEMORIN
                                ? '#FF00890F'
                                : '#84919E1A'
                        }}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...bindLongPressActivity('memorin')}
                        onClick={(): void => {
                            setExerciseTypeToShow(ExerciseType.MEMORIN);
                        }}>
                        {exerciseTypeToShow === ExerciseType.MEMORIN ||
                        exerciseTypeToShow === undefined ? (
                            <img
                                src={MemorinSvgIcon}
                                alt="Exercise"
                                style={{
                                    width: '30px',
                                    height: '30px'
                                }}
                            />
                        ) : (
                            <img
                                src={MemorinSvgIconGrey}
                                alt="Exercise"
                                style={{
                                    width: '30px',
                                    height: '30px'
                                }}
                            />
                        )}
                        <div style={{ textAlign: 'left' }}>
                            <Typography
                                style={{
                                    color:
                                        exerciseTypeToShow === ExerciseType.MEMORIN
                                            ? '#FF0089'
                                            : '#84919E',
                                    fontFamily: 'Luciole-Regular',
                                    fontSize:
                                        exerciseTypeToShow === ExerciseType.MEMORIN
                                            ? '20px'
                                            : '18px',
                                    fontWeight:
                                        exerciseTypeToShow === ExerciseType.MEMORIN ? '600' : '400',
                                    paddingLeft: '5px'
                                }}>
                                {t('memorin')}
                            </Typography>
                            <Typography
                                style={{
                                    color:
                                        exerciseTypeToShow === ExerciseType.MEMORIN
                                            ? '#FF0089'
                                            : '#84919E',
                                    fontFamily: 'Luciole-Regular',
                                    fontSize:
                                        exerciseTypeToShow === ExerciseType.MEMORIN
                                            ? '13px'
                                            : '12px',
                                    paddingLeft: '5px'
                                }}>
                                {t('levNavBarNew', {
                                    lvl: memorinDifficultyLevel,
                                    lvlMax: '10'
                                })}
                            </Typography>
                        </div>
                    </Button>
                    <Button
                        style={{
                            marginLeft: '15px',
                            marginTop: 'auto',
                            marginBottom: 'auto',
                            width: '190px',
                            // eslint-disable-next-line no-nested-ternary
                            backgroundColor: !exerciseTypeToShow
                                ? 'transparent'
                                : exerciseTypeToShow === ExerciseType.PONG
                                ? '#FF00890F'
                                : '#84919E1A'
                        }}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...bindLongPressActivity('pong')}
                        onClick={(): void => {
                            setExerciseTypeToShow(ExerciseType.PONG);
                        }}>
                        {exerciseTypeToShow === ExerciseType.PONG ||
                        exerciseTypeToShow === undefined ? (
                            <img
                                src={pongSvgIcon}
                                alt="Exercise"
                                style={{
                                    width: '30px',
                                    height: '30px'
                                }}
                            />
                        ) : (
                            <img
                                src={pongSvgIconGrey}
                                alt="Exercise"
                                style={{
                                    width: '30px',
                                    height: '30px'
                                }}
                            />
                        )}
                        <div style={{ textAlign: 'left' }}>
                            <Typography
                                style={{
                                    color:
                                        exerciseTypeToShow === ExerciseType.PONG
                                            ? '#FF0089'
                                            : '#84919E',
                                    fontFamily: 'Luciole-Regular',
                                    fontSize:
                                        exerciseTypeToShow === ExerciseType.PONG ? '20px' : '18px',
                                    fontWeight:
                                        exerciseTypeToShow === ExerciseType.PONG ? '600' : '400',
                                    paddingLeft: '5px'
                                }}>
                                {t('Pongin')}
                            </Typography>
                            <Typography
                                style={{
                                    color:
                                        exerciseTypeToShow === ExerciseType.PONG
                                            ? '#FF0089'
                                            : '#84919E',
                                    fontFamily: 'Luciole-Regular',
                                    fontSize:
                                        exerciseTypeToShow === ExerciseType.PONG ? '13px' : '12px',
                                    paddingLeft: '5px'
                                }}>
                                {t('levNavBarNew', {
                                    lvl: pongDifficultyLevel,
                                    lvlMax: '10'
                                })}
                            </Typography>
                        </div>
                    </Button>
                    <Button
                        style={{
                            marginLeft: '15px',
                            marginTop: 'auto',
                            marginBottom: 'auto',
                            width: '190px',
                            // eslint-disable-next-line no-nested-ternary
                            backgroundColor: !exerciseTypeToShow
                                ? 'transparent'
                                : exerciseTypeToShow === ExerciseType.PUZZLE
                                ? '#FF00890F'
                                : '#84919E1A'
                        }}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...bindLongPressActivity('puzzle')}
                        onClick={(): void => {
                            setExerciseTypeToShow(ExerciseType.PUZZLE);
                        }}>
                        <ExtensionIcon
                            style={{
                                fontSize: '32px',
                                // eslint-disable-next-line no-nested-ternary
                                color: !exerciseTypeToShow
                                    ? '#FF0189'
                                    : exerciseTypeToShow === ExerciseType.PUZZLE
                                    ? '#FF0189'
                                    : '#84919E'
                            }}
                        />
                        <div style={{ textAlign: 'left' }}>
                            <Typography
                                style={{
                                    color:
                                        exerciseTypeToShow === ExerciseType.PUZZLE
                                            ? '#FF0089'
                                            : '#84919E',
                                    fontFamily: 'Luciole-Regular',
                                    fontSize:
                                        exerciseTypeToShow === ExerciseType.PUZZLE
                                            ? '20px'
                                            : '18px',
                                    fontWeight:
                                        exerciseTypeToShow === ExerciseType.PUZZLE ? '600' : '400',
                                    paddingLeft: '5px'
                                }}>
                                {t('puzzle')}
                            </Typography>
                            <Typography
                                style={{
                                    color:
                                        exerciseTypeToShow === ExerciseType.PUZZLE
                                            ? '#FF0089'
                                            : '#84919E',
                                    fontFamily: 'Luciole-Regular',
                                    fontSize:
                                        exerciseTypeToShow === ExerciseType.PUZZLE
                                            ? '13px'
                                            : '12px',
                                    paddingLeft: '5px'
                                }}>
                                {t('levNavBarNew', {
                                    lvl: puzzleDifficultyLevel,
                                    lvlMax: '10'
                                })}
                            </Typography>
                        </div>
                    </Button>
                    <Button
                        style={{
                            marginLeft: '15px',
                            marginTop: 'auto',
                            marginBottom: 'auto',
                            width: '190px',
                            // eslint-disable-next-line no-nested-ternary
                            backgroundColor: !exerciseTypeToShow
                                ? 'transparent'
                                : exerciseTypeToShow === ExerciseType.MEMORY
                                ? '#FF00890F'
                                : '#84919E1A'
                        }}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...bindLongPressActivity('memory')}
                        onClick={(): void => {
                            setExerciseTypeToShow(ExerciseType.MEMORY);
                        }}>
                        {exerciseTypeToShow === ExerciseType.MEMORY ||
                        exerciseTypeToShow === undefined ? (
                            <BrainSvgIcon />
                        ) : (
                            <BrainSvgIconGrey />
                        )}
                        <div style={{ textAlign: 'left' }}>
                            <Typography
                                style={{
                                    color:
                                        exerciseTypeToShow === ExerciseType.MEMORY
                                            ? '#FF0089'
                                            : '#84919E',
                                    fontFamily: 'Luciole-Regular',
                                    fontSize:
                                        exerciseTypeToShow === ExerciseType.MEMORY
                                            ? '20px'
                                            : '18px',
                                    fontWeight:
                                        exerciseTypeToShow === ExerciseType.MEMORY ? '600' : '400',
                                    paddingLeft: '5px'
                                }}>
                                {t('quiz')}
                            </Typography>
                            <Typography
                                style={{
                                    color:
                                        exerciseTypeToShow === ExerciseType.MEMORY
                                            ? '#FF0089'
                                            : '#84919E',
                                    fontFamily: 'Luciole-Regular',
                                    fontSize:
                                        exerciseTypeToShow === ExerciseType.MEMORY
                                            ? '13px'
                                            : '12px',
                                    paddingLeft: '5px'
                                }}>
                                {t('levNavBarNew', {
                                    lvl: memoryDifficultyLevel,
                                    lvlMax: '10'
                                })}
                            </Typography>
                        </div>
                    </Button>
                    <Button
                        style={{
                            marginLeft: '15px',
                            marginTop: 'auto',
                            marginBottom: 'auto',
                            width: '250px',
                            // eslint-disable-next-line no-nested-ternary
                            backgroundColor: !exerciseTypeToShow
                                ? 'transparent'
                                : exerciseTypeToShow === ExerciseType.ORDER
                                ? '#FF00890F'
                                : '#84919E1A'
                        }}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...bindLongPressActivity('reorder')}
                        onClick={(): void => {
                            setExerciseTypeToShow(ExerciseType.ORDER);
                        }}>
                        {exerciseTypeToShow === ExerciseType.ORDER ||
                        exerciseTypeToShow === undefined ? (
                            <TocSvgIcon />
                        ) : (
                            <TocSvgIconGrey />
                        )}
                        <div style={{ textAlign: 'left' }}>
                            <Typography
                                style={{
                                    color:
                                        exerciseTypeToShow === ExerciseType.ORDER
                                            ? '#FF0089'
                                            : '#84919E',
                                    fontFamily: 'Luciole-Regular',
                                    fontSize:
                                        exerciseTypeToShow === ExerciseType.ORDER ? '20px' : '18px',
                                    fontWeight:
                                        exerciseTypeToShow === ExerciseType.ORDER ? '600' : '400',
                                    paddingLeft: '5px'
                                }}>
                                {t('reorder')}
                            </Typography>
                            <Typography
                                style={{
                                    color:
                                        exerciseTypeToShow === ExerciseType.ORDER
                                            ? '#FF0089'
                                            : '#84919E',
                                    fontFamily: 'Luciole-Regular',
                                    fontSize:
                                        exerciseTypeToShow === ExerciseType.ORDER ? '13px' : '12px',
                                    paddingLeft: '5px'
                                }}>
                                {t('levNavBarNew', {
                                    lvl: reorderDifficultyLevel,
                                    lvlMax: '10'
                                })}
                            </Typography>
                        </div>
                    </Button>
                    <Button
                        style={{
                            marginLeft: '15px',
                            marginTop: 'auto',
                            marginBottom: 'auto',
                            width: '190px',
                            // eslint-disable-next-line no-nested-ternary
                            backgroundColor: !exerciseTypeToShow
                                ? 'transparent'
                                : exerciseTypeToShow === ExerciseType.SUDOKU
                                ? '#FF00890F'
                                : '#84919E1A'
                        }}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...bindLongPressActivity('sudoku')}
                        onClick={(): void => {
                            setExerciseTypeToShow(ExerciseType.SUDOKU);
                        }}>
                        {exerciseTypeToShow === ExerciseType.SUDOKU ||
                        exerciseTypeToShow === undefined ? (
                            <img
                                src={sudokuSvgIcon}
                                alt="Exercise"
                                style={{
                                    width: '30px',
                                    height: '30px'
                                }}
                            />
                        ) : (
                            <img
                                src={sudokuSvgIconGrey}
                                alt="Exercise"
                                style={{
                                    width: '30px',
                                    height: '30px'
                                }}
                            />
                        )}
                        <div style={{ textAlign: 'left' }}>
                            <Typography
                                style={{
                                    color:
                                        exerciseTypeToShow === ExerciseType.SUDOKU
                                            ? '#FF0089'
                                            : '#84919E',
                                    fontFamily: 'Luciole-Regular',
                                    fontSize:
                                        exerciseTypeToShow === ExerciseType.SUDOKU
                                            ? '20px'
                                            : '18px',
                                    fontWeight:
                                        exerciseTypeToShow === ExerciseType.SUDOKU ? '600' : '400',
                                    paddingLeft: '5px'
                                }}>
                                {t('Sudokin')}
                            </Typography>
                            <Typography
                                style={{
                                    color:
                                        exerciseTypeToShow === ExerciseType.SUDOKU
                                            ? '#FF0089'
                                            : '#84919E',
                                    fontFamily: 'Luciole-Regular',
                                    fontSize:
                                        exerciseTypeToShow === ExerciseType.SUDOKU
                                            ? '13px'
                                            : '12px',
                                    paddingLeft: '5px'
                                }}>
                                {t('levNavBarNew', {
                                    lvl: sudokuDifficultyLevel,
                                    lvlMax: '10'
                                })}
                            </Typography>
                        </div>
                    </Button>
                </div>
                <div className={classes.root}>
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={loadFuncExo}
                        hasMore={true || false}
                        loader={
                            <div
                                className="loader"
                                key={0}
                                style={{ fontFamily: 'Luciole-Regular' }}>
                                {nbElementExoToDisplay !== exerciseListToShow.length
                                    ? t('loading')
                                    : ''}
                            </div>
                        }>
                        <Grid
                            container
                            rowSpacing={2}
                            columnSpacing={5}
                            sx={{ justifyContent: 'center' }}>
                            {getExerciseData()}
                        </Grid>
                    </InfiniteScroll>
                </div>
                <div>
                    {selectedExercise && selectedExercise.id && (
                        <TryExerciseAlerts
                            isModalOpen={renderModal}
                            closeModal={handleCloseModal}
                            exerciseSelected={selectedExercise}
                            random={
                                selectedExercise.exerciseType === ExerciseType.MEMORY ||
                                selectedExercise.exerciseType === ExerciseType.MEMORIN
                                    ? randomImage
                                    : null
                            }
                        />
                    )}
                </div>
            </div>
        );
    };

    return (
        <div>
            <LvlChoiseModal
                isModalOpen={openLvlModal}
                closeModal={(): void => {
                    setOpenLvlModal(false);
                    setAlreadyOpenLvlModal(false);
                }}
                exerciseType={dataExercise?.exercise.exerciseType}
                callback={async (): Promise<void> => {
                    if (dataExercise) {
                        await onExerciseSelect(dataExercise);
                        setOpenLvlModal(false);
                        setAlreadyOpenLvlModal(false);
                    }
                }}
                lvlArray={lvlArray}
                userId={user?.id}
                exoDifficultyLevel={{
                    memoryDifficultyLevel,
                    puzzleDifficultyLevel,
                    memorinDifficultyLevel,
                    sudokuDifficultyLevel,
                    pongDifficultyLevel,
                    reorderDifficultyLevel
                }}
            />
            {consultationItem && (
                <ConsultationItem
                    item={consultationItem}
                    setConsultationItem={setConsultationItem}
                    consultItem
                    itemList={mediaListToSend}
                    stopTimer={stopTimer}
                    // autoSkip={isConsultation} // a ajouter pour skip automatiquement via timer
                />
            )}
            {!consultationItem && (
                <PatientLayout setActive={setActive} active={active}>
                    {exerciseOrConsultation()}
                </PatientLayout>
            )}
        </div>
    );
}
