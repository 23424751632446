import React, { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import successTickIcon from '../assets/successTick.png';
import { SearchBox } from './SearchBox';
// eslint-disable-next-line import/no-cycle
import { getEstablishmentList, saveExercise } from '../services/cloudFirestore';
import { CustomCheckbox } from './CustomCheckbox';
import { Roles } from '../models/Roles';
import { Establishment } from '../models/Establishment';
import { UserDataProp } from '../models/userDataProp';
import { UserContext } from '../providers/UserProvider';
import { PatientSelector } from './PatientSelector';
import { Exercise } from '../models/Exercise';

const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 360,
    bgcolor: 'background.paper',
    borderRadius: '24px',
    boxShadow: 24,
    p: 4
};

const RootModal = styled(Box)(() => ({
    '@media (max-width:320px)': {
        width: '130px'
    },
    '@media (min-width:320px)': {
        width: '315px',
        maxHeight: '555px',
        overflowY: 'scroll'
    },
    '@media (min-width:480px)': {
        width: '430px',
        maxHeight: '555px',
        overflowY: 'scroll'
    },
    '@media (min-width:600px)': {
        width: '590px'
    },
    '@media (min-width:1025px)': {
        width: '900px',
        overflow: 'auto'
    },
    '@media (min-width:1281px)': {
        width: '1100px'
    }
}));

const CustomBox = styled(Box)(() => ({
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0% 5%',
    '@media (max-width: 598px)': {
        padding: '5%'
    }
}));

interface AddExoToEstablishmentModalProps {
    isModelOpen: boolean;
    setModelOpen: Function;
    onButtonClick: Function;
    exercisesList: Exercise[];
}

interface StateInterfaceEstablishment {
    establishmentsArray: Establishment[];
    establishmentIdArray: (string | undefined)[];
}

export function AddExoToEstablishmentModal({
    isModelOpen,
    setModelOpen,
    onButtonClick,
    exercisesList
}: AddExoToEstablishmentModalProps): JSX.Element {
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [searchText, setSearchText] = useState<string>('');
    const [establishmentsListArray, setEstablishmentsList] = useState<Establishment[]>();
    const [establishmentsIdListArray, setEstablishmentsIdList] = useState<string[]>([]);
    const [allEstablishmentsIdListArray, setAllEstablishmentsIdList] = useState<string[]>([]);
    const [allEstablishmentsListArray, setAllEstablishmentsList] = useState<Establishment[]>();
    const [selectedEstablishments, setSelectEstablishment] = useState<StateInterfaceEstablishment>({
        establishmentsArray: [],
        establishmentIdArray: []
    });
    const [exoData, setExoData] = useState<Exercise>();
    const { t } = useTranslation();

    const selectAllEstablishments = (): void => {
        if (establishmentsListArray) {
            if (
                selectedEstablishments.establishmentsArray.length !==
                establishmentsListArray?.length
            ) {
                setSelectEstablishment({
                    establishmentsArray: establishmentsListArray,
                    establishmentIdArray: establishmentsIdListArray
                });
            } else {
                setSelectEstablishment({
                    establishmentsArray: [],
                    establishmentIdArray: []
                });
            }
        }
    };

    useEffect(() => {
        if (searchText && allEstablishmentsListArray) {
            const filteredEstablishmentData = allEstablishmentsListArray.filter((element) => {
                if (element.establishmentName) {
                    return element.establishmentName
                        .toLowerCase()
                        .includes(searchText.toLowerCase());
                }
                return null;
            });
            setEstablishmentsList(filteredEstablishmentData);
            const list: string[] = [];
            filteredEstablishmentData.forEach((establishment) => {
                if (establishment.id) {
                    list.push(establishment.id);
                }
            });
            setEstablishmentsIdList(list);
        } else {
            setEstablishmentsList(allEstablishmentsListArray);
            setEstablishmentsIdList(allEstablishmentsIdListArray);
        }
    }, [searchText]);

    const selectEstablishment = (value: Establishment): void => {
        if (selectedEstablishments.establishmentsArray.indexOf(value) === -1 && value.id) {
            setSelectEstablishment({
                establishmentsArray: [...selectedEstablishments.establishmentsArray, value],
                establishmentIdArray: [...selectedEstablishments.establishmentIdArray, value.id]
            });
        } else {
            setSelectEstablishment({
                establishmentsArray: selectedEstablishments.establishmentsArray.filter(
                    (e) => e !== value
                ),
                establishmentIdArray: selectedEstablishments.establishmentIdArray.filter(
                    (e) => e !== value.id
                )
            });
        }
    };

    useEffect(() => {
        const getPatientOrEstablishmentList = async (): Promise<void> => {
            if (user?.role === Roles.ADMIN) {
                const establishments = await getEstablishmentList();
                setEstablishmentsList(establishments);
                setAllEstablishmentsList(establishments);
                const estList: string[] = [];
                establishments.forEach((establishment) => {
                    if (establishment.id) {
                        estList.push(establishment.id);
                    }
                });
                setEstablishmentsIdList(estList);
                setAllEstablishmentsIdList(estList);
            }
        };
        getPatientOrEstablishmentList();
    }, []);

    const handleSaveExercise = async (): Promise<void> => {
        if (user && user.id && user.role) {
            // eslint-disable-next-line array-callback-return
            exercisesList.map(async (exo) => {
                // eslint-disable-next-line no-param-reassign
                exo.establishmentList = selectedEstablishments.establishmentIdArray;
                await saveExercise(user, exo);
            });
        }
        onButtonClick();
    };

    const allItemsSelector = (): JSX.Element | null => {
        if (establishmentsListArray && user?.role === Roles.ADMIN) {
            return (
                <Grid item xs={12} sm={6} sx={{ padding: '1%' }} key={2}>
                    <CustomBox>
                        <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                            <Typography variant="h6" color="#004680" sx={{ fontWeight: 500 }}>
                                {t('sendToAllEstablishments')}
                            </Typography>
                        </Box>
                        <Box sx={{ width: '20%' }}>
                            <CustomCheckbox
                                isChecked={
                                    selectedEstablishments.establishmentsArray.length ===
                                    establishmentsListArray.length
                                }
                                selectItem={selectAllEstablishments}
                                item={undefined}
                            />
                        </Box>
                    </CustomBox>
                </Grid>
            );
        }
        return null;
    };

    const getPatients = (): JSX.Element[] | null => {
        if (establishmentsListArray && user?.role === Roles.ADMIN) {
            if (establishmentsListArray.length > 0) {
                return establishmentsListArray.map((item) => {
                    return (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                                padding: '1%'
                            }}
                            key={item.id}>
                            <PatientSelector
                                patientsArray={item}
                                isChecked={
                                    selectedEstablishments.establishmentIdArray.indexOf(item.id) >
                                    -1
                                }
                                selectPatient={selectEstablishment}
                            />
                        </Grid>
                    );
                });
            }
        }
        return null;
    };

    const body = (
        <RootModal sx={style}>
            <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
                <Grid container sx={{ width: '100%', margin: 'auto' }}>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                            padding: '1%'
                        }}
                        key={1}>
                        <SearchBox
                            placeholder={t('toSearch')}
                            onSearchChange={(value: string): void => setSearchText(value)}
                            hasSearchIcon
                        />
                    </Grid>
                    {allItemsSelector()}
                    {getPatients()}
                </Grid>
                <div style={{ display: 'flex' }}>
                    <Button
                        fullWidth
                        disableElevation
                        variant="outlined"
                        size="large"
                        sx={{ borderColor: '#5C6670', padding: '20px', margin: '0px 10px' }}
                        onClick={(): void => onButtonClick()}>
                        <Typography variant="subtitle1" color="#5C6670">
                            {t('back')}
                        </Typography>
                    </Button>
                    <Button
                        fullWidth
                        disableElevation
                        variant="outlined"
                        size="large"
                        sx={{
                            borderColor: '#8B004B',
                            backgroundColor: '#FEFAFC',
                            padding: '20px',
                            margin: '0px 10px'
                        }}
                        onClick={(): Promise<void> => handleSaveExercise()}>
                        <Typography variant="subtitle1" color="#D00070">
                            {t('validate')}
                        </Typography>
                    </Button>
                </div>
            </Box>
        </RootModal>
    );

    return (
        <div>
            <Modal
                open={isModelOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                {body}
            </Modal>
        </div>
    );
}
