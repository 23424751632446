import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { ExerciseType } from '../../models/ExerciseType';
import { CustomStepper } from '../CustomStepper';
import { Roles } from '../../models/Roles';
import { Patient } from '../../models/Patient';
import { User } from '../../models/User';
import { ExerciseItem } from '../../models/ExerciseItem';

const useStyles = makeStyles(() => ({
    patientSalutation: {
        lineHeight: '36px',
        letterSpacing: '-0.02em',
        textAlign: 'center',
        color: 'rgba(92, 102, 112, 1)',
        borderRadius: '3px',
        marginTop: '25px',
        marginBottom: '20px'
    },
    patientSalutationBackground: {
        background: '#E3E6E8',
        padding: '8px',
        borderRadius: '3px'
    },
    arrowLogo: {
        marginRight: '5px',
        float: 'left'
    },
    title: {
        textAlign: 'left',
        fontWeight: 'bold',
        display: 'inline'
    },
    summary: {
        color: 'rgba(55, 70, 83, 1)',
        fontWeight: 500
    },
    mobileStepper: {
        '@media (max-width: 598px)': {
            display: 'inline'
        },
        '@media (min-width: 598px)': {
            display: 'none'
        }
    },
    headerLayoutMobile: {
        '@media (max-width: 598px)': {
            justifyContent: 'space-between'
        },
        '@media (min-width: 598px)': {
            justifyContent: 'center'
        }
    }
}));

const NumberContainer = styled.span`
    height: 30px;
    background-color: #fbfbfb;
    border: 1px solid #e3e6e8;
    padding: 0px 8px;
    border-radius: 5px;
    @media (max-width: 599px) {
        margin: 5px 5px 0px 5px;
    }
    @media (min-width: 600px) {
        margin: 0px 5px;
    }
    @media (min-width: 900px) {
        margin: 5px 5px 0px 5px;
    }
`;

const NumberContainerPatient = styled.span`
    height: 40px;
    background-color: #fbfbfb;
    border: 1px solid #e3e6e8;
    padding: 0px 8px;
    margin: 0px 5px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 24px;
    font-weight: 700;
    line-height: '29px';
`;

const ArrowLogo = styled.img`
    height: 40px;
    padding: 12px;
`;

const HeadingContainer = styled.div`
    text-align: left;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    @media (max-width: 598px) {
        justify-content: normal;
    }
`;

const Introduction = styled.div`
    @media (max-width: 598px) {
        text-align: left;
    }
`;

const FamilySteps = [1, 2, 3, 4, 5];

const TherapistSteps = [1, 2, 3, 4, 5, 6];

interface TitleAndIntroProps {
    selectedCards?: ExerciseItem[];
    selectedPatients?: Patient[];
    type?: ExerciseType;
    activeStep: number;
    user: User | null | undefined;
    icon: string;
    endBody?: string;
    body: string;
}

export function TitleAndIntro({
    selectedCards,
    selectedPatients,
    type,
    activeStep,
    user,
    icon,
    endBody,
    body
}: TitleAndIntroProps): JSX.Element {
    const classes = useStyles();
    const { t } = useTranslation();
    const [step, setStep] = useState<string>('');

    useEffect(() => {
        if (user && user.role === Roles.FAMILY) {
            if (activeStep >= 4) {
                setStep(`step${activeStep}Of5`);
            } else {
                setStep(`step${activeStep + 1}Of5`);
            }
        } else {
            setStep(`step${activeStep + 1}Of6`);
        }
    }, []);

    const isWidthOfXS = (): boolean => {
        const { innerWidth: width, innerHeight: height } = window;
        if (width < 600) {
            return true;
        }
        return false;
    };

    return (
        <div style={{ width: '80%', margin: 'auto', paddingBottom: '5%' }}>
            <div
                className={classes.headerLayoutMobile}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap'
                }}>
                <div className={classes.patientSalutation}>
                    <Typography
                        className={classes.patientSalutationBackground}
                        variant="subtitle1"
                        style={{ display: 'inline' }}>
                        {t(step)}
                    </Typography>
                </div>
                <div style={{ width: '40%', marginTop: '35px' }} className={classes.mobileStepper}>
                    <CustomStepper
                        currentStep={
                            user && user.role === Roles.FAMILY && activeStep >= 4
                                ? activeStep - 1
                                : activeStep
                        }
                        steps={user && user.role === Roles.FAMILY ? FamilySteps : TherapistSteps}
                    />
                </div>
            </div>
            <HeadingContainer>
                {!isWidthOfXS() && (
                    <ArrowLogo
                        src={icon}
                        alt="ArrowHand"
                        className={classes.arrowLogo}
                        style={{ padding: '5px' }}
                    />
                )}
                <Typography variant="h4" color="#374653" className={classes.title}>
                    {body}
                </Typography>
                {activeStep === 0 && selectedCards && (
                    <HeadingContainer>
                        <NumberContainer
                            style={{
                                color: '#374653',
                                fontWeight: 'bold'
                            }}>
                            <Typography
                                variant="h4"
                                color="#374653"
                                sx={{ fontWeight: '700', lineHeight: '29px' }}>
                                {
                                    // eslint-disable-next-line no-nested-ternary
                                    type === ExerciseType.MEMORY
                                        ? 6 - selectedCards.length
                                        : type === ExerciseType.MEMORIN
                                        ? 18 - selectedCards.length
                                        : 1 - selectedCards.length
                                }
                            </Typography>
                        </NumberContainer>
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                display: 'inline'
                            }}
                            variant="h4"
                            color="#374653"
                            className={classes.title}>
                            {type === ExerciseType.PUZZLE || type === ExerciseType.ORDER
                                ? t('picture').toLowerCase()
                                : t('pictures')}
                        </Typography>
                    </HeadingContainer>
                )}
                {activeStep === 1 && (
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                            display: 'inline',
                            '@media (min-width: 515px)': {
                                marginLeft: '5px'
                            }
                        }}
                        variant="h4"
                        color="#374653"
                        className={classes.title}
                    />
                )}
            </HeadingContainer>
            {activeStep === 2 && user?.role !== Roles.FAMILY && selectedPatients && !endBody ? (
                <NumberContainerPatient style={{ color: '#374653', fontWeight: 'bold' }}>
                    {selectedPatients.length}
                </NumberContainerPatient>
            ) : (
                <Introduction>
                    {activeStep === 0 && (
                        <Typography variant="subtitle1" className={classes.summary}>
                            {t('cantFind')}
                        </Typography>
                    )}
                    <Typography variant="subtitle1" className={classes.summary}>
                        {endBody}
                    </Typography>
                </Introduction>
            )}
        </div>
    );
}
