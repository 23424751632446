import {
    Box,
    Button,
    Card,
    CardContent,
    CardActionArea,
    Typography,
    Input,
    useMediaQuery
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ExtensionIcon from '@mui/icons-material/Extension';
import TocIcon from '@mui/icons-material/Toc';
import { BrainSvgIcon } from './BrainSvgIcon';
import MemorinSvgIcon from '../assets/MemorinSvgIcon.svg';
import { Roles } from '../models/Roles';
import greyNext from '../assets/greyNext.png';
import closeIcon from '../assets/closeIcon.png';
import { TryExerciseAlerts } from '../pages/common/TryExerciseAlerts';
import { DeactiveModall } from './ContainerModal';
import puzzleOverlay from '../assets/puzzleOverlay.png';
import { ExerciseType } from '../models/ExerciseType';
import { UserContext } from '../providers/UserProvider';
import { PatientHomeData } from '../models/PatientHomeData';
import { getImageKitUrlFrom } from '../utilities/utils';
import { deleteExerciseCard } from '../services/cloudFirestore';
import { UserDataProp } from '../models/userDataProp';
import { clickAnalytics } from '../services/analyticsFunction';
import { Exercise } from '../pages/common/Exercise';

const useStyles = makeStyles(() => ({
    exerciseName: {
        letterSpacing: '-0.02em',
        textAlign: 'center',
        // alignSelf: 'center',
        justifySelf: 'center',
        maxHeight: '90px',
        alignItems: 'center',
        fontWeight: 500
    },
    cardContent: {
        display: 'grid',
        overflow: 'hidden',
        padding: '0 5px 10px !important',
        minHeight: '70px'
    },
    buttonCard: {
        // '@media (max-width: 598px)': {
        //     minHeight: '30vh'
        // },
        '@media (min-width: 598px)': {
            minHeight: '20vh'
        }
    },
    media: {
        marginTop: '10px',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: '6px'
    },
    divImageDisplay: {
        animationName: `$fade-out`,
        animationDuration: '2s'
    },
    '@keyframes fade-out': {
        from: {
            opacity: 1
        },
        to: {
            opacity: 0
        }
    }
}));

const ImageBox = styled(Box)({
    position: 'relative'
});

const ImageContainer = styled('img')({
    height: '210px',
    width: '100%',
    objectFit: 'cover'
});

const OverlayContainer = styled('img')({
    borderRadius: '9px',
    position: 'absolute',
    left: '0px',
    height: '98%',
    width: '100%',
    objectFit: 'cover'
});

const ControlButtons = styled(Button)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px solid #ACBCC7',
    borderRadius: '3px',
    margin: '5% 0%'
}));

const ModalButtons = styled(Button)(() => ({
    '@media (max-width: 598px)': {
        width: '100%',
        margin: '2% 0%'
    },
    '@media (min-width: 598px)': {
        width: '30%'
    }
}));

const ButtonContainer = styled(Box)({
    '@media (max-width: 598px)': {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    '@media (min-width: 598px)': {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row'
    }
});

const CloseLogoContainer = styled.div`
    margin-top: 15px;
`;

const LogoClose = styled.img`
    height: 40px;
    background-color: #fbfbfb;
    border: 1px solid #e3e6e8;
    padding: 6px;
    border-radius: 5px;
    cursor: pointer;
`;

const ImageInfoContainer = styled.div`
    position: absolute;
    z-index: 2;
    height: 36px;
    width: 36px;
    margin-top: 15px;
    margin-left: calc(100% - 50px);
    background-color: white;
    border-radius: 50%;
    color: rgba(251, 251, 251, 1);
    max-width: 150px;
    align-items: center;
    display: flex;
`;
interface CardDataProps {
    onCardClicked: Function;
    onLeaveCard: Function;
    item: PatientHomeData;
    isSelected?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addExerciseToList: (exercise: any) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    delExerciseToList: (exercise: any) => void;
    bgColor?: string;
}

export function ExerciseCards({
    onCardClicked,
    onLeaveCard,
    item,
    isSelected,
    addExerciseToList,
    delExerciseToList,
    bgColor
}: CardDataProps): JSX.Element {
    const isSmallWidth = !useMediaQuery('(min-width:598px)');
    const classes = useStyles();
    const history = useHistory();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [clickedCard, setClickedCard] = useState<string | undefined>('');
    const [renderModal, setRenderModal] = useState(false);
    const [renderDeleteExerciseModal, setDeleteModal] = useState(false);
    const [checked, setChecked] = useState<boolean>(false);
    const { t } = useTranslation();
    const width = window.innerWidth;
    const [isImgLoaded, setIsImgLoaded] = useState(false);

    const handleCloseModal = (): void => setRenderModal(false);
    const handleDeleteExercise = async (): Promise<void> => {
        if (clickedCard && user) {
            await deleteExerciseCard(clickedCard);
            clickAnalytics(`delete_${item.exercise.exerciseType?.toLowerCase()}`, user);
            setDeleteModal(false);
            window.location.reload();
        }
    };

    const click = (): void => {
        onLeaveCard();
        setClickedCard('');
    };

    const DeleteModalContent = (
        <div>
            <Typography variant="h3" color="#374653" sx={{ textAlign: 'center', marginTop: '5%' }}>
                {t('wantToDeleteExercise')}
            </Typography>
            <Typography
                variant="subtitle1"
                color="#374653"
                mt={2}
                sx={{ textAlign: 'center', fontWeight: 500 }}>
                {t('disclaimerToDeleteExercise')}
            </Typography>
            <ButtonContainer mt={3} mb={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                <ModalButtons
                    variant="outlined"
                    sx={{
                        border: '1px solid #374653',
                        boxShadow: '0px 0px 0px 2px rgba(92, 102, 112, 0.6)',
                        borderRadius: '12px',
                        width: '30%',
                        marginRight: '5%'
                    }}
                    onClick={(): void => setDeleteModal(false)}>
                    <Typography variant="subtitle1" color="#374653">
                        {t('toCancel')}
                    </Typography>
                </ModalButtons>
                <ModalButtons
                    variant="outlined"
                    sx={{
                        border: '1px solid #8B004B',
                        boxShadow: '0px 0px 0px 2px rgba(208, 0, 112, 0.4)',
                        borderRadius: '12px',
                        width: '30%'
                    }}
                    onClick={(): Promise<void> => handleDeleteExercise()}>
                    <Typography variant="subtitle1" color="#D00070">
                        {t('yes')}
                    </Typography>
                </ModalButtons>
            </ButtonContainer>
        </div>
    );

    const ExerciseTitleCardControls = (
        <div
            style={{
                position: 'absolute',
                backgroundColor: '#FFFFFFD9',
                zIndex: '100',
                // border: '1px solid #E3E6E8',
                marginBottom: '16px',
                height: width < 520 ? 'calc(100vw / 2 + 20px - 2px)' : 'calc(290px - 2px)',
                width: width < 520 ? 'calc(100vw / 2 - 20px - 2px)' : 'calc(220px - 2px)',
                borderRadius: '9px'
            }}>
            <CloseLogoContainer
                onClick={(): void => click()}
                style={{
                    marginTop: width < 520 && user?.role === Roles.THERAPIST ? '10px' : '15px'
                }}>
                <LogoClose src={closeIcon} alt="CloseButton" />
            </CloseLogoContainer>
            <Box
                className={classes.buttonCard}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding:
                        // eslint-disable-next-line no-nested-ternary
                        width < 520
                            ? '0px 3px'
                            : user?.role === Roles.THERAPIST
                            ? '16px 15px'
                            : '70px 15px'
                }}>
                {user?.role === Roles.THERAPIST && (
                    <ControlButtons
                        variant="outlined"
                        sx={{
                            marginTop: '10%',
                            backgroundColor: '#FFFFFF',
                            height: width < 520 && user?.role === Roles.THERAPIST ? '33px' : '48px',
                            '&:hover': {
                                backgroundColor: '#FFFFFF'
                            }
                        }}
                        onClick={(): void => {
                            setRenderModal(true);
                        }}>
                        <Typography variant="subtitle1" color="#374653">
                            {t('tester')}
                        </Typography>
                        <img
                            src={greyNext}
                            alt="RightArrow"
                            style={{ height: '20px', width: '20px' }}
                        />
                    </ControlButtons>
                )}
                <ControlButtons
                    variant="outlined"
                    onClick={(): void => {
                        // eslint-disable-next-line no-nested-ternary
                        item.exercise.exerciseType === ExerciseType.MEMORY
                            ? history.push(`/exercice-customisation/souven'in/${item.exercise.id}`)
                            : item.exercise.exerciseType === ExerciseType.MEMORIN
                            ? history.push(`/exercice-customisation/memor'in/${item.exercise.id}`)
                            : history.push(`/exercice-customisation/puzzl'in/${item.exercise.id}`);
                    }}
                    sx={{
                        backgroundColor: '#FFFFFF',
                        // margin:
                        //     width < 520 && user?.role === Roles.THERAPIST
                        //         ? '1% 0%'
                        //         : '5% 0%',
                        height: width < 520 && user?.role === Roles.THERAPIST ? '33px' : '48px',
                        '&:hover': {
                            backgroundColor: '#FFFFFF'
                        }
                    }}>
                    <Typography variant="subtitle1" color="#374653">
                        {t('toModify')}
                    </Typography>
                    <EditIcon sx={{ height: '30px', width: '30px', color: '#374653' }} />
                </ControlButtons>
                <ControlButtons
                    variant="outlined"
                    sx={{
                        backgroundColor: '#F4E0EB',
                        border: 'none !important',
                        // margin:
                        //     width < 520 && user?.role === Roles.THERAPIST
                        //         ? '1% 0%'
                        //         : '5% 0%',
                        height: width < 520 && user?.role === Roles.THERAPIST ? '33px' : '48px',
                        '&:hover': {
                            border: 'none',
                            backgroundColor: '#F4E0EB'
                        }
                    }}
                    onClick={(): void => {
                        setDeleteModal(true);
                    }}>
                    <Typography variant="subtitle1" color="#D00070">
                        {t('toDelete')}
                    </Typography>
                    <DeleteIcon sx={{ height: '30px', width: '30px', color: '#D00070' }} />
                </ControlButtons>
            </Box>
        </div>
    );

    const ExerciseTitleCardData = (
        <CardActionArea>
            <Box
                onClick={(): void => {
                    onCardClicked(item.exercise.id);
                    setClickedCard(item.exercise.id);
                }}>
                {/* <div> */}
                {item.exercise && (
                    <ImageInfoContainer>
                        <div style={{ marginTop: '8px', marginLeft: '3px' }}>
                            {
                                // eslint-disable-next-line no-nested-ternary
                                item.exercise.exerciseType === ExerciseType.MEMORY ? (
                                    <div
                                        style={{
                                            width: '20px',
                                            height: '20px',
                                            marginTop: '-13px',
                                            marginLeft: '1px'
                                        }}>
                                        <BrainSvgIcon />
                                    </div>
                                ) : // eslint-disable-next-line no-nested-ternary
                                item.exercise.exerciseType === ExerciseType.MEMORIN ? (
                                    <img
                                        src={MemorinSvgIcon}
                                        alt="Memorin"
                                        style={{
                                            width: '20px',
                                            height: '20px',
                                            marginTop: '-3px',
                                            marginLeft: '4.5px'
                                        }}
                                    />
                                ) : item.exercise.exerciseType === ExerciseType.ORDER ? (
                                    <div
                                        style={{
                                            width: '20px',
                                            height: '20px',
                                            marginTop: '-13px',
                                            marginLeft: '3.5px',
                                            color: '#FF0089'
                                        }}>
                                        <TocIcon />
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            width: '20px',
                                            height: '20px',
                                            marginTop: '-13px',
                                            marginLeft: '3.5px',
                                            color: '#FF0089'
                                        }}>
                                        <ExtensionIcon />
                                    </div>
                                )
                            }
                        </div>
                    </ImageInfoContainer>
                )}
                <ImageBox>
                    <ImageContainer
                        className={classes.media}
                        onLoad={(): void => setIsImgLoaded(true)}
                        style={{
                            height: width < 520 ? 'calc(100vw / 2 - 40px)' : '200px',
                            width: width < 520 ? 'calc(100vw / 2 - 40px)' : '200px',
                            // eslint-disable-next-line no-nested-ternary
                            visibility: !bgColor ? 'visible' : isImgLoaded ? 'visible' : 'hidden'
                        }}
                        src={getImageKitUrlFrom(item.exerciseImage)}
                        alt="selectedImage"
                    />
                    {bgColor && (
                        <Box
                            className={`${classes.media} ${
                                isImgLoaded ? classes.divImageDisplay : null
                            }`}
                            style={{
                                height: width < 520 ? 'calc(100vw / 2 - 40px)' : '200px',
                                width: width < 520 ? 'calc(100vw / 2 - 40px)' : '200px',
                                backgroundColor: bgColor,
                                visibility: 'visible',
                                opacity: isImgLoaded ? 0 : 1,
                                position: 'absolute',
                                top: 0,
                                left: '10px',
                                zIndex: 50
                            }}
                        />
                    )}
                </ImageBox>
                {/* </div> */}
                <CardContent className={classes.cardContent}>
                    <Typography
                        variant="h4"
                        className={classes.exerciseName}
                        color="#3387CC"
                        sx={{
                            fontWeight: 500,
                            fontFamily: user?.role === Roles.PATIENT ? 'Luciole-Regular' : 'Inter',
                            fontSize: width < 520 ? '0.95rem !important' : '1.25rem !important',
                            lineHeight: width < 520 ? '1rem' : '1.5rem',
                            paddingTop: '10px !important',
                            paddingRight: '5px',
                            paddingLeft: '5px'
                        }}>
                        {item.exercise.exerciseName}
                    </Typography>
                </CardContent>
            </Box>
            {user?.role === Roles.ADMIN && (
                <div
                    style={{
                        width: '15px',
                        textAlign: 'left',
                        position: 'absolute',
                        marginTop: '-272px',
                        marginLeft: '15px',
                        zIndex: 3600
                    }}>
                    <Input
                        type="checkbox"
                        key="test"
                        style={{ color: '#3387CC' }}
                        value={checked}
                        onChange={(e): void => {
                            e.stopPropagation();
                            if (item.exercise.id && !checked) {
                                addExerciseToList(item.exercise);
                            }
                            if (item.exercise.id && checked) {
                                delExerciseToList(item.exercise);
                            }
                            setChecked(!checked);
                        }}
                    />
                </div>
            )}
        </CardActionArea>
    );

    const ExerciseTitleCard = (
        <Card
            sx={{
                flexGrow: 0,
                marginBottom: '16px',
                height: width < 520 ? 'calc(100vw / 2 + 20px)' : '290px',
                width: width < 520 ? 'calc(100vw / 2 - 20px)' : '220px',
                borderRadius: '9px',
                display: 'flex'
            }}>
            {/* {isSelected ? ExerciseTitleCardControls : ExerciseTitleCardData} */}
            {ExerciseTitleCardData}
            {isSelected && ExerciseTitleCardControls}
        </Card>
    );

    return (
        <Box>
            {ExerciseTitleCard}
            <TryExerciseAlerts
                isModalOpen={renderModal}
                closeModal={handleCloseModal}
                exerciseSelected={item.exercise}
            />
            <DeactiveModall
                isModelOpen={renderDeleteExerciseModal}
                setModelOpen={setDeleteModal}
                body={DeleteModalContent}
                circleIcon
                onCancel={(): void => {
                    setDeleteModal(false);
                }}
            />
        </Box>
    );
}
