import {
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    Typography,
    useMediaQuery
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import OutlinedInput from '@mui/material/OutlinedInput';
import InfiniteScroll from 'react-infinite-scroller';
import { Roles } from '../../models/Roles';
import arrowHand from '../../assets/arrowHand.png';
import { Header } from '../../components/CreateAndEdit/Header';
import { CreateNewPhotoCard } from '../../components/CreateNewPhotoCard';
import { CustomToolbar } from '../../components/CustomToolbar';
import { PreviewExercise } from './PreviewExercise';
import { UserContext } from '../../providers/UserProvider';
import {
    getExerciseItem,
    getExerciseItemsForMedia,
    getExerciseItemsForMediaAdmin,
    getPatientFromEstablishment,
    getSpecificExercises
} from '../../services/cloudFirestore';
import { ExerciseItem } from '../../models/ExerciseItem';
import { Exercise } from '../../models/Exercise';
import { ExitCustomizationModal } from '../../components/ExitCustomizationModal';
import { ExerciseType } from '../../models/ExerciseType';
import { UserDataProp } from '../../models/userDataProp';
import { ChooseImage } from './ChooseImage';
import { TitleAndIntro } from '../../components/CreateAndEdit/TitleAndIntro';
import { AlertModal } from '../../components/AlertModal';
import { ThumbnailsCards } from '../../components/ThumbnailsCards';
import { Patient } from '../../models/Patient';

const useStyles = makeStyles(() => ({
    selectedCard: {
        width: '100%',
        cursor: 'pointer',
        border: '3px solid #D00070',
        boxShadow: '2px 2px 10px 0px #52525214',
        borderRadius: '9px'
    },
    deSelectedCard: {
        width: '100%',
        cursor: 'pointer',
        border: '1px solid #E3E6E8',
        boxShadow: '2px 2px 10px 0px #52525214',
        borderRadius: '9px'
    },
    selectAuthor: {
        '& fieldset': { borderColor: '#F0F0F0 !important' },
        '@media (max-width: 598px)': {
            marginBottom: '2%'
        },
        '@media (min-width: 598px)': {
            marginLeft: 'auto'
        }
    }
}));

const NextStepButton = styled(Button)(() => ({
    margin: '5% 0%',
    border: '1px solid #8B004B',
    boxShadow: '0px 0px 0px 2px rgba(208, 0, 112, 0.4)',
    '@media (max-width: 597px)': {
        width: '95%',
        padding: '0px'
    },
    '@media (min-width: 598px)': {
        width: '35%',
        padding: '0px'
    }
}));

const BottomButtonContainer = styled(Box)({
    marginTop: '90px',
    backgroundColor: '#ffffff',
    boxShadow: '0px -2px 20px rgba(118, 118, 118, 0.2)',
    borderRadius: '24px 24px 0px 0px',
    position: 'sticky',
    bottom: 0,
    zIndex: 100
});

interface StateInterface {
    cardsArray: ExerciseItem[];
    idArray: string[];
}

export function SelectThumbnails(): JSX.Element {
    const isSmallWidth = !useMediaQuery('(min-width:598px)');
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const { type } = useParams<{ type: string }>();
    const classes = useStyles();
    const [selectedItem, setSelectedItem] = useState<string>('Tous');
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openModalAlert, setOpenModalAlert] = useState<boolean>(false);
    const [allExerciseList, setAllExerciseList] = useState<ExerciseItem[]>([]);
    const [exerciseList, setExerciseList] = useState<ExerciseItem[]>([]);
    const [activeStep, setStep] = useState<number>(0);
    const [media, setMedia] = useState<File[]>();
    const [patientList, setPatientsList] = useState<Patient[]>([]);
    const [patientSelect, setPatientSelect] = useState<string>('');
    const [selectOpen, setSelectOpen] = useState<boolean>(false);
    const [nbThumbnailsToDisplay, setNbThumbnailsToDisplay] = useState<number>(5);
    const [selectedCards, setSelectCard] = useState<StateInterface>({
        cardsArray: [],
        idArray: []
    });
    const [data, setData] = useState<Exercise>({
        id: undefined,
        exerciseType:
            // eslint-disable-next-line no-nested-ternary
            type.toUpperCase() === ExerciseType.MEMORY
                ? ExerciseType.MEMORY
                : // eslint-disable-next-line no-nested-ternary
                type.toUpperCase() === ExerciseType.MEMORIN
                ? ExerciseType.MEMORIN
                : type.toLocaleUpperCase() === ExerciseType.ORDER
                ? ExerciseType.ORDER
                : ExerciseType.PUZZLE,
        patientList: undefined,
        exerciseName: undefined,
        authorId: undefined,
        exerciseImages: undefined,
        review: undefined,
        encouragementMessage: undefined,
        congratulationsMessage: undefined,
        rewardPhoto: undefined,
        rewardVideo: undefined,
        establishmentList: undefined,
        establishmentCode:
            !id && user?.role === Roles.THERAPIST ? user.establishmentCode : undefined,
        familyCode: !id && user?.role === Roles.FAMILY ? user.familyCode : undefined,
        theme: undefined
    });

    const themeDropdownList = [
        t('all'),
        t('art'),
        t('familyAndFriends'),
        t('youth'),
        t('premises'),
        t('nature'),
        t('dayToDay'),
        t('sciences'),
        t('sport')
    ];

    const handleChange = (
        state: string,
        value: string | undefined | null | (string | undefined)[]
    ): void => {
        setData({ ...data, [state]: value });
    };

    // Get all the patient of the therapist
    useEffect(() => {
        const getPatientOfTherapist = async (): Promise<void> => {
            let patients: Patient[] | null = [];
            if (user?.role === Roles.THERAPIST) {
                if (user && user.id && user.establishmentCode) {
                    patients = await getPatientFromEstablishment(user.establishmentCode);
                    if (patients) {
                        setPatientsList(patients);
                    }
                }
            }
        };
        if (user && user?.role === Roles.THERAPIST) {
            getPatientOfTherapist();
        }
    }, []);

    useEffect(() => {
        if (selectedItem === 'Tous') {
            setExerciseList(allExerciseList);
        } else {
            const searchData = allExerciseList.filter((element) => {
                if (element.theme) {
                    return element.theme.toLowerCase().includes(selectedItem.toLowerCase());
                }
                return null;
            });
            setExerciseList(searchData);
        }
    }, [selectedItem]);

    useEffect(() => {
        const list = selectedCards.cardsArray.map((obj) => obj.id);
        handleChange('exerciseImages', list);
    }, [selectedCards]);

    // // Get ExerciseItem into Exercise if user want to modify an exercise
    useEffect(() => {
        const modifyExerciseItem = async (): Promise<void> => {
            const exerciseData = await getSpecificExercises(id);
            const list: ExerciseItem[] = [];
            const listIds: string[] = [];
            if (exerciseData && exerciseData.exerciseImages) {
                setData(exerciseData);
                await Promise.all(
                    exerciseData.exerciseImages?.map(async (element) => {
                        if (element) {
                            const item = await getExerciseItem(element);
                            if (item && item.id) {
                                list.push(item);
                                listIds.push(item.id);
                            }
                        }
                    })
                );
                setSelectCard({ cardsArray: list, idArray: listIds });
            }
        };
        if (id) {
            modifyExerciseItem();
        }
    }, []);

    const getPatientList = (): JSX.Element[] | null => {
        if (patientList && patientList.length > 0) {
            return patientList.map((item) => {
                return (
                    <MenuItem
                        key={item.familyCode}
                        value={item.familyCode}>{`${item.firstName} ${item.lastName}`}</MenuItem>
                );
            });
        }
        return null;
    };

    useEffect(() => {
        const getExerciseList = async (): Promise<void> => {
            if (user && user.id) {
                let idForSearch = '';
                if (!patientSelect || patientSelect === 'empty') {
                    if (user.role === Roles.FAMILY && user.familyCode) {
                        idForSearch = user.familyCode;
                    }
                    if (user.role === Roles.THERAPIST && user.establishmentCode) {
                        idForSearch = user.establishmentCode;
                    }
                    if (user.role === Roles.ADMIN && user.userId) {
                        idForSearch = user.userId;
                        const thumbnailsItemData = await getExerciseItemsForMediaAdmin();
                        setAllExerciseList(thumbnailsItemData);
                        setExerciseList(thumbnailsItemData);
                    }
                } else {
                    idForSearch = patientSelect;
                }
                if (idForSearch !== '' && user.role !== Roles.ADMIN) {
                    let thumbnailsItemData = await getExerciseItemsForMedia(idForSearch);
                    if (
                        type.toUpperCase() === ExerciseType.MEMORY ||
                        type.toUpperCase() === ExerciseType.MEMORIN
                    ) {
                        thumbnailsItemData = thumbnailsItemData.filter(
                            (item) => item.answer !== null
                        );
                    }
                    setAllExerciseList(thumbnailsItemData);
                    setExerciseList(thumbnailsItemData);
                }
            }
        };
        getExerciseList();
    }, [media, patientSelect]);

    const handleNextStep = (): void => {
        setStep(activeStep + 1);
    };

    const handleCardClick = (value: ExerciseItem): void => {
        if (type.toUpperCase() === ExerciseType.MEMORY) {
            if (value.id && selectedCards.idArray.indexOf(value.id) === -1) {
                if (value.id && selectedCards.idArray.length < 6) {
                    setSelectCard({
                        cardsArray: [...selectedCards.cardsArray, value],
                        idArray: [...selectedCards.idArray, value.id]
                    });
                }
            } else {
                setSelectCard({
                    idArray: selectedCards.idArray.filter((e) => e !== value.id),
                    cardsArray: selectedCards.cardsArray.filter((e) => e.id !== value.id)
                });
            }
        }
        if (type.toUpperCase() === ExerciseType.MEMORIN) {
            if (value.id && selectedCards.idArray.indexOf(value.id) === -1) {
                if (value.id && selectedCards.idArray.length < 18) {
                    setSelectCard({
                        cardsArray: [...selectedCards.cardsArray, value],
                        idArray: [...selectedCards.idArray, value.id]
                    });
                }
            } else {
                setSelectCard({
                    idArray: selectedCards.idArray.filter((e) => e !== value.id),
                    cardsArray: selectedCards.cardsArray.filter((e) => e.id !== value.id)
                });
            }
        }
        if (type.toUpperCase() === ExerciseType.PUZZLE) {
            if (value.id && selectedCards.idArray.indexOf(value.id) === -1) {
                if (value.id && selectedCards.idArray.length < 1) {
                    setSelectCard({
                        cardsArray: [...selectedCards.cardsArray, value],
                        idArray: [...selectedCards.idArray, value.id]
                    });
                }
                if (value.id && selectedCards.idArray.length === 1) {
                    setSelectCard({
                        cardsArray: [value],
                        idArray: [value.id]
                    });
                }
            } else {
                setSelectCard({
                    idArray: selectedCards.idArray.filter((e) => e !== value.id),
                    cardsArray: selectedCards.cardsArray.filter((e) => e.id !== value.id)
                });
            }
        }
        if (type.toUpperCase() === ExerciseType.ORDER) {
            if (value.id && selectedCards.idArray.indexOf(value.id) === -1) {
                if (value.id && selectedCards.idArray.length < 1) {
                    setSelectCard({
                        cardsArray: [...selectedCards.cardsArray, value],
                        idArray: [...selectedCards.idArray, value.id]
                    });
                }
                if (value.id && selectedCards.idArray.length === 1) {
                    setSelectCard({
                        cardsArray: [value],
                        idArray: [value.id]
                    });
                }
            } else {
                setSelectCard({
                    idArray: selectedCards.idArray.filter((e) => e !== value.id),
                    cardsArray: selectedCards.cardsArray.filter((e) => e.id !== value.id)
                });
            }
        }
    };

    const NewPhotoCard = (): JSX.Element => {
        return (
            <Grid
                item
                xs
                sx={{
                    flexGrow: '0 !important',
                    display: 'block'
                }}>
                <CreateNewPhotoCard
                    setMedia={setMedia}
                    bodyText={t('importThumbnails')}
                    type="thumbnails"
                    setALert={(): void => setOpenModalAlert(true)}
                />
            </Grid>
        );
    };

    const getExercises = (): JSX.Element[] | null => {
        if (exerciseList) {
            return exerciseList.slice(0, nbThumbnailsToDisplay).map((item, index) => {
                let bgColor;
                const number = index % 4;
                switch (number) {
                    case 0:
                        bgColor = '#FF008980';
                        break;
                    case 1:
                        bgColor = '#3DECCF80';
                        break;
                    case 2:
                        bgColor = '#458ff680';
                        break;
                    case 3:
                        bgColor = '#6B00AD80';
                        break;
                    default:
                        break;
                }
                return (
                    <Grid
                        item
                        xs
                        sx={{
                            flexGrow: '0 !important',
                            display: 'block'
                        }}
                        onClick={(): void => {
                            handleCardClick(item);
                        }}
                        key={item.id}>
                        <ThumbnailsCards
                            onCardClicked={(): void => {}}
                            onLeaveCard={(): void => {}}
                            item={item}
                            bgColor={bgColor}
                            classReceived={
                                item.id && selectedCards.idArray.indexOf(item.id) > -1
                                    ? classes.selectedCard
                                    : classes.deSelectedCard
                            }
                        />
                    </Grid>
                );
            });
        }
        return null;
    };

    const loadFuncThumbnails = (): void => {
        if (nbThumbnailsToDisplay + 4 <= exerciseList.length) {
            setNbThumbnailsToDisplay(nbThumbnailsToDisplay + 4);
        } else {
            setNbThumbnailsToDisplay(exerciseList.length);
        }
    };

    const screen = (
        <div>
            <Box sx={{ boxShadow: 'inset 0px -1px 0px #E3E6E8' }}>
                <Header
                    setOpenModal={setOpenModal}
                    type={data.exerciseType}
                    activeStep={activeStep}
                    user={user}
                />
            </Box>
            <Box
                sx={{
                    backgroundColor: '#F8F9F9',
                    boxShadow: 'inset 0px -1px 0px rgba(172, 188, 199, 0.4)'
                }}>
                <TitleAndIntro
                    selectedCards={selectedCards.cardsArray}
                    type={data.exerciseType}
                    activeStep={activeStep}
                    user={user}
                    icon={arrowHand}
                    endBody={t('importNew')}
                    body={t('select')}
                />
            </Box>
            {user?.role === Roles.THERAPIST && (
                <FormControl className={classes.selectAuthor} sx={{ minWidth: 200 }}>
                    <InputLabel id="demo-multiple-name-label">{t('authorPicture')}</InputLabel>
                    <Select
                        sx={{
                            borderRadius: '20px',
                            backgroundColor: selectOpen ? '#3387CC1A' : '#F0F0F0'
                        }}
                        labelId="patient-select-label"
                        id="patient-select"
                        value={patientSelect}
                        input={<OutlinedInput label="Auteur" />}
                        onOpen={(): void => {
                            setSelectOpen(!selectOpen);
                        }}
                        onClose={(): void => {
                            setSelectOpen(!selectOpen);
                        }}
                        onChange={(event): void => {
                            setPatientSelect(event.target.value);
                            setSelectedItem('Tous');
                        }}>
                        <MenuItem key="empty" value="empty">
                            {t('myPicture')}
                        </MenuItem>
                        {getPatientList()}
                    </Select>
                </FormControl>
            )}
            <CustomToolbar
                items={themeDropdownList}
                onSelectChange={(value: string): void => setSelectedItem(value)}
            />
            <InfiniteScroll
                pageStart={1}
                loadMore={(): void => {
                    if (exerciseList.length !== 0) loadFuncThumbnails();
                }}
                hasMore={true || false}
                loader={
                    <div className="loader" key={0}>
                        {nbThumbnailsToDisplay !== exerciseList.length ? t('loading') : ''}
                    </div>
                }>
                <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={window.innerWidth < 520 ? 2 : 5}
                    sx={{ justifyContent: 'center' }}>
                    {activeStep === 0 ? NewPhotoCard() : null}

                    {getExercises()}
                    {exerciseList.length === 0 &&
                        Array.from(Array(nbThumbnailsToDisplay).keys()).map((item) => {
                            let bgColor;
                            const number = item % 4;
                            switch (number) {
                                case 0:
                                    bgColor = '#FF008980';
                                    break;
                                case 1:
                                    bgColor = '#3DECCF80';
                                    break;
                                case 2:
                                    bgColor = '#458ff680';
                                    break;
                                case 3:
                                    bgColor = '#6B00AD80';
                                    break;
                                default:
                                    break;
                            }

                            return (
                                <Grid
                                    item
                                    xs
                                    sx={{
                                        flexGrow: '0 !important',
                                        display: 'block'
                                    }}>
                                    <Box
                                        sx={{
                                            height:
                                                window.innerWidth < 520
                                                    ? 'calc(100vw / 2 + 20px)'
                                                    : '290px',
                                            width:
                                                window.innerWidth < 520
                                                    ? 'calc(100vw / 2 - 20px)'
                                                    : '220px',
                                            borderRadius: '9px',
                                            padding: '10px',
                                            boxShadow:
                                                '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
                                        }}>
                                        <Skeleton
                                            variant="rectangular"
                                            style={{
                                                // backgroundColor: '#FF0089',
                                                // backgroundColor: '#3DECCF',
                                                // backgroundColor: '#458ff6',
                                                // backgroundColor: '#6B00AD',
                                                backgroundColor: bgColor,
                                                borderRadius: '9px',
                                                width: '100%',
                                                height: '100%'
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            );
                        })}
                </Grid>
            </InfiniteScroll>
            <BottomButtonContainer>
                <NextStepButton
                    disabled={
                        // eslint-disable-next-line no-nested-ternary
                        type === "souven'in"
                            ? selectedCards.idArray.length !== 6
                            : type === "memor'in"
                            ? selectedCards.idArray.length !== 18
                            : selectedCards.idArray.length !== 1
                    }
                    fullWidth={user?.role === Roles.FAMILY}
                    variant="outlined"
                    size="large"
                    style={{ margin: '2% 0%' }}
                    onClick={(): void => handleNextStep()}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        {type.toUpperCase() === ExerciseType.MEMORY ||
                        type.toUpperCase() === ExerciseType.MEMORIN
                            ? t('validateChoiceOfThumbnail')
                            : t('validateChoiceOfPicture')}
                    </Typography>
                </NextStepButton>
            </BottomButtonContainer>
        </div>
    );

    return (
        <div>
            {media && activeStep === 0 && <ChooseImage selectedImage={media} setMedia={setMedia} />}
            {!media && activeStep === 0 && screen}
            {activeStep === 1 ? (
                <PreviewExercise
                    selectedCardsReceived={selectedCards.cardsArray}
                    currentStep={setStep}
                    content={data}
                />
            ) : null}
            <AlertModal
                openModalAlert={openModalAlert}
                setOpenModalAlert={setOpenModalAlert}
                setMedia={setMedia}
                thumbnails
            />
            <ExitCustomizationModal openModal={openModal} setOpenModal={setOpenModal} />
        </div>
    );
}
