/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-template */
/* eslint-disable curly */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/lines-between-class-members */
/* eslint-disable react/sort-comp */
/* eslint-disable import/no-cycle */
// import React from 'react';
import React, { useEffect } from 'react';
import Sketch from 'react-p5';
import p5Types from 'p5';
// import MenuComponent from './menu';
// import CongratsComponent from './congrats';
import * as CONSTANTS from './constants';

import cpuImg from '../../../assets/cpu.png';
import scrolling from '../../../assets/scrolling.png';
import { getImageKitUrlFrom, getImageKitUrlFromRounded } from '../../../utilities/utils';

interface PongProps {
    cpuMode: boolean;
    setDisplayRules: Function;
    finishGame: Function;
    scoreNeedToWin: number;
    ballSpeed: number;
    cpuSpeed: { min: number; max: number };
    userImage: string;
}

interface PongState {
    finished: boolean;
    goToMenu: boolean;
}

export class PongComponent extends React.Component<PongProps, PongState> {
    cpuMode: boolean;
    setDisplayRules: Function;
    getDisplayRules: Function | undefined;
    scoreNeedToWin: number;
    finishGame: Function;
    cpuSpeedMin: number;
    cpuSpeedMax: number;
    userImage: string;

    constructor(props: PongProps) {
        super(props);
        this.cpuMode = props.cpuMode;
        this.setDisplayRules = props.setDisplayRules;
        this.scoreNeedToWin = props.scoreNeedToWin;
        this.finishGame = props.finishGame;
        this.xBallSpeed = props.ballSpeed;
        this.yBallSpeed = props.ballSpeed;
        this.cpuSpeed = Math.floor((props.cpuSpeed.min + props.cpuSpeed.max) / 2);
        this.cpuSpeedMin = props.cpuSpeed.min;
        this.cpuSpeedMax = props.cpuSpeed.max;
        this.userImage = props.userImage;

        this.state = {
            finished: false,
            // eslint-disable-next-line react/no-unused-state
            goToMenu: false
        };
    }

    windowWidth: number = window.innerWidth;
    windowHeight: number = window.innerHeight - 78;

    scoreLeft: number = 0;
    scoreRight: number = 0;
    paddleWidth: number = 24;
    paddleHeight: number = 130;
    paddleStep: number = this.windowHeight / 9;
    borderOffset: number = 5;
    diameter: number = 34;

    xPaddleLeft: number = this.borderOffset;
    yPaddleLeft: number = this.windowHeight / 2;
    xPaddleRight: number = this.windowWidth - this.borderOffset - this.paddleWidth;
    yPaddleRight: number = this.windowHeight / 2;

    leftServeXpos: number = this.xPaddleLeft + this.paddleWidth + this.diameter / 2;
    leftServeYpos: number = this.yPaddleLeft + 0.5 * this.paddleHeight;
    rightServeXpos: number = this.xPaddleRight - this.diameter / 2;
    rightServeYpos: number = this.yPaddleRight + 0.5 * this.paddleHeight;

    yBall: number = this.leftServeYpos;
    xBall: number = this.leftServeXpos;
    xBallSpeed: number;
    yBallSpeed: number;
    yHand: number = this.windowHeight / 2 - 50;
    handDirection: number = 1;
    displayHand: boolean = true;

    started: boolean = false;
    firstServe: boolean = true;
    leftServe: boolean = true;
    rightServe: boolean = false;

    canAddScoreLeft: boolean = false;

    cpuSpeed: number;
    diffCpuBall: number = 0;

    gameWasFinished: boolean = false;

    imgCpu: p5Types.Image | undefined;
    imgUser: p5Types.Image | undefined;
    imgHand: p5Types.Image | undefined;

    // p5 Canvas Setup
    setup = (p5: p5Types, canvasParentRef: Element) => {
        p5.createCanvas(this.windowWidth, this.windowHeight, 'p2d').parent(canvasParentRef);
        this.imgCpu = p5.loadImage(cpuImg);
        this.imgHand = p5.loadImage(scrolling);
        this.imgUser = p5.loadImage(getImageKitUrlFromRounded(this.userImage, 300, 300));

        setTimeout(() => {
            this.displayHand = false;
        }, 5000);

        // // désactive add point to correct on low speed
        // useEffect(() => {
        //     if (this.started) {
        //         setTimeout(() => {
        //             this.canAddScoreLeft = true;
        //         }, 100);
        //     } else {
        //         this.canAddScoreLeft = false;
        //     }
        // }, [this.started]);
    };

    // p5 Canvas Re-draw method
    draw = (p5: p5Types) => {
        p5.background(0, 0, 0);

        // stop game if finished
        if (this.state.finished && !this.gameWasFinished) {
            this.gameWasFinished = true;
            this.setStarted(false);
            this.finishGame(this.scoreLeft, this.scoreRight);
        }

        // global pause - when not started or serve in progress
        if (this.started) {
            this.xBall += this.xBallSpeed;
            this.yBall += this.yBallSpeed;
        }

        // Draw img cpu and user
        if (this.cpuMode) {
            if (this.imgCpu) {
                p5.image(
                    this.imgCpu,
                    this.windowWidth * (3 / 4) - 50,
                    this.windowHeight - 120,
                    100,
                    100
                );
            }
            if (this.imgUser) {
                p5.image(
                    this.imgUser,
                    this.windowWidth * (1 / 4) - 50,
                    this.windowHeight - 120,
                    100,
                    100
                );
            }
        }

        // draw scrolling hand
        if (this.displayHand && this.imgHand) {
            p5.image(this.imgHand, this.paddleWidth + 15, this.yHand, 100, 100);
            this.yHand += 3 * this.handDirection;
            if (this.yHand >= (this.windowHeight / 4) * 3 - 100) {
                this.handDirection = -1;
            }
            if (this.yHand <= this.windowHeight / 4) {
                this.handDirection = 1;
            }
        }

        // Detect collision with left paddle
        // if hit with upper half of paddle, redirect up, if lower half, redirect down
        if (
            this.xBall <=
                0 + this.xPaddleLeft + this.paddleWidth + this.borderOffset + this.diameter / 2 &&
            this.yBall < this.yPaddleLeft + this.paddleHeight &&
            this.yBall >= this.yPaddleLeft
        ) {
            if (this.started && this.canAddScoreLeft) {
                this.addPointLeft();
            }
            // this.xBallSpeed *= -1;
            this.xBallSpeed = Math.abs(this.xBallSpeed);
            // if (
            //     this.yBall >= this.yPaddleLeft &&
            //     this.yBall < this.yPaddleLeft + 0.5 * this.paddleHeight
            // ) {
            //     // here
            //     this.yBallSpeed = Math.abs(this.yBallSpeed) * -1;
            //     this.xBallSpeed = Math.abs(this.xBallSpeed);
            // }
            // if (
            //     this.yBall > this.yPaddleLeft + 0.5 * this.paddleHeight &&
            //     this.yBall <= this.yPaddleLeft + this.paddleHeight
            // ) {
            //     // here
            //     this.yBallSpeed = Math.abs(this.yBallSpeed);
            //     this.xBallSpeed = Math.abs(this.xBallSpeed);
            // }
        }
        // points only if behind left wall
        else if (this.xBall < this.diameter / 2) {
            this.xBallSpeed *= -1;
            this.addPointRight();
            this.setStarted(false);
            // put ball for left serve
            this.xBall = this.xPaddleLeft + this.paddleWidth + this.diameter / 2;
            this.yBall = this.yPaddleLeft + 0.5 * this.paddleHeight;
            this.leftServe = true;
            this.resetCPUSpeed();

            // serve after 2 seconds
            setTimeout(() => {
                this.mobileServeLeft(this.leftServe);
            }, 2000);
        }

        // Detect collision with right paddle
        // if hit with upper half of paddle, redirect up, if lower half, redirect down
        if (
            this.xBall >=
                this.windowWidth - this.borderOffset - this.paddleWidth - this.diameter / 2 &&
            this.yBall <= this.yPaddleRight + this.paddleHeight &&
            this.yBall >= this.yPaddleRight
        ) {
            this.xBallSpeed = Math.abs(this.xBallSpeed) * -1;
            // if (
            //     this.yBall >= this.yPaddleRight &&
            //     this.yBall < this.yPaddleRight + 0.5 * this.paddleHeight
            // ) {
            //     this.yBallSpeed = Math.abs(this.yBallSpeed) * -1;
            //     this.xBallSpeed = Math.abs(this.xBallSpeed) * -1;
            // }
            // if (
            //     this.yBall > this.yPaddleRight + 0.5 * this.paddleHeight &&
            //     this.yBall <= this.yPaddleRight + this.paddleHeight
            // ) {
            //     this.yBallSpeed = Math.abs(this.yBallSpeed);
            //     this.xBallSpeed = Math.abs(this.xBallSpeed) * -1;
            // }
        }
        // points if behind right wall
        // pause game and do serve position for the lost point user
        else if (this.xBall + this.diameter / 2 > this.windowWidth) {
            this.xBallSpeed *= -1;
            this.addPointLeft();
            this.setStarted(false);
            // put ball for right serve
            this.xBall = this.xPaddleRight - this.diameter / 2;
            this.yBall = this.yPaddleRight + 0.5 * this.paddleHeight;
            this.rightServe = true;
            this.resetCPUSpeed();

            // setTimeout(() => {
            //     this.mobileServeRight(this.rightServe);
            // }, 1000);
        }

        this.bounceTopBottom();

        if (this.started === false && this.firstServe === true) {
            this.firstServe = false;
            // speed first serve
            setTimeout(() => {
                this.mobileServeLeft(this.leftServe);
            }, 5200);
        }

        // Draw paddle left
        p5.fill(96, 245, 255);
        p5.noStroke();
        p5.rect(this.xPaddleLeft, this.yPaddleLeft, this.paddleWidth, this.paddleHeight);

        // Draw paddle right
        p5.fill(96, 245, 255);
        p5.noStroke();
        p5.rect(this.xPaddleRight, this.yPaddleRight, this.paddleWidth, this.paddleHeight);

        this.drawStaticItems(p5);

        // Draw ball (top layer)
        p5.fill(255, 255, 255);
        p5.ellipse(this.xBall, this.yBall, this.diameter, this.diameter);

        this.cpuShouldAction();
        this.boundToWindow();
    };

    setStarted = (newVal: boolean) => {
        this.started = newVal;
        if (this.started) {
            setTimeout(() => {
                this.canAddScoreLeft = true;
            }, 100);
        } else {
            this.canAddScoreLeft = false;
        }
    };

    cpuShouldAction = () => {
        if (this.cpuMode) {
            this.diffCpuBall = this.yBall - this.yPaddleRight;
            if (this.rightServe) {
                this.rightServe = false;
                setTimeout(() => this.cpuServe(), 2000);
            }
            if (this.started) {
                this.cpuMove();
            }
        }
    };

    cpuServe = () => {
        this.setStarted(true);
        this.rightServe = false;
    };

    addDelayToScore = () => {
        this.canAddScoreLeft = false;
        setTimeout(() => {
            this.canAddScoreLeft = true;
        }, 100);
    };

    addPointLeft = () => {
        this.scoreLeft++;
        this.addDelayToScore();
        if (this.scoreLeft === this.scoreNeedToWin) {
            this.setState({ finished: true });
        }
    };
    addPointRight = () => {
        this.scoreRight++;
        if (this.scoreRight === this.scoreNeedToWin && !this.cpuMode) {
            this.setState({ finished: true });
        }
    };

    // CPU move right paddle
    cpuMove = () => {
        this.yPaddleRight +=
            this.diffCpuBall >= this.cpuSpeed * 0.7 ? this.cpuSpeed : this.cpuSpeed * -1;

        // bound to play window
        if (this.yPaddleRight <= 0) {
            this.yPaddleRight = 0;
        }
        if (this.yPaddleRight + this.paddleHeight >= this.windowHeight) {
            this.yPaddleRight = this.windowHeight - this.paddleHeight;
        }

        // Randomize CPU speed
        const speedDiff = Math.floor(Math.random() * 10);
        this.cpuSpeed += Math.random() <= 0.5 ? speedDiff : speedDiff * -1;

        // bound the CPU speed change
        if (this.cpuSpeed > this.cpuSpeedMax) {
            this.cpuSpeed = this.cpuSpeedMax;
        }
        if (this.cpuSpeed < this.cpuSpeedMin) {
            this.cpuSpeed = this.cpuSpeedMin;
        }
    };

    resetCPUSpeed = () => {
        if (this.cpuMode) {
            this.cpuSpeed = 8;
        }
    };
    bounceTopBottom = () => {
        // bounce from top and bottom
        if (this.yBall < this.diameter / 2 || this.yBall > this.windowHeight - this.diameter) {
            this.yBallSpeed *= -1;
        }
    };
    moveBallDuringRightServe = (moveBallDuringRightServe: boolean) => {
        if (moveBallDuringRightServe) {
            this.xBall = this.xPaddleRight - this.diameter / 2;
            this.yBall = this.yPaddleRight + 0.5 * this.paddleHeight;
        }
    };

    moveBallDuringLeftServe = (moveBallDuringLeftServe: boolean) => {
        if (moveBallDuringLeftServe) {
            this.xBall = this.xPaddleLeft + this.paddleWidth + this.diameter / 2;
            this.yBall = this.yPaddleLeft + 0.5 * this.paddleHeight;
        }
    };

    mobileServeRight = (rightServe: boolean) => {
        if (rightServe) {
            this.xBallSpeed *= this.xBallSpeed > 0 ? -1 : 1;
            this.rightServe = false;
            this.setStarted(true);
        }
    };

    mobileServeLeft = (leftServe: boolean) => {
        if (leftServe) {
            this.xBallSpeed *= this.xBallSpeed > 0 ? 1 : -1;
            this.leftServe = false;
            this.setStarted(true);
        }
    };

    boundToWindow = () => {
        if (this.yPaddleLeft <= 0) this.yPaddleLeft = 0;
        if (this.yPaddleLeft + this.paddleHeight >= this.windowHeight)
            this.yPaddleLeft = this.windowHeight - this.paddleHeight;
        if (this.yPaddleRight <= 0) this.yPaddleRight = 0;
        if (this.yPaddleRight + this.paddleHeight >= this.windowHeight)
            this.yPaddleRight = this.windowHeight - this.paddleHeight;
    };

    drawStaticItems = (p5: p5Types) => {
        // Draw middle line
        for (let i = 0; i < this.windowHeight; i += 20) {
            if (i % 40 === 0) {
                p5.fill(96, 245, 255);
                p5.noStroke();
                p5.rect(
                    (this.windowWidth - this.paddleWidth) / 2,
                    i - 8,
                    this.paddleWidth / 2,
                    this.windowHeight / 30
                );
            }
        }

        // Draw scores
        p5.textFont('Luciole-Regular', 36);
        p5.fill(96, 245, 255);
        p5.textSize(40);
        p5.text(
            // this.scoreLeft < 10 ? '0' + this.scoreLeft : this.scoreLeft,
            this.scoreLeft + ' point' + (this.scoreLeft > 1 ? 's' : ''),
            this.windowWidth * (1 / 4) - 110,
            40
        );
        if (!this.cpuMode) {
            p5.text(
                this.scoreRight < 10 ? '0' + this.scoreRight : this.scoreRight,
                this.windowWidth * (3 / 4),
                50
            );
        }

        // // title & menu back text
        // p5.textSize(50);
        // p5.text('IvoPong', (this.windowWidth - this.paddleWidth) / 2 - 100, this.windowHeight - 40);
        // p5.textSize(20);
        // p5.text(
        //     'ESC to Menu',
        //     (this.windowWidth - this.paddleWidth) / 2 - 62,
        //     this.windowHeight - 20
        // );
    };

    // p5 event on mobile screen tap / desktop click
    touchStartedSinglePlayer = (t: any) => {
        if (t.pmouseY < 0.5 * t.height) {
            this.yPaddleLeft -= this.paddleStep;
        } else {
            this.yPaddleLeft += this.paddleStep;
        }
        // this.boundToWindow();
        this.mobileServeLeft(this.leftServe);
    };

    mouveWhitMouse = (t: any) => {
        if (
            t.pmouseY < this.windowHeight - this.paddleHeight / 2 &&
            t.pmouseY > this.paddleHeight / 2
        )
            this.yPaddleLeft = t.pmouseY - this.paddleHeight / 2;
        if (t.pmouseY >= this.windowHeight - this.paddleHeight / 2)
            this.yPaddleLeft = this.windowHeight - this.paddleHeight;
        if (t.pmouseY <= this.paddleHeight / 2) this.yPaddleLeft = 0;
        if (this.leftServe) {
            // put ball for left serve
            this.xBall = this.xPaddleLeft + this.paddleWidth + this.diameter / 2;
            this.yBall = this.yPaddleLeft + 0.5 * this.paddleHeight;
        }
    };

    // p5 event on mobile screen tap / desktop click
    touchStartedTwoPlayers = (t: any) => {
        // right
        if (t.pmouseY < 0.5 * t.height && t.pmouseX > 0.5 * t.width) {
            this.yPaddleRight -= this.paddleStep;
        }
        if (t.pmouseY > 0.5 * t.height && t.pmouseX > 0.5 * t.width) {
            this.yPaddleRight += this.paddleStep;
        }
        // left
        if (t.pmouseY < 0.5 * t.height && t.pmouseX < 0.5 * t.width) {
            this.yPaddleLeft -= this.paddleStep;
        }
        if (t.pmouseY > 0.5 * t.height && t.pmouseX < 0.5 * t.width) {
            this.yPaddleLeft += this.paddleStep;
        }
        // this.boundToWindow();
        this.mobileServeRight(this.rightServe);
        this.mobileServeLeft(this.leftServe);
    };

    pause = () => {
        this.setStarted(!this.started);
    };

    // p5 event on key press
    keyPressed = (e: any) => {
        // esc to menu
        if (e.keyCode === CONSTANTS.ESC) {
            this.pause();
            // if (this.setDisplayRules) this.setDisplayRules(true);
        }
        if (e.keyCode === CONSTANTS.SPACEBAR) {
            // space
            this.setStarted(true);
            if (this.leftServe) {
                this.xBallSpeed = Math.abs(this.xBallSpeed);
            }
            if (this.rightServe) {
                this.xBallSpeed = Math.abs(this.xBallSpeed) * -1;
            }
            this.leftServe = false;
            this.rightServe = false;
        }
        // 2nd player keys W (87) and S (83)
        if (!this.cpuMode) {
            if (e.keyCode === CONSTANTS.UP_ARROW || e.keyCode === CONSTANTS.LEFT_ARROW) {
                this.yPaddleRight -= this.paddleStep;
            }
            if (e.keyCode === CONSTANTS.DOWN_ARROW || e.keyCode === CONSTANTS.RIGHT_ARROW) {
                this.yPaddleRight += this.paddleStep;
            }
        }

        if (e.keyCode === CONSTANTS.Z_KEY) {
            this.yPaddleLeft -= this.paddleStep;
        }
        if (e.keyCode === CONSTANTS.S_KEY) {
            this.yPaddleLeft += this.paddleStep;
        }

        this.moveBallDuringLeftServe(this.leftServe);
        this.moveBallDuringRightServe(this.rightServe);
        // this.boundToWindow();
    };

    render() {
        return (
            <Sketch
                style={{
                    position: 'relative',
                    width: '100vw',
                    height: '100%',
                    overflow: 'hidden'
                    // overscrollBehavior: 'none'
                }}
                setup={this.setup}
                draw={this.draw}
                keyPressed={this.keyPressed}
                // touchStarted={
                //     this.cpuMode ? this.touchStartedSinglePlayer : this.touchStartedTwoPlayers
                // }
                mouseMoved={this.mouveWhitMouse}
                touchMoved={this.mouveWhitMouse}
            />
        );
    }
}

// eslint-disable-next-line import/no-default-export
export default PongComponent;
