// eslint-disable-next-line import/no-extraneous-dependencies
import { Timestamp } from '@firebase/firestore';
import _ from 'lodash';
import { EstablishmentsExportData } from '../models/EstablishmentsExportData';
import locationIcon from '../assets/locationIcon.png';
import artIconWhite from '../assets/artIconWhite.png';
import natureIconWhite from '../assets/natureIconWhite.png';
import scienceIcon from '../assets/scienceIcon.png';
import sportIcon from '../assets/sportIcon.png';
import familyAndFriendIcon from '../assets/familyAndFriendIcon.png';
import dailyIcon from '../assets/dailyIcon.png';
import happyIconWhite from '../assets/happyIconWhite.png';
import { User } from '../models/User';
import { Roles } from '../models/Roles';
import { ExerciseType } from '../models/ExerciseType';

const imageKitBaseUrl = 'https://ik.imagekit.io/ttd0gzik4ov';
const firebaseStorageBaseUrl = 'https://firebasestorage.googleapis.com';

export const getImageKitUrlFrom = (url: string, width?: number, height?: number): string => {
    if (width && height) {
        return url.replace(firebaseStorageBaseUrl, `${imageKitBaseUrl}/tr:w-${width},h-${height}`);
    }
    return url.replace(firebaseStorageBaseUrl, imageKitBaseUrl);
};

export const getImageKitUrlFromRounded = (url: string, width?: number, height?: number): string => {
    if (width && height) {
        return url.replace(
            firebaseStorageBaseUrl,
            `${imageKitBaseUrl}/tr:w-${width},h-${height},r-max`
        );
    }
    return url.replace(firebaseStorageBaseUrl, imageKitBaseUrl);
};

export const getImageKitUrlFromCroped = (
    url: string,
    width: number,
    height: number,
    startWidth: number,
    startHeight: number
): string => {
    return url.replace(
        firebaseStorageBaseUrl,
        `${imageKitBaseUrl}/tr:w-${width},h-${height},cm-extract,x-${startWidth},y-${startHeight}`
    );
};

export const validateEmail = (emailReceived: string | null): boolean => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailReceived && emailReceived.trim() !== '') {
        return emailRegex.test(emailReceived);
    }
    return false;
};

export const isPhoneNumberValidate = (number: string): boolean => {
    const pattern = new RegExp(/^[0-9]{10}$/i);
    const patternWithCountryCode = new RegExp(/\+[0-9]{12}\b/);
    if (pattern.test(number) || patternWithCountryCode.test(number)) {
        return true;
    }
    return false;
};

export const validatePassword = (password: string): number[] => {
    const hasCaps = /[A-Z]/;
    const hasSmall = /[a-z]/;
    const hasDigit = /[0-9]/;
    const list: number[] = [];
    if (hasCaps.test(password)) {
        list.push(2);
    }
    if (hasSmall.test(password)) {
        list.push(1);
    }
    if (hasDigit.test(password)) {
        list.push(3);
    }
    if (password.length > 7) {
        list.push(0);
    }
    return list;
};

export const getDateFromTimestamp = (timestampData: Timestamp): string => {
    return new Date(timestampData.seconds * 1000).toLocaleDateString('fr');
};

export const getLegalReferant = (legalReferent: boolean | undefined): string => {
    if (legalReferent) {
        return 'Oui';
    }
    return 'Non';
};

export const convertToTitleCase = (str: string): string => {
    return str.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase());
};

export const exportCsvHeaders = (): string[] => {
    return [
        "Nom de l'établissement",
        'Nom',
        'Prénom',
        "Type d'utilisateur",
        'Sexe',
        "Niveau d'étude",
        'Catégorie socio-professionnelle',
        'Date de naissance',
        'Lien de parenté (famille) ou Fonction (thérapeute)',
        'Référent familial / légal ou Etablissement',
        'Code famille ou établissement',
        'Adresse postale',
        'Code postal',
        'Ville',
        'Email',
        'N° portable',
        'Date début abonnement',
        'Date de fin abonnement',
        'Patient account maximum number',
        'Therapist account maximum number',
        'Family account maximum number',
        'Statut compte',
        'Date de création du compte',
        'CGU validés ?',
        'Date CGU validés'
    ];
};

export const exportCsvData = (content: EstablishmentsExportData[]): string[][] => {
    const data = [exportCsvHeaders()];
    content.forEach((item) => {
        data.push([
            item.establishmentName,
            item.lastName,
            item.firstName,
            item.typeOfUser,
            item.gender,
            item.levelOfStudy,
            item.socioProfessionalCategory,
            item.dob,
            item.relationshipOrFunction,
            item.familyOrLegalReferent,
            item.establishmentCode,
            item.address,
            item.postalCode,
            item.city,
            item.emailId,
            item.mobileNumber,
            item.startDate,
            item.endDate,
            item.patientsMaxCount.toString(),
            item.therapistsMaxCount.toString(),
            item.famillesMaxCount.toString(),
            item.status,
            item.createdDate,
            item.cguValidate,
            item.cguValidateDate
        ]);
    });
    return data;
};

export const isNumberOnly = (number: string): boolean => {
    const pattern = new RegExp('^[0-9]+$');
    if (pattern.test(number)) {
        return true;
    }
    return false;
};

export const getPatientFamilyCode = (): string => {
    const chars = 'abcdefghijklmnopqrstufwxyzABCDEFGHIJKLMNOPQRSTUFWXYZ1234567890';
    const pwd = _.sampleSize(chars, 6 || 12);
    return pwd.join('');
};

export const getMultipleRandom = (arr: number[], num: number): number[] => {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());

    return shuffled.slice(0, num);
};

export const determineDifficultyLevel = (level: number): number => {
    if (level === 1) {
        return 3;
    }
    if (level === 2) {
        return 4;
    }
    if (level === 3) {
        return 5;
    }
    return 6;
};

export const randomPicture = (level: number, random: number, exoType: ExerciseType): number[] => {
    const arrayLvlToPicture = {
        "PUZZL'IN": [0],
        "SOUVEN'IN": [3, 4, 4, 4, 6, 4, 5, 5, 6, 6],
        "MEMOR'IN": [2, 3, 4, 5, 6, 8, 10, 12, 15, 18],
        "SUDOK'IN": [0],
        "PONG'IN": [0],
        "ORDON'IN": [0]
    };
    let list: number[] = [];
    let array: number[];
    if (exoType === ExerciseType.MEMORIN) {
        array = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];
    } else {
        array = [0, 1, 2, 3, 4, 5];
    }
    array.splice(array.indexOf(random), 1);
    // if (exoType === ExerciseType.MEMORIN && level > 4) {
    //     list = [0, 1, 2, 3, 4, 5];
    //     // eslint-disable-next-line array-callback-return
    //     getMultipleRandom(array, arrayLvlToPicture[exoType][level - 1] - 7).map((item) => {
    //         list.push(item);
    //     });
    // } else {
    list = getMultipleRandom(array, arrayLvlToPicture[exoType][level - 1] - 1);
    // }
    list.push(random);

    return list;
};

export const decreasePuzzlin = (count: number, difficultyLevel: number): boolean => {
    if (difficultyLevel === 2 && count >= 5) {
        return true;
    }
    if (difficultyLevel === 3 && count >= 8) {
        return true;
    }
    if (difficultyLevel === 4 && count >= 11) {
        return true;
    }
    if (difficultyLevel === 5 && count >= 15) {
        return true;
    }
    if (difficultyLevel === 6 && count >= 19) {
        return true;
    }
    if (difficultyLevel === 7 && count >= 24) {
        return true;
    }
    return false;
};

export const decreaseSouvenin = (count: number, difficultyLevel: number): boolean => {
    if (difficultyLevel === 2 && count >= 5) {
        return true;
    }
    if (difficultyLevel === 3 && count >= 9) {
        return true;
    }
    if (difficultyLevel === 4 && count >= 14) {
        return true;
    }
    if (difficultyLevel === 5 && count >= 8) {
        return true;
    }
    if (difficultyLevel === 6 && count >= 11) {
        return true;
    }
    return false;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getDate = (date: any): Date => {
    const dateArray = date.split('/');
    const dateComplete = new Date(dateArray[2], dateArray[1] - 1, dateArray[0]);
    return dateComplete;
};

const translateMonth = (month: string, stringMonth?: boolean): string => {
    if (month === 'Jan') {
        return stringMonth ? 'janvier' : '01';
    }
    if (month === 'Feb') {
        return stringMonth ? 'février' : '02';
    }
    if (month === 'Mar') {
        return stringMonth ? 'mars' : '03';
    }
    if (month === 'Apr') {
        return stringMonth ? 'avril' : '04';
    }
    if (month === 'May') {
        return stringMonth ? 'mai' : '05';
    }
    if (month === 'Jun') {
        return stringMonth ? 'juin' : '06';
    }
    if (month === 'Jul') {
        return stringMonth ? 'juillet' : '07';
    }
    if (month === 'Aug') {
        return stringMonth ? 'août' : '08';
    }
    if (month === 'Sep') {
        return stringMonth ? 'septembre' : '09';
    }
    if (month === 'Oct') {
        return stringMonth ? 'octobre' : '10';
    }
    if (month === 'Nov') {
        return stringMonth ? 'novembre' : '11';
    }
    if (month === 'Dec') {
        return stringMonth ? 'décembre' : '12';
    }
    return '';
};
const translateMonthByNumber = (month: string): string => {
    if (month === '01') {
        return 'janvier';
    }
    if (month === '02') {
        return 'février';
    }
    if (month === '03') {
        return 'mars';
    }
    if (month === '04') {
        return 'avril';
    }
    if (month === '05') {
        return 'mai';
    }
    if (month === '06') {
        return 'juin';
    }
    if (month === '07') {
        return 'juillet';
    }
    if (month === '08') {
        return 'août';
    }
    if (month === '09') {
        return 'septembre';
    }
    if (month === '10') {
        return 'octobre';
    }
    if (month === '11') {
        return 'novembre';
    }
    if (month === '12') {
        return 'décembre';
    }
    return '';
};

export const translateDate = (date: string): string => {
    let stringDate;
    const words = date.split(' ');
    if (words.length === 4) {
        words[1] = translateMonth(words[1]);
        stringDate = `${words[2]}/${words[1]}/${words[3]}`;
        return stringDate;
    }
    return '';
};

export const strDateTolongStr = (date: string): string => {
    let stringDate;
    const words = date.split('/');
    if (words.length === 3) {
        words[1] = translateMonthByNumber(words[1]);
        stringDate = `${words[0]} ${words[1]} ${words[2]}`;
        return stringDate;
    }
    return '';
};

export const translateDateToString = (date: string): string => {
    let stringDate;
    const words = date.split(' ');
    if (words.length === 4) {
        words[1] = translateMonth(words[1], true);
        stringDate = `${words[2]} ${words[1]} ${words[3]}`;
        return stringDate;
    }
    return '';
};

export const getThemeIcon = (theme: string): string => {
    if (theme === 'Art et culture') {
        return artIconWhite;
    }
    if (theme === 'Nature') {
        return natureIconWhite;
    }
    if (theme === 'Quotidien') {
        return dailyIcon;
    }
    if (theme === 'Jeunesse') {
        return happyIconWhite;
    }
    if (theme === 'Lieux et voyage') {
        return locationIcon;
    }
    if (theme === 'Famille et amis') {
        return familyAndFriendIcon;
    }
    if (theme === 'Sciences') {
        return scienceIcon;
    }
    if (theme === 'Sport') {
        return sportIcon;
    }
    return locationIcon;
};

export const capitalizeFirstLetter = (a: string): string => {
    return a.charAt(0).toUpperCase() + a.slice(1);
};

export const codeStorage = (user: User): string | null => {
    if (user?.role === Roles.FAMILY && user?.familyCode) {
        return user.familyCode;
    }
    if (user?.role === Roles.THERAPIST && user.establishmentCode) {
        return user.establishmentCode;
    }
    if (user?.role === Roles.ADMIN) {
        return 'admin';
    }
    return null;
};

export const YoutubeAPIKey = 'AIzaSyAr9CT1rhhTBnOfTQyM3YNGoBvsk46s_Lk'; // PROD
// export const YoutubeAPIKey = 'AIzaSyBR1Den86CcKYvU3lT27lAvVMG8ED5aqsg'; // DEV

export const youtube_parser = (url: string): string | boolean => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[7].length === 11 ? match[7] : false;
};
