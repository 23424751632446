import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Grid } from '@mui/material';
import ExtensionIcon from '@mui/icons-material/Extension';
import { LittleButton } from './NewComponents/LittleButton';
import logo from '../assets/logo.png';
import infoBlue from '../assets/infoBlue.svg';
import { ExerciseType } from '../models/ExerciseType';
import { updatePatientDifficultyLevel } from '../services/cloudFirestore';
import brainSvgIcon from '../assets/brainSvgIcon.svg';
import sudokuSvgIcon from '../assets/sudokuSvgIcon.svg';
import MemorinSvgIcon from '../assets/MemorinSvgIcon.svg';
import pongSvgIcon from '../assets/pongSvgIcon.svg';
import tocSvgIcon from '../assets/tocSvgIcon.svg';

import memorinLvlChoise1 from '../assets/memorinLvlChoise1.png';
import memorinLvlChoise2 from '../assets/memorinLvlChoise2.png';
import memorinLvlChoise3 from '../assets/memorinLvlChoise3.png';
import memorinLvlChoise4 from '../assets/memorinLvlChoise4.png';
import memorinLvlChoise5 from '../assets/memorinLvlChoise5.png';
import memorinLvlChoise6 from '../assets/memorinLvlChoise6.png';
import memorinLvlChoise7 from '../assets/memorinLvlChoise7.png';
import memorinLvlChoise8 from '../assets/memorinLvlChoise8.png';
import memorinLvlChoise9 from '../assets/memorinLvlChoise9.png';
import memorinLvlChoise10 from '../assets/memorinLvlChoise10.png';

import quizinLvlChoise1 from '../assets/quizinLvlChoise1.png';
import quizinLvlChoise2 from '../assets/quizinLvlChoise2.png';
import quizinLvlChoise3 from '../assets/quizinLvlChoise3.png';
import quizinLvlChoise4 from '../assets/quizinLvlChoise4.png';
import quizinLvlChoise5 from '../assets/quizinLvlChoise5.png';
import quizinLvlChoise6 from '../assets/quizinLvlChoise6.png';
import quizinLvlChoise7 from '../assets/quizinLvlChoise7.png';
import quizinLvlChoise8 from '../assets/quizinLvlChoise8.png';
import quizinLvlChoise9 from '../assets/quizinLvlChoise9.png';
import quizinLvlChoise10 from '../assets/quizinLvlChoise10.png';

import puzzlinLvlChoise1 from '../assets/puzzlinLvlChoise1.png';
import puzzlinLvlChoise2 from '../assets/puzzlinLvlChoise2.png';
import puzzlinLvlChoise3 from '../assets/puzzlinLvlChoise3.png';
import puzzlinLvlChoise4 from '../assets/puzzlinLvlChoise4.png';
import puzzlinLvlChoise5 from '../assets/puzzlinLvlChoise5.png';
import puzzlinLvlChoise6 from '../assets/puzzlinLvlChoise6.png';
import puzzlinLvlChoise7 from '../assets/puzzlinLvlChoise7.png';
import puzzlinLvlChoise8 from '../assets/puzzlinLvlChoise8.png';
import puzzlinLvlChoise9 from '../assets/puzzlinLvlChoise9.png';
import puzzlinLvlChoise10 from '../assets/puzzlinLvlChoise10.png';

import sudokinLvlChoise1 from '../assets/sudokinLvlChoise1.svg';
import sudokinLvlChoise2 from '../assets/sudokinLvlChoise2.svg';
import sudokinLvlChoise3 from '../assets/sudokinLvlChoise3.svg';
import sudokinLvlChoise4 from '../assets/sudokinLvlChoise4.svg';
import sudokinLvlChoise5 from '../assets/sudokinLvlChoise5.svg';
import sudokinLvlChoise6 from '../assets/sudokinLvlChoise6.svg';
import sudokinLvlChoise7 from '../assets/sudokinLvlChoise7.svg';
import sudokinLvlChoise8 from '../assets/sudokinLvlChoise8.svg';
import sudokinLvlChoise9 from '../assets/sudokinLvlChoise9.svg';
import sudokinLvlChoise10 from '../assets/sudokinLvlChoise10.svg';

import reorderLvlChoise1 from '../assets/reorderLevelChoise1.png';
import reorderLvlChoise2 from '../assets/reorderLevelChoise2.png';
import reorderLvlChoise3 from '../assets/reorderLevelChoise3.png';
import reorderLvlChoise4 from '../assets/reorderLevelChoise4.png';
import reorderLvlChoise5 from '../assets/reorderLevelChoise5.png';
import reorderLvlChoise6 from '../assets/reorderLevelChoise6.png';
import reorderLvlChoise7 from '../assets/reorderLevelChoise7.png';
import reorderLvlChoise8 from '../assets/reorderLevelChoise8.png';
import reorderLvlChoise9 from '../assets/reorderLevelChoise9.png';
import reorderLvlChoise10 from '../assets/reorderLevelChoise10.png';

import ponginLvlChoise from '../assets/ponginLvlChoise.png';

import speed1 from '../assets/speed1.png';
import speed2 from '../assets/speed2.png';
import speed3 from '../assets/speed3.png';
import speed4 from '../assets/speed4.png';
import speed5 from '../assets/speed5.png';
import speed6 from '../assets/speed6.png';

const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '100%',
    bgcolor: '#F5F5F5',
    color: '#0F0046',
    // borderRadius: '24px',
    boxShadow: 24,
    minHeight: '100vh',
    outline: 'none',
    overflow: 'auto',
    fontFamily: 'Luciole-Regular'
};

interface ModalProps {
    isModalOpen: boolean;
    closeModal: Function;
    exerciseType: ExerciseType | undefined;
    lvlArray: number[];
    callback: Function;
    userId: string | undefined;
    exoDifficultyLevel: {
        memoryDifficultyLevel: number;
        puzzleDifficultyLevel: number;
        memorinDifficultyLevel: number;
        sudokuDifficultyLevel: number;
        pongDifficultyLevel: number;
        reorderDifficultyLevel: number;
    };
}

export function LvlChoiseModal({
    isModalOpen,
    closeModal,
    exerciseType,
    lvlArray,
    callback,
    userId,
    exoDifficultyLevel
}: ModalProps): JSX.Element {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const memorinImages = [
        memorinLvlChoise1,
        memorinLvlChoise2,
        memorinLvlChoise3,
        memorinLvlChoise4,
        memorinLvlChoise5,
        memorinLvlChoise6,
        memorinLvlChoise7,
        memorinLvlChoise8,
        memorinLvlChoise9,
        memorinLvlChoise10
    ];

    const quizinImages = [
        quizinLvlChoise1,
        quizinLvlChoise2,
        quizinLvlChoise3,
        quizinLvlChoise4,
        quizinLvlChoise5,
        quizinLvlChoise6,
        quizinLvlChoise7,
        quizinLvlChoise8,
        quizinLvlChoise9,
        quizinLvlChoise10
    ];

    const sudokinImages = [
        sudokinLvlChoise1,
        sudokinLvlChoise2,
        sudokinLvlChoise3,
        sudokinLvlChoise4,
        sudokinLvlChoise5,
        sudokinLvlChoise6,
        sudokinLvlChoise7,
        sudokinLvlChoise8,
        sudokinLvlChoise9,
        sudokinLvlChoise10
    ];

    const puzzlinImages = [
        puzzlinLvlChoise1,
        puzzlinLvlChoise2,
        puzzlinLvlChoise3,
        puzzlinLvlChoise4,
        puzzlinLvlChoise5,
        puzzlinLvlChoise6,
        puzzlinLvlChoise7,
        puzzlinLvlChoise8,
        puzzlinLvlChoise9,
        puzzlinLvlChoise10
    ];

    const reorderImages = [
        reorderLvlChoise1,
        reorderLvlChoise2,
        reorderLvlChoise3,
        reorderLvlChoise4,
        reorderLvlChoise5,
        reorderLvlChoise6,
        reorderLvlChoise7,
        reorderLvlChoise8,
        reorderLvlChoise9,
        reorderLvlChoise10
    ];

    const speedImages = [
        speed1,
        speed2,
        speed2,
        speed3,
        speed3,
        speed4,
        speed4,
        speed5,
        speed5,
        speed6
    ];

    const changeDifficultyLevel = async (
        value: number | null,
        type: ExerciseType
    ): Promise<void> => {
        if (userId) {
            if (type === ExerciseType.MEMORY && value !== null) {
                await updatePatientDifficultyLevel(
                    value,
                    exoDifficultyLevel.memorinDifficultyLevel,
                    exoDifficultyLevel.puzzleDifficultyLevel,
                    exoDifficultyLevel.sudokuDifficultyLevel,
                    exoDifficultyLevel.pongDifficultyLevel,
                    exoDifficultyLevel.reorderDifficultyLevel,
                    userId
                );
            }
            if (type === ExerciseType.PUZZLE && value !== null) {
                await updatePatientDifficultyLevel(
                    exoDifficultyLevel.memoryDifficultyLevel,
                    exoDifficultyLevel.memorinDifficultyLevel,
                    value,
                    exoDifficultyLevel.sudokuDifficultyLevel,
                    exoDifficultyLevel.pongDifficultyLevel,
                    exoDifficultyLevel.reorderDifficultyLevel,
                    userId
                );
            }
            if (type === ExerciseType.MEMORIN && value !== null) {
                await updatePatientDifficultyLevel(
                    exoDifficultyLevel.memoryDifficultyLevel,
                    value,
                    exoDifficultyLevel.puzzleDifficultyLevel,
                    exoDifficultyLevel.sudokuDifficultyLevel,
                    exoDifficultyLevel.pongDifficultyLevel,
                    exoDifficultyLevel.reorderDifficultyLevel,
                    userId
                );
            }
            if (type === ExerciseType.SUDOKU && value !== null) {
                await updatePatientDifficultyLevel(
                    exoDifficultyLevel.memoryDifficultyLevel,
                    exoDifficultyLevel.memorinDifficultyLevel,
                    exoDifficultyLevel.puzzleDifficultyLevel,
                    value,
                    exoDifficultyLevel.pongDifficultyLevel,
                    exoDifficultyLevel.reorderDifficultyLevel,
                    userId
                );
            }
            if (type === ExerciseType.PONG && value !== null) {
                await updatePatientDifficultyLevel(
                    exoDifficultyLevel.memoryDifficultyLevel,
                    exoDifficultyLevel.memorinDifficultyLevel,
                    exoDifficultyLevel.puzzleDifficultyLevel,
                    exoDifficultyLevel.sudokuDifficultyLevel,
                    value,
                    exoDifficultyLevel.reorderDifficultyLevel,
                    userId
                );
            }
            if (type === ExerciseType.ORDER && value !== null) {
                await updatePatientDifficultyLevel(
                    exoDifficultyLevel.memoryDifficultyLevel,
                    exoDifficultyLevel.memorinDifficultyLevel,
                    exoDifficultyLevel.puzzleDifficultyLevel,
                    exoDifficultyLevel.sudokuDifficultyLevel,
                    exoDifficultyLevel.pongDifficultyLevel,
                    value,
                    userId
                );
            }
        }
    };

    const clickOnDifficulty = async (difficulty: number): Promise<void> => {
        // // check exeption for puzzle --> lvl 8 and 9 not available
        // if (exerciseType === ExerciseType.PUZZLE && difficulty >= 8) {
        //     return;
        // }

        if (exerciseType) {
            setLoading(true);
            await changeDifficultyLevel(difficulty, exerciseType);
            callback();
            setLoading(false);
        } else {
            closeModal();
        }
    };

    const getImage = (index: number): string => {
        switch (exerciseType) {
            case ExerciseType.MEMORIN:
                return memorinImages[index];
            case ExerciseType.PUZZLE:
                return puzzlinImages[index];
            case ExerciseType.SUDOKU:
                return sudokinImages[index];
            case ExerciseType.MEMORY:
                return quizinImages[index];
            case ExerciseType.ORDER:
                return reorderImages[index];
            case ExerciseType.PONG:
                return ponginLvlChoise;
            default:
                return '';
        }
    };

    const getSpeedImg = (index: number): string => {
        return speedImages[index];
    };

    const body = (
        <Box sx={style}>
            {loading && (
                <CircularProgress
                    style={{
                        position: 'absolute',
                        width: '40px',
                        height: '40px',
                        // marginRight: '19%',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: '#FF0089',
                        zIndex: 1000
                    }}
                />
            )}
            <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: '8vh',
                        width: '100%',
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        boxShadow: '0px 4px 44px rgba(0, 0, 0, 0.05)'
                    }}>
                    <LittleButton type="back" func={(): void => closeModal()} />
                    <img src={logo} alt="logo" height="27px" />
                    <LittleButton type="close" func={(): void => callback()} />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: '92vh',
                        width: '100%'
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'end',
                            marginBottom: '25px',
                            // height: '15vh',
                            width: '100%'
                        }}>
                        <Typography
                            sx={{
                                fontSize: '2.3rem !important',
                                fontWeight: '400',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                marginTop: '16px',
                                fontFamily: 'Luciole-Regular'
                            }}
                            id="modal-modal-title"
                            variant="h1">
                            {exerciseType === ExerciseType.MEMORIN && `${t('memorin')} `}
                            {exerciseType === ExerciseType.MEMORIN && (
                                <img src={MemorinSvgIcon} alt="MemorinSvgIcon" height="30px" />
                            )}

                            {exerciseType === ExerciseType.PUZZLE && `${t('puzzleTitle')} `}
                            {exerciseType === ExerciseType.PUZZLE && (
                                <ExtensionIcon
                                    style={{
                                        fontSize: '34px',
                                        color: '#FF0189'
                                    }}
                                />
                            )}

                            {exerciseType === ExerciseType.ORDER && `${t('reorder')} `}
                            {exerciseType === ExerciseType.ORDER && (
                                <img src={tocSvgIcon} alt="tocSvgIcon" height="30px" />
                            )}

                            {exerciseType === ExerciseType.SUDOKU && `${t('Sudokin')} `}
                            {exerciseType === ExerciseType.SUDOKU && (
                                <img src={sudokuSvgIcon} alt="sudokuSvgIcon" height="30px" />
                            )}

                            {exerciseType === ExerciseType.MEMORY && `${t("souven'in")} `}
                            {exerciseType === ExerciseType.MEMORY && (
                                <img src={brainSvgIcon} alt="brainSvgIcon" height="30px" />
                            )}

                            {exerciseType === ExerciseType.PONG && `${t('Pongin')} `}
                            {exerciseType === ExerciseType.PONG && (
                                <img src={pongSvgIcon} alt="pongSvgIcon" height="30px" />
                            )}

                            {` - ${t('chooseLevel')}`}
                        </Typography>
                    </Box>
                    <Grid
                        container
                        spacing={4}
                        sx={{ width: '80%', textAlign: 'center', justifyContent: 'center' }}>
                        {lvlArray.map((lvl, index) => (
                            <Grid
                                item
                                xs={4}
                                onClick={(): Promise<void> => clickOnDifficulty(index + 1)}
                                sx={{
                                    marginLeft: '0px !important',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    paddingLeft: '0px !important'
                                }}>
                                <Box
                                    sx={{
                                        width: '226px',
                                        height: '254px',
                                        left: '0px',
                                        top: '0px',
                                        background: '#FFFFFF',
                                        border: '1px solid #E3E6E8',
                                        boxShadow: '0px 16px 32px -8px rgba(0, 0, 0, 0.16)',
                                        borderRadius: '12px',
                                        cursor: 'pointer'
                                    }}>
                                    <Box
                                        sx={{
                                            height: '80%',
                                            width: '100%',
                                            background:
                                                'linear-gradient(0deg, #EDF4FA, #EDF4FA), #EDF4FA',
                                            borderRadius: '11px 11px 0px 0px',
                                            position: 'relative'
                                        }}>
                                        {(exerciseType === ExerciseType.MEMORIN ||
                                            exerciseType === ExerciseType.PUZZLE ||
                                            exerciseType === ExerciseType.PONG) && (
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    height: '25px',
                                                    width:
                                                        lvl >= 100 ||
                                                        exerciseType === ExerciseType.PONG
                                                            ? '90px'
                                                            : '80px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    background:
                                                        exerciseType === ExerciseType.PONG
                                                            ? '#FFFFFF'
                                                            : '#FF0089',
                                                    borderRadius: '8px',
                                                    top: '8px',
                                                    left: '8px',
                                                    color:
                                                        exerciseType === ExerciseType.PONG
                                                            ? '#002F55'
                                                            : '#FFFFFF',
                                                    fontSize: '14px',
                                                    fontWeight: '400',
                                                    fontFamily: 'Luciole-Regular',
                                                    zIndex: 200
                                                }}>
                                                {/* {exerciseType === ExerciseType.MEMORY &&
                                                    t('choise', { number: lvl })} */}
                                                {exerciseType === ExerciseType.MEMORIN &&
                                                    t('card', { number: lvl })}
                                                {exerciseType === ExerciseType.PUZZLE &&
                                                    t('pieces', { number: lvl })}
                                                {exerciseType === ExerciseType.PONG && t('speed')}
                                                {exerciseType === ExerciseType.PONG && (
                                                    <img
                                                        height="14px"
                                                        style={{ marginLeft: '5px' }}
                                                        src={getSpeedImg(index)}
                                                        alt={`speedImg${index}`}
                                                    />
                                                )}
                                                {/* {exerciseType === ExerciseType.SUDOKU &&
                                                    t('holes', { number: lvl })} */}
                                            </Box>
                                        )}
                                        <img
                                            src={getImage(index)}
                                            alt={`lvl ${index + 1}`}
                                            height="80%"
                                            style={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                zIndex: 100
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            height: '20%',
                                            width: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            fontFamily: 'Luciole-Regular',
                                            fontSize: '16px',
                                            fontWeight: '400',
                                            color: '#0F0046'
                                        }}>
                                        {t('lvl', { lvl: index + 1 })}
                                    </Box>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingTop: '25px',
                            paddingBottom: '25px'
                        }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '16px',
                                cursor: 'pointer',
                                width: exerciseType === ExerciseType.PUZZLE ? '483px' : '377px',
                                height: '54px',
                                background: '#EDF4FA',
                                border: '1px solid #3B86C3',
                                borderRadius: '16px',
                                fontFamily: 'Luciole-Regular',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '17px',
                                textAlign: 'center',
                                color: '#3B86C3'
                            }}>
                            <img
                                src={infoBlue}
                                alt="info"
                                height="20px"
                                style={{ marginRight: '4px' }}
                            />
                            {exerciseType === ExerciseType.PUZZLE && t('chooseLevelInfoPuzzle')}
                            {exerciseType !== ExerciseType.PUZZLE && t('chooseLevelInfo')}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );

    return (
        <div>
            <Modal
                sx={{ minHeight: '100vh', marginTop: 'auto', marginBottom: 'auto' }}
                open={isModalOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                {body}
            </Modal>
        </div>
    );
}
