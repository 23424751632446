import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { Patient } from '../models/Patient';

interface CheckboxProps {
    isChecked: boolean;
    selectItem: Function;
    item?: Patient;
    classReceived?: string;
}

export function CustomCheckbox({
    isChecked,
    selectItem,
    item,
    classReceived
}: CheckboxProps): JSX.Element {
    return (
        <Checkbox
            checked={isChecked}
            icon={<CircleOutlinedIcon sx={{ color: '#ACBCC7', fontSize: '20px' }} />}
            checkedIcon={<CheckCircleIcon sx={{ color: '#89CC33 !important' }} />}
            onChange={(): void => selectItem(item)}
            className={classReceived}
            sx={{
                transform: 'scale(2.5)',
                borderRadius: '50%',
                marginTop: '25%',
                padding: '0px !important',
                color: '#89CC33'
            }}
        />
    );
}
