import { Grid, Typography, Link } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import InfoIcon from '@mui/icons-material/Info';
import { Roles } from '../../models/Roles';
import createExcerciseIcon from '../../assets/therapistCreateIcon.png';
import mediaIcon from '../../assets/mediaIcon.png';
import editExcerciseIcon from '../../assets/therapistStimutateIcon.png';
import patientIcon from '../../assets/patientIcon.png';
import { Header } from '../../components/Header';
import { UserContext } from '../../providers/UserProvider';
import { UserDataProp } from '../../models/userDataProp';
import { CardHome } from '../../components/CardHome';

const useStyles = makeStyles(() => ({
    body: {
        padding: '48px 20px 32px'
    },
    SalutationBackground: {
        fontSize: '31px',
        fontWeight: 600,
        lineHeight: '36px',
        display: 'inline-block',
        background: '#E3E6E8',
        padding: '5px',
        borderRadius: '6px',
        marginBottom: '20px'
    },
    Question: {
        letterSpacing: '-0.02em',
        '@media (max-width: 375px)': {
            fontSize: '25px'
        }
    },
    needHelp: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '10px'
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        background: '#FBFBFB',
        borderRadius: '24px',
        paddingTop: '24px'
    }
}));

export function Home(): JSX.Element {
    const { t } = useTranslation();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const classes = useStyles();

    return (
        <div>
            <Header hasIconImage />
            <div className={classes.body}>
                <Typography
                    variant="h2"
                    color="secondary.light"
                    className={classes.SalutationBackground}>
                    {t('patientHello')}
                    {user?.firstName}
                    {t('exclamation')}
                </Typography>
                <Typography
                    variant="h3"
                    color="#374653"
                    lineHeight="34px"
                    className={classes.Question}>
                    {t('whatDoYouWantToDo')}
                </Typography>
                <div className={classes.root}>
                    <Grid container spacing={1} sx={{ justifyContent: 'center' }}>
                        <Grid item>
                            <CardHome
                                key={1}
                                iconImagePath={mediaIcon}
                                cardTitle={t('addMedia')}
                                variant="quaternary"
                                buttonLabel={t('feed')}
                                buttonAction="/mediatheque"
                                infoText={t('mediathequeInfo')}
                                infoTitle={t('mediaContent')}
                            />
                        </Grid>
                        <Grid item>
                            <CardHome
                                key={2}
                                iconImagePath={createExcerciseIcon}
                                cardTitle={t('createExercise')}
                                variant="primary"
                                buttonLabel={t('personalize')}
                                buttonAction="/exercise"
                                infoText={t('createExerciseInfo')}
                                infoTitle={t('createExerciseTitle')}
                            />
                        </Grid>
                        <Grid item>
                            <CardHome
                                key={3}
                                iconImagePath={editExcerciseIcon}
                                cardTitle={t('editExercise')}
                                variant="secondary"
                                buttonLabel={t('toAccess')}
                                buttonAction="/edit"
                                infoText={t('stimulateExerciseInfo')}
                                infoTitle={t('stimulateExerciseTitle')}
                            />
                        </Grid>
                        {user?.role === Roles.THERAPIST && (
                            <Grid item>
                                <CardHome
                                    key={4}
                                    iconImagePath={patientIcon}
                                    cardTitle={t('patientLibrary')}
                                    variant="tertiary"
                                    buttonLabel={t('toFollow')}
                                    buttonAction="/view-patient"
                                    infoText={t('patientLibraryInfo')}
                                    infoTitle={t('patientLibraryTitle')}
                                />
                            </Grid>
                        )}
                    </Grid>
                </div>
                <Link
                    href="https://app.stimulin.fr/userGuide.pdf"
                    color="secondary"
                    target="_blank"
                    sx={{ textDecoration: 'none' }}>
                    <Typography
                        variant="h5"
                        color="#5C6670"
                        lineHeight="10px"
                        className={classes.needHelp}>
                        <span style={{ paddingRight: '4px' }}>{t('needSomeHelp')}</span>
                        <span>
                            <InfoIcon sx={{ fontSize: '2.2rem' }} />
                        </span>
                    </Typography>
                </Link>
            </div>
        </div>
    );
}
