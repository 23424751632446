import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuItem from '@mui/material/MenuItem';
import styled from 'styled-components';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';

const useStyles = makeStyles(() => ({
    itemContainer: {
        backgroundColor: 'rgba(51, 135, 204, 0.1)',
        color: 'rgba(51, 135, 204, 1)'
    },
    textColor: {
        color: '#84919E'
    }
}));

const CustomAppBar = styled(AppBar)(() => ({
    backgroundColor: 'white',
    marginBottom: '3%',
    boxShadow: 'inset 0px -1px 0px #E3E6E8'
}));

interface ToolbarProps {
    items: string[];
    onSelectChange: Function;
}

export function CustomToolbar({ items, onSelectChange }: ToolbarProps): JSX.Element {
    const classes = useStyles();
    const [selectedItem, setSelectedItem] = useState<string>('Tous');

    const handleChange = (value: string): void => {
        setSelectedItem(value);
        onSelectChange(value);
    };

    const renderItems = (): JSX.Element[] => {
        return items.map((item) => {
            return (
                <span
                    key={item}
                    className={item === selectedItem ? classes.itemContainer : classes.textColor}>
                    <MenuItem onClick={(): void => handleChange(item)}>{item}</MenuItem>
                </span>
            );
        });
    };

    return (
        <CustomAppBar position="relative">
            <Toolbar sx={{ overflowX: 'auto', display: 'flex', justifyContent: 'space-between' }}>
                {renderItems()}
            </Toolbar>
        </CustomAppBar>
    );
}
