/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { createStyles, makeStyles, styled } from '@mui/styles';
import { blueGrey } from '@mui/material/colors';
import DialogTitle from '@mui/material/DialogTitle';
import Avatar from '@mui/material/Avatar';
import { useLongPress } from 'use-long-press';
import Card from '@mui/material/Card';
import { Container, CardMedia, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import '../../i18n/config';
import wantToPause from '../../assets/wantToPause.png';
import wantAnotherExercise from '../../assets/wantAnotherExercise.png';
import giftLogo from '../../assets/giftLogo.png';
import badReview from '../../assets/badReview .png';
import averageReview from '../../assets/averageReview.png';
import goodReview from '../../assets/goodReview.png';
import heart from '../../assets/heart.png';
import { ModalContainer } from '../../components/ModalContainer';
import { ModalContainerFullScreen } from '../../components/ModalContainerFullScreen';
import { Roles } from '../../models/Roles';
import { UserContext } from '../../providers/UserProvider';
import { Exercise } from '../../models/Exercise';
import { Review } from '../../models/Review';
import {
    getFamilyUser,
    getPatientUser,
    getTherapistUser,
    saveExerciseReview,
    updateExercisesAchieved,
    resetCompletedExercises,
    decreaseDifficultyLevel
} from '../../services/cloudFirestore';
import { Therapist } from '../../models/Therapist';
import {
    capitalizeFirstLetter,
    decreasePuzzlin,
    decreaseSouvenin,
    getImageKitUrlFrom
} from '../../utilities/utils';
import { ProfileImage } from '../../components/ProfileImage';
import { ExerciseItem } from '../../models/ExerciseItem';
import { sendThankYouMail } from '../../services/storageFunctions';
import { UserDataProp } from '../../models/userDataProp';
import { Patient } from '../../models/Patient';
import { Family } from '../../models/Family';
import { ExerciseType } from '../../models/ExerciseType';
import { formatVideoUrl } from '../../services/formatFunctions';
import { VideoPlayer } from '../../components/VideoPlayer';

const useStyles = makeStyles(() =>
    createStyles({
        avatarProfile: {
            height: '120px',
            width: '120px',
            margin: 'auto',
            marginTop: '0px',
            marginBottom: '0px'
        },
        avatarImage: {
            height: '250px',
            width: '250px'
        },
        btnLayout: {
            width: '100%',
            marginTop: '5%',
            marginLeft: '0px',
            '@media (max-width: 598px)': {
                cursor: 'pointer',
                width: '40%',
                margin: '16px 5px 30px 5px'
            }
        },
        input: {
            color: '#002F55'
        },
        singleBtnLayout: {
            width: '100%',
            marginTop: '5%',
            marginLeft: '0px',
            '@media (max-width: 598px)': {
                cursor: 'pointer',
                width: '80%',
                margin: '16px 10px'
            }
        },
        startExerciseBtn: {
            width: '100%',
            marginTop: '5%',
            marginLeft: '0px',
            '@media (max-width: 598px)': {
                cursor: 'pointer',
                width: '45%',
                margin: '16px 5px 30px 5px'
            }
        },
        anotherExerciseBtnLayout: {
            width: '100%',
            marginTop: '5%',
            marginLeft: '0px',
            '@media (max-width: 598px)': {
                cursor: 'pointer',
                width: '60%',
                margin: '16px 10px'
            }
        },
        selectLayout: {
            width: '100%',
            marginTop: '0px',
            color: 'rgba(0, 47, 85, 1)'
        },
        selectPlaceholder: {
            width: '100%',
            marginTop: '0px',
            color: 'rgba(172, 188, 199, 1)'
        },
        fontBlue: {
            '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
                color: 'rgba(0, 47, 85, 1)'
            }
        },
        heartbeatAnimation: {
            animation: `$heartbeatAnimation 6s ease-in-out 1s 2`
        },
        '@keyframes heartbeatAnimation': {
            '0%': {
                transform: 'scale(1)'
            },
            '25%': {
                transform: 'scale(0.7)'
            },
            '50%': {
                transform: 'scale(1)'
            },
            '75%': {
                transform: 'scale(0.7)'
            },
            '100%': {
                transform: 'scale(1)'
            }
        },
        videoModalHeading: {
            '@media (max-width: 1024px)': {
                width: '100%'
            },
            '@media (min-width: 1024px)': {
                width: '100%'
            }
        },
        headingLayout: {
            '@media (min-width: 1024px)': {
                width: '70% !important'
            }
        },
        therapistHeadingLayout: {
            '@media (min-width: 1025px)': {
                marginLeft: '15%'
            }
        },
        infoLayout: {
            '@media (min-width: 1024px)': {
                margin: '0px !important',
                marginRight: '15% !important'
            }
        },
        groupBtnContainer: {
            display: 'flex',
            width: '90%',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            marginTop: '2%'
        },
        profileImage: {
            marginRight: '10px',
            alignSelf: 'center',
            justifySelf: 'center',
            display: 'inline-block',
            width: '100%',
            padding: '45%'
        },
        currentUserInitials: {
            display: 'flex',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            margin: '0'
        }
    })
);

const DefaultLogo = styled('div')({
    borderRadius: '50%',
    background: '#efe7d7',
    position: 'relative'
});

interface ReviewExerciseAlertsProps {
    exercise: Exercise | undefined;
    exerciseType: ExerciseType;
    difficultyLevel: number;
    exerciseItemList?: ExerciseItem[] | undefined;
    nbTurn?: number;
    nbFind?: number;
    sudokuErrors?: number;
    sudokuClues?: number;
    pongPlayerScore?: number;
    pongCpuScore?: number;
    reorderError?: number;
    reorderClue?: number;
}

export function ReviewExerciseAlerts({
    exercise,
    exerciseType,
    difficultyLevel,
    exerciseItemList,
    nbTurn,
    nbFind,
    sudokuErrors,
    sudokuClues,
    pongPlayerScore,
    pongCpuScore,
    reorderError,
    reorderClue
}: ReviewExerciseAlertsProps): JSX.Element {
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [userInitials, setUserInitials] = useState<string>('');
    const [authorInitials, setAuthorInitials] = useState<string>('');
    const [openVideoAfterExercise, setOpenVideoAfterExercise] = useState(false);
    const [openPhotoAfterExercise, setOpenPhotoAfterExercise] = useState(false);
    const [openVideoPhotoAfterExercise, setOpenVideoPhotoAfterExercise] = useState(false);
    const [openReviewModal, setOpenReview] = useState(false);
    const [openThankModal, setOpenThankModal] = useState(false);
    const [openAnotherExercice, setOpenAnotherExerciceModal] = useState(false);
    const [isPhoto, setIsPhoto] = useState(true);
    const [patientData, setPatientData] = useState<Patient | null>();
    const [congratulationMessage, setCongratulationMessage] = useState<string>('');
    const [initials, setInitials] = useState<string>('');
    const [hiddenTitle, setHiddenTitle] = useState<boolean>(false);
    const [hiddenTitleYt, setHiddenTitleYt] = useState<boolean>(false);
    const [numberOfPlaing, setNumberOfPlaing] = useState<number>();
    const [author, setAuthor] = useState<Therapist>({
        firstName: undefined,
        lastName: undefined,
        emailId: undefined,
        dob: undefined,
        fonction: undefined,
        establishmentName: undefined,
        mobile: undefined,
        firstStepFormFields: [],
        secondStepFormFields: []
    });
    const [familyAuthor, setFamilyAuthor] = useState<Family>({
        firstName: undefined,
        lastName: undefined,
        emailId: undefined,
        dob: undefined,
        relationship: undefined,
        establishmentName: undefined,
        firstStepFormFields: [],
        secondStepFormFields: []
    });

    useEffect(() => {
        if (numberOfPlaing !== undefined) {
            if (numberOfPlaing === 0 || numberOfPlaing % 5 === 0) {
                setOpenReview(true);
            }
            if (numberOfPlaing === 0 || (numberOfPlaing - 1) % 5 === 0) {
                if (exercise?.authorType === Roles.ADMIN) {
                    setOpenAnotherExerciceModal(true);
                } else {
                    setOpenVideoPhotoAfterExercise(true);
                }
            }
            if (
                numberOfPlaing !== 0 &&
                numberOfPlaing % 5 !== 0 &&
                (numberOfPlaing - 1) % 5 !== 0
            ) {
                setOpenAnotherExerciceModal(true);
                // pour remetre la popup <3
                // if (exercise?.authorType === Roles.ADMIN) {
                //     setOpenAnotherExerciceModal(true);
                // } else {
                //     setOpenAnotherExerciceModal(true);
                //     // setOpenThankModal(true);
                // }
            }
        }
    }, [numberOfPlaing]);

    const getPatientData = async (id: string): Promise<void> => {
        const data = await getPatientUser(id);
        if (data) {
            setPatientData(data);
            if (data.statistics) {
                const tempStatistics: { [key: string]: number } = {};
                let tempNumberOfPlaing = 0;
                // eslint-disable-next-line array-callback-return
                data.statistics.map((stat): void => {
                    if (exercise && stat.exerciseId === exercise.id) {
                        tempNumberOfPlaing = stat.timeAndLevel.length;
                    }
                });
                setNumberOfPlaing(tempNumberOfPlaing);
            }
        }
    };

    useEffect(() => {
        if (author.firstName && author.lastName) {
            setInitials(
                author.firstName.charAt(0).toUpperCase() + author.lastName.charAt(0).toUpperCase()
            );
        }
        if (familyAuthor && familyAuthor.relationship) {
            if (familyAuthor.firstName && familyAuthor.lastName) {
                setInitials(
                    familyAuthor?.firstName.charAt(0).toUpperCase() +
                        familyAuthor?.lastName.charAt(0).toUpperCase()
                );
            }
        }
    }, [author, familyAuthor]);

    useEffect(() => {
        if (user && user?.id && user.role === Roles.PATIENT) {
            getPatientData(user.id);
        }
        if (user && user.firstName && user.lastName) {
            setUserInitials(
                user.firstName.charAt(0).toUpperCase() + user.lastName.charAt(0).toUpperCase()
            );
        }
    }, [user]);

    useEffect(() => {
        const getExerciseList = async (): Promise<void> => {
            if (
                exercise &&
                exercise.authorId &&
                (exercise.authorType === Roles.THERAPIST || exercise.authorType === Roles.ADMIN)
            ) {
                if (user && user?.id && user.role === Roles.PATIENT) {
                    const data = await getPatientUser(user.id);
                    if (data) {
                        setPatientData(data);
                    }
                    const authorData = await getTherapistUser(exercise.authorId);
                    if (authorData) {
                        setAuthor(authorData);
                        if (authorData.firstName && authorData.lastName) {
                            setAuthorInitials(
                                authorData.firstName.charAt(0).toUpperCase() +
                                    authorData.lastName.charAt(0).toUpperCase()
                            );
                        }
                    }
                    if (authorData) {
                        if (exercise && exercise.congratulationsMessage) {
                            setCongratulationMessage(exercise.congratulationsMessage);
                        }
                        setAuthor(authorData);
                    }
                } else if (user && user?.id && user.role === Roles.THERAPIST) {
                    const authorData = await getTherapistUser(exercise.authorId);
                    if (authorData) {
                        setAuthor(authorData);
                        if (authorData.firstName && authorData.lastName) {
                            setAuthorInitials(
                                authorData.firstName.charAt(0).toUpperCase() +
                                    authorData.lastName.charAt(0).toUpperCase()
                            );
                        }
                    }
                    if (authorData) {
                        if (exercise && exercise.congratulationsMessage) {
                            setCongratulationMessage(exercise.congratulationsMessage);
                        }
                        setAuthor(authorData);
                    }
                }
            } else if (exercise && exercise.authorId && exercise.authorType === Roles.FAMILY) {
                const authorData = await getFamilyUser(exercise.authorId);
                if (authorData) {
                    setFamilyAuthor(authorData);
                    if (exercise.congratulationsMessage) {
                        setCongratulationMessage(exercise.congratulationsMessage);
                    }
                    if (authorData.firstName && authorData.lastName) {
                        setAuthorInitials(
                            authorData.firstName.charAt(0).toUpperCase() +
                                authorData.lastName.charAt(0).toUpperCase()
                        );
                    }
                }
            }
        };
        getExerciseList();
        if (exercise && exercise.rewardPhoto) {
            if (exercise.rewardPhoto.toString().includes('.mp4')) {
                setIsPhoto(false);
            } else {
                setIsPhoto(true);
            }
        }
    }, [exercise]);

    useEffect(() => {
        if (user && user.id && exercise && user.role === Roles.PATIENT) {
            const startTimer = localStorage.getItem('startTime');
            const endTimer = new Date();
            if (startTimer && endTimer) {
                const timeTakenToCompleteExercise = Math.floor(
                    Math.abs(endTimer.getTime() - new Date(startTimer).getTime()) / 1000
                );
                updateExercisesAchieved(
                    user,
                    exercise,
                    timeTakenToCompleteExercise,
                    difficultyLevel,
                    exercise.exerciseType === ExerciseType.MEMORIN ? nbTurn : undefined,
                    exercise.exerciseType === ExerciseType.MEMORIN ? nbFind : undefined,
                    exercise.exerciseType === ExerciseType.SUDOKU ? sudokuErrors : undefined,
                    exercise.exerciseType === ExerciseType.SUDOKU ? sudokuClues : undefined,
                    exercise.exerciseType === ExerciseType.PONG ? pongPlayerScore : undefined,
                    exercise.exerciseType === ExerciseType.PONG ? pongCpuScore : undefined,
                    exercise.exerciseType === ExerciseType.ORDER ? reorderError : undefined,
                    exercise.exerciseType === ExerciseType.ORDER ? reorderClue : undefined
                );
            }
            if (exercise.exerciseType === ExerciseType.MEMORY) {
                resetCompletedExercises(user, exercise);
            }
        }
    }, []);

    const decreaseDifficulty = async (): Promise<void> => {
        if (exercise && exercise.exerciseType && user && user.id && user.role === Roles.PATIENT) {
            await decreaseDifficultyLevel(exercise?.exerciseType, user);
        }
    };

    useEffect(() => {
        if (user?.role === Roles.PATIENT) {
            const countErrorAndClue = localStorage.getItem('countErrorAndClue');
            let decrease = false;
            if (difficultyLevel > 1) {
                if (exercise?.exerciseType === ExerciseType.MEMORY) {
                    decrease = decreaseSouvenin(Number(countErrorAndClue), difficultyLevel);
                } else if (exercise?.exerciseType === ExerciseType.MEMORIN) {
                    // decrease = decreaseMemorin(Number(countErrorAndClue), difficultyLevel);
                } else {
                    decrease = decreasePuzzlin(Number(countErrorAndClue), difficultyLevel);
                }
            }
            if (decrease !== false) {
                decreaseDifficulty();
            }
        }
    }, []);

    const addExerciseReview = async (value: Review): Promise<void> => {
        if (exercise && user && user.id && user.role === Roles.PATIENT) {
            await saveExerciseReview(exercise, value, user.id, exerciseType);
        }
    };

    const handleTherapistCompletedExercise = (): void => {
        history.push('/edit');
    };

    const handleModalPhotoOrVideoOrThank = (): void => {
        if (exercise?.rewardPhoto) {
            setOpenPhotoAfterExercise(true);
        } else if (!exercise?.rewardPhoto && exercise?.rewardVideo) {
            setOpenVideoAfterExercise(true);
        } else if (numberOfPlaing === 0) {
            if (
                !exercise?.rewardPhoto &&
                !exercise?.rewardVideo &&
                exercise?.authorType === Roles.ADMIN
            ) {
                setOpenAnotherExerciceModal(true);
            } else {
                setOpenThankModal(true);
            }
        } else {
            history.push('/home');
        }
    };

    const handleNextModalAfterReview = (): void => {
        if (numberOfPlaing === 0) {
            if (user?.role === Roles.THERAPIST || user?.role === Roles.ADMIN) {
                if (exercise?.rewardVideo) {
                    setOpenVideoAfterExercise(true);
                } else {
                    history.push('/edit');
                }
            } else if (user?.role === Roles.PATIENT) {
                if (author.role === Roles.ADMIN) {
                    handleModalPhotoOrVideoOrThank();
                } else {
                    setOpenVideoPhotoAfterExercise(true);
                }
            }
        } else {
            history.push('/home');
        }
    };

    const handleCloseVideoPhoto = (): void => setOpenVideoPhotoAfterExercise(false);

    const isHeightOfXS = (): boolean => {
        const { innerWidth: width, innerHeight: height } = window;
        if (width < 550 || height < 550) {
            return true;
        }
        if (height <= 768 && height > 551) {
            return true;
        }
        return false;
    };

    const getWidth = (): number => {
        const { innerWidth: width, innerHeight: height } = window;
        if (width < 550 || height < 550) {
            return 80;
        }
        return 158;
    };

    // const sendThanksEmail = async (): Promise<void> => {
    //     if (exercise?.authorType !== Roles.FAMILY && author.emailId && author.firstName) {
    //         await sendThankYouMail(author.emailId, author.firstName);
    //     } else if (
    //         exercise?.authorType === Roles.FAMILY &&
    //         familyAuthor.emailId &&
    //         familyAuthor.firstName
    //     ) {
    //         await sendThankYouMail(familyAuthor.emailId, familyAuthor.firstName);
    //     }
    // };

    const bindLongPressDisliked = useLongPress(
        () => {
            addExerciseReview(Review.DISLIKED);
            setOpenReview(false);
            handleNextModalAfterReview();
        },
        { threshold: 1000 }
    );

    const bindLongPressSatisfactory = useLongPress(
        () => {
            addExerciseReview(Review.SATISFACTORY);
            setOpenReview(false);
            handleNextModalAfterReview();
        },
        { threshold: 1000 }
    );

    const bindLongPressLoved = useLongPress(
        () => {
            addExerciseReview(Review.LOVED);
            setOpenReview(false);
            handleNextModalAfterReview();
        },
        { threshold: 1000 }
    );

    const exerciseReview = (
        <div
            style={{
                minHeight: '75vh',
                maxHeight: '90vh',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly'
            }}>
            <Typography
                variant="h3"
                color={blueGrey[800]}
                sx={{
                    fontWeight: 600,
                    textAlign: 'center',
                    fontFamily: user?.role === Roles.PATIENT ? 'Luciole-Regular' : ''
                }}>
                {t('likeExercise?')}
            </Typography>
            <Typography
                variant="h3"
                color={blueGrey[800]}
                sx={{
                    fontWeight: 500,
                    textAlign: 'center',
                    marginTop: '2%',
                    fontFamily: user?.role === Roles.PATIENT ? 'Luciole-Regular' : ''
                }}>
                {t('clickOnReviewOption')}
            </Typography>
            <Container
                sx={{
                    color: '#004680',
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                <Card
                    sx={{
                        width: '30%',
                        boxShadow: 'none',
                        cursor: 'pointer'
                    }}>
                    <CardMedia
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...bindLongPressDisliked()}
                        onClick={(): void => {
                            addExerciseReview(Review.DISLIKED);
                            setOpenReview(false);
                            handleNextModalAfterReview();
                        }}
                        sx={{
                            backgroundColor: 'rgba(200, 65, 65, 0.1)',
                            width: '100%',
                            borderRadius: '50% !important'
                        }}>
                        <Avatar
                            src={badReview}
                            sx={{
                                width: '95%',
                                padding: '28%',
                                height: 'auto',
                                margin: 'auto',
                                borderRadius: '50%'
                            }}
                        />
                    </CardMedia>
                    <Typography
                        variant="h4"
                        sx={{
                            color: '#c84141',
                            fontWeight: 600,
                            fontFamily: user?.role === Roles.PATIENT ? 'Luciole-Regular' : '',
                            marginTop: '5%'
                        }}>
                        {t('iDidNotLike')}
                    </Typography>
                </Card>
                <Card
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...bindLongPressSatisfactory()}
                    onClick={(): void => {
                        addExerciseReview(Review.SATISFACTORY);
                        setOpenReview(false);
                        handleNextModalAfterReview();
                    }}
                    sx={{
                        width: '30%',
                        boxShadow: 'none',
                        cursor: 'pointer'
                    }}>
                    <CardMedia
                        sx={{
                            backgroundColor: 'rgba(231, 166, 0, 0.1)',
                            width: '100%',
                            borderRadius: '50% !important'
                        }}>
                        <Avatar
                            src={averageReview}
                            sx={{
                                width: '95%',
                                padding: '28%',
                                height: 'auto',
                                margin: 'auto',
                                borderRadius: '50%'
                            }}
                        />
                    </CardMedia>
                    <Typography
                        variant="h4"
                        sx={{
                            color: '#e7a600',
                            fontWeight: 600,
                            fontFamily: user?.role === Roles.PATIENT ? 'Luciole-Regular' : '',
                            marginTop: '5%'
                        }}>
                        {t('iHaveNoOpinion')}
                    </Typography>
                </Card>
                <Card
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...bindLongPressLoved()}
                    onClick={(): void => {
                        addExerciseReview(Review.LOVED);
                        setOpenReview(false);
                        handleNextModalAfterReview();
                    }}
                    sx={{
                        width: '30%',
                        boxShadow: 'none',
                        cursor: 'pointer'
                    }}>
                    <CardMedia
                        sx={{
                            backgroundColor: 'rgba(137, 204, 51, 0.1)',
                            width: '100%',
                            borderRadius: '50% !important'
                        }}>
                        <Avatar
                            src={goodReview}
                            sx={{
                                width: '95%',
                                padding: '28%',
                                height: 'auto',
                                margin: 'auto',
                                borderRadius: '50%'
                            }}
                        />
                    </CardMedia>
                    <Typography
                        variant="h4"
                        sx={{
                            color: '#89cc33',
                            fontWeight: 600,
                            fontFamily: user?.role === Roles.PATIENT ? 'Luciole-Regular' : '',
                            marginTop: '5%'
                        }}>
                        {t('iLoved')}
                    </Typography>
                </Card>
            </Container>
        </div>
    );

    const bindLongPressDiscover = useLongPress(
        () => {
            setOpenVideoPhotoAfterExercise(false);
            handleModalPhotoOrVideoOrThank();
        },
        { threshold: 1000 }
    );

    const videoAndPhotoAfterExercise = (
        <div
            style={{
                minHeight: '75vh',
                maxHeight: '90vh',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly'
            }}>
            {exercise?.authorType === Roles.FAMILY && !familyAuthor.profilePhoto && (
                <DefaultLogo className={classes.avatarProfile}>
                    <div style={{ height: '5vmin' }}>
                        <Typography
                            variant="h1"
                            sx={{ fontWeight: 'bold' }}
                            className={classes.currentUserInitials}>
                            {initials}
                        </Typography>
                    </div>
                </DefaultLogo>
            )}
            {exercise?.authorType === Roles.THERAPIST && !author.profilePhoto && (
                <DefaultLogo className={classes.avatarProfile}>
                    <div style={{ height: '5vmin' }}>
                        <Typography
                            variant="h1"
                            sx={{ fontWeight: 'bold' }}
                            className={classes.currentUserInitials}>
                            {initials}
                        </Typography>
                    </div>
                </DefaultLogo>
            )}
            {exercise?.authorType === Roles.FAMILY && familyAuthor.profilePhoto && (
                <ProfileImage
                    width={getWidth()}
                    height={getWidth()}
                    name={`${familyAuthor.firstName} ${familyAuthor.lastName}`}
                    url={familyAuthor.profilePhoto}
                />
            )}
            {exercise?.authorType === Roles.THERAPIST && author.profilePhoto && (
                <ProfileImage
                    width={getWidth()}
                    height={getWidth()}
                    name={`${author.firstName} ${author.lastName}`}
                    url={author.profilePhoto}
                />
            )}
            <Container sx={{ color: '#004680', textAlign: 'center', marginTop: '10px' }}>
                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: 'normal',
                        fontFamily: user?.role === Roles.PATIENT ? 'Luciole-Regular' : ''
                    }}>
                    {congratulationMessage}
                </Typography>
                <Button
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...bindLongPressDiscover()}
                    onClick={(): void => {
                        setOpenVideoPhotoAfterExercise(false);
                        handleModalPhotoOrVideoOrThank();
                    }}
                    variant="outlined"
                    size="large"
                    color="primary"
                    className={classes.startExerciseBtn}
                    sx={{
                        border: '1px solid #8b004b',
                        boxShadow: '0px 0px 0px 2px rgba(208, 0, 112, 0.4)',
                        fontSize: '24px',
                        lineHeight: '29px',
                        fontWeight: '600',
                        width: '400px !important',
                        marginBottom: isHeightOfXS() ? '0px' : '20px',
                        marginTop: isHeightOfXS() ? '2% !important' : '5%',
                        fontFamily: user?.role === Roles.PATIENT ? 'Luciole-Regular' : ''
                    }}>
                    {t('discover')}
                </Button>
            </Container>
        </div>
    );

    const bindLongPressDiscoverAnother = useLongPress(
        () => {
            setOpenPhotoAfterExercise(false);
            if (exercise?.rewardVideo) {
                setOpenVideoAfterExercise(true);
            } else if (numberOfPlaing === 0) {
                if (!exercise?.rewardVideo && exercise?.authorType === Roles.ADMIN) {
                    setOpenAnotherExerciceModal(true);
                } else {
                    setOpenThankModal(true);
                }
            } else {
                history.push('/home');
            }
        },
        { threshold: 1000 }
    );

    const photoAfterExercise = (
        <div
            style={{
                minHeight: '95vh',
                maxHeight: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}>
            <div
                style={{
                    display: hiddenTitle ? 'none' : 'flex',
                    justifyContent: 'center'
                }}>
                <Typography
                    variant="h3"
                    color={blueGrey[800]}
                    sx={{
                        textAlign: 'center',
                        // marginTop: '2%',
                        paddingLeft: '2%',
                        width: '80%',
                        '@media (max-width: 780px)': {
                            width: '100%'
                        },
                        fontWeight: 'bold',
                        justifyContent: 'space-around',
                        fontFamily: user?.role === Roles.PATIENT ? 'Luciole-Regular' : ''
                    }}>
                    {exercise?.authorType === Roles.FAMILY
                        ? t('clickOnPlayFami')
                        : t('clickOnPlayThera')}
                </Typography>
            </div>
            <Container
                sx={{
                    color: '#004680',
                    textAlign: 'center',
                    width: '100%',
                    margin: 'auto',
                    height: '100%',
                    overflow: 'hidden',
                    display: 'flex'
                }}>
                {exercise?.rewardPhoto && (
                    <Box
                        sx={{
                            width: '100%',
                            height: hiddenTitle ? 'calc(100vh - 130px)' : 'calc(100vh - 230px)',
                            maxHeight: 'inherit',
                            maxWidth: 'inherit',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontFamily: user?.role === Roles.PATIENT ? 'Luciole-Regular' : ''
                        }}>
                        <VideoPlayer
                            videoSrc={formatVideoUrl(exercise.rewardPhoto.toString())}
                            timeAutoPlay={2000}
                            setHiddenTitle={setHiddenTitle}
                        />
                    </Box>
                )}
            </Container>
            <Button
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...bindLongPressDiscoverAnother()}
                onClick={(): void => {
                    setOpenPhotoAfterExercise(false);
                    if (exercise?.rewardVideo) {
                        setOpenVideoAfterExercise(true);
                    } else if (numberOfPlaing === 0) {
                        if (!exercise?.rewardVideo && exercise?.authorType === Roles.ADMIN) {
                            setOpenAnotherExerciceModal(true);
                        } else {
                            setOpenThankModal(true);
                        }
                    } else {
                        history.push('/home');
                    }
                }}
                variant="outlined"
                size="large"
                color="primary"
                sx={{
                    border: '1px solid #8b004b',
                    boxShadow: '0px 0px 0px 2px rgba(208, 0, 112, 0.4)',
                    fontSize: '24px',
                    lineHeight: '29px',
                    fontFamily: user?.role === Roles.PATIENT ? 'Luciole-Regular' : '',
                    width: '400px !important',
                    marginTop: '0% !important',
                    marginLeft: 'auto !important',
                    marginRight: 'auto',
                    marginBottom: '10px',
                    paddingLeft: '0',
                    paddingRight: '0'
                }}
                className={classes.singleBtnLayout}>
                {t('disoverAnotherAttention')}
            </Button>
        </div>
    );

    const bindLongPressThank = useLongPress(
        () => {
            setOpenVideoAfterExercise(false);
            if (numberOfPlaing === 0) {
                if (user?.role === Roles.THERAPIST) {
                    handleTherapistCompletedExercise();
                } else if (exercise?.authorType === Roles.ADMIN) {
                    setOpenAnotherExerciceModal(true);
                } else {
                    setOpenThankModal(true);
                }
            } else {
                history.push('/home');
            }
        },
        { threshold: 1000 }
    );

    const videoAfterExerciseModal = (
        <div
            style={{
                minHeight: '95vh',
                maxHeight: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly'
            }}>
            <div style={{ display: 'flex' }}>
                <Box
                    sx={{ display: hiddenTitleYt ? 'none' : 'flex', flexDirection: 'column' }}
                    className={classes.videoModalHeading}>
                    <Typography
                        variant="h3"
                        color={blueGrey[800]}
                        sx={{
                            width: user?.role === Roles.PATIENT ? '90%' : 'auto',
                            margin: 'auto',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            justifyContent: 'space-around',
                            '@media (max-width: 780px)': {
                                width: '100%'
                            },
                            // marginTop: '2%',
                            paddingLeft: '2%',
                            fontFamily: user?.role === Roles.PATIENT ? 'Luciole-Regular' : ''
                        }}
                        className={
                            user?.role === Roles.PATIENT
                                ? classes.headingLayout
                                : classes.therapistHeadingLayout
                        }>
                        {exercise?.authorType === Roles.FAMILY
                            ? t('clickOnPlayFami')
                            : t('clickOnPlayThera')}
                    </Typography>
                </Box>
            </div>
            <Container
                sx={{
                    color: '#004680',
                    textAlign: 'center',
                    width: '100%',
                    margin: 'auto',
                    height: '100%',
                    overflow: 'hidden',
                    display: 'flex'
                }}>
                {exercise?.rewardVideo && (
                    <Box
                        sx={{
                            width: '100%',
                            height: hiddenTitleYt ? 'calc(100vh - 130px)' : 'calc(100vh - 230px)',
                            maxHeight: 'inherit',
                            maxWidth: 'inherit',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontFamily: user?.role === Roles.PATIENT ? 'Luciole-Regular' : ''
                        }}>
                        <VideoPlayer
                            videoSrc={formatVideoUrl(exercise.rewardVideo)}
                            timeAutoPlay={2000}
                            setHiddenTitle={setHiddenTitleYt}
                        />
                    </Box>
                )}
            </Container>
            <Button
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...bindLongPressThank()}
                onClick={(): void => {
                    setOpenVideoAfterExercise(false);
                    if (numberOfPlaing === 0) {
                        if (user?.role === Roles.THERAPIST) {
                            handleTherapistCompletedExercise();
                        } else if (exercise?.authorType === Roles.ADMIN) {
                            setOpenAnotherExerciceModal(true);
                        } else {
                            setOpenThankModal(true);
                        }
                    } else {
                        history.push('/home');
                    }
                }}
                variant="outlined"
                size="large"
                color="primary"
                sx={{
                    border: '1px solid #8b004b',
                    boxShadow: '0px 0px 0px 2px rgba(208, 0, 112, 0.4)',
                    fontSize: '24px',
                    lineHeight: '29px',
                    fontFamily: user?.role === Roles.PATIENT ? 'Luciole-Regular' : '',
                    marginTop: '1% !important',
                    width: '400px !important',
                    marginLeft: 'auto !important',
                    marginRight: 'auto',
                    marginBottom: '10px'
                }}
                className={classes.anotherExerciseBtnLayout}>
                {t('toThank')}
            </Button>
        </div>
    );

    const bindLongPressCloseModal = useLongPress(
        () => {
            setOpenThankModal(false);
            setOpenAnotherExerciceModal(true);
        },
        { threshold: 1000 }
    );

    const thankAfterVideo = (
        <Box
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: '5%'
            }}>
            <Typography
                variant="h3"
                color={blueGrey[800]}
                sx={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    fontFamily: user?.role === Roles.PATIENT ? 'Luciole-Regular' : ''
                }}>
                {t('placeFingreOnHeart')}{' '}
                {exercise?.authorType === Roles.FAMILY && familyAuthor.firstName
                    ? capitalizeFirstLetter(familyAuthor.firstName)
                    : author.firstName
                    ? capitalizeFirstLetter(author.firstName)
                    : ''}{' '}
                {t('forAllAttentions')}
            </Typography>
            <Box
                display="flex"
                justifyContent="center"
                sx={{ color: '#004680', marginTop: '5%', alignItems: 'center' }}>
                <ProfileImage
                    width={250}
                    height={250}
                    name={`${user!.firstName} ${user!.lastName}`}
                    url={user!.profilePhoto}
                />
                <Avatar
                    className={classes.heartbeatAnimation}
                    sx={{
                        display: 'inline-block',
                        width: '40%',
                        height: 'auto',
                        cursor: 'pointer'
                    }}
                    alt="Heart Img"
                    src={heart}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...bindLongPressCloseModal()}
                    onClick={(): void => {
                        setOpenThankModal(false);
                        setOpenAnotherExerciceModal(true);
                        // sendThanksEmail();
                    }}
                />
                {exercise?.authorType === Roles.THERAPIST && !author.profilePhoto && (
                    <DefaultLogo className={classes.avatarImage}>
                        <div style={{ height: '5vmin' }}>
                            <Typography
                                variant="h1"
                                sx={{ fontWeight: 'bold' }}
                                className={classes.currentUserInitials}>
                                {initials}
                            </Typography>
                        </div>
                    </DefaultLogo>
                )}
                {exercise?.authorType === Roles.THERAPIST && author.profilePhoto && (
                    <ProfileImage
                        width={250}
                        height={250}
                        name={`${author.firstName} ${author.lastName}`}
                        url={author.profilePhoto}
                    />
                )}
                {exercise?.authorType === Roles.FAMILY && !familyAuthor.profilePhoto && (
                    <DefaultLogo className={classes.avatarImage}>
                        <div style={{ height: '5vmin' }}>
                            <Typography
                                variant="h1"
                                sx={{ fontWeight: 'bold' }}
                                className={classes.currentUserInitials}>
                                {initials}
                            </Typography>
                        </div>
                    </DefaultLogo>
                )}
                {exercise?.authorType === Roles.FAMILY && familyAuthor.profilePhoto && (
                    <ProfileImage
                        width={250}
                        height={250}
                        name={`${familyAuthor.firstName} ${familyAuthor.lastName}`}
                        url={familyAuthor.profilePhoto}
                    />
                )}
            </Box>
        </Box>
    );

    const bindLongPressTakeBrake = useLongPress(
        () => {
            setOpenAnotherExerciceModal(false);
            history.push('/home');
        },
        { threshold: 1000 }
    );

    const bindLongPressAnotherEx = useLongPress(
        () => {
            setOpenAnotherExerciceModal(false);
            history.push('/home');
        },
        { threshold: 1000 }
    );

    const anotherExercice = (
        <div
            style={{
                minHeight: '75vh',
                maxHeight: '90vh',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly'
            }}>
            <Typography
                variant="h3"
                fontSize="lg"
                color={blueGrey[800]}
                sx={{
                    textAlign: 'center',
                    fontFamily: user?.role === Roles.PATIENT ? 'Luciole-Regular' : ''
                }}>
                {t('whatDoYouWantToDoNow')}
            </Typography>
            <Typography
                variant="h4"
                fontSize="lg"
                color={blueGrey[800]}
                sx={{
                    textAlign: 'center',
                    fontFamily: user?.role === Roles.PATIENT ? 'Luciole-Regular' : ''
                }}>
                {t('placeYourFingerForChoice')}
            </Typography>
            <Container className={classes.groupBtnContainer}>
                <Card
                    sx={{
                        width: '30%',
                        boxShadow: 'none',
                        cursor: 'pointer'
                    }}>
                    <CardMedia
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...bindLongPressTakeBrake()}
                        onClick={(): void => {
                            setOpenAnotherExerciceModal(false);
                            history.push('/home');
                        }}
                        sx={{
                            backgroundColor: 'rgba(0, 70, 128, 0.07)',
                            width: '100%',
                            borderRadius: '50% !important'
                        }}>
                        <Avatar
                            src={wantToPause}
                            sx={{
                                width: '95%',
                                padding: '15%',
                                height: 'auto',
                                margin: 'auto'
                            }}
                        />
                    </CardMedia>
                    <Typography
                        variant="h4"
                        sx={{
                            color: '#004680',
                            fontWeight: 'bold',
                            fontFamily: user?.role === Roles.PATIENT ? 'Luciole-Regular' : '',
                            marginTop: '10%'
                        }}>
                        {t('wantToTakeABreak')}
                    </Typography>
                </Card>
                <Card
                    sx={{
                        width: '30%',
                        boxShadow: 'none',
                        cursor: 'pointer'
                    }}>
                    <CardMedia
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...bindLongPressAnotherEx()}
                        onClick={(): void => {
                            setOpenAnotherExerciceModal(false);
                            history.push('/home');
                        }}
                        sx={{
                            backgroundColor: 'rgba(208, 0, 112, 0.07)',
                            width: '100%',
                            borderRadius: '50% !important'
                        }}>
                        <Avatar
                            src={wantAnotherExercise}
                            sx={{
                                width: '95%',
                                padding: '15%',
                                height: 'auto',
                                margin: 'auto'
                            }}
                        />
                    </CardMedia>
                    <Typography
                        variant="h4"
                        sx={{
                            color: '#D00070',
                            fontWeight: 'bold',
                            fontFamily: user?.role === Roles.PATIENT ? 'Luciole-Regular' : '',
                            marginTop: '10%'
                        }}>
                        {t('wantToDoAnotherExercise')}
                    </Typography>
                </Card>
            </Container>
        </div>
    );

    return (
        <Container>
            <ModalContainer openModal={openReviewModal} renderBody={exerciseReview} />
            <ModalContainer
                handleClose={(): void => {
                    history.push('/home');
                    setOpenVideoPhotoAfterExercise(false);
                }}
                openModal={openVideoPhotoAfterExercise}
                renderBody={videoAndPhotoAfterExercise}
                hasCloseButton
            />
            <ModalContainerFullScreen
                openModal={openPhotoAfterExercise}
                renderBody={photoAfterExercise}
                hasBackButton
                handleGoBack={(): void => {
                    setOpenVideoPhotoAfterExercise(true);
                    setOpenPhotoAfterExercise(false);
                }}
            />
            <ModalContainerFullScreen
                openModal={openVideoAfterExercise}
                renderBody={videoAfterExerciseModal}
                hasBackButton
                handleGoBack={(): void => {
                    setOpenVideoAfterExercise(false);
                    setOpenPhotoAfterExercise(true);
                }}
            />
            <ModalContainer openModal={openThankModal} renderBody={thankAfterVideo} />
            <ModalContainer openModal={openAnotherExercice} renderBody={anotherExercice} />
        </Container>
    );
}
