import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { styled } from '@mui/styles';
import circleCloseIcon from '../../assets/circleClose.png';

const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 360,
    bgcolor: 'background.paper',
    borderRadius: '24px',
    boxShadow: 24,
    p: 4
};

const Logo = styled('img')(() => ({
    objectFit: 'inherit',
    width: '48px',
    height: '48px',
    marginLeft: 'auto',
    float: 'right'
}));

const RootModal = styled(Box)(() => ({
    '@media (max-width:320px)': {
        width: '130px'
    },
    '@media (min-width:320px)': {
        width: '315px',
        maxHeight: '555px',
        overflowY: 'scroll'
    },
    '@media (min-width:480px)': {
        width: '430px',
        maxHeight: '555px',
        overflowY: 'scroll'
    },
    '@media (min-width:600px)': {
        width: '590px'
    },
    '@media (min-width:1025px)': {
        width: '900px',
        overflow: 'auto'
    },
    '@media (min-width:1281px)': {
        width: '1100px'
    }
}));

interface NextStepConfirmationModalProps {
    isModelOpen: boolean;
    setModelOpen: Function;
    onCreateButtonClick: Function;
    onEditButtonClick: Function;
}

export function NextStepConfirmationModal({
    isModelOpen,
    setModelOpen,
    onCreateButtonClick,
    onEditButtonClick
}: NextStepConfirmationModalProps): JSX.Element {
    const { t } = useTranslation();
    const body = (
        <RootModal sx={style}>
            <Logo
                src={circleCloseIcon}
                alt="circleCloseIcon"
                onClick={(): void => {
                    setModelOpen(false);
                }}
            />
            <Box sx={{ p: 4 }}>
                <Typography
                    variant="h3"
                    color="#374653"
                    sx={{
                        marginBottom: '20px',
                        textAlign: 'center'
                    }}>
                    {t('whatDoYouWantToDo')}
                </Typography>
                <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{ width: '100%' }}>
                    <Grid key={1} item xs sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography
                            variant="subtitle1"
                            color="#374653"
                            sx={{
                                marginBottom: '20px',
                                fontWeight: 500,
                                textAlign: 'center'
                            }}>
                            {t('createForFamilies1')}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            color="#374653"
                            sx={{
                                marginBottom: '20px',
                                fontWeight: 500,
                                fontStyle: 'italic',
                                textAlign: 'center'
                            }}>
                            {t('createForFamilies2')}
                        </Typography>
                        <Button
                            disableElevation
                            variant="outlined"
                            size="large"
                            onClick={(): void => onCreateButtonClick()}
                            sx={{ height: 'auto' }}>
                            <Typography variant="subtitle1">
                                {t('createForFamiliesButton')}
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid key={2} item xs sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography
                            variant="subtitle1"
                            color="#374653"
                            sx={{
                                marginBottom: '20px',
                                fontWeight: 500,
                                textAlign: 'center'
                            }}>
                            {t('createForFamilies1')}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            color="#374653"
                            sx={{
                                marginBottom: '20px',
                                fontWeight: 500,
                                fontStyle: 'italic',
                                textAlign: 'center'
                            }}>
                            {t('createForFamilies2')}
                        </Typography>
                        <Button
                            disableElevation
                            variant="outlined"
                            size="large"
                            onClick={(): void => onEditButtonClick()}
                            sx={{ height: 'auto' }}>
                            <Typography variant="subtitle1" color="#374653">
                                {t('createForPatientsButton')}
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </RootModal>
    );

    return (
        <div>
            <Modal
                open={isModelOpen}
                onClose={(): void => {
                    setModelOpen(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                {body}
            </Modal>
        </div>
    );
}
