/* eslint-disable @typescript-eslint/no-explicit-any */
export const getRadianAngle = (degreeValue: number): number => {
    return (degreeValue * Math.PI) / 180;
};

export const rotateSize = (width: number, height: number, rotation: number): any => {
    const rotRad = getRadianAngle(rotation);
    return {
        width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
        height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height)
    };
};

export const createImage = (url: string): Promise<any> =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', (error) => reject(error));
        image.setAttribute('crossOrigin', 'anonymous');
        image.src = url;
    });

// export const getCroppedImg = async (
//     imageSrc: any,
//     pixelCrop: any,
//     flip = { horizontal: false, vertical: false }
// ): Promise<any> => {
//     const image = await createImage(imageSrc);
//     const canvas = document.createElement('canvas');
//     const ctx = canvas.getContext('2d');
//     if (!ctx) {
//         return null;
//     }
//     const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, 0);
//     canvas.width = bBoxWidth;
//     canvas.height = bBoxHeight;
//     ctx.translate(image.width / 2, image.height / 2);
//     ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
//     ctx.translate(-image.width / 2, -image.height / 2);
//     ctx.drawImage(image, 0, 0);
//     const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);
//     canvas.width = pixelCrop.width;
//     canvas.height = pixelCrop.height;
//     ctx.putImageData(data, 0, 0);
//     return new Promise((resolve, reject) => {
//         canvas.toBlob((blob) => {
//             if (blob) {
//                 resolve({ url: window.URL.createObjectURL(blob), file: blob });
//             }
//         }, 'image/webp');
//     });
// };

export const getCroppedImg = async (imageSrc: any, pixelCrop: any): Promise<any> => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');
    if (!ctx) {
        return null;
    }

    ctx.fillStyle = '#000000';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
    );

    // As Base64 string
    // return canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve, reject) => {
        canvas.toBlob((file) => {
            if (file) {
                resolve({ url: URL.createObjectURL(file), file });
            }
        }, 'image/jpeg');
    });
};
