import { Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import { InputComponent } from './FormComponents/InputComponent';

interface SearchBoxProps {
    placeholder: string;
    hasSearchIcon?: boolean;
    onSearchChange: Function;
    onBlur?: Function;
    onFocus?: Function;
}

export function SearchBox({
    placeholder,
    hasSearchIcon,
    onSearchChange,
    onBlur,
    onFocus
}: SearchBoxProps): JSX.Element {
    const [data, setData] = useState('');

    const handleChange = (value: string): void => {
        setData(value);
        onSearchChange(value);
    };

    return (
        <Box>
            <Grid
                item
                xs={12}
                sm={12}
                sx={{
                    padding: '1%',
                    width: '100% !important'
                }}
                key={1}>
                <InputComponent
                    placeholder={placeholder}
                    type="text"
                    className=""
                    onTextChange={(value: string): void => handleChange(value)}
                    isMultiline={false}
                    value={data}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    hasSearchIcon={hasSearchIcon}
                />
            </Grid>
        </Box>
    );
}
