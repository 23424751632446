import React from 'react';
import { Card, CardContent, CardMedia, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    cardStyle: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '24px',
        position: 'static',
        '@media (min-width: 275px)': {
            width: '250px'
        },
        '@media (min-width: 321px)': {
            width: '311px'
        },
        '@media (max-width: 400px)': {
            height: '385px'
        },
        height: '480px',
        background: '#FFFFFF',
        border: '1px solid #E3E6E8',
        boxSizing: 'border-box',
        boxShadow: '5px 5px 20px rgba(82, 82, 82, 0.08)',
        borderRadius: '11px',
        flex: 'none',
        margin: '8px 0px'
    },
    cardContent: {
        height: '280px',
        '@media (min-width: 275px)': {
            width: '250px'
        },
        '@media (min-width: 321px)': {
            width: '305px'
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden',
        '@media (max-width: 400px)': {
            justifyContent: 'normal',
            paddingTop: '8px'
        },
        justifyContent: 'space-between'
    },
    iconStyle: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '140px',
        height: '140px',
        flex: 'none',
        order: 0,
        flexGrow: 0,
        margin: '10px 0px'
    },
    iconStyleMedia: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '125px',
        height: '125px',
        flex: 'none',
        order: 0,
        flexGrow: 0,
        margin: '15px 0 20px 0'
    },
    cardTitle: {
        fontSize: '22px',
        lineHeight: '27px',
        letterSpacing: '-0.02em',
        marginBottom: '5px'
    },
    cardDescription: {
        height: '72px',
        fontWeight: 400,
        '@media (min-width: 275px)': {
            width: '235px',
            fontSize: '13.5px'
        },
        '@media (min-width: 321px)': {
            width: '300px',
            fontSize: '15.5px'
        }
    },
    button: {
        marginRight: 'auto',
        marginLeft: 'auto',
        width: '220px',
        height: '46px',
        borderRadius: '12px',
        '@media (min-width: 275px)': {
            top: '15px'
        },
        '@media (min-width: 321px)': {
            top: '20px'
        }
    }
}));

type Variant = 'primary' | 'secondary' | 'tertiary' | 'quaternary';

interface DashboardCardProps {
    iconImagePath: string;
    cardTitle: string;
    buttonLabel: string;
    buttonAction?: () => void;
    cardTitleColorVariant: Variant;
    cardButtonStyleVariant: Variant;
    children?: JSX.Element;
}

const getCardTitleColor = (variant: Variant): string => {
    switch (variant) {
        case 'primary': {
            return 'primary.main';
        }
        case 'secondary': {
            return '#3387CC';
        }
        case 'tertiary': {
            return '#3BC4C4';
        }
        case 'quaternary': {
            return '#6B00AD';
        }
        default: {
            return '#000000';
        }
    }
};

const getCardButtonStyle = (variant: Variant): {} => {
    switch (variant) {
        case 'primary': {
            return {
                background:
                    'linear-gradient(0deg, rgba(208, 0, 112, 0.02), rgba(208, 0, 112, 0.02))',
                border: '1px solid primary.dark',
                boxShadow: '0px 0px 0px 2px rgba(208, 0, 112, 0.4)'
            };
        }
        case 'secondary': {
            return {
                background: 'linear-gradient(0deg, rgba(0, 70, 128, 0.02), rgba(0, 70, 128, 0.02))',
                border: '1px solid #004680',
                boxShadow: '0px 0px 0px 2px rgba(0, 70, 128, 0.4)'
            };
        }
        case 'tertiary': {
            return {
                background:
                    'linear-gradient(0deg, rgba(39, 152, 152, 0.02), rgba(39, 152, 152, 0.02))',
                border: '1px solid #279898',
                boxShadow: '0px 0px 0px 2px rgba(39, 152, 152, 0.4)'
            };
        }
        case 'quaternary': {
            return {
                background:
                    'linear-gradient(0deg, rgba(164, 2, 227, 0.02), rgba(164, 2, 227, 0.02))',
                border: '1px solid #600996',
                boxShadow: '0px 0px 0px 2px rgba(99, 11, 139, 0.4)'
            };
        }
        default: {
            return {};
        }
    }
};

const getCardButtonLabelColor = (variant: Variant): string => {
    switch (variant) {
        case 'primary': {
            return 'primary.main';
        }
        case 'secondary': {
            return '#004680';
        }
        case 'tertiary': {
            return '#279898';
        }
        case 'quaternary': {
            return '#861fc6';
        }
        default: {
            return '#000000';
        }
    }
};

export function DashboardCard({
    iconImagePath,
    cardButtonStyleVariant,
    cardTitle,
    cardTitleColorVariant,
    buttonLabel,
    buttonAction,
    children
}: DashboardCardProps): JSX.Element {
    const classes = useStyles();
    const cardTitleColor = getCardTitleColor(cardTitleColorVariant);
    const cardButtonStyle = getCardButtonStyle(cardButtonStyleVariant);
    const cardButtonLabelColor = getCardButtonLabelColor(cardButtonStyleVariant);

    return (
        <Card className={classes.cardStyle} onClick={buttonAction} style={{ cursor: 'pointer' }}>
            <CardMedia
                className={cardTitle !== 'Médiathèque' ? classes.iconStyle : classes.iconStyleMedia}
                image={iconImagePath}
            />
            <CardContent className={classes.cardContent}>
                <Typography className={classes.cardTitle} variant="h4" color={cardTitleColor}>
                    {cardTitle}
                </Typography>
                {children}
                <Button
                    className={classes.button}
                    variant="outlined"
                    sx={cardButtonStyle}
                    onClick={buttonAction}>
                    <Typography
                        color={cardButtonLabelColor}
                        variant="button"
                        sx={{ fontWeight: 600, fontSize: '1.03rem' }}>
                        {buttonLabel}
                    </Typography>
                </Button>
            </CardContent>
        </Card>
    );
}
