import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminFormLayout } from '../../layouts/AdminFormLayout';
import { Family } from '../../models/Family';
import addFamily from '../../assets/addFamily.png';

export function AddFamily(): React.ReactElement {
    const { t } = useTranslation();
    return (
        <AdminFormLayout
            subtitleDescription={t('addFormFamilyDescription')}
            data={new Family()}
            backgroundImage={addFamily}
            columnSize="340px"
            formFor="family"
        />
    );
}
