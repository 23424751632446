/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useState } from 'react';
import { Box, CircularProgress, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { ImageFrame } from './ImageFrame';
import waitPicture from '../../assets/waitPicture.png';
import bugDog from '../../assets/bugDog.png';
import { IdentifyPersonModal } from '../../components/ImageUpload/IdentifyPersonModal';
import { UserContext } from '../../providers/UserProvider';
import { UserDataProp } from '../../models/userDataProp';
import { UsersVideoData } from '../../models/UsersVideoData';
import { InputComponent } from '../../components/FormComponents/InputComponent';
import { Roles } from '../../models/Roles';
import { codeStorage } from '../../utilities/utils';
import { addPersonalVideo, getTherapistUser } from '../../services/cloudFirestore';
import { DeactiveModall } from '../../components/ContainerModal';
import { SelectPatient } from '../../components/ImageUpload/SelectPatient';
import { downloadData } from '../../services/analyticsFunction';

const useStyles = makeStyles(() => ({
    input: {
        marginBottom: '12px',
        color: '#374653'
    },
    textField: {
        marginBottom: '15px'
    },
    warningImage: {
        display: 'flex',
        width: '220px',
        margin: 'auto'
    }
}));

const ModalButtons = styled(Button)(() => ({
    '@media (max-width: 598px)': {
        width: '100%',
        margin: '2% 0%'
    },
    '@media (min-width: 598px)': {
        width: '30%'
    }
}));

const ButtonContainer = styled(Box)({
    '@media (max-width: 598px)': {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    '@media (min-width: 598px)': {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row'
    }
});

interface ChooseVideoProps {
    videoFile: File[];
    setMedia: Function;
}

export function ChooseVideo({ videoFile, setMedia }: ChooseVideoProps): JSX.Element {
    const isSmallWidth = !useMediaQuery('(min-width:500px)');
    const classes = useStyles();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const { t } = useTranslation();
    const [openEndModal, setOpenEndModal] = useState<boolean>(false);
    const [openModalError, setOpenModalError] = useState<boolean>(false);
    const [openWaitModal, setOpenWaitModal] = useState(false);
    const [enabledSubmit, setEnabledSubmit] = useState<boolean>(false);
    const [video, setVideo] = useState<string>('');
    const [videoSize, setVideoSize] = useState<number>(0);
    const [stepper, setStepper] = useState<'00' | '01' | '02'>('01');
    const [data, setData] = useState<UsersVideoData>({
        authorId: user?.userId ?? undefined,
        title: undefined,
        communityId: undefined,
        role: undefined,
        patientList: []
    });

    useEffect(() => {
        const getCommunityId = async (): Promise<void> => {
            if (user && user.id) {
                if (user.role === Roles.FAMILY && user.familyCode) {
                    setData({ ...data, communityId: user.familyCode, role: Roles.FAMILY });
                }
                if (user.role === Roles.THERAPIST) {
                    const therapistUser = await getTherapistUser(user.id);
                    if (therapistUser && therapistUser.establishmentCode) {
                        setData({
                            ...data,
                            communityId: therapistUser.establishmentCode,
                            role: Roles.THERAPIST
                        });
                    }
                }
                if (user.role === Roles.ADMIN) {
                    setData({
                        ...data,
                        communityId: user.id,
                        role: Roles.ADMIN
                    });
                }
            }
        };
        getCommunityId();
    }, []);

    useEffect(() => {
        if (data.title) {
            setEnabledSubmit(true);
        } else {
            setEnabledSubmit(false);
        }
    }, [data.title]);

    const handleChange = (state: string, value: string | File | Date | boolean): void => {
        setData({ ...data, [state]: value });
    };

    const [thisSelectedVideo, setSelectedVideo] = useState<File[]>();
    const setVideoTemp = (tempSelectedVideo: File[]): void => {
        if (tempSelectedVideo) {
            setVideoSize(tempSelectedVideo[0].size);
            const value = URL.createObjectURL(tempSelectedVideo[0]);
            setVideo(value);
            setSelectedVideo(tempSelectedVideo);
        }
    };

    useEffect(() => {
        if (videoFile) setVideoTemp(videoFile);
    }, [videoFile]);

    const closeErrorModal = (): void => {
        setOpenModalError(false);
        setMedia(null);
    };

    const saveItem = async (): Promise<void> => {
        if (user) {
            if (user.role === Roles.THERAPIST && stepper === '01') {
                setStepper('02');
            } else {
                const storage = codeStorage(user);
                if (data && thisSelectedVideo && storage !== null) {
                    const openWait = setTimeout((): void => {
                        setOpenWaitModal(true);
                    }, 5000);
                    const timeOutWait = setTimeout((): void => {
                        setOpenWaitModal(false);
                        setOpenModalError(true);
                    }, 60000);
                    const response = await addPersonalVideo(data, thisSelectedVideo[0], storage);
                    if (response === 'success') {
                        downloadData('new_video', user, videoSize);
                        clearTimeout(timeOutWait);
                        clearTimeout(openWait);
                        setOpenWaitModal(false);
                        setOpenModalError(false);
                        if (thisSelectedVideo && thisSelectedVideo.length > 1) {
                            setData({
                                ...data,
                                title: undefined
                            });
                            setVideoTemp(thisSelectedVideo.slice(1));
                            setVideo(URL.createObjectURL(thisSelectedVideo.slice(1)[0]));
                            setStepper('00');
                            setStepper('01');
                        } else {
                            setOpenEndModal(true);
                        }
                    } else {
                        clearTimeout(timeOutWait);
                        clearTimeout(openWait);
                        setOpenWaitModal(false);
                        setOpenModalError(true);
                    }
                }
            }
        }
    };

    const errorModal = (
        <div>
            <img className={classes.warningImage} src={bugDog} alt="dog" />
            <Typography variant="h3" color="#374653" sx={{ textAlign: 'center', marginTop: '3%' }}>
                {t('oups...')}
            </Typography>
            <Typography
                variant="h3"
                color="#374653"
                sx={{ textAlign: 'center', marginTop: '2%', marginBottom: '1.5%' }}>
                {t('littleBug')}
            </Typography>
            <Typography
                variant="subtitle1"
                my={2}
                sx={{ textAlign: 'center', fontSize: '1.25rem !important' }}>
                {t('makeSure')}
            </Typography>
            <ButtonContainer mt={3} mb={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                <ModalButtons
                    variant="outlined"
                    sx={{
                        border: '1px solid #8B004B',
                        boxShadow: '0px 0px 0px 2px rgba(208, 0, 112, 0.4)',
                        borderRadius: '12px',
                        width: '30%'
                    }}
                    onClick={(): void => {
                        closeErrorModal();
                    }}>
                    <Typography variant="subtitle1" color="#D00070">
                        {t('again')}
                    </Typography>
                </ModalButtons>
            </ButtonContainer>
        </div>
    );

    const endModal = (
        <div style={{ padding: isSmallWidth ? '' : '20px 30px 40px 30px' }}>
            <Typography
                variant="h4"
                color="#004680"
                sx={{
                    fontWeight: 700,
                    textAlign: 'center',
                    marginBottom: '20px'
                }}>
                {t('videoImport')}
            </Typography>
            <Typography
                variant="h6"
                color="#004680"
                sx={{
                    fontWeight: 600,
                    textAlign: 'center',
                    paddingBottom: '40px'
                }}>
                {t('returnToMediathequeVideo')}
            </Typography>
            <Button
                fullWidth
                disableElevation
                variant="outlined"
                size="large"
                sx={{ border: '3px solid rgba(179, 4, 98, 0.69)', marginBottom: '10px' }}
                onClick={(): void => {
                    window.location.reload();
                }}>
                <Typography variant="subtitle1" color="#D00070">
                    {t('yes').toUpperCase()}
                </Typography>
            </Button>
            <Button
                fullWidth
                disableElevation
                variant="outlined"
                size="large"
                sx={{ border: '3px solid rgba(57, 139, 206, 0.69)', marginBottom: '10px' }}
                onClick={(): void => {
                    window.location.reload();
                }}>
                <Typography variant="subtitle1" color="#3387CC">
                    {t('noThanks')}
                </Typography>
            </Button>
        </div>
    );

    const waitModalContent = (
        <div>
            <img className={classes.warningImage} src={waitPicture} alt="wait" />
            <Typography variant="h3" color="#374653" sx={{ textAlign: 'center', marginTop: '3%' }}>
                {t('downloadInProgress')}
            </Typography>
            <Typography
                variant="h3"
                color="#374653"
                sx={{ textAlign: 'center', marginTop: '2%', marginBottom: '1.5%' }}>
                {t('pleaseWait')}
            </Typography>
            <CircularProgress sx={{ display: 'flex', margin: 'auto' }} />
            <Typography
                variant="body1"
                color="#374653"
                mt={2}
                sx={{
                    textAlign: 'center',
                    fontWeight: 550,
                    color: '#D00070',
                    fontSize: '1.33rem !important'
                }}>
                {t('noted')}
            </Typography>
            <Typography
                variant="subtitle1"
                my={2}
                sx={{ textAlign: 'center', fontSize: '1.25rem !important' }}>
                {t('linkToYourConnection')}
            </Typography>
        </div>
    );

    return (
        <Box flex={1} display="flex" flexDirection="column" height="100vh">
            <IdentifyPersonModal
                isModelOpen={openEndModal}
                setModelOpen={setOpenEndModal}
                body={endModal}
                onCancel={(): void => {
                    setMedia(null);
                }}
            />
            <DeactiveModall
                isModelOpen={openModalError}
                setModelOpen={setOpenModalError}
                body={errorModal}
                circleIcon
                onCancel={(): void => {
                    closeErrorModal();
                }}
            />
            <DeactiveModall
                isModelOpen={openWaitModal}
                setModelOpen={setOpenWaitModal}
                body={waitModalContent}
                circleIcon={false}
                onCancel={(): void => {}}
            />
            {stepper === '01' && (
                <ImageFrame
                    activeStep={0}
                    maxStep={user?.role === Roles.THERAPIST ? 2 : 1}
                    title={t('titleOfVideo')}
                    onBackClick={(): void => setMedia('')}
                    onNextClick={saveItem}
                    isDisabled={enabledSubmit}
                    isValidateButton={user?.role !== Roles.THERAPIST}
                    isloadButton={!openEndModal}>
                    <Box height="100%" sx={{ marginTop: isSmallWidth ? '4%' : '10%' }}>
                        <Box
                            sx={{ width: '95%', margin: 'auto' }}
                            display="flex"
                            flexDirection={isSmallWidth ? 'column' : 'row'}
                            alignContent="flex-start"
                            alignItems="flex-start">
                            <Box
                                sx={{
                                    width: isSmallWidth ? '100%' : '45vw',
                                    marginRight: isSmallWidth ? '' : '4%'
                                }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        position: 'relative',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        alignContent: 'center',
                                        height: '40vh'
                                    }}>
                                    <video
                                        preload="metadata"
                                        style={{
                                            width: '100%',
                                            padding: '0',
                                            height: '100%'
                                        }}
                                        controls>
                                        <track
                                            src="your video"
                                            kind="captions"
                                            srcLang="fr"
                                            label="french_captions"
                                        />
                                        <source src={`${video}`} id="your video" type="video/mp4" />
                                    </video>
                                </div>
                            </Box>
                            <Box
                                sx={{
                                    width: isSmallWidth ? '100%' : '45vw',
                                    margin: isSmallWidth ? '' : 'auto',
                                    paddingBottom: isSmallWidth ? '' : '30px'
                                }}>
                                <div>
                                    <Typography
                                        variant="subtitle1"
                                        color="#374653"
                                        sx={{ textAlign: 'left', marginTop: '15px' }}>
                                        {t('shortTitleVideo')}{' '}
                                        <span style={{ color: 'red' }}>{t('required')}</span> :
                                    </Typography>
                                </div>
                                <Typography
                                    variant="body2"
                                    color="#ACBCC7"
                                    sx={{ textAlign: 'left', marginBottom: '15px' }}>
                                    {t('max40Characters')}
                                </Typography>
                                <InputComponent
                                    placeholder={t('example')}
                                    value={data.title ?? null}
                                    maximumLength={40}
                                    type="text"
                                    className={classes.textField}
                                    isMultiline={false}
                                    onTextChange={(value: string): void =>
                                        handleChange('title', value)
                                    }
                                />
                                <Typography
                                    style={{
                                        color: '#3746539C',
                                        marginBottom: '12px',
                                        textAlign: 'left',
                                        fontSize: '0.85rem'
                                    }}>
                                    <span style={{ color: 'red' }}>{t('required')}</span>{' '}
                                    {t('champIsrequired')}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </ImageFrame>
            )}
            {stepper === '02' && user?.role === Roles.THERAPIST && (
                <ImageFrame
                    maxStep={user?.role === Roles.THERAPIST ? 2 : 1}
                    activeStep={1}
                    title={t('shareVideo')}
                    onBackClick={(): void => setStepper('01')}
                    onNextClick={saveItem}
                    isDisabled
                    isSubmitButton
                    isloadButton>
                    <SelectPatient
                        setDataPatient={(content: (string | undefined)[]): void => {
                            if (content) {
                                setData({
                                    ...data,
                                    patientList: content
                                });
                            }
                        }}
                    />
                </ImageFrame>
            )}
        </Box>
    );
}
