import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import {
    Box,
    List,
    Collapse,
    ListItemText,
    ListItemIcon,
    ListItemButton,
    SvgIconTypeMap
} from '@mui/material';
import { OverridableComponent, OverridableTypeMap } from '@mui/material/OverridableComponent';

// ----------------------------------------------------------------------

const ListItemStyle = styled(ListItemButton)(({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    position: 'relative',
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2.5),
    color: theme.palette.text.secondary,
    '&:before': {
        top: 0,
        right: 0,
        width: 3,
        bottom: 0,
        content: "''",
        display: 'none',
        position: 'absolute',
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        backgroundColor: theme.palette.primary.main
    }
}));

const ListItemIconStyle = styled(ListItemIcon)({
    width: 22,
    height: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
});

// ----------------------------------------------------------------------

interface NavItemProps {
    item: { title: string; path: string; icon: JSX.Element };
    active: Function;
}

function NavItem({ item, active }: NavItemProps): JSX.Element {
    const theme = useTheme();
    const isActiveRoot = active(item.path);
    const { title, path, icon } = item;
    const [open, setOpen] = useState(isActiveRoot);

    const handleOpen = (): void => {
        setOpen((prev: boolean) => !prev);
    };

    const activeRootStyle = {
        color: '#1E2933',
        fontWeight: 'fontWeightMedium',
        '&:before': { display: 'block' }
    };

    const inactiveRootStyle = {
        color: '#E3E6E8',
        fontWeight: 'fontWeightMedium',
        '&:before': { display: 'block' }
    };

    return (
        <ListItemButton
            component={RouterLink}
            to={path}
            sx={{
                ...(isActiveRoot ? activeRootStyle : inactiveRootStyle)
            }}>
            <ListItemIconStyle
                sx={{
                    ...(isActiveRoot ? activeRootStyle : inactiveRootStyle)
                }}>
                {icon}
            </ListItemIconStyle>
            <ListItemText disableTypography primary={title} />
        </ListItemButton>
    );
}

interface NavSectionProps {
    navConfig: { title: string; path: string; icon: JSX.Element }[];
}

export function NavSection({ navConfig }: NavSectionProps): JSX.Element {
    const { pathname } = useLocation();
    const match = (path: string): boolean => (path ? !!matchPath(path, pathname) : false);

    return (
        <Box>
            <List disablePadding>
                {navConfig.map((item) => (
                    <NavItem key={item.title} item={item} active={match} />
                ))}
            </List>
        </Box>
    );
}
