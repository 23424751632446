import React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiStepIcon-root': {
            color: '#ffffff',
            border: '1px solid #ACBCC7 !important',
            borderRadius: '50% !important',
            '& .MuiStepIcon-text': {
                fill: 'rgba(92, 102, 112, 1)'
            }
        },
        '& .MuiStepIcon-root.Mui-active': {
            color: '#FF0089 !important',
            '& .MuiStepIcon-text': {
                fill: '#fff !important'
            }
        },
        '& .MuiStepIcon-root.Mui-completed': {
            color: '#89CC33 !important'
        },
        '& .MuiStep-root': {
            padding: '0px'
        },
        '& .MuiStepLabel-iconContainer': {
            padding: '0px'
        }
    }
}));

interface CustomStepperProps {
    steps: number[];
    currentStep: number;
}

export function CustomStepper({ steps, currentStep }: CustomStepperProps): JSX.Element {
    const classes = useStyles();
    const activeStep = currentStep;

    return (
        <Box sx={{ width: '100%' }} className={classes.root}>
            <Stepper activeStep={activeStep}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel className={classes.root} />
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
}
