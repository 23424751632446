import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { TableLayout } from '../../layouts/TableLayout';
import { EstablishmentsExportData } from '../../models/EstablishmentsExportData';
import { PatientViewData } from '../../models/PatientViewData';
import { getPatientExportData, getPatientList } from '../../services/cloudFirestore';
import { convertToTitleCase, getDateFromTimestamp } from '../../utilities/utils';

export function Patient(): JSX.Element {
    const { t } = useTranslation();
    const history = useHistory();
    const [patientList, setPatientList] = useState<PatientViewData[] | null>(null);
    const [patientExportData, setPatientExportData] = useState<EstablishmentsExportData[]>([]);

    // const updatePatientRow = async (data: EstablishmentViewData): Promise<void> => {
    // const row = await getEstablishment2(data.establishmentCode!);
    // if (row) {
    //     if (
    //         row.firstName !== data.firstName ||
    //         row.lastName !== data.lastName ||
    //         row.email !== data.email ||
    //         row.address !== data.address ||
    //         row.city !== data.city ||
    //         row.pincode !== data.pincode ||
    //         row.fonction !== data.fonction ||
    //         row.mobile !== data.mobile
    //     ) {
    //         await updateEstablishment(data);
    //         window.location.reload();
    //     }
    // }
    // };

    const columns = [
        { field: 'establishmentName', minWidth: 160, flex: 1, headerName: t('instituitionName') },
        {
            field: 'firstName',
            minWidth: 100,
            flex: 1,
            headerName: `${t('firstName')}*`,
            editable: true
        },
        { field: 'lastName', minWidth: 100, flex: 1, headerName: `${t('name')}*`, editable: true },
        {
            field: 'dob',
            minWidth: 180,
            flex: 1,
            headerName: `${t('dateOfBirth')}*`,
            editable: true
        },
        { field: 'status', minWidth: 120, flex: 1, headerName: t('status') },
        {
            field: 'button',
            headerName: 'Modifier',
            minWidth: 120,
            flex: 1,
            // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            renderCell: (params: GridRenderCellParams<Date>) => (
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={(): void => {
                        // updatePatientRow(params.row);
                    }}>
                    {t('updateData')}
                </Button>
            )
        }
    ];

    const onAddButtonClicked = (): void => {
        history.push('/patient/add');
    };

    const fetchPatientList = async (): Promise<void> => {
        const patient = await getPatientList();
        const patientViewData: PatientViewData[] = patient;
        patient.forEach((item, index) => {
            if (item.dob) {
                patientViewData[index].dob = getDateFromTimestamp(item.dob);
            }
            if (item.status) {
                patientViewData[index].status = convertToTitleCase(item.status);
            }
        });
        setPatientList(patientViewData);
        const exportData = await getPatientExportData();
        setPatientExportData(exportData);
    };

    useEffect(() => {
        if (patientList === null || patientList === undefined) {
            fetchPatientList();
        }
    });

    return (
        <TableLayout
            title={t('patients')}
            addButtonTitle={t('addPatients')}
            onAddButtonClicked={onAddButtonClicked}
            columns={columns}
            rows={patientList === undefined || patientList === null ? [] : patientList}
            exportData={patientExportData}
            nameOfFile={t('patients')}
        />
    );
}
