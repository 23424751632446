// eslint-disable-next-line
export default {
    colors: {
        // background: 'radial-gradient(#282c34cc, #282c34)',
        // background: '#FFEFD5',
        // black: '#282c34',
        blue: '#dadada',
        white: 'white',
        background: 'radial-gradient(#a0e9fd, #16697a)',
        kobaltblue: '#0F0046',
        corectValue: '#00DF76',
        darkorange: '#db6400',
        lightorange: '#ffa62b',
        lightBlue: '#dadada'
    },
    transition: '0.3s'
};
