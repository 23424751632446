import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Typography } from '@mui/material';

const useStyles = makeStyles(() =>
    createStyles({
        main: {
            color: 'rgba(51, 135, 204, 1)',
            width: '100%',
            textAlign: 'center',
            borderBottom: '1px solid #ACBCC7',
            lineHeight: '0.1em',
            margin: '40px 0 10px'
        },
        span: {
            background: '#fff',
            padding: '0 10px'
        }
    })
);

interface TitleFormPartInterface {
    text: string;
}

export const TitleFormPart = ({ text }: TitleFormPartInterface): JSX.Element => {
    const classes = useStyles();

    return (
        <Typography variant="h5" className={classes.main}>
            <span className={classes.span}>{text}</span>
        </Typography>
    );
};
