import { Box, LinearProgress, linearProgressClasses, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/styles';

const MedianBox = styled(Box)({
    display: 'flex',
    flexGrow: 1,
    m: 1,
    flexDirection: 'column',
    justifyContent: 'space-between'
});

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    backgroundColor: 'transparent'
}));

interface MedianExerciseProps {
    avgMemoryTime: number;
    minMemoryTime: number;
    maxMemoryTime: number;
    avgMemorinTime: number;
    minMemorinTime: number;
    maxMemorinTime: number;
    avgPuzzleTime: number;
    minPuzzleTime: number;
    maxPuzzleTime: number;
}

export function MedianExercise({
    avgMemoryTime,
    minMemoryTime,
    maxMemoryTime,
    avgMemorinTime,
    minMemorinTime,
    maxMemorinTime,
    avgPuzzleTime,
    maxPuzzleTime,
    minPuzzleTime
}: MedianExerciseProps): React.ReactElement {
    const [averageGlobalTime, setAverageGlobalTime] = useState<string>('');
    const progress = (value: number, total: number, color: string): React.ReactElement => {
        return (
            <BorderLinearProgress
                variant="determinate"
                value={(value / total) * 100}
                sx={{
                    mb: 1,
                    [`& .${linearProgressClasses.bar}`]: {
                        backgroundColor: color
                    }
                }}
            />
        );
    };

    const timeCalcul = (time: number): string => {
        const minute = Math.floor(time / 60);
        const second = time - minute * 60;
        let sec = `${Math.trunc(second)}`;
        if (second >= 0 && second <= 9) {
            sec = `0${second}`;
        }
        return `${minute}:${sec}`;
    };

    useEffect(() => {
        const time = (avgPuzzleTime + avgMemoryTime + avgMemorinTime) / 3;
        // const time = (avgPuzzleTime + avgMemoryTime) / 2;
        setAverageGlobalTime(timeCalcul(time));
    }, []);

    const { t } = useTranslation();
    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                textAlign: 'left',
                paddingTop: '30px',
                '& > :not(style)': {
                    m: 1,
                    height: 261,
                    width: 400
                }
            }}>
            <Paper
                elevation={1}
                sx={{
                    padding: '13px',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontWeight: 600 }} variant="h5">
                        {t('exerciseMedian')}
                    </Typography>
                    <Typography sx={{ fontWeight: 600 }} variant="h5">
                        {t('averageTime', { time: averageGlobalTime })}
                    </Typography>
                </Box>
                <MedianBox sx={{ paddingTop: '20px' }}>
                    <Box>
                        <Typography variant="subtitle2">
                            {t('memoryTime', { time: timeCalcul(avgMemoryTime) })}
                        </Typography>
                        <Box display="flex" justifyContent="space-between">
                            <Typography variant="caption">
                                {' '}
                                {t('minTime', { time: timeCalcul(minMemoryTime) })}
                            </Typography>
                            <Typography variant="caption">
                                {' '}
                                {t('maxTime', { time: timeCalcul(maxMemoryTime) })}
                            </Typography>
                        </Box>
                        {progress(100, 100, '#8200E8')}
                    </Box>
                    <Box>
                        <Typography variant="subtitle2">
                            {' '}
                            {t('puzzleTime', { time: timeCalcul(avgPuzzleTime) })}
                        </Typography>
                        <Box display="flex" justifyContent="space-between">
                            <Typography variant="caption">
                                {' '}
                                {t('minTime', { time: timeCalcul(minPuzzleTime) })}
                            </Typography>
                            <Typography variant="caption">
                                {' '}
                                {t('maxTime', { time: timeCalcul(maxPuzzleTime) })}
                            </Typography>
                        </Box>
                        {progress(100, 100, '#00D1FF')}
                    </Box>
                    <Box>
                        <Typography variant="subtitle2">
                            {t('memorinTime', { time: timeCalcul(avgMemorinTime) })}
                        </Typography>
                        <Box display="flex" justifyContent="space-between">
                            <Typography variant="caption">
                                {' '}
                                {t('minTime', { time: timeCalcul(minMemorinTime) })}
                            </Typography>
                            <Typography variant="caption">
                                {' '}
                                {t('maxTime', { time: timeCalcul(maxMemorinTime) })}
                            </Typography>
                        </Box>
                        {progress(100, 100, '#0F0046')}
                    </Box>
                </MedianBox>
            </Paper>
        </Box>
    );
}
