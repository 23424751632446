/* eslint-disable import/no-named-as-default */
import { Box, Button, Typography } from '@mui/material';
import React, { useState, useRef, useEffect, useContext } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ref } from '@firebase/storage';
import storage from 'redux-persist/lib/storage';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { ExerciseHeader } from '../../../components/ExerciseHeader';
import { UserDataProp } from '../../../models/userDataProp';
import { UserContext } from '../../../providers/UserProvider';
import { SuccessModal } from '../../../components/SuccessModal';
import { ExerciseType } from '../../../models/ExerciseType';
import PongComponent from './sketch';
import { getPatientUser } from '../../../services/cloudFirestore';
import { Patient } from '../../../models/Patient';
import { Roles } from '../../../models/Roles';
import { ReviewExerciseAlerts } from '../../common/ReviewExerciseAlerts';
import { Exercise } from '../../../models/Exercise';
import { getImageKitUrlFrom } from '../../../utilities/utils';
import imgPongin from '../../../assets/imgPongin.png';

interface LocationState {
    exercises: Exercise;
}

export function Pong(): JSX.Element {
    const { t } = useTranslation();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [patient, setPatient] = useState<Patient>();
    const [exercise, setExercise] = useState<Exercise>();
    const location = useLocation<LocationState>();
    const [displayRules, setDisplayRules] = useState<boolean>(true);
    const [cpuMode, setCPUMode] = useState<boolean | undefined>(undefined);
    const [difficultyLevel, setDifficultyLevel] = useState<number>(0);
    const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
    const [openReview, setOpenReview] = useState<boolean>(false);
    const [pongPlayerScore, setPongPlayerScore] = useState<number>(0);
    const [pongCpuScore, setPongCpuScore] = useState<number>(0);
    const ballSpeed = [4, 5, 6, 8, 10, 13, 16, 20, 22, 25];
    const cpuSpeed = [
        { min: 1, max: 5 },
        { min: 4, max: 7 },
        { min: 6, max: 9 },
        { min: 8, max: 14 },
        { min: 9, max: 15 },
        { min: 7, max: 16 }, // original
        { min: 10, max: 18 },
        { min: 13, max: 22 },
        { min: 16, max: 25 },
        { min: 18, max: 28 }
    ];
    const scoreNeedToWin = [5, 7, 10, 15, 20, 20, 25, 25, 30, 30];

    useEffect(() => {
        const getExerciseList = async (): Promise<void> => {
            if (user && user?.id) {
                const patientData = await getPatientUser(user?.id);
                if (patientData && patientData.pongDifficultyLevel) {
                    setPatient(patientData);
                    setDifficultyLevel(patientData.pongDifficultyLevel);
                    // localStorage.setItem('difficultyLevel', `${patientData.sudokuDifficultyLevel}`);
                }
                if (user?.role === Roles.ADMIN || user.role === Roles.THERAPIST) {
                    setDifficultyLevel(3);
                }
            }
            if (location.state.exercises) {
                setExercise(location.state.exercises);
            }
        };
        getExerciseList();
    }, []);

    useEffect(() => {
        if (user?.role === Roles.PATIENT) {
            const startTimer = localStorage.getItem('startTime');
            if (!startTimer) {
                localStorage.setItem('startTime', new Date().toString());
            }
        }
    }, []);

    const finishGame = (playerScore: number, cpuScore: number): void => {
        setOpenSuccessModal(true);
        setPongPlayerScore(playerScore);
        setPongCpuScore(cpuScore);
        setTimeout(() => {
            setOpenSuccessModal(false);
            setOpenReview(true);
        }, 3000);
    };

    return (
        <Box
            flex={1}
            display="flex"
            flexDirection="column"
            height="100vh"
            width="100%"
            overflow="hidden">
            {/* sx={{
                position: 'fixed',
                left: 0,
                top: 0,
                right: 0,
                bottom: 0
            }}> */}
            <SuccessModal
                isModelOpen={openSuccessModal}
                setModelOpen={setOpenSuccessModal}
                isSuccess
                isPlaySound
                isFirework
                nbModal={0}
            />
            {openReview && (
                <ReviewExerciseAlerts
                    exercise={exercise}
                    exerciseType={ExerciseType.SUDOKU}
                    difficultyLevel={difficultyLevel}
                    // sudokuTime={sudokuTime}
                    pongPlayerScore={pongPlayerScore}
                    pongCpuScore={pongCpuScore}
                />
            )}
            {/* <Box sx={{ height: '100px', display: displayRules ? 'block' : 'none' }}> */}
            <Box sx={{ minHeight: '78px', maxHeight: '78px', display: 'block' }}>
                <ExerciseHeader
                    exerciseName={t('Pongin')}
                    // level={patient && patient.pongDifficultyLevel ? patient.pongDifficultyLevel : 0}
                    level={difficultyLevel}
                    isPuzzle
                    isExerciseSelected={!displayRules}
                    difficultyLevel={6}
                    // handleClueClick={(): void => clue()}
                    exerciseType={ExerciseType.PONG}
                    handleClueClick={(): void => {}}
                />
            </Box>
            <Box
                sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: '#f5f5f5'
                }}>
                <Box
                    sx={{
                        height: '100%',
                        width: '100%',
                        display: displayRules ? 'flex' : 'none',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        backgroundColor: '#f5f5f5'
                    }}>
                    <Box
                        sx={{
                            height: '80%',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'row'
                        }}>
                        <Box
                            sx={{
                                height: '100%',
                                width: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}>
                            <img
                                src={imgPongin}
                                alt="imgPongin"
                                width={window.innerWidth > 1030 ? '65%' : '80%'}
                            />
                        </Box>
                        <Box
                            sx={{
                                height: '100%',
                                width: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'left',
                                flexDirection: 'column',
                                padding: '0 20px'
                            }}>
                            <Typography
                                sx={{
                                    color: '#000000',
                                    fontSize: '15px !important',
                                    fontWeight: '400',
                                    fontFamily: 'Luciole-Regular !important',
                                    width: '100%'
                                }}>
                                {t('pong.rules1')}
                                <br />
                                <br />
                                {t('pong.rules1.2')}
                                <ul style={{ marginTop: '0px' }}>
                                    <li>{t('pong.rules1Desc1')}</li>
                                    <li>{t('pong.rules1Desc2')}</li>
                                </ul>
                            </Typography>
                            <Typography
                                sx={{
                                    color: '#000000',
                                    fontSize: '15px !important',
                                    fontWeight: '400',
                                    fontFamily: 'Luciole-Regular !important',
                                    width: '100%'
                                }}>
                                {t('pong.rules2')}
                                <ul style={{ marginTop: '0px' }}>
                                    <li>{t('pong.rules2Desc1')}</li>
                                    <li>{t('pong.rules2Desc2')}</li>
                                </ul>
                            </Typography>
                            <Typography
                                sx={{
                                    color: '#000000',
                                    fontSize: '15px !important',
                                    fontWeight: '400',
                                    fontFamily: 'Luciole-Regular !important',
                                    width: '100%'
                                }}>
                                {t('pong.rules3', { nbPoint: scoreNeedToWin[difficultyLevel - 1] })}
                                <br />
                                <br />
                                {t('pong.rules3.2')}
                                <ul style={{ marginTop: '0px' }}>
                                    <li>{t('pong.rules3Desc1')}</li>
                                    <li>{t('pong.rules3Desc2')}</li>
                                </ul>
                            </Typography>
                            <Typography
                                sx={{
                                    color: '#000000',
                                    fontSize: '15px !important',
                                    fontWeight: '400',
                                    fontFamily: 'Luciole-Regular !important',
                                    width: '100%'
                                }}>
                                {t('pong.rules4')}
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            height: '20%',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            alignItems: 'center'
                        }}>
                        <Button
                            onClick={(): void => {
                                setDisplayRules(false);
                                setCPUMode(true);
                            }}
                            sx={{
                                marginTop: '0%',
                                border: '1px solid #8B004B',
                                borderRadius: '12px',
                                boxShadow: '0px 0px 0px 2px rgba(208, 0, 112, 0.4)',
                                width: '400px',
                                height: '61px',
                                fontSize: '24px',
                                fontWeight: '600',
                                fontFamily: 'Luciole-Regular'
                            }}>
                            {t('pong.start')}
                        </Button>
                        {/* button 2 player */}
                        {/* <Button
                            onClick={(): void => {
                                setDisplayRules(false);
                                setCPUMode(false);
                            }}
                            sx={{
                                marginTop: '0%',
                                border: '1px solid #8B004B',
                                borderRadius: '12px',
                                boxShadow: '0px 0px 0px 2px rgba(208, 0, 112, 0.4)',
                                width: '400px',
                                height: '61px',
                                fontSize: '24px',
                                fontWeight: '600',
                                fontFamily: 'Luciole-Regular'
                            }}>
                            {t('pong.start2')}
                        </Button> */}
                    </Box>
                </Box>
                <Box
                    display={!displayRules && cpuMode !== undefined ? 'block' : 'none'}
                    sx={{
                        // position: 'absolute',
                        // top: 78,
                        // zIndex: 100,
                        height: '100%',
                        overflow: 'hidden',
                        overscrollBehavior: 'none',
                        touchAction: 'none',
                        overflowY: 'hidden'
                    }}>
                    <Box
                        sx={{
                            // position: 'absolute',
                            // top: 0,
                            // zIndex: 100,
                            height: '100%',
                            overflow: 'hidden',
                            overscrollBehavior: 'none'
                        }}>
                        {difficultyLevel && !displayRules && (
                            <PongComponent
                                cpuMode={cpuMode !== false}
                                setDisplayRules={setDisplayRules}
                                scoreNeedToWin={scoreNeedToWin[difficultyLevel - 1]}
                                ballSpeed={ballSpeed[difficultyLevel - 1]}
                                cpuSpeed={cpuSpeed[difficultyLevel - 1]}
                                finishGame={finishGame}
                                userImage={
                                    patient &&
                                    patient.profilePhoto &&
                                    typeof patient.profilePhoto === 'string'
                                        ? patient.profilePhoto
                                        : ''
                                }
                            />
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
