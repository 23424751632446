/* eslint-disable react/no-unknown-property */
/* eslint-disable prettier/prettier */
import React from 'react';

export function PongSvgIcon(): JSX.Element {
    return (
        <svg
            width="28"
            height="26"
            viewBox="0 0 30 30"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            {/* <mask id="path-4-inside-1_11_9097" fill="white">
                <rect width="30" height="29" rx="1.71051" />
            </mask> */}
            <rect
                width="30"
                height="29"
                rx="1.71051"
                stroke="#FF0089"
                fill="none"
                strokeWidth="4"
                mask="url(#path-4-inside-1_11_9097)"
            />
            <rect x="2" y="5" width="4" height="8" fill="#FF0089" />
            <rect x="24" y="16" width="4" height="8" fill="#FF0089" />
            <circle cx="14.5" cy="14.5" r="2.5" fill="#FF0089" />
        </svg>
    );
}
