import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    Typography,
    useMediaQuery
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import OutlinedInput from '@mui/material/OutlinedInput';
import InfiniteScroll from 'react-infinite-scroller';
import { Roles } from '../../models/Roles';
import mediaLittleIcon from '../../assets/mediaLittleIcon.png';
import personalVideoIcon from '../../assets/personalVideoIcon.png';
import youtubeLittleIcon from '../../assets/youtubeLittleIcon.png';
import { CreateNewPhotoCard } from '../../components/CreateNewPhotoCard';
import { CentralLogo } from '../../components/Headers/CentralLogo';
import { UserContext } from '../../providers/UserProvider';
import {
    getExerciseItemsForMedia,
    getExerciseItemsForMediaAdmin,
    getPatientFromEstablishment,
    getPersonalVideo,
    getPersonalYoutubeVideo
} from '../../services/cloudFirestore';
import { ExerciseItem } from '../../models/ExerciseItem';
import { UserDataProp } from '../../models/userDataProp';
import { ChooseVideo } from './ChooseVideo';
import { ChooseImage } from './ChooseImage';
import { MediaInformation } from '../../models/MediaInformation';
import { VideoCards } from '../../components/VideoCards';
import { ThumbnailsCards } from '../../components/ThumbnailsCards';
import { AddYoutubeLink } from './AddYoutubeLink';
import { AlertModal } from '../../components/AlertModal';
import { CustomToolbar } from '../../components/CustomToolbar';
import { Patient } from '../../models/Patient';

const useStyles = makeStyles(() => ({
    title: {
        textAlign: 'left',
        fontWeight: 'bold',
        display: 'inline'
    },
    headerText: {
        '@media (max-width: 598px)': {
            margin: '5% 0'
        },
        '@media (min-width: 598px)': {
            margin: '3% 0'
        },
        display: 'flex'
    },
    headerLayoutMobile: {
        '@media (max-width: 598px)': {
            flexDirection: 'column'
        },
        '@media (min-width: 598px)': {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
        }
    },
    selectAuthor: {
        '& fieldset': { borderColor: '#F0F0F0 !important' },
        '@media (max-width: 598px)': {
            marginBottom: '2%'
        },
        '@media (min-width: 598px)': {
            marginLeft: 'auto'
        }
    },
    importCard: {
        padding: '1%',
        cursor: 'pointer'
    },
    deSelectedCard: {
        width: '100%',
        cursor: 'pointer',
        border: '1px solid #E3E6E8',
        boxShadow: '2px 2px 10px 0px #52525214',
        borderRadius: '9px'
    }
}));

const Logo = styled.img`
    height: 32px;
    width: 32px;
    margin-right: 10px;
    object-fit: contain;
`;

export function MediaContent(): JSX.Element {
    const isSmallWidth = !useMediaQuery('(min-width:598px)');
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const { t } = useTranslation();
    const classes = useStyles();
    const { type } = useParams<{ type: string }>();
    const [exercisesItemList, setExercisesItemList] = useState<ExerciseItem[]>([]);
    const [personalVideoList, setPersonalVideoList] = useState<MediaInformation[]>([]);
    const [youtubeItemList, setYoutubeItemList] = useState<MediaInformation[]>([]);
    const [clickedCard, setClickedCard] = useState<string | undefined>('');
    const [media, setMedia] = useState<File[]>();
    const [youtubePage, setYoutubePage] = useState<boolean>(false);
    const [modifyThumbnails, setModifyThumbnails] = useState<ExerciseItem | null>(null);
    const [openModalAlert, setOpenModalAlert] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<string>('Tous');
    const [exerciseList, setExerciseList] = useState<ExerciseItem[]>([]);
    const [patientList, setPatientsList] = useState<Patient[]>([]);
    const [patientSelect, setPatientSelect] = useState<string>('');
    const [selectOpen, setSelectOpen] = useState<boolean>(false);
    const [nbItemToDisplay, setNbItemToDisplay] = useState<number>(5);

    const themeDropdownList = [
        t('all'),
        t('art'),
        t('familyAndFriends'),
        t('youth'),
        t('premises'),
        t('nature'),
        t('dayToDay'),
        t('sciences'),
        t('sport')
    ];

    // Get all the patient of the therapist
    useEffect(() => {
        const getPatientOfTherapist = async (): Promise<void> => {
            let patients: Patient[] | null = [];
            if (user?.role === Roles.THERAPIST) {
                if (user && user.id && user.establishmentCode) {
                    patients = await getPatientFromEstablishment(user.establishmentCode);
                    if (patients) {
                        setPatientsList(patients);
                    }
                }
            }
        };
        if (user && user?.role === Roles.THERAPIST) {
            getPatientOfTherapist();
        }
    }, []);

    useEffect(() => {
        const getItemList = async (): Promise<void> => {
            if (user && user.id) {
                let idForSearch = '';
                if (!patientSelect || patientSelect === 'empty') {
                    if (user.role === Roles.FAMILY && user.familyCode) {
                        idForSearch = user.familyCode;
                    }
                    if (user.role === Roles.THERAPIST && user.establishmentCode) {
                        idForSearch = user.establishmentCode;
                    }
                    if (user.role === Roles.ADMIN && user.userId) {
                        idForSearch = user.userId;
                        const thumbnailsItemData = await getExerciseItemsForMediaAdmin();
                        setExercisesItemList(thumbnailsItemData);
                        setExerciseList(thumbnailsItemData);
                    }
                } else {
                    idForSearch = patientSelect;
                }
                if (idForSearch !== '') {
                    if (type === 'thumbnails' && user.role !== Roles.ADMIN) {
                        const thumbnailsItemData = await getExerciseItemsForMedia(idForSearch);
                        setExercisesItemList(thumbnailsItemData);
                        setExerciseList(thumbnailsItemData);
                    }
                    let secondIdForSearch = false;
                    if (
                        user.role === Roles.THERAPIST &&
                        patientSelect &&
                        patientSelect !== 'empty'
                    ) {
                        secondIdForSearch = true;
                    }
                    if (user.role === Roles.FAMILY) {
                        secondIdForSearch = true;
                    }
                    if (type === 'video') {
                        const videoItemData = await getPersonalVideo(
                            idForSearch,
                            secondIdForSearch === true ? user.establishmentCode : ''
                        );
                        if (videoItemData) {
                            const videoItem = videoItemData;
                            setPersonalVideoList(_.orderBy(videoItem, ['createdDate'], ['desc']));
                        } else {
                            setPersonalVideoList([]);
                        }
                    }
                    if (type === 'youtubeLink') {
                        const youtubeVideoItemData = await getPersonalYoutubeVideo(
                            idForSearch,
                            secondIdForSearch === true ? user.establishmentCode : ''
                        );
                        if (youtubeVideoItemData) {
                            const youtubeItem = youtubeVideoItemData;
                            setYoutubeItemList(_.orderBy(youtubeItem, ['createdDate'], ['desc']));
                        } else {
                            setYoutubeItemList([]);
                        }
                    }
                }
            }
        };

        setClickedCard('');
        getItemList();
    }, [media, modifyThumbnails, youtubePage, patientSelect]);

    useEffect(() => {
        if (type === 'thumbnails') {
            if (selectedItem === 'Tous') {
                setExerciseList(exercisesItemList);
            } else {
                const searchData = exercisesItemList.filter((element) => {
                    if (element.theme) {
                        return element.theme.toLowerCase().includes(selectedItem.toLowerCase());
                    }
                    return null;
                });
                setExerciseList(searchData);
            }
        }
    }, [selectedItem]);

    const NewPhotoCard = (): JSX.Element => {
        return (
            <Grid
                item
                xs
                sx={{
                    flexGrow: '0 !important',
                    display: 'block'
                }}>
                {type === 'thumbnails' && (
                    <CreateNewPhotoCard
                        setALert={setOpenModalAlert}
                        setMedia={setMedia}
                        bodyText={t('importThumbnails')}
                        type={type}
                    />
                )}
                {type === 'video' && (
                    <CreateNewPhotoCard
                        setALert={setOpenModalAlert}
                        setMedia={setMedia}
                        bodyText={t('importPersonalVideo')}
                        type={type}
                    />
                )}
                {type === 'youtubeLink' && (
                    <CreateNewPhotoCard
                        bodyText={t('importYoutubeLink')}
                        type={type}
                        isYoutubePage={(value: boolean): void => setYoutubePage(value)}
                    />
                )}
            </Grid>
        );
    };

    const getPatientList = (): JSX.Element[] | null => {
        if (patientList && patientList.length > 0) {
            return patientList.map((item) => {
                return (
                    <MenuItem
                        key={item.familyCode}
                        value={item.familyCode}>{`${item.firstName} ${item.lastName}`}</MenuItem>
                );
            });
        }
        return null;
    };

    let arrayLength = 0;
    if (type === 'thumbnails') arrayLength = exerciseList.length;
    if (type === 'video') arrayLength = personalVideoList.length;
    if (type === 'youtubeLink') arrayLength = youtubeItemList.length;

    const loadFuncItem = (): void => {
        if (arrayLength && nbItemToDisplay + 4 <= arrayLength) {
            setNbItemToDisplay(nbItemToDisplay + 4);
        } else {
            setNbItemToDisplay(arrayLength);
        }
    };

    const getExercise = (): JSX.Element[] | null => {
        if (type === 'thumbnails' && exerciseList.length > 0) {
            return exerciseList.slice(0, nbItemToDisplay).map((item, index) => {
                let bgColor;
                const number = index % 4;
                switch (number) {
                    case 0:
                        bgColor = '#FF008980';
                        break;
                    case 1:
                        bgColor = '#3DECCF80';
                        break;
                    case 2:
                        bgColor = '#458ff680';
                        break;
                    case 3:
                        bgColor = '#6B00AD80';
                        break;
                    default:
                        break;
                }
                return (
                    <Grid
                        item
                        xs
                        sx={{
                            flexGrow: '0 !important',
                            display: 'block'
                        }}
                        key={item.id}>
                        <ThumbnailsCards
                            onCardClicked={(value: string): void => setClickedCard(value)}
                            onLeaveCard={(): void => setClickedCard('')}
                            item={item}
                            bgColor={bgColor}
                            isSelected={clickedCard === item.id}
                            setModifyThumbnails={(value: ExerciseItem): void =>
                                setModifyThumbnails(value)
                            }
                            classReceived={classes.deSelectedCard}
                            activePP
                        />
                    </Grid>
                );
            });
        }
        if (type === 'video' && personalVideoList.length > 0) {
            // Add new element into the array cause Apple device doesn't make appear the first video
            // if (personalVideoList[0].title !== 'no') {
            //     personalVideoList.unshift({
            //         createdDate: personalVideoList[0].createdDate,
            //         title: 'no',
            //         videoUrl: 'no'
            //     });
            // }
            return personalVideoList.slice(0, nbItemToDisplay).map((item, index) => {
                let bgColor;
                const number = index % 4;
                switch (number) {
                    case 0:
                        bgColor = '#FF008980';
                        break;
                    case 1:
                        bgColor = '#3DECCF80';
                        break;
                    case 2:
                        bgColor = '#458ff680';
                        break;
                    case 3:
                        bgColor = '#6B00AD80';
                        break;
                    default:
                        break;
                }
                return (
                    <Grid
                        item
                        xs
                        sx={{
                            flexGrow: '0 !important',
                            display: 'block'
                        }}
                        key={item.title}>
                        <VideoCards
                            onCardClicked={(value: string): void => setClickedCard(value)}
                            onLeaveCard={(): void => setClickedCard('')}
                            crudCard
                            item={item}
                            bgColor={bgColor}
                            isSelected={clickedCard === item.title}
                            activePP
                            littlePlayBtn
                            classReceived={classes.deSelectedCard}
                        />
                    </Grid>
                );
            });
        }
        if (type === 'youtubeLink' && youtubeItemList.length > 0) {
            return youtubeItemList.slice(0, nbItemToDisplay).map((item, index) => {
                let bgColor;
                const number = index % 4;
                switch (number) {
                    case 0:
                        bgColor = '#FF008980';
                        break;
                    case 1:
                        bgColor = '#3DECCF80';
                        break;
                    case 2:
                        bgColor = '#458ff680';
                        break;
                    case 3:
                        bgColor = '#6B00AD80';
                        break;
                    default:
                        break;
                }
                return (
                    <Grid
                        item
                        xs
                        sx={{
                            flexGrow: '0 !important',
                            display: 'block'
                        }}
                        key={item.title}>
                        <VideoCards
                            onCardClicked={(value: string): void => setClickedCard(value)}
                            onLeaveCard={(): void => setClickedCard('')}
                            crudCard
                            item={item}
                            bgColor={bgColor}
                            isSelected={clickedCard === item.title}
                            youtubePage
                            activePP
                            littlePlayBtn
                        />
                    </Grid>
                );
            });
        }
        return null;
    };

    const getHeader = (): JSX.Element => {
        if (user && user.role === Roles.ADMIN) {
            return <CentralLogo hasBackButton hasMediathequeLogo />;
        }
        return <CentralLogo hasBackButton hasMediathequeLogo hasProfilePhoto />;
    };

    const titleAndIntroduction = (): JSX.Element | null => {
        let text;
        let logo;
        if (type === 'thumbnails') {
            text = t('myPicture');
            logo = mediaLittleIcon;
        } else if (type === 'video') {
            text = t('myPersonalVideo');
            logo = personalVideoIcon;
        } else {
            text = t('myYoutubeLink');
            logo = youtubeLittleIcon;
        }
        if (type && user?.role === Roles.THERAPIST) {
            return (
                <div style={{ width: '100%', margin: 'auto' }}>
                    <div
                        className={classes.headerLayoutMobile}
                        style={{
                            display: 'flex',
                            margin: !isSmallWidth ? '0% 2%' : '0% 5%'
                        }}>
                        {!isSmallWidth && (
                            <FormControl
                                sx={{ minWidth: 200, visibility: 'hidden', marginRight: 'auto' }}>
                                <InputLabel id="demo-multiple-name-label">
                                    {t('authorPicture')}
                                </InputLabel>
                                <Select
                                    labelId="patient-select-label"
                                    id="patient-select"
                                    value={patientSelect}
                                    input={<OutlinedInput label="Auteur" />}
                                    onChange={(event): void => {
                                        setPatientSelect(event.target.value);
                                    }}>
                                    <MenuItem key="empty" value="empty">
                                        {t('myPicture')}
                                    </MenuItem>
                                    {getPatientList()}
                                </Select>
                            </FormControl>
                        )}
                        <div className={classes.headerText}>
                            <Logo src={logo} alt="logo" />
                            <Typography
                                variant="h6"
                                color="#374653"
                                sx={{ fontWeight: 'bold', marginTop: '3px', color: '#6b01ad' }}>
                                {text}
                            </Typography>
                        </div>
                        <FormControl
                            className={classes.selectAuthor}
                            sx={{ minWidth: isSmallWidth ? 100 : 200 }}>
                            <InputLabel id="demo-multiple-name-label">
                                {t('authorPicture')}
                            </InputLabel>
                            <Select
                                sx={{
                                    borderRadius: '20px',
                                    backgroundColor: selectOpen ? '#3387CC1A' : '#F0F0F0'
                                }}
                                labelId="patient-select-label"
                                id="patient-select"
                                value={patientSelect}
                                input={<OutlinedInput label="Auteur" />}
                                onOpen={(): void => {
                                    setSelectOpen(!selectOpen);
                                }}
                                onClose={(): void => {
                                    setSelectOpen(!selectOpen);
                                }}
                                onChange={(event): void => {
                                    setPatientSelect(event.target.value);
                                    setSelectedItem('Tous');
                                }}>
                                <MenuItem key="empty" value="empty">
                                    {t('myPicture')}
                                </MenuItem>
                                {getPatientList()}
                            </Select>
                        </FormControl>
                    </div>
                </div>
            );
        }
        return (
            <div style={{ width: '100%', margin: 'auto' }}>
                <div
                    style={{
                        display: 'flex',
                        margin: '0% 2%',
                        justifyContent: 'center'
                    }}>
                    <div className={classes.headerText}>
                        <Logo src={logo} alt="logo" />
                        <Typography
                            variant="h6"
                            color="#374653"
                            sx={{ fontWeight: 'bold', marginTop: '3px', color: '#6b01ad' }}>
                            {text}
                        </Typography>
                    </div>
                </div>
            </div>
        );
    };

    const mediaContentScreen = (): JSX.Element => {
        return (
            <div>
                <Box sx={{ boxShadow: 'inset 0px -1px 0px #E3E6E8' }}>{getHeader()}</Box>
                <Box
                    sx={{
                        backgroundColor: '#F8F9F9',
                        boxShadow: 'inset 0px -1px 0px rgba(172, 188, 199, 0.4)',
                        marginBottom: type === 'thumbnails' ? '0' : '2%'
                    }}>
                    {titleAndIntroduction()}
                </Box>
                {type === 'thumbnails' && (
                    <CustomToolbar
                        items={themeDropdownList}
                        onSelectChange={(value: string): void => setSelectedItem(value)}
                    />
                )}
                <Box
                    sx={{
                        marginTop: '10px',
                        marginLeft: window.innerWidth < 520 ? '5px' : '20px',
                        marginRight: window.innerWidth < 520 ? '5px' : '20px'
                    }}>
                    <InfiniteScroll
                        pageStart={1}
                        loadMore={(): void => {
                            if (arrayLength !== 0) loadFuncItem();
                        }}
                        hasMore={true || false}
                        loader={
                            <div className="loader" key={0}>
                                {nbItemToDisplay !== arrayLength ? t('loading') : ''}
                            </div>
                        }>
                        <Grid
                            container
                            // sx={{ width: '100%', margin: 'auto' }}
                            rowSpacing={2}
                            columnSpacing={window.innerWidth < 520 ? 2 : 5}
                            sx={{ justifyContent: 'center' }}>
                            {NewPhotoCard()}
                            {/* HERE */}
                            {getExercise()}
                            {arrayLength === 0 &&
                                Array.from(Array(nbItemToDisplay).keys()).map((item) => {
                                    let bgColor;
                                    const number = item % 4;
                                    switch (number) {
                                        case 0:
                                            bgColor = '#FF008980';
                                            break;
                                        case 1:
                                            bgColor = '#3DECCF80';
                                            break;
                                        case 2:
                                            bgColor = '#458ff680';
                                            break;
                                        case 3:
                                            bgColor = '#6B00AD80';
                                            break;
                                        default:
                                            break;
                                    }

                                    return (
                                        <Grid
                                            item
                                            xs
                                            sx={{
                                                flexGrow: '0 !important',
                                                display: 'block'
                                            }}>
                                            <Box
                                                sx={{
                                                    height:
                                                        window.innerWidth < 520
                                                            ? 'calc(100vw / 2 + 20px)'
                                                            : '290px',
                                                    width:
                                                        window.innerWidth < 520
                                                            ? 'calc(100vw / 2 - 20px)'
                                                            : '220px',
                                                    borderRadius: '9px',
                                                    padding: '10px',
                                                    boxShadow:
                                                        '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
                                                }}>
                                                <Skeleton
                                                    variant="rectangular"
                                                    style={{
                                                        backgroundColor: bgColor,
                                                        borderRadius: '9px',
                                                        width: '100%',
                                                        height: '100%'
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                    );
                                })}
                        </Grid>
                    </InfiniteScroll>
                </Box>
            </div>
        );
    };

    const screenShow = (): JSX.Element => {
        if (modifyThumbnails && type === 'thumbnails') {
            return (
                <ChooseImage
                    setModifyThumbnails={setModifyThumbnails}
                    contentReceived={modifyThumbnails}
                />
            );
        }
        if (media && type === 'thumbnails') {
            return <ChooseImage selectedImage={media} setMedia={setMedia} />;
        }
        if (media && type === 'video') {
            return <ChooseVideo videoFile={media} setMedia={setMedia} />;
        }
        if (youtubePage && type === 'youtubeLink') {
            return <AddYoutubeLink isYoutubeLink={setYoutubePage} />;
        }
        return mediaContentScreen();
    };

    return (
        <div>
            {screenShow()}
            <AlertModal
                openModalAlert={openModalAlert}
                setOpenModalAlert={setOpenModalAlert}
                setMedia={setMedia}
                thumbnails={type === 'thumbnails'}
            />
        </div>
    );
}
