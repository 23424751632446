/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import closeIcon from '../../assets/closeIcon.png';
import vectorIcon from '../../assets/vectorIcon.png';
import { ExerciseType } from '../../models/ExerciseType';
import { CustomStepper } from '../CustomStepper';
import { Roles } from '../../models/Roles';
import { User } from '../../models/User';

const useStyles = makeStyles(() => ({
    tabletStepper: {
        '@media (max-width: 598px)': {
            display: 'none'
        },
        '@media (min-width: 598px)': {
            display: 'inline'
        }
    },
    mobileStepper: {
        '@media (max-width: 598px)': {
            display: 'inline'
        },
        '@media (min-width: 598px)': {
            display: 'none'
        }
    },
    headerText: {
        '@media (max-width: 598px)': {
            width: '85%'
        },
        '@media (min-width: 598px)': {
            width: '75%'
        }
    }
}));

const VectorLogo = styled.img`
    height: 10px;
    margin: 0% 2%;
`;

const LogoClose = styled.img`
    height: 30px;
    background-color: #fbfbfb;
    border: 1px solid #e3e6e8;
    padding: 6px;
    border-radius: 5px;
    cursor: pointer;
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    padding: 20px 0px;
    background-color: #ffffff;
    box-shadow: inset 0px -1px 0px #e3e6e8;
`;

const CloseLogoContainer = styled.div`
    @media (max-width: 598px) {
        width: 10%;
    }
    @media (min-width: 599px) {
        width: 20%;
        padding: 0px 10px;
    }
`;

const FamilySteps = [1, 2, 3, 4, 5];

const TherapistSteps = [1, 2, 3, 4, 5, 6];

interface HeaderProps {
    setOpenModal: Function;
    type?: ExerciseType;
    activeStep: number;
    user: User | null | undefined;
}

export function Header({ setOpenModal, type, activeStep, user }: HeaderProps): JSX.Element {
    const isSmallWidth = !useMediaQuery('(min-width:598px)');
    const classes = useStyles();
    const { t } = useTranslation();

    useEffect(() => {
        const topOfPage = document.getElementById('top');
        if (topOfPage) {
            topOfPage.scrollIntoView();
        }
    }, []);

    return (
        <HeaderContainer
            id="top"
            style={{
                display: 'flex',
                justifyContent: 'space-around',
                flexDirection: 'row',
                padding: '20px 0px',
                backgroundColor: '#ffffff',
                boxShadow: 'inset 0px -1px 0px #E3E6E8',
                height: '78px'
            }}>
            <CloseLogoContainer style={{ textAlign: 'left' }} className={classes.tabletStepper}>
                <LogoClose
                    onClick={(): void => setOpenModal(true)}
                    src={closeIcon}
                    alt="CloseButton"
                />
            </CloseLogoContainer>
            <div
                className={classes.headerText}
                style={{
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    textAlign: 'center'
                }}>
                {!isSmallWidth && <VectorLogo src={vectorIcon} alt="Vector Icon" />}
                <Typography
                    variant="h5"
                    style={{ color: '#5C6670', display: 'inline', fontWeight: 500 }}>
                    {type && type === ExerciseType.MEMORY
                        ? t('souvenIn')
                        : type && type === ExerciseType.MEMORIN
                        ? t('memorin')
                        : t('puzzlin')}
                </Typography>
                <Typography
                    variant="h5"
                    style={{
                        color: '#A2A2A2',
                        display: 'inline',
                        fontWeight: 'normal'
                    }}>
                    {isSmallWidth ? t('customizationIn') : t('inProcessOfCustomisation')}
                </Typography>
                {!isSmallWidth && <VectorLogo src={vectorIcon} alt="Vector Icon" />}
            </div>
            <CloseLogoContainer
                style={{ textAlign: 'left' }}
                className={classes.mobileStepper}
                onClick={(): void => setOpenModal(true)}>
                <LogoClose src={closeIcon} alt="CloseButton" />
            </CloseLogoContainer>
            <div
                style={{
                    width: '20%',
                    float: 'right',
                    padding: '7px 10px'
                }}
                className={classes.tabletStepper}>
                <CustomStepper
                    currentStep={
                        user && user.role === Roles.FAMILY && activeStep >= 4
                            ? activeStep - 1
                            : activeStep
                    }
                    steps={user && user.role === Roles.FAMILY ? FamilySteps : TherapistSteps}
                />
            </div>
        </HeaderContainer>
    );
}
