import {
    Box,
    LinearProgress,
    linearProgressClasses,
    Paper,
    styled,
    Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import {
    getAllUserCount,
    getAllUserCountPerMonth,
    getFamilyList,
    getPatientList,
    getThearpistList
} from '../../services/cloudFirestore';
import { getDateFromTimestamp } from '../../utilities/utils';

const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 8,
    backgroundColor: 'transparent'
}));

export function UsersGraph(): React.ReactElement {
    const { t } = useTranslation();
    const [totalUsers, setTotalUsers] = useState<number>(0);
    const [totalPatients, setTotalPatients] = useState<number>(0);
    const [totalTherapists, setTotalTherapists] = useState<number>(0);
    const [totalFamilies, setTotalFamilies] = useState<number>(0);
    const [familyListCount, setFamilyListCount] = useState<number[]>([]);
    const [patientListCount, setPatientListCount] = useState<number[]>([]);
    const [therapistListCount, setTherapistListCount] = useState<number[]>([]);
    const [startYear, setStartYear] = useState<string>('');

    useEffect(() => {
        const getUserList = async (): Promise<void> => {
            const data = await getAllUserCount();
            setTotalTherapists(data[0]);
            setTotalFamilies(data[1]);
            setTotalPatients(data[2]);
            setTotalUsers(data[0] + data[1] + data[2]);
            const count = await getAllUserCountPerMonth();
            setFamilyListCount(count.familyListCount);
            setPatientListCount(count.patientListCount);
            setTherapistListCount(count.therapistListCount);
            const familyList = await getFamilyList();
            const therapistList = await getThearpistList();
            const patientList = await getPatientList();
            const usersList = [];
            usersList.push(...familyList);
            usersList.push(...therapistList);
            usersList.push(...patientList);
            usersList.sort((a, b) => {
                const keyA = a.createdDate?.seconds;
                const keyB = b.createdDate?.seconds;
                if (keyA && keyB && keyA < keyB) return -1;
                if (keyA && keyB && keyA > keyB) return 1;
                return 0;
            });
            if (usersList.length > 0) {
                const findYear = usersList[usersList.length - 1].createdDate;
                if (findYear) {
                    const firstDate = getDateFromTimestamp(findYear).toString();
                    setStartYear(firstDate.substring(firstDate.length - 4));
                }
            }
        };
        getUserList();
    }, []);

    const CHART_DATA = [
        {
            name: 'Patients',
            type: 'line',
            data: patientListCount
        },
        {
            name: 'Thérapeutes',
            type: 'line',
            data: therapistListCount
        },
        {
            name: 'Familles',
            type: 'line',
            data: familyListCount
        }
    ];

    const chartOptions: ApexCharts.ApexOptions = {
        colors: ['#D00070', '#69DBC8', '#002E5D'],
        chart: {
            locales: [
                {
                    name: 'fr',
                    options: {
                        months: [
                            'Janvier',
                            'Février',
                            'Mars',
                            'Avril',
                            'Mai',
                            'Juin',
                            'Juillet',
                            'Aout',
                            'Septembre',
                            'Octobre',
                            'Novembre',
                            'Décembre'
                        ],
                        shortMonths: [
                            'JAN',
                            'FEV',
                            'MAR',
                            'AVR',
                            'MAI',
                            'JUIN',
                            'JUIL',
                            'AOUT',
                            'SEPT',
                            'OCT',
                            'NOV',
                            'DEV'
                        ]
                    }
                }
            ],
            defaultLocale: 'fr',
            toolbar: { show: false },
            zoom: { enabled: false },
            animations: { enabled: false }
        },
        dataLabels: { enabled: false },
        labels: [
            // `1/01/${startYear}`,
            `01/01/${startYear + 1}`,
            `02/01/${startYear + 1}`,
            `03/01/${startYear + 1}`,
            `04/01/${startYear + 1}`,
            `05/01/${startYear + 1}`,
            `06/01/${startYear + 1}`,
            `07/01/${startYear + 1}`,
            `08/01/${startYear + 1}`,
            `09/01/${startYear + 1}`,
            `10/01/${startYear + 1}`,
            `11/01/${startYear + 1}`,
            `12/01/${startYear + 1}`
        ],
        markers: { size: 5 },
        legend: { show: false },
        xaxis: { type: 'datetime' },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: (y: number) => {
                    if (typeof y !== 'undefined') {
                        return `${y.toFixed(0)} users`;
                    }
                    return y;
                }
            }
        }
    };

    const progress = (value: number, total: number, color: string): React.ReactElement => {
        return (
            <BorderLinearProgress
                variant="determinate"
                value={(value / total) * 100}
                sx={{
                    mb: 1,
                    [`& .${linearProgressClasses.bar}`]: {
                        backgroundColor: color
                    }
                }}
            />
        );
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexGrow: 1,
                flexWrap: 'wrap',
                textAlign: 'left',

                '& > :not(style)': {
                    m: 1,
                    height: 235
                }
            }}>
            <Paper elevation={1} sx={{ padding: '13px', width: '100%' }}>
                <Box display="flex">
                    <Box display="flex" flexDirection="column" mx={1} width={180}>
                        <Typography variant="subtitle1">{t('totalUsers')}</Typography>
                        <Typography variant="h1">{totalUsers}</Typography>
                        <Typography variant="subtitle2">
                            {t('totalPatients', { count: totalPatients })}
                        </Typography>
                        {progress(totalPatients, totalUsers, '#D00070')}
                        <Typography variant="subtitle2">
                            {t('totalTherapists', { count: totalTherapists })}
                        </Typography>
                        {progress(totalTherapists, totalUsers, '#69DBC8')}
                        <Typography variant="subtitle2">
                            {t('totalFamilies', { count: totalFamilies })}
                        </Typography>
                        {progress(totalFamilies, totalUsers, '#002E5D')}
                    </Box>
                    <Box mx={1} flexGrow={1}>
                        <ReactApexChart
                            type="line"
                            series={CHART_DATA}
                            options={chartOptions}
                            width="100%"
                            height={200}
                        />
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
}
