import React, { useContext, useEffect, useState } from 'react';
import { Box, FormControl, MenuItem, Select, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Roles } from '../../models/Roles';
import { ExerciseItem } from '../../models/ExerciseItem';
import { DatePickerCustom } from '../DatePickerCustom';
import { SelectComponent } from '../SelectComponent';
import { InputComponent } from '../FormComponents/InputComponent';
import { Header } from './Header';
import { UserContext } from '../../providers/UserProvider';
import { UserDataProp } from '../../models/userDataProp';

const SelectedImage = styled.img`
    object-fit: contain;
    min-width: 95%;
    max-width: 100%;
    min-height: 95%;
    max-height: 50vh;
    margin: auto;
`;

const useStyles = makeStyles(() => ({
    input: {
        marginBottom: '12px',
        color: '#004680 !important'
    },
    selectPlaceholder: {
        width: '100%',
        marginTop: '0px',
        color: '#A1A1A1'
    },
    fontBlue: {
        '& .MuiOutlinedInput-input': {
            color: '#004680 !important'
        }
    }
}));

interface FillDetailsProps {
    selectImage: string;
    setEnabled: Function;
    content: ExerciseItem;
}

export function FillDetails({ selectImage, setEnabled, content }: FillDetailsProps): JSX.Element {
    const { t } = useTranslation();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const themeDropdownList = [
        t('art'),
        t('familyAndFriends'),
        t('youth'),
        t('premises'),
        t('nature'),
        t('dayToDay'),
        t('sciences'),
        t('sport')
    ];

    const classes = useStyles();
    const isSmallWidth = !useMediaQuery('(min-width:500px)');
    const [data, setData] = useState<ExerciseItem>({
        id: undefined,
        title: undefined,
        answer: undefined,
        exerciseImage: undefined,
        imageDetails: [],
        photoCapturedPlace: undefined,
        photoCapturedOn: undefined,
        anecdote: undefined,
        theme: undefined
    });

    useEffect(() => {
        if (content) {
            setData({
                id: content.id,
                title: content.title,
                answer: content.answer,
                exerciseImage: content.exerciseImage,
                imageDetails: content.imageDetails,
                photoCapturedPlace: content.photoCapturedPlace,
                photoCapturedOn: content.photoCapturedOn,
                anecdote: content.anecdote,
                theme: content.theme
            });
        }
    }, [content]);

    const handleChange = (state: string, value: string | File | Date | boolean | null): void => {
        setData({ ...data, [state]: value });
    };

    useEffect(() => {
        if (data.answer) {
            if (
                data.photoCapturedPlace === content.photoCapturedPlace &&
                data.photoCapturedOn === content.photoCapturedOn &&
                data.theme === content.theme &&
                data.anecdote === content.anecdote &&
                data.answer === content.answer
            ) {
                setEnabled(true, null);
            } else {
                setEnabled(true, data);
            }
        } else {
            setEnabled(false, data);
        }
    }, [data]);

    return (
        <Box height="100%">
            <Header
                text={
                    user?.role === Roles.THERAPIST
                        ? t('discussInfoRequiredTab')
                        : t('discussInfoRequired')
                }
            />
            <Box
                sx={{ marginTop: isSmallWidth ? '10px' : '40px' }}
                display="flex"
                flexDirection={isSmallWidth ? 'column' : 'row'}
                justifyContent="center"
                alignContent="flex-start"
                alignItems="center">
                <Box
                    sx={{
                        width: isSmallWidth ? '90vw' : '45vw'
                    }}>
                    <SelectedImage src={selectImage} alt="CloseButton" />
                </Box>
                <Box
                    sx={{
                        width: isSmallWidth ? '100vw' : '44vw',
                        padding: isSmallWidth ? '15px' : '8px',
                        marginLeft: isSmallWidth ? '' : '50px'
                    }}>
                    <Typography variant="subtitle1" color="#374653" sx={{ textAlign: 'left' }}>
                        {t('shortTitle')} <span style={{ color: 'red' }}>{t('required')}</span> :
                    </Typography>
                    <Typography variant="body2" color="#374653" sx={{ textAlign: 'left' }}>
                        {t('max30Characters')}
                    </Typography>
                    <InputComponent
                        placeholder={t('example')}
                        value={data.answer ?? null}
                        maximumLength={30}
                        type="text"
                        className={classes.input}
                        isMultiline={false}
                        onTextChange={(value: string): void => handleChange('answer', value)}
                    />

                    <Typography
                        variant="body1"
                        color="#374653"
                        sx={{ textAlign: 'left', marginTop: isSmallWidth ? '12px' : '' }}>
                        {t('themeToPhoto')} :
                    </Typography>
                    <Box style={{ height: '56px', marginBottom: '12px' }}>
                        <SelectComponent
                            dropdownList={themeDropdownList}
                            placeholder={t('selectTheme')}
                            onTextChange={(value: string): void => handleChange('theme', value)}
                            value={data.theme ?? null}
                            classCustom={classes.input}
                            placeholderStyling={classes.selectPlaceholder}
                        />
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        sx={{ flexDirection: isSmallWidth ? 'column' : 'row' }}>
                        <Box style={{ width: isSmallWidth ? '100%' : '49%' }}>
                            <Typography variant="body1" color="#374653" sx={{ textAlign: 'left' }}>
                                {t('when')} :
                            </Typography>
                            <Box>
                                <DatePickerCustom
                                    sx={{ width: '100%' }}
                                    value={data.photoCapturedOn ?? null}
                                    onDateChange={(newValue: Date): void => {
                                        handleChange('photoCapturedOn', newValue?.toDateString());
                                    }}
                                    classReceived={
                                        data.photoCapturedOn !== null ? classes.fontBlue : ''
                                    }
                                />
                            </Box>
                        </Box>
                        <Box style={{ width: isSmallWidth ? '100%' : '49%' }}>
                            <Typography variant="body1" color="#374653" sx={{ textAlign: 'left' }}>
                                {t('wherePhotoTaken')} :
                            </Typography>
                            <InputComponent
                                placeholder={t('place')}
                                value={data.photoCapturedPlace ?? null}
                                type="text"
                                className={classes.input}
                                isMultiline={false}
                                onTextChange={(value: string): void =>
                                    handleChange('photoCapturedPlace', value)
                                }
                            />
                        </Box>
                    </Box>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: '12px'
                        }}>
                        <Typography variant="body1" color="#374653" sx={{ textAlign: 'left' }}>
                            {t('anecdoteToPhoto')} :
                        </Typography>
                        <Typography variant="body2" color="#ACBCC7" sx={{ textAlign: 'left' }}>
                            {t('max400Characters')}
                        </Typography>
                    </div>
                    <InputComponent
                        placeholder={t('additionalInfo')}
                        value={data.anecdote ?? null}
                        type="text"
                        className={classes.input}
                        isMultiline
                        noOfRows={4}
                        maximumLength={400}
                        onTextChange={(value: string): void => handleChange('anecdote', value)}
                    />
                    <Typography
                        style={{
                            color: '#3746539C',
                            marginBottom: '12px',
                            textAlign: 'left',
                            fontSize: '0.85rem'
                        }}>
                        <span style={{ color: 'red' }}>{t('required')}</span> {t('champIsrequired')}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}
