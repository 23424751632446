import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminFormLayout } from '../../layouts/AdminFormLayout';
import { Patient } from '../../models/Patient';
import addPatient from '../../assets/addPatient.png';
import { getPatientFamilyCode } from '../../utilities/utils';

export function AddPatient(): React.ReactElement {
    const { t } = useTranslation();
    return (
        <AdminFormLayout
            subtitleDescription={t('addFormPatientDescription')}
            data={new Patient()}
            backgroundImage={addPatient}
            columnSize="715px"
            formFor="patient"
            generatedFamilyCode={getPatientFamilyCode()}
        />
    );
}
