import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CsvDownloader from 'react-csv-downloader';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { DashboardLayout } from '../../layouts/DashboardLayout';
import { EstablishmentCount } from '../../components/Admin/EstablishmentCount';
import { UsersGraph } from '../../components/Admin/UsersGraph';
import { EstablishmentList } from '../../components/Admin/EstablishmentList';
import { ExerciseAssessment } from '../../components/Admin/ExerciseAssessment';
import { PersonalisedExercises } from '../../components/Admin/PersonalisedExercises';
import { CompletedExercises } from '../../components/Admin/CompletedExercises';
import { MedianExercise } from '../../components/Admin/MedianExercise';
import {
    getEstablishmentList,
    getEstablishmentsExportData,
    getPatientListExercisesDetails
} from '../../services/cloudFirestore';
import { EstablishmentsExportData } from '../../models/EstablishmentsExportData';
import { exportCsvData } from '../../utilities/utils';

const useStyles = makeStyles(() => ({
    exportButton: {
        backgroundColor: '#D00070',
        height: '48px',
        width: '159px',
        borderRadius: '8px',
        color: '#FFFFFF',
        fontSize: '14px',
        fontWeight: 700,
        border: 'none',
        cursor: 'pointer'
    }
}));

export function Dashboard(): JSX.Element {
    const classes = useStyles();
    const { t } = useTranslation();
    const [totalEstablishmentUsers, setTotalEstablishmentUsers] = useState<number>(0);
    const [avgMemoryTime, setAvgMemoryTime] = useState<number>(0);
    const [avgMemorinTime, setAvgMemorinTime] = useState<number>(0);
    const [avgPuzzleTime, setAvgPuzzleTime] = useState<number>(0);
    const [completeExerciseCount, setCompleteExerciseCount] = useState<number>(0);
    const [incompleteExerciseCount, setIncompleteExerciseCount] = useState<number>(0);
    const [maxMemoryTime, setMaxMemoryTime] = useState<number>(0);
    const [maxMemorinTime, setMaxMemorinTime] = useState<number>(0);
    const [maxPuzzleTime, setMaxPuzzleTime] = useState<number>(0);
    const [memoryCount, setMemoryCount] = useState<number>(0);
    const [minMemoryTime, setMinMemoryTime] = useState<number>(0);
    const [minMemorinTime, setMinMemorinTime] = useState<number>(0);
    const [minPuzzleTime, setMinPuzzleTime] = useState<number>(0);
    const [puzzleCount, setPuzzleCount] = useState<number>(0);
    const [establishmentExportData, setEstablishmentExportData] = useState<
        EstablishmentsExportData[]
    >([]);

    useEffect(() => {
        const getUserList = async (): Promise<void> => {
            const data = await getEstablishmentList();
            setTotalEstablishmentUsers(data.length);
            const graphData = await getPatientListExercisesDetails();
            setAvgMemoryTime(graphData.avgMemoryTime);
            setAvgPuzzleTime(graphData.avgPuzzleTime);
            setCompleteExerciseCount(graphData.completeExerciseCount);
            setIncompleteExerciseCount(graphData.startExerciseCount);
            setMaxMemoryTime(graphData.maxMemoryTime);
            setMaxPuzzleTime(graphData.maxPuzzleTime);
            setMemoryCount(graphData.memoryCount);
            setMinMemoryTime(graphData.minMemoryTime);
            setMinPuzzleTime(graphData.minPuzzleTime);
            setPuzzleCount(graphData.puzzleCount);
            const exportData = await getEstablishmentsExportData();
            setEstablishmentExportData(exportData);
        };
        getUserList();
    }, []);

    return (
        <DashboardLayout>
            <Box display="flex" p={2} justifyContent="space-between">
                <Typography variant="h2">{t('dashboard')}</Typography>
                <CsvDownloader
                    className={classes.exportButton}
                    datas={exportCsvData(establishmentExportData)}
                    filename="establishmentData"
                    extension=".csv"
                    separator=";"
                    wrapColumnChar=""
                    text={t('exportButton')}
                />
            </Box>
            <Box display="flex">
                <EstablishmentCount count={totalEstablishmentUsers} />
                <UsersGraph />
            </Box>
            <Box display="flex" m={1} alignItems="flex-end">
                <EstablishmentList rows={[]} />
                <ExerciseAssessment heading="exerciseAssessment" />
            </Box>
            <Box display="flex">
                <Box
                    width="100%"
                    height="514px"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between">
                    <PersonalisedExercises />
                </Box>
                <Box>
                    <CompletedExercises />
                    <MedianExercise
                        avgMemoryTime={avgMemoryTime}
                        minMemoryTime={minMemoryTime}
                        maxMemoryTime={maxMemoryTime}
                        avgMemorinTime={avgMemorinTime}
                        minMemorinTime={minMemorinTime}
                        maxMemorinTime={maxMemorinTime}
                        avgPuzzleTime={avgPuzzleTime}
                        minPuzzleTime={minPuzzleTime}
                        maxPuzzleTime={maxPuzzleTime}
                    />
                </Box>
            </Box>
        </DashboardLayout>
    );
}
