/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import ModalUnstyled from '@mui/core/ModalUnstyled';
import Typography from '@mui/material/Typography';
import { lightBlue } from '@mui/material/colors';
import muscleIcon from '../assets/muscle.png';
import choiceIcon from '../assets/choice.png';
import clapIcon from '../assets/Clap.png';
import pompomgirl from '../assets/pompomgirl.png';
import coupe from '../assets/coupe.png';
import choisir from '../assets/choisir.png';
import strongWoman from '../assets/strongWoman.png';
import applaudissements from '../assets/applaudissements.png';
import finger from '../assets/finger.png';
import successCupIcon from '../assets/successCup.png';
import confettiIcon from '../assets/confetti.png';
import { Fireworks } from './confetti/firework';

interface SuccessModalProps {
    isModelOpen: boolean;
    setModelOpen: Function;
    isSuccess: boolean;
    nbModal: number;
    isFirework?: boolean;
    isPlaySound?: boolean;
    isReorder?: boolean;
}

const Logo = styled.img`
        height: 27px;
        margin-right: 20px;
        object-fit: inherit;
        width: 60px;
        height: 60px;
        alignSelf: 'center',
        justifySelf: 'center'
    `;

const StyledModal = styled(ModalUnstyled)`
    position: fixed;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Backdrop = styled('div')`
    z-index: -1;
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
`;

const style = {
    bgcolor: 'background.paper',
    borderRadius: '10%/50%',
    padding: '45px',
    '@media (max-width: 1366px)': {
        minWidth: '65%'
    },
    '@media (max-width: 1024px)': {
        minWidth: '70%'
    },
    outline: 'none'
};

export function SuccessModal({
    isModelOpen,
    setModelOpen,
    isSuccess,
    nbModal = 0,
    isFirework,
    isPlaySound,
    isReorder
}: SuccessModalProps): JSX.Element {
    const { t } = useTranslation();

    const isWidthOfXS = (): boolean => {
        const { innerWidth: width, innerHeight: height } = window;
        if (width < 1000 || height < 800) {
            return true;
        }
        return false;
    };

    const isIOSDevice = (): boolean => {
        return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    };

    const body = (
        <Box sx={style}>
            {isPlaySound && (
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <audio autoPlay>
                    <source src="/applause-sound-effect.mp3" type="audio/mp3" />
                </audio>
            )}
            {isFirework && (
                <div
                    style={{
                        zIndex: '-1',
                        position: 'fixed',
                        right: '0',
                        bottom: '0',
                        top: '0',
                        left: '0'
                    }}>
                    <Fireworks play />
                </div>
            )}
            {isSuccess && nbModal === 0 ? ( // Normal Success
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                    <Logo
                        src={pompomgirl}
                        alt="pompomgirl"
                        style={{ height: '220px', width: '220px' }}
                    />
                    <Typography
                        variant="h1"
                        color={lightBlue[900]}
                        sx={{
                            marginRight: '20px',
                            marginLeft: '20px',
                            fontSize: isIOSDevice() ? '2rem !important' : '4rem !important',
                            textAlign: 'center'
                        }}>
                        {t('Bravo1')}
                        <br />
                        {t('Bravo2')}
                    </Typography>
                    <Logo src={coupe} alt="coupe" style={{ height: '200px', width: '200px' }} />
                </Box>
            ) : isSuccess && nbModal === 1 ? ( // First Success
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                    <Logo
                        src={strongWoman}
                        alt="pompomgirl"
                        style={{ height: '200px', width: '200px' }}
                    />
                    <Typography
                        variant="h1"
                        color={lightBlue[900]}
                        sx={{
                            marginRight: '20px',
                            marginLeft: '20px',
                            fontSize: isIOSDevice() ? '2rem !important' : '4rem !important',
                            textAlign: 'center'
                        }}>
                        {t('firstBravo1')}
                        <br />
                        {t('firstBravo2')}
                    </Typography>
                </Box>
            ) : isSuccess && nbModal === 2 ? ( // second Success
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                    <Logo
                        src={applaudissements}
                        alt="pompomgirl"
                        style={{ height: '200px', width: '200px' }}
                    />
                    <Typography
                        variant="h1"
                        color={lightBlue[900]}
                        sx={{
                            marginRight: '20px',
                            marginLeft: '20px',
                            fontSize: isIOSDevice() ? '2rem !important' : '4rem !important',
                            textAlign: 'center'
                        }}>
                        {t('secondBravo1')}
                        <br />
                        {t('secondBravo2')}
                    </Typography>
                    {/* <Logo src={coupe} alt="coupe" style={{ height: '200px', width: '200px' }} /> */}
                </Box>
            ) : !isSuccess && nbModal === 1 ? (
                // Not Success for Memor'in
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Box>
                        <Typography
                            variant="h2"
                            color={lightBlue[900]}
                            sx={{
                                marginRight: '10px',
                                // marginLeft: '10px',
                                fontSize: isIOSDevice() ? '2rem !important' : '4rem !important',
                                textAlign: 'center'
                            }}>
                            {t('chooseAnotherOptionMemorin1')}
                        </Typography>
                        <Typography
                            variant="h2"
                            color={lightBlue[900]}
                            sx={{
                                marginRight: '10px',
                                // marginLeft: '10px',
                                fontSize: isIOSDevice() ? '1.5rem !important' : '2rem !important',
                                textAlign: 'center'
                            }}>
                            {!isReorder && t('chooseAnotherOptionMemorin2')}
                            <br />
                            {t('chooseAnotherOptionMemorin3')}
                        </Typography>
                    </Box>
                    <Logo
                        src={finger}
                        alt="finger"
                        style={{ height: '200px', width: '200px', paddingTop: '30px' }}
                    />
                </Box>
            ) : (
                // Not Success
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Logo
                        src={choisir}
                        alt="choisir"
                        style={{ height: '200px', width: '200px', paddingTop: '30px' }}
                    />
                    <Typography
                        variant="h2"
                        color={lightBlue[900]}
                        sx={{
                            marginRight: '10px',
                            // marginLeft: '10px',
                            fontSize: isIOSDevice() ? '2rem !important' : '4rem !important',
                            textAlign: 'center'
                        }}>
                        {t('chooseAnotherOption1')}
                        <br />
                        {t('chooseAnotherOption2')}
                    </Typography>
                </Box>
            )}
        </Box>
    );
    return (
        <div>
            <StyledModal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={isModelOpen}
                BackdropComponent={Backdrop}>
                {body}
            </StyledModal>
        </div>
    );
}
