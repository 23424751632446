import React from 'react';
import { Breakpoint, Theme, useMediaQuery } from '@mui/material';

// ----------------------------------------------------------------------

interface MHiddenProps {
    children: React.ReactElement;
    width:
        | 'xsDown'
        | 'smDown'
        | 'mdDown'
        | 'lgDown'
        | 'xlDown'
        | 'xsUp'
        | 'smUp'
        | 'mdUp'
        | 'lgUp'
        | 'xlUp';
}

function getBreakpoint(key: String): 'xs' | 'sm' | 'md' | 'lg' | 'xl' {
    switch (key) {
        case 'xs':
            return 'xs';
        case 'sm':
            return 'sm';
        case 'md':
            return 'md';
        case 'lg':
            return 'lg';
        case 'xl':
            return 'xl';
        default:
            return 'md';
    }
}

export function MHidden({ width, children }: MHiddenProps): React.ReactElement | null {
    const breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl' = getBreakpoint(width.substring(0, 2));

    const hiddenUp = useMediaQuery((theme: Theme) => theme.breakpoints.up(breakpoint));
    const hiddenDown = useMediaQuery((theme: Theme) => theme.breakpoints.down(breakpoint));

    if (width.includes('Down')) {
        return hiddenDown ? null : children;
    }

    if (width.includes('Up')) {
        return hiddenUp ? null : children;
    }

    return null;
}
