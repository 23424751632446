export class DropdownData {
    name: string;

    value: string;

    familyCode: string | undefined;

    constructor(name: string, value: string, familyCode: string | undefined) {
        this.name = name;
        this.value = value;
        this.familyCode = familyCode;
    }
}
