/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { makeStyles, styled } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import { Header } from '../components/Header';
import imgConfirmAccount from '../assets/imgConfirmAccount.png';

const useStyles = makeStyles(() => ({
    root: {
        borderColor: '#D9D9D9',
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#D9D9D9 !important'
        }
    },
    inputComponentLayout: {
        marginTop: '5%'
    },
    input: {
        height: '35px',
        width: '100%',
        borderRadius: '10px',
        border: '1px solid #D9D9D9',
        padding: '0 10px',
        margin: '0 0 5% 0',
        fontSize: '14px',
        color: '#002F55',
        '&:focus': {
            outline: 'none',
            border: '1px solid #D9D9D9'
        },
        '&:hover': {
            border: '1px solid #D9D9D9'
        },
        '&::placeholder': {
            color: '#002F55',
            opacity: 1
        }
    }
}));

export function NewAccountConfirm(): JSX.Element {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <Box flex={1} display="flex" flexDirection="column" height="100vh">
            <Header />
            <Box
                flex={1}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="top">
                <Box
                    width="95%"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    paddingTop={2}
                    justifyContent="center">
                    <Typography
                        variant="h4"
                        color="#FF0089"
                        sx={{ fontFamily: 'Inter', fontWeight: '700', fontSize: '22px' }}>
                        {t('newAccountConfirm.title')}
                    </Typography>
                    <img
                        src={imgConfirmAccount}
                        alt="selectChoise"
                        style={{ maxWidth: '45%', maxHeight: '45%' }}
                    />
                    <Typography
                        variant="h5"
                        color="#002F55"
                        sx={{ fontFamily: 'Inter', fontWeight: '600', fontSize: '20px' }}>
                        {t('newAccountConfirm.desc1')}
                    </Typography>
                    <br />
                    <Typography
                        variant="h5"
                        color="#002F55"
                        sx={{ fontFamily: 'Inter', fontWeight: '600', fontSize: '20px' }}>
                        {t('newAccountConfirm.desc2')}
                    </Typography>
                    <br />
                    <Typography
                        variant="h5"
                        color="#002F55"
                        sx={{ fontFamily: 'Inter', fontWeight: '600', fontSize: '20px' }}>
                        {t('newAccountConfirm.desc3-1')}
                        <span style={{ fontWeight: '400', fontSize: '14px' }}>
                            {t('newAccountConfirm.desc3-2')}
                        </span>
                    </Typography>
                    <br />
                    <Typography
                        variant="h5"
                        color="#002F55"
                        sx={{ fontFamily: 'Inter', fontWeight: '600', fontSize: '20px' }}>
                        {t('newAccountConfirm.desc4')}
                    </Typography>
                    <br />
                    <Typography
                        variant="h5"
                        color="#002F55"
                        sx={{ fontFamily: 'Inter', fontWeight: '600', fontSize: '20px' }}>
                        {t('newAccountConfirm.desc5')}
                    </Typography>
                </Box>
            </Box>
            {/* leave and next button */}
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-evenly"
                paddingTop={2}
                paddingBottom={2}
                width="100%">
                <Button
                    variant="contained"
                    color="primary"
                    sx={{
                        width: '40%',
                        height: '40px',
                        backgroundColor: '#FF0089',
                        border: 'none',
                        color: '#FFFFFF',
                        borderRadius: '12px',
                        boxShadow: '0px 3.02813px 3.02813px rgba(0, 0, 0, 0.25)',
                        '&:hover': {
                            backgroundColor: '#FF0089'
                        }
                    }}
                    onClick={async (): Promise<void> => {
                        window.location.href = 'https://www.stimulin.fr/';
                    }}>
                    {t('newAccountConfirm.endButton')}
                </Button>
            </Box>
        </Box>
    );
}
