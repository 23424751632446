import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Login } from './pages/Login';
import { Register } from './pages/Register';
import { ForgotPassword } from './pages/ForgotPassword';
import { NewAccount } from './pages/NewAccount';
import { NewAccountConfirm } from './pages/NewAccountConfirm';
import { Roles } from './models/Roles';
import { PageNotFound } from './pages/PageNotFound';
import { Home as PatientHome } from './pages/patient/Home';
import { SelectThumbnails } from './pages/common/SelectThumbnails';
import { Home } from './pages/common/Home';
import { Dashboard as AdminDashboard } from './pages/admin/Dashboard';
import { MemoryExercise1 } from './pages/patient/MemoryExercise1';
import { MemoryExercise2 } from './pages/patient/MemoryExercise2';
import { PatientScreenSettings } from './pages/patient/PatientSettingsScreen';
import { FamilyScreenSettings } from './pages/family/FamilySettingsScreen';
import { TherapistScreenSettings } from './pages/therapist/TherapistSettingsScreen';
import { ViewPatient } from './pages/therapist/ViewPatient';
import { PuzzleExercise } from './pages/patient/PuzzleExercise';
import { MemorinExercise } from './pages/patient/MemorinExercise';
import { Sudoku } from './pages/patient/sudoku/sudoku';
import { Pong } from './pages/patient/pong/pong';
import { Patient } from './pages/admin/Patient';
import { Families } from './pages/admin/Families';
import { AddFamily } from './pages/admin/AddFamily';
import { AddPatient } from './pages/admin/AddPatient';
import { AddTherapist } from './pages/admin/AddTherapist';
import { AddEstablishment } from './pages/admin/AddEstablishment';
import { Therapist } from './pages/admin/Therapist';
import { Establishments } from './pages/admin/Establishments';
import { Exercise } from './pages/common/Exercise';
import { Mediatheque } from './pages/common/Mediatheque';
import { MediaContent } from './pages/common/MediaContent';
import { ExerciseObjective } from './pages/common/ExerciseObjective';
import { Profile } from './pages/admin/Profile';
import { ChangePasswordOnboarding } from './pages/ChangePasswordOnboarding';
import { CustomizeExercise } from './pages/common/CustomizeExercise';
import { UserContext } from './providers/UserProvider';
import { Reorder } from './pages/patient/reorder/reorder';

function PatientRoutes(): JSX.Element {
    return (
        <Switch>
            <Route path="/home" exact component={PatientHome} />
            <Route path="/profile" exact component={PatientScreenSettings} />
            <Route path="/souven'in/:id" exact component={MemoryExercise1} />
            <Route path="/souven'in/exerciseItem/:id" exact component={MemoryExercise2} />
            <Route path="/puzzl'in" exact component={PuzzleExercise} />
            <Route path="/sudok'in" exact component={Sudoku} />
            <Route path="/pong'in" exact component={Pong} />
            <Route path="/memor'in/:id" exact component={MemorinExercise} />
            <Route path="/ordon'in" exact component={Reorder} />
        </Switch>
    );
}

function FamilyRoutes(): JSX.Element {
    return (
        <Switch>
            <Route path="/home" exact component={Home} />
            <Route path="/mediatheque" exact component={Mediatheque} />
            <Route path="/mediatheque/:type" exact component={MediaContent} />
            <Route path="/exercice-customisation/:type/:id?" exact component={SelectThumbnails} />
            <Route path="/exercise" exact component={Exercise} />
            <Route path="/objective/:type" exact component={ExerciseObjective} />
            <Route path="/profile" exact component={FamilyScreenSettings} />
            <Route path="/edit" exact component={CustomizeExercise} />
        </Switch>
    );
}

function TherapistRoutes(): JSX.Element {
    return (
        <Switch>
            <Route path="/home" exact component={Home} />
            <Route path="/mediatheque" exact component={Mediatheque} />
            <Route path="/mediatheque/:type" exact component={MediaContent} />
            <Route path="/exercise" exact component={Exercise} />
            <Route path="/objective/:type" exact component={ExerciseObjective} />
            <Route path="/exercice-customisation/:type/:id?" exact component={SelectThumbnails} />
            <Route path="/view-patient" exact component={ViewPatient} />
            <Route path="/profile" exact component={TherapistScreenSettings} />
            <Route path="/edit" exact component={CustomizeExercise} />
            <Route path="/souven'in/:id" exact component={MemoryExercise1} />
            <Route path="/souven'in/exerciseItem/:id" exact component={MemoryExercise2} />
            <Route path="/puzzl'in" exact component={PuzzleExercise} />
        </Switch>
    );
}

function AdminRoutes(): JSX.Element {
    return (
        <Switch>
            <Route path="/home" exact component={AdminDashboard} />
            <Route path="/patient" exact component={Patient} />
            <Route path="/patient/add" exact component={AddPatient} />
            <Route path="/family" exact component={Families} />
            <Route path="/family/add" exact component={AddFamily} />
            <Route path="/patient/add" exact component={AddPatient} />
            <Route path="/therapist" exact component={Therapist} />
            <Route path="/therapist/add" exact component={AddTherapist} />
            <Route path="/establishment" exact component={Establishments} />
            <Route path="/establishment/add" exact component={AddEstablishment} />
            <Route path="/profile" exact component={Profile} />
            <Route path="/exercise" exact component={Exercise} />
            <Route path="/mediatheque" exact component={Mediatheque} />
            <Route path="/mediatheque/:type" exact component={MediaContent} />
            <Route path="/exercice-customisation/:type/:id?" exact component={SelectThumbnails} />
            <Route path="/edit" exact component={CustomizeExercise} />
            <Route path="/souven'in/:id" exact component={MemoryExercise1} />
            <Route path="/souven'in/exerciseItem/:id" exact component={MemoryExercise2} />
            <Route path="/puzzl'in" exact component={PuzzleExercise} />
        </Switch>
    );
}

function setUserRoutes(): JSX.Element {
    const userDataProp = useContext(UserContext);
    const user = userDataProp?.user;
    if (user === null) {
        return <Redirect to="/login" />;
    }
    const role = user?.role;
    switch (role) {
        case Roles.PATIENT:
            return PatientRoutes();
        case Roles.FAMILY:
            return FamilyRoutes();
        case Roles.THERAPIST:
            return TherapistRoutes();
        case Roles.ADMIN:
            return AdminRoutes();
        default:
            return <Route path="/401" component={PageNotFound} />;
    }
}

export function Router(): JSX.Element {
    return (
        <Switch>
            <Route path="/" exact>
                <Redirect to="/home" />
            </Route>
            <Route path="/login" exact component={Login} />
            <Route path="/register" exact component={Register} />
            <Route path="/forgot-password" exact component={ForgotPassword} />
            <Route path="/change-password" exact component={ChangePasswordOnboarding} />
            <Route path="/new-account" exact component={NewAccount} />
            <Route path="/new-account-confirm" exact component={NewAccountConfirm} />
            {setUserRoutes()}
            <Route path="/404" component={PageNotFound} />;
            <Route path="*">
                <Redirect to="/404" />
            </Route>
        </Switch>
    );
}
