/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useContext } from 'react';
import { blue } from '@mui/material/colors';
import { Avatar, Box, Card, CssBaseline, Grid, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLongPress } from 'use-long-press';
import { createStyles, makeStyles, styled } from '@mui/styles';
import Button from '@mui/material/Button';
import { useHistory, useLocation, useParams } from 'react-router-dom';
// import clapping from '../../audios/clapping.wav';
import { ExerciseHeader } from '../../components/ExerciseHeader';
import { SuccessModal } from '../../components/SuccessModal';
import { FormModalAlert } from '../common/FormModalAlert';
import { ExerciseItem } from '../../models/ExerciseItem';
import { getImageKitUrlFrom } from '../../utilities/utils';
import { Exercise } from '../../models/Exercise';
import { UserContext } from '../../providers/UserProvider';
import { Roles } from '../../models/Roles';
import { Therapist } from '../../models/Therapist';
import { updateErrorCount } from '../../services/cloudFirestore';
import { UserDataProp } from '../../models/userDataProp';
import { Fireworks } from '../../components/confetti/firework';
import question from '../../assets/question.svg';

const useStyles = makeStyles(() =>
    createStyles({
        profileImage: {
            display: 'inline',
            marginRight: '10px',
            width: '80px',
            height: '80px',
            alignSelf: 'center',
            justifySelf: 'center',
            objectFit: 'cover'
        },
        currentUserInitials: {
            display: 'flex',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            margin: '0'
        },
        itemFadeout: {
            animation:
                '$fadeout 3s linear forwards, $shake 0.82s cubic-bezier(.36,.07,.19,.97) both'
        },
        itemShake: {
            animation: '$shake 0.82s cubic-bezier(.36,.07,.19,.97) both'
        },
        '@keyframes fadeout': {
            '0%': { opacity: 1 },
            '100%': { opacity: 0 }
        },
        '@keyframes shake': {
            '10%, 90%': {
                transform: 'translate3d(-1px, 0, 0)'
            },
            '20%, 80%': {
                transform: 'translate3d(2px, 0, 0)'
            },
            '30%, 50%, 70%': {
                transform: 'translate3d(-4px, 0, 0)'
            },
            '40%, 60%': {
                transform: 'translate3d(4px, 0, 0)'
            }
        }
        // '@keyframes fadein': {
        //     from: { maxWidth: '0%', maxHeight: '0%' },
        //     to: { maxWidth: '100%', maxHeight: '100%' }
        // },
        // // set fadein animation to the element with the fadein class
        // fadein: {
        //     animationName: '$fadein',
        //     animationDuration: '10s'
        // }
    })
);

const Root = styled('div')({
    display: 'flex',
    alignContent: 'space-around',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 0,
    marginLeft: '2%',
    marginTop: '2%',
    marginRight: '2%',
    minWidth: '90%',
    maxHeight: '100%'
});

const DefaultLogo = styled('div')({
    borderRadius: '50%',
    background: '#efe7d7',
    position: 'relative'
});

const ImageContainer = styled('img')({
    maxHeight: 'calc(100vh - 120px - 16vh - 16px)',
    maxWidth: 'calc(100vh - 120px - 16vh - 16px)',
    height: 'calc(50vw - 60px - 16px)',
    width: 'calc(50vw - 60px - 16px)',
    // maxWidth: '100%',
    // maxHeight: '100%',
    objectFit: 'cover'
});

interface LocationState {
    author: Therapist;
    memoryLevel: number;
    exerciseItemList: ExerciseItem[];
    currentExercise: ExerciseItem;
    exercise: Exercise;
    difficultyLevel: number;
    completedExercises: string[];
    submittedCorrectAnswers: (string | undefined)[];
}

export function MemoryExercise2(): JSX.Element {
    const history = useHistory();
    const classes = useStyles();
    const { t } = useTranslation();
    const [initials, setInitials] = useState<string>('');
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const { id } = useParams<{ id: string }>();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openFormModal, setOpenFormModal] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<string>('');
    const location = useLocation<LocationState>();
    const [exercise, setExercise] = useState<Exercise>();
    const [exerciseItemList, setExerciseItemList] = useState<ExerciseItem[]>([]);
    const [exerciseItem, setExerciseItem] = useState<ExerciseItem | null>();
    const [removedOptionList, setRemovedOptionList] = useState<string[]>([]);
    const [answerList, setAnswerList] = useState<string[] | null>(null);
    const [difficultyLevel, setDifficultyLevel] = useState<number>(0);
    const [memoryLevel, setMemoryLevel] = useState<number>(0);
    const [isShuffled, setIsShuffled] = useState<boolean>(false);
    const [isLastOption, setIsLastOption] = useState<boolean>(false);
    const [isAnimating, setIsAnimating] = useState<boolean>(false);
    const [playFirework, setPlayFirework] = useState<boolean>(false);
    const nbOfElementToDisplay = [3, 4, 4, 4, 4, 4, 5, 5, 6, 6];
    const [author, setAuthor] = useState<Therapist>({
        firstName: undefined,
        lastName: undefined,
        emailId: undefined,
        dob: undefined,
        fonction: undefined,
        establishmentName: undefined,
        mobile: undefined,
        firstStepFormFields: [],
        secondStepFormFields: []
    });
    const [itemToShake, setItemToShake] = useState<string>('');
    const [isFirstSetAnswerList, setIsFirstSetAnswerList] = useState<boolean>(true);
    const [isMemorization, setIsMemorization] = useState<boolean>(location.state.memoryLevel >= 6);
    const [answerListToDisplay, setAnswerListToDisplay] = useState<{ [key: string]: string }>();
    const [timerId, setTimerId] = useState<NodeJS.Timeout>();

    useEffect(() => {
        let tmpTimerId;

        if (isMemorization) {
            tmpTimerId = setTimeout(() => {
                setIsMemorization(false);
            }, 10000);
            setTimerId(tmpTimerId);
        } else if (timerId && !isMemorization) {
            clearTimeout(timerId);
        }
    }, [isMemorization]);

    const getCharacter = (str: string, index: number): string => {
        const arrayCharNotMove = [
            '(',
            ')',
            "'",
            '"',
            ',',
            '.',
            '?',
            '!',
            ':',
            ';',
            '-',
            '~',
            '&',
            '[',
            ']',
            '{',
            '}',
            '°',
            '%',
            '€',
            '$',
            '£',
            '§',
            '²',
            '¤',
            '*',
            '+',
            '/',
            '=',
            '#',
            '@',
            'µ',
            '‘'
        ];
        if (arrayCharNotMove.includes(str)) {
            return str;
        }
        if (location.state.memoryLevel === 3 || location.state.memoryLevel === 7) {
            if (index % 2 === 0) {
                return str;
            }
            return '_';
        }
        if (
            location.state.memoryLevel === 4 ||
            location.state.memoryLevel === 5 ||
            location.state.memoryLevel === 8 ||
            location.state.memoryLevel === 9
        ) {
            if (index === 1) {
                return '_ ';
            }
            if (index === 2) {
                return '_';
            }
            if (index === 0 || index % 2 === 1) {
                return str;
            }
            return '_';
        }
        return '_';
    };

    const getItemToString = (word: string): string => {
        if (word && location.state.memoryLevel >= 3 && location.state.memoryLevel !== 6) {
            const wordWithHole = word
                .split(' ')
                .map((thisWord) => {
                    if (location.state.memoryLevel !== 10) {
                        return thisWord
                            .split('')
                            .map((letter, index) => {
                                return getCharacter(letter, index);
                            })
                            .join('');
                    }
                    if (location.state.memoryLevel === 10) {
                        // create var "thisWordWithUnsort" and set it to "thisWord" but unsorted
                        let thisWordWithUnsort = thisWord;
                        if (thisWord.length === 1) return thisWord;
                        while (thisWordWithUnsort === thisWord) {
                            thisWordWithUnsort = thisWord
                                .split('')
                                .sort(() => Math.random() - 0.5)
                                .join('');
                        }
                        return thisWordWithUnsort;
                    }
                    return '';
                })
                .join('\u00A0\u00A0');
            return wordWithHole;
        }
        return word;
    };

    useEffect(() => {
        if (answerList && answerList?.length > 1 && !isShuffled) {
            const array = answerList;
            for (let i = array.length - 1; i > 0; i -= 1) {
                const j = Math.floor(Math.random() * (i + 1));
                const temp = array[i];
                array[i] = array[j];
                array[j] = temp;
            }
            setAnswerList(array);
            setIsShuffled(true);
        }
        if (
            (answerList && answerList?.length <= 1) ||
            answerList?.length === removedOptionList.length - 1
        ) {
            setIsLastOption(true);
        }

        if (answerList && answerList?.length > 1 && !answerListToDisplay) {
            let templist = {};
            answerList.forEach((item, index) => {
                templist = { ...templist, [item]: getItemToString(item) };
            });
            setAnswerListToDisplay(templist);
        }
    }, [answerList]);

    useEffect(() => {
        if (answerList && answerList?.length <= 1) {
            setIsLastOption(true);
        }
    }, [removedOptionList]);

    useEffect(() => {
        if (
            location.state.author &&
            location.state.author.firstName &&
            location.state.author.lastName
        ) {
            setAuthor(location.state.author);
            setInitials(
                location.state.author.firstName.charAt(0).toUpperCase() +
                    location.state.author.lastName.charAt(0).toUpperCase()
            );
        }
        if (location.state.difficultyLevel) {
            setDifficultyLevel(location.state.difficultyLevel);
        }
        if (location.state.exercise) {
            setExercise(location.state.exercise);
        }
        const list: string[] = [];
        if (
            location.state.exerciseItemList &&
            location.state.memoryLevel &&
            user &&
            user.role === Roles.PATIENT
        ) {
            setMemoryLevel(location.state.memoryLevel);
            setExerciseItemList(location.state.exerciseItemList);
            location.state.exerciseItemList.forEach((item) => {
                if (item.id === id && item.answer) {
                    list.push(item.answer);
                }
            });
            location.state.exerciseItemList.forEach((item) => {
                if (
                    item.answer &&
                    list.length < nbOfElementToDisplay[location.state.memoryLevel - 1] &&
                    item.id !== id
                ) {
                    list.push(item.answer);
                }
            });
            setAnswerList(list);
        }
        if (location.state.exerciseItemList && user && user.role === Roles.THERAPIST) {
            setExerciseItemList(location.state.exerciseItemList);
            location.state.exerciseItemList.forEach((item) => {
                if (item.answer) {
                    list.push(item.answer);
                }
            });
        }
        setAnswerList(list);
        if (location.state.currentExercise) {
            setExerciseItem(location.state.currentExercise);
        }
    }, [location.state]);

    const handleFormModal = (): void => {
        setOpenFormModal(true);
    };

    const handleCloseFormModal = (): void => {
        setOpenFormModal(false);
    };

    const removeAnswerFromList = (answer: string): void => {
        if (answerList) {
            const index = answerList.indexOf(answer);
            if (index > -1) {
                answerList.splice(index, 1);
            }
        }
    };

    const updateCount = async (): Promise<void> => {
        if (exercise && user && user.id && exercise.id && user.role === Roles.PATIENT) {
            await updateErrorCount(exercise.id, user.id);
            const countErrorAndClue = localStorage.getItem('countErrorAndClue');
            if (countErrorAndClue) {
                const newCountErrorAndClue = Number(countErrorAndClue) + 1;
                localStorage.setItem('countErrorAndClue', newCountErrorAndClue.toString());
            }
        }
    };

    // const audio = new Audio('/applause-sound-effect.mp3');
    const audio = document.querySelector('audio');
    const audioContext = new AudioContext();

    const start = (): void => {
        if (audio) {
            const source = audioContext.createMediaElementSource(audio);
            source.connect(audioContext.destination);
            audio.play();
        }
    };

    const stopAudio = (): void => {
        if (audio) {
            audio.pause();
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onOptionClick = async (answer: string, event: any): Promise<void> => {
        setSelectedOption(answer);
        setIsAnimating(true);
        if (exerciseItem && answer === exerciseItem.answer) {
            if (event.target.tagName === 'BUTTON') {
                // eslint-disable-next-line no-param-reassign
                event.target.style.backgroundColor = '#00DF76';
            } else {
                const parentButton = event.target.closest('button');
                // eslint-disable-next-line no-param-reassign
                parentButton.style.backgroundColor = '#00DF76';
            }
            setIsSuccess(true);
            start();
            setPlayFirework(true);
        } else {
            updateCount();
            setIsSuccess(false);
            setRemovedOptionList([...removedOptionList, answer]);
            setItemToShake(answer);
            // stop shake
            setTimeout(() => {
                setItemToShake('');
            }, 3100);
            if (location.state.memoryLevel === 1) {
                setTimeout(() => {
                    removeAnswerFromList(answer);
                }, 3000);
            }
        }
        setTimeout(async () => {
            setOpenModal(true);
            setIsAnimating(false);
        }, 1000);
        setTimeout(async () => {
            setOpenModal(false);
            if (openModal === false || isSuccess) {
                if (exerciseItem && answer === exerciseItem.answer) {
                    stopAudio();
                    handleFormModal();
                    setSelectedOption('');
                    setPlayFirework(false);
                }
            }
        }, 3000);
    };

    const isHeightOfXS = (): boolean => {
        const { innerWidth: width, innerHeight: height } = window;
        if (width < 550 || height < 550) {
            return true;
        }
        if (height <= 768 && height > 551) {
            return true;
        }
        return false;
    };

    const bindLongPress = useLongPress(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (event, index) => {
            if (answerList) {
                onOptionClick(answerList[Number(index.context)], event);
            }
        },
        { threshold: 1000 }
    );

    const getExercises = (): JSX.Element[] | null => {
        if (answerList) {
            return answerList.map((item, index) => {
                // if (removedOptionList.includes(item)) {
                //     return <></>;
                // }
                return (
                    <Grid sx={{ marginBottom: '25px' }} item xs key={item}>
                        <Button
                            sx={{
                                padding: '35px 0px'
                            }}
                            className={
                                // eslint-disable-next-line no-nested-ternary
                                itemToShake === item
                                    ? location.state.memoryLevel === 1
                                        ? classes.itemFadeout
                                        : classes.itemShake
                                    : ''
                            }
                            fullWidth
                            disabled={isAnimating}
                            disableElevation
                            variant="dashed"
                            size="large"
                            {...bindLongPress(index)}
                            onClick={(): void => {
                                // eslint-disable-next-line no-restricted-globals
                                onOptionClick(item, event);
                            }}>
                            <Typography
                                variant="h2"
                                color="#374653"
                                sx={{
                                    fontWeight: 500,
                                    fontSize:
                                        location.state.memoryLevel === 4 ||
                                        location.state.memoryLevel === 5 ||
                                        location.state.memoryLevel === 8 ||
                                        location.state.memoryLevel === 9
                                            ? '1.5rem ! important'
                                            : '2rem',
                                    fontFamily:
                                        user?.role === Roles.PATIENT ? 'Luciole-Regular' : ''
                                }}>
                                {/* {getItemToString(item)} */}
                                {answerListToDisplay && answerListToDisplay[item]}
                            </Typography>
                        </Button>
                    </Grid>
                );
            });
        }
        return null;
    };

    const handleRemovedOptionList = (list: string[]): void => {
        const item = list[Math.floor(Math.random() * list.length)];
        setItemToShake(item);
        setTimeout(() => {
            setItemToShake('');
            const newList = answerList?.filter((answer) => answer !== item);
            if (newList) setAnswerList(newList);
        }, 3100);
    };

    const handleClueClick = (): void => {
        if (exerciseItem) {
            const list = answerList?.filter((item) => {
                return item !== exerciseItem.answer;
            });
            if (location.state.memoryLevel <= 2 || location.state.memoryLevel === 6) {
                if (list && answerList) {
                    const list1 = list.filter((item) => !removedOptionList.includes(item));
                    setAnswerList(answerList.filter((item) => !removedOptionList.includes(item)));
                    handleRemovedOptionList(list1);
                }
            } else if (location.state.memoryLevel !== 10) {
                const tempAnswerListToDisplay = answerListToDisplay;
                answerList?.forEach((item) => {
                    if (tempAnswerListToDisplay) {
                        let isFisrtChar = true;
                        const newStr = tempAnswerListToDisplay[item]
                            .replaceAll('_ _', '__')
                            .replaceAll('\u00A0\u00A0', ' ')
                            .split('')
                            .map((char, index) => {
                                if (isFisrtChar && char === '_' && item.split('')[index] !== '_') {
                                    isFisrtChar = false;
                                    return item.split('')[index];
                                }
                                return char;
                            })
                            .join('')
                            .replaceAll('__', '_ _');
                        tempAnswerListToDisplay[item] = newStr;
                    }
                });
                setAnswerListToDisplay(tempAnswerListToDisplay);

                // for reload answer list to display when click clue
                if (answerList) setAnswerList([...answerList]);
            } else {
                const tempAnswerListToDisplay = answerListToDisplay;
                answerList?.forEach((item) => {
                    if (tempAnswerListToDisplay) {
                        let isFisrtword = true;
                        const newStr = tempAnswerListToDisplay[item]
                            .split('\u00A0\u00A0')
                            .map((word, index) => {
                                if (isFisrtword && word !== item.split(' ')[index]) {
                                    isFisrtword = false;
                                    return item.split(' ')[index];
                                }
                                return word;
                            })
                            .join('\u00A0\u00A0');
                        tempAnswerListToDisplay[item] = newStr;
                    }
                });
                setAnswerListToDisplay(tempAnswerListToDisplay);

                // for reload answer list to display when click clue
                if (answerList) setAnswerList([...answerList]);
            }
        }
    };

    return (
        <Box flex={1} display="flex" flexDirection="column" height="80vh" justifyItems="center">
            <audio style={{ display: 'none' }} src="/applause-sound-effect.mp3">
                <track kind="captions" />
            </audio>
            {/* {playFirework === true && false && <Fireworks play={playFirework} />} */}
            <CssBaseline />
            <SuccessModal
                isModelOpen={openModal}
                setModelOpen={setOpenModal}
                isSuccess={isSuccess}
                isFirework={!!isSuccess}
                nbModal={
                    // eslint-disable-next-line no-nested-ternary
                    !isSuccess
                        ? 0
                        : // eslint-disable-next-line no-nested-ternary
                        location.state.completedExercises &&
                          location.state.completedExercises.length + 1 < exerciseItemList.length
                        ? (location.state.completedExercises.length + 1) % 2
                            ? 1
                            : 2
                        : 0
                }
            />
            {openFormModal && exerciseItem && (
                <FormModalAlert
                    exerciseItemList={exerciseItemList}
                    exerciseReceived={exerciseItem}
                    closeModal={handleCloseFormModal}
                    exercise={exercise}
                    difficultyLevel={location.state.memoryLevel}
                    displayInfo
                />
            )}
            <ExerciseHeader
                exerciseId={location.state.exercise.id}
                exerciseName={location.state.exercise.exerciseName}
                isExerciseSelected={!isMemorization}
                displayBack
                handleClueClick={handleClueClick}
                difficultyLevel={difficultyLevel}
                level={location.state.memoryLevel}
                isLastOption={isLastOption}
                handleGoBack={(): void => {
                    !isMemorization && location.state.memoryLevel >= 6
                        ? setIsMemorization(true)
                        : history.goBack();
                }}
            />
            <Paper
                variant="outlined"
                sx={{
                    // backgroundColor: isMemorization ? 'transparent' : blue[50],
                    // display: 'flex',
                    backgroundColor: blue[50],
                    display: isMemorization ? 'none' : 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '6px',
                    marginTop:
                        // eslint-disable-next-line no-nested-ternary
                        difficultyLevel === 1
                            ? location.state.memoryLevel === 3 ||
                              location.state.memoryLevel === 4 ||
                              location.state.memoryLevel === 5 ||
                              location.state.memoryLevel >= 7
                                ? 'calc(140px - 2vh)'
                                : '140px'
                            : location.state.memoryLevel === 3 ||
                              location.state.memoryLevel === 4 ||
                              location.state.memoryLevel === 5 ||
                              location.state.memoryLevel >= 7
                            ? 'calc(120px - 2vh)'
                            : '120px',
                    marginRight: '2%',
                    marginLeft: '2%',
                    marginBottom: '2vh',
                    border: isMemorization ? 'none' : '1px solid rgba(0, 0, 0, 0.12)',
                    // eslint-disable-next-line no-nested-ternary
                    height: isHeightOfXS()
                        ? location.state.memoryLevel === 3 ||
                          location.state.memoryLevel === 4 ||
                          location.state.memoryLevel === 5 ||
                          location.state.memoryLevel >= 7
                            ? '12vh'
                            : '10vh'
                        : location.state.memoryLevel === 3 ||
                          location.state.memoryLevel === 4 ||
                          location.state.memoryLevel === 5 ||
                          location.state.memoryLevel >= 7
                        ? '9vh'
                        : '7vh'
                }}>
                {author &&
                    author.role !== undefined &&
                    author.role !== Roles.ADMIN &&
                    !author.profilePhoto &&
                    !isMemorization && (
                        <DefaultLogo className={classes.profileImage}>
                            <div style={{ height: '5vmin' }}>
                                <Typography
                                    variant="h2"
                                    sx={{
                                        fontWeight: 'bold',
                                        color: '#374653',
                                        fontFamily:
                                            user?.role === Roles.PATIENT ? 'Luciole-Regular' : ''
                                    }}
                                    className={classes.currentUserInitials}>
                                    {initials}
                                </Typography>
                            </div>
                        </DefaultLogo>
                    )}
                {author &&
                    author.role !== undefined &&
                    author.role !== Roles.ADMIN &&
                    author.profilePhoto &&
                    typeof author.profilePhoto === 'string' &&
                    !isMemorization && (
                        <Avatar
                            alt="userIcon"
                            src={getImageKitUrlFrom(author.profilePhoto)}
                            className={classes.profileImage}
                        />
                    )}
                <Typography
                    variant="h4"
                    my={5}
                    color="secondary"
                    sx={{
                        marginTop: '25px',
                        marginBottom: '25px',
                        color: isMemorization ? 'transparent' : '#3387CC',
                        fontFamily: user?.role === Roles.PATIENT ? 'Luciole-Regular' : ''
                    }}>
                    {
                        // eslint-disable-next-line no-nested-ternary
                        exercise?.authorType === Roles.FAMILY
                            ? t('placeFingerOnTitleFamily')
                            : // eslint-disable-next-line no-nested-ternary
                            location.state.memoryLevel === 10
                            ? t('placeFingerOnTitle2')
                            : location.state.memoryLevel === 3 ||
                              location.state.memoryLevel === 4 ||
                              location.state.memoryLevel === 5 ||
                              location.state.memoryLevel >= 7
                            ? t('placeFingerOnTitle3')
                            : t('placeFingerOnTitle')
                    }
                </Typography>
            </Paper>
            <Box
                margin="auto"
                sx={{
                    width: '100vw',
                    height: isMemorization ? '80vh' : '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: isMemorization ? '20vh' : '0',
                    position: 'relative'
                    // HERE
                }}>
                {isMemorization && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '6px',
                            width: '40.5%',
                            margin: 'auto',
                            position: 'absolute',
                            bottom: '0',
                            left: '74.5%',
                            transform: 'translate(-50%, 0)'
                        }}>
                        <Button
                            sx={{
                                backgroundColor: '#ffffff',
                                color: '#D00070',
                                border: '1px solid #D00070',
                                borderRadius: '10px',
                                width: '100%',
                                height: '100%',
                                '&:hover': {
                                    backgroundColor: '#ffffff',
                                    color: '#D00070',
                                    border: '1px solid #D00070'
                                }
                            }}
                            onClick={(): void => setIsMemorization(false)}>
                            <Typography
                                sx={{
                                    fontFamily: 'Luciole-Regular !important',
                                    fontSize: '1.5rem !important',
                                    fontWeight: '400 !important'
                                }}>
                                {t('skipMemorisation')}
                            </Typography>
                        </Button>
                    </Box>
                )}
                <Root>
                    <Grid
                        container
                        spacing={1}
                        sx={{
                            justifyContent: 'center',
                            marginRight: '1%',
                            width: '50%',
                            height: '100%'
                        }}>
                        {exerciseItem && typeof exerciseItem.exerciseImage === 'string' && (
                            <Card
                                sx={{
                                    height: 'calc(50vw - 60px)',
                                    width: 'calc(50vw - 60px)',
                                    maxHeight: 'calc(100vh - 120px - 16vh)',
                                    maxWidth: 'calc(100vh - 120px - 16vh)',
                                    borderRadius: '35px',
                                    boxShadow: '0px 19.3854px 63.0027px #A0BDD6',
                                    // minWidth: '100%',
                                    // minHeight: '100%',
                                    border: '8px solid #FFFFFF',
                                    position: 'relative',
                                    backgroundColor: 'transparent !important'
                                }}>
                                <ImageContainer
                                    src={getImageKitUrlFrom(exerciseItem.exerciseImage)}
                                    alt="exercise image"
                                    style={{
                                        filter:
                                            !isMemorization && location.state.memoryLevel >= 6
                                                ? 'blur(15px)'
                                                : 'none'
                                    }}
                                />
                                <img
                                    src={question}
                                    alt="question"
                                    style={{
                                        display:
                                            !isMemorization && location.state.memoryLevel >= 6
                                                ? 'block'
                                                : 'none',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: '24vh',
                                        height: '24vh'
                                    }}
                                />
                            </Card>
                        )}
                    </Grid>
                    <Grid
                        direction="column"
                        container
                        sx={{
                            justifyContent: 'center',
                            alignItems: 'stretch',
                            width: '50%',
                            marginLeft: '2%',
                            alignSelf:
                                answerList && !isMemorization && answerList.length >= 6
                                    ? 'start'
                                    : 'center'
                        }}>
                        {!isMemorization && getExercises()}
                        {isMemorization && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '6px',
                                    height: '30vh',
                                    width: '85%',
                                    backgroundColor: '#3387CC1A',
                                    margin: 'auto',
                                    position: 'relative'
                                }}>
                                {author &&
                                    author.role !== undefined &&
                                    author.role !== Roles.ADMIN &&
                                    author.profilePhoto &&
                                    typeof author.profilePhoto === 'string' && (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                position: 'absolute',
                                                top: '10px',
                                                left: '10px',
                                                transform: 'translate(-50%, -50%)'
                                            }}>
                                            <Avatar
                                                alt="userIcon"
                                                src={getImageKitUrlFrom(author.profilePhoto)}
                                                className={classes.profileImage}
                                            />
                                        </Box>
                                    )}
                                <Typography
                                    variant="h4"
                                    color="secondary"
                                    // className={classes.fadein}
                                    sx={{
                                        marginTop: '25px',
                                        marginBottom: '25px',
                                        marginLeft: '10px',
                                        marginRight: '10px',
                                        textAlign: 'left',
                                        fontFamily:
                                            user?.role === Roles.PATIENT ? 'Luciole-Regular' : ''
                                        // whiteSpace: 'nowrap'
                                        // overflow: 'hidden'
                                        // textOverflow: 'ellipsis'
                                        // webkitLineClamp: '3'
                                        // webkitBoxOrient: 'vertical'
                                    }}>
                                    {t('waitMemorizationQuiz')}
                                </Typography>
                            </Box>
                        )}
                    </Grid>
                </Root>
            </Box>
        </Box>
    );
}
