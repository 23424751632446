import React, { useCallback, useContext, useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Box, CircularProgress, Slider, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { ExerciseItem } from '../../models/ExerciseItem';
import { Roles } from '../../models/Roles';
import { ImageFrame } from './ImageFrame';
import waitPicture from '../../assets/waitPicture.png';
import bugDog from '../../assets/bugDog.png';
import { InputComponent } from '../../components/FormComponents/InputComponent';
import { SelectComponent } from '../../components/SelectComponent';
import { ImageIdentificationData } from '../../models/ImageIdentificationData';
import { IdentifyPerson } from '../../components/ImageUpload/IdentifyPerson';
import { FillDetails } from '../../components/ImageUpload/FillDetails';
import { Header } from '../../components/ImageUpload/Header';
import { IdentifyPersonModal } from '../../components/ImageUpload/IdentifyPersonModal';
import { UserContext } from '../../providers/UserProvider';
import { saveExerciseItem } from '../../services/cloudFirestore';
import { codeStorage, getImageKitUrlFrom } from '../../utilities/utils';
import { getCroppedImg } from '../../utilities/cropImage';
import { UserDataProp } from '../../models/userDataProp';
import { DeactiveModall } from '../../components/ContainerModal';
import { SelectPatient } from '../../components/ImageUpload/SelectPatient';
import { downloadData } from '../../services/analyticsFunction';
import zoomIn from '../../assets/zoom_in_24px.svg';
import zoomOut from '../../assets/zoom_out_24px.svg';

const ModalButtons = styled(Button)(() => ({
    '@media (max-width: 598px)': {
        width: '100%',
        margin: '2% 0%'
    },
    '@media (min-width: 598px)': {
        width: '30%'
    }
}));

const ButtonContainer = styled(Box)({
    '@media (max-width: 598px)': {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    '@media (min-width: 598px)': {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row'
    }
});

interface ChooseImageProps {
    selectedImage?: File[];
    setMedia?: Function;
    contentReceived?: ExerciseItem;
    setModifyThumbnails?: Function;
}

export function ChooseImage({
    selectedImage,
    setMedia,
    contentReceived,
    setModifyThumbnails
}: ChooseImageProps): JSX.Element {
    const isSmallWidth = !useMediaQuery('(min-width:500px)');
    const useStyles = makeStyles(() => ({
        input: {
            marginBottom: '12px',
            color: '#374653'
        },
        selectPlaceholder: {
            width: '100%',
            marginTop: '0px',
            color: '#A1A1A1',
            marginBottom: '12px'
        },
        modalStyle: {
            padding: isSmallWidth ? '' : '40px 60px 80px 60px'
        },
        cropContainer: {
            position: 'relative',
            width: '100%',
            height: '100%',
            background: '#fff'
        },
        warningImage: {
            display: 'flex',
            width: '220px',
            margin: 'auto'
        }
    }));

    const classes = useStyles();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const { t } = useTranslation();
    const dropdownList = [
        t('friends'),
        t('animal'),
        t('greatGrandMother'),
        t('greatGrandFather'),
        t('greatGrandSon'),
        t('greatGrandDaughter'),
        t('otherResident'),
        t('selfPotrait'),
        t('beautifulGirl'),
        t('stepSon'),
        t('brotherInLaw'),
        t('sisterInLaw'),
        t('husbandWife'),
        t('cousin'),
        t('cousine'),
        t('girl'),
        t('son'),
        t('brother'),
        t('grandMother'),
        t('grandFather'),
        t('mother'),
        t('mySelf'),
        t('nephew'),
        t('niece'),
        t('dad'),
        t('nursingStaff'),
        t('grandSon'),
        t('littleGirl'),
        t('sister'),
        t('speaker')
    ];
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openEndModal, setOpenEndModal] = useState<boolean>(false);
    const [openModalError, setOpenModalError] = useState<boolean>(false);
    const [openWaitModal, setOpenWaitModal] = useState(false);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [enabledSubmit, setEnabledSubmit] = useState<boolean>(false);
    const [stepper, setStepper] = useState<'01' | '02' | '03' | '04'>('01');
    const [imageSize, setImageSize] = useState<number>(0);
    const [image, setImage] = useState<string>('');
    const [itemId, setItemId] = useState<number>(0);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [cropImage, setCropImage] = useState<any>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [result, setResult] = useState<any>(null);
    const [exerciseImage, setExerciseImage] = useState<File>();
    const [exerciseImageType, setExerciseImageType] = useState<string>();
    const [itemCount, setItemCount] = useState<number>(0);
    const [zoom, setZoom] = useState(1);
    const [data, setData] = useState<ExerciseItem>({
        communityId: contentReceived?.communityId ?? undefined,
        authorId: contentReceived?.authorId ?? undefined,
        authorType: contentReceived?.authorType ?? undefined,
        id: contentReceived?.id ?? undefined,
        exerciseImageType: contentReceived?.exerciseImageType ?? undefined,
        title: contentReceived?.title ?? t('placeFingerOnTitle'),
        answer: contentReceived?.answer ?? undefined,
        exerciseImage: contentReceived?.exerciseImage ?? undefined,
        imageDetails: contentReceived?.imageDetails ?? [],
        photoCapturedPlace: contentReceived?.photoCapturedPlace ?? undefined,
        photoCapturedOn: contentReceived?.photoCapturedOn ?? undefined,
        anecdote: contentReceived?.anecdote ?? undefined,
        theme: contentReceived?.theme ?? undefined,
        patientList: contentReceived?.patientList ?? []
    });

    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [inputValue, setInputValue] = useState<ImageIdentificationData>({
        id: 0,
        firstName: null,
        lastName: null,
        relation: null
    });

    const getExerciseList = async (): Promise<void> => {
        if (user && user.id) {
            if (user.role === Roles.FAMILY) {
                if (user.familyCode) {
                    setData({
                        ...data,
                        authorType: Roles.FAMILY,
                        communityId: user.familyCode,
                        authorId: user.userId
                    });
                }
            }
            if (user.role === Roles.THERAPIST) {
                if (user.establishmentCode) {
                    setData({
                        ...data,
                        authorType: Roles.THERAPIST,
                        communityId: user.establishmentCode,
                        authorId: user.userId
                    });
                }
            }
            if (user.role === Roles.ADMIN) {
                setData({
                    ...data,
                    authorType: Roles.ADMIN,
                    communityId: user.id,
                    authorId: user.userId
                });
            }
        }
    };

    const onCropComplete = useCallback(
        async (croppedArea, croppedAreaPixels) => {
            const croppedImageData = await getCroppedImg(
                getImageKitUrlFrom(image),
                croppedAreaPixels
            );
            if (croppedImageData && croppedImageData.url && contentReceived === undefined) {
                setResult(croppedImageData.url);
                setExerciseImage(croppedImageData.file);
                setExerciseImageType(croppedImageData.file.type);
                getExerciseList();
            }
        },
        [image]
    );

    const [thisSelectedImage, setSelectedImage] = useState<File[]>();
    const setImageToCrop = (tempSelectedImage: File[]): void => {
        if (tempSelectedImage && contentReceived === undefined) {
            setImageSize(tempSelectedImage[0].size);
            const value = URL.createObjectURL(tempSelectedImage[0]);
            setImage(value);
            setSelectedImage(tempSelectedImage);
        }
    };
    useEffect(() => {
        if (selectedImage) setImageToCrop(selectedImage);
    }, [selectedImage]);

    // If I want to modify my thumbnails, I go directly to second step
    useEffect(() => {
        if (contentReceived !== undefined && user && user.id) {
            setStepper('02');
            if (data.exerciseImage && typeof data.exerciseImage === 'string') {
                setResult(data.exerciseImage);
            }
            if (data.authorId && data.authorId === data.communityId) {
                setData({ ...data, authorId: user.userId });
            }
        }
    }, [contentReceived]);

    useEffect(() => {
        if (inputValue.firstName && inputValue.lastName && inputValue.relation) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [inputValue]);

    const handleImageChange = (value: ImageIdentificationData): void => {
        setData({ ...data, imageDetails: [...data.imageDetails, value] });
    };

    const handleModalChange = (state: string, value: string | number): void => {
        setInputValue({ ...inputValue, [state]: value });
    };

    const closeErrorModal = (): void => {
        setOpenModalError(false);
        if (setMedia) {
            setMedia(null);
        }
        if (setModifyThumbnails) {
            setModifyThumbnails(null);
        }
    };

    const removeItem = (value: number): void => {
        const list = data.imageDetails;
        const updatedList = list.filter((item) => item.id !== value);
        setData({ ...data, imageDetails: updatedList });
    };

    const editItem = (value: number): void => {
        const currentItem = data.imageDetails.find((item) => item.id === value);
        if (currentItem) {
            setInputValue({
                id: currentItem.id,
                firstName: currentItem.firstName,
                lastName: currentItem.lastName,
                relation: currentItem.relation
            });
        }
        setOpenModal(true);
        setItemId(value);
    };

    const resetValues = (): void => {
        setItemId(0);
        setOpenModal(false);
        setInputValue({
            id: data.imageDetails.length,
            firstName: null,
            lastName: null,
            relation: null
        });
    };

    const updateItem = (value: number, content: ImageIdentificationData): void => {
        const list = data.imageDetails;
        const objIndex = list.findIndex((obj) => obj.id === value);
        list[objIndex].firstName = content.firstName;
        list[objIndex].lastName = content.lastName;
        list[objIndex].relation = content.relation;
        setData({ ...data, imageDetails: list });
        setItemId(0);
    };

    const sendImageOnData = (): void => {
        setData({ ...data, exerciseImage, exerciseImageType });
    };

    const saveItem = async (): Promise<void> => {
        if (user) {
            if (user.role === Roles.THERAPIST && stepper === '03') {
                setStepper('04');
            } else {
                const storage = codeStorage(user);
                if (storage !== null) {
                    const openWait = setTimeout((): void => {
                        setOpenWaitModal(true);
                    }, 5000);
                    const timeOutWait = setTimeout((): void => {
                        setOpenWaitModal(false);
                        setOpenModalError(true);
                    }, 60000);
                    const item = await saveExerciseItem(data, storage);
                    if (item) {
                        if (imageSize !== 0) {
                            downloadData('new_picture', user, imageSize);
                        }
                        // here
                        if (item === 'error') {
                            clearTimeout(timeOutWait);
                            clearTimeout(openWait);
                            setOpenWaitModal(false);
                            setOpenModalError(true);
                        } else {
                            clearTimeout(timeOutWait);
                            clearTimeout(openWait);
                            setOpenWaitModal(false);
                            setOpenModalError(false);
                            if (thisSelectedImage && thisSelectedImage.length > 1) {
                                setData({
                                    ...data,
                                    photoCapturedPlace: undefined,
                                    photoCapturedOn: undefined,
                                    anecdote: undefined,
                                    theme: undefined,
                                    answer: undefined,
                                    imageDetails: []
                                });
                                setCrop({ x: 0, y: 0 });
                                setZoom(1);
                                setImageToCrop(thisSelectedImage.slice(1));
                                setStepper('01');
                            } else {
                                setOpenEndModal(true);
                            }
                        }
                    }
                }
            }
        }
    };

    const errorModal = (
        <div>
            <img className={classes.warningImage} src={bugDog} alt="dog" />
            <Typography variant="h3" color="#374653" sx={{ textAlign: 'center', marginTop: '3%' }}>
                {t('oups...')}
            </Typography>
            <Typography
                variant="h3"
                color="#374653"
                sx={{ textAlign: 'center', marginTop: '2%', marginBottom: '1.5%' }}>
                {t('littleBug')}
            </Typography>
            <Typography
                variant="subtitle1"
                my={2}
                sx={{ textAlign: 'center', fontSize: '1.25rem !important' }}>
                {t('makeSure')}
            </Typography>
            <ButtonContainer mt={3} mb={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                <ModalButtons
                    variant="outlined"
                    sx={{
                        border: '1px solid #8B004B',
                        boxShadow: '0px 0px 0px 2px rgba(208, 0, 112, 0.4)',
                        borderRadius: '12px',
                        width: '30%'
                    }}
                    onClick={(): void => {
                        closeErrorModal();
                    }}>
                    <Typography variant="subtitle1" color="#D00070">
                        {t('again')}
                    </Typography>
                </ModalButtons>
            </ButtonContainer>
        </div>
    );

    const endModal = (
        <div style={{ padding: isSmallWidth ? '' : '20px 30px 40px 30px' }}>
            <Typography
                variant="h4"
                color="#004680"
                sx={{
                    fontWeight: 700,
                    textAlign: 'center',
                    marginBottom: '20px'
                }}>
                {contentReceived ? t('modifiedThumbnails') : t('photoImport')}
            </Typography>
            <Typography
                variant="h6"
                color="#004680"
                sx={{
                    fontWeight: 600,
                    textAlign: 'center',
                    paddingBottom: '40px'
                }}>
                {contentReceived ? t('clickForLeave') : t('returnToMediatheque')}
            </Typography>
            <Button
                fullWidth
                disableElevation
                variant="outlined"
                size="large"
                sx={{ border: '3px solid rgba(179, 4, 98, 0.69)', marginBottom: '10px' }}
                onClick={(): void => {
                    window.location.reload();
                }}>
                <Typography variant="subtitle1" color="#D00070">
                    {t('yes').toUpperCase()}
                </Typography>
            </Button>
            <Button
                fullWidth
                disableElevation
                variant="outlined"
                size="large"
                sx={{ border: '3px solid rgba(57, 139, 206, 0.69)', marginBottom: '10px' }}
                onClick={(): void => {
                    window.location.reload();
                }}>
                <Typography variant="subtitle1" color="#3387CC">
                    {t('noThanks')}
                </Typography>
            </Button>
        </div>
    );

    const identifyPeoplePresentData = (
        <div className={classes.modalStyle}>
            <Typography
                variant="h4"
                color="#004680"
                sx={{
                    fontWeight: 700,
                    margin: '20px 0px'
                }}>
                {t('identifyPeoplePresent')}
            </Typography>
            <InputComponent
                placeholder={t('firstName')}
                value={inputValue.firstName}
                type="text"
                className={classes.input}
                isMultiline={false}
                onTextChange={(value: string): void => handleModalChange('firstName', value)}
            />
            <InputComponent
                placeholder={t('name')}
                value={inputValue.lastName}
                type="text"
                className={classes.input}
                isMultiline={false}
                onTextChange={(value: string): void => handleModalChange('lastName', value)}
            />
            <SelectComponent
                dropdownList={dropdownList}
                placeholder={t('link')}
                onTextChange={(value: string): void => {
                    const index = data.imageDetails.slice(-1).pop();
                    setInputValue({
                        ...inputValue,
                        relation: value,
                        id: index ? index.id + 1 : data.imageDetails.length + 1
                    });
                }}
                value={inputValue.relation}
                classCustom={classes.input}
                placeholderStyling={classes.selectPlaceholder}
            />
            <Button
                fullWidth
                disableElevation
                variant="contained"
                disabled={disabled}
                onClick={(): void => {
                    resetValues();
                    if (itemId > 0) {
                        updateItem(itemId, inputValue);
                    } else {
                        handleImageChange(inputValue);
                        setItemCount(itemCount + 1);
                    }
                }}
                sx={{
                    background: '#E1F4F4',
                    color: '#279898',
                    marginBottom: '12px',
                    '&:hover': {
                        backgroundColor: '#E1F4F4'
                    }
                }}>
                {t('confirmIdentification')}
            </Button>
            <Button
                fullWidth
                disableElevation
                variant="contained"
                onClick={(): void => {
                    resetValues();
                    setOpenModal(false);
                }}
                sx={{
                    background: '#E3E6E8',
                    color: '#374653',
                    marginBottom: '12px',
                    '&:hover': {
                        backgroundColor: '#E3E6E8'
                    }
                }}>
                {t('cancelIdentification')}
            </Button>
        </div>
    );

    const waitModalContent = (
        <div>
            <img className={classes.warningImage} src={waitPicture} alt="wait" />
            <Typography variant="h3" color="#374653" sx={{ textAlign: 'center', marginTop: '3%' }}>
                {t('downloadInProgress')}
            </Typography>
            <Typography
                variant="h3"
                color="#374653"
                sx={{ textAlign: 'center', marginTop: '2%', marginBottom: '1.5%' }}>
                {t('pleaseWait')}
            </Typography>
            <CircularProgress sx={{ display: 'flex', margin: 'auto' }} />
            <Typography
                variant="body1"
                color="#374653"
                mt={2}
                sx={{
                    textAlign: 'center',
                    fontWeight: 550,
                    color: '#D00070',
                    fontSize: '1.33rem !important'
                }}>
                {t('noted')}
            </Typography>
            <Typography
                variant="subtitle1"
                my={2}
                sx={{ textAlign: 'center', fontSize: '1.25rem !important' }}>
                {t('linkToYourConnection')}
            </Typography>
        </div>
    );

    return (
        <Box flex={1} display="flex" flexDirection="column" height="100vh">
            <IdentifyPersonModal
                isModelOpen={openModal}
                setModelOpen={setOpenModal}
                body={identifyPeoplePresentData}
                onCancel={(): void => {
                    resetValues();
                }}
            />
            <IdentifyPersonModal
                isModelOpen={openEndModal}
                setModelOpen={setOpenEndModal}
                body={endModal}
                onCancel={(): void => {
                    if (setMedia) {
                        setMedia(null);
                    }
                }}
            />
            <DeactiveModall
                isModelOpen={openModalError}
                setModelOpen={setOpenModalError}
                body={errorModal}
                circleIcon
                onCancel={(): void => {
                    closeErrorModal();
                }}
            />
            <DeactiveModall
                isModelOpen={openWaitModal}
                setModelOpen={setOpenWaitModal}
                body={waitModalContent}
                circleIcon={false}
                onCancel={(): void => {}}
            />
            {stepper === '01' && (
                <ImageFrame
                    activeStep={0}
                    maxStep={user?.role === Roles.THERAPIST ? 4 : 3}
                    title={t('cropImage')}
                    onBackClick={(): void => {
                        setCropImage('');
                        if (setMedia) {
                            setMedia('');
                        }
                    }}
                    onNextClick={(): void => {
                        setStepper('02');
                    }}
                    isDisabled>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                        <Header text={t('squareFormatRequired')} />
                        <Box
                            sx={{
                                position: 'relative',
                                height: '50vh',
                                width: '50vh',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                objectFit: 'contain',
                                marginTop: isSmallWidth ? '9%' : '5%'
                            }}>
                            <Cropper
                                image={getImageKitUrlFrom(image)}
                                crop={crop}
                                zoom={zoom}
                                minZoom={0.5}
                                restrictPosition={false}
                                aspect={1}
                                onCropChange={setCrop}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '50vh',
                                marginTop: '2%',
                                marginBottom: '2%'
                            }}>
                            <Button
                                onClick={(): void => setZoom(zoom - 0.2 >= 0.5 ? zoom - 0.2 : 0.5)}>
                                <img src={zoomOut} alt="zoom out" />
                            </Button>
                            <Slider
                                value={zoom}
                                sx={{ color: '#1876D2' }}
                                min={0.5}
                                max={3}
                                step={0.1}
                                aria-labelledby="Zoom"
                                onChange={(e, thisZoom): void => setZoom(thisZoom as number)}
                            />
                            <Button onClick={(): void => setZoom(zoom + 0.2 <= 3 ? zoom + 0.2 : 3)}>
                                <img src={zoomIn} alt="zoom in" />
                            </Button>
                        </Box>
                    </Box>
                </ImageFrame>
            )}
            {stepper === '02' && result === null && <CircularProgress style={{ margin: 'auto' }} />}
            {stepper === '02' && result !== null && (
                <ImageFrame
                    activeStep={1}
                    maxStep={user?.role === Roles.THERAPIST ? 4 : 3}
                    title={
                        user?.role === Roles.ADMIN || user?.role === Roles.THERAPIST
                            ? t('identifyPeoplePresent')
                            : t('identifyPerson')
                    }
                    onBackClick={(): void => {
                        if (contentReceived !== undefined && setModifyThumbnails) {
                            setModifyThumbnails(null);
                        } else {
                            setStepper('01');
                            setCropImage('');
                        }
                    }}
                    onNextClick={(): void => {
                        if (contentReceived === undefined) {
                            sendImageOnData();
                        }
                        setStepper('03');
                    }}
                    isDisabled={data.imageDetails.length <= 7}>
                    <IdentifyPerson
                        selectImage={getImageKitUrlFrom(result)}
                        data={data}
                        showModal={(): void => setOpenModal(true)}
                        editItem={(value: number): void => editItem(value)}
                        deleteItem={(value: number): void => {
                            removeItem(value);
                            setItemCount(itemCount - 1);
                        }}
                        itemCount={itemCount}
                    />
                </ImageFrame>
            )}
            {stepper === '03' && (
                <ImageFrame
                    maxStep={user?.role === Roles.THERAPIST ? 4 : 3}
                    activeStep={2}
                    title={t('qualifyPhoto')}
                    onBackClick={(): void => setStepper('02')}
                    onNextClick={saveItem}
                    isDisabled={enabledSubmit}
                    isSubmitButton={user?.role !== Roles.THERAPIST}
                    isloadButton>
                    <FillDetails
                        content={data}
                        selectImage={getImageKitUrlFrom(result)}
                        setEnabled={(value: boolean, content: ExerciseItem | null): void => {
                            setEnabledSubmit(value);
                            if (value && content) {
                                setData({
                                    ...data,
                                    photoCapturedPlace: content.photoCapturedPlace,
                                    photoCapturedOn: content.photoCapturedOn,
                                    anecdote: content.anecdote,
                                    theme: content.theme,
                                    answer: content.answer
                                });
                            }
                        }}
                    />
                </ImageFrame>
            )}
            {stepper === '04' && user?.role === Roles.THERAPIST && (
                <ImageFrame
                    maxStep={user?.role === Roles.THERAPIST ? 4 : 3}
                    activeStep={3}
                    title={t('sharePicture')}
                    onBackClick={(): void => setStepper('03')}
                    onNextClick={saveItem}
                    isDisabled
                    isSubmitButton
                    isloadButton>
                    <SelectPatient
                        selectImage={getImageKitUrlFrom(result)}
                        content={data}
                        setDataPatient={(content: (string | undefined)[]): void => {
                            if (content) {
                                setData({
                                    ...data,
                                    patientList: content
                                });
                            }
                        }}
                    />
                </ImageFrame>
            )}
        </Box>
    );
}
