import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UserContext } from '../providers/UserProvider';
import rotateScreenIcon from '../assets/rotateScreenIcon.png';
import { UserDataProp } from '../models/userDataProp';
import { Roles } from '../models/Roles';
import circleCloseIcon from '../assets/circleClose.png';

const Logo = styled('img')({
    width: '48px',
    height: '48px',
    marginLeft: 'none',
    position: 'absolute',
    top: '20px',
    right: '20px',
    cursor: 'pointer'
});

interface ScreenOrientationPopupProps {
    isMobile: Boolean;
    isTablet: Boolean;
    isApple: Boolean;
    screenOrientation: String;
}

export function ScreenOrientationPopup({
    isMobile,
    isTablet,
    isApple,
    screenOrientation
}: ScreenOrientationPopupProps): JSX.Element {
    const { t } = useTranslation();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [displayScreenOrientationPopup, setDisplayScreenOrientationPopup] = useState(
        (isMobile || isTablet || isApple) &&
            user &&
            user.role === Roles.PATIENT &&
            screenOrientation === 'portrait'
    );

    useEffect(() => {
        if ((isMobile || isTablet || isApple) && user && user.role === Roles.PATIENT) {
            if (screenOrientation === 'landscape') setDisplayScreenOrientationPopup(false);
            else setDisplayScreenOrientationPopup(true);
        }
    }, [screenOrientation]);

    displayScreenOrientationPopup
        ? (document.body.style.overflow = 'hidden')
        : (document.body.style.overflow = 'auto');

    useEffect(() => {
        if (!displayScreenOrientationPopup) document.body.style.overflow = 'auto';
        else document.body.style.overflow = 'hidden';
    }, [displayScreenOrientationPopup]);

    return displayScreenOrientationPopup ? (
        <Box
            style={{
                position: 'fixed',
                top: '0',
                left: '0',
                backgroundColor: 'rgba(4, 5, 5, 0.7)',
                zIndex: 1000,
                width: '100%',
                height: '100%'
            }}>
            <Box
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'white',
                    borderRadius: '45px',
                    padding: '7%',
                    width: '75%',
                    height: 'auto'
                }}>
                <Typography
                    variant="h4"
                    style={{
                        fontFamily: 'Luciole-regular',
                        fontSize:
                            // eslint-disable-next-line no-nested-ternary
                            isMobile && !isTablet ? '1em' : isTablet ? '28px' : '22px',
                        fontWeight: '700',
                        width: '100%',
                        textAlign: 'center',
                        justifyContent: 'center',
                        paddingBottom: '7%',
                        color: '#004680',
                        marginTop: !isTablet ? '15%' : ''
                    }}>
                    {isTablet
                        ? t('rotateScreen', { device: t('tablet') })
                        : t('rotateScreen', { device: t('device') })}
                </Typography>
                <img
                    src={rotateScreenIcon}
                    alt="rotateScreenIcon"
                    style={{
                        width: '80%',
                        height: 'auto'
                    }}
                />
                <Logo
                    style={{
                        width: !isTablet && !isApple ? '30px' : '48px',
                        height: !isTablet && !isApple ? '30px' : '48px'
                    }}
                    src={circleCloseIcon}
                    alt="circleCloseIcon"
                    onClick={(): void => {
                        setDisplayScreenOrientationPopup(false);
                    }}
                />
            </Box>
        </Box>
    ) : (
        <div />
    );
}
