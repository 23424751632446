import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminFormLayout } from '../../layouts/AdminFormLayout';
import addEstablishment from '../../assets/addEstablishment.png';
import { Establishment } from '../../models/Establishment';

export function AddEstablishment(): React.ReactElement {
    const { t } = useTranslation();
    return (
        <AdminFormLayout
            subtitleDescription={t('addFormEstablishmentDescription')}
            data={new Establishment()}
            backgroundImage={addEstablishment}
            columnSize="715px"
            formFor="establishment"
        />
    );
}
