import * as React from 'react';
import logoBack from '../../assets/logoBack.svg';
import logoClose from '../../assets/logoClose.svg';

interface ModalProps {
    type: 'back' | 'close';
    func: Function;
}

export function LittleButton({ type, func }: ModalProps): JSX.Element {
    return (
        <button
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                padding: '9px',
                gap: '8px',
                backgroundColor: '#FBFBFB',
                width: '30px',
                height: '30px',
                border: '1px solid #E3E6E8',
                borderRadius: '6px'
            }}
            type="button"
            onClick={(): void => func()}>
            {type === 'back' && <img src={logoBack} alt="back" height="100%" />}
            {type === 'close' && <img src={logoClose} alt="close" height="100%" />}
        </button>
    );
}
