import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import editImgIcon from '../../assets/editImg.png';
import { getImageKitUrlFrom } from '../../utilities/utils';

const useStyles = makeStyles(() =>
    createStyles({
        currentUserInitials: {
            display: 'flex',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            margin: '0'
        },
        profileImage: {
            height: '120px',
            width: '120px',
            margin: 'auto',
            objectFit: 'cover'
        }
    })
);

const DefaultLogo = styled.div`
    border-radius: 50%;
    background: #efe7d7;
    height: 5vmin;
`;

const FamilyProfilePhoto = styled.img`
    height: 3vmin;
    object-fit: 'contain';
    border-radius: 50%;
    @media (max-width: 598px) : {
        height: 5vh;
    }
`;

const EditIconStyle = styled('img')({
    height: '40px',
    width: '40px',
    position: 'absolute',
    marginTop: '-118px',
    marginLeft: '20px'
});

interface LogoInterface {
    photo: string | undefined;
    firstName: string;
    lastName: string;
    updateImage: Function;
}

export const Logo = ({ photo, firstName, lastName, updateImage }: LogoInterface): JSX.Element => {
    const classes = useStyles();
    const inputFile = useRef<HTMLInputElement>(null);
    const [initials, setInitials] = useState<string>('');

    useEffect(() => {
        setInitials(firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase());
    }, []);

    const triggerImageUpload = (): void => {
        if (inputFile && inputFile.current) {
            inputFile.current.click();
        }
    };

    return (
        <div style={{ position: 'relative' }}>
            {!photo && (
                <DefaultLogo className={classes.profileImage}>
                    <div style={{ height: '5vmin' }}>
                        <Typography
                            variant="h1"
                            sx={{ fontWeight: 'bold' }}
                            className={classes.currentUserInitials}>
                            {initials}
                        </Typography>
                    </div>
                </DefaultLogo>
            )}
            {photo && (
                <FamilyProfilePhoto
                    src={getImageKitUrlFrom(photo)}
                    alt="logo"
                    className={classes.profileImage}
                    style={{ objectFit: 'cover' }}
                />
            )}
            <div>
                <EditIconStyle src={editImgIcon} alt="editImgIcon" onClick={triggerImageUpload} />
                <input
                    type="file"
                    ref={inputFile}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                        updateImage(event)
                    }
                    accept="image/*"
                    multiple={false}
                    style={{ display: 'none', cursor: 'pointer' }}
                />
            </div>
        </div>
    );
};
