/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import { blue } from '@mui/material/colors';
import { Avatar, Box, CardActionArea, Grid, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { styled, makeStyles, createStyles } from '@mui/styles';
import { Howl, Howler } from 'howler';
import { ExerciseHeader } from '../../components/ExerciseHeader';
import { Exercise } from '../../models/Exercise';
import { ExerciseItem } from '../../models/ExerciseItem';
import {
    getExerciseItem,
    getPatientUser,
    getTherapistUser,
    updateErrorCount,
    updateClueCount
} from '../../services/cloudFirestore';
import { determineDifficultyLevel, getImageKitUrlFrom, randomPicture } from '../../utilities/utils';
import { UserContext } from '../../providers/UserProvider';
import { Patient } from '../../models/Patient';
import { Roles } from '../../models/Roles';
import { Therapist } from '../../models/Therapist';
import { UserDataProp } from '../../models/userDataProp';
import { ExerciseType } from '../../models/ExerciseType';
// eslint-disable-next-line import/no-cycle
import { MemorinCard } from '../../components/MemorinCard';
import { SuccessModal } from '../../components/SuccessModal';
import { ReviewExerciseAlerts } from '../common/ReviewExerciseAlerts';

export interface CardObj {
    id: string;
    image: string | File;
    flipped: boolean;
    found: boolean;
    activeClue: boolean;
    correct: boolean;
    incorrect: boolean;
    idNumber: number;
    alreadyTurn: boolean;
}

const useStyles = makeStyles(() =>
    createStyles({
        profileImage: {
            display: 'inline',
            marginRight: '10px',
            width: '70px',
            height: '70px',
            alignSelf: 'center',
            justifySelf: 'center',
            objectFit: 'cover'
        },
        currentUserInitials: {
            display: 'flex',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            margin: '0'
        }
    })
);

const DefaultLogo = styled('div')({
    borderRadius: '50%',
    background: '#efe7d7',
    position: 'relative'
});

interface LocationState {
    exercises: Exercise;
    exercisesItem: ExerciseItem[];
    random: number;
}

export function MemorinExercise(): JSX.Element {
    const classes = useStyles();
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const location = useLocation<LocationState>();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [initials, setInitials] = useState<string>('');
    const [exercise, setExercise] = useState<Exercise>();
    const [author, setAuthor] = useState<Therapist>({
        firstName: undefined,
        lastName: undefined,
        emailId: undefined,
        dob: undefined,
        fonction: undefined,
        establishmentName: undefined,
        mobile: undefined,
        firstStepFormFields: [],
        secondStepFormFields: []
    });
    const [patient, setPatient] = useState<Patient>({
        firstName: undefined,
        lastName: undefined,
        emailId: undefined,
        dob: undefined,
        sex: undefined,
        level: undefined,
        category: undefined,
        familyCode: undefined,
        legalReferent: undefined,
        establishmentName: undefined,
        establishmentCode: undefined,
        startDate: undefined,
        endDate: undefined,
        address: undefined,
        pincode: undefined,
        city: undefined,
        familyMembers: undefined,
        memoryDifficultyLevel: undefined,
        memorinDifficultyLevel: undefined,
        puzzleDifficultyLevel: undefined,
        completedExerciseList: undefined,
        firstStepFormFields: [],
        secondStepFormFields: []
    });
    const [exerciseItemList, setExerciseItemList] = useState<ExerciseItem[]>([]);
    const [cardsItem, setCardsItem] = useState<CardObj[]>([]);
    const [selectedCards, setSelectedCards] = useState<CardObj[]>([]);
    const [turns, setTurns] = useState<number>(0);
    const [score, setScore] = useState<number>(0);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openReview, setOpenReview] = useState(false);
    const [activClue, setActivClue] = useState(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [source, setSource] = useState<boolean>(false);
    const [firstError, setFirstError] = useState<boolean>(true);

    const [time, setTime] = React.useState<number>(10);

    const isWidthOfXS = (): boolean => {
        const { innerWidth: width, innerHeight: height } = window;
        if (width < 1000 || height < 800) {
            return true;
        }
        return false;
    };

    const displayClue = (): void => {
        if (cardsItem.length) {
            if (selectedCards.length === 0) {
                let loop2 = true;
                const newState = cardsItem.map((oneCard) => {
                    if (!oneCard.flipped && !oneCard.found && loop2) {
                        loop2 = false;
                        return { ...oneCard, activeClue: true };
                    }
                    return oneCard;
                });
                setCardsItem(newState);
            }
            if (selectedCards.length === 1) {
                const newState = cardsItem.map((oneCard) => {
                    if (oneCard.id === selectedCards[0].id && !oneCard.flipped) {
                        return { ...oneCard, activeClue: true };
                    }
                    return oneCard;
                });

                setCardsItem(newState);
            }
        }
    };

    const [alreadyUpdateCount, setAlreadyUpdateCount] = useState<boolean>(false);
    const updateCount = async (): Promise<void> => {
        if (
            !alreadyUpdateCount &&
            user &&
            user.id &&
            user.role === Roles.PATIENT &&
            exercise &&
            exercise.id &&
            exercise.exerciseType
        ) {
            setAlreadyUpdateCount(true);
            await updateClueCount(exercise.id, user.id, exercise.exerciseType);
            const countErrorAndClue = localStorage.getItem('countErrorAndClue');
            if (countErrorAndClue) {
                const newCountErrorAndClue = Number(countErrorAndClue) + 1;
                localStorage.setItem('countErrorAndClue', newCountErrorAndClue.toString());
            }
        }
    };

    const tick = (): void => {
        if (time === 0) {
            setTime(10);
            displayClue();
        }
        if (time > 0) {
            setTime(time - 1);
        }
    };

    useEffect(() => {
        const timerId = setInterval(() => tick(), 1000);
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        return () => clearInterval(timerId);
    });

    const isHeightOfXS = (): boolean => {
        const { innerWidth: width, innerHeight: height } = window;
        if (width < 550 || height < 550) {
            return true;
        }
        if (height <= 768 && height > 551) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        if (user?.role === Roles.PATIENT) {
            const startTimer = localStorage.getItem('startTime');
            const count = localStorage.getItem('countErrorAndClue');
            if (!startTimer) {
                localStorage.setItem('startTime', new Date().toString());
            }
            if (!count) {
                localStorage.setItem('countErrorAndClue', '0');
            }
        }
    }, []);

    const handleClick = (cardIndex: number, card: CardObj): void => {
        setTime(10);
        if (selectedCards.length < 2) {
            if (cardsItem) {
                if (cardsItem[cardIndex].flipped === true || cardsItem[cardIndex].found === true) {
                    return;
                }

                const newState = cardsItem.map((ownCard, index) => {
                    if (index === cardIndex) {
                        return { ...ownCard, flipped: true, activeClue: false, alreadyTurn: true };
                    }
                    return { ...ownCard, activeClue: false };
                });
                setCardsItem(newState);
            }
            if (card.flipped === true) {
                return;
            }
            if (card.found === true) {
                return;
            }
            setSelectedCards([...selectedCards, card]);
        }
    };

    useEffect(() => {
        if (selectedCards.length === 2) {
            if (cardsItem) {
                setTurns(turns + 1);

                let newState: CardObj[] = [];
                let firstState: CardObj[] = [];
                if (selectedCards[0].id === selectedCards[1].id) {
                    setScore(score + 1);
                    firstState = cardsItem.map((oneCard) => {
                        if (oneCard.idNumber === selectedCards[1].idNumber) {
                            return { ...oneCard, correct: true };
                        }
                        return oneCard;
                    });
                    setTimeout(() => {
                        setCardsItem(firstState);
                    }, 500);

                    newState = cardsItem.map((oneCard) => {
                        if (oneCard.id === selectedCards[0].id) {
                            return { ...oneCard, found: true, flipped: false };
                        }
                        return oneCard;
                    });

                    setActivClue(false);
                    // if (score === 0) {
                    setTimeout(() => {
                        setCardsItem(newState);
                        setSelectedCards([]);

                        setIsSuccess(true);
                        if (score === 0 || score + 1 === cardsItem.length / 2) setOpenModal(true);
                        // startAudio();
                        setTimeout(() => {
                            // stopAudio();
                            setOpenModal(false);
                            setTime(10);
                        }, 3000);
                    }, 2000);
                    // }
                } else {
                    if (selectedCards[1].alreadyTurn === true) {
                        if (
                            exercise &&
                            user &&
                            user.id &&
                            exercise.id &&
                            user.role === Roles.PATIENT
                        ) {
                            updateErrorCount(exercise.id, user.id);
                            updateCount();
                        }
                    }
                    firstState = cardsItem.map((oneCard) => {
                        if (oneCard.id === selectedCards[1].id) {
                            return { ...oneCard, incorrect: true };
                        }
                        return oneCard;
                    });
                    newState = cardsItem.map((oneCard) => {
                        if (
                            (oneCard.id === selectedCards[0].id ||
                                oneCard.id === selectedCards[1].id) &&
                            !activClue
                        ) {
                            return { ...oneCard, flipped: false, incorrect: false };
                        }
                        if (oneCard.id === selectedCards[1].id && activClue) {
                            return { ...oneCard, flipped: false, incorrect: false };
                        }
                        return oneCard;
                    });
                    setTimeout(() => {
                        setCardsItem(firstState);
                    }, 1500);
                    setTimeout(() => {
                        setCardsItem(newState);
                        if (activClue) {
                            setSelectedCards([selectedCards[0]]);
                        } else {
                            setSelectedCards([]);
                        }
                        setIsSuccess(false);
                        if (firstError) setOpenModal(true);
                        setTimeout(() => {
                            setFirstError(false);
                            setOpenModal(false);
                            setTime(10);
                        }, 3000);
                    }, 3500);
                }
            }
        }
    }, [selectedCards]);

    useEffect(() => {
        setTimeout(() => {
            if (score === cardsItem.length / 2 && score > 0) {
                setOpenReview(true);
            }
        }, 5000);
    }, [score]);

    const getItemList = async (globalExercise: Exercise, patientData?: Patient): Promise<void> => {
        let listArray: number[] = [];
        const list: ExerciseItem[] = [];
        const listIds: string[] = [];
        const level: number =
            patientData && patientData.memorinDifficultyLevel
                ? patientData.memorinDifficultyLevel
                : 4;
        listArray = randomPicture(level, location.state.random, ExerciseType.MEMORIN);
        if (globalExercise.exerciseImages) {
            await Promise.all(
                listArray.map(async (element) => {
                    if (globalExercise.exerciseImages && globalExercise.exerciseImages[element]) {
                        const string = globalExercise.exerciseImages[element];
                        if (string) {
                            const item = await getExerciseItem(string);
                            if (user && user.role === Roles.PATIENT && item && item.id) {
                                list.push(item);
                                listIds.push(item.id);
                            }
                            if (user && user.role === Roles.THERAPIST && item && item.id) {
                                list.push(item);
                                listIds.push(item.id);
                            }
                        }
                    }
                })
            );
            setExerciseItemList(list);
        }
    };

    useEffect(() => {
        const getExerciseList = async (): Promise<void> => {
            if (user && user.id) {
                const patientData = await getPatientUser(user.id);
                if (patientData) {
                    setPatient(patientData);
                }
                const globalExercise = location.state.exercises;
                const arrayToAddImg = [
                    '26e4de56-0f0e-4ace-9e6c-dd3c440026ef',
                    'a22d2136-1cd0-4536-8de8-d187c271b2ad',
                    'a47f8b3e-48a9-4bca-9523-e7d5a59b9770',
                    'fd56249c-3939-4d56-8310-104ae8e9b040',
                    'c22217df-5cba-468b-8df8-3d6c1fa0fade',
                    '8e3452ab-b61d-4672-9b3f-6b498a8500c9',
                    'af9f5eca-5959-41bc-b706-9faf933ba591',
                    'df5b7a89-5708-4e97-825f-54315dcb6028',
                    'b00afe3f-318b-4bcb-bcc8-b44f1b3b741e',
                    '81e908b6-a35a-4989-a021-70e372575579'
                ];
                // eslint-disable-next-line no-plusplus
                for (let i = 0; i < 18; i++) {
                    if (globalExercise.exerciseImages && !globalExercise.exerciseImages[i]) {
                        globalExercise.exerciseImages.push(arrayToAddImg[i - 8]);
                    }
                }
                setExercise(globalExercise);

                if (user && user.role === Roles.THERAPIST) {
                    await getItemList(globalExercise);
                } else if (user.role === Roles.PATIENT) {
                    if (
                        id !== undefined &&
                        id !== null &&
                        location.state.random !== undefined &&
                        patientData !== null
                    ) {
                        await getItemList(globalExercise, patientData);
                        if (globalExercise && globalExercise.authorId) {
                            const authorData = await getTherapistUser(globalExercise.authorId);
                            if (authorData) {
                                setAuthor(authorData);
                                if (authorData.firstName && authorData.lastName) {
                                    setInitials(
                                        authorData.firstName.charAt(0).toUpperCase() +
                                            authorData.lastName.charAt(0).toUpperCase()
                                    );
                                }
                            }
                        }
                    } else {
                        const list: string[] = [];
                        setExerciseItemList(location.state.exercisesItem);
                        location.state.exercisesItem.forEach((element) => {
                            if (element.id) {
                                list.push(element.id);
                            }
                        });
                        if (globalExercise && globalExercise.authorId) {
                            const authorData = await getTherapistUser(globalExercise.authorId);
                            if (authorData) {
                                setAuthor(authorData);
                                if (authorData.firstName && authorData.lastName) {
                                    setInitials(
                                        authorData.firstName.charAt(0).toUpperCase() +
                                            authorData.lastName.charAt(0).toUpperCase()
                                    );
                                }
                            }
                        }
                    }
                }
            }
        };
        getExerciseList();
    }, [id]);

    useEffect(() => {
        if (exerciseItemList.length) {
            const temp: CardObj[] = [];
            let tempIdNumber = 0;
            exerciseItemList.map(async (item) => {
                if (item.id && item.exerciseImage) {
                    tempIdNumber += 1;
                    const temp1: CardObj = {
                        id: item.id,
                        image: item.exerciseImage,
                        flipped: false,
                        found: false,
                        activeClue: false,
                        correct: false,
                        incorrect: false,
                        idNumber: tempIdNumber,
                        alreadyTurn: false
                    };
                    tempIdNumber += 1;
                    const temp2: CardObj = {
                        id: item.id,
                        image: item.exerciseImage,
                        flipped: false,
                        found: false,
                        activeClue: false,
                        correct: false,
                        incorrect: false,
                        idNumber: tempIdNumber,
                        alreadyTurn: false
                    };
                    temp.push(temp1);
                    temp.push(temp2);
                }
            });
            setCardsItem(temp.sort(() => Math.random() - 0.5));
        }
    }, [exerciseItemList]);

    const handleClueClick = (): void => {
        if (selectedCards.length === 0) {
            setActivClue(true);
            let loop = true;
            // eslint-disable-next-line array-callback-return
            cardsItem.map((item, index) => {
                if (!item.flipped && !item.found && loop) {
                    loop = false;
                    handleClick(index, item);
                }
            });
        }
        if (selectedCards.length === 1) {
            const newState = cardsItem.map((oneCard) => {
                if (oneCard.id === selectedCards[0].id && !oneCard.flipped) {
                    return { ...oneCard, activeClue: true };
                }
                return oneCard;
            });

            setCardsItem(newState);
        }
    };

    return (
        <Box flex={1} display="flex" flexDirection="column" height="100%">
            {/* <audio style={{ display: 'none' }} src="/applause-sound-effect.mp3">
                <track kind="captions" />
            </audio> */}
            <SuccessModal
                isModelOpen={openModal}
                setModelOpen={setOpenModal}
                isSuccess={isSuccess}
                isFirework={isSuccess}
                isPlaySound={isSuccess}
                nbModal={!isSuccess ? 1 : score === cardsItem.length / 2 ? 0 : (score % 2) + 1}
            />
            {openReview && (
                <ReviewExerciseAlerts
                    exercise={exercise}
                    exerciseItemList={exerciseItemList}
                    exerciseType={ExerciseType.MEMORIN}
                    difficultyLevel={
                        patient.memoryDifficultyLevel ? patient.memoryDifficultyLevel : 0
                    }
                    nbTurn={turns}
                    nbFind={score}
                />
            )}
            <ExerciseHeader
                exerciseId={exercise?.id}
                exerciseName={exercise?.exerciseName}
                difficultyLevel={determineDifficultyLevel(
                    patient && patient.memorinDifficultyLevel ? patient.memorinDifficultyLevel : 0
                )}
                level={
                    patient && patient.memorinDifficultyLevel ? patient.memorinDifficultyLevel : 0
                }
                handleClueClick={handleClueClick}
                exerciseItemList={exerciseItemList}
                exercise={exercise}
                isExerciseSelected
                isMemorin
                alreadyUpdateCount={alreadyUpdateCount}
                setAlreadyUpdateCount={setAlreadyUpdateCount}
                // completedExercises={completedExercises}
            />
            <Paper
                variant="outlined"
                sx={{
                    backgroundColor: blue[50],
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '6px',
                    marginTop: '100px',
                    marginRight: '2%',
                    marginLeft: '2%',
                    marginBottom: '2vh',
                    height: isHeightOfXS()
                        ? selectedCards.length < 1
                            ? '10vh'
                            : '11vh'
                        : selectedCards.length < 1
                        ? '7vh'
                        : '9vh'
                }}>
                {author &&
                    !author.profilePhoto &&
                    author.role !== undefined &&
                    author.role !== Roles.ADMIN && (
                        <DefaultLogo
                            className={classes.profileImage}
                            style={{
                                height: isHeightOfXS() ? '12vh' : '9vh',
                                width: isHeightOfXS() ? '12vh' : '9vh'
                            }}>
                            <div style={{ height: '5vmin' }}>
                                <Typography
                                    variant="h2"
                                    sx={{ fontWeight: 'bold', color: '#374653' }}
                                    className={classes.currentUserInitials}>
                                    {initials}
                                </Typography>
                            </div>
                        </DefaultLogo>
                    )}
                {author &&
                    author.role !== undefined &&
                    author.role !== Roles.ADMIN &&
                    author.profilePhoto &&
                    typeof author.profilePhoto === 'string' && (
                        <Avatar
                            alt="userIcon"
                            src={getImageKitUrlFrom(author.profilePhoto)}
                            className={classes.profileImage}
                        />
                    )}
                <Typography
                    variant="h4"
                    my={5}
                    color="#3387CC"
                    sx={{
                        marginTop: '25px',
                        marginBottom: '25px',
                        fontFamily: user?.role === Roles.PATIENT ? 'Luciole-Regular' : ''
                    }}>
                    {selectedCards.length < 1 ? (
                        exercise?.authorType === Roles.FAMILY ? (
                            t('placeFingerOnCardMemorinFamily')
                        ) : (
                            t('placeFingerOnCardMemorin')
                        )
                    ) : exercise?.authorType === Roles.FAMILY && selectedCards.length > 0 ? (
                        <>
                            {t('placeFingerOnCardMemorinFamily2.1')}
                            {isWidthOfXS() ? <br /> : ' '}
                            {t('placeFingerOnCardMemorinFamily2.2')}
                        </>
                    ) : (
                        <>
                            {t('placeFingerOnCardMemorin2.1')}
                            {isWidthOfXS() ? <br /> : ' '}
                            {t('placeFingerOnCardMemorin2.2')}
                        </>
                    )}
                </Typography>
            </Paper>
            <Box sx={{ height: '100%' }}>
                <Grid
                    container
                    sx={{ width: '100%', margin: 'auto', height: '100%', overflow: 'hidden' }}>
                    {cardsItem.map((card, index) => {
                        return (
                            <MemorinCard
                                // eslint-disable-next-line react/no-array-index-key
                                key={`card_number_${index}`}
                                card={card}
                                difficultyLvl={
                                    patient.memorinDifficultyLevel
                                        ? patient.memorinDifficultyLevel - 1
                                        : 0
                                }
                                handleClick={handleClick}
                                cardIndex={index}
                            />
                        );
                    })}
                </Grid>
            </Box>
        </Box>
    );
}
