import { Grid } from '@mui/material';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { useLongPress } from 'use-long-press';
import MemorinCardBack from '../assets/MemorinCardBack.svg';
// eslint-disable-next-line import/no-cycle
import { CardObj } from '../pages/patient/MemorinExercise';
import click from '../assets/click.png';
import memorinIncorrect from '../assets/memorinIncorrect.svg';
import memorinCorrect from '../assets/memorinCorrect.svg';

const useStyles = makeStyles(() =>
    createStyles({
        Animation: {
            animationName: `$Animation`,
            animationDuration: '4s',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'linear'
        },
        '@keyframes Animation': {
            '0%': {
                transform: 'translate(-26px, -40px) rotate(-45deg) scale(1)'
            },
            '25%': {
                transform: 'translate(-26px, -40px) rotate(-45deg) scale(0.7)'
            },
            '50%': {
                transform: 'translate(-26px, -40px) rotate(-45deg) scale(1)'
            },
            '75%': {
                transform: 'translate(-26px, -40px) rotate(-45deg) scale(0.7)'
            },
            '100%': {
                transform: 'translate(-26px, -40px) rotate(-45deg) scale(1)'
            }
        }
    })
);

interface MemorinCardProps {
    card: CardObj;
    difficultyLvl: number;
    handleClick: (cardIndex: number, card: CardObj) => void;
    cardIndex: number;
    // card: CardObj;
    // cardIndex: number;
    // handleClick: (cardIndex: number, card: CardObj) => void;
}

export function MemorinCard({
    card,
    difficultyLvl,
    handleClick,
    cardIndex
}: MemorinCardProps): JSX.Element {
    const classes = useStyles();
    // const [show, setShow] = useState<boolean>(false);
    const sizeCardsByDifficultyLevel = [6, 4, 3, 3, 3, 3, 3, 2, 2, 2];
    const imageContainer = useRef<HTMLInputElement>(null);

    // useEffect(() => {
    //     // setShow(card.flipped);
    // }, [card]);

    const bindLongPress = useLongPress(
        (event) => {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            if (!card.found) {
                handleClick(cardIndex, card);
            }
        },
        {
            threshold: 1000
        }
    );

    return (
        <Grid
            item
            xs={sizeCardsByDifficultyLevel[difficultyLvl]}
            sx={{
                padding: '5px',
                height: 'auto',
                display: 'block',
                flexBasis: difficultyLvl + 1 === 4 ? '20% !important' : 'none'
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...bindLongPress()}
            onClick={(): void => {
                if (!card.found) {
                    handleClick(cardIndex, card);
                }
            }}>
            <div
                ref={imageContainer}
                style={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    display: 'inline-block',
                    overflow: 'hidden',
                    margin: '0'
                }}>
                {!card.flipped && !card.found && (
                    <img
                        style={{
                            display: 'block',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            height: '100%',
                            width: 'auto',
                            transform: 'translate(-50%, -50%)'
                        }}
                        src={MemorinCardBack}
                        alt="MemorinCardBack"
                    />
                )}
                {(card.flipped || card.found) && (
                    <img
                        style={{
                            display: 'block',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            maxHeight: '100%',
                            maxWidth: '100%',
                            height: '100%',
                            width: 'auto',
                            transform: 'translate(-50%, -50%)',
                            borderRadius: '7px',
                            // eslint-disable-next-line no-nested-ternary
                            border: card.incorrect
                                ? '5px solid #EEF300'
                                : card.correct
                                ? '5px solid #00DF76'
                                : '1.5px solid #E3E6E8'
                        }}
                        src={card.image.toString()}
                        alt="cardFront"
                    />
                )}
                {card.found && (
                    <div
                        style={{
                            display: 'block',
                            position: 'absolute',
                            top: '0%',
                            left: '0%',
                            height: '100%',
                            width: '100%',
                            // transform: 'translate(-26px, -40px) rotate(-45deg)',
                            // transform: 'rotate(-45deg)',
                            zIndex: '3',
                            backgroundColor: 'rgba(255, 255, 255, 0.62)'
                        }}
                    />
                )}
                {!(card.flipped || card.found) && card.activeClue && (
                    <img
                        src={click}
                        alt="click"
                        className={classes.Animation}
                        style={{
                            display: 'block',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            height: '50%',
                            width: 'auto',
                            transform: 'translate(-26px, -40px) rotate(-45deg)',
                            // transform: 'rotate(-45deg)',
                            zIndex: '3'
                        }}
                    />
                )}
                {card.correct && card.flipped && (
                    <img
                        src={memorinCorrect}
                        alt="memorinCorrect"
                        style={{
                            display: 'block',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            height: '50%',
                            width: 'auto',
                            transform: 'translate(-50%, -50%)',
                            zIndex: '5'
                        }}
                    />
                )}
                {card.incorrect && card.flipped && (
                    <img
                        src={memorinIncorrect}
                        alt="memorinIncorrect"
                        style={{
                            display: 'block',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            height: '50%',
                            width: 'auto',
                            transform: 'translate(-50%, -50%)',
                            zIndex: '5'
                        }}
                    />
                )}
            </div>
        </Grid>
    );
}
