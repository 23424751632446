import * as React from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import { useLongPress } from 'use-long-press';
import Modal from '@mui/material/Modal';
import circleCloseIcon from '../assets/circleClose.png';
import squareCloseIcon from '../assets/squareClose.png';

const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 265,
    maxHeight: '95%',
    overflow: 'auto',
    bgcolor: 'background.paper',
    borderRadius: '24px',
    boxShadow: 24,
    p: 2,
    outline: 'none',
    width: '90%'
};

const Logo = styled.img`
    object-fit: inherit;
    width: 40px;
    height: 40px;
`;

interface DeactiveModalProps {
    isModelOpen: boolean;
    setModelOpen: Function;
    body: JSX.Element;
    circleIcon: boolean;
    onCancel: Function;
}

export function DeactiveModall({
    isModelOpen,
    setModelOpen,
    body,
    circleIcon,
    onCancel
}: DeactiveModalProps): JSX.Element {
    const bindLongPress = useLongPress(
        () => {
            onCancel();
        },
        { threshold: 1000 }
    );

    const bodyContents = (
        <Box sx={style}>
            <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
                {circleIcon && (
                    <Logo
                        style={{ marginLeft: circleIcon ? 'auto' : 'none', cursor: 'pointer' }}
                        src={circleCloseIcon}
                        alt="circleCloseIcon"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...bindLongPress()}
                        onClick={(): void => {
                            onCancel();
                        }}
                    />
                )}
                {body}
            </Box>
        </Box>
    );

    return (
        <div>
            <Modal
                open={isModelOpen}
                onClose={(): void => {
                    setModelOpen(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                {bodyContents}
            </Modal>
        </div>
    );
}
