export const formatVideoUrl = (link: string): string => {
    const youtubeSubString = 'youtube.com/';
    const youtubeSubstring2 = 'youtu.be/';
    const embedString = 'embed/';
    if (link.includes(youtubeSubString) || link.includes(youtubeSubstring2)) {
        const position = link.indexOf(youtubeSubString);
        if (!link.includes('embed')) {
            const youtubeBaseUrl = 'https://youtube.com/embed/';
            const videoId = link.slice(link.length - 11);
            const url = youtubeBaseUrl.concat(videoId);
            return url;
        }
    }
    return link;
};
