import * as React from 'react';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import fr from 'date-fns/locale/fr';
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import { SxProps } from '@mui/system';
import { UserContext } from '../providers/UserProvider';
import { UserDataProp } from '../models/userDataProp';

interface DatePickerCustomProps {
    onDateChange: Function;
    value: Date | null;
    label?: string;
    sx?: SxProps;
    disabled?: boolean;
    classReceived?: string;
    readonly?: boolean;
}

const DatePick = styled(TextField)(() => ({
    '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
        color: 'rgba(0, 47, 85, 1) !important'
    }
}));

export function DatePickerCustom({
    label,
    onDateChange,
    sx,
    value,
    disabled = false,
    classReceived,
    readonly
}: DatePickerCustomProps): JSX.Element {
    const { t } = useTranslation();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={fr}>
            <DesktopDatePicker
                disabled={disabled}
                readOnly={readonly}
                label={label}
                inputFormat="dd/MM/yyyy"
                value={value}
                onChange={(newValue: Date | null): void => onDateChange(newValue)}
                renderInput={(params): React.ReactElement => (
                    <TextField
                        className={classReceived ?? ''}
                        {...params}
                        inputProps={{
                            ...params.inputProps,
                            placeholder: 'jj/mm/aaaa'
                        }}
                        sx={{ ...sx }}
                    />
                )}
            />
        </LocalizationProvider>
    );
}
