import React from 'react';

export function MemorinEmptySvgIcon(): JSX.Element {
    return (
        <svg
            width="28"
            height="26"
            viewBox="0 0 28 26"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <rect y="12.5449" width="10.4545" height="10.4545" rx="2" fill="#C4C4C4" />
            <rect x="12.5449" y="12.5449" width="10.4545" height="10.4545" rx="2" fill="#C4C4C4" />
            <rect width="10.4545" height="10.4545" rx="2" fill="#C4C4C4" />
            <rect x="12.5449" width="10.4545" height="10.4545" rx="2" fill="#C4C4C4" />
        </svg>
    );
}
