import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { DashboardCard } from '../pages/common/DashboardCard';

type Variant = 'primary' | 'secondary' | 'tertiary' | 'quaternary';

interface CardHomeInterface {
    iconImagePath: string;
    cardTitle: string;
    variant: Variant;
    buttonLabel: string;
    buttonAction: string;
    infoText: string;
    infoTitle: string;
}

export function CardHome({
    iconImagePath,
    cardTitle,
    variant,
    buttonLabel,
    buttonAction,
    infoText,
    infoTitle
}: CardHomeInterface): JSX.Element {
    const isSmallWidth = !useMediaQuery('(min-width:400px)');
    const history = useHistory();

    return (
        <DashboardCard
            iconImagePath={iconImagePath}
            cardTitle={cardTitle}
            cardButtonStyleVariant={variant}
            cardTitleColorVariant={variant}
            buttonLabel={buttonLabel}
            buttonAction={(): void => {
                history.push(buttonAction);
            }}>
            <Box>
                {!isSmallWidth && (
                    <Typography
                        color="#5C6670"
                        variant="subtitle1"
                        sx={{
                            fontWeight: 400,
                            fontStyle: 'italic',
                            fontSize: '1.03rem'
                        }}>
                        {infoText}
                    </Typography>
                )}
                <Typography
                    color="#5C6670"
                    variant="subtitle1"
                    sx={{
                        fontWeight: 400,
                        marginTop: '5%',
                        letterSpacing: '-0.1px'
                    }}>
                    {infoTitle}
                </Typography>
            </Box>
        </DashboardCard>
    );
}
