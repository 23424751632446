import { Box, Checkbox, FormControlLabel, Link, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Person } from '@mui/icons-material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import { makeStyles, styled } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { InputComponent } from '../components/FormComponents/InputComponent';
import { Header } from '../components/Header';
import { validateEmail } from '../utilities/utils';
import { signUp } from '../services/firebaseAuth';
import { PatientInfo } from './family/PatientInfo';
import { Patient } from '../models/Patient';

const useStyles = makeStyles(() => ({
    root: {
        borderColor: '#D9D9D9',
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#D9D9D9 !important'
        }
    },
    inputComponentLayout: {
        marginTop: '5%'
    }
}));

const Title = styled(Typography)({
    display: 'flex',
    alignContent: 'space-around',
    alignItems: 'center',
    justifyContent: 'center'
});

interface SignUpState {
    password: string;
    showPassword: boolean;
}

export function Register(): JSX.Element {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();

    const [familyCode, setFamilyCode] = useState<string | null>(null);
    const [email, setEmail] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [checkPatient, setCheckPatient] = useState(false);
    const [checked, setchecked] = useState<boolean>(false);

    const [values, setValues] = useState({
        password: '',
        showPassword: false,
        prop: ''
    });

    const handleChange = (prop: keyof SignUpState) => (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = (): void => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const isDisabled = (): boolean => {
        return !(
            familyCode &&
            familyCode.trim() !== '' &&
            values.password &&
            values.password.trim() !== '' &&
            values.password.trim().length >= 6 &&
            validateEmail(email) &&
            checked === true
        );
    };

    const onRegister = (patient: Patient): void => {
        setError(false);
        if (
            email &&
            values.password &&
            familyCode &&
            patient &&
            patient.startDate &&
            patient.endDate &&
            patient.establishmentCode &&
            patient.establishmentName &&
            patient.id
        ) {
            setLoading(true);
            signUp(
                email,
                values.password,
                familyCode,
                patient.endDate,
                patient.startDate,
                patient.establishmentCode,
                patient.establishmentName,
                patient.id
            )
                .then((): void => {
                    history.replace('/profile');
                })
                .catch((err): void => {
                    setLoading(false);
                    setError(true);
                    setErrorText(t('signUpError'));
                    setCheckPatient(false);
                });
        }
    };

    const onError = (code: string): void => {
        if (code === 'PATIENT_NOT_FOUND') {
            setErrorText(t('signUpErrorWrongCode'));
            setError(true);
            setCheckPatient(false);
        } else {
            setCheckPatient(false);
        }
    };

    const getSignUp = (): React.ReactElement => {
        return (
            <Box flex={1} display="flex" flexDirection="column" height="100vh">
                <Header />
                <Box maxWidth={800} margin="auto" px={2}>
                    <Title variant="h3" style={{ marginTop: '5%' }}>
                        <Person fontSize="inherit" />
                        <Typography variant="h3">{t('registration')}</Typography>
                    </Title>
                    <Typography variant="body1" color="#1E2933" mt={4} sx={{ textAlign: 'left' }}>
                        {t('familySignupDisclaimer')}
                    </Typography>
                    <InputComponent
                        placeholder={t('enterFamilyCode')}
                        value={familyCode}
                        type="text"
                        className={classes.inputComponentLayout}
                        isMultiline={false}
                        onTextChange={(value: string): void => setFamilyCode(value)}
                    />
                    <InputComponent
                        placeholder={t('enterYourEmailAddress')}
                        value={email}
                        type="email"
                        className={classes.inputComponentLayout}
                        isMultiline={false}
                        onTextChange={(value: string): void => setEmail(value)}
                    />
                    <OutlinedInput
                        id="password"
                        fullWidth
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password}
                        placeholder={t('enterPersonalPassword')}
                        onChange={handleChange('password')}
                        inputProps={{
                            style: { color: '#002F55' },
                            className: classes.root
                        }}
                        sx={{ marginTop: '5%' }}
                        className={classes.root}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end">
                                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <Box mt={5} sx={{ display: 'flex' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                    color="success"
                                    checked={checked}
                                    onChange={(): void => setchecked(!checked)}
                                />
                            }
                            label={
                                <Typography variant="body1" mt={1} style={{ textAlign: 'left' }}>
                                    <span>{t('disclaimer')} </span>
                                    <span>
                                        <Link
                                            color="secondary"
                                            href="https://app.stimulin.fr/Cgu.pdf">
                                            {t('generalConditions')}
                                        </Link>
                                    </span>
                                    <span> {t('and')} </span>
                                    <span>
                                        <Link
                                            color="secondary"
                                            href="https://app.stimulin.fr/Confidential.pdf">
                                            {t('confidential')}
                                        </Link>
                                    </span>
                                    <span> {t('stimulinApp')}</span>
                                </Typography>
                            }
                        />
                    </Box>
                </Box>
                {error ? (
                    <Typography variant="body1" color="error">
                        {errorText}
                    </Typography>
                ) : null}
                <Box
                    sx={{
                        backgroundColor: 'linear-gradient(0deg, #FBFBFB, #FBFBFB)',
                        borderTop: '1px solid #ACBCC7',
                        marginTop: '5%'
                    }}>
                    <Box maxWidth={800} margin="auto" px={2}>
                        <LoadingButton
                            style={{ marginTop: '5%' }}
                            fullWidth
                            loading={loading}
                            variant="outlined"
                            disabled={isDisabled()}
                            size="large"
                            onClick={(): void => setCheckPatient(true)}>
                            {t('familyRegister')}
                        </LoadingButton>
                        <Typography variant="body1" sx={{ margin: '3% 0% 2% 0%' }}>
                            <span>{t('alreadyHaveAccount')} </span>
                            <span>
                                <Button
                                    color="secondary"
                                    onClick={(): void => history.push('/login')}>
                                    {t('logIn')}
                                </Button>
                            </span>
                        </Typography>
                    </Box>
                </Box>
            </Box>
        );
    };

    return (
        <Box>
            {checkPatient ? (
                <PatientInfo code={familyCode!} onSuccess={onRegister} onError={onError} />
            ) : (
                getSignUp()
            )}
        </Box>
    );
}
