import { GRID } from '../../typings';

import { fillGrid } from '..';

/**
 * A function to create a full valid sudoku grid
 */
function createFullGrid(): GRID {
    const grid: GRID = [
        [0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0]
    ];
    fillGrid(grid);
    return grid;
}

// eslint-disable-next-line import/no-default-export
export default createFullGrid;
