import {
    Box,
    Button,
    Card,
    CardContent,
    Typography,
    CardMedia,
    Avatar,
    CardActionArea
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import closeIcon from '../assets/closeIcon.png';
import PlayButton from '../assets/PlayButton.svg';
import PlayButtonVideo from '../assets/PlayButtonVideo.svg';
import LittlePlayButtonVideo from '../assets/LittlePlayButtonVideo.svg';
import PlayButtonYT from '../assets/PlayButtonYoutube.svg';
import warningDelete from '../assets/warningDelete.png';
import { DeactiveModall } from './ContainerModal';
// eslint-disable-next-line import/no-cycle
import { UserContext } from '../providers/UserProvider';
// eslint-disable-next-line import/no-cycle
import {
    deletePersonalVideo,
    deletePersonalYoutubeVideo,
    getUser
} from '../services/cloudFirestore';
import { UserDataProp } from '../models/userDataProp';
import { MediaInformation } from '../models/MediaInformation';
import { formatVideoUrl } from '../services/formatFunctions';
import { codeStorage, getImageKitUrlFrom, YoutubeAPIKey, youtube_parser } from '../utilities/utils';
import { Roles } from '../models/Roles';
import { VideoPlayer } from './VideoPlayer';
import { FullScreenModal } from './FullScreenModal';

const useStyles = makeStyles(() => ({
    exerciseName: {
        letterSpacing: '-0.02em',
        textAlign: 'center',
        // alignSelf: 'center',
        justifySelf: 'center',
        maxHeight: '90px',
        alignItems: 'center',
        fontWeight: 500
    },
    cardContent: {
        display: 'grid',
        overflow: 'hidden',
        padding: '0 5px !important',
        minHeight: '80px'
    },
    buttonCard: {
        // '@media (max-width: 598px)': {
        //     minHeight: '1vh'
        // },
        '@media (min-width: 598px)': {
            minHeight: '20vh'
        }
    },
    boxCard: {
        width: '100%',
        boxShadow: 'none',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '0 !important'
    },
    videoDiv: {
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        width: '100.5%',
        writingMode: 'vertical-rl'
    },
    videoMedia: {
        marginTop: '10px',
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: 'black',
        borderRadius: '6px'
    },
    media: {
        marginTop: '10px',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: '6px'
    },
    underline: {
        textDecoration: 'underline'
    },
    warningImage: {
        display: 'flex',
        width: '200px',
        margin: 'auto'
    },
    profileImage: {
        float: 'right',
        background: 'white'
    },
    currentUserInitials: {
        display: 'flex',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        margin: '0'
    },
    divVideoDisplay: {
        animationName: `$fade-out`,
        animationDuration: '2s'
    },
    '@keyframes fade-out': {
        from: {
            opacity: 1
        },
        to: {
            opacity: 0
        }
    }
}));

const DefaultLogo = styled.div`
    border-radius: 50%;
    background: #efe7d7;
    height: 27px;
`;

const ControlButtons = styled(Button)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    border: 'none',
    borderRadius: '3px',
    margin: '5% 0%',
    backgroundColor: '#F4E0EB',
    color: '#D00070',
    '&:hover': {
        backgroundColor: '#F4E0EB',
        border: 'none'
    }
}));

const ModalButtons = styled(Button)(() => ({
    '@media (max-width: 598px)': {
        width: '100%',
        margin: '2% 0%'
    },
    '@media (min-width: 598px)': {
        width: '30%'
    }
}));

const ButtonContainer = styled(Box)({
    '@media (max-width: 598px)': {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        marginBottom: '0 !important'
    },
    '@media (min-width: 598px)': {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row'
    }
});

const CloseLogoContainer = styled.div`
    margin-top: 15px;
`;

const AuthorInfoContainer = styled.div`
    position: absolute;
    z-index: 2;
    right: 0px;
    border-radius: 50%;
    color: rgba(251, 251, 251, 1);
    max-width: 150px;
    align-items: center;
    display: flex;
`;

const LogoClose = styled.img`
    height: 40px;
    background-color: #fbfbfb;
    border: 1px solid #e3e6e8;
    padding: 6px;
    border-radius: 5px;
    cursor: pointer;
`;

const ImageBox = styled(Box)({
    position: 'relative'
});

const ImageContainer = styled('img')({
    height: '210px',
    width: '210px',
    objectFit: 'cover'
});

interface CardDataProps {
    onCardClicked: Function;
    onLeaveCard: Function;
    item: MediaInformation;
    classReceived?: string;
    crudCard?: boolean;
    isSelected?: boolean;
    youtubePage?: boolean;
    mediaTypeToShow?: string;
    desactivatePlayButton?: boolean;
    activePP?: boolean;
    littlePlayBtn?: boolean;
    bgColor?: string;
}

export function VideoCards({
    onCardClicked,
    onLeaveCard,
    item,
    classReceived,
    crudCard,
    isSelected,
    youtubePage,
    mediaTypeToShow,
    desactivatePlayButton,
    activePP,
    littlePlayBtn,
    bgColor
}: CardDataProps): JSX.Element {
    const classes = useStyles();
    const { t } = useTranslation();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [clickedCard, setClickedCard] = useState<string | undefined>('');
    const [renderDeleteExerciseModal, setDeleteModal] = useState(false);
    const [profilePicturePatient, setProfilePicturePatient] = useState<string>('');
    const [initials, setInitials] = useState<string>('');
    const [video, setVideo] = useState<string>('');
    const [checkAvatar, setCheckAvatar] = useState(false);
    const [authorItemRole, setAuthorItemRole] = useState<string>('');
    const [videoThumbnail, setVideoThumbnail] = useState<string>();
    const [pictureLoad, setPictureLoad] = useState<boolean>(false);
    const width = window.innerWidth;
    const [displayVideo, setDisplayVideo] = useState<boolean>(false);
    const [isVideoLoaded, setIsVideoLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (item.videoUrl && typeof item.videoUrl === 'string' && youtubePage) {
            setVideo(formatVideoUrl(item.videoUrl));
        }
        if (item.videoUrl && typeof item.videoUrl === 'string' && !youtubePage) {
            setVideo(item.videoUrl);
        }
    }, []);

    const handleDeleteExercise = async (): Promise<void> => {
        if (clickedCard && user) {
            let communityId = codeStorage(user);
            if (communityId === 'admin' && user && user.userId) {
                communityId = user.userId;
            }
            if (communityId && item.videoUrl && typeof item.videoUrl === 'string') {
                if (youtubePage) {
                    await deletePersonalYoutubeVideo(communityId, item.videoUrl);
                } else {
                    await deletePersonalVideo(communityId, item.videoUrl);
                }
                setDeleteModal(false);
                window.location.reload();
            }
        }
    };

    const click = (): void => {
        onLeaveCard();
        setClickedCard('');
    };

    useEffect(() => {
        const getPatientAssign = async (): Promise<void> => {
            if (item && item.authorId) {
                const authorItem = await getUser(item.authorId);
                if (authorItem && authorItem.role) {
                    setCheckAvatar(true);
                    setAuthorItemRole(authorItem.role);
                    if (authorItem?.profilePhoto && typeof authorItem.profilePhoto === 'string') {
                        setProfilePicturePatient(authorItem.profilePhoto);
                    } else if (authorItem.firstName && authorItem.lastName) {
                        setInitials(
                            authorItem?.firstName.charAt(0).toUpperCase() +
                                authorItem?.lastName.charAt(0).toUpperCase()
                        );
                    }
                }
            }
        };

        getPatientAssign();
    }, []);

    const iconButtonElement = (): JSX.Element => {
        return (
            <div style={{ margin: 'auto' }}>
                {profilePicturePatient === '' && (
                    <DefaultLogo
                        className={classes.profileImage}
                        style={{
                            height: width < 520 ? '35px' : '53px',
                            width: width < 520 ? '35px' : '53px'
                        }}>
                        <div style={{ height: '5vmin' }}>
                            <Typography
                                variant="subtitle1"
                                sx={{ fontWeight: 'bold', color: '#374653' }}
                                className={classes.currentUserInitials}>
                                {initials}
                            </Typography>
                        </div>
                    </DefaultLogo>
                )}
                {user && profilePicturePatient !== '' && (
                    <Avatar
                        alt="userIcon"
                        src={getImageKitUrlFrom(profilePicturePatient)}
                        className={classes.profileImage}
                        style={{
                            height: width < 520 ? '35px' : '53px',
                            width: width < 520 ? '35px' : '53px'
                        }}
                    />
                )}
            </div>
        );
    };

    const getVideoThumbnail = async (): Promise<string | undefined> => {
        if (video && !videoThumbnail && !pictureLoad && !video.includes('firebase')) {
            setPictureLoad(true);
            await fetch(
                `https://www.googleapis.com/youtube/v3/videos?id=${youtube_parser(
                    video
                )}&part=snippet&key=${YoutubeAPIKey}`
            )
                .then((response) => {
                    return response.json();
                })
                .then((json) => {
                    setVideoThumbnail(json.items[0].snippet.thumbnails.medium.url);
                })
                .catch(Error);
        }
        return undefined;
    };
    getVideoThumbnail();

    const ExerciseDetailCard = (
        <CardActionArea
            onClick={(): void => {
                if (crudCard) {
                    onCardClicked(item.title);
                    setClickedCard(item.title);
                }
            }}>
            {activePP &&
                item.authorId &&
                item.authorId !== '' &&
                authorItemRole !== Roles.ADMIN &&
                checkAvatar && (
                    <AuthorInfoContainer
                        style={{
                            backgroundColor:
                                authorItemRole === Roles.FAMILY ? '#D00070' : '#00FFFF',
                            marginRight: width < 520 ? '12px' : '15px',
                            marginTop: width < 520 ? '12px' : '15px',
                            height: width < 520 ? '40px' : '60px',
                            width: width < 520 ? '40px' : '60px'
                        }}>
                        {iconButtonElement()}
                    </AuthorInfoContainer>
                )}
            <Box sx={{ position: 'relative' }}>
                {youtubePage ? (
                    <ImageBox
                        style={{
                            height: width < 520 ? 'calc(100vw / 2 - 40px)' : '200px',
                            width: width < 520 ? 'calc(100vw / 2 - 40px)' : '200px',
                            marginTop: '10px',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            borderRadius: '6px'
                        }}>
                        <ImageContainer
                            // className={classes.media}
                            onLoad={(): void => setIsVideoLoaded(true)}
                            style={{
                                height: width < 520 ? 'calc(100vw / 2 - 40px)' : '200px',
                                width: width < 520 ? 'calc(100vw / 2 - 40px)' : '200px',
                                borderRadius: '6px',
                                // eslint-disable-next-line no-nested-ternary
                                visibility: !bgColor
                                    ? 'visible'
                                    : isVideoLoaded
                                    ? 'visible'
                                    : 'hidden'
                            }}
                            src={videoThumbnail}
                            alt="videoThumbnail"
                        />
                    </ImageBox>
                ) : (
                    <video
                        className={classes.videoMedia}
                        controls={false}
                        onLoadedData={(): void => setIsVideoLoaded(true)}
                        style={{
                            height: width < 520 ? 'calc(100vw / 2 - 39.5px)' : '200px',
                            width: width < 520 ? 'calc(100vw / 2 - 39.5px)' : '200px',
                            // eslint-disable-next-line no-nested-ternary
                            visibility: !bgColor ? 'visible' : isVideoLoaded ? 'visible' : 'hidden'
                        }}>
                        <track
                            src="your video"
                            kind="captions"
                            srcLang="fr"
                            label="french_captions"
                        />
                        <source src={`${video}#t=0.01`} id="your video" type="video/mp4" />
                    </video>
                )}
                {bgColor && (
                    <Box
                        className={`${youtubePage ? null : classes.videoMedia} ${
                            isVideoLoaded ? classes.divVideoDisplay : null
                        }`}
                        style={{
                            height:
                                width < 520
                                    ? `calc(100vw / 2 - ${youtubePage ? '40' : '39.5'}px)`
                                    : '200px',
                            width:
                                width < 520
                                    ? `calc(100vw / 2 - ${youtubePage ? '40' : '39.5'}px)`
                                    : '200px',
                            backgroundColor: bgColor,
                            visibility: 'visible',
                            borderRadius: '6px',
                            opacity: isVideoLoaded ? 0 : 1,
                            position: 'absolute',
                            top: 0,
                            left: '10px',
                            zIndex: 50
                        }}
                    />
                )}
                {width >= 520 && !littlePlayBtn && !desactivatePlayButton && (
                    <img
                        src={
                            // eslint-disable-next-line no-nested-ternary
                            mediaTypeToShow === 'video'
                                ? PlayButtonVideo
                                : mediaTypeToShow === 'videoYT'
                                ? PlayButtonYT
                                : PlayButton
                        }
                        alt="PlayButton"
                        style={{
                            height: '100px',
                            width: '100px',
                            top: '60px',
                            left: 'calc(100% / 2 - 50px)',
                            position: 'absolute'
                        }}
                    />
                )}
                {littlePlayBtn && (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                    <img
                        src={LittlePlayButtonVideo}
                        alt="LittlePlayButtonVideo"
                        style={{
                            height: width < 520 ? '40px' : '50px',
                            width: width < 520 ? '40px' : '50px',
                            top:
                                width < 520
                                    ? 'calc((100vw / 2 - 20px) / 2 - 20px)'
                                    : 'calc(200px / 2 - 25px)',
                            left:
                                width < 520
                                    ? 'calc((100vw / 2 - 20px) / 2 - 20px)'
                                    : 'calc(100% / 2 - 25px)',
                            position: 'absolute',
                            zIndex: '50'
                        }}
                        onClick={(e): void => {
                            e.stopPropagation();
                            setDisplayVideo(true);
                        }}
                    />
                )}
                <CardContent className={classes.cardContent}>
                    <Typography
                        variant="h4"
                        className={classes.exerciseName}
                        color="#6B00AD"
                        sx={{
                            fontWeight: 500,
                            fontFamily: user?.role === Roles.PATIENT ? 'Luciole-Regular' : 'Inter',
                            fontSize: width < 520 ? '0.95rem !important' : '1.25rem !important',
                            lineHeight: user?.role === Roles.PATIENT ? '1.4rem' : '1.4rem',
                            marginTop:
                                // eslint-disable-next-line no-nested-ternary
                                user?.role === Roles.PATIENT || width >= 520
                                    ? '13px !important'
                                    : '0px !important',
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2
                        }}>
                        {item.title}
                    </Typography>
                </CardContent>
            </Box>
        </CardActionArea>
    );

    const ExerciseTitleCardControls = (
        <div
            style={{
                position: 'absolute',
                backgroundColor: '#FFFFFFD9',
                zIndex: '100',
                // marginBottom: '16px',
                height: width < 520 ? 'calc(100vw / 2 + 20px - 2px)' : 'calc(290px - 2px)',
                width: width < 520 ? 'calc(100vw / 2 - 20px - 2px)' : 'calc(220px - 2px)',
                borderRadius: '9px'
            }}>
            <CloseLogoContainer onClick={(): void => click()}>
                <LogoClose src={closeIcon} alt="CloseButton" style={{ borderRadius: '50%' }} />
            </CloseLogoContainer>
            <Box
                className={classes.buttonCard}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: width < 520 ? '0px 3px' : '85px 15px'
                }}>
                <ControlButtons
                    variant="outlined"
                    onClick={(): void => {
                        setDeleteModal(true);
                    }}>
                    <Typography variant="subtitle1" color="#D00070">
                        {t('toDelete')}
                    </Typography>
                    <DeleteIcon sx={{ height: '30px', width: '30px', color: '#D00070' }} />
                </ControlButtons>
            </Box>
        </div>
    );

    const ExerciseTitleCard = (
        <Card
            style={{
                flexGrow: 0,
                // marginBottom: '16px',
                height: width < 520 ? 'calc(100vw / 2 + 20px)' : 'calc(290px - 2px)',
                width: width < 520 ? 'calc(100vw / 2 - 20px)' : 'calc(220px - 2px)',
                borderRadius: '9px',
                display: 'flex'
            }}
            className={classReceived}>
            {/* {isSelected ? ExerciseTitleCardControls : ExerciseDetailCard} */}
            {ExerciseDetailCard}
            {isSelected && ExerciseTitleCardControls}
        </Card>
    );

    const DeleteModalContent = (
        <div>
            {!youtubePage && (
                <img className={classes.warningImage} src={warningDelete} alt="warning" />
            )}
            <Typography variant="h3" color="#374653" sx={{ textAlign: 'center', marginTop: '3%' }}>
                {t('wantToDeleteVideo')}
            </Typography>
            <Typography
                variant="subtitle1"
                color="#374653"
                mt={2}
                sx={{ textAlign: 'center', fontWeight: 500, color: '#D00070' }}>
                {!youtubePage ? `${t('attention')}.` : t('disclaimerToDeletevideo')}
            </Typography>
            {!youtubePage && (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="subtitle1" mb={2} style={{ textAlign: 'center' }}>
                        <span>{t('ifYes')} </span>
                        <span className={classes.underline}>{t('allExercises')}</span>
                        <span> {t('inUse')} </span>
                        <span className={classes.underline}>{t('deleted')}</span>
                    </Typography>
                </Box>
            )}
            <ButtonContainer mt={3} mb={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                <ModalButtons
                    variant="outlined"
                    sx={{
                        border: '1px solid #8B004B',
                        boxShadow: '0px 0px 0px 2px rgba(208, 0, 112, 0.4)',
                        borderRadius: '12px',
                        marginRight: '5%',
                        width: '30%'
                    }}
                    onClick={(): Promise<void> => handleDeleteExercise()}>
                    <Typography variant="subtitle1" color="#D00070">
                        {!youtubePage ? t('yesSure') : t('yes')}
                    </Typography>
                </ModalButtons>
                <ModalButtons
                    variant="outlined"
                    sx={{
                        border: '1px solid #3387CC',
                        boxShadow: '0px 0px 0px 2px rgba(59, 125, 191, 0.6)',
                        borderRadius: '12px',
                        width: '30%'
                    }}
                    onClick={(): void => setDeleteModal(false)}>
                    <Typography variant="subtitle1" color="#3387CC">
                        {!youtubePage ? t('noSure') : t('toCancel')}
                    </Typography>
                </ModalButtons>
            </ButtonContainer>
        </div>
    );

    const closePlayer = (): void => {
        setDisplayVideo(false);
    };

    return (
        <Box>
            {displayVideo && item.videoUrl && (
                <Box
                    style={{
                        height: '100%',
                        width: '100%',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        zIndex: '200'
                    }}>
                    <VideoPlayer
                        videoSrc={item.videoUrl.toString()}
                        timeAutoPlay={2000}
                        btnClose
                        closeFun={closePlayer}
                    />
                </Box>
            )}
            {ExerciseTitleCard}
            <DeactiveModall
                isModelOpen={renderDeleteExerciseModal}
                setModelOpen={setDeleteModal}
                body={DeleteModalContent}
                circleIcon
                onCancel={(): void => {
                    setDeleteModal(false);
                }}
            />
        </Box>
    );
}
