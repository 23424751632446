import React, { useState, useEffect } from 'react';
import { styled, Box, Typography, Modal, Button } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useTranslation } from 'react-i18next';
import circleCloseIcon from '../assets/circleClose.png';
import { validatePassword } from '../utilities/utils';
import { changePassword } from '../services/firebaseAuth';

const Logo = styled('img')({
    objectFit: 'inherit',
    width: '48px',
    height: '48px',
    marginLeft: 'none'
});

const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: '24px',
    boxShadow: 24,
    p: 2
};

const RootModal = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        marginRight: '8%',
        minWidth: '325px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        minWidth: '600px'
    },
    [theme.breakpoints.up('md')]: {
        minWidth: '820px'
    },
    '@media (max-width: 300px)': {
        minWidth: '260px'
    }
}));

const ChangePasswordBtn = styled(Button)(() => ({
    marginTop: '5%',
    borderRadius: '3px',
    border: 'none',
    width: '100%',
    '&:hover': {
        backgroundColor: '#E1F4F4',
        border: 'none'
    }
}));

const BodyBox = styled(Box)(({ theme }) => ({
    [theme.breakpoints.between('sm', 'md')]: {
        padding: '20px 80px'
    },
    [theme.breakpoints.up('md')]: {
        padding: '80px 120px'
    }
}));

const SpanContainer = styled('span')(() => ({
    padding: '4px 8px',
    borderRadius: '5px',
    marginRight: '5px'
}));

interface ChangePasswordProps {
    isModelOpen: boolean;
    setModelOpen: Function;
    onCancel: Function;
    onButtonClick: Function;
}

export function ChangePassword({
    isModelOpen,
    setModelOpen,
    onCancel,
    onButtonClick
}: ChangePasswordProps): JSX.Element {
    const { t } = useTranslation();
    const [disabled, setDisabled] = useState<boolean>(true);
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [hiddenConfirm, setHiddenConfirm] = useState<boolean>(false);
    const [hiddenNew, setHiddenNew] = useState<boolean>(false);
    const [active, setActive] = useState<number[]>([]);
    const [isPasswordError, setPasswordError] = useState<boolean>(false);

    useEffect(() => {
        if (
            newPassword &&
            confirmPassword &&
            active.includes(0) &&
            active.includes(1) &&
            active.includes(2) &&
            active.includes(3)
        ) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [newPassword, confirmPassword]);

    useEffect(() => {
        setActive(validatePassword(newPassword));
    }, [newPassword]);

    const saveDetails = async (): Promise<void> => {
        await changePassword(newPassword);
        onButtonClick();
    };

    const bodyContents = (
        <RootModal sx={style}>
            <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
                <Logo
                    src={circleCloseIcon}
                    alt="circleCloseIcon"
                    onClick={(): void => {
                        setNewPassword('');
                        setConfirmPassword('');
                        onCancel();
                    }}
                />
                <BodyBox>
                    <Typography
                        variant="h4"
                        color="#004680"
                        sx={{
                            textAlign: 'center',
                            fontWeight: 700,
                            marginBottom: '40px'
                        }}>
                        {t('changeMyPassword')}
                    </Typography>
                    <OutlinedInput
                        id="password"
                        fullWidth
                        type={hiddenNew ? 'text' : 'password'}
                        value={newPassword}
                        placeholder={t('newPassword')}
                        onChange={(event): void => setNewPassword(event.target.value)}
                        sx={{ marginBottom: '10px', color: '#004680' }}
                        endAdornment={
                            <InputAdornment position="end">
                                {newPassword.length > 0 && (
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={(): void => setHiddenNew(!hiddenNew)}
                                        edge="end">
                                        {hiddenNew ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                )}
                            </InputAdornment>
                        }
                    />
                    <Box>
                        <SpanContainer
                            sx={{ background: active.includes(0) ? '#DCF2EB' : '#F7F5F0' }}>
                            <Typography
                                variant="caption"
                                sx={{ color: active.includes(0) ? '#03A172' : '#33454F' }}
                                fontWeight="500">
                                {t('8characters')}
                            </Typography>
                        </SpanContainer>
                        <SpanContainer
                            sx={{ background: active.includes(1) ? '#DCF2EB' : '#F7F5F0' }}>
                            <Typography
                                variant="caption"
                                fontWeight="500"
                                sx={{ color: active.includes(1) ? '#03A172' : '#33454F' }}>
                                {t('1lowerCase')}
                            </Typography>
                        </SpanContainer>
                        <SpanContainer
                            sx={{ background: active.includes(2) ? '#DCF2EB' : '#F7F5F0' }}>
                            <Typography
                                variant="caption"
                                fontWeight="500"
                                sx={{ color: active.includes(2) ? '#03A172' : '#33454F' }}>
                                {t('1upperCase')}
                            </Typography>
                        </SpanContainer>
                        <SpanContainer
                            sx={{ background: active.includes(3) ? '#DCF2EB' : '#F7F5F0' }}>
                            <Typography
                                variant="caption"
                                fontWeight="500"
                                sx={{ color: active.includes(3) ? '#03A172' : '#33454F' }}>
                                {t('1digit')}
                            </Typography>
                        </SpanContainer>
                    </Box>
                    <OutlinedInput
                        id="confirm-password"
                        fullWidth
                        type={hiddenConfirm ? 'text' : 'password'}
                        value={confirmPassword}
                        placeholder={t('confirmNewPassword')}
                        onChange={(event): void => {
                            setConfirmPassword(event.target.value);
                            setPasswordError(false);
                        }}
                        sx={{ marginTop: '20px', color: '#004680' }}
                        endAdornment={
                            <InputAdornment position="end">
                                {confirmPassword.length > 0 && (
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={(): void => setHiddenConfirm(!hiddenConfirm)}
                                        edge="end">
                                        {hiddenConfirm ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                )}
                            </InputAdornment>
                        }
                    />
                    {isPasswordError && (
                        <Typography variant="body2" color="#f44336">
                            {t('passwordDontMatch')}
                        </Typography>
                    )}
                    <ChangePasswordBtn
                        disabled={disabled}
                        variant="outlined"
                        size="large"
                        sx={{
                            backgroundColor: '#E1F4F4',
                            marginTop: '40px',
                            opacity: disabled ? '0.5' : '1'
                        }}
                        onClick={(): void => {
                            if (newPassword !== confirmPassword) {
                                setPasswordError(true);
                            } else {
                                setPasswordError(false);
                                saveDetails();
                            }
                        }}>
                        <Typography variant="subtitle1" color="#279898">
                            {t('changeMyPassword')}
                        </Typography>
                    </ChangePasswordBtn>
                </BodyBox>
            </Box>
        </RootModal>
    );

    return (
        <div>
            <Modal
                open={isModelOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                {bodyContents}
            </Modal>
        </div>
    );
}
