import React, { useState, useEffect, createContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import firebase from 'firebase/compat';
import * as Sentry from '@sentry/react';
// eslint-disable-next-line import/no-cycle
import { logOut } from '../services/firebaseAuth';
import { User } from '../models/User';
// eslint-disable-next-line import/no-cycle
import { getUser } from '../services/cloudFirestore';
import { fireAuth } from '../services/firebase';
import { UserDataProp } from '../models/userDataProp';

export const UserContext = createContext<UserDataProp | null>(null);

interface UserProviderProps {
    children?: React.ReactNode;
}

export const UserProvider: React.FunctionComponent<UserProviderProps> = ({
    children
}: UserProviderProps): JSX.Element => {
    // return (
    //     <Box display="flex" justifyContent="center" alignItems="center" height="100%" width="100vw">
    //         <CircularProgress />
    //     </Box>
    // );

    const [loaded, setLoaded] = useState<boolean | false>(false);
    const [user, setUser] = useState<User | null>(null);
    const history = useHistory();
    const { pathname } = useLocation();
    const [propData, setPropData] = useState<UserDataProp>({
        user: undefined,
        updateUser: undefined
    });

    const updateUser = async (): Promise<void> => {
        if (user && user.id) {
            const updatedUser: User | null = await getUser(user.id);
            setUser(updatedUser);
        }
    };

    useEffect(() => {
        setPropData({
            user,
            updateUser
        });

        if (user && user.id && user.emailId) {
            Sentry.setUser({ email: user.emailId, id: user.id });
        }
    }, [user]);

    useEffect(() => {
        let unsubscribe: firebase.Unsubscribe | undefined;
        if (
            pathname !== '/register' &&
            pathname !== '/new-account' &&
            pathname !== '/new-account-confirm' &&
            pathname !== '/forgot-password' &&
            pathname !== '/guide-utilisateurs'
        ) {
            unsubscribe = fireAuth.onAuthStateChanged(async (firebaseUser) => {
                if (firebaseUser != null) {
                    const fireUser: User | null = await getUser(firebaseUser.uid);
                    setUser(fireUser);
                    if (!fireUser) {
                        logOut(user);
                    }
                    if (
                        (fireUser?.firstName === '' || fireUser?.firstName === null) &&
                        fireUser.role === 'FAMILY'
                    ) {
                        history.replace('/profile');
                    }
                } else {
                    setUser(null);
                    history.replace('/login');
                }
                setLoaded(true);
            });
        } else {
            setLoaded(true);
        }
        return unsubscribe;
    }, [pathname]);

    return loaded ? (
        <UserContext.Provider value={propData}>{children}</UserContext.Provider>
    ) : (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%" width="100vw">
            <CircularProgress />
        </Box>
    );
};
