import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton, Typography, useMediaQuery, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import editIcon from '../../assets/edit.png';
import deleteIcon from '../../assets/delete.png';
import { ExerciseItem } from '../../models/ExerciseItem';
import { Header } from './Header';

interface IdentifyPersonProps {
    selectImage: string;
    data: ExerciseItem;
    showModal: Function;
    editItem: Function;
    deleteItem: Function;
    itemCount: number;
}

const useStyles = makeStyles(() => ({
    inputLayout: {
        width: '60%',
        '@media (min-width: 598px)': {
            width: '65%'
        }
    },
    btnLayout: {
        width: '40%',
        '@media (min-width: 598px)': {
            width: '35%'
        }
    }
}));

const SelectedImage = styled('img')({
    objectFit: 'contain',
    minWidth: '95%',
    maxWidth: '100%',
    minHeight: '95%',
    maxHeight: '50vh',
    margin: 'auto'
});

const Edit = styled('img')({
    objectFit: 'contain',
    width: '40px',
    height: '40px'
});

export function IdentifyPerson({
    selectImage,
    data,
    showModal,
    editItem,
    deleteItem,
    itemCount
}: IdentifyPersonProps): JSX.Element {
    const isSmallWidth = !useMediaQuery('(min-width:500px)');
    const { t } = useTranslation();
    const classes = useStyles();
    const [disabled, setDisabled] = useState<boolean>(false);

    useEffect(() => {
        if (itemCount === 7) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [itemCount]);

    const displayLimitWarning = (
        <Typography
            variant="body1"
            sx={{ display: itemCount === 7 ? 'block' : 'none', color: 'red' }}>
            {t('limitIsReached')}
        </Typography>
    );

    const getList = (): JSX.Element[] | null => {
        if (data.imageDetails) {
            return data.imageDetails.map((item) => {
                return (
                    <Box
                        key={item.id}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginBottom: '20px',
                            marginTop: '20px'
                        }}>
                        <Box
                            className={classes.inputLayout}
                            sx={{
                                border: '1px solid #E3E6E8',
                                paddingLeft: '10px'
                            }}>
                            <Typography
                                variant="body1"
                                color="#374653"
                                sx={{
                                    textAlign: 'left',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    width: '100%'
                                }}>
                                {item.firstName} {item.lastName}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="#ACBCC7"
                                sx={{ fontWeight: 400, textAlign: 'left' }}>
                                {item.relation}
                            </Typography>
                        </Box>
                        <Box className={classes.btnLayout}>
                            <IconButton
                                className="my-catalogue-grid"
                                onClick={(): void => {
                                    editItem(item.id);
                                }}>
                                <Edit src={editIcon} alt="editIcon" />
                            </IconButton>
                            <IconButton
                                className="my-catalogue-grid"
                                onClick={(): void => deleteItem(item.id)}>
                                <Edit src={deleteIcon} alt="deleteIcon" />
                            </IconButton>
                        </Box>
                    </Box>
                );
            });
        }
        return null;
    };
    return (
        <Box height="100%">
            <Header text={t('cropImageSubtitle')} />
            <Box
                sx={{ width: '95%', margin: 'auto', marginTop: '40px' }}
                display="flex"
                flexDirection={isSmallWidth ? 'column' : 'row'}
                justifyContent="space-between"
                alignContent="flex-start"
                alignItems="flex-start">
                <Box
                    sx={{
                        width: isSmallWidth ? '100%' : '45vw'
                    }}>
                    <SelectedImage src={selectImage} alt="CloseButton" />
                </Box>
                <Box
                    sx={{
                        width: isSmallWidth ? '100%' : '44vw'
                    }}>
                    {getList()}
                    {displayLimitWarning}
                    <div style={{ display: isSmallWidth ? 'blocl' : 'table' }}>
                        <Button
                            variant="greyBorderButton"
                            disabled={disabled}
                            sx={{
                                margin: isSmallWidth ? '10px 0px' : '',
                                width: isSmallWidth ? '100%' : 'auto',
                                float: 'left',
                                marginBottom: '6px'
                            }}
                            onClick={(): void => {
                                if (itemCount < 7) {
                                    showModal();
                                }
                            }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                {t('identifyPerson')}
                            </Typography>
                        </Button>
                        <Typography
                            style={{
                                color: '#3746539C',
                                textAlign: 'left',
                                fontSize: '0.85rem',
                                marginLeft: '10px',
                                marginBottom: '12px'
                            }}>
                            {t('champIsNotrequired')}
                        </Typography>
                    </div>
                </Box>
            </Box>
        </Box>
    );
}
