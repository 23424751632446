import React from 'react';
import { OutlinedInput, MenuItem, FormControl, Select } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

const useOutlinedInputStyles = makeStyles(() =>
    createStyles({
        root: {
            '& $notchedOutline': {
                borderColor: '#D9D9D9'
            },
            '&$focused $notchedOutline': {
                borderColor: '#B94700'
            }
        },
        focused: {},
        notchedOutline: {}
    })
);

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        formControl: {
            width: '100%'
        }
    })
);

interface SelectProps {
    dropdownList: string[];
    placeholder: string;
    onTextChange: Function;
    value: string | number | null;
    width?: string | null;
    disabled?: boolean;
    classCustom?: string;
    placeholderStyling?: string;
    readonly?: boolean;
}

export function SelectComponent({
    dropdownList,
    placeholder,
    onTextChange,
    value,
    width,
    disabled,
    classCustom,
    placeholderStyling,
    readonly
}: SelectProps): JSX.Element {
    const classes = useStyles();
    const outlinedInputClasses = useOutlinedInputStyles();

    const dropdownItems = (): JSX.Element[] => {
        return dropdownList.map(
            (item): JSX.Element => {
                return (
                    <MenuItem key={item} value={item}>
                        {item}
                    </MenuItem>
                );
            }
        );
    };

    return (
        <div className="dropdown-list">
            <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: width ?? '' }}>
                <Select
                    readOnly={readonly ?? false}
                    disabled={disabled ?? false}
                    value={value === null ? 'none' : value}
                    onChange={(e): void => onTextChange(e.target.value)}
                    className={value === null ? placeholderStyling : classCustom}
                    inputProps={{ 'aria-label': 'Without label' }}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left'
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left'
                        }
                    }}
                    input={
                        <OutlinedInput
                            name="age"
                            id="outlined-age-simple"
                            sx={{ textAlign: 'left', color: 'green' }}
                            classes={outlinedInputClasses}
                        />
                    }>
                    <MenuItem value="none" selected disabled>
                        {placeholder}
                    </MenuItem>
                    {dropdownItems()}
                </Select>
            </FormControl>
        </div>
    );
}
