import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Button } from '@mui/material';
import styled, { keyframes } from 'styled-components';
import { BrowserRouter, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import smartphoneShortcut from './assets/smartphoneShortcut.svg';
import desktopShortcut from './assets/desktopShortcut.svg';
import tabletShortcut from './assets/tabletShortcut.png';
import './i18n/config';
import { Router } from './routes';
import { ThemeConfig } from './theme';
import { UserProvider } from './providers/UserProvider';
import { ScreenOrientationPopup } from './components/ScreenOrientationPopup';

import squareLogo from './assets/squareLogo.svg';
import appleInstallAppStep1 from './assets/appleInstallAppStep1.png';
import appleInstallAppStep2 from './assets/appleInstallAppStep2.png';
import appleInstallAppStep3 from './assets/appleInstallAppStep3.png';
import circleCloseIcon from './assets/circleClose.png';

const Logo = styled('img')({
    width: '48px',
    height: '48px',
    marginLeft: 'none',
    position: 'absolute',
    top: '20px',
    right: '20px',
    cursor: 'pointer'
});

const AppContainer = styled.section`
    text-align: center;
    height: 100vh;
`;

export function App(): JSX.Element {
    const { t } = useTranslation();
    const isApple = /iPhone|iPad|iPod|Macintosh/i.test(navigator.userAgent);
    const isMobile = /Mobile|Windows Phone|Lumia|Android|webOS|iPhone|iPod|Blackberry|PlayBook|BB10|Opera Mini|\bCrMo\/|Opera Mobi/i.test(
        navigator.userAgent
    );
    let isTablet = /Tablet|iPad/i.test(navigator.userAgent);

    if (isMobile && window.innerHeight < window.innerWidth) {
        isTablet = true;
    }

    const [supportsPWA, setSupportsPWA] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [promptInstall, setPromptInstall] = useState<any>(null);
    const [displayInstallFrame, setDisplayInstallFrame] = useState(true);
    const [screenOrientation, setScreenOrientation] = useState(
        window.matchMedia('(orientation: landscape)').matches ? 'landscape' : 'portrait'
    );

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
        const handler = (e: any) => {
            e.preventDefault();
            setSupportsPWA(true);
            setPromptInstall(e);
        };
        window.addEventListener('beforeinstallprompt', handler);

        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        return () => window.removeEventListener('transitionend', handler);
    }, []);

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
    const onClick = (evt: any) => {
        evt.preventDefault();
        if (!promptInstall) {
            return;
        }
        promptInstall.prompt();
        setDisplayInstallFrame(false);
    };

    let displayMode = 'browser tab';
    if (window.matchMedia('(display-mode: standalone)').matches) {
        displayMode = 'standalone';
    }

    const handleOrientationChange = (): void => {
        if (isTablet || isMobile || isApple) {
            setScreenOrientation(
                window.matchMedia('(orientation: landscape)').matches ? 'landscape' : 'portrait'
            );
        }
    };

    useEffect(() => {
        // window.addEventListener('orientationchange', handleOrientationChange, true);
        // window.addEventListener('deviceorientation', handleOrientationChange, true);
        window.addEventListener('resize', handleOrientationChange, true);
        return (): void => {
            // window.removeEventListener('orientationchange', handleOrientationChange, true);
            // window.removeEventListener('deviceorientation', handleOrientationChange, true);
            window.removeEventListener('resize', handleOrientationChange, true);
        };
    }, []);

    return (
        <Sentry.ErrorBoundary
            // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            fallback={({ error, componentStack, resetError }) => <div>{error}</div>}>
            <ThemeConfig>
                <AppContainer>
                    {/* style={{ overflow: !appInstalled && isMobile ? 'hidden' : 'auto' }}> */}
                    <BrowserRouter>
                        <UserProvider>
                            <ScreenOrientationPopup
                                isMobile={isMobile}
                                isTablet={isTablet}
                                isApple={isApple}
                                screenOrientation={screenOrientation}
                            />
                            {displayInstallFrame &&
                                displayMode !== 'standalone' &&
                                (supportsPWA || (!supportsPWA && isApple)) &&
                                window.location.href.slice(-11) !== 'new-account' &&
                                window.location.href.slice(-19) !== 'new-account-confirm' && (
                                    <Box
                                        style={{
                                            position: 'fixed',
                                            top: '0',
                                            left: '0',
                                            backgroundColor: 'rgba(4, 5, 5, 0.7)',
                                            zIndex: 1000,
                                            width: '100%',
                                            height: '100%'
                                        }}>
                                        <Box
                                            style={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                backgroundColor: 'white',
                                                borderRadius: '10px',
                                                padding: '25px 10px',
                                                width: '75%',
                                                height: '80%'
                                            }}>
                                            {!isApple && (
                                                <>
                                                    <Typography
                                                        variant="h4"
                                                        style={{
                                                            fontFamily: 'Inter',
                                                            fontSize:
                                                                // eslint-disable-next-line no-nested-ternary
                                                                isMobile && !isTablet
                                                                    ? '18px'
                                                                    : isTablet
                                                                    ? '28px'
                                                                    : '22px',
                                                            fontWeight: '700',
                                                            width: '100%',
                                                            textAlign: 'center'
                                                        }}>
                                                        {t('installAppText1')}
                                                        <br />
                                                        {t('installAppText2')}
                                                    </Typography>
                                                    <Box
                                                        style={{
                                                            height: 'inherit',
                                                            paddingBottom: '20px',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center'
                                                        }}>
                                                        {isMobile && !isTablet && (
                                                            <img
                                                                src={smartphoneShortcut}
                                                                alt="smartphoneShortcut"
                                                                style={{
                                                                    width: '80%',
                                                                    height: 'auto'
                                                                }}
                                                            />
                                                        )}
                                                        {isTablet && (
                                                            <img
                                                                src={tabletShortcut}
                                                                alt="tabletShortcut"
                                                                style={{
                                                                    width: '65%',
                                                                    height: 'auto',
                                                                    maxHeight: '75%',
                                                                    maxWidth: 'max-content'
                                                                }}
                                                            />
                                                        )}
                                                        {!isTablet && !isMobile && (
                                                            <img
                                                                src={desktopShortcut}
                                                                alt="desktopShortcut"
                                                                style={{
                                                                    width: '45%',
                                                                    height: 'auto'
                                                                }}
                                                            />
                                                        )}
                                                    </Box>
                                                    <Button
                                                        style={{
                                                            backgroundColor: '#FFFFFF',
                                                            border: '1px solid #D00070',
                                                            boxShadow: '0px 0px 0px 2px',
                                                            borderRadius: '12px',
                                                            bottom: '20px',
                                                            position: 'absolute',
                                                            left: '50%',
                                                            transform: 'translate(-50%, 0)',
                                                            width: '90%',
                                                            height: '50px',
                                                            fontFamily: 'Inter',
                                                            fontSize:
                                                                // eslint-disable-next-line no-nested-ternary
                                                                isMobile && !isTablet
                                                                    ? '18px'
                                                                    : isTablet
                                                                    ? '26px'
                                                                    : '20px',
                                                            color: '#D00070',
                                                            padding: '10px 20px'
                                                        }}
                                                        onClick={onClick}>
                                                        {t('installApp')}
                                                    </Button>
                                                </>
                                            )}
                                            {isApple && (
                                                <>
                                                    <Logo
                                                        src={circleCloseIcon}
                                                        alt="circleCloseIcon"
                                                        onClick={(): void => {
                                                            setDisplayInstallFrame(false);
                                                        }}
                                                    />
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'start',
                                                            alignItems: 'center',
                                                            flexDirection: 'column',
                                                            width: '80%',
                                                            margin: '0 auto'
                                                        }}>
                                                        <Typography
                                                            variant="h4"
                                                            style={{
                                                                fontFamily: 'Inter',
                                                                fontSize:
                                                                    // eslint-disable-next-line no-nested-ternary
                                                                    isMobile && !isTablet
                                                                        ? '18px'
                                                                        : isTablet
                                                                        ? '28px'
                                                                        : '22px',
                                                                fontWeight: '700',
                                                                width: '100%',
                                                                display: 'flex',
                                                                justifyContent: 'start',
                                                                alignItems: 'center',
                                                                textAlign: 'center',
                                                                color: '#374653'
                                                            }}>
                                                            <img
                                                                src={squareLogo}
                                                                height="60px"
                                                                style={{ paddingRight: '10px' }}
                                                                alt="squareLogo"
                                                            />
                                                            {t('installAppText1')}
                                                        </Typography>
                                                    </Box>
                                                    <hr
                                                        style={{
                                                            width: '100%',
                                                            height: '4px',
                                                            marginTop: '20px',
                                                            backgroundColor: '#DADADA',
                                                            border: 'none'
                                                        }}
                                                    />
                                                    <Box
                                                        style={{
                                                            height: 'inherit',
                                                            paddingBottom: '20px',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: '100%'
                                                        }}>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                width: '80%'
                                                            }}>
                                                            <Typography
                                                                variant="h4"
                                                                style={{
                                                                    fontFamily: 'Inter',
                                                                    fontSize: '20px',
                                                                    fontWeight: '500',
                                                                    width: '100%',
                                                                    textAlign: 'left',
                                                                    color: '#374653'
                                                                }}>
                                                                {t('appleInstallAppText1')}
                                                                <br />
                                                                {t('appleInstallAppText2')}
                                                                <br />
                                                                {t('appleInstallAppText3')}
                                                            </Typography>
                                                            <Typography
                                                                variant="h4"
                                                                style={{
                                                                    fontFamily: 'Inter',
                                                                    fontSize: '24px',
                                                                    fontWeight: '700',
                                                                    width: '100%',
                                                                    textAlign: 'left',
                                                                    color: '#374653',
                                                                    marginTop: '40px',
                                                                    display: 'flex',
                                                                    alignItems: 'center'
                                                                }}>
                                                                {t('appleInstallAppStep1')}
                                                                <img
                                                                    src={appleInstallAppStep1}
                                                                    alt="appleInstallAppStep1"
                                                                    style={{
                                                                        height: '40px',
                                                                        paddingLeft: '36px'
                                                                    }}
                                                                />
                                                            </Typography>
                                                            <Typography
                                                                variant="h4"
                                                                style={{
                                                                    fontFamily: 'Inter',
                                                                    fontSize: '24px',
                                                                    fontWeight: '700',
                                                                    width: '100%',
                                                                    textAlign: 'left',
                                                                    color: '#374653',
                                                                    marginTop: '20px',
                                                                    display: 'flex',
                                                                    alignItems: 'center'
                                                                }}>
                                                                {t('appleInstallAppStep2')}
                                                                <img
                                                                    src={appleInstallAppStep2}
                                                                    alt="appleInstallAppStep2"
                                                                    style={{
                                                                        height: '50px',
                                                                        paddingLeft: '10px'
                                                                    }}
                                                                />
                                                            </Typography>
                                                            <Typography
                                                                variant="h4"
                                                                style={{
                                                                    fontFamily: 'Inter',
                                                                    fontSize: '24px',
                                                                    fontWeight: '700',
                                                                    width: '100%',
                                                                    textAlign: 'left',
                                                                    color: '#374653',
                                                                    marginTop: '20px',
                                                                    display: 'flex',
                                                                    alignItems: 'center'
                                                                }}>
                                                                {t('appleInstallAppStep3')}
                                                                <img
                                                                    src={appleInstallAppStep3}
                                                                    alt="appleInstallAppStep3"
                                                                    style={{
                                                                        height: '40px',
                                                                        paddingLeft: '35px'
                                                                    }}
                                                                />
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </>
                                            )}
                                        </Box>
                                    </Box>
                                )}
                            <Router />
                        </UserProvider>
                    </BrowserRouter>
                </AppContainer>
            </ThemeConfig>
        </Sentry.ErrorBoundary>
    );
}
