/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { makeStyles, createStyles } from '@mui/styles';
import { Person } from '@mui/icons-material';
import Button from '@mui/material/Button';
import { Timestamp } from 'firebase/firestore';
import { LoadingButton } from '@mui/lab';
import informationIcon from '../../assets/informationIcon.png';
import { CentralLogo } from '../../components/Headers/CentralLogo';
import { DeactiveModal } from '../../components/DeactiveModal';
import { SelectComponent } from '../../components/SelectComponent';
import { ChangePassword } from '../../components/ChangePassword';
import { logOut } from '../../services/firebaseAuth';
import { Family } from '../../models/Family';
import { UserContext } from '../../providers/UserProvider';
import {
    deactiveUserAccount,
    getFamilyUser,
    getLegalReferent,
    getPatientFromCode,
    saveFamilyProfile
} from '../../services/cloudFirestore';
import { Patient } from '../../models/Patient';
import { DatePickerCustom } from '../../components/DatePickerCustom';
import { UserDataProp } from '../../models/userDataProp';
import { sendWelcomeEmailFamily } from '../../services/storageFunctions';
import { InputTextNotDisabled, InputTextDisabled } from '../../components/FormComponents/InputText';
import { Logo } from '../../components/FormComponents/Logo';
import { TitleFormPart } from '../../components/FormComponents/TitleFormPart';

const useStyles = makeStyles(() =>
    createStyles({
        marginTopLayout: {
            marginTop: '2%'
        },
        selectLayout: {
            width: '100%',
            marginTop: '0px',
            color: '#004680 !important'
        },
        selectPlaceholder: {
            width: '100%',
            marginTop: '0px',
            color: 'rgba(172, 188, 199, 1)'
        },
        informationLayout: {
            '@media (max-width: 598px)': {
                width: '90%'
            },
            '@media (min-width: 598px)': {
                maxWidth: '540px'
            }
        },
        suscriptionText: {
            textAlign: 'left',
            color: '#004680',
            fontFamily: 'Luciole-Regular',
            marginTop: '4%'
        }
    })
);

const Title = styled('div')({
    marginTop: '3%',
    marginBottom: '1%',
    display: 'flex',
    alignContent: 'space-around',
    alignItems: 'center',
    justifyContent: 'center'
});

const ChangePasswordBtn = styled(Button)({
    margin: '2% 0%',
    width: '100%',
    fontSize: '20px',
    lineHeight: '24px',
    backgroundColor: 'rgba(51, 135, 204, 0.02)',
    boxShadow: '0px 0px 0px 2px rgba(51, 135, 204, 0.6)',
    border: '1px solid #004680',
    padding: '0px'
});

const LogoutBtn = styled(Button)({
    margin: '2% 0%',
    width: '100%',
    fontSize: '20px',
    lineHeight: '24px',
    backgroundColor: 'rgba(208, 0, 112, 0.02)',
    boxShadow: '0px 0px 0px 2px rgba(208, 0, 112, 0.4);',
    border: '1px solid #8B004B'
});

const DeactivateMyAccountBtn = styled(Button)({
    margin: '2% 0 2% 0',
    width: '100%',
    fontSize: '20px',
    lineHeight: '24px',
    border: 'none'
});

const ValidateMyProfileBtn = styled(LoadingButton)({
    margin: '5% 0%',
    width: '100%',
    fontSize: '20px',
    lineHeight: '24px',
    backgroundColor: 'rgba(208, 0, 112, 0.02)',
    boxShadow: '0px 0px 0px 2px rgba(208, 0, 112, 0.4);',
    border: '1px solid #8B004B'
});

const ProfileInstructions = styled(Box)({
    display: 'flex',
    backgroundColor: '#F4E0EB',
    margin: '0px 16px',
    padding: '0px 16px',
    marginTop: '5% !important',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto'
});

const DatePick = styled(DatePickerCustom)(() => ({
    '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
        color: 'rgba(0, 0, 0, 0.23)'
    },
    width: '100%',
    marginTop: '2%'
}));

export function FamilyScreenSettings(): JSX.Element {
    const classes = useStyles();
    const { t } = useTranslation();
    const [logout, setLogout] = useState<boolean>(false);
    const [isChangePassword, setIsChangePassword] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const history = useHistory();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [disabled, setDisabled] = useState<boolean>(true);
    const [hasPatientData, setHasPatientData] = useState<boolean>(false);
    const [disabledProfileBtn, setDisableProfileBtn] = useState<boolean>(true);
    const [photo, setPhoto] = useState<string | undefined>();
    const [patient, setPatient] = useState<Patient>({
        firstName: undefined,
        lastName: undefined,
        dob: undefined,
        establishmentName: undefined,
        address: undefined,
        pincode: undefined,
        city: undefined,
        emailId: undefined
    });
    const [firstConnection, setFirstConnection] = useState(true);
    const [data, setData] = useState<Family>({
        firstName: undefined,
        lastName: undefined,
        emailId: undefined,
        profilePhoto: undefined,
        familyCode: undefined,
        address: undefined,
        pincode: undefined,
        city: undefined,
        relationship: undefined,
        legalReferent: undefined,
        lovedPassword: undefined,
        startDate: undefined,
        endDate: undefined,
        firstStepFormFields: [],
        secondStepFormFields: []
    });

    const RelationshipList = [
        t('host'),
        t('greatGrandSon'),
        t('greatGrandDaughter'),
        t('otherResident'),
        t('beautifulGirl'),
        t('stepSon'),
        t('husbandWife'),
        t('cousin'),
        t('cousine'),
        t('girl'),
        t('son'),
        t('brother'),
        t('sister'),
        t('mother'),
        t('dad'),
        t('nephew'),
        t('niece'),
        t('grandSon'),
        t('littleGirl'),
        t('nursingStaff'),
        t('speaker')
    ];

    useEffect(() => {
        if (
            data.firstName &&
            data.lastName &&
            data.emailId &&
            data.familyCode &&
            // data.address &&
            // data.pincode &&
            // data.city &&
            data.relationship
        ) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [data]);

    useEffect(() => {
        if (patient?.firstName && patient.lastName && patient.establishmentName) {
            setHasPatientData(true);
        } else {
            setHasPatientData(false);
        }
    }, [patient]);

    useEffect(() => {
        // eslint-disable-next-line no-console
        // console.log('hasPatientData', hasPatientData);
        // eslint-disable-next-line no-console
        // console.log('disabled', disabled);
        if (hasPatientData && !disabled) {
            setDisableProfileBtn(false);
        } else {
            setDisableProfileBtn(true);
        }
    }, [hasPatientData, disabled]);

    // useEffect(() => {
    //     console.log('hasPatientData', hasPatientData);
    //     console.log('disabled', disabled);
    //     if (hasPatientData && !disabled) {
    //         setDisableProfileBtn(false);
    //     } else {
    //         setDisableProfileBtn(true);
    //     }
    // }, [disabled]);

    useEffect(() => {
        if (user && user?.familyCode) {
            getPatientFromCode(user.familyCode).then((value) => {
                if (value === null) {
                    logOut(user);
                } else {
                    setPatient({
                        firstName: value.firstName,
                        lastName: value.lastName,
                        dob: value.dob,
                        establishmentName: value.establishmentName,
                        address: value.address,
                        pincode: value.pincode,
                        city: value.city,
                        emailId: value.emailId
                    });
                }
            });
        }
    }, []);

    useEffect(() => {
        const getFamilyMember = async (): Promise<void> => {
            if (user && user.id) {
                const family = await getFamilyUser(user.id);
                if (family) {
                    if (typeof family.profilePhoto === 'string') {
                        setPhoto(family.profilePhoto);
                    }
                    if (!family.firstName) {
                        setFirstConnection(false);
                    }
                    setData({
                        profilePhoto: family.profilePhoto,
                        firstName: family.firstName,
                        lastName: family.lastName,
                        emailId: family.emailId,
                        dob: family.dob,
                        familyCode: family.familyCode,
                        address: family.address,
                        pincode: family.pincode,
                        city: family.city,
                        relationship: family.relationship,
                        legalReferent: family.legalReferent,
                        lovedPassword: family.lovedPassword,
                        startDate: family.startDate,
                        endDate: family.endDate,
                        firstStepFormFields: [],
                        secondStepFormFields: []
                    });
                }
            }
        };
        getFamilyMember();
    }, []);

    const handleChange = (
        state: string,
        value: string | number | boolean | File | Timestamp
    ): void => {
        setData({ ...data, [state]: value });
    };

    const saveDetails = async (): Promise<void> => {
        setLoading(true);
        if (user && user.id) {
            await saveFamilyProfile(user.id, data);
        }
        setLoading(false);
    };

    const deactivateAccount = async (): Promise<void> => {
        if (user && user.id) {
            await deactiveUserAccount(user.id);
            logOut(user);
        }
    };

    const updateImage = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.files) {
            handleChange('profilePhoto', event.target.files[0]);
            setPhoto(URL.createObjectURL(event.target.files[0]));
        }
    };

    const inputComponent = (
        placeholder: string,
        dataToShow: string | null,
        position?: string
    ): JSX.Element => {
        return (
            <InputTextNotDisabled
                placeholder={t(placeholder)}
                data={dataToShow}
                labelPresent
                change={(value: string): void => handleChange(placeholder, value)}
                side={position}
            />
        );
    };

    const getFamilyProfileInstructions = (
        <ProfileInstructions className={classes.informationLayout}>
            <img
                src={informationIcon}
                alt="InformationIcon"
                style={{ height: '20px', width: '20px', marginRight: '5px' }}
            />
            <Typography
                variant="body1"
                color="#D00070"
                mt={2}
                mb={2}
                sx={{
                    textAlign: 'left',
                    marginLeft: '5px',
                    fontWeight: 400
                }}>
                {t('familyProfileInstructions')}
            </Typography>
        </ProfileInstructions>
    );

    const getSettingsScreenButtons = (
        <Box sx={{ paddingTop: '2%' }}>
            <ChangePasswordBtn
                fullWidth
                variant="outlined"
                size="large"
                sx={{ margin: '5%' }}
                color="secondary"
                onClick={(): Promise<void> => saveDetails()}
                disabled={disabled}>
                {loading && (
                    <div className="loading">
                        <CircularProgress
                            style={{
                                marginTop: '20px',
                                float: 'right',
                                marginRight: '19%'
                            }}
                        />
                    </div>
                )}
                {!loading && <Typography variant="h5">{t('modifier')}</Typography>}
            </ChangePasswordBtn>
            <ChangePasswordBtn
                fullWidth
                variant="outlined"
                size="large"
                color="secondary"
                onClick={(): void => {
                    setIsChangePassword(true);
                }}>
                <Typography variant="h5">{t('changeMyPassword')}</Typography>
            </ChangePasswordBtn>
            <LogoutBtn
                fullWidth
                variant="outlined"
                size="large"
                onClick={(): void => {
                    setLogout(true);
                }}>
                <Typography variant="h5"> {t('logOut')}</Typography>
            </LogoutBtn>
            {/* <DeactivateMyAccountBtn
                fullWidth
                variant="outlined"
                size="large"
                onClick={(): void => {
                    setDeactive(true);
                }}>
                {t('deactivateMyAccount')}
            </DeactivateMyAccountBtn> */}
        </Box>
    );

    const sendWelcomeFamily = async (): Promise<void> => {
        if (data.familyCode && data.emailId && data.firstName && data.lastName) {
            const patientData = await getPatientFromCode(data.familyCode);
            const familyRef = await getLegalReferent(data.familyCode);
            if (
                patientData?.firstName &&
                patientData.lastName &&
                patientData.emailId &&
                familyRef?.lovedPassword &&
                familyRef.emailId
            ) {
                await sendWelcomeEmailFamily(
                    data.firstName,
                    data.lastName,
                    patientData.firstName,
                    patientData.lastName,
                    patientData.emailId,
                    familyRef.lovedPassword,
                    data.emailId,
                    data.familyCode,
                    familyRef.emailId
                );
            }
        }
    };

    const getProfileButtons = (
        <div>
            {loading ? (
                <div className="loading">
                    <CircularProgress
                        style={{
                            marginTop: '20px',
                            marginLeft: '43%'
                        }}
                    />
                </div>
            ) : (
                <ValidateMyProfileBtn
                    fullWidth
                    variant="outlined"
                    size="large"
                    disabled={disabledProfileBtn}
                    loading={loading}
                    onClick={(): void => {
                        setLoading(true);
                        saveDetails().then(() => {
                            sendWelcomeFamily().then(() => {
                                history.replace('/home');
                            });
                        });
                    }}>
                    <Typography variant="h5">{t('validateMyProfile')}</Typography>
                </ValidateMyProfileBtn>
            )}
        </div>
    );

    const getFamilyProfileAdditionalFields = (
        <Box>
            <TitleFormPart text={t('myClose')} />
            <InputTextDisabled
                placeholder={t('firstName')}
                data={patient.firstName ?? null}
                side="left"
            />
            <InputTextDisabled
                placeholder={t('name')}
                data={patient.lastName ?? null}
                side="right"
            />
            <Box height="8px" />
            <DatePick
                disabled
                value={patient && typeof patient?.dob === 'object' ? patient?.dob?.toDate() : null}
                sx={{ marginRight: '6%', width: '100%' }}
                onDateChange={(newDate: Date): void => {}}
            />
            <div style={{ marginTop: '2%' }}>
                <InputTextDisabled
                    placeholder={t('nameEstablishment')}
                    data={patient.establishmentName ?? null}
                />
            </div>
            <Box sx={{ marginTop: '2%' }}>
                <InputTextDisabled placeholder={t('address')} data={patient.address ?? null} />
            </Box>
            <InputTextDisabled
                placeholder={t('pincode')}
                data={patient.pincode ?? null}
                side="left"
            />
            <InputTextDisabled placeholder={t('city')} data={patient.city ?? null} side="right" />
            <InputTextDisabled placeholder={t('userId')} data={patient.emailId ?? null} />
            <InputTextDisabled placeholder={t('password')} data={data.lovedPassword ?? null} />
        </Box>
    );

    const familyProfileSetting = (
        <div>
            {!firstConnection ? (
                <CentralLogo hasBackButton={false} hasCentralLogo />
            ) : (
                <CentralLogo hasBackButton hasProfilePhoto />
            )}
            <Box
                flex={1}
                display="flex"
                flexDirection="column"
                height="100vh"
                width="90%"
                margin="auto">
                <Title>
                    <Person sx={{ width: '35px', height: 'auto' }} fontSize="inherit" />
                    <Typography variant="h3">{t('profile')}</Typography>
                </Title>
                <Logo
                    photo={photo}
                    firstName={user?.firstName ?? ''}
                    lastName={user?.lastName ?? ''}
                    updateImage={(event: React.ChangeEvent<HTMLInputElement>): void =>
                        updateImage(event)
                    }
                />
                {!firstConnection ? getFamilyProfileInstructions : null}
                <Box maxWidth={800} margin="auto" px={2} sx={{ textAlign: 'left' }}>
                    <Typography className={classes.marginTopLayout}>{t('firstName')}</Typography>
                    {inputComponent('firstName', data.firstName ?? null)}
                    <Typography className={classes.marginTopLayout}>{t('name')}</Typography>
                    {inputComponent('lastName', data.lastName ?? null)}
                    <Typography className={classes.marginTopLayout}>{t('relationship')}</Typography>
                    <SelectComponent
                        dropdownList={RelationshipList}
                        placeholder={t('relationship')}
                        onTextChange={(value: string): void => handleChange('relationship', value)}
                        value={data.relationship ?? null}
                        classCustom={classes.selectLayout}
                        placeholderStyling={classes.selectPlaceholder}
                    />
                    <Typography className={classes.marginTopLayout}>{t('familyCode')}</Typography>
                    <InputTextDisabled
                        placeholder={t('familyCode')}
                        data={data.familyCode ?? null}
                        labelPresent
                    />
                    <Typography className={classes.marginTopLayout}>{t('address')}</Typography>
                    <div style={{ marginBottom: '2%' }}>
                        {inputComponent('address', data.address ?? null)}
                    </div>
                    {inputComponent('pincode', data.pincode ?? null, 'left')}
                    {inputComponent('city', data.city ?? null, 'right')}
                    <Box>
                        <Typography className={classes.marginTopLayout}>
                            {t('dateOfBirth')}
                        </Typography>
                        <DatePick
                            value={typeof data?.dob === 'object' ? data?.dob?.toDate() : null}
                            sx={{ marginRight: '6%', width: '100%' }}
                            onDateChange={(newDate: Date): void =>
                                handleChange('dob', Timestamp.fromDate(newDate))
                            }
                        />
                    </Box>
                    <Typography className={classes.marginTopLayout}>{t('email')}</Typography>
                    <InputTextDisabled
                        placeholder={t('email')}
                        data={data.emailId ?? null}
                        labelPresent
                    />
                    {data.legalReferent ? getFamilyProfileAdditionalFields : null}

                    <TitleFormPart text={t('mySuscription')} />
                    <Typography className={classes.marginTopLayout}>
                        {t('startSuscription')}
                    </Typography>
                    <DatePickerCustom
                        disabled
                        sx={{ marginRight: '6%', width: '100%' }}
                        onDateChange={(date: Date): void => {}}
                        value={data.startDate ? data?.startDate.toDate() : null}
                    />
                    <Typography className={classes.marginTopLayout}>
                        {t('endSuscription')}
                    </Typography>
                    <DatePickerCustom
                        disabled
                        sx={{ marginRight: '6%', width: '100%' }}
                        onDateChange={(date: Date): void => {}}
                        value={data.endDate ? data?.endDate.toDate() : null}
                    />

                    {!firstConnection ? getProfileButtons : getSettingsScreenButtons}
                </Box>
            </Box>
        </div>
    );

    return (
        <div>
            <ChangePassword
                isModelOpen={isChangePassword}
                setModelOpen={setIsChangePassword}
                onCancel={(): void => setIsChangePassword(false)}
                onButtonClick={(): void => setIsChangePassword(false)}
            />
            {/* <DeactiveModal
                isModelOpen={deactive}
                setModelOpen={setDeactive}
                title={t('confirmDeactivate')}
                bodyText={t('confirmDeactivateSubtitle')}
                buttonText={t('disableAccount')}
                onButtonClick={deactivateAccount}
            /> */}
            <DeactiveModal
                isModelOpen={logout}
                setModelOpen={setLogout}
                title={t('logout')}
                bodyText={t('confirmLogout')}
                buttonText={t('logout')}
                onButtonClick={(): void => {
                    logOut(user);
                }}
            />
            {familyProfileSetting}
        </div>
    );
}
