import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { TableLayout } from '../../layouts/TableLayout';
import { EstablishmentsExportData } from '../../models/EstablishmentsExportData';
import { EstablishmentViewData } from '../../models/EstablishmentViewData';
import {
    getEstablishmentAdminSide,
    updateEstablishmentByAdmin,
    getEstablishmentList,
    getEstablishmentsExportData
} from '../../services/cloudFirestore';
import { convertToTitleCase, getDateFromTimestamp } from '../../utilities/utils';

export function Establishments(): JSX.Element {
    const { t } = useTranslation();
    const history = useHistory();
    const [establishmentList, setEstablishmentList] = useState<EstablishmentViewData[] | null>(
        null
    );
    const [establishmentExportData, setEstablishmentExportData] = useState<
        EstablishmentsExportData[]
    >([]);

    const updateEstablishmentRow = async (data: EstablishmentViewData): Promise<void> => {
        const row = await getEstablishmentAdminSide(data.establishmentCode!);
        if (row && row.dob && row.startDate && row.endDate && row.status) {
            if (
                getDateFromTimestamp(row.dob) !== data.dob ||
                getDateFromTimestamp(row.startDate) !== data.startDate ||
                getDateFromTimestamp(row.endDate) !== data.endDate ||
                convertToTitleCase(row.status) !== data.status ||
                row.firstName !== data.firstName ||
                row.lastName !== data.lastName ||
                row.email !== data.email ||
                row.address !== data.address ||
                row.city !== data.city ||
                row.pincode !== data.pincode ||
                row.fonction !== data.fonction ||
                row.mobile !== data.mobile
            ) {
                await updateEstablishmentByAdmin(data);
                window.location.reload();
            }
        }
    };

    const columns = [
        {
            field: 'establishmentName',
            minWidth: 250,
            flex: 1,
            headerName: t('instituitionName')
        },
        {
            field: 'firstName',
            minWidth: 100,
            flex: 1,
            headerName: `${t('firstName')}*`,
            editable: true
        },
        { field: 'lastName', minWidth: 100, flex: 1, headerName: `${t('name')}*`, editable: true },
        {
            field: 'dob',
            minWidth: 180,
            flex: 1,
            headerName: `${t('dateOfBirth')}*`,
            editable: true
        },
        {
            field: 'email',
            minWidth: 200,
            flex: 1,
            headerName: `${t('emailPlaceholder')}*`,
            editable: true
        },
        {
            field: 'fonction',
            minWidth: 150,
            flex: 1,
            headerName: `${t('fonction')}*`,
            editable: true
        },
        {
            field: 'address',
            minWidth: 200,
            flex: 1,
            headerName: `${t('addressTableHeading')}*`,
            editable: true
        },
        {
            field: 'pincode',
            minWidth: 120,
            flex: 1,
            headerName: `${t('postalCodeTableHeading')}*`,
            editable: true
        },
        { field: 'city', minWidth: 120, flex: 1, headerName: `${t('city')}*`, editable: true },
        {
            field: 'mobile',
            minWidth: 120,
            flex: 1,
            headerName: `${t('phoneNumber')}*`,
            editable: true
        },
        {
            field: 'establishmentCode',
            minWidth: 200,
            flex: 1,
            headerName: t('establishmentCode')
        },
        {
            field: 'startDate',
            minWidth: 190,
            flex: 1,
            headerName: `${t('startDate')}*`,
            editable: true
        },
        {
            field: 'endDate',
            minWidth: 160,
            flex: 1,
            headerName: `${t('endDate')}*`,
            editable: true
        },
        { field: 'status', minWidth: 80, flex: 1, headerName: t('status') },
        {
            field: 'button',
            headerName: 'Modifier',
            minWidth: 120,
            flex: 1,
            // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            renderCell: (params: GridRenderCellParams<Date>) => (
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={(): void => {
                        updateEstablishmentRow(params.row);
                    }}>
                    {t('updateData')}
                </Button>
            )
        }
    ];

    const onAddButtonClicked = (): void => {
        history.push('/establishment/add');
    };

    const fetchEstablishmentList = async (): Promise<void> => {
        const therapist = await getEstablishmentList();
        const establishmentViewData: EstablishmentViewData[] = therapist;
        therapist.forEach((item, index) => {
            if (item.dob) {
                establishmentViewData[index].dob = getDateFromTimestamp(item.dob);
            }
            if (item.startDate) {
                establishmentViewData[index].startDate = getDateFromTimestamp(item.startDate);
            }
            if (item.endDate) {
                establishmentViewData[index].endDate = getDateFromTimestamp(item.endDate);
            }
            if (item.status) {
                establishmentViewData[index].status = convertToTitleCase(item.status);
            }
        });
        setEstablishmentList(establishmentViewData);
        const exportData = await getEstablishmentsExportData();
        setEstablishmentExportData(exportData);
    };

    useEffect(() => {
        if (establishmentList === null || establishmentList === undefined) {
            fetchEstablishmentList();
        }
    });

    return (
        <TableLayout
            title={t('establishments')}
            addButtonTitle={t('addEstablishments')}
            onAddButtonClicked={onAddButtonClicked}
            columns={columns}
            rows={
                establishmentList === undefined || establishmentList === null
                    ? []
                    : establishmentList
            }
            exportData={establishmentExportData}
            nameOfFile={t('establishments')}
        />
    );
}
