/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { InputComponent } from './InputComponent';

const useStyles = makeStyles(() =>
    createStyles({
        leftLayout: {
            width: '47%',
            margin: '0px',
            padding: '0px',
            marginRight: '6%',
            display: 'inline-block'
        },
        rightLayout: {
            width: '47%',
            margin: '0px',
            padding: '0px',
            display: 'inline-block'
        },
        marginTopLayout: {
            marginTop: '16px'
        }
    })
);

const renderHtml = (
    disabled: boolean,
    side: string | undefined,
    placeholder: string,
    data: string | null,
    labelPresent?: boolean,
    change?: Function | undefined
): JSX.Element => {
    const classes = useStyles();
    const [sideClass, setSideClass] = useState<string>('');

    useEffect(() => {
        if (side) {
            if (side === 'right') {
                setSideClass(classes.rightLayout);
            } else if (side === 'left') {
                setSideClass(classes.leftLayout);
            } else if (side === 'marginTopLayout') {
                setSideClass(classes.marginTopLayout);
            }
        }
    }, []);

    return (
        <div className={sideClass} style={{ marginTop: labelPresent ? '0px' : '16px' }}>
            <InputComponent
                placeholder={placeholder}
                value={data}
                type="text"
                className="presentation-input-field"
                isMultiline={false}
                onTextChange={(value: string): void => {
                    if (change) {
                        change(value);
                    }
                }}
                disabled={disabled}
            />
        </div>
    );
};

interface InputTextInterface {
    placeholder: string;
    data: string | null;
    labelPresent?: boolean;
    change?: Function;
    side?: string;
}

export const InputTextDisabled = ({
    placeholder,
    data,
    labelPresent,
    side
}: InputTextInterface): JSX.Element => {
    return renderHtml(true, side, placeholder, data, labelPresent);
};

export const InputTextNotDisabled = ({
    placeholder,
    data,
    labelPresent,
    change,
    side
}: InputTextInterface): JSX.Element => {
    return renderHtml(false, side, placeholder, data, labelPresent, change);
};
