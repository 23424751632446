import { Box, Button, Card, CardActionArea, CardMedia, Typography } from '@mui/material';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import memoryIcon from '../../assets/greyMemory.png';
import tocSvgIcon from '../../assets/tocSvgIconGrey.svg';
import memorinIcon from '../../assets/MemorinSvgIconGrey.svg';
import puzzleIcon from '../../assets/greyPuzzle.png';
import backButton from '../../assets/backButton.png';
import memoryThumbnail from '../../assets/memoryThumbnail.png';
import reorderThumbnail from '../../assets/reorderLevelChoise1.png';
import memorinThumbnail from '../../assets/memorinThumbnail.svg';
import puzzleThumbnail from '../../assets/puzzleThumbnail.png';
import exerciseTitle from '../../assets/exerciseTitle.svg';
import { Navbar } from '../../components/Headers/Navbar';
import { UserContext } from '../../providers/UserProvider';
import { DashboardLayout } from '../../layouts/DashboardLayout';
import { Roles } from '../../models/Roles';
import { UserDataProp } from '../../models/userDataProp';
import { clickAnalytics } from '../../services/analyticsFunction';

const useStyles = makeStyles(() => ({
    root: {
        margin: 'auto',
        justifyContent: 'space-around',
        display: 'flex',
        flexWrap: 'wrap'
    },
    media: {
        height: '182px',
        margin: '15px',
        borderRadius: '9px',
        '@media (min-width: 500px)': {
            height: '182px',
            width: '300px',
            margin: 'auto',
            marginTop: '40px',
            marginBottom: '40px'
        }
    },
    headerTitle: {
        fontWeight: 700,
        margin: '40px',
        '@media (max-width: 541px)': {
            padding: '20px',
            margin: '0px'
        }
    },
    myExercises: {
        margin: '2%',
        width: '98%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    GreySalute: {
        margin: '2%',
        width: '98%'
    },
    button: {
        padding: '10%',
        background: '#E3E6E8',
        width: '100%',
        height: '100%',
        margin: 'auto'
    },
    divMoreDetails: {
        width: '100%',
        paddingTop: '20px',
        paddingBottom: '20px'
    },
    buttonMoreDetails: {
        width: '50%',
        color: '#3387cc',
        marginBottom: '5px',
        borderRadius: '10px',
        background: 'linear-gradient(0deg, rgba(39, 152, 152, 0.02), rgba(39, 152, 152, 0.02))',
        border: '1px solid #0b46a1',
        height: '40px',
        // boxShadow: '0px 0px 0px 2px rgba(15, 76, 167, 0.55)',
        '&:hover': {
            backgroundColor: '#3387cc'
        }
    },
    divButtonAndCard: {
        margin: '2%',
        width: '98%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '@media (min-width: 700px)': {
            width: '46%'
        }
    }
}));

const ImageContainer = styled('img')({
    borderRadius: '9px',
    height: '182px',
    width: '300px',
    objectFit: 'contain'
});

const ImageBox = styled(Box)({
    borderRadius: '9px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '182px',
    // width: '326px',
    margin: 'auto',
    marginTop: '40px',
    marginBottom: '40px',
    '@media (max-width: 598px)': {
        margin: 'auto',
        marginTop: '15px',
        marginBottom: '25px'
    }
});

const Logo = styled.img`
    height: 32px;
    width: 32px;
    margin-right: 10px;
`;

export function Exercise(): JSX.Element {
    const { t } = useTranslation();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const history = useHistory();
    const classes = useStyles();
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    );

    return (
        <div>
            {user && user.role === Roles.ADMIN ? (
                <DashboardLayout>
                    <div>
                        <Typography
                            variant="h2"
                            color="#1E2933"
                            sx={{ textAlign: 'left', paddingLeft: 2 }}>
                            {t('create')}
                        </Typography>
                        <Typography
                            variant="h4"
                            color="#374653"
                            className={classes.headerTitle}
                            sx={{ marginTop: '10px' }}>
                            {t('chooseModelToCustomise')}
                        </Typography>
                        <Box className={classes.root}>
                            <Card className={classes.myExercises}>
                                <CardActionArea
                                    onClick={(): void => {
                                        history.push(`/exercice-customisation/memor'in`);
                                    }}>
                                    <div
                                        style={{
                                            justifyContent: 'center',
                                            marginTop: '15px',
                                            marginBottom: '5px',
                                            display: 'flex',
                                            flexDirection: 'row'
                                        }}>
                                        <Logo src={memorinIcon} alt="logo" />
                                        <Typography
                                            variant="h4"
                                            color="secondary"
                                            sx={{
                                                fontWeight: '600'
                                            }}>
                                            {t('memorin')}
                                        </Typography>
                                    </div>
                                    <CardMedia className={classes.media} image={memorinThumbnail} />
                                </CardActionArea>
                            </Card>
                            <Card className={classes.myExercises}>
                                <CardActionArea
                                    onClick={(): void => {
                                        history.push(`/exercice-customisation/souven'in`);
                                    }}>
                                    <div
                                        style={{
                                            justifyContent: 'center',
                                            marginTop: '15px',
                                            marginBottom: '5px',
                                            display: 'flex',
                                            flexDirection: 'row'
                                        }}>
                                        <Logo src={memoryIcon} alt="logo" />
                                        <Typography
                                            variant="h4"
                                            color="secondary"
                                            sx={{
                                                fontWeight: '600'
                                            }}>
                                            {t('memory')}
                                        </Typography>
                                    </div>
                                    <CardMedia className={classes.media} image={memoryThumbnail} />
                                </CardActionArea>
                            </Card>
                            <Card className={classes.myExercises}>
                                <CardActionArea
                                    onClick={(): void => {
                                        history.push(`/exercice-customisation/puzzl'in`);
                                    }}>
                                    <div
                                        style={{
                                            justifyContent: 'center',
                                            marginTop: '15px',
                                            marginBottom: '5px',
                                            display: 'flex',
                                            flexDirection: 'row'
                                        }}>
                                        <Logo src={puzzleIcon} alt="logo" />
                                        <Typography
                                            variant="h4"
                                            color="secondary"
                                            sx={{
                                                fontWeight: '600'
                                            }}>
                                            {t('puzzleTitle')}
                                        </Typography>
                                    </div>
                                    <ImageBox>
                                        <ImageContainer
                                            src={puzzleThumbnail}
                                            alt="puzzleThumbnail"
                                        />
                                    </ImageBox>
                                </CardActionArea>
                            </Card>
                            <Card className={classes.myExercises}>
                                <CardActionArea
                                    onClick={(): void => {
                                        history.push(`/exercice-customisation/ordon'in`);
                                    }}>
                                    <div
                                        style={{
                                            justifyContent: 'center',
                                            marginTop: '15px',
                                            marginBottom: '5px',
                                            display: 'flex',
                                            flexDirection: 'row'
                                        }}>
                                        <Logo src={tocSvgIcon} alt="logo" />
                                        <Typography
                                            variant="h4"
                                            color="secondary"
                                            sx={{
                                                fontWeight: '600'
                                            }}>
                                            {t('reorder')}
                                        </Typography>
                                    </div>
                                    <ImageBox>
                                        <ImageContainer
                                            src={reorderThumbnail}
                                            alt="reorderThumbnail"
                                        />
                                    </ImageBox>
                                </CardActionArea>
                            </Card>
                        </Box>
                    </div>
                </DashboardLayout>
            ) : (
                <div>
                    <Navbar
                        leftIcon={backButton}
                        centerIcon={exerciseTitle}
                        leftIconLink={(): void => {
                            history.push('/home');
                        }}
                    />
                    <Typography variant="h4" color="#374653" className={classes.headerTitle}>
                        {t('chooseTemplateToCustomise')}
                    </Typography>
                    <Box className={classes.root}>
                        <div className={classes.divButtonAndCard}>
                            <Card
                                className={classes.myExercises}
                                sx={{ width: isMobile ? '73% !important' : '98%' }}>
                                <CardActionArea
                                    onClick={(): void => {
                                        if (user) {
                                            clickAnalytics(`souven'in_click_creatheque`, user);
                                        }
                                        history.push(`/exercice-customisation/souven'in`);
                                    }}>
                                    <div
                                        style={{
                                            justifyContent: 'center',
                                            marginTop: '15px',
                                            marginBottom: '5px',
                                            display: 'flex',
                                            flexDirection: 'row'
                                        }}>
                                        <Logo src={memoryIcon} alt="logo" />
                                        <Typography
                                            variant="h4"
                                            color="secondary"
                                            sx={{
                                                fontWeight: '600'
                                            }}>
                                            {t('memory')}
                                        </Typography>
                                    </div>
                                    <ImageBox>
                                        <ImageContainer src={memoryThumbnail} alt="ok" />
                                    </ImageBox>
                                </CardActionArea>
                            </Card>
                            <div className={classes.divMoreDetails}>
                                <Button
                                    className={classes.buttonMoreDetails}
                                    onClick={(): void => {
                                        if (user) {
                                            clickAnalytics(`more_details_click`, user);
                                        }
                                        history.push(`objective/souven'in`);
                                    }}>
                                    {t('moreDetails')}
                                </Button>
                            </div>
                        </div>
                        <div className={classes.divButtonAndCard}>
                            <Card
                                className={classes.myExercises}
                                sx={{ width: isMobile ? '73% !important' : '98%' }}>
                                <CardActionArea
                                    onClick={(): void => {
                                        if (user) {
                                            clickAnalytics(`puzzl'in_click_creatheque`, user);
                                        }
                                        history.push(`/exercice-customisation/puzzl'in`);
                                    }}>
                                    <div
                                        style={{
                                            justifyContent: 'center',
                                            marginTop: '15px',
                                            marginBottom: '5px',
                                            display: 'flex',
                                            flexDirection: 'row'
                                        }}>
                                        <Logo src={puzzleIcon} alt="logo" />
                                        <Typography
                                            variant="h4"
                                            color="secondary"
                                            sx={{
                                                fontWeight: '600'
                                            }}>
                                            {t('puzzleTitle')}
                                        </Typography>
                                    </div>
                                    <ImageBox>
                                        <ImageContainer
                                            src={puzzleThumbnail}
                                            alt="puzzleThumbnail"
                                        />
                                    </ImageBox>
                                </CardActionArea>
                            </Card>
                            <div className={classes.divMoreDetails}>
                                <Button
                                    className={classes.buttonMoreDetails}
                                    onClick={(): void => {
                                        if (user) {
                                            clickAnalytics(`more_details_click`, user);
                                        }
                                        history.push(`objective/puzzl'in`);
                                    }}>
                                    {t('moreDetails')}
                                </Button>
                            </div>
                        </div>
                        <div className={classes.divButtonAndCard}>
                            <Card
                                className={classes.myExercises}
                                sx={{ width: isMobile ? '73% !important' : '98%' }}>
                                <CardActionArea
                                    onClick={(): void => {
                                        if (user) {
                                            clickAnalytics(`memor'in_click_creatheque`, user);
                                        }
                                        history.push(`/exercice-customisation/memor'in`);
                                    }}>
                                    <div
                                        style={{
                                            justifyContent: 'center',
                                            marginTop: '15px',
                                            marginBottom: '5px',
                                            display: 'flex',
                                            flexDirection: 'row'
                                        }}>
                                        <Logo src={memorinIcon} alt="logo" />
                                        <Typography
                                            variant="h4"
                                            color="secondary"
                                            sx={{
                                                fontWeight: '600'
                                            }}>
                                            {t('memorin')}
                                        </Typography>
                                    </div>
                                    <ImageBox>
                                        <ImageContainer src={memorinThumbnail} alt="ok" />
                                    </ImageBox>
                                </CardActionArea>
                            </Card>
                            {/* <div className={classes.divMoreDetails}>
                                <Button
                                    className={classes.buttonMoreDetails}
                                    onClick={(): void => {
                                        if (user) {
                                            clickAnalytics(`more_details_click`, user);
                                        }
                                        history.push(`objective/memor'in`);
                                    }}>
                                    {t('moreDetails')}
                                </Button>
                            </div> */}
                        </div>
                        <div className={classes.divButtonAndCard}>
                            <Card
                                className={classes.myExercises}
                                sx={{ width: isMobile ? '73% !important' : '98%' }}>
                                <CardActionArea
                                    onClick={(): void => {
                                        if (user) {
                                            clickAnalytics(`ordon'in_click_creatheque`, user);
                                        }
                                        history.push(`/exercice-customisation/ordon'in`);
                                    }}>
                                    <div
                                        style={{
                                            justifyContent: 'center',
                                            marginTop: '15px',
                                            marginBottom: '5px',
                                            display: 'flex',
                                            flexDirection: 'row'
                                        }}>
                                        <Logo src={tocSvgIcon} alt="logo" />
                                        <Typography
                                            variant="h4"
                                            color="secondary"
                                            sx={{
                                                fontWeight: '600'
                                            }}>
                                            {t('reorder')}
                                        </Typography>
                                    </div>
                                    <ImageBox>
                                        <ImageContainer src={reorderThumbnail} alt="ok" />
                                    </ImageBox>
                                </CardActionArea>
                            </Card>
                        </div>
                        <Card className={classes.GreySalute}>
                            <div className={classes.button}>
                                <Typography variant="h6" color="#5C6670">
                                    {t('discoverMoreModels')}
                                </Typography>
                            </div>
                        </Card>
                    </Box>
                </div>
            )}
        </div>
    );
}
