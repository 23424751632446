import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Grid,
    Typography,
    useMediaQuery
} from '@mui/material';
import React, { IframeHTMLAttributes, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import { getThemeExamples } from '../../services/viewFunctions';
import { ExerciseType } from '../../models/ExerciseType';
import backButton from '../../assets/backButton.png';
import memoryTitle from '../../assets/memoryTitle.png';
import puzzleTitle from '../../assets/puzzleTitle.png';
import check from '../../assets/check.png';
import addCross from '../../assets/addCross.png';
import idea from '../../assets/idea.png';
import dialog from '../../assets/dialog.png';
import arrowHand from '../../assets/arrowHand.png';
import thumbnailsIcon from '../../assets/thumbnailsIcon.png';
import legendIcon from '../../assets/legendIcon.png';
import { Navbar } from '../../components/Headers/Navbar';
import { UserContext } from '../../providers/UserProvider';
import { UserDataProp } from '../../models/userDataProp';
import { Roles } from '../../models/Roles';
import { ThemeExamplesViewData } from '../../models/ThemeExamplesViewData';
import { ThemeCard } from '../../components/ThemeCard';
import { DeactiveModall } from '../../components/ContainerModal';
import { clickAnalytics } from '../../services/analyticsFunction';

const useStyles = makeStyles(() => ({
    groupButtonLayout: {
        display: 'flex',
        '@media (max-width: 598px)': {
            width: '95%',
            margin: 'auto',
            paddingBottom: '5%'
        },
        '@media (min-width: 598px)': {
            width: '75%',
            margin: 'auto',
            justifyContent: 'space-between'
        }
    }
}));

const StyledTopography = styled(Typography)({
    fontWeight: 400,
    marginBottom: '10px',
    color: '#002F55'
});

const HeadingTopography = styled(Typography)({
    color: '#374653',
    '@media (min-width: 300px)': {
        marginBottom: '5px',
        marginTop: '10px'
    },
    '@media (min-width: 1000px)': {
        marginTop: '20px',
        marginBottom: '20px'
    }
});

const UnderHeadingTopography = styled(Typography)({
    color: '#374653',
    marginBottom: '10px'
});

const Container = styled(Box)({
    alignItems: 'center',
    textAlign: 'center'
});

const PhoneContainer = styled(Box)({
    alignContent: 'space-around',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 0,
    marginLeft: '4%',
    marginTop: '3%',
    marginRight: '4%',
    textAlign: 'left'
});

const SectionContainerSouvenin = styled(Box)({
    alignContent: 'space-around',
    alignItems: 'center',
    justifyContent: 'left',
    display: 'flex'
});

const Root = styled(Grid)({
    alignContent: 'space-around',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '40px',
    marginRight: '40px',
    marginBottom: '20px'
});

const Section = styled(Box)({
    alignContent: 'space-around',
    alignItems: 'center',
    justifyContent: 'left',
    display: 'flex'
});

const Content = styled(Box)({
    display: 'flex'
});

const Logo = styled.img`
    @media (min-width: 300px) {
        height: 20px;
        width: 20px;
        margin-right: 0px;
    }
    @media (min-width: 598px) {
        height: 24px;
        width: 24px;
        margin-right: 10px;
    }
`;

const ThumbnailsLogo = styled.img`
    margin-top: 10px;
    @media (min-width: 300px) {
        margin-top: 0px;
        width: 55px;
        height: 95px;
    }
    @media (min-width: 598px) {
        width: 65px;
        height: 125px;
    }
    @media (min-width: 650px) {
        width: 95px;
        height: 155px;
    }
    @media (min-width: 850px) {
        width: 105px;
        height: 165px;
    }
`;

const LegendLogo = styled.img`
    margin-top: 20px;
    @media (min-width: 300px) {
        margin-top: 10px;
        width: 70px;
        height: 50px;
    }
    @media (min-width: 598px) {
        width: 80px;
        height: 50px;
        margin-top: 30px;
    }
    @media (min-width: 650px) {
        width: 120px;
        height: 80px;
        margin-top: 30px;
    }
    @media (min-width: 850px) {
        width: 125px;
        height: 85px;
    }
`;

const MessageLogo = styled.img`
    margin-top: 20px;
    @media (min-width: 300px) {
        margin-top: 10px;
        width: 64px;
        height: 51px;
    }
    @media (min-width: 598px) {
        margin-top: 30px;
        width: 69px;
        height: 56px;
    }
    @media (min-width: 650px) {
        margin-top: 30px;
        width: 89px;
        height: 76px;
    }
    @media (min-width: 850px) {
        width: 94px;
        height: 81px;
    }
`;

const CrossLogo = styled.img`
    margin: auto;
    margin-left: 0;
    margin-right: 0;
    @media (min-width: 300px) {
        width: 20px;
        height: 20px;
    }
    @media (min-width: 598px) {
        height: 25px;
        width: 25px;
    }
    @media (min-width: 650px) {
        height: 40px;
        width: 40px;
    }
    @media (min-width: 1000px) {
        height: 50px;
        width: 50px;
    }
`;

const IdeaLogo = styled.img`
    @media (min-width: 300px) {
        height: 30px;
        width: 30px;
        margin-left: 5px;
        margin-bottom: 7px;
    }
    @media (min-width: 598px) {
        height: 35px;
        width: 35px;
        margin-left: 8px;
        margin-bottom: 10px;
    }
    @media (min-width: 1000px) {
        height: 55px;
        width: 55px;
        margin-left: 13px;
        margin-bottom: 10px;
    }
`;

const ModalImageContainer = styled(Container)({
    padding: '10vh',
    '@media (max-height: 450px)': {
        padding: '18vh'
    },
    '@media (min-height: 760px) and (min-width: 580px)': {
        padding: '6vh'
    }
});

const BottomButtonContainer = styled(Box)({
    marginTop: '40px',
    height: '90px',
    backgroundColor: '#ffffff',
    boxShadow: '0px -2px 20px rgba(118, 118, 118, 0.2)',
    borderRadius: '24px 24px 0px 0px',
    position: 'sticky',
    bottom: 0,
    zIndex: 100
});

export function ExerciseObjective(): JSX.Element {
    const classes = useStyles();
    const { t } = useTranslation();
    const isLargeScreen = useMediaQuery('(min-width:598px)');
    const isVeryLargeScreen = useMediaQuery('(min-width:1000px)');
    const history = useHistory();
    const { type } = useParams<{ type: string }>();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [isInspirationScreen, setIsInspirationScreen] = useState<boolean>(false);
    const [exerciseList, setExerciseList] = useState<ThemeExamplesViewData[]>([]);
    const [isThemeModalOpen, setThemeModalOpen] = useState<boolean>(false);
    const [clickedCard, setClickedCard] = useState<ThemeExamplesViewData>();
    const [list, setList] = useState<string[]>([]);
    const [firstThemeList, setFirstThemeList] = useState<string[]>([]);
    const [secondThemeList, setSecondThemeList] = useState<string[]>([]);

    useEffect(() => {
        const iframe = document.getElementsByTagName('iframe');
        if (iframe[0] && iframe[0].contentWindow && user) {
            const listener = window.addEventListener('blur', () => {
                if (document.activeElement === iframe[0]) {
                    clickAnalytics(`objective_${type}_video_seen`, user);
                }
            });
        }
    }, []);

    useEffect(() => {
        if (clickedCard && clickedCard.otherThemes) {
            setList(clickedCard.otherThemes);
        }
    }, [clickedCard]);

    useEffect(() => {
        if (list.length > 4) {
            const middleIndex = Math.ceil(list.length / 2);
            const firstHalf = [];
            const secondHalf = [];
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < middleIndex; i++) {
                firstHalf.push(list[i]);
            }
            // eslint-disable-next-line no-plusplus
            for (let i = middleIndex; i < list.length; i++) {
                secondHalf.push(list[i]);
            }
            setFirstThemeList(firstHalf);
            setSecondThemeList(secondHalf);
        } else if (list.length <= 4) {
            const firstHalf = [];
            const secondHalf = [];
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < list.length; i += 1) {
                firstHalf.push(list[i]);
            }
            setFirstThemeList(firstHalf);
            setSecondThemeList([]);
        }
    }, [list]);

    const iconTextSection = (large: boolean, text: string): JSX.Element => {
        const img = <Logo src={check} alt="checkIcon" />;
        const data = (
            <Typography
                variant="body2"
                color="#374653"
                sx={{ marginTop: '10px', marginBottom: '8px', letterSpacing: '-0.5px' }}>
                {t(text)}
            </Typography>
        );
        return (
            <Box sx={{ whiteSpace: 'nowrap', display: 'inline' }}>
                {large ? (
                    <SectionContainerSouvenin>
                        {img}
                        {data}
                    </SectionContainerSouvenin>
                ) : (
                    <Section sx={{ width: '169px' }}>
                        {' '}
                        {img}
                        {data}
                    </Section>
                )}
            </Box>
        );
    };

    const inspireMeButton = (): JSX.Element => {
        return (
            <Box
                sx={{
                    width: '70%',
                    '@media (max-width: 598px)': {
                        width: '99%',
                        paddingTop: '10px'
                    },
                    margin: 'auto'
                }}>
                <Button
                    fullWidth
                    variant="outlined"
                    size="medium"
                    sx={{
                        border: '1px solid #374653',
                        boxShadow: '0px 0px 0px 2px rgba(92, 102, 112, 0.6)',
                        width: '75%',
                        fontSize: '15px',
                        paddingTop: '20px',
                        paddingBottom: '20px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        color: '#374653',
                        '@media (max-width: 598px)': {
                            width: '80%'
                        }
                    }}
                    onClick={async (): Promise<void> => {
                        const exercises = getThemeExamples();
                        setExerciseList(exercises);
                        setIsInspirationScreen(true);
                        if (user) {
                            clickAnalytics('click_inspire_button', user);
                        }
                    }}>
                    {t('inspireButton')}
                </Button>
            </Box>
        );
    };

    const otherThemesButton = (): JSX.Element => {
        return (
            <Box
                sx={{
                    paddingTop: '0px',
                    paddingBottom: '20px',
                    width: '70%',
                    '@media (max-width: 598px)': {
                        width: '99%',
                        paddingBottom: '10px'
                    },
                    margin: 'auto'
                }}>
                <Button
                    fullWidth
                    variant="outlined"
                    size="medium"
                    sx={{
                        border: '1px solid #374653',
                        boxShadow: '0px 0px 0px 2px rgba(92, 102, 112, 0.6)',
                        width: '75%',
                        fontSize: '20px',
                        paddingTop: '30px',
                        paddingBottom: '30px',
                        marginTop: '20px',
                        color: '#374653',
                        '@media (max-width: 598px)': {
                            width: '90%',
                            paddingTop: '20px',
                            paddingBottom: '20px'
                        }
                    }}
                    onClick={(): void => {
                        setThemeModalOpen(false);
                    }}>
                    <Typography variant="h5">{t('seeOtherThemes')}</Typography>
                </Button>
            </Box>
        );
    };

    const fetchButton = (skip?: boolean): JSX.Element => {
        return (
            <Box
                sx={{
                    width: '70%',
                    '@media (max-width: 598px)': {
                        width: '99%',
                        paddingTop: '10px'
                    },
                    margin: 'auto'
                }}>
                <Button
                    fullWidth
                    variant="outlined"
                    size="medium"
                    color="primary"
                    sx={{
                        border: '1px solid #8b004b',
                        boxShadow: '0px 0px 0px 2px rgba(208, 0, 112, 0.4)',
                        width: '75%',
                        fontSize: '15px',
                        paddingTop: '20px',
                        paddingBottom: '20px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        '@media (max-width: 598px)': {
                            width: '80%'
                        }
                    }}
                    onClick={(): void => {
                        if (ExerciseType.MEMORY.toLowerCase() === type) {
                            history.push(`/exercice-customisation/souven'in`);
                        } else {
                            history.push(`/exercice-customisation/puzzl'in`);
                        }
                    }}>
                    {skip ? t('skipObjective') : t('customiseExercise')}
                </Button>
            </Box>
        );
    };

    const objectiveTab = (
        <div>
            <Navbar
                leftIcon={backButton}
                centerIcon={ExerciseType.MEMORY.toLowerCase() === type ? memoryTitle : puzzleTitle}
            />
            {!isLargeScreen && (
                <div>
                    <div>
                        <iframe
                            id="iframe"
                            title="Puzzlin video"
                            src={
                                type === `puzzl'in`
                                    ? 'https://www.loom.com/embed/ee770a4df1e746e5b08a737e4af36912'
                                    : 'https://www.loom.com/embed/3c1ffbcba44249b7986664b8497634b7'
                            }
                            frameBorder="0"
                            allowFullScreen
                            style={{ width: '100%', height: '300px', border: '0px' }}
                        />
                    </div>
                    <PhoneContainer>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="objectiveExerciseTab"
                                id="objectiveExerciseTab">
                                <HeadingTopography variant="h5">
                                    {t('objectiveExerciseTab')}
                                </HeadingTopography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <Typography
                                        variant="body1"
                                        color="#002F55"
                                        sx={{ marginRight: '10px' }}>
                                        {t('dash')}
                                    </Typography>
                                    <UnderHeadingTopography variant="h6">
                                        {user?.role === Roles.FAMILY
                                            ? t('dynamiseFamily')
                                            : t('dynamise')}
                                    </UnderHeadingTopography>
                                </Box>
                                <Content sx={{ justifyContent: 'space-around' }}>
                                    <div>
                                        {iconTextSection(true, 'attention')}
                                        {iconTextSection(true, 'language')}
                                        {iconTextSection(true, 'brief')}
                                    </div>
                                    <div>
                                        {iconTextSection(true, 'executiveFunction')}
                                        {iconTextSection(true, 'visualFunction')}
                                        {iconTextSection(true, 'socialCognition')}
                                    </div>
                                </Content>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        marginTop: '10px'
                                    }}>
                                    <Typography
                                        variant="body1"
                                        color="#002F55"
                                        sx={{ marginRight: '10px' }}>
                                        {t('dash')}
                                    </Typography>
                                    <UnderHeadingTopography variant="h6">
                                        {t('anxiety')}
                                    </UnderHeadingTopography>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                        <HeadingTopography variant="h5">
                            {ExerciseType.MEMORY.toLowerCase() === type
                                ? t('goalTabSouvenin')
                                : t('goalTabPuzzlin')}
                        </HeadingTopography>
                        <Accordion sx={{ marginTop: '10px' }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="customisableElementsThumbnails"
                                id="customisableElementsThumbnails">
                                <HeadingTopography variant="h5">
                                    {t('customisableElementsThumbnails')}
                                </HeadingTopography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Container
                                    sx={{
                                        backgroundColor: '#E3E6E8',
                                        borderRadius: '10px',
                                        paddingBottom: '10px',
                                        padding: '1% 0'
                                    }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-around'
                                        }}>
                                        {ExerciseType.MEMORY.toLowerCase() === type && (
                                            <>
                                                <div>
                                                    <UnderHeadingTopography variant="h6">
                                                        {t('vignette')}
                                                    </UnderHeadingTopography>
                                                    <ThumbnailsLogo
                                                        src={thumbnailsIcon}
                                                        alt="thumbnailIcon"
                                                    />
                                                </div>
                                                <CrossLogo src={addCross} alt="addIcon" />
                                            </>
                                        )}
                                        <div>
                                            <UnderHeadingTopography variant="h6">
                                                {t('legend')}
                                            </UnderHeadingTopography>
                                            <LegendLogo src={legendIcon} alt="legendIcon" />
                                        </div>
                                        <CrossLogo src={addCross} alt="addIcon" />
                                        <div>
                                            <UnderHeadingTopography variant="h6">
                                                {t('message')}
                                            </UnderHeadingTopography>
                                            <MessageLogo src={dialog} alt="dialogIcon" />
                                        </div>
                                    </div>
                                </Container>
                            </AccordionDetails>
                        </Accordion>
                        <HeadingTopography
                            variant="subtitle2"
                            sx={{
                                fontWeight: 700,
                                marginTop: '15px !important',
                                display: user?.role === Roles.FAMILY ? 'block' : 'none',
                                paddingBottom: '20px !important',
                                marginLeft: '5px'
                            }}>
                            {t('inspirationAndInfo')}
                        </HeadingTopography>
                    </PhoneContainer>
                    <BottomButtonContainer
                        sx={{ display: user?.role === Roles.FAMILY ? 'flex' : 'none' }}>
                        <Container className={classes.groupButtonLayout}>
                            {inspireMeButton()}
                            {fetchButton(true)}
                        </Container>
                    </BottomButtonContainer>
                </div>
            )}
            {isLargeScreen && !isVeryLargeScreen && (
                <div>
                    <Box
                        sx={{
                            marginTop: '-20px',
                            width: '100%',
                            marginBottom: '20px'
                        }}>
                        <div>
                            <iframe
                                id="iframe"
                                title="Puzzlin video"
                                src={
                                    type === `puzzl'in`
                                        ? 'https://www.loom.com/embed/ee770a4df1e746e5b08a737e4af36912'
                                        : 'https://www.loom.com/embed/3c1ffbcba44249b7986664b8497634b7'
                                }
                                frameBorder="0"
                                allowFullScreen
                                style={{
                                    height: '300px',
                                    border: '0px',
                                    marginTop: '5%',
                                    marginBottom: '2%',
                                    width: '92%'
                                }}
                            />
                        </div>
                        <Root xs={12} sx={{ textAlign: 'left' }}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="objectiveExerciseTab"
                                    id="objectiveExerciseTab">
                                    <HeadingTopography variant="h5">
                                        {t('objectiveExerciseTab')}
                                    </HeadingTopography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                        <Typography
                                            variant="body1"
                                            color="#002F55"
                                            sx={{ marginRight: '10px' }}>
                                            {t('dash')}
                                        </Typography>
                                        <UnderHeadingTopography variant="h6">
                                            {t('dynamise')}
                                        </UnderHeadingTopography>
                                    </Box>
                                    <Content sx={{ justifyContent: 'space-around' }}>
                                        <div>
                                            {iconTextSection(false, 'attention')}
                                            {iconTextSection(false, 'language')}
                                        </div>
                                        <div>
                                            {iconTextSection(false, 'brief')}
                                            {iconTextSection(false, 'executiveFunction')}
                                        </div>
                                        <div>
                                            {iconTextSection(false, 'visualFunction')}
                                            {iconTextSection(false, 'socialCognition')}
                                        </div>
                                    </Content>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            marginTop: '10px'
                                        }}>
                                        <Typography
                                            variant="body1"
                                            color="#002F55"
                                            sx={{ marginRight: '10px' }}>
                                            {t('dash')}
                                        </Typography>
                                        <UnderHeadingTopography variant="h6">
                                            {t('anxiety')}
                                        </UnderHeadingTopography>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Root>
                    </Box>
                    <HeadingTopography variant="h5">
                        {ExerciseType.MEMORY.toLowerCase() === type
                            ? t('goalTabSouvenin')
                            : t('goalTabPuzzlin')}
                    </HeadingTopography>
                    <Root sx={{ margin: '10px 0' }}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="customisableElementsThumbnails"
                                id="customisableElementsThumbnails">
                                <HeadingTopography variant="h5">
                                    {t('customisableElementsThumbnails')}
                                </HeadingTopography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box>
                                    <Root
                                        sx={{
                                            textAlign: 'center',
                                            backgroundColor: '#E3E6E8',
                                            borderRadius: '10px',
                                            padding: '2% 0',
                                            margin: '0 20px 10px 20px !important'
                                        }}>
                                        <Container
                                            sx={{
                                                '@media (min-width: 750px)': {
                                                    height: '228px'
                                                },
                                                '@media (min-width: 900px)': {
                                                    height: '238px'
                                                }
                                            }}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-around',
                                                    margin: '0 10px'
                                                }}>
                                                {ExerciseType.MEMORY.toLowerCase() === type && (
                                                    <>
                                                        <div>
                                                            <UnderHeadingTopography variant="h6">
                                                                {t('vignette')}
                                                            </UnderHeadingTopography>
                                                            <ThumbnailsLogo
                                                                src={thumbnailsIcon}
                                                                alt="thumbnailIcon"
                                                            />
                                                        </div>
                                                        <CrossLogo src={addCross} alt="addIcon" />
                                                    </>
                                                )}
                                                <div>
                                                    <UnderHeadingTopography variant="h6">
                                                        {t('legend')}
                                                    </UnderHeadingTopography>
                                                    <LegendLogo src={legendIcon} alt="legendIcon" />
                                                </div>
                                                <CrossLogo src={addCross} alt="addIcon" />
                                                <div>
                                                    <UnderHeadingTopography variant="h6">
                                                        {t('message')}
                                                    </UnderHeadingTopography>
                                                    <MessageLogo src={dialog} alt="dialogIcon" />
                                                </div>
                                            </div>
                                        </Container>
                                    </Root>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Root>
                    <BottomButtonContainer
                        sx={{ display: user?.role === Roles.FAMILY ? 'flex' : 'none' }}>
                        <Container className={classes.groupButtonLayout}>
                            {inspireMeButton()}
                            {fetchButton(true)}
                        </Container>
                    </BottomButtonContainer>
                </div>
            )}
            {isVeryLargeScreen && (
                <div>
                    <Box
                        sx={{
                            display: 'flex',
                            marginTop: '-20px',
                            width: '97%',
                            marginBottom: '20px'
                        }}>
                        <Root xs={5} sx={{ textAlign: 'left', marginTop: '45px' }}>
                            <div>
                                <iframe
                                    id="iframe"
                                    title="Puzzlin video"
                                    src={
                                        type === `puzzl'in`
                                            ? 'https://www.loom.com/embed/ee770a4df1e746e5b08a737e4af36912'
                                            : 'https://www.loom.com/embed/3c1ffbcba44249b7986664b8497634b7'
                                    }
                                    frameBorder="0"
                                    allowFullScreen
                                    style={{
                                        height: '300px',
                                        border: '0px',
                                        margin: '5% 3% 5% 0%',
                                        width: '100%'
                                    }}
                                />
                            </div>
                        </Root>
                        <Root xs={8} sx={{ textAlign: 'left', marginTop: '45px' }}>
                            <HeadingTopography variant="h5">
                                {t('objectiveExerciseTab')}
                            </HeadingTopography>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography
                                    variant="body1"
                                    color="#002F55"
                                    sx={{ marginRight: '10px' }}>
                                    {t('dash')}
                                </Typography>
                                <UnderHeadingTopography variant="h6">
                                    {t('dynamise')}
                                </UnderHeadingTopography>
                            </Box>
                            <Content sx={{ justifyContent: 'space-between' }}>
                                <div>
                                    {iconTextSection(false, 'attention')}
                                    {iconTextSection(false, 'language')}
                                </div>
                                <div>
                                    {iconTextSection(false, 'brief')}
                                    {iconTextSection(false, 'executiveFunction')}
                                </div>
                                <div>
                                    {iconTextSection(false, 'visualFunction')}
                                    {iconTextSection(false, 'socialCognition')}
                                </div>
                            </Content>
                            <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
                                <Typography
                                    variant="body1"
                                    color="#002F55"
                                    sx={{ marginRight: '10px' }}>
                                    {t('dash')}
                                </Typography>
                                <UnderHeadingTopography variant="h6">
                                    {t('anxiety')}
                                </UnderHeadingTopography>
                            </Box>
                            <HeadingTopography variant="h5">
                                {ExerciseType.MEMORY.toLowerCase() === type
                                    ? t('goalTabSouvenin')
                                    : t('goalTabPuzzlin')}
                            </HeadingTopography>
                        </Root>
                    </Box>
                    <Box sx={{ marginTop: '-30px' }}>
                        <Root
                            sx={{
                                textAlign: 'center',
                                backgroundColor: '#E3E6E8',
                                borderRadius: '10px',
                                padding: '1% 0'
                            }}>
                            <Container
                                sx={{
                                    '@media (min-width: 1024px)': {
                                        height: '292px'
                                    },
                                    '@media (min-width: 1366px)': {
                                        height: '307px'
                                    }
                                }}>
                                <HeadingTopography variant="h5">
                                    {t('customisableElementsThumbnails')}
                                </HeadingTopography>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-around'
                                    }}>
                                    {ExerciseType.MEMORY.toLowerCase() === type && (
                                        <>
                                            <div>
                                                <UnderHeadingTopography variant="h6">
                                                    {t('vignette')}
                                                </UnderHeadingTopography>
                                                <ThumbnailsLogo
                                                    src={thumbnailsIcon}
                                                    alt="thumbnailIcon"
                                                />
                                            </div>
                                            <CrossLogo src={addCross} alt="addIcon" />
                                        </>
                                    )}
                                    <div>
                                        <UnderHeadingTopography variant="h6">
                                            {t('legend')}
                                        </UnderHeadingTopography>
                                        <img
                                            style={{
                                                marginTop: '20px',
                                                width: '160px',
                                                height: '120px'
                                            }}
                                            src={legendIcon}
                                            alt="legendIcon"
                                        />
                                    </div>
                                    <CrossLogo src={addCross} alt="addIcon" />
                                    <div>
                                        <UnderHeadingTopography variant="h6">
                                            {t('message')}
                                        </UnderHeadingTopography>
                                        <img
                                            style={{
                                                marginTop: '20px',
                                                width: '119px',
                                                height: '106px'
                                            }}
                                            src={dialog}
                                            alt="dialogIcon"
                                        />
                                    </div>
                                </div>
                            </Container>
                        </Root>
                    </Box>
                    <BottomButtonContainer
                        sx={{ display: user?.role === Roles.FAMILY ? 'flex' : 'none' }}>
                        <Container className={classes.groupButtonLayout}>
                            {inspireMeButton()}
                            {fetchButton(true)}
                        </Container>
                    </BottomButtonContainer>
                </div>
            )}
            <BottomButtonContainer sx={{ display: user?.role === Roles.FAMILY ? 'none' : 'flex' }}>
                <Container className={classes.groupButtonLayout}>
                    {inspireMeButton()}
                    {fetchButton()}
                </Container>
            </BottomButtonContainer>
        </div>
    );

    const getExercises = (): JSX.Element[] | null => {
        if (exerciseList) {
            return exerciseList.map((item) => {
                return (
                    <Grid
                        item
                        xs={6}
                        sm={3}
                        sx={{
                            padding: '1%'
                        }}
                        key={item.id}>
                        <ThemeCard
                            onCardClicked={(value: ThemeExamplesViewData): void => {
                                if (user) {
                                    clickAnalytics('click_thematic_inspire_page', user);
                                }
                                setClickedCard(value);
                                setThemeModalOpen(true);
                            }}
                            item={item}
                        />
                    </Grid>
                );
            });
        }
        return null;
    };

    const inspirationScreen = (
        <div>
            <Navbar
                leftIcon={backButton}
                centerIcon={ExerciseType.MEMORY.toLowerCase() === type ? memoryTitle : puzzleTitle}
            />
            <PhoneContainer>
                <HeadingTopography variant="h5" style={{ color: '#002F55', margin: '5% 0%' }}>
                    {t('needInspiration')}
                </HeadingTopography>
                <Box sx={{ display: 'flex', alignItems: 'center', margin: '2% 0%' }}>
                    <img
                        src={arrowHand}
                        alt="ideaIcon"
                        style={{ width: '25px', height: '25px', marginRight: '10px' }}
                    />
                    <Typography variant="h5" sx={{ color: '#002F55' }}>
                        {t('clickOn1Theme')}
                    </Typography>
                </Box>
                <Grid container sx={{ width: '100%', margin: 'auto' }}>
                    {getExercises()}
                </Grid>
                <Box sx={{ marginTop: '1%', marginLeft: '10px' }}>
                    <Section sx={{ width: '169px' }}>
                        <HeadingTopography variant="h6">{t('inspiration')}</HeadingTopography>
                        <IdeaLogo src={idea} alt="ideaIcon" />
                    </Section>
                    <StyledTopography
                        variant="body2"
                        color="#374653"
                        sx={{
                            whiteSpace: 'pre-wrap'
                        }}>
                        {t('themeExamples')}
                    </StyledTopography>
                    <StyledTopography
                        variant="body2"
                        color="#374653"
                        sx={{
                            whiteSpace: 'pre-wrap'
                        }}>
                        {t('instructionsToChangeTheme')}
                    </StyledTopography>
                </Box>
                <Container>{fetchButton()}</Container>
            </PhoneContainer>
        </div>
    );

    const themeAlertContent = (
        <Box sx={{ marginTop: '5%' }}>
            <ModalImageContainer
                sx={{
                    borderTop: '1px solid #E3E6E8',
                    paddingLeft: '0 !important',
                    paddingRight: '0 !important',
                    paddingBottom: '0 !important',
                    marginTop: '10px'
                }}>
                <Box
                    sx={{
                        width: '200px',
                        margin: 'auto',
                        marginTop: '-130px !important',
                        border: '1px solid #E3E6E8',
                        borderRadius: '5px'
                    }}>
                    {clickedCard ? (
                        <ThemeCard onCardClicked={(): void => {}} item={clickedCard} />
                    ) : null}
                </Box>
            </ModalImageContainer>
            <Container
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start !important'
                }}>
                <ul style={{ width: '50%' }}>
                    {firstThemeList.map((item) => {
                        return (
                            <li
                                key={item}
                                style={{
                                    wordBreak: 'break-all',
                                    textAlign:
                                        isLargeScreen || isVeryLargeScreen ? 'justify' : 'left',
                                    width: isLargeScreen || isVeryLargeScreen ? '50%' : '',
                                    margin: isLargeScreen || isVeryLargeScreen ? 'auto' : '0'
                                }}>
                                <Typography
                                    variant="caption"
                                    sx={{
                                        fontWeight: 500,
                                        fontSize:
                                            isLargeScreen || isVeryLargeScreen
                                                ? '0.85rem !important'
                                                : '12px'
                                    }}>
                                    {item}
                                </Typography>
                            </li>
                        );
                    })}
                </ul>
                <ul style={{ width: '50%' }}>
                    {secondThemeList.map((item) => {
                        return (
                            <li
                                key={item}
                                style={{
                                    wordBreak: 'break-all',
                                    textAlign:
                                        isLargeScreen || isVeryLargeScreen ? 'justify' : 'left',
                                    width: isLargeScreen || isVeryLargeScreen ? '50%' : '',
                                    margin: isLargeScreen || isVeryLargeScreen ? 'auto' : '0'
                                }}>
                                <Typography
                                    variant="caption"
                                    sx={{
                                        fontWeight: 500,
                                        fontSize:
                                            isLargeScreen || isVeryLargeScreen
                                                ? '0.85rem !important'
                                                : '12px'
                                    }}>
                                    {item}
                                </Typography>
                            </li>
                        );
                    })}
                </ul>
            </Container>
            <Container>
                {otherThemesButton()}
                {fetchButton()}
            </Container>
        </Box>
    );

    return (
        <div>
            {isInspirationScreen ? inspirationScreen : objectiveTab}
            <DeactiveModall
                isModelOpen={isThemeModalOpen}
                setModelOpen={setThemeModalOpen}
                body={themeAlertContent}
                circleIcon
                onCancel={(): void => {
                    setThemeModalOpen(false);
                }}
            />
        </div>
    );
}
