import * as React from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import circleCloseIcon from '../assets/circleClose.png';

const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 360,
    bgcolor: 'background.paper',
    borderRadius: '24px',
    boxShadow: 24,
    p: 4,
    outline: 'none'
};

const Logo = styled.img`
    object-fit: inherit;
    width: 48px;
    height: 48px;
    margin-left: auto;
`;

interface DeactiveModalProps {
    isModelOpen: boolean;
    setModelOpen: Function;
    title: string;
    bodyText: string;
    buttonText: string;
    onButtonClick: Function;
}

export function DeactiveModal({
    isModelOpen,
    setModelOpen,
    title,
    bodyText,
    buttonText,
    onButtonClick
}: DeactiveModalProps): JSX.Element {
    const body = (
        <Box sx={style}>
            <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
                <Logo
                    src={circleCloseIcon}
                    alt="circleCloseIcon"
                    onClick={(): void => {
                        setModelOpen(false);
                    }}
                />
                <Typography
                    variant="h3"
                    color="#374653"
                    sx={{
                        marginBottom: '20px',
                        textAlign: 'center'
                    }}>
                    {title}
                </Typography>
                <Typography
                    variant="subtitle1"
                    color="#374653"
                    sx={{
                        marginBottom: '20px'
                    }}>
                    {bodyText}
                </Typography>
                <Button
                    fullWidth
                    disableElevation
                    variant="outlined"
                    size="large"
                    onClick={(): void => onButtonClick()}>
                    <Typography variant="subtitle1">{buttonText}</Typography>
                </Button>
            </Box>
        </Box>
    );

    return (
        <div>
            <Modal
                open={isModelOpen}
                onClose={(): void => {
                    setModelOpen(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                {body}
            </Modal>
        </div>
    );
}
