/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import canvasConfetti, { CreateTypes, GlobalOptions, Options } from 'canvas-confetti';
import React, { CSSProperties, RefObject } from 'react';

export interface Iprops extends Options, GlobalOptions {
    fire?: any;
    reset?: any;
    width?: string | number;
    height?: string | number;
    className?: string;
    style?: CSSProperties;
    refConfetti?: (confetti: CreateTypes | null) => void;
    onDecay?: () => void;
    onFire?: () => void;
    onReset?: () => void;
}

// eslint-disable-next-line import/no-default-export
export default class ReactCanvasConfetti extends React.Component<Iprops> {
    private refCanvas: RefObject<HTMLCanvasElement>;

    private confetti: CreateTypes | null;

    constructor(props: Iprops) {
        super(props);
        this.refCanvas = React.createRef();
        this.confetti = null;
    }

    componentDidMount(): void {
        if (!this.refCanvas.current) {
            return;
        }

        const { resize, useWorker } = this.props;
        const globalOptions: GlobalOptions = {
            resize: typeof resize === 'undefined' ? true : resize,
            useWorker: typeof useWorker === 'undefined' ? true : useWorker
        };

        this.confetti = canvasConfetti.create(this.refCanvas.current, globalOptions);
        this.setRefConfetti();
    }

    componentDidUpdate(prevProps: Readonly<Iprops>): void {
        const { fire, reset } = this.props;
        const isFireTrue = !!fire;
        const isFireChanged = fire !== prevProps.fire;

        if (isFireTrue && isFireChanged) {
            this.fireConfetti();
        }

        const isResetTrue = !!reset;
        const isResetChanged = reset !== prevProps.reset;

        if (isResetTrue && isResetChanged) {
            this.resetConfetti();
        }
    }

    componentWillUnmount(): void {
        this.unsetRefConfetti();
    }

    private setRefConfetti(): void {
        const { refConfetti } = this.props;

        refConfetti && refConfetti(this.confetti);
    }

    private unsetRefConfetti(): void {
        const { refConfetti } = this.props;

        refConfetti && refConfetti(null);
    }

    private fireConfetti() {
        if (!this.confetti) {
            return;
        }

        const {
            onFire,
            onDecay,
            onReset,
            className,
            style,
            width,
            height,
            refConfetti,
            fire,
            reset,
            ...confettiProps
        } = this.props;

        onFire && onFire();
        const promise = this.confetti(confettiProps);
        promise &&
            promise.then(() => {
                onDecay && onDecay();
            });
    }

    private resetConfetti() {
        if (!this.confetti) {
            return;
        }
        this.confetti.reset();
        const { onReset } = this.props;
        onReset && onReset();
    }

    render() {
        return (
            <canvas
                ref={this.refCanvas}
                style={{ position: 'absolute', zIndex: '1', width: '100%', height: '100%' }}
            />
        );
    }
}
