import { Box, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import styled from 'styled-components';
import { Roles } from '../../models/Roles';
import tickIcon from '../../assets/tickIcon.png';
import { InputComponent } from '../../components/FormComponents/InputComponent';
import { AssignExercise } from './AssignExercise';
import { ExerciseMessage } from './ExerciseMessage';
import { ExerciseType } from '../../models/ExerciseType';
import { UserContext } from '../../providers/UserProvider';
import { ExerciseItem } from '../../models/ExerciseItem';
import { Exercise } from '../../models/Exercise';
import { ExitCustomizationModal } from '../../components/ExitCustomizationModal';
import { UserDataProp } from '../../models/userDataProp';
import { getFamilyUser, getPatientFromCode } from '../../services/cloudFirestore';
import { SelectComponent } from '../../components/SelectComponent';
import { Header } from '../../components/CreateAndEdit/Header';
import { TitleAndIntro } from '../../components/CreateAndEdit/TitleAndIntro';
import { ButtonsCreateAndEdit } from '../../components/CreateAndEdit/ButtonsCreateAndEdit';
import { ThumbnailsCards } from '../../components/ThumbnailsCards';

const useStyles = makeStyles(() => ({
    deSelectedCard: {
        border: '1px solid #E3E6E8',
        borderRadius: '9px',
        padding: '9px'
    },
    inputLayout: {
        display: 'block',
        width: '100%'
    },
    inputContainer: {
        display: 'block'
    },
    secondStepContainer: {
        '@media (max-width: 598px)': {
            width: '95%',
            margin: 'auto'
        },
        '@media (min-width: 598px)': {
            width: '75%',
            margin: 'auto'
        }
    },
    input: {
        marginBottom: '12px',
        color: '#004680 !important'
    },
    selectPlaceholder: {
        width: '100%',
        marginTop: '0px',
        color: '#A1A1A1'
    },
    onlyPuzzlinImage: {
        zIndex: 1,
        objectFit: 'cover',
        '@media (max-width: 598px)': {
            height: '300px',
            marginBottom: '20px'
        },
        '@media (min-width: 598px)': {
            height: '400px'
        }
    }
}));

const InputLabel = styled(Typography)(() => ({
    display: 'block',
    width: '100%'
}));

interface StateInterface {
    cardsArray: ExerciseItem[];
}

interface SecondStepperProps {
    selectedCardsReceived: ExerciseItem[];
    currentStep: React.Dispatch<React.SetStateAction<number>>;
    content: Exercise;
}

export function PreviewExercise({
    selectedCardsReceived,
    currentStep,
    content
}: SecondStepperProps): JSX.Element {
    const { t } = useTranslation();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const themeDropdownList = [
        t('art'),
        t('familyAndFriends'),
        t('youth'),
        t('premises'),
        t('nature'),
        t('dayToDay'),
        t('sciences'),
        t('sport')
    ];
    const [openModal, setOpenModal] = useState<boolean>(false);
    const classes = useStyles();
    const [activeStep, setStep] = useState<number>(1);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [patientArray, setPatientArray] = useState<string[] | null>(null);
    const [relationship, setRelationship] = useState<string>('');
    const [picture, setPicture] = useState<string | File>('');
    const [data, setData] = useState<Exercise>({
        id: content.id,
        exerciseType: content.exerciseType,
        patientList: content.patientList,
        exerciseName: content.exerciseName,
        authorId: content.authorId,
        exerciseImages: content.exerciseImages,
        review: content.review,
        encouragementMessage: content.encouragementMessage,
        congratulationsMessage: content.congratulationsMessage,
        rewardPhoto: content.rewardPhoto,
        rewardVideo: content.rewardVideo,
        establishmentCode: content.establishmentCode,
        establishmentList: content.establishmentList,
        familyCode: content.familyCode,
        theme: content.theme
    });
    const [selectedCards, setSelectCard] = useState<StateInterface>({
        cardsArray: selectedCardsReceived
    });
    const [isKeybord, setIsKeybord] = useState<boolean>(false);
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    );

    const getFamilyMember = async (id: string): Promise<void> => {
        const familyUser = await getFamilyUser(id);
        if (familyUser && familyUser.relationship) {
            setRelationship(familyUser.relationship);
        } else {
            setRelationship('');
        }
    };

    const getPatient = async (code: string): Promise<void> => {
        const newList: string[] | null | undefined = [];
        const patient = await getPatientFromCode(code);
        if (patient && patient.id) {
            newList.push(patient.id);
            setPatientArray(newList);
        }
    };

    const handleChange = (state: string, value: string | undefined | null): void => {
        setData({ ...data, [state]: value });
    };

    useEffect(() => {
        if (
            content.exerciseType === ExerciseType.MEMORY ||
            content.exerciseType === ExerciseType.MEMORIN
        ) {
            if (data.exerciseName && data.theme) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        }
        if (
            content.exerciseType === ExerciseType.PUZZLE ||
            content.exerciseType === ExerciseType.ORDER
        ) {
            if (
                selectedCardsReceived &&
                selectedCardsReceived.length === 1 &&
                data.exerciseName === undefined
            ) {
                handleChange('exerciseName', selectedCardsReceived[0].answer);
            }
            if (data.exerciseName) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        }
    }, [data.exerciseName, data.theme]);

    useEffect(() => {
        if (user && user.role === Roles.FAMILY && user.familyCode && user.id) {
            getFamilyMember(user.id);
            getPatient(user.familyCode);
        }
    }, [disabled]);

    // useEffect for make by default a theme
    useEffect(() => {
        if (
            content &&
            (content.exerciseType === ExerciseType.PUZZLE ||
                content.exerciseType === ExerciseType.ORDER) &&
            selectedCards.cardsArray &&
            selectedCards.cardsArray[0].exerciseImage
        ) {
            setPicture(selectedCards.cardsArray[0].exerciseImage);
            handleChange('theme', selectedCards.cardsArray[0].theme);
        }
        if (
            (content.exerciseType === ExerciseType.MEMORY ||
                content.exerciseType === ExerciseType.MEMORIN) &&
            !content.theme
        ) {
            const counts: { [key: string]: number } = {};
            let compare = 0;
            let mostFrequent = '';
            selectedCards.cardsArray.forEach((el) => {
                if (el.theme !== undefined) {
                    counts[el.theme] = counts[el.theme] ? (counts[el.theme] += 1) : 1;
                    if (counts[el.theme] > compare) {
                        compare = counts[el.theme];
                        mostFrequent = el.theme;
                    }
                }
            });
            if (themeDropdownList.includes(mostFrequent)) {
                handleChange('theme', mostFrequent);
            }
        }
    }, []);

    const getExercises = (): JSX.Element[] | null => {
        if (selectedCards.cardsArray) {
            return selectedCards.cardsArray.map((item) => {
                return (
                    <Grid
                        item
                        xs={6}
                        sm={4}
                        sx={{
                            padding: '1%'
                        }}
                        key={item.id}>
                        <ThumbnailsCards
                            onCardClicked={(): void => {}}
                            onLeaveCard={(): void => {}}
                            item={item}
                            classReceived={classes.deSelectedCard}
                        />
                    </Grid>
                );
            });
        }
        return null;
    };

    const screen = (
        <div>
            <Box sx={{ boxShadow: 'inset 0px -1px 0px #E3E6E8' }}>
                <Header
                    setOpenModal={setOpenModal}
                    type={data.exerciseType}
                    activeStep={activeStep}
                    user={user}
                />
            </Box>
            <Box
                sx={{
                    backgroundColor: '#F8F9F9',
                    boxShadow: 'inset 0px -1px 0px rgba(172, 188, 199, 0.4)'
                }}>
                <TitleAndIntro
                    type={data.exerciseType}
                    activeStep={activeStep}
                    user={user}
                    icon={tickIcon}
                    endBody={t('shortTitleMemoryDashboard')}
                    body={t('named')}
                />
            </Box>
            <div className={classes.secondStepContainer}>
                <Box
                    style={{ width: '95%', margin: '2% 2%', alignItems: 'center' }}
                    className={classes.inputContainer}>
                    <div className={classes.inputLayout}>
                        <InputLabel
                            variant="body1"
                            style={{
                                textAlign: 'left'
                            }}>
                            {t('exerciseName')}
                        </InputLabel>
                        <InputComponent
                            placeholder={
                                user && user.role === Roles.FAMILY
                                    ? t('potraitsOfBrothersAndSisters')
                                    : t('reminiscenceWorkshop')
                            }
                            value={data.exerciseName ?? null}
                            type="text"
                            isMultiline={false}
                            maximumLength={30}
                            onTextChange={(value: string): void =>
                                handleChange('exerciseName', value)
                            }
                            onBlur={(): void => setIsKeybord(false)}
                            onFocus={(): void => setIsKeybord(true)}
                            className={classes.input}
                        />
                    </div>
                    {content.exerciseType === ExerciseType.MEMORY ||
                    content.exerciseType === ExerciseType.MEMORIN ? (
                        <Box>
                            <InputLabel
                                variant="body1"
                                sx={{
                                    textAlign: 'left'
                                }}>
                                {t('principalTheme')}
                            </InputLabel>
                            <SelectComponent
                                dropdownList={themeDropdownList}
                                placeholder={t('selectTheme')}
                                onTextChange={(value: string): void => handleChange('theme', value)}
                                value={data.theme ?? null}
                                classCustom={classes.input}
                                placeholderStyling={classes.selectPlaceholder}
                            />
                        </Box>
                    ) : null}
                </Box>
            </div>
            {content.exerciseType === ExerciseType.MEMORY ||
            content.exerciseType === ExerciseType.MEMORIN ? (
                <Grid
                    container
                    sx={{ width: '100%', margin: 'auto' }}
                    className={classes.secondStepContainer}>
                    {getExercises()}
                </Grid>
            ) : (
                <img
                    src={typeof picture === 'string' ? picture : ''}
                    alt="SampleImg"
                    className={classes.onlyPuzzlinImage}
                />
            )}
            <div style={{ height: window.innerWidth < 600 ? '190px' : '81px', width: '100%' }} />
            <Box
                style={{
                    position: isMobile && isKeybord ? 'inherit' : 'fixed',
                    zIndex: '2',
                    bottom: '0',
                    width: '100%',
                    backgroundColor: '#FFFFFF'
                }}>
                <ButtonsCreateAndEdit
                    disabled={disabled}
                    nextText={t('nextStep')}
                    nextClick={(): void => setStep(activeStep + 1)}
                    previousClick={(): void => {
                        setStep(activeStep - 1);
                        currentStep(activeStep - 1);
                    }}
                />
            </Box>
        </div>
    );

    return (
        <div>
            {activeStep === 1 && screen}
            {(user?.role === Roles.ADMIN || user?.role === Roles.THERAPIST) && activeStep === 2 && (
                <AssignExercise content={data} currentStep={setStep} />
            )}
            {user?.role === Roles.FAMILY && activeStep === 2 && (
                <ExerciseMessage
                    content={data}
                    currentStep={setStep}
                    patientArray={patientArray && patientArray?.length > 0 ? patientArray : null}
                    relationship={relationship}
                />
            )}
            <ExitCustomizationModal openModal={openModal} setOpenModal={setOpenModal} />
        </div>
    );
}
