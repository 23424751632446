import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DeactiveModall } from './ContainerModal';

interface ModalInterface {
    openModal: boolean;
    setOpenModal: Function;
}

const ModalButtons = styled(Button)(() => ({
    '@media (max-width: 598px)': {
        width: '100%',
        margin: '2% 0%'
    },
    '@media (min-width: 598px)': {
        width: '30%'
    }
}));

const ButtonContainer = styled(Box)({
    '@media (max-width: 598px)': {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    '@media (min-width: 598px)': {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row'
    }
});

export function ExitCustomizationModal({ openModal, setOpenModal }: ModalInterface): JSX.Element {
    const { t } = useTranslation();
    const history = useHistory();

    const handleExitCustomization = (): void => {
        setOpenModal(false);
        history.push('/home');
    };

    const ExitModal = (
        <div style={{ width: '90%', margin: 'auto' }}>
            <Typography variant="h3" color="#374653" sx={{ textAlign: 'center' }}>
                {t('wantToExitCustomization')}
            </Typography>
            <Typography
                variant="subtitle1"
                color="#374653"
                mt={2}
                sx={{ textAlign: 'center', fontWeight: 500 }}>
                {t('disclaimerToExitCustomization')}
            </Typography>
            <ButtonContainer mt={3} mb={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                <ModalButtons
                    variant="outlined"
                    sx={{
                        border: '1px solid #374653',
                        boxShadow: '0px 0px 0px 2px rgba(92, 102, 112, 0.6)',
                        borderRadius: '12px',
                        width: '30%',
                        marginRight: '5%'
                    }}
                    onClick={(): void => setOpenModal(false)}>
                    <Typography variant="subtitle1" color="#374653">
                        {t('toCancel')}
                    </Typography>
                </ModalButtons>
                <ModalButtons
                    variant="outlined"
                    sx={{
                        border: '1px solid #8B004B',
                        boxShadow: '0px 0px 0px 2px rgba(208, 0, 112, 0.4)',
                        borderRadius: '12px',
                        width: '30%'
                    }}
                    onClick={(): void => handleExitCustomization()}>
                    <Typography variant="subtitle1" color="#D00070">
                        {t('yes')}
                    </Typography>
                </ModalButtons>
            </ButtonContainer>
        </div>
    );

    return (
        <DeactiveModall
            isModelOpen={openModal}
            setModelOpen={setOpenModal}
            body={ExitModal}
            circleIcon
            onCancel={(): void => {
                setOpenModal(false);
            }}
        />
    );
}
