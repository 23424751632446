/* eslint-disable import/no-cycle */
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Card,
    CardMedia,
    Grid,
    Typography,
    useMediaQuery
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import styled from 'styled-components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useHistory } from 'react-router-dom';
import ExtensionIcon from '@mui/icons-material/Extension';
import TocIcon from '@mui/icons-material/Toc';
import { Roles } from '../../models/Roles';
import giftIcon from '../../assets/giftIcon.png';
// import { BrainSvgIcon } from '../BrainSvgIcon';
import { BrainSvgIcon } from '../../components/BrainSvgIcon';
import MemorinSvgIcon from '../../assets/MemorinSvgIcon.svg';
import puzzleWatermark from '../../assets/puzzleWatermark.png';
import thankyouIcon from '../../assets/thankyouIcon.png';
import LittlePlayButtonVideo from '../../assets/LittlePlayButtonVideo.svg';
import youtubeLittleIcon from '../../assets/youtubeLittleIcon.svg';
import { UserContext } from '../../providers/UserProvider';
import { ExerciseItem } from '../../models/ExerciseItem';
import { Exercise } from '../../models/Exercise';
import { ExitCustomizationModal } from '../../components/ExitCustomizationModal';
import { ExerciseType } from '../../models/ExerciseType';
import { UserDataProp } from '../../models/userDataProp';
import { ModalContainer } from '../../components/ModalContainer';
import { getImageKitUrlFrom } from '../../utilities/utils';
import { formatVideoUrl } from '../../services/formatFunctions';
import { getExerciseItem, saveExercise } from '../../services/cloudFirestore';
import { Header } from '../../components/CreateAndEdit/Header';
import { TitleAndIntro } from '../../components/CreateAndEdit/TitleAndIntro';
import { ButtonsCreateAndEdit } from '../../components/CreateAndEdit/ButtonsCreateAndEdit';
import { ThumbnailsCards } from '../../components/ThumbnailsCards';

const useStyles = makeStyles(() => ({
    selectedCard: {
        border: '3px solid #D00070',
        borderRadius: '9px',
        width: '100%',
        height: '100%',
        cursor: 'pointer'
    },
    deSelectedCard: {
        border: '1px solid #E3E6E8',
        borderRadius: '9px'
    },
    secondStepContainer: {
        '@media (max-width: 598px)': {
            width: '95%',
            margin: 'auto'
        },
        '@media (min-width: 598px)': {
            width: '85%',
            margin: 'auto'
        }
    },
    onlyPuzzlinImage: {
        zIndex: 1,
        objectFit: 'cover',
        '@media (max-width: 598px)': {
            height: '300px',
            marginBottom: '20px'
        },
        '@media (min-width: 598px)': {
            height: '400px'
        }
    },
    accordionHead: {
        backgroundColor: '#fbfbfb',
        boxShadow: 'none',
        color: '#374653',
        margin: '15px 0',
        '&.MuiAccordion-root:before': {
            display: 'none'
        }
    },
    accordionBody: {
        backgroundColor: '#F4F4F4',
        whiteSpace: 'pre-line',
        borderRadius: '9px'
    },
    videoContent: {
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        height: '40vh',
        writingMode: 'vertical-rl',
        width: '100%',
        '@media (max-width: 420px) ': {
            height: '30vh'
        },
        '@media (max-width: 300px)': {
            height: '20vh'
        }
    },
    videoMedia: {
        width: '100%',
        padding: '0',
        height: '100%',
        backgroundColor: 'black',
        borderRadius: '9px 9px 0 0'
    },
    playButton: {
        height: '15%',
        width: '15%',
        top: 'calc(100% / 2 - (15% / 2))',
        left: 'calc(100% / 2 - (15% / 2))',
        position: 'absolute',
        '@media (max-width: 32em)': {
            height: '20%',
            width: '20%',
            top: 'calc(100% / 2 - (20% / 2))',
            left: 'calc(100% / 2 - (20% / 2))'
        }
    },
    divVideoDisplay: {
        animationName: `$fade-out`,
        animationDuration: '2s'
    },
    '@keyframes fade-out': {
        from: {
            opacity: 1
        },
        to: {
            opacity: 0
        }
    }
}));

const NextStepButton = styled(Button)(() => ({
    margin: '2% 0%',
    border: '1px solid #8B004B',
    boxShadow: '0px 0px 0px 2px rgba(208, 0, 112, 0.4)',
    '@media (min-width: 598px)': {
        width: '40%',
        padding: '0px'
    }
}));

const ImageInfoContainer = styled.div`
    // position: absolute;
    z-index: 2;
    margin-left: 5px;
    height: 36px;
    width: 36px;
    // margin-top: 15px;
    // margin-left: calc(100% - 50px);
    background-color: white;
    border-radius: 50%;
    color: rgba(251, 251, 251, 1);
    max-width: 150px;
    align-items: center;
    display: flex;
    border: 0.7px solid #c4c4c4;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

interface StepperProps {
    content: Exercise;
    videoTitle: { video: string; videoYt: string };
    currentStep: React.Dispatch<React.SetStateAction<number>>;
}

export function ExerciseSummary({ content, videoTitle, currentStep }: StepperProps): JSX.Element {
    const isSmallWidth = !useMediaQuery('(min-width:598px)');
    const history = useHistory();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const { t } = useTranslation();
    const classes = useStyles();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [exerciseItemList, setExerciseItemList] = useState<ExerciseItem[]>([]);
    const [picture, setPicture] = useState<string>();
    const [openEndModal, setOpenEndModal] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [activeStep, setStep] = useState<number>(5);
    const [imageLoaded, setImageLoaded] = useState<boolean>(false);

    useEffect(() => {
        const getExerciseList = async (): Promise<void> => {
            const list: ExerciseItem[] = [];
            if (content.exerciseImages) {
                await Promise.all(
                    content.exerciseImages.map(async (element) => {
                        if (element) {
                            const item = await getExerciseItem(element);
                            if (item) {
                                list.push(item);
                            }
                        }
                    })
                );
                if (
                    content.exerciseType === ExerciseType.MEMORY ||
                    content.exerciseType === ExerciseType.MEMORIN
                ) {
                    setExerciseItemList(list);
                }
                if (
                    (content.exerciseType === ExerciseType.PUZZLE ||
                        content.exerciseType === ExerciseType.ORDER) &&
                    typeof list[0].exerciseImage === 'string'
                ) {
                    setPicture(list[0].exerciseImage);
                }
            }
        };
        getExerciseList();
    }, []);

    const getExercises = (): JSX.Element[] | null => {
        if (exerciseItemList) {
            return exerciseItemList.map((item) => {
                return (
                    <Grid
                        item
                        xs={6}
                        sm={4}
                        sx={{
                            padding: '1%'
                        }}
                        key={item.id}>
                        <ThumbnailsCards
                            onCardClicked={(): void => {}}
                            onLeaveCard={(): void => {}}
                            item={item}
                            classReceived={classes.deSelectedCard}
                        />
                    </Grid>
                );
            });
        }
        return null;
    };

    const handleSaveExercise = async (): Promise<void> => {
        if (user && user.id && user.role) {
            setDisabled(true);
            await saveExercise(user, content);
        }
        setOpenEndModal(true);
    };

    const showGoodVideo = (): JSX.Element | null => {
        if (content.rewardVideo !== undefined && typeof content.rewardVideo === 'string') {
            return (
                // <CardMedia
                //     component="iframe"
                //     src={formatVideoUrl(content.rewardVideo)}
                //     className={classes.videoMedia}
                // />
                <>
                    <video
                        className={classes.videoMedia}
                        style={{ borderRadius: '9px' }}
                        preload="metadata"
                        poster={
                            content.rewardVideo && typeof content.rewardVideo === 'string'
                                ? `https://img.youtube.com/vi/${content.rewardVideo.match(
                                      /[\w\\-]{11,}/
                                  )}/0.jpg`
                                : ''
                        }
                        controls={false}>
                        <track
                            src="your video"
                            kind="captions"
                            srcLang="fr"
                            label="french_captions"
                        />
                    </video>
                    <img src={youtubeLittleIcon} alt="PlayButton" className={classes.playButton} />
                </>
            );
        }
        if (content.rewardPhoto !== undefined && typeof content.rewardPhoto === 'string') {
            return (
                // <video className={classes.videoMedia} controls preload="metadata">
                //     <track src="your video" kind="captions" srcLang="fr" label="french_captions" />
                //     <source
                //         src={`${getImageKitUrlFrom(content.rewardPhoto)}#t=0.01`}
                //         id="your video"
                //         type="video/mp4"
                //     />
                // </video>
                <>
                    <video
                        className={classes.videoMedia}
                        style={{ borderRadius: '9px' }}
                        preload="metadata"
                        controls={false}>
                        <track
                            src="your video"
                            kind="captions"
                            srcLang="fr"
                            label="french_captions"
                        />
                        <source
                            src={
                                content.rewardPhoto && typeof content.rewardPhoto === 'string'
                                    ? `${getImageKitUrlFrom(content.rewardPhoto)}#t=0.01`
                                    : ''
                            }
                            id="your video"
                            type="video/mp4"
                        />
                    </video>
                    <img
                        src={LittlePlayButtonVideo}
                        alt="PlayButton"
                        className={classes.playButton}
                    />
                </>
            );
        }
        return null;
    };

    const accordion = (typo: string, bodyText: string | undefined): JSX.Element => {
        return (
            <Accordion className={classes.accordionHead}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={typo} id={typo}>
                    <Typography variant="h5">{t(typo)}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionBody}>
                    <Typography variant="body2" color="#004680" sx={{ fontWeight: 530 }}>
                        {bodyText}
                    </Typography>
                </AccordionDetails>
            </Accordion>
        );
    };

    const videoRewardToShow = (): JSX.Element | null => {
        if (content.rewardPhoto && content.rewardVideo) {
            return (
                <div>
                    <Typography
                        sx={{
                            textAlign: 'left',
                            paddingLeft: 'calc(2% + 16px)',
                            marginBottom: '3%'
                        }}
                        variant="h5">
                        {t('videoGift')}
                    </Typography>
                    <Grid
                        container
                        sx={{ width: '100%', margin: 'auto' }}
                        className={classes.secondStepContainer}>
                        <Grid
                            item
                            xs={6}
                            sm={6}
                            sx={{
                                padding: '1%'
                            }}
                            key={1}>
                            <Card
                                className={classes.selectedCard}
                                sx={{ padding: '7px', border: '3px solid #6B00AD !important' }}>
                                <div
                                    className={classes.videoContent}
                                    style={{ position: 'relative' }}>
                                    <video
                                        className={classes.videoMedia}
                                        style={{ borderRadius: '9px' }}
                                        preload="metadata"
                                        controls={false}>
                                        <track
                                            src="your video"
                                            kind="captions"
                                            srcLang="fr"
                                            label="french_captions"
                                        />
                                        <source
                                            src={
                                                content.rewardPhoto &&
                                                typeof content.rewardPhoto === 'string'
                                                    ? `${getImageKitUrlFrom(
                                                          content.rewardPhoto
                                                      )}#t=0.01`
                                                    : ''
                                            }
                                            id="your video"
                                            type="video/mp4"
                                        />
                                    </video>
                                    <img
                                        src={LittlePlayButtonVideo}
                                        alt="PlayButton"
                                        className={classes.playButton}
                                    />
                                </div>
                                <Typography
                                    sx={{
                                        color: '#374653',
                                        fontWeight: '500',
                                        fontSize: '1.2rem'
                                    }}>
                                    {videoTitle.video}
                                </Typography>
                            </Card>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            sm={6}
                            sx={{
                                padding: '1%'
                            }}
                            key={2}>
                            <Card
                                className={classes.selectedCard}
                                sx={{ padding: '7px', border: '3px solid #6B00AD !important' }}>
                                <div
                                    className={classes.videoContent}
                                    style={{ position: 'relative' }}>
                                    {/* <CardMedia
                                        component="iframe"
                                        src={
                                            content.rewardVideo &&
                                            typeof content.rewardVideo === 'string'
                                                ? formatVideoUrl(content.rewardVideo)
                                                : ''
                                        }
                                        controls={false}
                                        className={classes.videoMedia}
                                    /> */}
                                    <video
                                        className={classes.videoMedia}
                                        style={{ borderRadius: '9px' }}
                                        preload="metadata"
                                        poster={
                                            content.rewardVideo &&
                                            typeof content.rewardVideo === 'string'
                                                ? `https://img.youtube.com/vi/${content.rewardVideo.match(
                                                      /[\w\\-]{11,}/
                                                  )}/0.jpg`
                                                : ''
                                        }
                                        controls={false}>
                                        <track
                                            src="your video"
                                            kind="captions"
                                            srcLang="fr"
                                            label="french_captions"
                                        />
                                    </video>
                                    <img
                                        src={youtubeLittleIcon}
                                        alt="PlayButton"
                                        className={classes.playButton}
                                    />
                                </div>
                                <Typography
                                    sx={{
                                        color: '#374653',
                                        fontWeight: '500',
                                        fontSize: '1.2rem'
                                    }}>
                                    {videoTitle.videoYt}
                                </Typography>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            );
        }
        if (content.rewardPhoto || content.rewardVideo) {
            return (
                <div>
                    <Typography
                        sx={{
                            textAlign: 'left',
                            paddingLeft: 'calc(2% + 16px)',
                            marginBottom: '3%'
                        }}
                        variant="h5">
                        {t('videoGift')}
                    </Typography>
                    <Grid
                        container
                        sx={{ width: '100%', margin: 'auto' }}
                        className={classes.secondStepContainer}>
                        <Grid item xs={12} sm={12} key={1}>
                            <Card
                                className={classes.selectedCard}
                                sx={{ padding: '7px', border: '3px solid #6B00AD !important' }}>
                                <div
                                    className={classes.videoContent}
                                    style={{ position: 'relative' }}>
                                    {showGoodVideo()}
                                </div>
                                {content.rewardPhoto && (
                                    <Typography
                                        sx={{
                                            color: '#374653',
                                            fontWeight: '500',
                                            fontSize: '1.2rem'
                                        }}>
                                        {videoTitle.video}
                                    </Typography>
                                )}
                                {content.rewardVideo && (
                                    <Typography
                                        sx={{
                                            color: '#374653',
                                            fontWeight: '500',
                                            fontSize: '1.2rem'
                                        }}>
                                        {videoTitle.videoYt}
                                    </Typography>
                                )}
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            );
        }
        return null;
    };

    const summaryScreen = (
        <div>
            <Box sx={{ boxShadow: 'inset 0px -1px 0px #E3E6E8' }}>
                <Header
                    setOpenModal={setOpenModal}
                    type={content.exerciseType}
                    activeStep={activeStep}
                    user={user}
                />
            </Box>
            <Box
                sx={{
                    backgroundColor: '#F8F9F9',
                    boxShadow: 'inset 0px -1px 0px rgba(172, 188, 199, 0.4)'
                }}>
                <TitleAndIntro
                    type={content.exerciseType}
                    activeStep={activeStep}
                    user={user}
                    icon={giftIcon}
                    body={t('sendCreation')}
                    endBody={
                        user && user.role === Roles.FAMILY
                            ? t('summaryOfContentFamily')
                            : t('summaryOfContentTherapist')
                    }
                />
            </Box>
            <div className={classes.secondStepContainer} style={{ marginBottom: '20px' }}>
                <Box style={{ width: '95%', margin: '2% 2%', alignItems: 'center' }}>
                    {accordion('messageOfEncouragement', content.encouragementMessage)}
                    <Box>
                        <Typography
                            variant="h4"
                            sx={{
                                textAlign: isSmallWidth ? 'center' : 'left',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                            {
                                // eslint-disable-next-line no-nested-ternary
                                content.exerciseType === ExerciseType.MEMORY
                                    ? `${t('exercise')} "${t(`souven'in`)}"`
                                    : // eslint-disable-next-line no-nested-ternary
                                    content.exerciseType === ExerciseType.MEMORIN
                                    ? `${t('exercise')} "${t(`memor'in`)}"`
                                    : content.exerciseType === ExerciseType.ORDER
                                    ? `${t('exercise')} "${t(`reorder`)}`
                                    : `${t('exercise')} "${t(`puzzl'in`)}"`
                            }
                            {content.exerciseType === ExerciseType.MEMORY && (
                                <ImageInfoContainer>
                                    <div style={{ marginTop: '8px', marginLeft: '3px' }}>
                                        <div
                                            style={{
                                                width: '20px',
                                                height: '20px',
                                                marginTop: '-15px',
                                                marginLeft: '0px'
                                            }}>
                                            <BrainSvgIcon />
                                        </div>
                                    </div>
                                </ImageInfoContainer>
                            )}
                            {content.exerciseType === ExerciseType.MEMORIN && (
                                <ImageInfoContainer>
                                    <div style={{ marginTop: '4px', marginLeft: '3px' }}>
                                        <img
                                            src={MemorinSvgIcon}
                                            alt="Memorin"
                                            style={{
                                                width: '20px',
                                                height: '20px',
                                                marginTop: '-3px',
                                                marginLeft: '4.5px'
                                            }}
                                        />
                                    </div>
                                </ImageInfoContainer>
                            )}
                            {content.exerciseType === ExerciseType.PUZZLE && (
                                <ImageInfoContainer>
                                    <div style={{ marginTop: '8px', marginLeft: '3px' }}>
                                        <div
                                            style={{
                                                width: '20px',
                                                height: '20px',
                                                marginTop: '-16px',
                                                marginLeft: '3.5px',
                                                color: '#FF0089'
                                            }}>
                                            <ExtensionIcon />
                                        </div>
                                    </div>
                                </ImageInfoContainer>
                            )}
                            {content.exerciseType === ExerciseType.ORDER && (
                                <ImageInfoContainer>
                                    <div style={{ marginTop: '8px', marginLeft: '3px' }}>
                                        <div
                                            style={{
                                                width: '20px',
                                                height: '20px',
                                                marginTop: '-16px',
                                                marginLeft: '3.5px',
                                                color: '#FF0089'
                                            }}>
                                            <TocIcon />
                                        </div>
                                    </div>
                                </ImageInfoContainer>
                            )}
                        </Typography>
                        {content.exerciseType === ExerciseType.MEMORY ||
                        content.exerciseType === ExerciseType.MEMORIN ? (
                            <Grid
                                container
                                sx={{
                                    width: '100% !important',
                                    margin: 'auto',
                                    paddingTop: '15px'
                                }}
                                className={classes.secondStepContainer}>
                                {getExercises()}
                            </Grid>
                        ) : (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    position: 'relative',
                                    width: '100%',
                                    height: '100%'
                                }}>
                                <Box
                                    className={`${classes.onlyPuzzlinImage} ${
                                        imageLoaded ? classes.divVideoDisplay : null
                                    }`}
                                    style={{
                                        visibility: 'visible',
                                        backgroundColor: '#FF008980',
                                        position: 'absolute',
                                        opacity: imageLoaded ? 0 : 1,
                                        zIndex: 1000,
                                        height: '400px',
                                        width: '400px'
                                    }}
                                />
                                <img
                                    style={{
                                        marginTop: '15px',
                                        visibility: imageLoaded ? 'visible' : 'hidden'
                                    }}
                                    src={picture ?? ''}
                                    onLoad={(): void => setImageLoaded(true)}
                                    alt="SampleImg"
                                    className={classes.onlyPuzzlinImage}
                                />
                                {content.exerciseType === ExerciseType.PUZZLE && (
                                    <img
                                        style={{
                                            marginTop: '15px',
                                            position: 'absolute',
                                            visibility: imageLoaded ? 'visible' : 'hidden'
                                        }}
                                        src={picture ? puzzleWatermark : ''}
                                        alt="SampleImg"
                                        className={classes.onlyPuzzlinImage}
                                    />
                                )}
                            </Box>
                        )}
                    </Box>
                    {accordion('congratulationMessage', content.congratulationsMessage)}
                </Box>
                {videoRewardToShow()}
            </div>
            <ButtonsCreateAndEdit
                lastStep
                disabled={disabled}
                revertButtonIsSmallWidth
                nextText={
                    // eslint-disable-next-line no-nested-ternary
                    user?.role === Roles.FAMILY
                        ? t('sendToMyLovedOne')
                        : user?.role === Roles.THERAPIST
                        ? t('sendToAllPatients')
                        : t('sendToAllEstablishments')
                }
                nextClick={(): Promise<void> => handleSaveExercise()}
                previousClick={(): void => {
                    setStep(activeStep - 1);
                    currentStep(activeStep - 1);
                }}
            />
        </div>
    );

    const modalBox = (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '8%'
            }}>
            <img src={thankyouIcon} alt="ThankYouIcon" style={{ width: '30px', height: '30px' }} />
            <Typography variant="h3" color="#374653">
                {user?.role === Roles.ADMIN || user?.role === Roles.THERAPIST
                    ? `${t('thankYouForContribution')} ${user.firstName} !`
                    : t('thankyouForYourContribution')}
            </Typography>
            {user?.role === Roles.ADMIN || user?.role === Roles.THERAPIST ? (
                <div>
                    <Typography variant="body1" color="#374653">
                        {t('thanksForPersonalization')}
                    </Typography>

                    <Typography variant="body1" color="#374653">
                        {t('thanksForPersonalizationCont.')}
                    </Typography>
                </div>
            ) : (
                <Typography variant="body2" color="#374653" sx={{ fontWeight: 500 }}>
                    {t('thanksForPersonalizationForLovedOnes')}
                </Typography>
            )}
            <NextStepButton
                fullWidth
                variant="outlined"
                size="large"
                style={{
                    margin: '0%',
                    marginTop: '4%',
                    display: 'block',
                    alignItems: 'center'
                }}
                onClick={(): void => history.push('/')}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    {user?.role === Roles.ADMIN || user?.role === Roles.THERAPIST
                        ? t('backToHomePage')
                        : t('returnToHomePage')}
                </Typography>
            </NextStepButton>
        </div>
    );

    return (
        <div>
            {openEndModal && <ModalContainer openModal={openEndModal} renderBody={modalBox} />}
            {activeStep === 5 && summaryScreen}
            <ExitCustomizationModal openModal={openModal} setOpenModal={setOpenModal} />
        </div>
    );
}
