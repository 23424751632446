import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import { AppBar, Avatar, IconButton, Switch, Toolbar, Typography } from '@mui/material';
import { makeStyles, createStyles, withStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import { useLongPress } from 'use-long-press';
import { useTranslation } from 'react-i18next';
import logo from '../assets/logo.png';
import backButton from '../assets/backButton.png';
import { UserContext } from '../providers/UserProvider';
import { getImageKitUrlFrom } from '../utilities/utils';
import { Roles } from '../models/Roles';
import { UserDataProp } from '../models/userDataProp';

const useStyles = makeStyles(() =>
    createStyles({
        profileImage: {
            height: '50px',
            width: '50px',
            float: 'right'
        },
        currentUserInitials: {
            display: 'flex',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            margin: '0'
        }
    })
);

const Logo = styled.img`
    height: 27px;
`;

const LogoBack = styled.img`
    height: 40px;
    background-color: #fbfbfb;
    border: 1px solid #e3e6e8;
    padding: 12px;
    border-radius: 5px;
    cursor: pointer;
`;

const StimulinAppBar = styled(AppBar)({
    background: '#fff',
    height: '78px'
});

const AppToolBar = styled(Toolbar)({
    justifyContent: 'space-between',
    height: '78px'
});

const DefaultLogo = styled.div`
    border-radius: 50%;
    background: #efe7d7;
    height: 27px;
`;

interface HeaderProps {
    canGoBack?: boolean;
    hasIconImage?: boolean;
    showAvatar?: boolean;
    setActive?: Function;
    active?: boolean;
    goBackFunc?: Function;
}

export function Header({
    canGoBack,
    hasIconImage,
    showAvatar = true,
    setActive,
    active,
    goBackFunc
}: HeaderProps): JSX.Element {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [initials, setInitials] = useState<string>('');
    const [clickCount, setClickCount] = useState<number>(1);

    // Put Screen to the top
    useEffect(() => {
        const topOfPage = document.getElementById('top');
        if (topOfPage) {
            topOfPage.scrollIntoView();
        }
    }, []);

    useEffect(() => {
        if (user && user.firstName && user.lastName) {
            setInitials(
                user.firstName.charAt(0).toUpperCase() + user.lastName.charAt(0).toUpperCase()
            );
        }
    }, [user]);

    const handleProfileClick = (): void => {
        // setClickCount(clickCount + 1);
        // if (user?.role === Roles.PATIENT && clickCount >= 2) {
        //     history.push('/profile');
        // } else if (user?.role !== Roles.PATIENT) {
        //     history.push('/profile');
        // }
        history.push('/profile');
    };

    const bindLongPressProfile = useLongPress(
        () => {
            handleProfileClick();
        },
        { threshold: 1000 }
    );

    const iconButtonElement = (): JSX.Element => {
        return (
            <IconButton
                sx={{ marginRight: setActive ? '10px' : '' }}
                size="large"
                edge="start"
                color="secondary"
                aria-label="menu"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...bindLongPressProfile()}
                onClick={(): void => handleProfileClick()}>
                {showAvatar && user && !user.profilePhoto && (
                    <DefaultLogo className={classes.profileImage}>
                        <div style={{ height: '5vmin' }}>
                            <Typography
                                variant="subtitle1"
                                sx={{ fontWeight: 'bold', color: '#374653' }}
                                className={classes.currentUserInitials}>
                                {initials}
                            </Typography>
                        </div>
                    </DefaultLogo>
                )}
                {showAvatar && user && user.profilePhoto && typeof user.profilePhoto === 'string' && (
                    <Avatar
                        alt="userIcon"
                        src={getImageKitUrlFrom(user.profilePhoto)}
                        sx={{
                            objectFit: 'cover',
                            width: '54px',
                            height: '54px',
                            alignSelf: 'center',
                            justifySelf: 'center',
                            visibility: hasIconImage ? 'visible' : 'hidden'
                        }}
                    />
                )}
            </IconButton>
        );
    };

    const CustomSwitch = withStyles({
        switchBase: {
            color: '#005398',
            '&$checked': {
                color: '#6B00AD'
            },
            '&$checked + $track': {
                backgroundColor: '#6B00AD'
            }
        },
        checked: {},
        track: {
            backgroundColor: '#005398'
        }
    })(Switch);

    const bindLongPressChangeActivity = useLongPress(
        (event, value) => {
            if (setActive) {
                switch (value.context) {
                    case 'true':
                        setActive(true);
                        break;
                    default:
                        setActive(false);
                        break;
                }
            }
        },
        { threshold: 1000 }
    );

    const bindLongPressGoBack = useLongPress(
        () => {
            history.goBack();
        },
        { threshold: 1000 }
    );

    return (
        <Box id="top">
            <StimulinAppBar position="static">
                <AppToolBar>
                    {canGoBack && (
                        <IconButton
                            size="large"
                            edge="start"
                            color="secondary"
                            aria-label="menu"
                            sx={{
                                visibility: canGoBack ? 'visible' : 'hidden',
                                marginRight: setActive ? '10px' : ''
                            }}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...bindLongPressGoBack()}
                            onClick={(): void => {
                                if (goBackFunc) goBackFunc();
                                else history.goBack();
                            }}>
                            <LogoBack src={backButton} alt="BackButton" />
                        </IconButton>
                    )}
                    {!canGoBack && !setActive && <div />}
                    {setActive && (
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography
                                variant="subtitle2"
                                sx={{
                                    alignSelf: 'center',
                                    width: '70px',
                                    textAlign: 'right',
                                    fontWeight: active ? '400' : 'bold',
                                    fontSize: active ? '0.75rem !important' : '0.875px',
                                    '&:hover': {
                                        cursor: 'pointer'
                                    }
                                }}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...bindLongPressChangeActivity('false')}
                                onClick={(): void => setActive(false)}
                                color={active ? '#84919E' : '#005398'}>
                                {/* {active ? t('exerciseActivate') : t('consultationActivate')} */}
                                {t('Activity')}
                            </Typography>
                            <CustomSwitch
                                checked={active}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...bindLongPressChangeActivity(active ? 'false' : 'true')}
                                onChange={(): void => (setActive ? setActive(!active) : '')}
                                name="loading"
                                sx={{
                                    visibility: setActive ? 'visible' : 'hidden',
                                    marginRight: '-0.4em',
                                    // marginLeft: '-em',
                                    alignSelf: 'center'
                                }}
                            />
                            <Typography
                                sx={{
                                    alignSelf: 'center',
                                    width: '100px',
                                    fontWeight: active ? 'bold !important' : '400',
                                    fontSize: active ? '0.875rem' : '0.75rem !important',
                                    marginRight: '0.45rem',
                                    '&:hover': {
                                        cursor: 'pointer'
                                    }
                                }}
                                variant="subtitle2"
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...bindLongPressChangeActivity('true')}
                                onClick={(): void => setActive(true)}
                                color={active ? '#6B00AD' : '#84919E'}>
                                {t('mediatheque')}
                            </Typography>
                        </div>
                    )}
                    <Logo
                        src={logo}
                        alt="logo"
                        // style={{ marginLeft: setActive ? '-20px' : '15px' }}
                        style={{
                            position: 'absolute',
                            marginLeft: 'calc(50% - 75px)'
                            // transform: 'translate(50%, 0px)'
                        }}
                    />
                    {setActive ? iconButtonElement() : <div>{iconButtonElement()}</div>}
                </AppToolBar>
            </StimulinAppBar>
        </Box>
    );
}
