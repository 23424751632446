/* eslint-disable import/no-cycle */
import { Avatar, Box, Button, Card, CardContent, Typography, CardActionArea } from '@mui/material';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getImageKitUrlFrom, getThemeIcon } from '../utilities/utils';
import closeIcon from '../assets/closeIcon.png';
import warningDelete from '../assets/warningDelete.png';
import { DeactiveModall } from './ContainerModal';
import { UserContext } from '../providers/UserProvider';
import { deleteExerciseItemCard, getUser } from '../services/cloudFirestore';
import { UserDataProp } from '../models/userDataProp';
import { ExerciseItem } from '../models/ExerciseItem';
import { Roles } from '../models/Roles';

const useStyles = makeStyles(() => ({
    exerciseName: {
        display: 'grid',
        overflow: 'hidden'
    },
    cardContent: {
        display: 'grid',
        overflow: 'hidden',
        padding: '0 5px 10px !important',
        minHeight: '70px'
    },
    buttonCard: {
        // '@media (max-width: 598px)': {
        //     minHeight: '30vh'
        // },
        '@media (min-width: 598px)': {
            minHeight: '20vh'
        }
    },
    underline: {
        textDecoration: 'underline'
    },
    warningImage: {
        display: 'flex',
        width: '200px',
        margin: 'auto'
    },
    profileImage: {
        float: 'right'
    },
    currentUserInitials: {
        display: 'flex',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        margin: '0'
    },
    media: {
        marginTop: '10px',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: '6px'
    },
    // imageDisplay: {
    //     animationName: `$fade-in`,
    //     animationDuration: '2s'
    // },
    divImageDisplay: {
        animationName: `$fade-out`,
        animationDuration: '2s'
    },
    // '@keyframes fade-in': {
    //     '0%': {
    //         opacity: 0
    //     },
    //     '100%': {
    //         opacity: 1
    //     }
    // },
    '@keyframes fade-out': {
        from: {
            opacity: 1
        },
        to: {
            opacity: 0
        }
    },
    fullDisplay: {
        animation: `$full-slide 1s ease-in alternate`
    },
    '@keyframes full-slide': {
        '0%': {
            width: window.innerWidth < 520 ? 'calc(100vw / 2 - 40px)' : '220px',
            transform: 'scaleX(1)',
            transformOrigin: '100% 50%'
        },
        '100%': {
            width: window.innerWidth < 520 ? 'calc(100vw / 2 - 40px)' : '220px',
            transform: 'scaleX(0)',
            transformOrigin: '100% 50%'
        }
    }
}));

const AuthorInfoContainer = styled.div`
    position: absolute;
    z-index: 2;
    right: 0px;
    border-radius: 50%;
    color: rgba(251, 251, 251, 1);
    max-width: 150px;
    align-items: center;
    display: flex;
`;

const ImageInfoContainer = styled.div`
    position: absolute;
    z-index: 2;
    height: 30px;
    margin-top: 180px;
    background-color: rgba(55, 70, 83, 1);
    border-radius: 0px 3px;
    color: rgba(251, 251, 251, 1);
    max-width: 150px;
    align-items: center;
    display: flex;
    margin-left: 10px;
`;

const ControlButtons = styled(Button)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px solid #ACBCC7',
    borderRadius: '3px',
    margin: '5% 0%'
}));

const ModalButtons = styled(Button)(() => ({
    '@media (max-width: 598px)': {
        width: '100%',
        margin: '2% 0%'
    },
    '@media (min-width: 598px)': {
        width: '30%'
    }
}));

const ButtonContainer = styled(Box)({
    '@media (max-width: 598px)': {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        marginBottom: '0 !important'
    },
    '@media (min-width: 598px)': {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row'
    }
});

const ImageBox = styled(Box)({
    position: 'relative'
});

const ImageContainer = styled('img')({
    height: '210px',
    width: '100%',
    objectFit: 'cover'
});

const CloseLogoContainer = styled.div`
    margin-top: 15px;
`;

const LogoClose = styled.img`
    height: 40px;
    background-color: #fbfbfb;
    border: 1px solid #e3e6e8;
    padding: 6px;
    border-radius: 5px;
    cursor: pointer;
`;

const DefaultLogo = styled.div`
    border-radius: 50%;
    background: #efe7d7;
    height: 27px;
`;
interface CardDataProps {
    onCardClicked: Function;
    onLeaveCard: Function;
    item: ExerciseItem;
    classReceived?: string;
    isSelected?: boolean;
    setModifyThumbnails?: Function;
    activePP?: boolean;
    bgColor?: string;
}

export function ThumbnailsCards({
    onCardClicked,
    onLeaveCard,
    item,
    classReceived,
    isSelected,
    setModifyThumbnails,
    activePP,
    bgColor
}: CardDataProps): JSX.Element {
    const classes = useStyles();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [clickedCard, setClickedCard] = useState<string | undefined>('');
    const [profilePicturePatient, setProfilePicturePatient] = useState<string>('');
    const [initials, setInitials] = useState<string>('');
    const [renderDeleteExerciseModal, setDeleteModal] = useState(false);
    const [checkAvatar, setCheckAvatar] = useState(false);
    const { t } = useTranslation();
    const width = window.innerWidth;
    const [isImgLoaded, setIsImgLoaded] = useState<boolean>(false);

    const handleDeleteThumbnails = async (): Promise<void> => {
        if (clickedCard) {
            await deleteExerciseItemCard(clickedCard);
            setDeleteModal(false);
            window.location.reload();
        }
    };

    const click = (): void => {
        onLeaveCard();
        setClickedCard('');
    };

    useEffect(() => {
        const getPatientAssign = async (): Promise<void> => {
            if (item && item.authorId) {
                const authorItem = await getUser(item.authorId);
                if (authorItem) {
                    setCheckAvatar(true);
                    if (authorItem?.profilePhoto && typeof authorItem.profilePhoto === 'string') {
                        setProfilePicturePatient(authorItem.profilePhoto);
                    } else if (authorItem.firstName && authorItem.lastName) {
                        setInitials(
                            authorItem?.firstName.charAt(0).toUpperCase() +
                                authorItem?.lastName.charAt(0).toUpperCase()
                        );
                    }
                }
            }
        };
        getPatientAssign();
    }, []);

    const iconButtonElement = (): JSX.Element => {
        return (
            <div style={{ margin: 'auto' }}>
                {profilePicturePatient === '' && (
                    <DefaultLogo
                        className={classes.profileImage}
                        style={{
                            height: width < 520 ? '35px' : '53px',
                            width: width < 520 ? '35px' : '53px'
                        }}>
                        <div style={{ height: '5vmin' }}>
                            <Typography
                                variant="subtitle1"
                                sx={{ fontWeight: 'bold', color: '#374653' }}
                                className={classes.currentUserInitials}>
                                {initials}
                            </Typography>
                        </div>
                    </DefaultLogo>
                )}
                {user && profilePicturePatient !== '' && (
                    <Avatar
                        sx={{ backgroundColor: 'white' }}
                        style={{
                            height: width < 520 ? '35px' : '53px',
                            width: width < 520 ? '35px' : '53px'
                        }}
                        alt="userIcon"
                        src={getImageKitUrlFrom(profilePicturePatient)}
                        className={classes.profileImage}
                    />
                )}
            </div>
        );
    };

    const ExerciseTitleCardControls = (
        <div
            style={{
                position: 'absolute',
                backgroundColor: '#FFFFFFD9',
                zIndex: '100',
                // border: '1px solid #E3E6E8',
                // marginBottom: '16px',
                height: width < 520 ? 'calc(100vw / 2 + 20px - 2px)' : 'calc(290px - 2px)',
                width: width < 520 ? 'calc(100vw / 2 - 20px - 2px)' : 'calc(220px - 2px)',
                borderRadius: '9px'
            }}>
            <CloseLogoContainer onClick={(): void => click()}>
                <LogoClose src={closeIcon} alt="CloseButton" style={{ borderRadius: '50%' }} />
            </CloseLogoContainer>
            <Box
                className={classes.buttonCard}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: width < 520 ? '0px 3px' : '45px 15px'
                }}>
                <ControlButtons
                    variant="outlined"
                    sx={{
                        backgroundColor: '#FFFFFF',
                        '&:hover': {
                            backgroundColor: '#FFFFFF'
                        }
                    }}
                    onClick={(): void => {
                        if (setModifyThumbnails) {
                            setModifyThumbnails(item);
                        }
                    }}>
                    <Typography variant="subtitle1" color="#374653">
                        {t('toModify')}
                    </Typography>
                    <EditIcon sx={{ height: '30px', width: '30px', color: '#374653' }} />
                </ControlButtons>
                <ControlButtons
                    variant="outlined"
                    sx={{
                        backgroundColor: '#F4E0EB',
                        border: 'none !important',
                        '&:hover': {
                            border: 'none',
                            backgroundColor: '#F4E0EB'
                        }
                    }}
                    onClick={(): void => {
                        setDeleteModal(true);
                    }}>
                    <Typography variant="subtitle1" color="#D00070">
                        {t('toDelete')}
                    </Typography>
                    <DeleteIcon sx={{ height: '30px', width: '30px', color: '#D00070' }} />
                </ControlButtons>
            </Box>
        </div>
    );

    const ExerciseTitleCardData = (
        <CardActionArea
            onClick={(): void => {
                if (setModifyThumbnails) {
                    onCardClicked(item.id);
                    setClickedCard(item.id);
                }
            }}>
            {activePP &&
                item.authorId &&
                item.authorId !== '' &&
                item.authorType !== Roles.ADMIN &&
                checkAvatar && (
                    <AuthorInfoContainer
                        style={{
                            backgroundColor:
                                item.authorType === Roles.FAMILY ? '#D00070' : '#00FFFF',
                            marginRight: width < 520 ? '12px' : '15px',
                            marginTop: width < 520 ? '12px' : '15px',
                            height: width < 520 ? '40px' : '60px',
                            width: width < 520 ? '40px' : '60px'
                        }}>
                        {iconButtonElement()}
                    </AuthorInfoContainer>
                )}
            {width >= 520 && user?.role !== Roles.PATIENT && (
                <ImageInfoContainer>
                    <div style={{ marginTop: '6px', marginLeft: '5px' }}>
                        <img
                            src={item.theme ? getThemeIcon(item.theme) : ''}
                            alt="Exercise"
                            style={{
                                width: '20px',
                                height: '20px'
                            }}
                        />
                    </div>
                    <div style={{ marginBottom: '5px', marginLeft: '2px' }}>
                        <Typography
                            variant="caption"
                            sx={{ margin: '0px 4px', fontSize: { xs: '10px' }, fontWeight: 500 }}>
                            {item.theme}
                        </Typography>
                    </div>
                </ImageInfoContainer>
            )}
            <ImageBox>
                {typeof item.exerciseImage === 'string' && (
                    <>
                        <ImageContainer
                            className={`${classes.media}`}
                            style={{
                                height: width < 520 ? 'calc(100vw / 2 - 40px)' : '200px',
                                width: width < 520 ? 'calc(100vw / 2 - 40px)' : '200px',
                                // eslint-disable-next-line no-nested-ternary
                                visibility: !bgColor
                                    ? 'visible'
                                    : isImgLoaded
                                    ? 'visible'
                                    : 'hidden'
                            }}
                            src={item.exerciseImage}
                            onLoad={(): void => setIsImgLoaded(true)}
                            alt="SampleImg"
                        />
                        {bgColor && (
                            <Box
                                className={`${classes.media} ${
                                    isImgLoaded ? classes.divImageDisplay : null
                                }`}
                                style={{
                                    height: width < 520 ? 'calc(100vw / 2 - 40px)' : '200px',
                                    width: width < 520 ? 'calc(100vw / 2 - 40px)' : '200px',
                                    backgroundColor: bgColor,
                                    visibility: 'visible',
                                    opacity: isImgLoaded ? 0 : 1,
                                    position: 'absolute',
                                    top: 0,
                                    left: '10px',
                                    zIndex: 50
                                }}
                            />
                        )}
                    </>
                )}
            </ImageBox>
            <CardContent className={classes.cardContent}>
                <Typography
                    variant="h4"
                    className={classes.exerciseName}
                    style={{ height: width < 520 ? '80px' : '69px' }}
                    color="#6B00AD"
                    sx={{
                        fontWeight: 500,
                        fontFamily: user?.role === Roles.PATIENT ? 'Luciole-Regular' : 'Inter',
                        fontSize: width < 520 ? '0.95rem !important' : '1.25rem !important',
                        lineHeight: user?.role === Roles.PATIENT ? '1.4rem' : '1.4rem',
                        paddingTop:
                            user?.role === Roles.PATIENT || width >= 520
                                ? '13px !important'
                                : '0px !important',
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2
                    }}>
                    {item.answer}
                </Typography>
            </CardContent>
        </CardActionArea>
    );

    const ExerciseTitleCard = (
        <Card
            style={{
                flexGrow: 0,
                // marginRight: '16px',
                // marginBottom: '16px',
                height: width < 520 ? 'calc(100vw / 2 + 20px)' : '290px',
                width: width < 520 ? 'calc(100vw / 2 - 20px)' : '220px',
                borderRadius: '9px',
                display: 'flex',
                position: 'relative'
            }}
            className={classReceived}>
            {/* {bgColor && (
                <Box
                    className={`${classes.fullDisplay}`}
                    style={{
                        height: width < 520 ? 'calc(100vw / 2)' : '270px',
                        borderRadius: '9px',
                        backgroundColor: bgColor,
                        visibility: 'visible',
                        position: 'absolute',
                        top: '10px',
                        left: '10px',
                        zIndex: 50
                    }}
                />
            )} */}
            {ExerciseTitleCardData}
            {isSelected && ExerciseTitleCardControls}
        </Card>
    );

    const DeleteModalContent = (
        <div>
            <img className={classes.warningImage} src={warningDelete} alt="warning" />
            <Typography variant="h3" color="#374653" sx={{ textAlign: 'center', marginTop: '3%' }}>
                {t('wantToDeletePhoto')}
            </Typography>
            <Typography
                variant="subtitle1"
                color="#374653"
                mt="11px"
                sx={{ textAlign: 'center', fontWeight: 500, color: '#D00070' }}>
                {`${t('attention')}.`}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant="subtitle1" mb={2} style={{ textAlign: 'center' }}>
                    <span>{t('ifYes')} </span>
                    <span className={classes.underline}>{t('allExercises')}</span>
                    <span> {t('inUse')} </span>
                    <span className={classes.underline}>{t('deleted')}</span>
                </Typography>
            </Box>
            <ButtonContainer mt="5px" mb={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                <ModalButtons
                    variant="outlined"
                    sx={{
                        border: '1px solid #8B004B',
                        boxShadow: '0px 0px 0px 2px rgba(208, 0, 112, 0.4)',
                        borderRadius: '12px',
                        marginRight: '5%',
                        width: '30%'
                    }}
                    onClick={(): Promise<void> => handleDeleteThumbnails()}>
                    <Typography variant="subtitle1" color="#D00070">
                        {t('yesSure')}
                    </Typography>
                </ModalButtons>
                <ModalButtons
                    variant="outlined"
                    sx={{
                        border: '1px solid #3387CC',
                        boxShadow: '0px 0px 0px 2px rgba(59, 125, 191, 0.6)',
                        borderRadius: '12px',
                        width: '30%'
                    }}
                    onClick={(): void => setDeleteModal(false)}>
                    <Typography variant="subtitle1" color="#3387CC">
                        {t('noSure')}
                    </Typography>
                </ModalButtons>
            </ButtonContainer>
        </div>
    );

    return (
        <Box>
            {ExerciseTitleCard}
            <DeactiveModall
                isModelOpen={renderDeleteExerciseModal}
                setModelOpen={setDeleteModal}
                body={DeleteModalContent}
                circleIcon
                onCancel={(): void => {
                    setDeleteModal(false);
                }}
            />
        </Box>
    );
}
