import firestore, { Timestamp } from 'firebase/firestore';
import { FormData } from './FormData';
import { FormField } from './FormField';
import { Status } from './Status';
import { User } from './User';

const optionList: string[] = [
    "Directeur/trice d'établissement",
    '(Neuro) Psychologue',
    'Neurologue',
    'Médecin coordonnateur',
    'Gériatre',
    'Psychiatre',
    'Infirmier(e) / Cadre de santé',
    'Assistant(e) de soin en gérontologie / AMP',
    'Ergothérapeute',
    'Animateur/trice en gérontologie',
    'Orthophoniste',
    'Autre'
];

export class Therapist extends User implements FormData {
    mobile?: string | undefined;

    fonction?: string | undefined;

    establishmentCode?: string | undefined;

    establishmentName?: string | undefined;

    startDate?: Timestamp | undefined;

    endDate?: Timestamp | undefined;

    status?: Status | undefined;

    createdDate?: Timestamp | undefined;

    patientPassword?: string | undefined;

    cguValidate?: string | undefined;

    cguValidateDate?: Timestamp | undefined;

    constructor(
        id?: string,
        role?: 'PATIENT' | 'FAMILY' | 'THERAPIST' | 'ADMIN',
        emailId?: string,
        firstName?: string,
        lastName?: string,
        dob?: Timestamp,
        profilePhoto?: string | File,
        mobile?: string,
        fonction?: string,
        establishmentName?: string,
        establishmentCode?: string,
        startDate?: Timestamp,
        endDate?: Timestamp,
        status?: Status,
        createdDate?: Timestamp,
        patientPassword?: string,
        cguValidate?: string,
        cguValidateDate?: Timestamp
    ) {
        super({ id, role, emailId, firstName, lastName, dob, profilePhoto });
        this.id = id;
        this.role = role;
        this.emailId = emailId;
        this.firstName = firstName;
        this.lastName = lastName;
        this.dob = dob;
        this.profilePhoto = profilePhoto;
        this.mobile = mobile;
        this.fonction = fonction;
        this.establishmentName = establishmentName;
        this.establishmentCode = establishmentCode;
        this.startDate = startDate;
        this.endDate = endDate;
        this.status = status;
        this.createdDate = createdDate;
        this.patientPassword = patientPassword;
        this.cguValidate = cguValidate;
        this.cguValidateDate = cguValidateDate;
    }

    firstStepFormFields: FormField[] = [
        new FormField({ placeholder: 'therapistLastName', field: 'lastName' }),
        new FormField({ placeholder: 'mobile', field: 'mobile' }),
        new FormField({ placeholder: 'therapistFirstName', field: 'firstName' }),
        new FormField({ placeholder: 'emailPlaceholder', field: 'email' }),
        new FormField({ placeholder: 'dob', field: 'dob', type: 'date', required: false }),
        new FormField({
            placeholder: 'nameEstablishment',
            field: 'establishmentCode',
            type: 'select',
            dropdownOptions: 'establishment'
        }),
        new FormField({
            placeholder: 'fonction',
            field: 'fonction',
            type: 'select',
            options: optionList
        })
    ];

    secondStepFormFields: FormField[] = [
        new FormField({ placeholder: 'commonPassword', field: 'patientPassword' }),
        new FormField({ placeholder: 'startDate', field: 'startDate', type: 'date' }),
        new FormField({ placeholder: 'endDate', field: 'endDate', type: 'date' })
    ];
}

export const TherapistConverter: firestore.FirestoreDataConverter<Therapist> = {
    toFirestore(therapist) {
        return {
            role: therapist.role,
            emailId: therapist.emailId,
            firstName: therapist.firstName,
            lastName: therapist.lastName,
            dob: therapist.dob,
            profilePhoto: therapist.profilePhoto,
            mobile: therapist.mobile,
            fonction: therapist.fonction,
            establishmentName: therapist.establishmentName,
            establishmentCode: therapist.establishmentCode,
            startDate: therapist.startDate,
            endDate: therapist.endDate,
            status: therapist.status,
            patientPassword: therapist.patientPassword,
            cguValidate: therapist.cguValidate,
            cguValidateDate: therapist.cguValidateDate
        };
    },
    fromFirestore(snapshot, options): Therapist {
        const data = snapshot.data(options);
        return new Therapist(
            snapshot.id,
            data.role,
            data.emailId,
            data.firstName,
            data.lastName,
            data.dob,
            data.profilePhoto,
            data.mobile,
            data.fonction,
            data.establishmentName,
            data.establishmentCode,
            data.startDate,
            data.endDate,
            data.status,
            data.createdDate,
            data.patientPassword,
            data.cguValidate,
            data.cguValidateDate
        );
    }
};
