import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import {
    Box,
    Checkbox,
    FormControlLabel,
    IconButton,
    InputAdornment,
    Link,
    OutlinedInput
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import '../i18n/config';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from '../assets/lockIcon.png';
import { Header } from '../components/Header';
import { UserContext } from '../providers/UserProvider';
import { changePassword } from '../services/firebaseAuth';
import { passwordChanged } from '../services/cloudFirestore';
import { User } from '../models/User';
import { UserDataProp } from '../models/userDataProp';
import { validatePassword } from '../utilities/utils';

const theme = createTheme();

interface LoginState {
    password: string;
    showPassword: boolean;
}

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
            minHeight: '80vh',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '8px 0px 0px 0px',
            padding: '0px',
            '& input:valid + fieldset': {
                border: '1px solid #ACBCC7',
                borderRadius: '1px',
                padding: '18.5px 14px'
            },
            '& input:valid:focus + fieldset': {
                border: '1px solid #ACBCC7',
                padding: '18.5px 14px',
                borderRadius: '1px'
            },
            '& .MuiInput-underline:after': {
                border: '1px solid #ACBCC7',
                borderRadius: '1px'
            },
            '& .makeStyles-margin-279': {
                margin: '0px'
            },
            '& .MuiGrid-item': {
                padding: '0px',
                paddingLeft: '10px'
            },
            '& .makeStyles-root-344': {
                padding: '8px'
            }
        },
        paper: {
            margin: 'auto',
            boxShadow: 'none',
            '@media (max-width: 598px)': {
                width: '95%'
            },
            '@media (min-width: 598px)': {
                width: '80%'
            }
        },
        img: {
            margin: 'auto',
            display: 'block',
            maxWidth: '100%',
            maxHeight: '100%'
        },
        header: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '28px',
            lineHeight: '34px',
            letterSpacing: '-0.02em',
            color: '#374653',
            marginTop: '50px'
        },
        conditions: {
            paddingTop: '10px',
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '28px',
            margin: '32px 0px 32px 0px',
            color: '#1E2933'
        },
        loginBtnLayout: {
            [theme.breakpoints.up('sm')]: {
                position: 'relative',
                marginTop: '16px',
                bottom: 0,
                width: '100%',
                marginLeft: '0px'
            },
            left: 0,
            bottom: '0%',
            width: '100%',
            marginBottom: '50px'
        },
        loginBtn: {
            textTransform: 'none',
            fontWeight: 600,
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontSize: '20px',
            color: '#d00070'
        },
        input: {
            '&::placeholder': {
                fontFamily: 'Inter',
                color: '#c4c4c4'
            }
        }
    })
);

const Logo = styled.img`
    margin-right: 8px;
    width: 25px;
`;
const Heading = styled.h3`
    margin-top: 100px;
    margin-bottom: 32px;
`;
const LoginButton = styled.button`
    margin-top: 16px;
    background-color: white;
    border: 2px solid #d00070;
    border-radius: 10px;
    width: 100%;
    padding: 5px;
    text-transform: 'none';
`;

function ChangePassword(): JSX.Element {
    const [values, setValues] = useState({
        password: '',
        showPassword: false,
        prop: ''
    });
    const [error, setError] = useState(false);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [checked, setchecked] = useState<boolean>(false);
    const history = useHistory();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;

    const handleClickShowPassword = (): void => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    useEffect(() => {
        if (values.password && validatePassword(values.password).length > 3 && checked === true) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [values.password, checked]);

    const handleChange = (prop: keyof LoginState) => (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        setValues({ ...values, [prop]: event.target.value.trim() });
    };

    const handlePasswordChange = async (): Promise<void> => {
        if (values.password.trim().length !== 0 && user) {
            await changePassword(values.password, user.id);
            history.push('/home');
        }
    };

    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <div>
            <Header showAvatar={false} />
            <div className={classes.root}>
                <div className={classes.paper}>
                    <Heading className={classes.header}>
                        <Logo src={logo} alt="logo" />
                        {t('customizePassword')}
                    </Heading>
                    <Typography variant="body1" className={classes.conditions}>
                        <span>{t('changePasswordDisclaimer')} </span>
                    </Typography>
                    <OutlinedInput
                        id="password"
                        fullWidth
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password}
                        error={error}
                        placeholder={t('enterPersonalPassword')}
                        onChange={handleChange('password')}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end">
                                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <Box my={2} sx={{ display: 'flex' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                    color="success"
                                    checked={checked}
                                    onChange={(): void => setchecked(!checked)}
                                />
                            }
                            label={
                                <Typography variant="body1" mt={2} style={{ textAlign: 'left' }}>
                                    <span>{t('disclaimer')} </span>
                                    <span>
                                        <Link
                                            color="secondary"
                                            href="https://app.stimulin.fr/Cgu.pdf">
                                            {t('generalConditions')}
                                        </Link>
                                    </span>
                                    <span> {t('and')} </span>
                                    <span>
                                        <Link
                                            color="secondary"
                                            href="https://app.stimulin.fr/Confidential.pdf">
                                            {t('confidential')}
                                        </Link>
                                    </span>
                                    <span> {t('stimulinApp')}</span>
                                </Typography>
                            }
                        />
                    </Box>
                    <LoginButton className={classes.loginBtnLayout}>
                        <Button
                            disabled={disabled}
                            fullWidth
                            color="secondary"
                            className={classes.loginBtn}
                            onClick={(): Promise<void> => handlePasswordChange()}>
                            {t('confirmMyPassword')}
                        </Button>
                    </LoginButton>
                </div>
            </div>
        </div>
    );
}

export function ChangePasswordOnboarding(): JSX.Element {
    return (
        <ThemeProvider theme={theme}>
            <ChangePassword />
        </ThemeProvider>
    );
}
