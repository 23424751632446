import * as React from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import greenTickCircle from '../assets/greenTickCircle.png';

const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 440,
    bgcolor: 'background.paper',
    borderRadius: '24px',
    boxShadow: 24,
    p: 4
};

const RootModal = styled(Box)({
    '@media (max-width:320px)': {
        width: '130px'
    },
    '@media (min-width:320px)': {
        width: '315px',
        maxHeight: '555px',
        overflowY: 'scroll'
    },
    '@media (min-width:480px)': {
        width: '430px',
        maxHeight: '555px',
        overflowY: 'scroll'
    },
    '@media (min-width:600px)': {
        width: '590px'
    },
    '@media (min-width:1025px)': {
        width: '900px',
        overflow: 'auto'
    },
    '@media (min-width:1281px)': {
        width: '1100px'
    }
});

const Logo = styled.img`
    object-fit: inherit;
    width: 68px;
    height: 68px;
    margin: auto;
`;

interface CompletedModalProps {
    isModelOpen: boolean;
    setModelOpen: Function;
    onButtonClick: Function;
}

export function CompletedModal({
    isModelOpen,
    setModelOpen,
    onButtonClick
}: CompletedModalProps): JSX.Element {
    const { t } = useTranslation();
    const body = (
        <RootModal sx={style}>
            <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
                <Logo src={greenTickCircle} alt="greenTickCircle" />
                <Typography
                    variant="h3"
                    color="#1E2933"
                    sx={{
                        margin: '20px 0px',
                        textAlign: 'center'
                    }}>
                    {t('NewAccount')}
                </Typography>
                <Typography
                    variant="subtitle2"
                    color="#5C6670"
                    sx={{
                        marginBottom: '20px',
                        textAlign: 'center'
                    }}>
                    {t('Votre compte a bien été créé !')}
                </Typography>
                <Button
                    fullWidth
                    disableElevation
                    variant="outlined"
                    size="large"
                    sx={{
                        backgroundColor: '#D00070',
                        '&:hover': {
                            backgroundColor: '#D00070'
                        }
                    }}
                    onClick={(): void => onButtonClick()}>
                    <Typography
                        variant="subtitle1"
                        color="#FFFFFF"
                        sx={{
                            '&:hover': {
                                color: '#FFFFFF'
                            }
                        }}>
                        {t('toContinue')}
                    </Typography>
                </Button>
            </Box>
        </RootModal>
    );

    return (
        <div>
            <Modal
                open={isModelOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                {body}
            </Modal>
        </div>
    );
}
