import * as React from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '100%',
    bgcolor: 'background.paper',
    // borderRadius: '24px',
    boxShadow: 24,
    minHeight: '100vh',
    outline: 'none'
};

const Logo = styled.img`
    object-fit: inherit;
    width: 48px;
    height: 48px;
    margin-left: auto;
`;

interface DeactiveModalProps {
    isModelOpen: boolean;
    title: string;
    bodyText: JSX.Element;
    buttonText: string;
}

export function FullScreenModal({
    isModelOpen,
    title,
    bodyText,
    buttonText
}: DeactiveModalProps): JSX.Element {
    const body = (
        <Box sx={style}>
            <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
                <Typography variant="subtitle1" color="#374653">
                    {bodyText}
                </Typography>
            </Box>
        </Box>
    );

    return (
        <div>
            <Modal
                sx={{ minHeight: '100vh', marginTop: 'auto', marginBottom: 'auto' }}
                open={isModelOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                {body}
            </Modal>
        </div>
    );
}
