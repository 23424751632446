import { TypographyOptions } from '@mui/material/styles/createTypography';

function pxToRem(value: number): string {
    return `${value / 16}rem`;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function responsiveFontSizes({ sm, md, lg }: { sm: number; md: number; lg: number }) {
    return {
        '@media (min-width:600px)': {
            fontSize: pxToRem(sm)
        },
        '@media (min-width:900px)': {
            fontSize: pxToRem(md)
        },
        '@media (min-width:1200px)': {
            fontSize: pxToRem(lg)
        }
    };
}

const FONT_PRIMARY = 'Inter';
const FONT_SECONDARY = 'Luciole';

export const typography: TypographyOptions = {
    fontFamily: FONT_PRIMARY,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
        fontWeight: 700,
        lineHeight: 80 / 64,
        fontSize: pxToRem(40),
        ...responsiveFontSizes({ sm: 38, md: 40, lg: 42 })
    },
    h2: {
        fontWeight: 700,
        lineHeight: 64 / 48,
        fontSize: pxToRem(32),
        ...responsiveFontSizes({ sm: 30, md: 32, lg: 34 })
    },
    h3: {
        fontWeight: 700,
        lineHeight: 1.5,
        fontSize: pxToRem(28),
        ...responsiveFontSizes({ sm: 26, md: 28, lg: 30 })
    },
    h4: {
        fontWeight: 500,
        lineHeight: 1.5,
        fontSize: pxToRem(24),
        ...responsiveFontSizes({ sm: 20, md: 24, lg: 26 })
    },
    h5: {
        fontWeight: 700,
        lineHeight: 1.5,
        fontSize: pxToRem(18),
        ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 })
    },
    h6: {
        fontWeight: 700,
        lineHeight: 28 / 18,
        fontSize: pxToRem(17),
        ...responsiveFontSizes({ sm: 15, md: 17, lg: 19 })
    },
    subtitle1: {
        fontWeight: 600,
        lineHeight: 1.5,
        fontSize: pxToRem(16),
        ...responsiveFontSizes({ sm: 14, md: 16, lg: 18 })
    },
    subtitle2: {
        fontWeight: 600,
        lineHeight: 22 / 14,
        fontSize: pxToRem(14),
        ...responsiveFontSizes({ sm: 12, md: 14, lg: 16 })
    },
    body1: {
        lineHeight: 1.5,
        fontWeight: 500,
        fontSize: pxToRem(16),
        ...responsiveFontSizes({ sm: 14, md: 16, lg: 18 })
    },
    body2: {
        lineHeight: 22 / 14,
        fontSize: pxToRem(14),
        color: '#5C6670',
        ...responsiveFontSizes({ sm: 12, md: 14, lg: 16 })
    },
    caption: {
        lineHeight: 1.5,
        fontSize: pxToRem(12),
        ...responsiveFontSizes({ sm: 10, md: 12, lg: 14 })
    },
    overline: {
        fontWeight: 700,
        lineHeight: 1.5,
        fontSize: pxToRem(12),
        letterSpacing: 1.1,
        textTransform: 'uppercase'
    },
    button: {
        fontWeight: 700,
        lineHeight: 24 / 14,
        fontSize: pxToRem(14),
        textTransform: 'none'
    }
};
