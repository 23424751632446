import { Box, Card, Typography, useMediaQuery } from '@mui/material';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import blueAddPhotoIcon from '../assets/blueAddPhotoIcon.png';
import purpleAddIcon from '../assets/purpleAddIcon.png';
import { NextStepConfirmationModal } from './Admin/NextStepConfirmationModal';
import { AlertModal } from './AlertModal';

const NewCardButton = styled.img`
    border-radius: 50%;
    width: 50%;
    margin: 10% 0% 25% 0%;
`;

const CustomBox = styled(Box)(() => ({
    width: '100%',
    borderRadius: '9px',
    padding: '13px'
}));

interface NewPhotoCardProps {
    bodyText: string;
    type?: string;
    setMedia?: Function;
    setALert?: Function;
    isYoutubePage?: Function;
    isStimultheque?: boolean;
}

export function CreateNewPhotoCard({
    bodyText,
    type,
    setMedia,
    setALert,
    isYoutubePage,
    isStimultheque
}: NewPhotoCardProps): JSX.Element {
    const isSmallWidth = !useMediaQuery('(min-width:598px)');
    const history = useHistory();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const inputFile = useRef<HTMLInputElement>(null);
    const width = window.innerWidth;
    const [isLoaded, setIsloaded] = useState<boolean>(false);
    const [displayErrorLimit, setDisplayErrorLimit] = useState<boolean>(false);

    const triggerImageUpload = (): void => {
        if (inputFile && inputFile.current) {
            inputFile.current.click();
        }
    };

    const updateVideo = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.files) {
            if (event.target.files.length <= 10) {
                // const value = event.target.files[0];
                const value = Array.from(event.target.files).map((file) => {
                    return file;
                });
                // add "&& value.size <= 50000000" to restore size limit
                if (value !== undefined && setMedia) {
                    setMedia(value);
                }
                // if (value !== undefined && value.size > 50000000 && setALert) {
                //     setALert(true);
                // }
            } else {
                setDisplayErrorLimit(true);
            }
        }
    };

    const updatePhoto = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.files) {
            if (event.target.files.length <= 10) {
                // const value = event.target.files[0];
                const value = Array.from(event.target.files).map((file) => {
                    return file;
                });
                // add "&& value.size <= 10000000" to restore size limit
                if (value !== undefined && setMedia) {
                    setMedia(value);
                }
                // if (value !== undefined && value.size > 10000000 && setALert) {
                //     setALert(true);
                // }
            } else {
                setDisplayErrorLimit(true);
            }
        }
    };

    const CustomizeExerciseCard = (): JSX.Element => {
        if (isStimultheque) {
            return (
                <Box>
                    <NewCardButton
                        src={blueAddPhotoIcon}
                        alt="BlueAddIcon"
                        style={{
                            margin: '0px',
                            marginTop: width < 520 ? '15%' : '25%',
                            visibility: isLoaded ? 'visible' : 'hidden'
                        }}
                        onLoad={(): void => setIsloaded(true)}
                    />
                    <Typography
                        variant="h5"
                        sx={{
                            color: 'white',
                            padding: '0px 10px',
                            marginBottom: '25%',
                            visibility: isLoaded ? 'visible' : 'hidden',
                            fontSize: width < 520 ? '1rem !important' : '1.25rem !important'
                        }}>
                        {bodyText}
                    </Typography>
                </Box>
            );
        }
        return (
            <Box>
                <NewCardButton
                    src={purpleAddIcon}
                    alt="purpleAddIcon"
                    style={{
                        margin: '0px',
                        marginTop: width < 520 ? '15%' : '25%',
                        visibility: isLoaded ? 'visible' : 'hidden'
                    }}
                    onLoad={(): void => setIsloaded(true)}
                />
                <Typography
                    variant="h5"
                    sx={{
                        color: 'white',
                        padding: '0px 10px',
                        marginBottom: '25%',
                        visibility: isLoaded ? 'visible' : 'hidden',
                        fontSize: width < 520 ? '1rem !important' : '1.25rem !important'
                    }}>
                    {bodyText}
                </Typography>
            </Box>
        );
    };

    const stimulthequeCard = (): JSX.Element => {
        return (
            <Card
                sx={{
                    flexGrow: 0,
                    // marginBottom: '16px',
                    height: width < 520 ? 'calc(100vw / 2 + 20px)' : '290px',
                    width: width < 520 ? 'calc(100vw / 2 - 20px)' : '220px',
                    borderRadius: '9px'
                }}
                onClick={(): void => {
                    history.push('/exercise');
                }}>
                <Box
                    sx={{
                        backgroundImage:
                            'linear-gradient(55deg, hsl(209deg 69% 56%) 45.26%, #6c79da 94.49%)',
                        height: width < 520 ? 'calc(100vw / 2)' : '270px',
                        width: width < 520 ? 'calc(100vw / 2 - 40px)' : '200px',
                        marginTop: '10px',
                        marginLeft: '10px',
                        marginRight: '10px',
                        marginBottom: '10px',
                        borderRadius: '6px'
                    }}>
                    {CustomizeExerciseCard()}
                </Box>
            </Card>
        );
    };

    const redCardLayout = (): JSX.Element => {
        if (type === 'thumbnails') {
            return (
                <Card
                    sx={{
                        flexGrow: 0,
                        // marginRight: '16px',
                        // marginBottom: '16px',
                        height: width < 520 ? 'calc(100vw / 2 + 20px)' : '290px',
                        width: width < 520 ? 'calc(100vw / 2 - 20px)' : '220px',
                        borderRadius: '9px'
                    }}>
                    <Box
                        sx={{
                            backgroundImage:
                                'linear-gradient(55deg, #6b079c 45.26%, #ce6cda 94.49%)',
                            height: width < 520 ? 'calc(100vw / 2)' : '270px',
                            width: width < 520 ? 'calc(100vw / 2 - 40px)' : '200px',
                            marginTop: '10px',
                            marginLeft: '10px',
                            marginRight: '10px',
                            marginBottom: '10px',
                            borderRadius: '6px'
                        }}
                        onClick={triggerImageUpload}>
                        <input
                            type="file"
                            ref={inputFile}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                                updatePhoto(event)
                            }
                            accept="image/*"
                            multiple
                            style={{ display: 'none' }}
                        />
                        {CustomizeExerciseCard()}
                    </Box>
                </Card>
            );
        }
        if (type === 'youtubeLink') {
            return (
                <Card
                    sx={{
                        flexGrow: 0,
                        // marginRight: '16px',
                        // marginBottom: '16px',
                        height: width < 520 ? 'calc(100vw / 2 + 20px)' : '290px',
                        width: width < 520 ? 'calc(100vw / 2 - 20px)' : '220px',
                        borderRadius: '9px'
                    }}>
                    <Box
                        sx={{
                            backgroundImage:
                                'linear-gradient(55deg, #6b079c 45.26%, #ce6cda 94.49%)',
                            height: width < 520 ? 'calc(100vw / 2)' : '270px',
                            width: width < 520 ? 'calc(100vw / 2 - 40px)' : '200px',
                            marginTop: '10px',
                            marginLeft: '10px',
                            marginRight: '10px',
                            marginBottom: '10px',
                            borderRadius: '6px'
                        }}
                        onClick={(): void => {
                            if (isYoutubePage) {
                                isYoutubePage(true);
                            }
                        }}>
                        {CustomizeExerciseCard()}
                    </Box>
                </Card>
            );
        }
        return (
            <Card
                sx={{
                    flexGrow: 0,
                    // marginRight: '16px',
                    // marginBottom: '16px',
                    height: width < 520 ? 'calc(100vw / 2 + 20px)' : '290px',
                    width: width < 520 ? 'calc(100vw / 2 - 20px)' : '220px',
                    borderRadius: '9px'
                }}>
                <Box
                    sx={{
                        backgroundImage: 'linear-gradient(55deg, #6b079c 45.26%, #ce6cda 94.49%)',
                        height: width < 520 ? 'calc(100vw / 2)' : '270px',
                        width: width < 520 ? 'calc(100vw / 2 - 40px)' : '200px',
                        marginTop: '10px',
                        marginLeft: '10px',
                        marginRight: '10px',
                        marginBottom: '10px',
                        borderRadius: '6px'
                    }}
                    onClick={triggerImageUpload}>
                    <input
                        type="file"
                        ref={inputFile}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                            updateVideo(event)
                        }
                        accept="video/mp4,video/x-m4v"
                        multiple
                        style={{ display: 'none' }}
                    />
                    {CustomizeExerciseCard()}
                </Box>
            </Card>
        );
    };

    return (
        <>
            <NextStepConfirmationModal
                isModelOpen={openModal}
                setModelOpen={setOpenModal}
                onCreateButtonClick={(): void => {
                    setOpenModal(false);
                    history.push('/edit');
                }}
                onEditButtonClick={(): void => history.push("/chooseImage/souven'in")}
            />
            <AlertModal
                openModalAlert={displayErrorLimit}
                setOpenModalAlert={setDisplayErrorLimit}
                setMedia={setMedia ?? ((): void => {})}
                thumbnails={type === 'thumbnails'}
                numberExceedLimit
            />
            {/* <CustomBox>{isStimultheque ? stimulthequeCard() : redCardLayout()}</CustomBox> */}
            <Box>{isStimultheque ? stimulthequeCard() : redCardLayout()}</Box>
        </>
    );
}
