import { Box, Typography, Button, Checkbox, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { LoadingButton } from '@mui/lab';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/styles';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Header } from '../components/Header';
import { forgotPassword } from '../services/firebaseAuth';
import { sendResetInfoPatient } from '../services/storageFunctions';
import { getAllFamilly, getUserByEmailId, getUserByInfo } from '../services/cloudFirestore';
import { clickAnalytics } from '../services/analyticsFunction';
import { validateEmail } from '../utilities/utils';
import { Roles } from '../models/Roles';

import magicUnicorns from '../assets/magicUnicorns.png';
import checkGreen from '../assets/checkGreen.svg';
import { CustomCheckbox } from '../components/CustomCheckbox';
import { Family } from '../models/Family';
import { User } from '../models/User';

const Title = styled(Typography)({
    display: 'flex',
    alignContent: 'space-around',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 950px) and (max-height: 700px)': {
        marginTop: '6%'
    }
});

const ForgotButton = styled(LoadingButton)({
    width: '25%',
    minWidth: '160px',
    margin: '10px',
    color: '#D00070',
    fontSize: '1.2rem',
    fontWeight: '600',
    border: '1px solid #8B004B',
    boxShadow: '0px 0px 0px 1.7833px rgba(208, 0, 112, 0.4)',
    borderRadius: '12px',
    '&:hover': {
        backgroundColor: '#D00070',
        boxShadow: '0px 0px 0px 1.7833px rgba(208, 0, 112, 0.4)',
        color: 'white'
    },
    '@media (max-width: 930px)': {
        width: '100%',
        minWidth: '100%'
    }
});

const ForgotButtonBig = styled(LoadingButton)({
    width: '60%',
    margin: '10px',
    color: '#D00070',
    fontSize: '1.2rem',
    fontWeight: '600',
    border: '1px solid #8B004B',
    boxShadow: '0px 0px 0px 1.7833px rgba(208, 0, 112, 0.4)',
    borderRadius: '12px',
    '&:hover': {
        backgroundColor: '#D00070',
        boxShadow: '0px 0px 0px 1.7833px rgba(208, 0, 112, 0.4)',
        color: 'white'
    },
    '@media (max-width: 650px)': {
        width: '100%',
        minWidth: '100%'
    }
});

const ForgotInputBig = styled(TextField)({
    width: '60%',
    marginTop: '10px',
    '@media (max-width: 650px)': {
        width: '100%',
        minWidth: '100%'
    }
});
const ForgotSelectBig = styled(Select)({
    width: '60%',
    marginTop: '10px',
    '@media (max-width: 650px)': {
        width: '100%',
        minWidth: '100%'
    }
});

export function ForgotPassword(): JSX.Element {
    const { t } = useTranslation();
    const history = useHistory();
    const [typeForgot, setTypeForgot] = useState<'password' | 'email' | 'passwordEmail'>();
    const [step, setStep] = useState(0);

    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const [errorEmailOrTwo, setErrorEmailOrTwo] = useState(false);
    const [errorSelectMail, setErrorSelectMail] = useState(false);
    const [step2Error1, setStep2Error1] = useState<boolean>(false);
    const [step2Error2, setStep2Error2] = useState<boolean>(false);
    const [step2Error3, setStep2Error3] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [userIsPatient, setUserIsPatient] = useState<boolean>();
    const [familyCode, setFamilyCode] = useState<string>();

    const [emailSending, setEmailSending] = useState<{
        myEmail: string;
        famillyEmail: { email: string; firstName: string };
        otherEmail: string;
    }>({ myEmail: '', famillyEmail: { email: '', firstName: '' }, otherEmail: '' });
    const [emailSendingBool, setEmailSendingBool] = useState<{
        myEmail: boolean;
        famillyEmail: boolean;
        otherEmail: boolean;
    }>({ myEmail: false, famillyEmail: false, otherEmail: false });
    const [arrayFamillyEmail, setArrayFamillyEmail] = useState<
        { email: string; firstName: string }[]
    >([]);
    const [userInfo, setUserInfo] = useState<{
        firstName: string;
        lastName: string;
        dob: string;
    }>({ firstName: '', lastName: '', dob: new Date().toISOString().slice(0, 10) });
    const [user, setUser] = useState<User>();

    const validStep0 = (type: 'password' | 'email' | 'passwordEmail' | undefined): void => {
        setTypeForgot(type);
        if (type === undefined) setStep(0);
        else setStep(1);
    };

    const [sendingEmailList, sendingEmailListBool] = useState<string[]>([]);
    // const [sendingFirstNameFamilly, setSendingFirstNameFamilly] = useState<string>();
    const validStep2 = async (): Promise<void> => {
        let sendingFirstNameFamilly;
        // use temps because useState is async
        let tempStep2Error1 = false;
        let tempStep2Error2 = false;
        let tempStep2Error3 = false;
        sendingEmailListBool([]);
        if (emailSendingBool.myEmail) {
            if (validateEmail(emailSending.myEmail)) {
                sendingEmailList.push(emailSending.myEmail);
            } else {
                // console.log('error1', step2Error1);
                setStep2Error1(true);
                tempStep2Error1 = true;
                // console.log('error2', step2Error1);
            }
        }
        if (emailSendingBool.famillyEmail) {
            if (validateEmail(emailSending.famillyEmail.email)) {
                sendingEmailList.push(emailSending.famillyEmail.email);
                sendingFirstNameFamilly = emailSending.famillyEmail.firstName;
            } else {
                setStep2Error2(true);
                tempStep2Error2 = true;
            }
        }
        if (emailSendingBool.otherEmail) {
            if (validateEmail(emailSending.otherEmail)) {
                sendingEmailList.push(emailSending.otherEmail);
            } else {
                setStep2Error3(true);
                tempStep2Error3 = true;
            }
        }

        if (
            !tempStep2Error1 &&
            !tempStep2Error2 &&
            !tempStep2Error3 &&
            (emailSendingBool.myEmail ||
                emailSendingBool.famillyEmail ||
                emailSendingBool.otherEmail)
        ) {
            const tempSendingEmailList: string[] = [];
            if (emailSendingBool.myEmail) tempSendingEmailList.push(emailSending.myEmail);
            if (emailSendingBool.famillyEmail) {
                tempSendingEmailList.push(emailSending.famillyEmail.email);
            }

            // sending to user and familly member
            if (typeForgot) {
                sendResetInfoPatient(
                    typeForgot,
                    email,
                    tempSendingEmailList,
                    {
                        firstName: sendingFirstNameFamilly ?? '',
                        firstNamePatient: user && user.firstName ? user.firstName : '',
                        lastNamePatient: user && user.lastName ? user.lastName : ''
                    },
                    emailSendingBool.myEmail ? emailSending.myEmail : ''
                ).then((res) => {
                    if (res) {
                        setStep(-1);
                    }
                });
            }

            // sending to other account
            if (emailSendingBool.otherEmail && typeForgot) {
                sendResetInfoPatient(
                    typeForgot,
                    email,
                    [emailSending.otherEmail],
                    {
                        firstName: '',
                        firstNamePatient: user && user.firstName ? user.firstName : '',
                        lastNamePatient: user && user.lastName ? user.lastName : ''
                    },
                    emailSendingBool.myEmail ? emailSending.myEmail : ''
                ).then((res) => {
                    if (res) {
                        setStep(-1);
                    }
                });
            }
        } else {
            setErrorSelectMail(true);
        }
    };

    useEffect(() => {
        setError(false);
        if (
            step === 2 &&
            !emailSendingBool.myEmail &&
            !emailSendingBool.famillyEmail &&
            !emailSendingBool.otherEmail
        ) {
            let tempMyEmail = '';
            const tempFamillyEmail: { email: string; firstName: string }[] = [];
            const getData = async (): Promise<void> => {
                if (validateEmail(email) && emailSending.myEmail === '') tempMyEmail = email;
                if (familyCode) {
                    const FamillyEmail = await getAllFamilly(familyCode);
                    if (FamillyEmail) {
                        FamillyEmail.forEach((element) => {
                            if (element.emailId && element.firstName) {
                                tempFamillyEmail.push({
                                    email: element.emailId,
                                    firstName: element.firstName
                                });
                            }
                        });
                    }
                }
            };
            getData().then(() => {
                setArrayFamillyEmail(tempFamillyEmail);
                setEmailSending({
                    myEmail: tempMyEmail,
                    famillyEmail: tempFamillyEmail[0],
                    otherEmail: ''
                });
                setEmailSendingBool({
                    myEmail: tempMyEmail !== '',
                    famillyEmail: tempFamillyEmail[0].email !== '',
                    otherEmail: false
                });
            });
        }
    }, [email, step]);

    const onForgotPassword = async (): Promise<void> => {
        // setError(false);
        if (email) {
            setError(false);
            let tempEmail = '';
            if (!validateEmail(email)) tempEmail = `${email}@stimulin.fr`;
            else tempEmail = email;

            const thisUser = await getUserByEmailId(tempEmail);
            if (thisUser) {
                if (thisUser.role === Roles.PATIENT) {
                    setUserIsPatient(true);
                    setFamilyCode(thisUser.familyCode);
                    setUser(thisUser);
                    setStep(2);
                } else {
                    setUserIsPatient(false);
                    setLoading(true);
                    const tempSendingEmailList = [tempEmail];
                    sendResetInfoPatient(
                        'password',
                        tempEmail,
                        tempSendingEmailList,
                        {
                            firstName: thisUser.firstName ?? '',
                            firstNamePatient: thisUser.firstName ?? '',
                            lastNamePatient: thisUser.lastName ?? ''
                        },
                        tempEmail
                    ).then((result) => {
                        if (result) {
                            setStep(-1);
                        }
                    });
                }
            } else {
                setError(true);
            }
        } else {
            setError(true);
        }
    };

    const onForgotEmailOrTwo = async (): Promise<void> => {
        setErrorEmailOrTwo(false);
        if (userInfo.firstName && userInfo.lastName) {
            getUserByInfo(
                userInfo.firstName,
                userInfo.lastName,
                userInfo.dob !== new Date().toISOString().slice(0, 10) ? userInfo.dob : null
            ).then((res) => {
                if (res && res.length === 1 && res[0].emailId) {
                    if (res[0].role !== Roles.PATIENT) {
                        if (typeForgot) {
                            const tempSendingEmailList = [res[0].emailId];
                            sendResetInfoPatient(
                                typeForgot,
                                res[0].emailId,
                                tempSendingEmailList,
                                {
                                    firstName: res[0].firstName ?? '',
                                    firstNamePatient: res[0].firstName ?? '',
                                    lastNamePatient: res[0].lastName ?? ''
                                },
                                res[0].emailId
                            ).then((result) => {
                                if (result) {
                                    setStep(-1);
                                }
                            });
                        }
                    } else {
                        const setingEmail = async (): Promise<void> => {
                            if (res[0].emailId) {
                                await setEmail(res[0].emailId);
                                await setUserIsPatient(true);
                                await setFamilyCode(res[0].familyCode);
                                await setUser(res[0]);
                            }
                        };
                        setingEmail().then(() => {
                            setStep(2);
                        });
                    }
                } else {
                    setErrorEmailOrTwo(true);
                }
            });
        } else {
            setErrorEmailOrTwo(true);
        }
    };

    return (
        <Box flex={1} display="flex" flexDirection="column" height="100vh">
            <Header
                canGoBack
                goBackFunc={(): void => {
                    if (step > 0) setStep(step - 1);
                    else history.goBack();
                }}
            />
            <Box width="100%">
                {step === -1 && (
                    <Box
                        sx={{
                            position: 'fixed',
                            top: 0,
                            height: '100vh',
                            width: '100vw',
                            backgroundColor: '#7B838B',
                            zIndex: 1000,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        <Box
                            sx={{
                                width: '75%',
                                height: '75%',
                                backgroundColor: 'white',
                                borderRadius: '20px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                padding: '20px'
                            }}>
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '30%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                <img src={magicUnicorns} alt="magicUnicorns" height="100%" />
                                <img src={checkGreen} alt="check" />
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '40%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                <Typography
                                    variant="h3"
                                    sx={{
                                        textAlign: 'center',
                                        fontSize: '1.5 !important',
                                        fontWeight: '700 !important',
                                        color: '#374653'
                                    }}>
                                    {userIsPatient ? t('emailSentPatient') : t('emailSent!patient')}
                                </Typography>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        textAlign: 'center',
                                        fontSize: '1.2rem !important',
                                        fontWeight: '400 !important',
                                        color: '#374653'
                                    }}>
                                    {!userIsPatient && t('checkYourEmail!patient')}
                                    {userIsPatient &&
                                        typeForgot === 'password' &&
                                        t('checkYourEmailPatientPassword')}
                                    {userIsPatient && typeForgot === 'email' && (
                                        <>
                                            {t('checkYourEmailPatientEmail1')}
                                            <br />
                                            {t('checkYourEmailPatientEmail2')}
                                        </>
                                    )}
                                    {userIsPatient &&
                                        typeForgot === 'passwordEmail' &&
                                        t('checkYourEmailPatientPasswordEmail')}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '30%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                <ForgotButtonBig
                                    onClick={(): void => {
                                        history.push('/login');
                                    }}>
                                    {t('Igo')}
                                </ForgotButtonBig>
                            </Box>
                        </Box>
                    </Box>
                )}
                {step === 0 && (
                    <>
                        <Title variant="h3" sx={{ marginTop: '3%' }}>
                            <Typography variant="h3">{t('whatForgot')}</Typography>
                        </Title>
                        <Box
                            sx={{
                                marginTop: '5%',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                width: '90%',
                                fontFamily: 'Inter',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-evenly',
                                flexWrap: 'wrap'
                            }}>
                            <ForgotButton onClick={(): void => validStep0('password')}>
                                {t('myPassword')}
                            </ForgotButton>
                            <ForgotButton onClick={(): void => validStep0('email')}>
                                {t('myEmail')}
                            </ForgotButton>
                            <ForgotButton onClick={(): void => validStep0('passwordEmail')}>
                                {t('myPasswordEmail')}
                            </ForgotButton>
                        </Box>
                    </>
                )}
                {step === 1 && typeForgot === 'password' && (
                    <>
                        {/* {typeForgot === 'password' && (
                            <> */}
                        <Title variant="h3" sx={{ marginTop: '3%' }}>
                            <Typography variant="h3">{t('forgotPasswordTitle')}</Typography>
                        </Title>
                        <Title
                            variant="h4"
                            sx={{ flexDirection: 'column', marginTop: '0px !important' }}>
                            <Typography variant="h4">{t('forgotPasswordText1')}</Typography>
                            <Typography variant="h4">{t('forgotPasswordText2')}</Typography>
                        </Title>
                        {/* </>
                        )} */}
                        <Box
                            sx={{
                                marginTop: '3%',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                width: '90%',
                                fontFamily: 'Inter',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-evenly',
                                flexWrap: 'wrap'
                            }}>
                            {/* {typeForgot === 'password' && (
                                <> */}
                            <ForgotInputBig
                                id="email"
                                label={t('inputForgotEmail')}
                                variant="outlined"
                                value={email}
                                error={error}
                                onChange={(e): void => setEmail(e.target.value)}
                            />
                            <ForgotButtonBig
                                loading={loading}
                                onClick={async (): Promise<void> => {
                                    onForgotPassword();
                                }}>
                                {t('createNewPassword')}
                            </ForgotButtonBig>
                            {error && (
                                <Typography variant="body1" color="error">
                                    {t('errorForgotPasswordNotFind')}
                                </Typography>
                            )}
                            <Title variant="h3" sx={{ marginTop: '3%' }}>
                                <Typography variant="h3">{t('oupsForgotPasswordEmail')}</Typography>
                            </Title>
                            <Title
                                variant="h4"
                                sx={{
                                    flexDirection: 'column',
                                    marginTop: '0px !important'
                                }}>
                                <Typography variant="h4">
                                    {t('oupsForgotPasswordEmailDesc')}
                                </Typography>
                            </Title>
                            <ForgotButtonBig
                                sx={{ marginTop: '20px !important' }}
                                onClick={(): void => {
                                    validStep0('passwordEmail');
                                }}>
                                {t('getPasswordEmail')}
                            </ForgotButtonBig>
                            {/* </>
                            )} */}
                        </Box>
                    </>
                )}
                {step === 1 && typeForgot !== 'password' && (
                    <>
                        <Title variant="h3" sx={{ marginTop: '3%' }}>
                            <Typography variant="h3">
                                {t('forgotEmail|EmailPasswordTitle')}
                                <br />
                                {typeForgot === 'passwordEmail'
                                    ? t('forgotEmailPasswordTitle2')
                                    : t('forgotEmailTitle2')}
                            </Typography>
                        </Title>
                        <Box
                            sx={{
                                marginTop: '3%',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                width: '90%',
                                fontFamily: 'Inter',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-evenly',
                                flexWrap: 'wrap'
                            }}>
                            <ForgotInputBig
                                id="firstname"
                                label={t('inputFirstname')}
                                variant="outlined"
                                value={userInfo.firstName}
                                onChange={(e): void => {
                                    setUserInfo({
                                        ...userInfo,
                                        firstName: e.target.value
                                    });
                                }}
                            />
                            <ForgotInputBig
                                id="lastname"
                                label={t('inputLastname')}
                                variant="outlined"
                                value={userInfo.lastName}
                                onChange={(e): void => {
                                    setUserInfo({
                                        ...userInfo,
                                        lastName: e.target.value
                                    });
                                }}
                            />
                            <ForgotInputBig
                                id="dob"
                                label={t('inputBirthday')}
                                variant="outlined"
                                type="date"
                                value={userInfo.dob}
                                onChange={(e): void => {
                                    setUserInfo({
                                        ...userInfo,
                                        dob: e.target.value
                                    });
                                }}
                            />
                            <ForgotButtonBig
                                sx={{
                                    marginTop: '5% !important',
                                    height: typeForgot === 'email' ? '50px' : '85px'
                                }}
                                loading={loading}
                                onClick={async (): Promise<void> => {
                                    onForgotEmailOrTwo();
                                }}>
                                {typeForgot === 'email' ? (
                                    t('getIdentifiant')
                                ) : (
                                    <span style={{ lineHeight: '1.3rem' }}>
                                        {t('getIdentifiantPass1')}
                                        <br />
                                        {t('getIdentifiantPass2')}
                                    </span>
                                )}
                            </ForgotButtonBig>
                            {errorEmailOrTwo && (
                                <Typography variant="body1" color="error">
                                    {t('errorForgotEmailOrTwoEmpty')}
                                </Typography>
                            )}
                        </Box>
                    </>
                )}
                {step === 2 && userIsPatient && (
                    <>
                        <Title
                            variant="h3"
                            sx={{ marginTop: '3%', marginLeft: '2%', marginRight: '2%' }}>
                            <Typography variant="h3">{t('forgotPasswordPatient')}</Typography>
                        </Title>
                        <Box
                            sx={{
                                marginTop: '3%',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                width: '90%',
                                fontFamily: 'Inter',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-evenly',
                                flexWrap: 'wrap'
                            }}>
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '50px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginTop: '4%'
                                }}>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        textAlign: 'left',
                                        fontSize: '1.2rem !important',
                                        fontWeight: '400 !important',
                                        color: '#84919E',
                                        width: '40%',
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'start'
                                    }}>
                                    {t('forgotPasswordPatient.choise1')}
                                </Typography>
                                <ForgotInputBig
                                    id="email"
                                    type="email"
                                    error={step2Error1}
                                    sx={{
                                        width: '50% !important',
                                        marginTop: '0px !important'
                                    }}
                                    value={emailSending?.myEmail}
                                    onChange={(e): void => {
                                        setEmailSending({
                                            ...emailSending,
                                            myEmail: e.target.value
                                        });
                                        if (e.target.value === '') {
                                            setEmailSendingBool({
                                                ...emailSendingBool,
                                                myEmail: false
                                            });
                                        } else {
                                            setEmailSendingBool({
                                                ...emailSendingBool,
                                                myEmail: true
                                            });
                                        }
                                    }}
                                />
                                <Box
                                    sx={{
                                        width: '10%',
                                        height: '100% !important',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                    <Checkbox
                                        checked={emailSendingBool.myEmail}
                                        icon={
                                            <CircleOutlinedIcon
                                                sx={{ color: '#ACBCC7', fontSize: '20px' }}
                                            />
                                        }
                                        checkedIcon={
                                            <CheckCircleIcon sx={{ color: '#89CC33 !important' }} />
                                        }
                                        onChange={(): void => {
                                            setEmailSendingBool({
                                                ...emailSendingBool,
                                                myEmail: !emailSendingBool.myEmail
                                            });
                                        }}
                                        sx={{
                                            transform: 'scale(2.5)',
                                            borderRadius: '50%',
                                            padding: '0px !important',
                                            color: '#89CC33'
                                            // width: '10%',
                                            // height: '100% !important'
                                        }}
                                    />
                                </Box>
                            </Box>
                            {step2Error1 && (
                                <Typography sx={{ width: '100%' }} variant="body1" color="error">
                                    {t('errorIsNotEmail')}
                                </Typography>
                            )}
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '50px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginTop: '4%'
                                }}>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        textAlign: 'left',
                                        fontSize: '1.2rem !important',
                                        fontWeight: '400 !important',
                                        color: '#84919E',
                                        width: '40%',
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'start'
                                    }}>
                                    {t('forgotPasswordPatient.choise2')}
                                </Typography>
                                <ForgotSelectBig
                                    defaultValue={arrayFamillyEmail[0]}
                                    sx={{
                                        width: '50% !important',
                                        marginTop: '0px !important',
                                        textAlign: 'left'
                                    }}
                                    value={emailSending.famillyEmail.email}
                                    onChange={(e): void => {
                                        if (
                                            e &&
                                            e.target &&
                                            e.target.value &&
                                            typeof e.target.value === 'string'
                                        ) {
                                            // get first name into arrayFamillyEmail with email
                                            const firstName = arrayFamillyEmail.find(
                                                (item) => item === e.target.value
                                            );
                                            if (firstName) {
                                                setEmailSending({
                                                    ...emailSending,
                                                    famillyEmail: {
                                                        email: e.target.value,
                                                        firstName: firstName.firstName
                                                    }
                                                });
                                            }
                                            if (e.target.value === '') {
                                                setEmailSendingBool({
                                                    ...emailSendingBool,
                                                    famillyEmail: false
                                                });
                                            } else {
                                                setEmailSendingBool({
                                                    ...emailSendingBool,
                                                    famillyEmail: true
                                                });
                                            }
                                        }
                                    }}>
                                    {arrayFamillyEmail.map((item) => (
                                        <MenuItem value={item.email}>{item.email}</MenuItem>
                                    ))}
                                </ForgotSelectBig>
                                <Box
                                    sx={{
                                        width: '10%',
                                        height: '100% !important',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                    <Checkbox
                                        defaultChecked={emailSendingBool.famillyEmail}
                                        checked={emailSendingBool.famillyEmail}
                                        icon={
                                            <CircleOutlinedIcon
                                                sx={{ color: '#ACBCC7', fontSize: '20px' }}
                                            />
                                        }
                                        checkedIcon={
                                            <CheckCircleIcon sx={{ color: '#89CC33 !important' }} />
                                        }
                                        onChange={(): void => {
                                            setEmailSendingBool({
                                                ...emailSendingBool,
                                                famillyEmail: !emailSendingBool.famillyEmail
                                            });
                                        }}
                                        sx={{
                                            transform: 'scale(2.5)',
                                            borderRadius: '50%',
                                            padding: '0px !important',
                                            color: '#89CC33'
                                        }}
                                    />
                                </Box>
                            </Box>
                            {step2Error2 && (
                                <Typography sx={{ width: '100%' }} variant="body1" color="error">
                                    {t('errorIsNotEmail')}
                                </Typography>
                            )}
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '50px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginTop: '4%'
                                }}>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        textAlign: 'left',
                                        fontSize: '1.2rem !important',
                                        fontWeight: '400 !important',
                                        color: '#84919E',
                                        width: '40%',
                                        height: '100%'
                                        // display: 'flex'
                                        // flexDirection: 'row',
                                        // alignItems: 'center',
                                        // justifyContent: 'start'
                                    }}>
                                    {t('forgotPasswordPatient.choise3')}
                                    <span style={{ fontSize: '0.8rem' }}>
                                        {t('forgotPasswordPatient.choise3.2')}
                                    </span>
                                    {' :'}
                                </Typography>
                                <ForgotInputBig
                                    type="email"
                                    error={step2Error3}
                                    sx={{
                                        width: '50% !important',
                                        marginTop: '0px !important'
                                    }}
                                    // value={}
                                    onChange={(e): void => {
                                        setEmailSending({
                                            ...emailSending,
                                            otherEmail: e.target.value
                                        });
                                        if (e.target.value === '') {
                                            setEmailSendingBool({
                                                ...emailSendingBool,
                                                otherEmail: false
                                            });
                                        } else {
                                            setEmailSendingBool({
                                                ...emailSendingBool,
                                                otherEmail: true
                                            });
                                        }
                                    }}
                                />
                                <Box
                                    sx={{
                                        width: '10%',
                                        height: '100% !important',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                    <Checkbox
                                        checked={emailSendingBool.otherEmail}
                                        icon={
                                            <CircleOutlinedIcon
                                                sx={{ color: '#ACBCC7', fontSize: '20px' }}
                                            />
                                        }
                                        checkedIcon={
                                            <CheckCircleIcon sx={{ color: '#89CC33 !important' }} />
                                        }
                                        onChange={(): void => {
                                            setEmailSendingBool({
                                                ...emailSendingBool,
                                                otherEmail: !emailSendingBool.otherEmail
                                            });
                                        }}
                                        sx={{
                                            transform: 'scale(2.5)',
                                            borderRadius: '50%',
                                            padding: '0px !important',
                                            color: '#89CC33'
                                        }}
                                    />
                                </Box>
                            </Box>
                            {step2Error3 && (
                                <Typography sx={{ width: '100%' }} variant="body1" color="error">
                                    {t('errorIsNotEmail')}
                                </Typography>
                            )}
                            <ForgotButtonBig
                                sx={{
                                    marginTop: '6% !important',
                                    height: typeForgot === 'passwordEmail' ? '85px' : '50px'
                                }}
                                onClick={(): void => {
                                    validStep2();
                                }}>
                                {typeForgot === 'password' && t('getLinkByEmail')}
                                {typeForgot === 'email' && t('getIdByEmail')}
                                {typeForgot === 'passwordEmail' && (
                                    <span style={{ lineHeight: '1.3rem' }}>
                                        {t('getIdPassByEmail1')}
                                        <br />
                                        {t('getIdPassByEmail2')}
                                    </span>
                                )}
                            </ForgotButtonBig>
                            {errorSelectMail && (
                                <Typography sx={{ width: '100%' }} variant="body1" color="error">
                                    {t('errorSelectMail')}
                                </Typography>
                            )}
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );
}
