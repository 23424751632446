/* eslint-disable react/no-unknown-property */
/* eslint-disable prettier/prettier */
import React from 'react';

export function SudokuEmptySvgIcon(): JSX.Element {
    return (
        <svg
            width="28"
            height="26"
            viewBox="0 0 30 30"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <rect
                x="2.19117"
                y="2.19117"
                width="25.6164"
                height="25.6164"
                rx="1.51933"
                stroke="#84919E"
                stroke-width="0.382349"
            />
            <rect
                width="30"
                height="29"
                rx="1.71051"
                stroke="#84919E"
                stroke-width="4"
                mask="url(#path-16-inside-1_11_9077)"
            />
            <rect
                x="2.20525"
                y="2.20525"
                width="8.1679"
                height="8.1679"
                rx="0.478925"
                fill="#FBFBFB"
                stroke="#84919E"
                stroke-width="0.410507"
            />
            <rect
                x="2.20525"
                y="10.9181"
                width="8.1679"
                height="8.1679"
                rx="0.478925"
                fill="#F2F2F2"
                stroke="#84919E"
                stroke-width="0.410507"
            />
            <rect
                x="2.20525"
                y="19.6252"
                width="8.1679"
                height="8.1679"
                rx="0.478925"
                fill="#F2F2F2"
                stroke="#84919E"
                stroke-width="0.410507"
            />
            <rect
                x="10.9152"
                y="2.20525"
                width="8.1679"
                height="8.1679"
                rx="0.478925"
                fill="#FBFBFB"
                stroke="#84919E"
                stroke-width="0.410507"
            />
            <rect
                x="10.9152"
                y="10.9181"
                width="8.1679"
                height="8.1679"
                rx="0.478925"
                fill="#FBFBFB"
                stroke="#84919E"
                stroke-width="0.410507"
            />
            <rect
                x="10.9152"
                y="19.6252"
                width="8.1679"
                height="8.1679"
                rx="0.478925"
                fill="#F2F2F2"
                stroke="#84919E"
                stroke-width="0.410507"
            />
            <rect
                x="19.6291"
                y="2.20525"
                width="8.1679"
                height="8.1679"
                rx="0.478925"
                fill="#FBFBFB"
                stroke="#84919E"
                stroke-width="0.410507"
            />
            <rect
                x="19.6291"
                y="10.9181"
                width="8.1679"
                height="8.1679"
                rx="0.478925"
                fill="#F2F2F2"
                stroke="#84919E"
                stroke-width="0.410507"
            />
            <rect
                x="19.6291"
                y="19.6252"
                width="8.1679"
                height="8.1679"
                rx="0.478925"
                fill="#F2F2F2"
                stroke="#84919E"
                stroke-width="0.410507"
            />
            <path
                d="M7.35527 15.3122C7.35527 15.5544 7.25713 15.7557 7.06086 15.9163C6.86458 16.0744 6.6237 16.1534 6.33822 16.1534C6.02979 16.1534 5.78126 16.0795 5.59264 15.9316C5.40656 15.7838 5.31352 15.5875 5.31352 15.3428C5.31352 15.1873 5.3594 15.042 5.45117 14.9069C5.54293 14.7718 5.67166 14.6571 5.83734 14.5628C5.70479 14.494 5.60156 14.4061 5.52764 14.299C5.45627 14.1894 5.42058 14.0696 5.42058 13.9396C5.42058 13.7127 5.50725 13.5254 5.68058 13.3775C5.85646 13.2271 6.07695 13.1519 6.34204 13.1519C6.60459 13.1519 6.81743 13.2208 6.98056 13.3584C7.14625 13.4961 7.22909 13.6719 7.22909 13.8861C7.22909 14.0263 7.19086 14.155 7.11439 14.2722C7.03792 14.3895 6.93596 14.48 6.80851 14.5437C6.99458 14.638 7.13223 14.7489 7.22144 14.8763C7.31066 15.0012 7.35527 15.1465 7.35527 15.3122ZM6.78174 13.9511C6.78174 13.8211 6.73968 13.7166 6.65557 13.6375C6.574 13.5585 6.46184 13.519 6.3191 13.519C6.18655 13.519 6.07822 13.5572 5.9941 13.6337C5.90999 13.7102 5.86793 13.8058 5.86793 13.9205C5.86793 14.0275 5.91509 14.1206 6.0094 14.1996C6.10626 14.2786 6.25538 14.3474 6.45675 14.4061C6.55616 14.3576 6.63518 14.2939 6.6938 14.2149C6.75243 14.1333 6.78174 14.0454 6.78174 13.9511ZM6.90792 15.3084C6.90792 15.186 6.84419 15.0752 6.71674 14.9758C6.58929 14.8763 6.40449 14.796 6.16234 14.7349C6.04253 14.7859 5.94567 14.861 5.87175 14.9605C5.79783 15.0573 5.76087 15.1644 5.76087 15.2816C5.76087 15.4346 5.81058 15.5569 5.90999 15.6487C6.01195 15.7404 6.14832 15.7863 6.3191 15.7863C6.50008 15.7863 6.64282 15.7443 6.74733 15.6602C6.85439 15.5735 6.90792 15.4562 6.90792 15.3084Z"
                fill="#0F0046"
            />
            <path
                d="M24.5204 15.7175V16.1113H22.9031V15.7175H23.4919V13.9549H22.8687V13.6108C22.9222 13.6108 22.9719 13.6082 23.0178 13.6031C23.0637 13.5955 23.107 13.5866 23.1478 13.5764C23.1962 13.5611 23.2396 13.5432 23.2778 13.5228C23.3186 13.4999 23.353 13.4731 23.381 13.4425C23.4193 13.4018 23.4473 13.361 23.4652 13.3202C23.483 13.2794 23.4919 13.2373 23.4919 13.194H23.9316V15.7175H24.5204Z"
                fill="#0F0046"
            />
            <path
                d="M23.5106 24.8194C23.4494 24.8194 23.3895 24.8156 23.3309 24.8079C23.2748 24.8028 23.22 24.7952 23.1665 24.785C23.0951 24.7697 23.0327 24.7519 22.9791 24.7315C22.9256 24.7111 22.881 24.6881 22.8453 24.6626V24.2268C22.8836 24.2573 22.9294 24.2854 22.983 24.3109C23.0365 24.3338 23.0977 24.3555 23.1665 24.3759C23.2226 24.3912 23.2799 24.4039 23.3385 24.4141C23.3997 24.4218 23.4609 24.4256 23.5221 24.4256C23.698 24.4256 23.8407 24.381 23.9503 24.2918C24.0599 24.2025 24.1147 24.0853 24.1147 23.94C24.1147 23.782 24.0548 23.6647 23.935 23.5882C23.8178 23.5092 23.6342 23.4697 23.3844 23.4697H23.1015V23.0835H23.3844C23.5883 23.0835 23.754 23.0428 23.8815 22.9612C24.0089 22.8771 24.0727 22.7598 24.0727 22.6094C24.0727 22.4794 24.028 22.3813 23.9388 22.315C23.8522 22.2462 23.726 22.2118 23.5603 22.2118C23.5017 22.2118 23.4431 22.2156 23.3844 22.2233C23.3284 22.2309 23.271 22.2436 23.2124 22.2615C23.1486 22.2793 23.09 22.2997 23.0365 22.3227C22.9855 22.3456 22.9396 22.3711 22.8988 22.3991V21.9747C22.983 21.9263 23.09 21.8881 23.22 21.86C23.3526 21.832 23.4851 21.818 23.6177 21.818C23.8955 21.818 24.1147 21.8842 24.2753 22.0168C24.4384 22.1493 24.52 22.329 24.52 22.5559C24.52 22.7165 24.4818 22.858 24.4053 22.9803C24.3314 23.1001 24.2256 23.1893 24.088 23.2479V23.2556C24.2383 23.304 24.3543 23.3881 24.4359 23.5079C24.52 23.6277 24.5621 23.7756 24.5621 23.9515C24.5621 24.2166 24.4652 24.4281 24.2715 24.5862C24.0778 24.7417 23.8241 24.8194 23.5106 24.8194Z"
                fill="#0F0046"
            />
            <path
                d="M15.2001 24.7773V24.0929H13.946V23.7259L15.1198 21.86H15.6398V23.7259H15.9763V24.0929H15.6398V24.7773H15.2001ZM14.4125 23.7259H15.2001V22.3571H15.1886C15.1784 22.3851 15.1644 22.417 15.1466 22.4527C15.1287 22.4883 15.0612 22.6043 14.9439 22.8006L14.401 23.7144L14.4125 23.7259Z"
                fill="#0F0046"
            />
            <path
                d="M7.20165 22.2883L6.09284 24.7773H5.63785L6.7696 22.2653L6.75813 22.2538H5.42756V21.86H7.20165V22.2883Z"
                fill="#0F0046"
            />
            <mask id="path-16-inside-1_11_9077" fill="white">
                <rect width="30" height="29" rx="1.71051" />
            </mask>
        </svg>
    );
}
