import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/styles';
import circleCloseIcon from '../../assets/circleClose.png';
import { Status } from '../../models/Status';

const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 360,
    bgcolor: 'background.paper',
    borderRadius: '24px',
    boxShadow: 24,
    p: 4
};

const Logo = styled('img')(() => ({
    objectFit: 'inherit',
    width: '48px',
    height: '48px',
    marginLeft: 'auto',
    float: 'right'
}));

const RootModal = styled(Box)(() => ({
    '@media (max-width:320px)': {
        width: '130px'
    },
    '@media (min-width:320px)': {
        width: '315px',
        maxHeight: '555px',
        overflowY: 'scroll'
    },
    '@media (min-width:480px)': {
        width: '430px',
        maxHeight: '555px',
        overflowY: 'scroll'
    },
    '@media (min-width:600px)': {
        width: '590px'
    },
    '@media (min-width:1025px)': {
        width: '900px',
        overflow: 'auto'
    },
    '@media (min-width:1281px)': {
        width: '1100px'
    }
}));

const ModalButtons = styled(Button)(() => ({
    '@media (max-width: 598px)': {
        width: '100%',
        marginTop: '2%'
    },
    '@media (min-width: 598px)': {
        width: '30%'
    }
}));

const ButtonContainer = styled(Box)({
    '@media (max-width: 598px)': {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    '@media (min-width: 598px)': {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row'
    }
});

interface UpdateStatusModalProps {
    isModelOpen: boolean;
    setModelOpen: Function;
    currentStatus: Status;
    onCancel: Function;
    onChange: Function;
}

export function UpdateStatusModal({
    isModelOpen,
    setModelOpen,
    currentStatus,
    onCancel,
    onChange
}: UpdateStatusModalProps): JSX.Element {
    const { t } = useTranslation();

    const body = (
        <RootModal sx={style}>
            <Logo
                src={circleCloseIcon}
                alt="circleCloseIcon"
                onClick={(): void => {
                    setModelOpen(false);
                }}
            />
            <Box sx={{ p: 4 }}>
                <Typography
                    variant="h3"
                    color="#374653"
                    sx={{
                        marginBottom: '20px',
                        textAlign: 'center'
                    }}>
                    {t('changeStatusTitle', { status: currentStatus })}
                </Typography>
                <ButtonContainer
                    mt={3}
                    mb={6}
                    sx={{ display: 'flex', justifyContent: 'center', marginBottom: '0px' }}>
                    <ModalButtons
                        variant="outlined"
                        sx={{
                            border: '1px solid #374653',
                            boxShadow: '0px 0px 0px 2px rgba(92, 102, 112, 0.6)',
                            borderRadius: '12px',
                            width: '30%',
                            marginRight: '5%'
                        }}
                        onClick={(): void => onCancel()}>
                        <Typography variant="subtitle1" color="#374653">
                            {t('toCancel')}
                        </Typography>
                    </ModalButtons>
                    <ModalButtons
                        variant="contained"
                        sx={{
                            border: '1px solid #8B004B',
                            boxShadow: '0px 0px 0px 2px rgba(208, 0, 112, 0.4)',
                            borderRadius: '12px',
                            width: '30%',
                            backgroundColor: '#D00070',
                            '&:hover': {
                                backgroundColor: '#D00070'
                            }
                        }}
                        onClick={(): Promise<void> => onChange()}>
                        <Typography variant="subtitle1" color="#FFFFFF">
                            {currentStatus === Status.ACTIVE ? t('inactive') : t('active')}
                        </Typography>
                    </ModalButtons>
                </ButtonContainer>
            </Box>
        </RootModal>
    );

    return (
        <div>
            <Modal
                open={isModelOpen}
                onClose={(): void => {
                    setModelOpen(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                {body}
            </Modal>
        </div>
    );
}
