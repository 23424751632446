import React, { Component, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { makeStyles, createStyles } from '@mui/styles';
import { blueGrey } from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { useLongPress } from 'use-long-press';
import PersonIcon from '@mui/icons-material/Person';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, IconButton, Paper, Grid } from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import '../../i18n/config';
import closeIcon from '../../assets/closeIcon.png';
import { getModalFormQuestions } from '../../services/viewFunctions';
import { ExerciseModalForm } from '../../models/ExerciseModalForm';
import { InputComponent } from '../../components/FormComponents/InputComponent';
import { SelectComponent } from '../../components/SelectComponent';
import { FullScreenModal } from '../../components/FullScreenModal';
import { PatientHomeViewData } from '../../models/PatientHomeViewData';
import { ExerciseType } from '../../models/ExerciseType';
import { MemoryExerciseViewData } from '../../models/MemoryExerciseViewData';
import { ReviewExerciseAlerts } from './ReviewExerciseAlerts';
import { ExerciseItem } from '../../models/ExerciseItem';
import { updateCompletedExercises } from '../../services/cloudFirestore';
import { UserContext } from '../../providers/UserProvider';
import { Exercise } from '../../models/Exercise';
import { Roles } from '../../models/Roles';
import { getImageKitUrlFrom, strDateTolongStr, translateDate } from '../../utilities/utils';
import { UserDataProp } from '../../models/userDataProp';
import { User } from '../../models/User';
import { DatePickerCustom } from '../../components/DatePickerCustom';

const useStyles = makeStyles(() =>
    createStyles({
        btnLayout: {
            width: '100%',
            marginLeft: '0px',
            '@media (max-width: 598px)': {
                cursor: 'pointer',
                width: '40%',
                margin: '16px 5px 30px 5px'
            },
            '@media (min-width: 1024px)': {
                cursor: 'pointer',
                width: '40%',
                margin: '16px 5px 30px 5px'
            }
        },
        input: {
            color: '#002F55'
        },
        selectLayout: {
            width: '100%',
            marginTop: '0px',
            color: 'rgba(0, 47, 85, 1)',
            backgroundColor: '#D8D7D766',
            fontSize: '1.2rem'
        },
        selectPlaceholder: {
            width: '100%',
            marginTop: '0px',
            color: 'rgba(172, 188, 199, 1)'
        },
        fontBlue: {
            '& .MuiOutlinedInput-input': {
                color: '#004680 !important'
            }
        },
        paper: {
            backgroundColor: '#D8D7D766',
            padding: '5px 0 5px 8px',
            minHeight: '40px'
        },
        parentContentDiv: {
            width: '90%',
            margin: '3% 5%',
            textAlign: 'left',
            maxHeight: '90vh',
            minHeight: '75vh',
            overflowX: 'hidden',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        },
        contentDiv: {
            maxHeight: '90vh',
            minHeight: '65vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around'
        },
        infoAnimation: {
            animationName: `$infoAnimation2`,
            animationDuration: '9s',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'linear',
            animationDelay: '5s'
            // animation: `$infoAnimation 5s ease-in-out 2s 1.5 both`
        },
        '@keyframes infoAnimation': {
            '0%': {
                transform: 'scale(1)'
            },
            '25%': {
                transform: 'scale(0.7)'
            },
            '50%': {
                transform: 'scale(1)'
            },
            '75%': {
                transform: 'scale(0.7)'
            },
            '100%': {
                transform: 'scale(1)'
            }
        },
        '@keyframes infoAnimation2': {
            '0%, 20%, 40%, 60%, 80%, 100%': {
                transform: 'scale(1)'
            },
            '10%, 30%, 50%': {
                transform: 'scale(0.7)'
            }
        }
    })
);

const LogoClose = styled.img`
    height: 50px;
    background-color: #fbfbfb;
    border: 1px solid #e3e6e8;
    padding: 12px;
    border-radius: 5px;
    cursor: pointer;
`;

const NextExerciseButton = styled(Button)(() => ({
    border: '3px solid #D00070',
    fontSize: '24px',
    lineHeight: '29px',
    color: '#D00070',
    padding: '7%',
    margin: '15% 0%',
    '&:hover': {
        border: '3px solid #D00070'
    }
}));

interface FormModalProps {
    exerciseItemList?: ExerciseItem[];
    closeModal: Function;
    exerciseReceived: ExerciseItem;
    exercise: Exercise | undefined;
    difficultyLevel: number;
    displayInfo?: boolean;
}

export function FormModalAlert({
    exerciseItemList,
    closeModal,
    exerciseReceived,
    exercise,
    difficultyLevel,
    displayInfo = false
}: FormModalProps): JSX.Element {
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [openFormModal, setOpenFormModal] = useState(true);
    const [openReview, setOpenReview] = useState(false);
    const [date, setDate] = useState('');
    const [personOnPicture, setPersonOnPicture] = useState<string[]>([]);
    const [showInfo, setShowInfo] = useState(
        !!(displayInfo && (exerciseReceived.anecdote || exerciseReceived.imageDetails.length))
    );

    const updateMemoryExerciseCompleted = async (id: string): Promise<void> => {
        if (user && user.id && exercise) {
            await updateCompletedExercises(id, user, exercise);
            if (exercise) {
                history.push({
                    pathname: `/souven'in/${exercise.id}`,
                    state: { exercisesItem: exerciseItemList, exercises: exercise }
                });
            }
        }
    };

    const updateReorderExerciseCompleted = async (id: string): Promise<void> => {
        if (user && user.id && exercise) {
            await updateCompletedExercises(id, user, exercise);
            if (exercise) {
                history.push({
                    pathname: `/ordon'in`,
                    state: { exerciseDone: true, exercises: exercise }
                });
            }
        }
    };

    const updatePuzzleExerciseCompleted = async (id: string): Promise<void> => {
        if (user && user.id && exercise) {
            setOpenReview(false);
            await updateCompletedExercises(id, user, exercise);
            if (exercise.exerciseType === ExerciseType.PUZZLE) {
                setOpenReview(true);
            } else {
                setOpenFormModal(false);
                history.push({
                    pathname: `/souven'in/${exercise.id}`,
                    state: { exercises: exerciseItemList }
                });
            }
        }
    };

    const onExerciseSelect = async (exerciseItem: ExerciseItem): Promise<void> => {
        if (exercise?.exerciseType) {
            switch (exercise.exerciseType) {
                case ExerciseType.MEMORY:
                    if (exerciseItem.id) {
                        updateMemoryExerciseCompleted(exerciseItem.id);
                    }
                    break;
                case ExerciseType.PUZZLE:
                    if (exerciseItem.id) {
                        updatePuzzleExerciseCompleted(exerciseItem.id);
                    }
                    break;
                case ExerciseType.ORDER:
                    if (exerciseItem.id) {
                        updateReorderExerciseCompleted(exerciseItem.id);
                    }
                    break;
                default:
                    history.push(`/404}`);
            }
        }
    };

    const [exerciseModalForm, setExerciseModalQuestions] = useState<ExerciseModalForm>({
        firstQuestion: '',
        secondQuestion: '',
        thirdQuestion: '',
        fourthQuestion: ''
    });

    // Open the summary of the picture
    useEffect(() => {
        const exerciseModalQuestions = getModalFormQuestions();
        if (exerciseModalQuestions) {
            setExerciseModalQuestions(exerciseModalQuestions);
        }
    }, []);

    const fetchPersonInPhoto = (): void => {
        const personNameList: string[] = [];
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < exerciseReceived.imageDetails.length; i += 1) {
            const personName = `${exerciseReceived.imageDetails[i].firstName} ${exerciseReceived.imageDetails[i].lastName} - ${exerciseReceived.imageDetails[i].relation}`;
            personNameList.push(personName);
        }
        setPersonOnPicture(personNameList);
    };

    useEffect(() => {
        if (exerciseReceived.photoCapturedOn) {
            const dateToTranslate = exerciseReceived.photoCapturedOn;
            setDate(translateDate(dateToTranslate?.toString()));
        }
        fetchPersonInPhoto();
    }, []);

    // Close the summary modal and make appear the review for patient
    const handleCloseFormModal = (): void => {
        onExerciseSelect(exerciseReceived);
    };

    const peopleList = (): JSX.Element[] => {
        return exerciseReceived.imageDetails.map(
            (people): JSX.Element => {
                return (
                    <Typography
                        key={people.id}
                        sx={{
                            color: 'white',
                            fontSize: '20px !important',
                            paddingBottom: '20px',
                            textAlign: 'left',
                            lineHeight: '1.75rem',
                            paddingLeft: '40px',
                            paddingRight: '40px',
                            fontFamily: 'Luciole-Regular',
                            display: 'inline-block',
                            alignItems: 'center',
                            flex: '1',
                            flexWrap: 'wrap'
                        }}>
                        <PersonIcon
                            style={{
                                backgroundColor: '#FFF',
                                color: '#0F0046',
                                borderRadius: '5px',
                                height: '20px',
                                width: '20px'
                            }}
                        />
                        &nbsp;
                        {people.firstName} {people.lastName} - {people.relation}
                    </Typography>
                );
            }
        );
    };

    const bindLongPressInfo = useLongPress(
        () => {
            setShowInfo(true);
        },
        { threshold: 1000 }
    );

    const informationBtn = (): JSX.Element => {
        return (
            <button
                className={classes.infoAnimation}
                type="button"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...bindLongPressInfo()}
                onClick={(): void => setShowInfo(true)}
                style={{
                    border: 'solid 5px white',
                    display: showInfo ? 'none' : 'block',
                    minWidth: '70px',
                    height: '70px',
                    borderRadius: '35px',
                    backgroundColor: '#FF0089',
                    position: 'absolute',
                    right: '50px',
                    top: '30px',
                    cursor: 'pointer'
                }}>
                <Typography
                    sx={{
                        color: 'white',
                        fontSize: '2rem !important',
                        fontWeight: '700'
                    }}
                    variant="body1">
                    {t('i')}
                </Typography>
            </button>
        );
    };

    const bindLongPressClose = useLongPress(
        () => {
            onExerciseSelect(exerciseReceived);
        },
        { threshold: 1000 }
    );

    const bindLongPressInfoClose = useLongPress(
        () => {
            setShowInfo(false);
        },
        { threshold: 1000 }
    );

    const modalForm = (
        <div style={{ display: 'flex', width: '100%', minHeight: '100vh' }}>
            {exerciseReceived.anecdote || exerciseReceived.imageDetails.length
                ? informationBtn()
                : ''}
            {/* <NextExerciseButton
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...bindLongPressClose()}
                onClick={(): void => {
                    onExerciseSelect(exerciseReceived);
                }}
                style={{
                    marginTop: '0%',
                    marginBottom: '0%',
                    height: '60px',
                    width: '60px',
                    padding: '0%',
                    position: 'absolute',
                    top: '30px',
                    left: '50px',
                    border: '1px solid #CDCDCD'
                }}
                fullWidth
                variant="outlined"
                size="large"
                sx={{ fontFamily: 'Luciole-Regular' }}>
                <CloseIcon sx={{ color: '#FF0089', fontSize: '36px' }} />
            </NextExerciseButton> */}
            <div
                style={{
                    width: showInfo ? '67%' : '100%',
                    display: 'flex'
                }}>
                <div style={{ width: '100%' }}>
                    <div style={{ width: '100%', marginTop: '40px' }}>
                        <Typography
                            color="#3387CC"
                            sx={{
                                fontFamily: user?.role === Roles.PATIENT ? 'Luciole-Regular' : '',
                                paddingBottom: '10px',
                                textAlign: 'center',
                                fontSize: '40px !important',
                                fontWeight: '600'
                            }}>
                            {exerciseReceived.answer}
                        </Typography>
                        <Typography
                            color="#000"
                            sx={{
                                fontFamily: user?.role === Roles.PATIENT ? 'Luciole-Regular' : '',
                                paddingBottom: '30px',
                                textAlign: 'center',
                                fontSize: '26px !important',
                                color: '#0F0046'
                            }}>
                            {`${
                                exerciseReceived.photoCapturedOn &&
                                exerciseReceived.photoCapturedPlace &&
                                exerciseReceived.photoCapturedPlace.replaceAll(' ', '') !== ''
                                    ? `${strDateTolongStr(date)} ${t('-')} ${
                                          exerciseReceived.photoCapturedPlace
                                      }`
                                    : `${
                                          exerciseReceived.photoCapturedOn
                                              ? strDateTolongStr(date)
                                              : `${
                                                    exerciseReceived.photoCapturedPlace
                                                        ? exerciseReceived.photoCapturedPlace
                                                        : ''
                                                }`
                                      }`
                            }`}
                        </Typography>
                    </div>

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        {exerciseReceived && typeof exerciseReceived.exerciseImage === 'string' && (
                            <img
                                src={getImageKitUrlFrom(exerciseReceived.exerciseImage)}
                                alt="CloseButton"
                                style={{
                                    width: 'calc(100vh - 260px)',
                                    height: 'calc(100vh - 260px)',
                                    objectFit: 'cover',
                                    maxHeight: '85vh'
                                }}
                            />
                        )}
                    </div>
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            verticalAlign: 'middle'
                        }}>
                        <NextExerciseButton
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...bindLongPressClose()}
                            onClick={(): void => {
                                onExerciseSelect(exerciseReceived);
                            }}
                            style={{
                                marginTop: '0%',
                                marginBottom: '0%',
                                height: '61px',
                                width: '400px',
                                padding: '0%',
                                position: 'fixed',
                                bottom: '10px'
                            }}
                            fullWidth
                            variant="outlined"
                            size="large"
                            sx={{ fontFamily: 'Luciole-Regular' }}>
                            {t('moveOn')}
                        </NextExerciseButton>
                    </div>
                </div>
            </div>
            <div
                style={{
                    display: showInfo ? 'block' : 'none',
                    width: '33%',
                    backgroundColor: '#0F0046',
                    color: '#FFF',
                    justifyContent: 'center'
                }}>
                <div style={{ width: '100%' }}>
                    <button
                        type="button"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...bindLongPressInfoClose()}
                        onClick={(): void => setShowInfo(false)}
                        style={{
                            border: 'none',
                            marginTop: '20px',
                            marginLeft: '20px',
                            backgroundColor: 'unset',
                            cursor: 'pointer'
                        }}>
                        <Typography
                            sx={{
                                color: 'white',
                                fontSize: '2rem !important',
                                fontWeight: '700'
                            }}
                            variant="body1">
                            <KeyboardDoubleArrowRightIcon
                                sx={{ color: '#FFF', fontSize: '3rem' }}
                            />
                        </Typography>
                    </button>
                </div>
                <div
                    style={{
                        width: '100%',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        height: 'calc(100% - 83px)'
                    }}>
                    <div
                        style={{
                            margin: '0',
                            position: 'absolute',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            msTransform: 'translateY(-50%)',
                            width: '33%',
                            flexDirection: 'row'
                        }}>
                        {exerciseReceived.anecdote ? (
                            <Typography
                                sx={{
                                    // display: 'inline-flex',
                                    color: 'white',
                                    fontSize: '20px !important',
                                    paddingBottom: exerciseReceived.imageDetails.length
                                        ? '50px'
                                        : '20px',
                                    textAlign: 'left',
                                    lineHeight: 'initial',
                                    paddingLeft: '40px',
                                    paddingRight: '40px',
                                    fontFamily: 'Luciole-Regular',
                                    flex: '1',
                                    flexWrap: 'wrap'
                                }}>
                                {exerciseReceived.anecdote}
                            </Typography>
                        ) : (
                            ''
                        )}
                        {exerciseReceived.imageDetails.length ? peopleList() : ''}
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div>
            <FullScreenModal
                bodyText={modalForm}
                isModelOpen={openFormModal}
                title=""
                buttonText=""
            />
            {openReview && exercise && (
                <ReviewExerciseAlerts
                    exercise={exercise}
                    exerciseType={ExerciseType.PUZZLE}
                    difficultyLevel={difficultyLevel}
                />
            )}
        </div>
    );
}
