import React from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const InputLabelDiv = styled(Typography)(() => ({
    width: '47%',
    marginTop: '2%',
    display: 'inline-block',
    color: '#374653'
}));

interface InputLabelInterface {
    position: string;
    label: string;
    width?: string;
}

export const InputLabel = ({ position, label, width }: InputLabelInterface): JSX.Element => {
    const { t } = useTranslation();

    return (
        <InputLabelDiv
            sx={{
                textAlign: 'left',
                marginRight: position === 'left' ? '6%' : '',
                width: width ?? '0'
            }}>
            {label === 'socialProfCategory' ? `(ex) ${t(label)}` : t(label)}
        </InputLabelDiv>
    );
};
