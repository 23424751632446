import firestore, { Timestamp } from 'firebase/firestore';
import { FormData } from './FormData';
import { FormField } from './FormField';
import { Status } from './Status';

const optionList: string[] = [
    "Directeur/trice d'établissement",
    '(Neuro) Psychologue',
    'Neurologue',
    'Médecin coordonnateur',
    'Gériatre',
    'Psychiatre',
    'Infirmier(e) / Cadre de santé',
    'Assistant(e) de soin en gérontologie / AMP',
    'Ergothérapeute',
    'Animateur/trice en gérontologie',
    'Autre'
];

export class Establishment implements FormData {
    id?: string | undefined;

    establishmentName?: string | undefined;

    lastName?: string | undefined;

    firstName?: string | undefined;

    email?: string | undefined;

    mobile?: string | undefined;

    fonction?: string | undefined;

    dob?: Timestamp | undefined;

    address?: string | undefined;

    pincode?: string | undefined;

    city?: string | undefined;

    establishmentCode?: string | undefined;

    maxPatients?: string | undefined;

    maxTherapists?: string | undefined;

    maxFamily?: string | undefined;

    startDate?: Timestamp | undefined;

    endDate?: Timestamp | undefined;

    status?: Status | undefined;

    constructor(
        id?: string,
        lastName?: string,
        firstName?: string,
        email?: string,
        mobile?: string,
        dob?: Timestamp,
        fonction?: string,
        establishmentName?: string,
        establishmentCode?: string,
        startDate?: Timestamp,
        endDate?: Timestamp,
        address?: string,
        pincode?: string,
        city?: string,
        maxPatients?: string,
        maxTherapists?: string,
        maxFamily?: string,
        status?: Status
    ) {
        this.id = id;
        this.lastName = lastName;
        this.firstName = firstName;
        this.email = email;
        this.mobile = mobile;
        this.dob = dob;
        this.fonction = fonction;
        this.establishmentName = establishmentName;
        this.establishmentCode = establishmentCode;
        this.startDate = startDate;
        this.endDate = endDate;
        this.address = address;
        this.pincode = pincode;
        this.city = city;
        this.maxFamily = maxFamily;
        this.maxPatients = maxPatients;
        this.maxTherapists = maxTherapists;
        this.status = status;
    }

    firstStepFormFields: FormField[] = [
        new FormField({
            placeholder: 'nameEstablishment',
            field: 'establishmentName',
            required: true
        }),
        new FormField({ placeholder: 'mobile', field: 'mobile', required: true }),
        new FormField({
            placeholder: 'establishmentFirstName',
            field: 'firstName',
            required: true
        }),
        new FormField({
            placeholder: 'emailPlaceholder',
            field: 'email',
            required: true,
            type: 'email'
        }),
        new FormField({
            placeholder: 'establishmentLastName',
            field: 'lastName',
            required: true
        }),
        new FormField({ placeholder: 'address', field: 'address', required: true }),
        new FormField({ placeholder: 'dob', field: 'dob', required: true, type: 'date' }),
        new FormField({
            placeholder: 'pincode',
            field: 'pincode',
            width: 'HALF',
            required: true
        }),
        new FormField({ placeholder: 'city', field: 'city', width: 'HALF', required: true }),
        new FormField({
            placeholder: 'fonction',
            field: 'fonction',
            required: true,
            type: 'select',
            options: optionList
        })
    ];

    secondStepFormFields: FormField[] = [
        new FormField({
            placeholder: 'startDate',
            field: 'startDate',
            required: true,
            type: 'date'
        }),
        new FormField({ placeholder: 'endDate', field: 'endDate', required: true, type: 'date' }),
        new FormField({
            placeholder: 'maxPatients',
            field: 'maxPatients',
            required: true,
            type: 'number'
        }),
        new FormField({
            placeholder: 'maxTherapists',
            field: 'maxTherapists',
            required: true,
            type: 'number'
        }),
        new FormField({
            placeholder: 'maxFamily',
            field: 'maxFamily',
            required: true,
            type: 'number'
        })
    ];
}

export const EstablishmentConverter: firestore.FirestoreDataConverter<Establishment> = {
    toFirestore(establishment) {
        return {
            id: establishment.id,
            lastName: establishment.lastName,
            firstName: establishment.firstName,
            email: establishment.email,
            mobile: establishment.mobile,
            dob: establishment.dob,
            fonction: establishment.fonction,
            establishmentName: establishment.establishmentName,
            establishmentCode: establishment.establishmentCode,
            startDate: establishment.startDate,
            endDate: establishment.endDate,
            address: establishment.address,
            pincode: establishment.pincode,
            city: establishment.city,
            maxPatients: establishment.maxPatients,
            maxTherapists: establishment.maxTherapists,
            maxFamily: establishment.maxFamily,
            status: establishment.status
        };
    },
    fromFirestore(snapshot, options): Establishment {
        const data = snapshot.data(options);
        return new Establishment(
            snapshot.id,
            data.lastName,
            data.firstName,
            data.email,
            data.mobile,
            data.dob,
            data.fonction,
            data.establishmentName,
            data.establishmentCode,
            data.startDate,
            data.endDate,
            data.address,
            data.pincode,
            data.city,
            data.maxPatients,
            data.maxTherapists,
            data.maxFamily,
            data.status
        );
    }
};
