import React, { useEffect } from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import { AppBar, Toolbar } from '@mui/material';
import logo from '../assets/logo.png';

const Logo = styled.img`
    height: 27px;
`;
const StimulinAppBar = styled(AppBar)({
    background: '#fff',
    height: '78px'
});
const AppToolBar = styled(Toolbar)({
    justifyContent: 'center',
    height: '78px'
});

export function HomeHeader(): JSX.Element {
    useEffect(() => {
        const topOfPage = document.getElementById('top');
        if (topOfPage) {
            topOfPage.scrollIntoView();
        }
    }, []);

    return (
        <Box id="top">
            <StimulinAppBar position="static">
                <AppToolBar>
                    <Logo src={logo} alt="logo" style={{ marginLeft: '15px' }} />
                </AppToolBar>
            </StimulinAppBar>
        </Box>
    );
}
