import { Box, Paper, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactApexChart from 'react-apexcharts';
import {
    getCompletedExercises,
    getCompletedTherapistExercises
} from '../../services/cloudFirestore';
import { User } from '../../models/User';
import { UserContext } from '../../providers/UserProvider';
import { Roles } from '../../models/Roles';
import { UserDataProp } from '../../models/userDataProp';

interface PatientCompletedExercisesProps {
    memoryCount: number;
    memorinCount: number;
    puzzleCount: number;
    completeExerciseCount: number;
    startExerciseCount: number;
}

export function PatientCompletedExercises({
    memoryCount,
    memorinCount,
    puzzleCount,
    completeExerciseCount,
    startExerciseCount
}: PatientCompletedExercisesProps): React.ReactElement {
    const { t } = useTranslation();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [completedMemoryExercise, setCompletedMemoryExercise] = useState<number>(0);
    const [completedMemorinExercise, setCompletedMemorinExercise] = useState<number>(0);
    const [completedPuzzleExercise, setCompletedPuzzleExercise] = useState<number>(0);
    const [completedAllExercise, setCompletedAllExercise] = useState<number>(0);
    const [allExercise, setAllExercise] = useState<number>(startExerciseCount);

    useEffect(() => {
        const getUserList = async (): Promise<void> => {
            if (user && user.id && user.role === Roles.ADMIN) {
                const data = await getCompletedTherapistExercises(user.id);
                setCompletedMemoryExercise(data[0]);
                setCompletedPuzzleExercise(data[1]);
                setCompletedMemorinExercise(data[2]);
                setCompletedAllExercise(data[0] + data[1] + data[2]);
            }
        };
        getUserList();
    }, []);

    useEffect(() => {
        if (user && user.role === Roles.THERAPIST) {
            setCompletedMemoryExercise(memoryCount);
            setCompletedMemorinExercise(memorinCount);
            setCompletedPuzzleExercise(puzzleCount);
            setAllExercise(startExerciseCount);
        }
    }, [memoryCount, puzzleCount, memorinCount, startExerciseCount]);

    const chart = {
        series: [completedMemoryExercise, completedPuzzleExercise, completedMemorinExercise],
        chartOptions: {
            colors: ['#8200E8', '#00D1FF', '#0F0046'],
            labels: [t('memory'), t('puzzleTitle'), t('memorin')],
            dataLabels: {
                style: {
                    fontSize: '9.5px'
                }
            },
            plotOptions: {
                pie: {
                    donut: {
                        size: '55%'
                    }
                }
            }
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                textAlign: 'left',
                paddingTop: '30px',
                '& > :not(style)': {
                    m: 1
                }
            }}>
            <Paper
                elevation={1}
                sx={{
                    padding: '13px',
                    width: '100%',
                    display: 'flex'
                }}>
                <Box sx={{ marginRight: '50px' }}>
                    <Typography variant="h5" sx={{ fontWeight: 600 }}>
                        {t('exercisesStarted')}
                    </Typography>
                    <Typography variant="h1" m={1}>
                        {allExercise}
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="h5">
                        {t('ofWhichCompletedYears', {
                            count:
                                user && user.role === Roles.ADMIN
                                    ? completedAllExercise
                                    : completeExerciseCount
                        })}
                    </Typography>
                    <Box mx={1} flexGrow={1}>
                        <ReactApexChart
                            type="donut"
                            series={chart.series}
                            options={chart.chartOptions}
                            width="100%"
                            height={160}
                        />
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
}
