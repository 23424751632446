/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Box, Checkbox, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import { makeStyles, propsToClassKey, styled } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import { trim } from 'lodash';
import getStripe from '../lib/getStripe';
import { Header } from '../components/Header';
import { validateEmail, getPatientFamilyCode } from '../utilities/utils';
import { createUserRegistration } from '../services/cloudFirestore';
import { CustomStepper } from '../components/CustomStepper';
import info from '../assets/info.svg';
import addImageNewAccountDefault from '../assets/addImageNewAccountDefault.svg';
import addImageNewAccountPicture from '../assets/addImageNewAccountPicture.svg';
import missingfield from '../assets/missingfield.jpg';
import emailNotValid from '../assets/emailNotValid.jpg';
import imgPasswordNotValidLength from '../assets/imgPasswordNotValidLength.jpg';
import zipCodeNotValid from '../assets/zipCodeNotValid.jpg';
import starInSquare from '../assets/starInSquare.png';
import confusion from '../assets/confusion.png';
import goodStar from '../assets/goodStar.png';
import activityModel from '../assets/activityModel.png';
import preloadTheme from '../assets/preloadTheme.png';
import selectChoise from '../assets/selectChoise.jpg';
import circleCloseIcon from '../assets/circleClose.png';
import { getThemeExamples } from '../services/viewFunctions';

const useStyles = makeStyles(() => ({
    root: {
        borderColor: '#D9D9D9',
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#D9D9D9 !important'
        }
    },
    inputComponentLayout: {
        marginTop: '5%'
    },
    input: {
        height: '35px',
        width: '100%',
        borderRadius: '10px',
        border: '1px solid #D9D9D9',
        padding: '0 10px',
        margin: '0 0 5% 0',
        fontSize: '14px',
        color: '#002F55',
        '&:focus': {
            outline: 'none',
            border: '1px solid #D9D9D9'
        },
        '&:hover': {
            border: '1px solid #D9D9D9'
        },
        '&::placeholder': {
            color: '#002F55',
            opacity: 1
        }
    }
}));

const Title = styled(Typography)({
    display: 'flex',
    alignContent: 'space-around',
    alignItems: 'center',
    justifyContent: 'center'
});

interface NewAccountState {
    picture: string;
    name: string;
    lastName: string;
    dob: string;
    email: string;
    password: string;
    verifyPassword: string;
    cgvu: boolean;
    showPassword: boolean;
}

interface NewAccountStatePatient {
    picture: string;
    gender: string;
    name: string;
    lastName: string;
    dob: string;
    email: String;
    addr: String;
    compAddr: String;
    zipCode: Number;
    city: String;
    kinship: string;
}

interface NewAccountStatePatient2 {
    id: String;
    password: String;
    verifyPassword: String;
    familyCode: String;
}

export function NewAccount(): JSX.Element {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();

    const [hiddeInfo, setHiddeInfo] = useState<boolean>(false);
    const [hiddeMisseField, setHiddeMisseField] = useState<boolean>(false);
    const [hiddeMisseFieldList, setHiddeMisseFieldList] = useState<string[]>([]);
    const [hiddeEmailNotValid, setHiddeEmailNotValid] = useState<boolean>(false);
    const [hiddeZipCodeNotValid, setHiddeZipCodeNotValid] = useState<boolean>(false);
    const [hiddePasswordNotValidLength, setHiddePasswordNotValidLength] = useState<boolean>(false);
    const [hiddeFormulaInfo, setHiddeFormulaInfo] = useState<boolean>(false);
    const [hiddeSelectChoise, setHiddeSelectChoise] = useState<boolean>(false);
    const [passwordNotValidLength, setPasswordNotValidLength] = useState<number>(0);
    const [hiddePasswordNotConfirm, setHiddePasswordNotConfirm] = useState<boolean>(false);
    const [formula, setFormula] = useState<string>('');
    const [hiddePhoto, setHiddePhoto] = useState<boolean>(false);
    const [step, setStep] = useState<number>(0);
    const [infoTitle, setInfoTitle] = useState<string>('');
    const [infoDesc, setInfoDesc] = useState<string>('');
    const [infoDesc2, setInfoDesc2] = useState<string | null>(null);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const inputFile = React.useRef<HTMLInputElement>(null);
    const inputFilePatient = React.useRef<HTMLInputElement>(null);

    const [values, setValues] = useState({
        picture: '',
        name: '',
        lastName: '',
        dob: '',
        email: '',
        password: '',
        verifyPassword: '',
        showPassword: true,
        cgvu: false,
        prop: ''
    });
    const [valuesPatient, setValuesPatient] = useState({
        picture: '',
        gender: 'Femme',
        name: '',
        lastName: '',
        dob: '',
        email: '',
        addr: '',
        compAddr: '',
        zipCode: '',
        city: '',
        kinship: ''
    });

    const [familyPictureFile, setFamilyPictureFile] = useState<File | null>(null);
    const [patientPictureFile, setPatientPictureFile] = useState<File | null>(null);

    const [valuesPatient2, setValuesPatient2] = useState({
        id: '',
        password: '',
        verifyPassword: '',
        familyCode: ''
    });

    useEffect(() => {
        if (step === 2) {
            setValuesPatient2({
                ...valuesPatient2,
                id: `${valuesPatient.name}.${valuesPatient.lastName}.${valuesPatient.dob.slice(
                    0,
                    4
                )}`,
                familyCode: getPatientFamilyCode()
            });
        }
    }, [step]);

    const handleChange = (prop: keyof NewAccountState) => (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleChangePatient = (prop: keyof NewAccountStatePatient) => (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        setValuesPatient({ ...valuesPatient, [prop]: event.target.value });
    };

    const handleChangePatient2 = (prop: keyof NewAccountStatePatient2) => (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        setValuesPatient2({ ...valuesPatient2, [prop]: event.target.value });
    };

    const handleClickShowPassword = (): void => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const sendPayement = async (): Promise<void> => {
        const stripe = await getStripe();
        const { error } = await stripe.redirectToCheckout({
            lineItems: [
                {
                    price: 'price_1MCLeUDvPQw4X9qPcLy5WAH2',
                    quantity: 1
                }
            ],
            mode: 'subscription',
            successUrl: `http://localhost:3000/success`,
            cancelUrl: `http://localhost:3000/cancel`,
            customerEmail: 'customer@email.com'
        });
    };

    const nextStep = async (): Promise<void> => {
        switch (step) {
            case 0:
                if (
                    // trim(values.picture) === '' ||
                    trim(values.name) === '' ||
                    trim(values.lastName) === '' ||
                    trim(values.dob) === '' ||
                    trim(values.email) === '' ||
                    trim(values.password) === '' ||
                    trim(values.verifyPassword) === '' ||
                    values.cgvu === false
                ) {
                    setHiddeMisseFieldList([]);
                    if (trim(values.name) === '') {
                        setHiddeMisseFieldList((prev) => [...prev, t('newAccount.name')]);
                    }
                    if (trim(values.lastName) === '') {
                        setHiddeMisseFieldList((prev) => [...prev, t('newAccount.lastName')]);
                    }
                    if (trim(values.dob) === '') {
                        setHiddeMisseFieldList((prev) => [...prev, t('newAccount.dob')]);
                    }
                    if (trim(values.email) === '') {
                        setHiddeMisseFieldList((prev) => [...prev, t('newAccount.email')]);
                    }
                    if (trim(values.password) === '') {
                        setHiddeMisseFieldList((prev) => [...prev, t('newAccount.password')]);
                    }
                    if (trim(values.verifyPassword) === '') {
                        setHiddeMisseFieldList((prev) => [...prev, t('newAccount.verifyPassword')]);
                    }
                    if (values.cgvu === false) {
                        setHiddeMisseFieldList((prev) => [...prev, t('newAccount.cgvu')]);
                    }
                    setHiddeMisseField(true);
                    break;
                }
                if (!validateEmail(values.email)) {
                    setHiddeEmailNotValid(true);
                    break;
                }
                if (values.password.length < 6) {
                    setPasswordNotValidLength(6 - values.password.length);
                    setHiddePasswordNotValidLength(true);
                    break;
                }
                if (values.password !== values.verifyPassword) {
                    setHiddePasswordNotConfirm(true);
                    break;
                }
                setStep(step + 1);
                break;
            case 1:
                if (
                    // trim(values.picture) === '' ||
                    // trim(valuesPatient.gender) === '' ||
                    trim(valuesPatient.name) === '' ||
                    trim(valuesPatient.lastName) === '' ||
                    trim(valuesPatient.dob) === '' ||
                    // trim(valuesPatient.email) === '' ||
                    trim(valuesPatient.addr) === '' ||
                    // trim(valuesPatient.compAddr) === '' ||
                    trim(valuesPatient.zipCode) === '' ||
                    trim(valuesPatient.city) === '' ||
                    trim(valuesPatient.kinship) === '' ||
                    trim(valuesPatient.kinship) === t('newAccount.patient.kinship')
                ) {
                    setHiddeMisseFieldList([]);
                    if (trim(valuesPatient.name) === '') {
                        setHiddeMisseFieldList((prev) => [...prev, t('newAccount.patient.name')]);
                    }
                    if (trim(valuesPatient.lastName) === '') {
                        setHiddeMisseFieldList((prev) => [
                            ...prev,
                            t('newAccount.patient.lastName')
                        ]);
                    }
                    if (trim(valuesPatient.dob) === '') {
                        setHiddeMisseFieldList((prev) => [...prev, t('newAccount.patient.dob')]);
                    }
                    // if (trim(valuesPatient.email) === '') {
                    //     setHiddeMisseFieldList((prev) => [...prev, t('newAccount.patient.email')]);
                    // }
                    if (trim(valuesPatient.addr) === '') {
                        setHiddeMisseFieldList((prev) => [...prev, t('newAccount.patient.addr')]);
                    }
                    // if (trim(valuesPatient.compAddr) === '') {
                    //     setHiddeMisseFieldList((prev) => [
                    //         ...prev,
                    //         t('newAccount.patient.compAddr')
                    //     ]);
                    // }
                    if (trim(valuesPatient.zipCode) === '') {
                        setHiddeMisseFieldList((prev) => [
                            ...prev,
                            t('newAccount.patient.zipCode')
                        ]);
                    }
                    if (trim(valuesPatient.city) === '') {
                        setHiddeMisseFieldList((prev) => [...prev, t('newAccount.patient.city')]);
                    }
                    if (
                        trim(valuesPatient.kinship) === t('newAccount.patient.kinship') ||
                        trim(valuesPatient.kinship) === ''
                    ) {
                        setHiddeMisseFieldList((prev) => [
                            ...prev,
                            t('newAccount.patient.kinship')
                        ]);
                    }
                    setHiddeMisseField(true);
                    break;
                }
                if (trim(valuesPatient.email) !== '' && !validateEmail(valuesPatient.email)) {
                    setHiddeEmailNotValid(true);
                    break;
                }
                if (valuesPatient.zipCode.length !== 5) {
                    setHiddeZipCodeNotValid(true);
                    break;
                }
                setStep(step + 1);
                break;
            case 2:
                if (
                    trim(valuesPatient2.password) === '' ||
                    trim(valuesPatient2.verifyPassword) === ''
                ) {
                    if (trim(valuesPatient2.password) === '') {
                        setHiddeMisseFieldList((prev) => [
                            ...prev,
                            t('newAccount.patient2.password')
                        ]);
                    }
                    if (trim(valuesPatient2.verifyPassword) === '') {
                        setHiddeMisseFieldList((prev) => [
                            ...prev,
                            t('newAccount.patient2.verifyPassword')
                        ]);
                    }
                    setHiddeMisseField(true);
                    break;
                }
                if (valuesPatient2.password.length < 6) {
                    setPasswordNotValidLength(6 - valuesPatient2.password.length);
                    setHiddePasswordNotValidLength(true);
                    break;
                }
                if (valuesPatient2.password !== valuesPatient2.verifyPassword) {
                    setHiddePasswordNotConfirm(true);
                    break;
                }
                setStep(step + 1);
                break;
            case 3:
                if (formula !== '') {
                    // save user
                    // send to db with user info
                    const result = await createUserRegistration({
                        familyPicture: familyPictureFile,
                        familyName: values.name,
                        familyLastName: values.lastName,
                        familyDob: values.dob,
                        familyEmail: values.email,
                        familyPassword: values.password,
                        patientPicture: patientPictureFile,
                        patientGender: valuesPatient.gender,
                        patientName: valuesPatient.name,
                        patientLastName: valuesPatient.lastName,
                        patientDob: valuesPatient.dob,
                        patientEmail: valuesPatient.email,
                        patientAddress: valuesPatient.addr,
                        patientCompAddr: valuesPatient.compAddr,
                        patientZipcode: valuesPatient.zipCode,
                        patientCity: valuesPatient.city,
                        patientKinship: valuesPatient.kinship,
                        patientID: valuesPatient2.id,
                        patientPassword: valuesPatient2.password,
                        patientFamilyCode: valuesPatient2.familyCode
                    });
                    if (formula === 'BASIC' && result === 'success') {
                        window.location.href = 'https://buy.stripe.com/eVa7uC7mgeWL5os5kq';
                        break;
                    }
                    if (formula === 'FAN' && result === 'success') {
                        window.location.href = 'https://buy.stripe.com/6oEaGOeOI6qfg3614b';
                        break;
                    }
                }
                setHiddeSelectChoise(true);
                // display error message
                break;
            default:
                break;
        }
    };

    const showInfo = (title: string, desc: string, desc2?: string): void => {
        setInfoTitle(title);
        setInfoDesc(desc);
        setInfoDesc2(desc2 ?? null);
        setHiddeInfo(true);
    };

    const triggerImageUpload = (): void => {
        if (inputFile && inputFile.current) {
            inputFile.current.click();
        }
    };

    const triggerImageUploadPatient = (): void => {
        if (inputFilePatient && inputFilePatient.current) {
            inputFilePatient.current.click();
        }
    };

    const updateImage = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.files) {
            // uploadFile(event.target.files[0]);
            setValues({ ...values, picture: URL.createObjectURL(event.target.files[0]) });
            setFamilyPictureFile(event.target.files[0]);
        }
    };

    const updateImagePatient = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.files) {
            setValuesPatient({
                ...valuesPatient,
                picture: URL.createObjectURL(event.target.files[0])
            });
            setPatientPictureFile(event.target.files[0]);
        }
    };

    const getNewAccount = (): React.ReactElement => {
        return (
            <Box flex={1} display="flex" flexDirection="column" height="100vh">
                <Header />
                <Box
                    flex={1}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="top">
                    {/* customStepper */}
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        width="75%"
                        paddingTop={2}
                        paddingBottom={2}
                        justifyContent="center">
                        <CustomStepper currentStep={step} steps={[1, 2, 3, 4, 5]} />
                    </Box>
                    {step === 0 && (
                        <Box
                            width="80%"
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center">
                            <Typography
                                variant="h4"
                                color="#FF0089"
                                sx={{ fontFamily: 'Inter', fontWeight: '700', fontSize: '22' }}>
                                {t('newAccountTitle')}
                            </Typography>
                            <Box
                                width="100%"
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                className={classes.inputComponentLayout}>
                                <Box sx={{ width: '100%' }}>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        sx={{ marginBottom: '5%' }}
                                        onClick={triggerImageUpload}>
                                        <input
                                            type="file"
                                            ref={inputFile}
                                            onChange={(
                                                event: React.ChangeEvent<HTMLInputElement>
                                            ): void => updateImage(event)}
                                            accept="image/*"
                                            multiple={false}
                                            style={{ display: 'none', cursor: 'pointer' }}
                                        />
                                        <Box sx={{ position: 'relative' }}>
                                            {values.picture === '' && (
                                                <img
                                                    src={addImageNewAccountDefault}
                                                    alt="addImageNewAccountDefault"
                                                    style={{
                                                        marginRight: '5px',
                                                        height: '49px',
                                                        width: '49px'
                                                    }}
                                                />
                                            )}
                                            {values.picture !== '' && (
                                                <img
                                                    src={values.picture}
                                                    alt="addImageNewAccountDefault"
                                                    style={{
                                                        marginRight: '5px',
                                                        height: '49px',
                                                        width: '49px'
                                                    }}
                                                />
                                            )}
                                            <img
                                                src={addImageNewAccountPicture}
                                                alt="addImageNewAccountPicture"
                                                style={{
                                                    marginRight: '5px',
                                                    top: '0',
                                                    right: '0',
                                                    position: 'absolute',
                                                    width: '16px',
                                                    height: '16px'
                                                }}
                                            />
                                        </Box>
                                        <Typography
                                            variant="h4"
                                            sx={{
                                                fontFamily: 'Inter',
                                                fontSize: '13px'
                                            }}>
                                            <span style={{ fontWeight: '600' }}>
                                                {t('newAccount.addPhoto1')}
                                            </span>{' '}
                                            <span style={{ fontWeight: '600', color: '#FF0089' }}>
                                                {t('newAccount.addPhoto2')}
                                            </span>{' '}
                                            <span style={{ fontWeight: '600' }}>
                                                {t('newAccount.addPhoto3')}
                                            </span>
                                            <span
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    flexWrap: 'wrap'
                                                }}>
                                                <span>{t('newAccount.addPhoto4')}</span>
                                                {'\u00A0'}
                                                <span style={{ textDecoration: 'underline' }}>
                                                    {t('newAccount.addPhoto5')}
                                                </span>
                                                {'\u00A0'}
                                                {'→ '}
                                                {'\u00A0'}
                                                <img
                                                    src={info}
                                                    alt="info"
                                                    onClick={(e): void => {
                                                        e.stopPropagation();
                                                        showInfo(
                                                            t('newAccount.infoPictureTitle'),
                                                            t('newAccount.infoPictureDesc')
                                                        );
                                                    }}
                                                />
                                            </span>
                                        </Typography>
                                    </Box>
                                </Box>
                                <input
                                    className={classes.input}
                                    type="text"
                                    placeholder={t('newAccount.name')}
                                    value={values.name}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                                        handleChange('name')(event)
                                    }
                                />
                                <input
                                    className={classes.input}
                                    type="text"
                                    placeholder={t('newAccount.lastName')}
                                    value={values.lastName}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                                        handleChange('lastName')(event)
                                    }
                                />
                                <Box
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        verticalAlign: 'middle'
                                    }}>
                                    <input
                                        className={classes.input}
                                        type={values.dob === '' ? 'text' : 'date'}
                                        placeholder={t('newAccount.dob')}
                                        value={values.dob}
                                        onFocus={(e): void => {
                                            e.target.type = 'date';
                                        }}
                                        onBlur={(e): void => {
                                            if (e.target.value === '') {
                                                e.target.type = 'text';
                                            }
                                        }}
                                        onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>
                                        ): void => handleChange('dob')(event)}
                                    />
                                    <img
                                        src={info}
                                        alt="info"
                                        style={{ margin: '0 0 5% 5px' }}
                                        onClick={(): void =>
                                            showInfo(
                                                t('newAccount.infoDobTitle'),
                                                t('newAccount.infoDobDesc1'),
                                                t('newAccount.infoDobDesc2')
                                            )
                                        }
                                    />
                                </Box>
                                <input
                                    className={classes.input}
                                    type="email"
                                    placeholder={t('newAccount.email')}
                                    value={values.email}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                                        handleChange('email')(event)
                                    }
                                />
                                <Box sx={{ position: 'relative', width: '100%' }}>
                                    <input
                                        className={classes.input}
                                        type={values.showPassword ? 'text' : 'password'}
                                        placeholder={t('newAccount.password')}
                                        value={values.password}
                                        onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>
                                        ): void => handleChange('password')(event)}
                                    />
                                    <IconButton
                                        sx={{ position: 'absolute', right: '10px', top: '-2px' }}
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end">
                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </Box>
                                <Box sx={{ position: 'relative', width: '100%' }}>
                                    <input
                                        className={classes.input}
                                        type={values.showPassword ? 'text' : 'password'}
                                        placeholder={t('newAccount.verifyPassword')}
                                        value={values.verifyPassword}
                                        onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>
                                        ): void => handleChange('verifyPassword')(event)}
                                    />
                                    <IconButton
                                        sx={{ position: 'absolute', right: '10px', top: '-2px' }}
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end">
                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </Box>
                                <FormControlLabel
                                    // sx={{ alignItems: 'flex-start' }}
                                    sx={{ display: 'table', marginRight: '0px' }}
                                    control={
                                        <div style={{ display: 'table-cell' }}>
                                            <Checkbox
                                                checked={values.cgvu}
                                                onChange={(
                                                    event: React.ChangeEvent<HTMLInputElement>
                                                ): void =>
                                                    setValues({
                                                        ...values,
                                                        cgvu: event.target.checked
                                                    })
                                                }
                                                name="checkedB"
                                                color="primary"
                                            />
                                        </div>
                                    }
                                    label={
                                        // <div style={{ display: 'table-cell' }}>
                                        <Typography
                                            style={{
                                                display: 'table-cell',
                                                textAlign: 'left',
                                                fontSize: '13.5px',
                                                color: '#002F55',
                                                fontWeight: '500',
                                                fontFamily: 'Inter'
                                            }}>
                                            <span>{t('newAccount.cgu1')}</span>{' '}
                                            <span style={{ textDecoration: 'underline' }}>
                                                {t('newAccount.cgu2')}
                                            </span>{' '}
                                            <span>{t('newAccount.cgu3')}</span>{' '}
                                            <span style={{ textDecoration: 'underline' }}>
                                                {t('newAccount.cgu4')}
                                            </span>
                                            .
                                        </Typography>
                                        // </div>
                                    }
                                />
                            </Box>
                        </Box>
                    )}
                    {step === 1 && (
                        <Box
                            width="80%"
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center">
                            <Typography
                                variant="h4"
                                color="#FF0089"
                                sx={{ fontFamily: 'Inter', fontWeight: '700', fontSize: '22' }}>
                                {t('newAccountPatientTitle1')}
                                <br />
                                {t('newAccountPatientTitle2')}
                            </Typography>
                            <Box
                                width="100%"
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                className={classes.inputComponentLayout}>
                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginBottom: '5%'
                                    }}>
                                    <Box sx={{ width: '40%', display: 'inline-block' }}>
                                        <Box
                                            display="flex"
                                            flexDirection="row"
                                            alignItems="center"
                                            onClick={triggerImageUploadPatient}>
                                            <input
                                                type="file"
                                                ref={inputFilePatient}
                                                onChange={(
                                                    event: React.ChangeEvent<HTMLInputElement>
                                                ): void => updateImagePatient(event)}
                                                accept="image/*"
                                                multiple={false}
                                                style={{ display: 'none', cursor: 'pointer' }}
                                            />
                                            <Box sx={{ position: 'relative' }}>
                                                {valuesPatient.picture === '' && (
                                                    <img
                                                        src={addImageNewAccountDefault}
                                                        alt="addImageNewAccountDefault"
                                                        style={{
                                                            marginRight: '5px',
                                                            height: '49px',
                                                            width: '49px'
                                                        }}
                                                    />
                                                )}
                                                {valuesPatient.picture !== '' && (
                                                    <img
                                                        src={valuesPatient.picture}
                                                        alt="addImageNewAccountDefault"
                                                        style={{
                                                            marginRight: '5px',
                                                            height: '49px',
                                                            width: '49px'
                                                        }}
                                                    />
                                                )}
                                                <img
                                                    src={addImageNewAccountPicture}
                                                    alt="addImageNewAccountPicture"
                                                    style={{
                                                        marginRight: '5px',
                                                        top: '0',
                                                        right: '0',
                                                        position: 'absolute',
                                                        width: '16px',
                                                        height: '16px'
                                                    }}
                                                />
                                            </Box>
                                            <Typography
                                                variant="h4"
                                                sx={{
                                                    fontFamily: 'Inter',
                                                    fontSize: '13px',
                                                    textAlign: 'left'
                                                }}>
                                                <span style={{ fontWeight: '600' }}>
                                                    {t('newAccount.patient.addPhoto1')}
                                                </span>{' '}
                                                <span
                                                    style={{ fontWeight: '600', color: '#FF0089' }}>
                                                    {t('newAccount.patient.addPhoto2')}
                                                </span>{' '}
                                                <span style={{ fontWeight: '600' }}>
                                                    {t('newAccount.patient.addPhoto3')}
                                                </span>
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            width: '60%',
                                            display: 'inline-block',
                                            marginLeft: '6px'
                                        }}>
                                        <Box
                                            display="flex"
                                            flexDirection="row"
                                            alignItems="center"
                                            sx={{ marginBottom: '5%' }}>
                                            <RadioGroup
                                                style={{ display: 'flex', flexDirection: 'row' }}
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue="Femme"
                                                name="radio-buttons-group"
                                                onChange={(
                                                    event: React.ChangeEvent<HTMLInputElement>
                                                ): void => handleChangePatient('gender')(event)}>
                                                <FormControlLabel
                                                    style={{
                                                        display: 'inline-block',
                                                        fontSize: '14px'
                                                    }}
                                                    value="Femme"
                                                    control={<Radio />}
                                                    label={
                                                        <Typography
                                                            variant="h6"
                                                            sx={{
                                                                fontFamily: 'Inter',
                                                                fontSize: '13px',
                                                                marginLeft: '-8px',
                                                                display: 'inline-block'
                                                            }}>
                                                            {t('female').toString()}
                                                        </Typography>
                                                    }
                                                />
                                                <FormControlLabel
                                                    style={{
                                                        display: 'inline-block',
                                                        fontSize: '14px'
                                                    }}
                                                    value="Homme"
                                                    control={<Radio />}
                                                    label={
                                                        <Typography
                                                            variant="h6"
                                                            sx={{
                                                                fontFamily: 'Inter',
                                                                fontSize: '13px',
                                                                marginLeft: '-8px',
                                                                display: 'inline-block'
                                                            }}>
                                                            {t('male').toString()}
                                                        </Typography>
                                                    }
                                                />
                                            </RadioGroup>
                                        </Box>
                                    </Box>
                                </Box>
                                <input
                                    className={classes.input}
                                    type="text"
                                    placeholder={t('newAccount.patient.name')}
                                    value={valuesPatient.name}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                                        handleChangePatient('name')(event)
                                    }
                                />
                                <input
                                    className={classes.input}
                                    type="text"
                                    placeholder={t('newAccount.patient.lastName')}
                                    value={valuesPatient.lastName}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                                        handleChangePatient('lastName')(event)
                                    }
                                />
                                <Box
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        verticalAlign: 'middle'
                                    }}>
                                    <input
                                        className={classes.input}
                                        type={valuesPatient.dob === '' ? 'text' : 'date'}
                                        placeholder={t('newAccount.patient.dob')}
                                        value={valuesPatient.dob}
                                        onFocus={(e): void => {
                                            e.target.type = 'date';
                                        }}
                                        onBlur={(e): void => {
                                            if (e.target.value === '') {
                                                e.target.type = 'text';
                                            }
                                        }}
                                        onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>
                                        ): void => handleChangePatient('dob')(event)}
                                    />
                                    <img
                                        src={info}
                                        alt="info"
                                        style={{ margin: '0 0 5% 5px' }}
                                        onClick={(): void =>
                                            showInfo(
                                                t('newAccount.infoDobPatienTitle'),
                                                t('newAccount.infoDobPatienDesc')
                                            )
                                        }
                                    />
                                </Box>
                                <Box
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        verticalAlign: 'middle'
                                    }}>
                                    <input
                                        className={classes.input}
                                        type="email"
                                        placeholder={t('newAccount.patient.email')}
                                        value={valuesPatient.email}
                                        onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>
                                        ): void => handleChangePatient('email')(event)}
                                    />
                                    <img
                                        src={info}
                                        alt="info"
                                        style={{ margin: '0 0 5% 5px' }}
                                        onClick={(): void =>
                                            showInfo(
                                                t('newAccount.infoEmailPatienTitle'),
                                                t('newAccount.infoEmailPatienDesc1'),
                                                t('newAccount.infoEmailPatienDesc2')
                                            )
                                        }
                                    />
                                </Box>
                                <input
                                    className={classes.input}
                                    type="text"
                                    placeholder={t('newAccount.patient.addr')}
                                    value={valuesPatient.addr}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                                        handleChangePatient('addr')(event)
                                    }
                                />
                                <input
                                    className={classes.input}
                                    type="text"
                                    placeholder={t('newAccount.patient.compAddr')}
                                    value={valuesPatient.compAddr}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                                        handleChangePatient('compAddr')(event)
                                    }
                                />
                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}>
                                    <input
                                        style={{
                                            width: '40%',
                                            display: 'inline-block',
                                            marginRight: '5px'
                                        }}
                                        maxLength={5}
                                        className={classes.input}
                                        type="number"
                                        placeholder={t('newAccount.patient.zipCode')}
                                        value={valuesPatient.zipCode}
                                        onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>
                                        ): void => handleChangePatient('zipCode')(event)}
                                    />
                                    <input
                                        style={{
                                            width: '60%',
                                            display: 'inline-block',
                                            marginLeft: '5px'
                                        }}
                                        className={classes.input}
                                        type="text"
                                        placeholder={t('newAccount.patient.city')}
                                        value={valuesPatient.city}
                                        onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>
                                        ): void => handleChangePatient('city')(event)}
                                    />
                                </Box>
                                <select
                                    className={classes.input}
                                    value={valuesPatient.kinship}
                                    onChange={(event): void =>
                                        setValuesPatient({
                                            ...valuesPatient,
                                            kinship: event.target.value
                                        })
                                    }>
                                    <option key="0">{t('newAccount.patient.kinship')}</option>
                                    {getThemeExamples()[1].otherThemes.map((item: string) => (
                                        <option key={item}>{item}</option>
                                    ))}
                                </select>
                            </Box>
                        </Box>
                    )}
                    {step === 2 && (
                        <Box
                            width="80%"
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center">
                            <Typography
                                variant="h4"
                                color="#FF0089"
                                sx={{ fontFamily: 'Inter', fontWeight: '700', fontSize: '22' }}>
                                {t('newAccountPatient2Title1')}
                                <br />
                                {t('newAccountPatient2Title2')}
                            </Typography>
                            <Box
                                width="100%"
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                className={classes.inputComponentLayout}>
                                <Box
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        verticalAlign: 'left',
                                        margin: '0 0 5% 5px'
                                    }}>
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            fontFamily: 'Inter',
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            color: '#002F55',
                                            paddingRight: '5px',
                                            textAlign: 'left'
                                        }}>
                                        {t('newAccount.patient2.info')}
                                    </Typography>
                                    <img
                                        src={info}
                                        alt="info"
                                        onClick={(): void =>
                                            showInfo(
                                                t('newAccount.patient2.infoTitle'),
                                                t('newAccount.patient2.infoDesc1'),
                                                t('newAccount.patient2.infoDesc2')
                                            )
                                        }
                                    />
                                </Box>

                                <Typography
                                    variant="h5"
                                    sx={{
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        width: '100%',
                                        textAlign: 'left',
                                        color: '#002F55'
                                    }}>
                                    {t('newAccount.patient2.idInfo')}
                                </Typography>
                                <Box
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        verticalAlign: 'middle'
                                    }}>
                                    <input
                                        className={classes.input}
                                        type="text"
                                        disabled
                                        value={valuesPatient2.id}
                                    />
                                    <img
                                        src={info}
                                        alt="info"
                                        style={{ margin: '0 0 5% 5px' }}
                                        onClick={(): void =>
                                            showInfo(
                                                t('newAccount.patient2.infoIdTitle'),
                                                t('newAccount.patient2.infoIdDesc')
                                            )
                                        }
                                    />
                                </Box>

                                <Box
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        verticalAlign: 'middle'
                                    }}>
                                    <Box sx={{ position: 'relative', width: '100%' }}>
                                        <input
                                            className={classes.input}
                                            type={values.showPassword ? 'text' : 'password'}
                                            placeholder={t('newAccount.patient2.password')}
                                            value={valuesPatient2.password}
                                            onChange={(
                                                event: React.ChangeEvent<HTMLInputElement>
                                            ): void => handleChangePatient2('password')(event)}
                                        />
                                        <IconButton
                                            sx={{
                                                position: 'absolute',
                                                right: '10px',
                                                top: '-2px'
                                            }}
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            edge="end">
                                            {values.showPassword ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </IconButton>
                                    </Box>
                                    <img
                                        src={info}
                                        alt="info"
                                        style={{ margin: '0 0 5% 5px' }}
                                        onClick={(): void =>
                                            showInfo(
                                                t('newAccount.patient2.infoPassTitle'),
                                                t('newAccount.patient2.infoPassDesc')
                                            )
                                        }
                                    />
                                </Box>
                                <Box sx={{ position: 'relative', width: '100%' }}>
                                    <input
                                        className={classes.input}
                                        type={values.showPassword ? 'text' : 'password'}
                                        placeholder={t('newAccount.patient2.verifyPassword')}
                                        value={valuesPatient2.verifyPassword}
                                        onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>
                                        ): void => handleChangePatient2('verifyPassword')(event)}
                                    />
                                    <IconButton
                                        sx={{
                                            position: 'absolute',
                                            right: '10px',
                                            top: '-2px'
                                        }}
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end">
                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </Box>

                                <Typography
                                    variant="h5"
                                    sx={{
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        width: '100%',
                                        textAlign: 'left',
                                        color: '#002F55'
                                    }}>
                                    {t('newAccount.patient2.familyCode1')}
                                    <span style={{ fontSize: '11px' }}>
                                        {t('newAccount.patient2.familyCode2')}
                                    </span>
                                    {' :'}
                                </Typography>
                                <Box
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        verticalAlign: 'middle'
                                    }}>
                                    <input
                                        className={classes.input}
                                        type="text"
                                        disabled
                                        value={valuesPatient2.familyCode}
                                    />
                                    <img
                                        src={info}
                                        alt="info"
                                        style={{ margin: '0 0 5% 5px' }}
                                        onClick={(): void =>
                                            showInfo(
                                                t('newAccount.patient2.infoCfTitle'),
                                                t('newAccount.patient2.infoCfDesc')
                                            )
                                        }
                                    />
                                </Box>
                            </Box>
                        </Box>
                    )}
                    {step === 3 && (
                        <Box
                            width="80%"
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center">
                            <Typography
                                variant="h4"
                                color="#FF0089"
                                sx={{
                                    fontFamily: 'Inter',
                                    fontWeight: '700',
                                    fontSize: '22',
                                    marginBottom: '20px'
                                }}>
                                {t('newAccountChoseformula')}
                            </Typography>
                            <Box
                                width="100%"
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center">
                                <Box
                                    width="100%"
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    style={{
                                        border:
                                            formula === 'BASIC'
                                                ? '6px solid #FF0089'
                                                : '1px solid #C4C4C4',
                                        background: '#FFFFFF',
                                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                        borderRadius: '20px',
                                        padding: '10px 0'
                                    }}
                                    onClick={(): void => setFormula('BASIC')}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            textAlign: 'center',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                        <img src={starInSquare} alt="starInSquare" height="30px" />
                                        <Typography
                                            variant="h5"
                                            sx={{
                                                fontFamily: 'Inter',
                                                color: '#002F55',
                                                fontSize: '10px',
                                                fontWeight: '400',
                                                textAlign: 'left'
                                            }}>
                                            <span>{t("Stimul'in")}</span>{' '}
                                            <span style={{ fontSize: '14px' }}>{t('BASIC')}</span>
                                        </Typography>
                                    </Box>
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            fontFamily: 'Inter',
                                            color: '#002F55',
                                            fontSize: '12px',
                                            fontWeight: '600',
                                            marginTop: '7px'
                                        }}>
                                        {t('newAccount.monthlyFormuma')}
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            fontFamily: 'Inter',
                                            color: '#002F55',
                                            fontSize: '10px',
                                            fontWeight: '400',
                                            marginTop: '10px'
                                        }}>
                                        <span style={{ fontWeight: '700', fontSize: '15px' }}>
                                            {t('newAccount.monthlyChristmasOffer')}
                                        </span>
                                        <br />
                                        <br />
                                        <span
                                            style={{
                                                fontWeight: '800',
                                                fontSize: '16px',
                                                color: '#FF0089'
                                            }}>
                                            {t('newAccount.monthlyChristmasOfferPrice')}
                                        </span>
                                        <br />
                                        <span style={{ fontWeight: '400', fontSize: '10px' }}>
                                            {t('newAccount.monthlyChristmasOfferPriceDesc')}
                                        </span>
                                        <br />
                                        <br />
                                        <span style={{ fontWeight: '800', fontSize: '10px' }}>
                                            {t('newAccount.monthlyChristmasOfferPriceAdd')}
                                        </span>
                                        <br />
                                        <br />
                                        <span style={{ fontWeight: '400', fontSize: '10px' }}>
                                            {t('newAccount.monthlyChristmasOfferValidity')}
                                        </span>
                                    </Typography>
                                    <Button
                                        sx={{
                                            width: '85%',
                                            height: '45px',
                                            background: '#FF0089',
                                            borderRadius: '10px',
                                            marginTop: '10px',
                                            color: '#FFFFFF',
                                            fontFamily: 'Inter',
                                            fontWeight: '800',
                                            fontSize: '15px',
                                            textTransform: 'none',
                                            '&:hover': {
                                                background: '#FF0089'
                                            }
                                        }}>
                                        {t('newAccount.choise')}
                                    </Button>
                                </Box>
                                <Box
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        verticalAlign: 'middle',
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                        marginTop: '10px'
                                    }}>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontFamily: 'Inter',
                                            color: '#002F55',
                                            fontSize: '10px',
                                            fontWeight: '200',
                                            display: 'inline-block'
                                        }}>
                                        {t('newAccount.moreInfo')}
                                    </Typography>
                                    <img
                                        src={info}
                                        alt="info"
                                        onClick={(): void => {
                                            setHiddeFormulaInfo(true);
                                        }}
                                        style={{ marginLeft: '5px', display: 'inline-block' }}
                                    />
                                </Box>
                                <Box
                                    width="100%"
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    style={{
                                        border:
                                            formula === 'FAN'
                                                ? '6px solid #FF0089'
                                                : '1px solid #C4C4C4',
                                        background: '#FFFFFF',
                                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                        borderRadius: '20px',
                                        padding: '10px 0',
                                        marginTop: '20px'
                                    }}
                                    onClick={(): void => setFormula('FAN')}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            textAlign: 'center',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                        <img src={starInSquare} alt="starInSquare" height="30px" />
                                        <Typography
                                            variant="h5"
                                            sx={{
                                                fontFamily: 'Inter',
                                                color: '#002F55',
                                                fontSize: '10px',
                                                fontWeight: '400'
                                            }}>
                                            <span>{t("Stimul'in")}</span>{' '}
                                            <span style={{ fontSize: '14px' }}>{t('FAN')}</span>
                                        </Typography>
                                    </Box>
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            fontFamily: 'Inter',
                                            color: '#002F55',
                                            fontSize: '12px',
                                            fontWeight: '600',
                                            marginTop: '7px'
                                        }}>
                                        {t('newAccount.annualFormuma')}
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            fontFamily: 'Inter',
                                            color: '#002F55',
                                            fontSize: '10px',
                                            fontWeight: '400',
                                            marginTop: '10px'
                                        }}>
                                        <span style={{ fontWeight: '700', fontSize: '15px' }}>
                                            {t('newAccount.annualChristmasOffer')}
                                        </span>
                                        <br />
                                        <br />
                                        <span
                                            style={{
                                                fontWeight: '800',
                                                fontSize: '16px',
                                                color: '#FF0089'
                                            }}>
                                            {t('newAccount.annualChristmasOfferPrice')}
                                        </span>
                                        <br />
                                        <span style={{ fontWeight: '400', fontSize: '10px' }}>
                                            {t('newAccount.annualChristmasOfferPriceDesc')}
                                        </span>
                                        <br />
                                        <br />
                                        <span style={{ fontWeight: '800', fontSize: '10px' }}>
                                            {t('newAccount.annualChristmasOfferPriceAdd')}
                                        </span>
                                        <br />
                                        <br />
                                        <span style={{ fontWeight: '400', fontSize: '10px' }}>
                                            {t('newAccount.annualChristmasOfferValidity')}
                                        </span>
                                    </Typography>
                                    <Button
                                        sx={{
                                            width: '85%',
                                            height: '45px',
                                            background: '#FF0089',
                                            borderRadius: '10px',
                                            marginTop: '10px',
                                            color: '#FFFFFF',
                                            fontFamily: 'Inter',
                                            fontWeight: '800',
                                            fontSize: '15px',
                                            textTransform: 'none',
                                            '&:hover': {
                                                background: '#FF0089'
                                            }
                                        }}>
                                        {t('newAccount.choise')}
                                    </Button>
                                </Box>
                                <Box
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        verticalAlign: 'middle',
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                        marginTop: '10px'
                                    }}>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontFamily: 'Inter',
                                            color: '#002F55',
                                            fontSize: '10px',
                                            fontWeight: '200',
                                            display: 'inline-block'
                                        }}>
                                        {t('newAccount.moreInfo')}
                                    </Typography>
                                    <img
                                        src={info}
                                        alt="info"
                                        onClick={(): void => {
                                            setHiddeFormulaInfo(true);
                                        }}
                                        style={{ marginLeft: '5px', display: 'inline-block' }}
                                    />
                                </Box>

                                <Box
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        verticalAlign: 'middle',
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                        marginTop: '20px'
                                    }}>
                                    <img
                                        src={confusion}
                                        alt="feature1"
                                        style={{
                                            marginRight: '5px',
                                            display: 'inline-block',
                                            width: '40px'
                                        }}
                                    />
                                    <Typography
                                        variant="h6"
                                        align="left"
                                        textAlign="left"
                                        sx={{
                                            fontFamily: 'Inter',
                                            color: '#000000',
                                            fontSize: '10px',
                                            fontWeight: '600',
                                            display: 'inline-block'
                                        }}>
                                        {t('newAccount.feature1')}
                                    </Typography>
                                </Box>
                                <Box
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        verticalAlign: 'middle',
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                        marginTop: '20px'
                                    }}>
                                    <img
                                        src={goodStar}
                                        alt="feature2"
                                        style={{
                                            marginRight: '5px',
                                            display: 'inline-block',
                                            width: '40px'
                                        }}
                                    />
                                    <Typography
                                        variant="h6"
                                        align="left"
                                        textAlign="left"
                                        sx={{
                                            fontFamily: 'Inter',
                                            color: '#000000',
                                            fontSize: '10px',
                                            fontWeight: '600',
                                            display: 'inline-block'
                                        }}>
                                        {t('newAccount.feature2')}
                                    </Typography>
                                </Box>
                                <Box
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        verticalAlign: 'middle',
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                        marginTop: '20px'
                                    }}>
                                    <img
                                        src={preloadTheme}
                                        alt="feature3"
                                        style={{
                                            marginRight: '5px',
                                            display: 'inline-block',
                                            width: '40px'
                                        }}
                                    />
                                    <Typography
                                        variant="h6"
                                        align="left"
                                        textAlign="left"
                                        sx={{
                                            fontFamily: 'Inter',
                                            color: '#000000',
                                            fontSize: '10px',
                                            fontWeight: '600',
                                            display: 'inline-block'
                                        }}>
                                        {t('newAccount.feature3')}
                                    </Typography>
                                </Box>
                                <Box
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        verticalAlign: 'middle',
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                        marginTop: '20px',
                                        marginBottom: '20px'
                                    }}>
                                    <img
                                        src={activityModel}
                                        alt="feature4"
                                        style={{
                                            marginRight: '5px',
                                            display: 'inline-block',
                                            width: '40px'
                                        }}
                                    />
                                    <Typography
                                        variant="h6"
                                        align="left"
                                        textAlign="left"
                                        sx={{
                                            fontFamily: 'Inter',
                                            color: '#000000',
                                            fontSize: '10px',
                                            fontWeight: '600',
                                            display: 'inline-block'
                                        }}>
                                        {t('newAccount.feature4')}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Box>
                {/* leave and next button */}
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-evenly"
                    paddingTop={2}
                    paddingBottom={2}
                    width="100%">
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            width: '40%',
                            height: '40px',
                            backgroundColor: '#F4F4F4',
                            border: '1px solid #002F55',
                            color: '#002F55',
                            borderRadius: '12px',
                            '&:hover': {
                                backgroundColor: '#F4F4F4'
                            }
                        }}
                        onClick={(): void => {
                            if (step === 0) {
                                window.location.href = 'https://www.stimulin.fr/tarifs';
                            } else {
                                setStep(step - 1);
                            }
                        }}>
                        {step === 0 ? t('newAccount.leave') : t('newAccount.back')}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            width: '40%',
                            height: '40px',
                            backgroundColor: '#FF0089',
                            border: 'none',
                            color: '#FFFFFF',
                            borderRadius: '12px',
                            boxShadow: '0px 3.02813px 3.02813px rgba(0, 0, 0, 0.25)',
                            '&:hover': {
                                backgroundColor: '#FF0089'
                            }
                        }}
                        onClick={async (): Promise<void> => nextStep()}>
                        {t('newAccount.next')}
                    </Button>
                </Box>
            </Box>
        );
    };

    return (
        <Box overflow={hiddeInfo ? 'hidden' : 'auto'}>
            {hiddeInfo && (
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    width="100vw"
                    height="100vh"
                    bgcolor="#040505B2"
                    position="absolute"
                    zIndex="3600">
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        padding={2}
                        width="70%"
                        minHeight="60%"
                        position="relative"
                        bgcolor="#FFFFFF"
                        borderRadius="12px">
                        <img
                            src={circleCloseIcon}
                            alt="circleCloseIcon"
                            style={{
                                position: 'absolute',
                                top: '20px',
                                right: '20px',
                                height: '37px'
                            }}
                            onClick={(): void => setHiddeInfo(false)}
                        />
                        <img src={info} alt="info" />
                        <Typography
                            variant="h4"
                            color="#FF0089"
                            sx={{
                                fontFamily: 'Inter',
                                fontWeight: '600',
                                fontSize: '14px',
                                marginTop: '20px',
                                marginBottom: '20px'
                            }}>
                            {infoTitle}
                        </Typography>
                        <Typography
                            variant="h4"
                            color="#374653"
                            sx={{ fontFamily: 'Inter', fontWeight: '600', fontSize: '14px' }}>
                            {infoDesc}
                        </Typography>
                        {infoDesc2 && (
                            <>
                                <br />
                                <Typography
                                    variant="h4"
                                    color="#374653"
                                    sx={{
                                        fontFamily: 'Inter',
                                        fontWeight: '600',
                                        fontSize: '14px'
                                    }}>
                                    {infoDesc2}
                                </Typography>
                            </>
                        )}
                    </Box>
                </Box>
            )}
            {hiddeMisseField && (
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    width="100vw"
                    height="100vh"
                    bgcolor="#040505B2"
                    position="absolute"
                    zIndex="3600">
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        padding={2}
                        width="70%"
                        minHeight="60%"
                        bgcolor="#FFFFFF"
                        position="relative"
                        borderRadius="12px">
                        <img
                            src={circleCloseIcon}
                            alt="circleCloseIcon"
                            style={{
                                position: 'absolute',
                                top: '20px',
                                right: '20px',
                                height: '37px'
                            }}
                            onClick={(): void => setHiddeMisseField(false)}
                        />
                        <Typography
                            variant="h4"
                            color="#FF0089"
                            sx={{
                                fontFamily: 'Inter',
                                fontWeight: '600',
                                fontSize: '14px',
                                marginTop: '20px',
                                marginBottom: '20px'
                            }}>
                            {t('newAccount.missingFieldTitle')}
                        </Typography>
                        <Typography
                            variant="h4"
                            color="#374653"
                            sx={{ fontFamily: 'Inter', fontWeight: '600', fontSize: '13px' }}>
                            {hiddeMisseFieldList.length === 1
                                ? t('newAccount.missingFieldDesc')
                                : t('newAccount.missingFieldDescMultiple')}
                            <br />
                            <ul
                                style={{
                                    textAlign: 'center',
                                    listStylePosition: 'inside',
                                    paddingLeft: '0'
                                }}>
                                {hiddeMisseFieldList.map((item: string, index: number) => (
                                    <li>&quot;{item}&quot;</li>
                                ))}
                            </ul>
                        </Typography>
                        <img style={{ height: '120px' }} src={missingfield} alt="missingfield" />
                    </Box>
                </Box>
            )}
            {hiddeEmailNotValid && (
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    width="100vw"
                    height="100vh"
                    bgcolor="#040505B2"
                    position="absolute"
                    zIndex="3600">
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        padding={2}
                        width="70%"
                        minHeight="60%"
                        position="relative"
                        bgcolor="#FFFFFF"
                        borderRadius="12px">
                        <img
                            src={circleCloseIcon}
                            alt="circleCloseIcon"
                            style={{
                                position: 'absolute',
                                top: '20px',
                                right: '20px',
                                height: '37px'
                            }}
                            onClick={(): void => setHiddeEmailNotValid(false)}
                        />
                        <Typography
                            variant="h4"
                            color="#FF0089"
                            sx={{
                                fontFamily: 'Inter',
                                fontWeight: '600',
                                fontSize: '14px',
                                marginTop: '20px',
                                marginBottom: '20px'
                            }}>
                            {t('newAccount.missingFieldTitle')}
                        </Typography>
                        <Typography
                            variant="h4"
                            color="#374653"
                            sx={{ fontFamily: 'Inter', fontWeight: '600', fontSize: '14px' }}>
                            {t('newAccount.emailNotValidDesc1')}
                            <br />
                            {t('newAccount.emailNotValidDesc2')}
                        </Typography>
                        <img style={{ height: '120px' }} src={emailNotValid} alt="emailNotValid" />
                    </Box>
                </Box>
            )}
            {hiddeZipCodeNotValid && (
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    width="100vw"
                    height="100vh"
                    bgcolor="#040505B2"
                    position="absolute"
                    zIndex="3600">
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        padding={2}
                        width="70%"
                        minHeight="60%"
                        position="relative"
                        bgcolor="#FFFFFF"
                        borderRadius="12px">
                        <img
                            src={circleCloseIcon}
                            alt="circleCloseIcon"
                            style={{
                                position: 'absolute',
                                top: '20px',
                                right: '20px',
                                height: '37px'
                            }}
                            onClick={(): void => setHiddeZipCodeNotValid(false)}
                        />
                        <Typography
                            variant="h4"
                            color="#FF0089"
                            sx={{
                                fontFamily: 'Inter',
                                fontWeight: '600',
                                fontSize: '14px',
                                marginTop: '20px',
                                marginBottom: '20px'
                            }}>
                            {t('newAccount.missingFieldTitle')}
                        </Typography>
                        <Typography
                            variant="h4"
                            color="#374653"
                            sx={{ fontFamily: 'Inter', fontWeight: '600', fontSize: '14px' }}>
                            {t('newAccount.zipCodeNotValidDesc1')}
                            <br />
                            {t('newAccount.zipCodeNotValidDesc2')}
                        </Typography>
                        <img
                            style={{ paddingTop: '15px', height: '120px' }}
                            src={zipCodeNotValid}
                            alt="zipCodeNotValid"
                        />
                    </Box>
                </Box>
            )}
            {hiddePasswordNotValidLength && (
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    width="100vw"
                    height="100vh"
                    bgcolor="#040505B2"
                    position="absolute"
                    zIndex="3600">
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        padding={2}
                        width="70%"
                        minHeight="60%"
                        position="relative"
                        bgcolor="#FFFFFF"
                        borderRadius="12px">
                        <img
                            src={circleCloseIcon}
                            alt="circleCloseIcon"
                            style={{
                                position: 'absolute',
                                top: '20px',
                                right: '20px',
                                height: '37px'
                            }}
                            onClick={(): void => setHiddePasswordNotValidLength(false)}
                        />
                        <Typography
                            variant="h4"
                            color="#FF0089"
                            sx={{
                                fontFamily: 'Inter',
                                fontWeight: '600',
                                fontSize: '14px',
                                marginTop: '20px',
                                marginBottom: '20px'
                            }}>
                            {t('newAccount.missingFieldTitle')}
                        </Typography>
                        <Typography
                            variant="h4"
                            color="#374653"
                            sx={{ fontFamily: 'Inter', fontWeight: '600', fontSize: '14px' }}>
                            {t('newAccount.passwordNotValidLength1')}
                            <br />
                            {t('newAccount.passwordNotValidLength2', {
                                number: passwordNotValidLength.toString()
                            })}
                        </Typography>
                        <img
                            style={{ paddingTop: '10px', height: '120px' }}
                            src={imgPasswordNotValidLength}
                            alt="imgPasswordNotValidLength"
                        />
                    </Box>
                </Box>
            )}
            {hiddePasswordNotConfirm && (
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    width="100vw"
                    height="100vh"
                    bgcolor="#040505B2"
                    position="absolute"
                    zIndex="3600">
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        padding={2}
                        width="70%"
                        minHeight="60%"
                        position="relative"
                        bgcolor="#FFFFFF"
                        borderRadius="12px">
                        <img
                            src={circleCloseIcon}
                            alt="circleCloseIcon"
                            style={{
                                position: 'absolute',
                                top: '20px',
                                right: '20px',
                                height: '37px'
                            }}
                            onClick={(): void => setHiddePasswordNotConfirm(false)}
                        />
                        <Typography
                            variant="h4"
                            color="#FF0089"
                            sx={{
                                fontFamily: 'Inter',
                                fontWeight: '600',
                                fontSize: '14px',
                                marginTop: '20px',
                                marginBottom: '20px'
                            }}>
                            {t('newAccount.missingFieldTitle')}
                        </Typography>
                        <Typography
                            variant="h4"
                            color="#374653"
                            sx={{ fontFamily: 'Inter', fontWeight: '600', fontSize: '14px' }}>
                            {t('newAccount.hiddePasswordNotConfirm1')}
                            <br />
                            {t('newAccount.hiddePasswordNotConfirm2')}
                        </Typography>
                        <img
                            style={{ paddingTop: '10px', height: '120px' }}
                            src={imgPasswordNotValidLength}
                            alt="imgPasswordNotValidLength"
                        />
                    </Box>
                </Box>
            )}
            {hiddeFormulaInfo && (
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    width="100vw"
                    height="100vh"
                    bgcolor="#040505B2"
                    position="absolute"
                    zIndex="3600">
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        paddingTop={8}
                        width="70%"
                        minHeight="60%"
                        maxHeight="80%"
                        position="relative"
                        bgcolor="#FFFFFF"
                        borderRadius="12px">
                        <Box overflow="auto" padding={2}>
                            <img
                                src={circleCloseIcon}
                                alt="circleCloseIcon"
                                style={{
                                    position: 'absolute',
                                    top: '20px',
                                    right: '20px',
                                    height: '37px'
                                }}
                                onClick={(): void => setHiddeFormulaInfo(false)}
                            />
                            <img src={info} alt="info" />
                            <Typography
                                variant="h4"
                                color="#FF0089"
                                textAlign="center"
                                sx={{
                                    fontFamily: 'Inter',
                                    fontWeight: '600',
                                    fontSize: '14px',
                                    marginTop: '20px',
                                    marginBottom: '20px'
                                }}>
                                {t('newAccount.infoStimulinTitle')}
                            </Typography>
                            <Typography
                                variant="h4"
                                color="#374653"
                                textAlign="left"
                                sx={{ fontFamily: 'Inter', fontWeight: '600', fontSize: '14px' }}>
                                <ul
                                    style={{
                                        textAlign: 'left',
                                        listStylePosition: 'inside',
                                        paddingLeft: '0'
                                    }}>
                                    <li>{t('newAccount.infoStimulinDesc1')}</li>
                                    <br />
                                    <li>{t('newAccount.infoStimulinDesc2')}</li>
                                    <br />
                                    <li>{t('newAccount.infoStimulinDesc3')}</li>
                                    <br />
                                    <li>{t('newAccount.infoStimulinDesc4')}</li>
                                    <br />
                                    <li>{t('newAccount.infoStimulinDesc5')}</li>
                                    <br />
                                    <li>{t('newAccount.infoStimulinDesc6')}</li>
                                    <br />
                                    <li>...</li>
                                </ul>
                                {t('newAccount.infoStimulinEnd1')}
                                <br />
                                <br />
                                {t('newAccount.infoStimulinEnd2')}
                                <br />
                                {t('newAccount.infoStimulinEnd3')}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            )}
            {hiddeSelectChoise && (
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    width="100vw"
                    height="100vh"
                    bgcolor="#040505B2"
                    position="absolute"
                    zIndex="3600">
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        padding={2}
                        width="70%"
                        minHeight="60%"
                        position="relative"
                        bgcolor="#FFFFFF"
                        borderRadius="12px">
                        <img
                            src={circleCloseIcon}
                            alt="circleCloseIcon"
                            style={{
                                position: 'absolute',
                                top: '20px',
                                right: '20px',
                                height: '37px'
                            }}
                            onClick={(): void => setHiddeSelectChoise(false)}
                        />
                        <Typography
                            variant="h4"
                            color="#FF0089"
                            sx={{
                                fontFamily: 'Inter',
                                fontWeight: '600',
                                fontSize: '14px',
                                marginTop: '20px',
                                marginBottom: '20px'
                            }}>
                            {t('newAccount.missingFieldTitle')}
                        </Typography>
                        <Typography
                            variant="h4"
                            color="#374653"
                            sx={{ fontFamily: 'Inter', fontWeight: '600', fontSize: '14px' }}>
                            {t('newAccount.hiddesSlectChoise1')}
                            <br />
                            {t('newAccount.hiddesSlectChoise2')}
                        </Typography>
                        <img
                            style={{ paddingTop: '10px', height: '120px' }}
                            src={selectChoise}
                            alt="selectChoise"
                        />
                    </Box>
                </Box>
            )}
            {getNewAccount()}
        </Box>
    );
}
