import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Person } from '@mui/icons-material';
import { styled } from '@mui/styles';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import { Header } from '../../components/Header';
import { Patient } from '../../models/Patient';
import { getPatientFromCode } from '../../services/cloudFirestore';
import { translateDate } from '../../utilities/utils';
import { ProfileImage } from '../../components/ProfileImage';

const Title = styled(Typography)({
    display: 'flex',
    alignContent: 'space-around',
    alignItems: 'center',
    justifyContent: 'center'
});

interface PatientInfoProps {
    code: string;
    onSuccess: Function;
    onError: Function;
}

export function PatientInfo({ code, onSuccess, onError }: PatientInfoProps): JSX.Element {
    const [patient, setPatient] = useState<Patient | null>();
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        getPatientFromCode(code).then((data) => {
            if (data === null) {
                onError('PATIENT_NOT_FOUND');
            }
            setPatient(data);
        });
    }, []);

    useEffect(() => {
        if (patient && patient.dob) {
            setDate(translateDate(patient?.dob?.toDate().toDateString()));
        }
    }, [patient]);

    const getPatientInfo = (
        <Box flex={1} display="flex" flexDirection="column" height="100vh" justifyContent="center">
            <Header showAvatar={false} />
            <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
                maxWidth={800}
                margin="auto"
                px={2}>
                <Title variant="h3">
                    <Person sx={{ marginBottom: '15px' }} fontSize="inherit" />
                    <Typography variant="h3" sx={{ marginBottom: '15px' }}>
                        {t('welcome')}
                    </Typography>
                </Title>
                {patient ? (
                    <ProfileImage
                        width={100}
                        height={100}
                        name={`${patient.firstName} ${patient.lastName}`}
                        url={patient.profilePhoto}
                    />
                ) : null}
                <Box>
                    <Typography color="#374653">{t('inFamilyOf')}</Typography>
                    <Typography variant="body1" color="#374653" sx={{ fontWeight: 600 }}>
                        {`${patient?.firstName} ${patient?.lastName}`}
                    </Typography>
                    <Typography variant="body1" color="#374653" sx={{ fontWeight: 600 }}>
                        {`${t('born')} ${date}`}
                    </Typography>
                    {patient?.establishmentName && (
                        <Typography variant="body1" color="#374653" sx={{ fontWeight: 600 }}>
                            {`${t('residence')} ${patient?.establishmentName}`}
                        </Typography>
                    )}
                </Box>
            </Box>
            <Box
                sx={{
                    marginTop: '5%'
                }}>
                <Box maxWidth={800} margin="auto" px={2}>
                    <LoadingButton
                        fullWidth
                        loading={loading}
                        variant="outlined"
                        size="large"
                        onClick={(): void => {
                            setLoading(true);
                            onSuccess(patient);
                        }}>
                        {t('toContinue')}
                    </LoadingButton>
                    <Box
                        sx={{
                            margin: '5% 0%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        <Typography variant="body1">{t('wrongCode?')} </Typography>
                        <span>
                            <Button
                                fullWidth
                                onClick={(): void => {
                                    onError('WRONG_CODE');
                                }}>
                                {t('crossOut')}
                            </Button>
                        </span>
                    </Box>
                </Box>
            </Box>
        </Box>
    );

    return (
        <Box>
            {patient ? (
                getPatientInfo
            ) : (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100vh"
                    width="100vw">
                    <CircularProgress />
                </Box>
            )}
        </Box>
    );
}
