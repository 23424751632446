import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles, createStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { CentralLogo } from '../../components/Headers/CentralLogo';
import { SelectComponent } from '../../components/SelectComponent';
import { DeactiveModal } from '../../components/DeactiveModal';
import { logOut } from '../../services/firebaseAuth';
import { UserContext } from '../../providers/UserProvider';
import {
    getLegalReferent,
    getPatientUser,
    savePatientProfile,
    updatePatientDifficultyLevel
} from '../../services/cloudFirestore';
import { levelOptionList, optionList, Patient } from '../../models/Patient';
import { Family } from '../../models/Family';
import { DatePickerCustom } from '../../components/DatePickerCustom';
import { UserDataProp } from '../../models/userDataProp';
import { ExerciseType } from '../../models/ExerciseType';
import { InputTextNotDisabled, InputTextDisabled } from '../../components/FormComponents/InputText';
import { RatingExercise, RatingText } from '../../components/FormComponents/RatingExercise';
import { Roles } from '../../models/Roles';
import { TitleFormPart } from '../../components/FormComponents/TitleFormPart';
import { Logo } from '../../components/FormComponents/Logo';

const useStyles = makeStyles(() =>
    createStyles({
        selectLayout: {
            width: '100%',
            marginTop: '16px',
            color: 'rgba(0, 47, 85, 1)'
        },
        selectPlaceholder: {
            width: '100%',
            marginTop: '16px',
            color: 'rgba(172, 188, 199, 1)'
        },
        suscriptionText: {
            textAlign: 'left',
            color: '#004680',
            fontFamily: 'Luciole-Regular',
            marginTop: '4%'
        }
    })
);

export function PatientScreenSettings(): JSX.Element {
    const { t } = useTranslation();
    const classes = useStyles();
    const [disabled, setDisabled] = useState<boolean>(true);
    const [logout, setLogout] = useState<boolean>(false);
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const inputFile = useRef<HTMLInputElement>(null);
    const user = userDataProp?.user;
    const [loading, setLoading] = useState<boolean>(false);
    const [photo, setPhoto] = useState<string | undefined>();
    const [memoryDifficulty, setMemoryDifficulty] = useState<number>(0);
    const [puzzleDifficulty, setPuzzleDifficulty] = useState<number>(0);
    const [memorinDifficulty, setMemorinDifficulty] = useState<number>(0);
    const [sudokuDifficulty, setSudokuDifficulty] = useState<number>(0);
    const [pongDifficulty, setPongDifficulty] = useState<number>(0);
    const [reorderDifficulty, setReorderDifficulty] = useState<number>(0);
    const [data, setData] = useState<Patient>({
        firstName: undefined,
        lastName: undefined,
        emailId: undefined,
        dob: undefined,
        profilePhoto: undefined,
        sex: undefined,
        level: undefined,
        category: undefined,
        familyCode: undefined,
        legalReferent: undefined,
        establishmentName: undefined,
        establishmentCode: undefined,
        startDate: undefined,
        endDate: undefined,
        address: undefined,
        pincode: undefined,
        city: undefined,
        familyMembers: undefined,
        completedExerciseList: undefined,
        firstStepFormFields: [],
        secondStepFormFields: [],
        memoryDifficultyLevel: undefined,
        memorinDifficultyLevel: undefined,
        puzzleDifficultyLevel: undefined,
        reorderDifficultyLevel: undefined
    });
    const [family, setFamily] = useState<Family>({
        firstName: undefined,
        lastName: undefined,
        emailId: undefined,
        familyCode: undefined,
        address: undefined,
        pincode: undefined,
        city: undefined,
        relationship: undefined,
        lovedPassword: undefined,
        firstStepFormFields: [],
        secondStepFormFields: []
    });

    useEffect(() => {
        if (data.profilePhoto) {
            setDisabled(false);
        }
    }, [data.profilePhoto]);

    const updateImage = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.files) {
            setPhoto(URL.createObjectURL(event.target.files[0]));
            setData({ ...data, profilePhoto: event.target.files[0] });
        }
    };

    const handleChange = (state: string, value: string | null): void => {
        setData({ ...data, [state]: value });
    };

    const saveDetails = async (): Promise<void> => {
        setLoading(true);
        if (user && user.id && data) {
            await savePatientProfile(user.id, data);
        }
        setLoading(false);
        window.location.reload();
    };

    useEffect(() => {
        const getPatientData = async (): Promise<void> => {
            if (user && user.id) {
                const patient = await getPatientUser(user.id);
                if (patient && patient.familyCode) {
                    const familyReferant = await getLegalReferent(patient.familyCode);
                    if (familyReferant) {
                        setFamily({
                            firstName: familyReferant.firstName,
                            lastName: familyReferant.lastName,
                            emailId: familyReferant.emailId,
                            dob: familyReferant.dob,
                            familyCode: familyReferant.familyCode,
                            address: familyReferant.address,
                            pincode: familyReferant.pincode,
                            city: familyReferant.city,
                            relationship: familyReferant.relationship,
                            lovedPassword: familyReferant.lovedPassword,
                            firstStepFormFields: [],
                            secondStepFormFields: []
                        });
                    }
                }
                if (patient) {
                    if (typeof patient.profilePhoto === 'string') {
                        setPhoto(patient.profilePhoto);
                    }
                    setData({
                        firstName: patient.firstName,
                        lastName: patient.lastName,
                        emailId: patient.emailId,
                        dob: patient.dob,
                        profilePhoto: patient.profilePhoto,
                        sex: patient.sex,
                        level: patient.level,
                        category: patient.category,
                        familyCode: patient.familyCode,
                        legalReferent: patient.legalReferent,
                        establishmentName: patient.establishmentName,
                        establishmentCode: patient.establishmentCode,
                        startDate: patient.startDate,
                        endDate: patient.endDate,
                        address: patient.address,
                        pincode: patient.pincode,
                        city: patient.city,
                        completedExerciseList: patient.completedExerciseList,
                        firstStepFormFields: [],
                        secondStepFormFields: [],
                        memoryDifficultyLevel: patient.memoryDifficultyLevel,
                        memorinDifficultyLevel: patient.memorinDifficultyLevel,
                        sudokuDifficultyLevel: patient.sudokuDifficultyLevel,
                        pongDifficultyLevel: patient.pongDifficultyLevel,
                        puzzleDifficultyLevel: patient.puzzleDifficultyLevel,
                        reorderDifficultyLevel: patient.reorderDifficultyLevel
                    });
                    if (
                        patient.memoryDifficultyLevel &&
                        patient.puzzleDifficultyLevel &&
                        patient.memorinDifficultyLevel &&
                        patient.sudokuDifficultyLevel &&
                        patient.pongDifficultyLevel &&
                        patient.reorderDifficultyLevel
                    ) {
                        setMemoryDifficulty(patient.memoryDifficultyLevel);
                        setMemorinDifficulty(patient.memorinDifficultyLevel);
                        setSudokuDifficulty(patient.sudokuDifficultyLevel);
                        setPongDifficulty(patient.pongDifficultyLevel);
                        setPuzzleDifficulty(patient.puzzleDifficultyLevel);
                        setReorderDifficulty(patient.reorderDifficultyLevel);
                    }
                }
            }
        };
        getPatientData();
    }, []);

    const inputComponent = (
        position: string,
        placeholder: string,
        dataToShow: string | null
    ): JSX.Element => {
        return <InputTextDisabled side={position} placeholder={t(placeholder)} data={dataToShow} />;
    };

    const changeDifficultyLevel = async (
        value: number | null,
        type: ExerciseType
    ): Promise<void> => {
        if (user && user.id) {
            if (type === ExerciseType.MEMORY && value !== null) {
                updatePatientDifficultyLevel(
                    value,
                    memorinDifficulty,
                    puzzleDifficulty,
                    sudokuDifficulty,
                    pongDifficulty,
                    reorderDifficulty,
                    user.id
                );
                setMemoryDifficulty(value);
            }
            if (type === ExerciseType.PUZZLE && value !== null) {
                updatePatientDifficultyLevel(
                    memoryDifficulty,
                    memorinDifficulty,
                    value,
                    sudokuDifficulty,
                    pongDifficulty,
                    reorderDifficulty,
                    user.id
                );
                setPuzzleDifficulty(value);
            }
            if (type === ExerciseType.MEMORIN && value !== null) {
                updatePatientDifficultyLevel(
                    memoryDifficulty,
                    value,
                    puzzleDifficulty,
                    sudokuDifficulty,
                    pongDifficulty,
                    reorderDifficulty,
                    user.id
                );
                setMemorinDifficulty(value);
            }
            if (type === ExerciseType.SUDOKU && value !== null) {
                updatePatientDifficultyLevel(
                    memoryDifficulty,
                    memorinDifficulty,
                    puzzleDifficulty,
                    value,
                    pongDifficulty,
                    reorderDifficulty,
                    user.id
                );
                setSudokuDifficulty(value);
            }
            if (type === ExerciseType.PONG && value !== null) {
                updatePatientDifficultyLevel(
                    memoryDifficulty,
                    memorinDifficulty,
                    puzzleDifficulty,
                    sudokuDifficulty,
                    value,
                    reorderDifficulty,
                    user.id
                );
                setPongDifficulty(value);
            }
            if (type === ExerciseType.ORDER && value !== null) {
                updatePatientDifficultyLevel(
                    memoryDifficulty,
                    memorinDifficulty,
                    puzzleDifficulty,
                    sudokuDifficulty,
                    pongDifficulty,
                    value,
                    user.id
                );
                setReorderDifficulty(value);
            }
        }
    };

    const patientSettingsScreen = (
        <div>
            <CentralLogo hasHomeButton hasCentralLogo hasProfilePhoto />
            <Box
                flex={1}
                display="flex"
                flexDirection="column"
                height="100vh"
                width="80%"
                margin="auto">
                <Container sx={{ marginTop: '5%' }}>
                    <Logo
                        photo={photo}
                        firstName={user?.firstName ?? ''}
                        lastName={user?.lastName ?? ''}
                        updateImage={(event: React.ChangeEvent<HTMLInputElement>): void =>
                            updateImage(event)
                        }
                    />
                </Container>
                <Box maxWidth={800} margin="auto" px={2}>
                    {inputComponent('left', 'firstName', data.firstName ?? null)}
                    {inputComponent('right', 'name', data.lastName ?? null)}
                    <DatePickerCustom
                        disabled
                        sx={{ marginTop: '15px', marginRight: '6%', width: '47%' }}
                        onDateChange={(date: Date): void => {}}
                        value={data.dob ? data.dob.toDate() : null}
                    />
                    <div style={{ display: 'inline-block', width: '47%' }}>
                        <SelectComponent
                            dropdownList={['Femme', 'Homme']}
                            placeholder={t('gender')}
                            onTextChange={(value: string): void => {}}
                            value={data.sex ?? null}
                            classCustom={classes.selectLayout}
                            placeholderStyling={classes.selectPlaceholder}
                            disabled
                        />
                    </div>
                    <div
                        style={{
                            width: '47%',
                            margin: '0px',
                            padding: '0px',
                            marginRight: '6%',
                            display: 'inline-block'
                        }}>
                        <SelectComponent
                            dropdownList={levelOptionList}
                            placeholder={t('levelOfStudy')}
                            onTextChange={(value: string): void => {}}
                            value={data.level ?? null}
                            classCustom={classes.selectLayout}
                            placeholderStyling={classes.selectPlaceholder}
                            disabled
                        />
                    </div>
                    <div
                        style={{
                            width: '47%',
                            margin: '0px',
                            padding: '0px',
                            display: 'inline-block'
                        }}>
                        <SelectComponent
                            dropdownList={optionList}
                            placeholder={t('socialCategory')}
                            onTextChange={(value: string): void => {}}
                            value={data.category ?? null}
                            classCustom={classes.selectLayout}
                            placeholderStyling={classes.selectPlaceholder}
                            disabled
                        />
                    </div>
                    {inputComponent('left', 'familyCode', data.familyCode ?? null)}
                    {inputComponent('right', 'nameEstablishment', data.establishmentName ?? null)}
                    <InputTextNotDisabled
                        placeholder={t('addressTableHeading')}
                        data={data.address ?? null}
                        change={(value: string): void => {
                            handleChange('address', value);
                        }}
                        side="marginTopLayout"
                    />
                    <InputTextNotDisabled
                        placeholder={t('pincode')}
                        data={data.pincode ?? null}
                        change={(value: string): void => {
                            handleChange('pincode', value);
                        }}
                        side="left"
                    />
                    <InputTextNotDisabled
                        placeholder={t('city')}
                        data={data.city ?? null}
                        change={(value: string): void => {
                            handleChange('city', value);
                        }}
                        side="right"
                    />
                    {inputComponent('left', 'email', data.emailId ?? null)}
                    {inputComponent('right', 'lovedPassword', family.lovedPassword ?? null)}
                    <TitleFormPart text={t('difficultyLevel')} />
                    <RatingText userType={Roles.PATIENT} />
                    <RatingExercise
                        type="memor'in"
                        level={memorinDifficulty}
                        side="center"
                        downgradeDifficultyLevel={(value: number | null): void => {
                            changeDifficultyLevel(value, ExerciseType.MEMORIN);
                        }}
                        max={10}
                    />
                    <br />
                    <RatingExercise
                        type="puzzl'in"
                        level={puzzleDifficulty}
                        side="center"
                        downgradeDifficultyLevel={(value: number | null): void => {
                            changeDifficultyLevel(value, ExerciseType.PUZZLE);
                        }}
                        max={10}
                    />
                    <br />
                    <RatingExercise
                        type="souven'in"
                        level={memoryDifficulty}
                        side="center"
                        downgradeDifficultyLevel={(value: number | null): void => {
                            changeDifficultyLevel(value, ExerciseType.MEMORY);
                        }}
                        max={10}
                    />
                    <br />
                    <RatingExercise
                        type="sudok'in"
                        level={sudokuDifficulty}
                        side="center"
                        downgradeDifficultyLevel={(value: number | null): void => {
                            changeDifficultyLevel(value, ExerciseType.SUDOKU);
                        }}
                        max={10}
                    />
                    <br />
                    <RatingExercise
                        type="pong'in"
                        level={pongDifficulty}
                        side="center"
                        downgradeDifficultyLevel={(value: number | null): void => {
                            changeDifficultyLevel(value, ExerciseType.PONG);
                        }}
                        max={10}
                    />
                    <br />
                    <RatingExercise
                        type="reorder"
                        level={reorderDifficulty}
                        side="center"
                        downgradeDifficultyLevel={(value: number | null): void => {
                            changeDifficultyLevel(value, ExerciseType.ORDER);
                        }}
                        max={10}
                    />
                    <TitleFormPart text={t('familyReference')} />
                    {inputComponent('left', 'firstName', family.firstName ?? null)}
                    {inputComponent('right', 'name', family.lastName ?? null)}
                    <DatePickerCustom
                        disabled
                        sx={{ marginTop: '15px', marginRight: '6%', width: '47%' }}
                        onDateChange={(date: Date): void => {}}
                        value={family.dob ? family?.dob.toDate() : null}
                    />
                    {inputComponent('right', 'relationship', family.relationship ?? null)}
                    {inputComponent('marginTopLayout', 'address', family.address ?? null)}
                    {inputComponent('left', 'pincode', family.pincode ?? null)}
                    {inputComponent('right', 'city', family.city ?? null)}
                    <Button
                        disabled={disabled}
                        fullWidth
                        variant="outlined"
                        size="large"
                        sx={{
                            margin: '5% 0 2% 0',
                            width: '80%',
                            fontSize: '20px',
                            lineHeight: '24px',
                            backgroundColor: 'rgba(208, 0, 112, 0.02)',
                            boxShadow: '0px 0px 0px 2px rgba(208, 0, 112, 0.4);',
                            border: '1px solid #8B004B'
                        }}
                        onClick={saveDetails}>
                        {loading && (
                            <div className="loading">
                                <CircularProgress
                                    style={{
                                        marginTop: '20px',
                                        float: 'right',
                                        marginRight: '19%'
                                    }}
                                />
                            </div>
                        )}
                        {!loading && <Typography variant="h5">{t('modifier')}</Typography>}
                    </Button>
                    <TitleFormPart text={t('mySuscription')} />
                    <Typography
                        variant="h6"
                        className={classes.suscriptionText}
                        sx={{ fontWeight: '550' }}>
                        {t('startSuscription')}
                    </Typography>
                    <DatePickerCustom
                        disabled
                        sx={{ marginRight: '6%', width: '100%' }}
                        onDateChange={(date: Date): void => {}}
                        value={data.startDate ? data?.startDate.toDate() : null}
                    />
                    <Typography
                        variant="h6"
                        className={classes.suscriptionText}
                        sx={{ fontWeight: '550' }}>
                        {t('endSuscription')}
                    </Typography>
                    <DatePickerCustom
                        disabled
                        sx={{ marginRight: '6%', width: '100%' }}
                        onDateChange={(date: Date): void => {}}
                        value={data.endDate ? data?.endDate.toDate() : null}
                    />
                    <Button
                        fullWidth
                        variant="outlined"
                        size="large"
                        sx={{
                            margin: '30px 0 2% 0',
                            width: '80%',
                            fontSize: '20px',
                            lineHeight: '24px',
                            backgroundColor: 'rgba(208, 0, 112, 0.02)',
                            boxShadow: '0px 0px 0px 2px rgba(208, 0, 112, 0.4);',
                            border: '1px solid #8B004B'
                        }}
                        onClick={(): void => {
                            setLogout(true);
                        }}>
                        <Typography variant="h5">{t('logOut')}</Typography>
                    </Button>
                </Box>
            </Box>
        </div>
    );

    return (
        <div>
            <DeactiveModal
                isModelOpen={logout}
                setModelOpen={setLogout}
                title={t('logout')}
                bodyText={t('confirmLogout')}
                buttonText={t('logout')}
                onButtonClick={(): void => {
                    logOut(user);
                }}
            />
            {patientSettingsScreen}
        </div>
    );
}
