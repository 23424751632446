import React, { FC } from 'react';

import styled from 'styled-components';
import { NUMBERS } from '../../typings';

import Button from './button';
// import { Container } from './styles';

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const Numbers: FC = () => (
    <Container>
        {([1, 2, 3, 4, 5, 6, 7, 8, 9] as NUMBERS[]).map((value) => (
            <Button key={value} value={value} />
        ))}
    </Container>
);

// eslint-disable-next-line import/no-default-export
export default Numbers;
