import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Roles } from '../../models/Roles';
// eslint-disable-next-line import/no-cycle
import { PatientSelector } from '../PatientSelector';
import { CustomCheckbox } from '../CustomCheckbox';
// eslint-disable-next-line import/no-cycle
import { Header } from './Header';
// eslint-disable-next-line import/no-cycle
import { SearchBox } from '../SearchBox';
// eslint-disable-next-line import/no-cycle
import { UserContext } from '../../providers/UserProvider';
// eslint-disable-next-line import/no-cycle
import { getPatientFromEstablishment, getPatientFromCode } from '../../services/cloudFirestore';
import { Patient } from '../../models/Patient';
import { UserDataProp } from '../../models/userDataProp';
import { ExerciseItem } from '../../models/ExerciseItem';

const CustomBox = styled(Box)(() => ({
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 598px)': {
        padding: '5%'
    }
}));

const SelectedImage = styled.img`
    min-width: 95%;
    max-width: 100%;
    min-height: 95%;
    max-height: 50vh;
    margin: auto;
`;

export interface StateInterface {
    patientsArray: Patient[];
    patientIdArray: (string | undefined)[];
}

interface SelectPatientProps {
    selectImage?: string;
    content?: ExerciseItem;
    setDataPatient: Function;
}

export function SelectPatient({
    selectImage,
    content,
    setDataPatient
}: SelectPatientProps): JSX.Element {
    const { t } = useTranslation();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const isSmallWidth = !useMediaQuery('(min-width:500px)');
    const [searchText, setSearchText] = useState<string>('');
    const [allPatientsIdListArray, setAllPatientsIdList] = useState<string[]>([]);
    const [allPatientsListArray, setAllPatientsList] = useState<Patient[]>();
    const [patientsIdListArray, setPatientsIdList] = useState<string[]>([]);
    const [patientsListArray, setPatientsList] = useState<Patient[]>();
    const [selectedPatients, setSelectCard] = useState<StateInterface>({
        patientsArray: [],
        patientIdArray: []
    });
    const [data, setData] = useState<ExerciseItem>({
        id: undefined,
        title: undefined,
        answer: undefined,
        exerciseImage: undefined,
        imageDetails: [],
        photoCapturedPlace: undefined,
        photoCapturedOn: undefined,
        anecdote: undefined,
        theme: undefined,
        patientList: undefined
    });

    useEffect(() => {
        if (content !== undefined) {
            setData({
                ...data,
                id: content.id ?? undefined,
                title: content.title,
                answer: content.answer,
                exerciseImage: content.exerciseImage,
                imageDetails: content.imageDetails,
                photoCapturedPlace: content.photoCapturedPlace,
                photoCapturedOn: content.photoCapturedOn,
                anecdote: content.anecdote,
                theme: content.theme,
                patientList: content.patientList
            });
        }
    }, []);

    useEffect(() => {
        const getPatientList = async (): Promise<void> => {
            let patients: Patient[] | null = [];
            if (user?.role === Roles.THERAPIST) {
                if (user && user.id && user.establishmentCode) {
                    patients = await getPatientFromEstablishment(user.establishmentCode);
                    if (patients) {
                        setPatientsList(patients);
                        setAllPatientsList(patients);
                    }
                }
                const list: string[] = [];
                if (patients) {
                    patients.forEach((patient) => {
                        if (patient.familyCode) {
                            list.push(patient.familyCode);
                        }
                    });
                }
                setPatientsIdList(list);
                setAllPatientsIdList(list);
            }
        };
        getPatientList();
    }, []);

    useEffect(() => {
        setData({ ...data, patientList: selectedPatients.patientIdArray });
        setDataPatient(selectedPatients.patientIdArray);
    }, [selectedPatients]);

    useEffect(() => {
        if (searchText && allPatientsListArray) {
            const filteredData = allPatientsListArray.filter((element) => {
                if (element.firstName) {
                    return element.firstName.toLowerCase().includes(searchText.toLowerCase());
                }
                return null;
            });
            setPatientsList(filteredData);
            const list: string[] = [];
            filteredData.forEach((patient) => {
                if (patient.familyCode) {
                    list.push(patient.familyCode);
                }
            });
            setPatientsIdList(list);
        } else {
            setPatientsList(allPatientsListArray);
            setPatientsIdList(allPatientsIdListArray);
        }
    }, [searchText]);

    useEffect(() => {
        const getPatientList = async (): Promise<void> => {
            if (content && content.patientList) {
                const list: Patient[] = [];
                await Promise.all(
                    content.patientList.map(async (element) => {
                        if (element) {
                            const item = await getPatientFromCode(element);
                            if (item && item.familyCode) {
                                list.push(item);
                            }
                        }
                    })
                );
                setSelectCard({
                    patientsArray: list,
                    patientIdArray: content.patientList ?? []
                });
            }
        };
        if (user?.role === Roles.THERAPIST) {
            getPatientList();
        }
    }, []);

    const selectPatient = (value: Patient): void => {
        if (selectedPatients.patientsArray.indexOf(value) === -1 && value.familyCode) {
            setSelectCard({
                patientsArray: [...selectedPatients.patientsArray, value],
                patientIdArray: [...selectedPatients.patientIdArray, value.familyCode]
            });
        } else {
            setSelectCard({
                patientsArray: selectedPatients.patientsArray.filter((e) => e !== value),
                patientIdArray: selectedPatients.patientIdArray.filter(
                    (e) => e !== value.familyCode
                )
            });
        }
    };

    const selectAllPatients = (): void => {
        if (patientsListArray) {
            if (selectedPatients.patientsArray.length !== patientsListArray?.length) {
                setSelectCard({
                    patientsArray: patientsListArray,
                    patientIdArray: patientsIdListArray
                });
            } else {
                setSelectCard({
                    patientsArray: [],
                    patientIdArray: []
                });
            }
        }
    };

    const getPatients = (): JSX.Element[] | null => {
        if (patientsListArray && user?.role === Roles.THERAPIST) {
            if (patientsListArray.length > 0) {
                return patientsListArray.map((item) => {
                    return (
                        <Grid
                            item
                            sx={{
                                padding: '1%'
                            }}
                            key={item.id}>
                            <PatientSelector
                                mediatheque
                                patientsArray={item}
                                isChecked={
                                    selectedPatients.patientIdArray.indexOf(item.familyCode) > -1
                                }
                                selectPatient={selectPatient}
                            />
                        </Grid>
                    );
                });
            }
        }
        return null;
    };

    const allItemsSelector = (): JSX.Element | null => {
        if (patientsListArray && user?.role === Roles.THERAPIST) {
            return (
                <Grid item xs={12} sm={6} sx={{ padding: '1%' }} key={2}>
                    <CustomBox>
                        <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                            <Typography
                                variant="h6"
                                color="#004680"
                                sx={{ fontWeight: 500, textAlign: isSmallWidth ? 'left' : '' }}>
                                {t('sendToFamilyOfPatient')}
                            </Typography>
                        </Box>
                        <Box sx={{ width: '20%', height: '80%' }}>
                            <CustomCheckbox
                                isChecked={
                                    selectedPatients.patientsArray.length ===
                                    patientsListArray.length
                                }
                                selectItem={selectAllPatients}
                                item={undefined}
                            />
                        </Box>
                    </CustomBox>
                </Grid>
            );
        }
        return null;
    };

    return (
        <Box height="100%">
            <Header
                text={
                    content
                        ? t('sharePictureWithFamilyPatientExplanation')
                        : t('shareVideoWithFamilyPatientExplanation')
                }
            />
            <Box
                sx={{ marginTop: isSmallWidth ? '10px' : '40px' }}
                display="flex"
                flexDirection={isSmallWidth ? 'column' : 'row'}
                justifyContent="space-around"
                alignContent="flex-start"
                alignItems="center">
                {selectImage && (
                    <Box
                        sx={{
                            width: isSmallWidth ? '90vw' : '40%',
                            marginBottom: '10px'
                        }}>
                        <SelectedImage src={selectImage} alt="yourImportImage" />
                    </Box>
                )}
                <Box
                    sx={{
                        width: !selectImage || (selectImage && isSmallWidth) ? '90vw' : '50vw'
                    }}>
                    <Typography
                        sx={{
                            textAlign: 'left',
                            fontWeight: 'bold',
                            marginBottom: '10px',
                            marginLeft: isSmallWidth ? '10px' : '5px'
                        }}
                        variant="h4"
                        color="#374653">
                        {t('selectFamilyToShare')}
                    </Typography>
                    <Grid container sx={{ width: '100%', margin: 'auto' }}>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                                padding: '1%'
                            }}
                            key={1}>
                            <SearchBox
                                placeholder={t('toSearch')}
                                onSearchChange={(value: string): void => setSearchText(value)}
                                hasSearchIcon
                            />
                        </Grid>
                        {allItemsSelector()}
                    </Grid>
                    <Box overflow="auto" height="40vh">
                        {getPatients()}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
