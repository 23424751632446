/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
import React, { useState, useRef, useEffect, useContext } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import { Avatar, Box, Button, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Card, Content, Grid, NewGameButton, Name, Numbers, Title } from './components';
import { configureStore, register } from './core';
import { GlobalStyles, theme } from './styles';
import { SuccessModal } from '../../../components/SuccessModal';
import continueGame from '../../../assets/continueGame.png';
import { ExerciseHeader } from '../../../components/ExerciseHeader';
import { FormModalAlert } from '../../common/FormModalAlert';
import { compareArrays } from './utils';
import { UserDataProp } from '../../../models/userDataProp';
import { UserContext } from '../../../providers/UserProvider';
import { Patient } from '../../../models/Patient';
import { getExerciseItem, getPatientUser } from '../../../services/cloudFirestore';
import { Roles } from '../../../models/Roles';
import global from './global';
import { ReviewExerciseAlerts } from '../../common/ReviewExerciseAlerts';
import { Exercise } from '../../../models/Exercise';
import { ExerciseItem } from '../../../models/ExerciseItem';
import { ExerciseType } from '../../../models/ExerciseType';

const { persistor, store } = configureStore();

interface LocationState {
    exercises: Exercise;
}

// eslint-disable-next-line jest/no-export
export function Sudoku(): JSX.Element {
    const { t } = useTranslation();
    const history = useHistory();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [exercise, setExercise] = useState<Exercise>();
    const location = useLocation<LocationState>();
    const [exerciseItem, setExerciseItem] = useState<ExerciseItem>();
    const [openReview, setOpenReview] = useState<boolean>(false);
    const [sudokuClues, setSudokuClues] = useState<number>(0);
    const [sudokuErrors, setSudokuErrors] = useState<number>(0);

    // const { solvedGrid } = store.getState();
    const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
    const [stopTest, setStopTest] = useState<boolean>(false);
    const [patient, setPatient] = useState<Patient>();
    const [difficultyLevel, setDifficultyLevel] = useState<number>(0);
    const [displayRules, setDisplayRules] = useState<boolean>(true);
    const [displayGrid, setDisplayGrid] = useState<boolean>(false);
    const [displayModalContinueExercise, setDisplayModalContinueExercise] = useState<boolean>(
        false
    );
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    );

    useEffect(() => {
        const getExerciseList = async (): Promise<void> => {
            if (user && user?.id) {
                const patientData = await getPatientUser(user?.id);
                if (patientData && patientData.sudokuDifficultyLevel) {
                    setPatient(patientData);
                    setDifficultyLevel(patientData.sudokuDifficultyLevel);
                    // localStorage.setItem('difficultyLevel', `${patientData.sudokuDifficultyLevel}`);
                    if (
                        global.difficultyLevel &&
                        global.difficultyLevel !== patientData.sudokuDifficultyLevel
                    ) {
                        global.difficultyLevel = patientData.sudokuDifficultyLevel;
                        store.dispatch({ type: 'CREATE_GRID' });
                    }
                    global.difficultyLevel = patientData.sudokuDifficultyLevel;
                    setDisplayGrid(true);
                }
                if (user?.role === Roles.ADMIN || user.role === Roles.THERAPIST) {
                    setDifficultyLevel(3);
                    global.difficultyLevel = 3;
                }
                if (
                    store.getState().challengeGrid &&
                    store.getState().workingGrid &&
                    !compareArrays(store.getState().challengeGrid, store.getState().workingGrid)
                ) {
                    setDisplayModalContinueExercise(true);
                }
            }

            if (location.state.exercises) {
                setExercise(location.state.exercises);
                if (
                    location.state.exercises.exerciseImages &&
                    location.state.exercises.exerciseImages[0] &&
                    typeof location.state.exercises.exerciseImages[0] === 'string'
                ) {
                    const item = await getExerciseItem(location.state.exercises.exerciseImages[0]);
                    if (item) {
                        setExerciseItem(item);
                    }
                }
            }
        };
        getExerciseList();
    }, []);

    useEffect(() => {
        if (user?.role === Roles.PATIENT) {
            const startTimer = localStorage.getItem('startTime');
            const count = localStorage.getItem('countErrorAndClue');
            if (!startTimer) {
                localStorage.setItem('startTime', new Date().toString());
            }
        }
    }, []);

    const test = (): void => {
        const { solvedGrid } = store.getState();
        if (stopTest || !solvedGrid || !store.getState().workingGrid) return;
        if (!compareArrays(solvedGrid, store.getState().workingGrid)) {
            setTimeout(test, 1000);
        } else {
            setOpenSuccessModal(true);
            setStopTest(true);
            setTimeout(() => {
                setOpenSuccessModal(false);
                setOpenReview(true);
                store.dispatch({ type: 'CREATE_GRID' });
            }, 3000);
        }
    };
    // eslint-disable-next-line jest/no-disabled-tests, jest/expect-expect
    test();

    const clue = (): void => {
        // get first empty cell and fill it with the correct value
        const { workingGrid } = store.getState();
        const { solvedGrid } = store.getState();
        if (workingGrid && solvedGrid) {
            for (let i = 0; i < 9; i++) {
                for (let j = 0; j < 9; j++) {
                    if (workingGrid[i][j] === 0) {
                        const temp = store.dispatch({
                            type: 'FILL_BLOCK',
                            value: solvedGrid[i][j],
                            coords: [i, j]
                        });
                        setSudokuClues(sudokuClues + 1);
                        return;
                    }
                }
            }
        }
    };

    // useEffect(() => {
    //     if (displayRules && localStorage.getItem('persist:root') !== null) {
    //         const temps = {
    //             ...JSON.parse(localStorage.getItem('persist:root') ?? ''),
    //             selectedBlock: ''
    //         };
    //         localStorage.setItem('persist:root', JSON.stringify(temps));
    //     }
    // }, [displayRules]);

    return (
        <Box flex={1} display="flex" flexDirection="column" height="100vh" width="100%">
            <SuccessModal
                isModelOpen={openSuccessModal}
                setModelOpen={setOpenSuccessModal}
                isSuccess
                isPlaySound
                isFirework
                nbModal={0}
            />
            {openReview && (
                <ReviewExerciseAlerts
                    exercise={exercise}
                    exerciseType={ExerciseType.SUDOKU}
                    difficultyLevel={difficultyLevel}
                    // sudokuTime={sudokuTime}
                    sudokuErrors={store.getState().errorCount}
                    sudokuClues={sudokuClues}
                />
            )}
            {displayModalContinueExercise && (
                // create modal to continue or not exercise
                <Box
                    sx={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        width: '100%',
                        height: '100%',
                        backgroundColor: '#5C6670CC',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 3600
                    }}>
                    <Box
                        sx={{
                            width: '80%',
                            height: 'fit-content',
                            padding: '20px',
                            backgroundColor: '#ffffff',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '100px',
                            flexDirection: 'column'
                        }}>
                        <img src={continueGame} alt="logo" style={{ width: '30%' }} />
                        <Typography
                            variant="h4"
                            sx={{
                                color: '#004680',
                                fontWeight: '700',
                                textAlign: 'center',
                                marginTop: '20px',
                                fontFamily: 'Luciole-Regular',
                                fontSize: '40px !important'
                            }}>
                            {t('pausedExercise')}
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '20px',
                                width: '100%'
                            }}>
                            <Button
                                sx={{
                                    width: '25%',
                                    height: '65px',
                                    backgroundColor: '#00DF76',
                                    color: '#ffffff',
                                    borderRadius: '10px',
                                    marginRight: '20px',
                                    fontFamily: 'Luciole-Regular',
                                    fontSize: '20px !important',
                                    fontWeight: '600 !important'
                                }}
                                onClick={(): void => {
                                    setDisplayRules(false);
                                    setDisplayModalContinueExercise(false);
                                    setDisplayGrid(true);
                                }}>
                                {t('pausedExercise.continue')}
                            </Button>
                            <Button
                                sx={{
                                    width: '25%',
                                    height: '65px',
                                    backgroundColor: '#FFFFFF',
                                    background:
                                        'linear-gradient(0deg, rgba(208, 0, 112, 0.02), rgba(208, 0, 112, 0.02)), #FFFFFF',
                                    border: '1px solid #8B004B',
                                    boxShadow: '0px 0px 0px 2px rgba(208, 0, 112, 0.4)',
                                    borderRadius: '12px',
                                    color: '#D00070',
                                    marginLeft: '20px',
                                    fontFamily: 'Luciole-Regular',
                                    fontSize: '20px !important',
                                    fontWeight: '600 !important'
                                }}
                                onClick={(): void => {
                                    setDisplayModalContinueExercise(false);
                                    store.dispatch({ type: 'CREATE_GRID' });
                                    setDisplayRules(true);
                                }}>
                                {t('pausedExercise.new')}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            )}
            <Box sx={{ height: '100px' }}>
                <ExerciseHeader
                    exerciseName={t('Sudokin')}
                    // level={patient && patient.pongDifficultyLevel ? patient.pongDifficultyLevel : 0}
                    level={difficultyLevel}
                    isPuzzle
                    isExerciseSelected={!displayRules}
                    difficultyLevel={6}
                    handleClueClick={(): void => clue()}
                    exerciseType={ExerciseType.SUDOKU}
                />
            </Box>
            <Box
                sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: '#f5f5f5'
                }}>
                <ThemeProvider theme={theme}>
                    {/* <GlobalStyles /> */}
                    <Provider store={store}>
                        <PersistGate loading={null} persistor={persistor}>
                            <Box
                                sx={{
                                    height: displayRules ? '80%' : '100%',
                                    maxHeight: displayRules ? '80%' : '100%',
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    alignItems: 'center'
                                }}>
                                <Box
                                    sx={{
                                        height: '100%',
                                        width: displayRules ? '60%' : '100%',
                                        // width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        position: 'relative'
                                    }}>
                                    <Content
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            maxWidth: '90%',
                                            width: '90%',
                                            position: 'relative'
                                        }}>
                                        <Card
                                            style={{
                                                fontFamily: 'Luciole-Bold',
                                                maxWidth: '70vh',
                                                width: isMobile
                                                    ? displayRules
                                                        ? '90%'
                                                        : '50%'
                                                    : displayRules
                                                    ? '70%'
                                                    : '40%',
                                                height: displayRules ? '90%' : '50%',
                                                fontSize: '25px'
                                            }}>
                                            {/* <NewGameButton /> */}
                                            <Box
                                                sx={{
                                                    pointerEvents: displayRules ? 'none' : 'all'
                                                }}>
                                                {displayGrid && <Grid />}
                                                {/* <Grid /> */}
                                            </Box>
                                            {/* <Numbers /> */}
                                        </Card>
                                        {!displayRules && (
                                            <Box
                                                sx={{
                                                    // height: '100%',
                                                    width: '40%',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    marginLeft: '20px',
                                                    marginTop: '10px',
                                                    marginBottom: '10px'
                                                }}>
                                                <Button
                                                    onClick={(): void => setDisplayRules(true)}
                                                    sx={{
                                                        width: '100%',
                                                        // height: '45px',
                                                        border: '1px solid #5C6670',
                                                        borderRadius: '5px',
                                                        backgroundColor: '#FFFFFF',
                                                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
                                                    }}>
                                                    <Typography
                                                        variant="h4"
                                                        sx={{
                                                            fontFamily: 'Luciole-Regular',
                                                            fontWeight: '400',
                                                            fontSize: '18px',
                                                            color: '#5C6670'
                                                        }}>
                                                        {t('lookRules')}
                                                    </Typography>
                                                </Button>
                                                <Numbers />
                                                <NewGameButton />
                                            </Box>
                                        )}
                                    </Content>
                                </Box>
                                {displayRules && (
                                    <Box
                                        sx={{
                                            height: '100%',
                                            width: '50%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            paddingRight: '5%'
                                        }}>
                                        <Typography
                                            variant="h4"
                                            sx={{
                                                width: '100%',
                                                textAlign: 'left',
                                                fontFamily: 'Luciole-Regular',
                                                fontWeight: '400',
                                                fontSize: '22px',
                                                color: '#000000',
                                                marginBottom: '5%'
                                            }}>
                                            {t('sudoku.rules.p1')}
                                        </Typography>
                                        <Typography
                                            variant="h4"
                                            sx={{
                                                width: '100%',
                                                textAlign: 'left',
                                                fontFamily: 'Luciole-Regular',
                                                fontWeight: '400',
                                                fontSize: '22px',
                                                color: '#000000'
                                            }}>
                                            {t('sudoku.rules.p2')}
                                        </Typography>
                                        <Typography
                                            variant="h4"
                                            sx={{
                                                width: '100%',
                                                textAlign: 'left',
                                                fontFamily: 'Luciole-Regular',
                                                fontWeight: '400',
                                                fontSize: '22px',
                                                color: '#000000',
                                                marginBottom: '5%',
                                                lineHeight: '2.5rem'
                                            }}>
                                            <ul>
                                                <li>{t('sudoku.rule1')}</li>
                                                <li>{t('sudoku.rule2')}</li>
                                                <li>{t('sudoku.rule3')}</li>
                                            </ul>
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                            <Box
                                sx={{
                                    height: '20%',
                                    width: '100%',
                                    display: displayRules ? 'flex' : 'none',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                <Button
                                    onClick={(): void => setDisplayRules(false)}
                                    sx={{
                                        marginTop: '0%',
                                        border: '1px solid #8B004B',
                                        borderRadius: '12px',
                                        boxShadow: '0px 0px 0px 2px rgba(208, 0, 112, 0.4)',
                                        width: '400px',
                                        height: '61px',
                                        fontSize: '24px',
                                        fontWeight: '600',
                                        fontFamily: 'Luciole-Regular'
                                    }}>
                                    {store.getState().challengeGrid &&
                                    store.getState().workingGrid &&
                                    compareArrays(
                                        store.getState().challengeGrid,
                                        store.getState().workingGrid
                                    )
                                        ? t('StartSudoku')
                                        : t('reStartSudoku')}
                                </Button>
                            </Box>
                        </PersistGate>
                    </Provider>
                </ThemeProvider>
            </Box>
        </Box>
    );
}
