import React from 'react';

export function TocSvgIcon(): JSX.Element {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 25 25"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3 9h14V7H3v2zm0 4h14v-2H3v2zm0 4h14v-2H3v2zm16 0h2v-2h-2v2zm0-10v2h2V7h-2zm0 6h2v-2h-2v2z"
                fill="#FF0089"
            />
        </svg>
    );
}
