/* eslint-disable import/no-cycle */
import {
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    Typography
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import styled from 'styled-components';
import OutlinedInput from '@mui/material/OutlinedInput';
import InfiniteScroll from 'react-infinite-scroller';
import { Roles } from '../../models/Roles';
import giftIcon from '../../assets/giftIcon.png';
import { CreateNewPhotoCard } from '../../components/CreateNewPhotoCard';
import { UserContext } from '../../providers/UserProvider';
import { Exercise } from '../../models/Exercise';
import { ExitCustomizationModal } from '../../components/ExitCustomizationModal';
import { UserDataProp } from '../../models/userDataProp';
import { ChooseVideo } from './ChooseVideo';
import { AddYoutubeLink } from './AddYoutubeLink';
import { ExerciseSummary } from './ExerciseSummary';
import { MediaInformation } from '../../models/MediaInformation';
import {
    getPatientFromEstablishment,
    getPersonalVideo,
    getPersonalYoutubeVideo
} from '../../services/cloudFirestore';
import { VideoCards } from '../../components/VideoCards';
import { ModalContainer } from '../../components/ModalContainer';
import { Header } from '../../components/CreateAndEdit/Header';
import { TitleAndIntro } from '../../components/CreateAndEdit/TitleAndIntro';
import { AlertModal } from '../../components/AlertModal';
import { ButtonsCreateAndEdit } from '../../components/CreateAndEdit/ButtonsCreateAndEdit';
import { Patient } from '../../models/Patient';

const useStyles = makeStyles(() => ({
    selectedCard: {
        width: '100%',
        cursor: 'pointer',
        border: '3px solid #D00070',
        boxShadow: '2px 2px 10px 0px #52525214',
        borderRadius: '9px'
    },
    deSelectedCard: {
        width: '100%',
        cursor: 'pointer',
        border: '1px solid #E3E6E8',
        boxShadow: '2px 2px 10px 0px #52525214',
        borderRadius: '9px'
    },
    newPhotoCard: {
        padding: '1%',
        cursor: 'pointer'
    },
    selectAuthor: {
        '& fieldset': { borderColor: '#F0F0F0 !important' },
        '@media (max-width: 598px)': {
            marginBottom: '2%'
        },
        '@media (min-width: 598px)': {
            marginLeft: 'auto'
        }
    }
}));

const NextStepButton = styled(Button)(() => ({
    margin: '2% 0%',
    border: '1px solid #8B004B',
    boxShadow: '0px 0px 0px 2px rgba(208, 0, 112, 0.4)',
    '@media (min-width: 598px)': {
        width: '40%',
        padding: '0px'
    }
}));

interface StepperProps {
    content: Exercise;
    currentStep: React.Dispatch<React.SetStateAction<number>>;
}

export function ExerciseMedia({ content, currentStep }: StepperProps): JSX.Element {
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const { t } = useTranslation();
    const classes = useStyles();
    const [clickedCard, setClickedCard] = useState<string | undefined>('');
    const [media, setMedia] = useState<File[]>();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [firstPart, setFirstPart] = useState<boolean>(true);
    const [secondPart, setSecondPart] = useState<boolean>(false);
    const [mediaListPersonalVideo, setMediaListPersonalVideo] = useState<MediaInformation[]>([]);
    const [mediaListYoutubeVideo, setMediaListYoutubeVideo] = useState<MediaInformation[]>([]);
    const [activeStep, setStep] = useState<number>(4);
    const [youtubePage, setYoutubePage] = useState<boolean>(false);
    const [selectedVideoCard, setSelectedVideoCard] = useState<string>();
    const [selectedVideoCardTitle, setSelectedVideoCardTitle] = useState<string>();
    const [selectedYoutubeCard, setSelectedYoutubeCard] = useState<string>();
    const [selectedYoutubeCardTitle, setSelectedYoutubeCardTitle] = useState<string>();
    const [openModalAlert, setOpenModalAlert] = useState<boolean>(false);
    const [patientList, setPatientsList] = useState<Patient[]>([]);
    const [patientSelect, setPatientSelect] = useState<string>('');
    const [selectOpen, setSelectOpen] = useState<boolean>(false);
    const [nbYtVideoToDisplay, setNbYtVideoToDisplay] = useState<number>(5);
    const [nbPersonalVideoToDisplay, setNbPersonalVideoToDisplay] = useState<number>(5);
    const [data, setData] = useState<Exercise>({
        id: content.id,
        exerciseType: content.exerciseType,
        patientList: content.patientList,
        exerciseName: content.exerciseName,
        authorId: content.authorId,
        exerciseImages: content.exerciseImages,
        review: content.review,
        encouragementMessage: content.encouragementMessage,
        congratulationsMessage: content.congratulationsMessage,
        rewardPhoto: content.rewardPhoto,
        rewardVideo: content.rewardVideo,
        establishmentCode: content.establishmentCode,
        establishmentList: content.establishmentList,
        familyCode: content.familyCode,
        theme: content.theme
    });

    // Get all the patient of the therapist
    useEffect(() => {
        const getPatientOfTherapist = async (): Promise<void> => {
            let patients: Patient[] | null = [];
            if (user?.role === Roles.THERAPIST) {
                if (user && user.id && user.establishmentCode) {
                    patients = await getPatientFromEstablishment(user.establishmentCode);
                    if (patients) {
                        setPatientsList(patients);
                    }
                }
            }
        };
        if (user && user?.role === Roles.THERAPIST) {
            getPatientOfTherapist();
        }
    }, []);

    useEffect(() => {
        const getItemList = async (): Promise<void> => {
            if (user && user.id) {
                let idForSearch = '';
                if (!patientSelect || patientSelect === 'empty') {
                    if (user.role === Roles.FAMILY && user.familyCode) {
                        idForSearch = user.familyCode;
                    }
                    if (user.role === Roles.THERAPIST && user.establishmentCode) {
                        idForSearch = user.establishmentCode;
                    }
                    if (user.role === Roles.ADMIN) {
                        idForSearch = user.id;
                    }
                } else {
                    idForSearch = patientSelect;
                }
                let secondIdForSearch = false;
                if (user.role === Roles.THERAPIST && patientSelect && patientSelect !== 'empty') {
                    secondIdForSearch = true;
                }
                if (user.role === Roles.FAMILY) {
                    secondIdForSearch = true;
                }
                if (idForSearch !== '') {
                    const videoItemData = await getPersonalVideo(
                        idForSearch,
                        secondIdForSearch === true ? user.establishmentCode : ''
                    );
                    if (videoItemData) {
                        const videoItem = videoItemData;
                        setMediaListPersonalVideo(_.orderBy(videoItem, ['createdDate'], ['desc']));
                    } else {
                        setMediaListPersonalVideo([]);
                    }
                    const youtubeVideoItemData = await getPersonalYoutubeVideo(
                        idForSearch,
                        secondIdForSearch === true ? user.establishmentCode : ''
                    );
                    if (youtubeVideoItemData) {
                        const youtubeItem = youtubeVideoItemData;
                        setMediaListYoutubeVideo(_.orderBy(youtubeItem, ['createdDate'], ['desc']));
                    } else {
                        setMediaListYoutubeVideo([]);
                    }
                }
            }
        };
        getItemList();
    }, [media, youtubePage, firstPart, patientSelect]);

    useEffect(() => {
        if (content.rewardPhoto !== undefined && typeof content.rewardPhoto === 'string') {
            setSelectedVideoCard(content.rewardPhoto);
        }
        if (content.rewardVideo !== undefined && typeof content.rewardVideo === 'string') {
            setSelectedYoutubeCard(content.rewardVideo);
        }
    }, []);

    const handleChange = (
        state: string,
        value: string | undefined | null | (string | undefined)[]
    ): void => {
        setData({ ...data, [state]: value });
    };

    const handleNextStep = (): void => {
        if (firstPart && !secondPart) {
            setFirstPart(false);
            setSecondPart(true);
        }
        if (!firstPart && secondPart) {
            setStep(activeStep + 1);
        }
    };

    const handlePreviousStep = (): void => {
        if (firstPart && !secondPart && user && user.role === Roles.FAMILY) {
            currentStep(activeStep - 2);
            setStep(activeStep - 2);
        }
        if (
            firstPart &&
            !secondPart &&
            user &&
            (user.role === Roles.THERAPIST || user.role === Roles.ADMIN)
        ) {
            currentStep(activeStep - 1);
            setStep(activeStep - 1);
        }
        if (!firstPart && secondPart) {
            setFirstPart(true);
            setSecondPart(false);
        }
    };

    const NewPhotoCard = (): JSX.Element => {
        if (firstPart) {
            return (
                <Grid
                    item
                    xs
                    sx={{
                        flexGrow: '0 !important',
                        display: 'block'
                    }}>
                    <CreateNewPhotoCard
                        bodyText={t('importYoutubeLink')}
                        type="youtubeLink"
                        isYoutubePage={(value: boolean): void => setYoutubePage(value)}
                    />
                </Grid>
            );
        }
        return (
            <Grid
                item
                xs
                sx={{
                    flexGrow: '0 !important',
                    display: 'block'
                }}>
                <CreateNewPhotoCard
                    setMedia={setMedia}
                    bodyText={t('importPersonalVideo')}
                    type="video"
                    setALert={(): void => setOpenModalAlert(true)}
                />
            </Grid>
        );
    };

    const getPatientList = (): JSX.Element[] | null => {
        if (patientList && patientList.length > 0) {
            return patientList.map((item) => {
                return (
                    <MenuItem
                        key={item.familyCode}
                        value={item.familyCode}>{`${item.firstName} ${item.lastName}`}</MenuItem>
                );
            });
        }
        return null;
    };

    const handleVideoCard = (url: string, title: string): void => {
        if (selectedVideoCard === url) {
            setSelectedVideoCard(undefined);
            handleChange('rewardPhoto', undefined);
        } else {
            setSelectedVideoCard(url);
            setSelectedVideoCardTitle(title);
            handleChange('rewardPhoto', url);
        }
    };

    const handleYoutubeCard = (url: string, title: string): void => {
        if (selectedYoutubeCard === url) {
            setSelectedYoutubeCard(undefined);
            handleChange('rewardVideo', undefined);
        } else {
            setSelectedYoutubeCard(url);
            setSelectedYoutubeCardTitle(title);
            handleChange('rewardVideo', url);
        }
    };

    const getExercises = (): JSX.Element[] | null => {
        if (mediaListPersonalVideo && mediaListYoutubeVideo) {
            if (activeStep === 4 && firstPart) {
                return mediaListYoutubeVideo.slice(0, nbYtVideoToDisplay).map((item, index) => {
                    let bgColor;
                    const number = index % 4;
                    switch (number) {
                        case 0:
                            bgColor = '#FF008980';
                            break;
                        case 1:
                            bgColor = '#3DECCF80';
                            break;
                        case 2:
                            bgColor = '#458ff680';
                            break;
                        case 3:
                            bgColor = '#6B00AD80';
                            break;
                        default:
                            break;
                    }
                    return (
                        <Grid
                            item
                            // xs={6}
                            // sm={3}
                            xs
                            sx={{
                                // padding: '1%'
                                flexGrow: '0 !important',
                                display: 'block'
                            }}
                            onClick={(): void => {
                                if (item.videoUrl && typeof item.videoUrl === 'string') {
                                    handleYoutubeCard(item.videoUrl, item.title ? item.title : '');
                                }
                            }}
                            key={item.title}>
                            <VideoCards
                                classReceived={
                                    selectedYoutubeCard === item.videoUrl
                                        ? classes.selectedCard
                                        : classes.deSelectedCard
                                }
                                onCardClicked={(value: string): void => setClickedCard(value)}
                                onLeaveCard={(): void => setClickedCard('')}
                                item={item}
                                bgColor={bgColor}
                                isSelected={clickedCard === item.title}
                                youtubePage
                                desactivatePlayButton
                            />
                        </Grid>
                    );
                });
            }
            if (activeStep === 4 && secondPart) {
                // Add new element into the array cause Apple device doesn't make appear the first video
                // if (mediaListPersonalVideo[0].title !== 'no') {
                //     mediaListPersonalVideo.unshift({
                //         createdDate: mediaListPersonalVideo[0].createdDate,
                //         title: 'no',
                //         videoUrl: 'no'
                //     });
                // }
                return mediaListPersonalVideo
                    .slice(0, nbPersonalVideoToDisplay)
                    .map((item, index) => {
                        let bgColor;
                        const number = index % 4;
                        switch (number) {
                            case 0:
                                bgColor = '#FF008980';
                                break;
                            case 1:
                                bgColor = '#3DECCF80';
                                break;
                            case 2:
                                bgColor = '#458ff680';
                                break;
                            case 3:
                                bgColor = '#6B00AD80';
                                break;
                            default:
                                break;
                        }
                        return (
                            <Grid
                                item
                                xs
                                sx={{
                                    flexGrow: '0 !important',
                                    display: 'block'
                                }}
                                onClick={(): void => {
                                    if (item.videoUrl && typeof item.videoUrl === 'string') {
                                        handleVideoCard(
                                            item.videoUrl,
                                            item.title ? item.title : ''
                                        );
                                    }
                                }}
                                key={item.title}>
                                <VideoCards
                                    classReceived={
                                        selectedVideoCard === item.videoUrl
                                            ? classes.selectedCard
                                            : classes.deSelectedCard
                                    }
                                    onCardClicked={(value: string): void => setClickedCard(value)}
                                    onLeaveCard={(): void => setClickedCard('')}
                                    item={item}
                                    bgColor={bgColor}
                                    isSelected={clickedCard === item.title}
                                    desactivatePlayButton
                                />
                            </Grid>
                        );
                    });
            }
        }
        return null;
    };

    const loadFuncYtVideo = (): void => {
        if (nbYtVideoToDisplay + 4 <= mediaListYoutubeVideo.length) {
            setNbYtVideoToDisplay(nbYtVideoToDisplay + 4);
        } else {
            setNbYtVideoToDisplay(mediaListYoutubeVideo.length);
        }
    };

    const loadFuncPersonalVideo = (): void => {
        if (nbPersonalVideoToDisplay + 4 <= mediaListPersonalVideo.length) {
            setNbPersonalVideoToDisplay(nbPersonalVideoToDisplay + 4);
        } else {
            setNbPersonalVideoToDisplay(mediaListPersonalVideo.length);
        }
    };

    const whichText = (): string => {
        if (firstPart) {
            if (user?.role === Roles.FAMILY) {
                return t('sendYoutubeVideoFamily');
            }
            return t('sendYoutubeVideoTherapist');
        }
        if (secondPart) {
            if (user?.role === Roles.FAMILY) {
                return t('sendPersonalVideoFamily');
            }
            return t('sendPersonalVideoTherapist');
        }
        return '';
    };

    const screen = (
        <div>
            <Box sx={{ boxShadow: 'inset 0px -1px 0px #E3E6E8' }}>
                <Header
                    setOpenModal={setOpenModal}
                    type={data.exerciseType}
                    activeStep={activeStep}
                    user={user}
                />
            </Box>
            <Box
                sx={{
                    backgroundColor: '#F8F9F9',
                    boxShadow: 'inset 0px -1px 0px rgba(172, 188, 199, 0.4)'
                }}>
                <TitleAndIntro
                    type={data.exerciseType}
                    activeStep={activeStep}
                    user={user}
                    icon={giftIcon}
                    endBody={whichText()}
                    body={firstPart ? t('chooseYoutubeLink') : t('choosePersonalVideo')}
                />
            </Box>
            {user?.role === Roles.THERAPIST && (
                <FormControl className={classes.selectAuthor} sx={{ minWidth: 200 }}>
                    <InputLabel id="demo-multiple-name-label">{t('authorPicture')}</InputLabel>
                    <Select
                        sx={{
                            borderRadius: '20px',
                            backgroundColor: selectOpen ? '#3387CC1A' : '#F0F0F0'
                        }}
                        labelId="patient-select-label"
                        id="patient-select"
                        value={patientSelect}
                        input={<OutlinedInput label="Auteur" />}
                        onOpen={(): void => {
                            setSelectOpen(!selectOpen);
                        }}
                        onClose={(): void => {
                            setSelectOpen(!selectOpen);
                        }}
                        onChange={(event): void => {
                            setPatientSelect(event.target.value);
                        }}>
                        <MenuItem key="empty" value="empty">
                            {t('myPicture')}
                        </MenuItem>
                        {getPatientList()}
                    </Select>
                </FormControl>
            )}
            <InfiniteScroll
                pageStart={1}
                loadMore={(): void => {
                    if (firstPart && mediaListYoutubeVideo.length !== 0) {
                        loadFuncYtVideo();
                    }
                    if (secondPart && mediaListPersonalVideo.length !== 0) {
                        loadFuncPersonalVideo();
                    }
                }}
                hasMore={true || false}
                loader={
                    <div className="loader" key={0}>
                        {firstPart && nbYtVideoToDisplay !== mediaListYoutubeVideo.length
                            ? t('loading')
                            : ''}
                        {secondPart && nbPersonalVideoToDisplay !== mediaListPersonalVideo.length
                            ? t('loading')
                            : ''}
                    </div>
                }>
                <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={window.innerWidth < 520 ? 2 : 5}
                    sx={{ justifyContent: 'center' }}>
                    {activeStep === 4 ? NewPhotoCard() : null}
                    {getExercises()}
                    {firstPart &&
                        mediaListYoutubeVideo.length === 0 &&
                        Array.from(Array(nbYtVideoToDisplay).keys()).map((item) => {
                            let bgColor;
                            const number = item % 4;
                            switch (number) {
                                case 0:
                                    bgColor = '#FF008980';
                                    break;
                                case 1:
                                    bgColor = '#3DECCF80';
                                    break;
                                case 2:
                                    bgColor = '#458ff680';
                                    break;
                                case 3:
                                    bgColor = '#6B00AD80';
                                    break;
                                default:
                                    break;
                            }

                            return (
                                <Grid
                                    item
                                    xs
                                    sx={{
                                        flexGrow: '0 !important',
                                        display: 'block'
                                    }}>
                                    <Box
                                        sx={{
                                            height:
                                                window.innerWidth < 520
                                                    ? 'calc(100vw / 2 + 20px)'
                                                    : '290px',
                                            width:
                                                window.innerWidth < 520
                                                    ? 'calc(100vw / 2 - 20px)'
                                                    : '220px',
                                            borderRadius: '9px',
                                            padding: '10px',
                                            boxShadow:
                                                '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
                                        }}>
                                        <Skeleton
                                            variant="rectangular"
                                            style={{
                                                backgroundColor: bgColor,
                                                borderRadius: '9px',
                                                width: '100%',
                                                height: '100%'
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            );
                        })}
                </Grid>
            </InfiniteScroll>
            <div style={{ height: window.innerWidth < 600 ? '190px' : '81px', width: '100%' }} />
            <Box
                style={{
                    position: 'fixed',
                    zIndex: '1000',
                    bottom: '0',
                    width: '100%',
                    backgroundColor: '#FFFFFF'
                }}>
                <ButtonsCreateAndEdit
                    disabled={false}
                    nextText={
                        (activeStep === 4 && !secondPart && selectedYoutubeCard) ||
                        (activeStep === 4 && secondPart && selectedVideoCard)
                            ? t('nextStep')
                            : t('skipObjective')
                    }
                    nextClick={(): void => handleNextStep()}
                    previousClick={(): void => handlePreviousStep()}
                />
            </Box>
        </div>
    );

    return (
        <div>
            {!youtubePage && activeStep === 4 && firstPart && screen}
            {youtubePage && activeStep === 4 && firstPart && (
                <AddYoutubeLink isYoutubeLink={setYoutubePage} />
            )}
            {!media && activeStep === 4 && secondPart && screen}
            {media && secondPart && activeStep === 4 && (
                <ChooseVideo videoFile={media} setMedia={setMedia} />
            )}
            {activeStep === 5 && (
                <ExerciseSummary
                    content={data}
                    videoTitle={{
                        video: selectedVideoCardTitle ?? '',
                        videoYt: selectedYoutubeCardTitle ?? ''
                    }}
                    currentStep={setStep}
                />
            )}
            <AlertModal
                openModalAlert={openModalAlert}
                setOpenModalAlert={setOpenModalAlert}
                setMedia={setMedia}
            />
            <ExitCustomizationModal openModal={openModal} setOpenModal={setOpenModal} />
        </div>
    );
}
