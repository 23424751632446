import { Box, Grid, Typography, Button, Skeleton } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import InfiniteScroll from 'react-infinite-scroller';
import { Roles } from '../../models/Roles';
import { CreateNewPhotoCard } from '../../components/CreateNewPhotoCard';
import { ExerciseCards } from '../../components/ExerciseCards';
import { SearchBox } from '../../components/SearchBox';
import { UserContext } from '../../providers/UserProvider';
import {
    getCompletedTherapistExercises,
    getExerciseItem,
    getExercises,
    getFamilyUser,
    getTherapistUser
} from '../../services/cloudFirestore';
import { PatientHomeData } from '../../models/PatientHomeData';
import { Exercise } from '../../models/Exercise';
import backButton from '../../assets/backButton.png';
import stimulthequeLogo from '../../assets/stimulthequeLogo.png';
import { DashboardLayout } from '../../layouts/DashboardLayout';
import { UserDataProp } from '../../models/userDataProp';
import { Navbar } from '../../components/Headers/Navbar';
import { AddExoToEstablishmentModal } from '../../components/AddExoToEstablishmentModal';

const useStyles = makeStyles(() => ({
    headerLayoutMobile: {
        '@media (max-width: 598px)': {
            justifyContent: 'space-between'
        },
        '@media (min-width: 598px)': {
            justifyContent: 'center'
        }
    }
}));

interface PictureArray {
    imageToSearch: string;
    imageToShow: string;
}

export function CustomizeExercise(): JSX.Element {
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const [searchText, setSearchText] = useState<string>('');
    const [exercises, setExercises] = useState<Exercise[]>([]);
    const [exerciseList, setExerciseList] = useState<PatientHomeData[]>([]);
    const [clickedCard, setClickedCard] = useState<string | undefined>('');
    const [exercisesList, setExercisesList] = useState<Exercise[]>([]);
    const [successModal, setSuccessModal] = useState<boolean>(false);
    const [nbItemToDisplay, setNbItemToDisplay] = useState<number>(5);

    // Change sort with a sort function !
    const getExerciseImage = async (exercise: Exercise[]): Promise<PictureArray[]> => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const pictureArray: PictureArray[] = [];
        await Promise.all(
            exercise.map(async (item) => {
                if (item.exerciseImages && item.exerciseImages[0]) {
                    const pictureItem = await getExerciseItem(item.exerciseImages[0]);
                    if (item && pictureItem && typeof pictureItem.exerciseImage === 'string') {
                        if (
                            !pictureArray.find(
                                (el: { imageToShow: string }) =>
                                    el.imageToShow === pictureItem.exerciseImage
                            )
                        ) {
                            pictureArray.push({
                                imageToSearch: item.exerciseImages[0],
                                imageToShow: pictureItem.exerciseImage
                            });
                        }
                    }
                }
            })
        );
        return pictureArray;
    };

    const getExerciseItemData = async (exerciseData: Exercise[]): Promise<void> => {
        const dataList: PatientHomeData[] = [];
        const arrayOfPicture = await getExerciseImage(exerciseData);
        exerciseData.forEach((exercise) => {
            const picture = arrayOfPicture.find((el): PictureArray | null => {
                if (
                    exercise.exerciseImages !== undefined &&
                    exercise.exerciseImages[0] !== undefined &&
                    el.imageToSearch === exercise.exerciseImages[0]
                ) {
                    return el;
                }
                return null;
            });
            if (picture) {
                dataList.push({
                    exercise,
                    exerciseImage: picture.imageToShow
                });
            }
        });

        setExerciseList(dataList);
    };

    useEffect(() => {
        const searchData = exercises.filter((element) => {
            if (element.exerciseName) {
                return element.exerciseName.toLowerCase().includes(searchText.toLowerCase());
            }
            return null;
        });
        getExerciseItemData(searchData);
    }, [searchText]);

    useEffect(() => {
        const getExerciseList = async (): Promise<void> => {
            if (user && user.id) {
                const exercisesData = await getExercises();
                let filteredExercises = exercisesData.filter(
                    (item) => item.authorType === user?.role
                );
                if (user.role === Roles.THERAPIST && user.id) {
                    const therapist = await getTherapistUser(user.id);
                    filteredExercises = filteredExercises.filter(
                        (item) => item.establishmentCode === therapist?.establishmentCode
                    );
                }
                if (user.role === Roles.FAMILY && user.id) {
                    const familyMember = await getFamilyUser(user.id);
                    filteredExercises = filteredExercises.filter(
                        (item) => item.familyCode === familyMember?.familyCode
                    );
                }
                if (filteredExercises) {
                    setExercises(filteredExercises);
                    getExerciseItemData(filteredExercises);
                }
            }
        };
        getExerciseList();
    }, []);

    const NewPhotoCard = (): JSX.Element => {
        return (
            <Grid
                item
                xs
                sx={{
                    flexGrow: '0 !important',
                    display: 'block'
                }}>
                <CreateNewPhotoCard bodyText={t('customizeAnExercise')} isStimultheque />
            </Grid>
        );
    };

    const addExerciseToList = (exercise: Exercise): void => {
        setExercisesList([...exercisesList, exercise]);
    };

    const delExerciseToList = (exercise: Exercise): void => {
        exercisesList.splice(exercisesList.indexOf(exercise), 1);
        setExercisesList([...exercisesList]);
    };

    const getExercise = (): JSX.Element[] | null => {
        if (exerciseList) {
            return exerciseList.slice(0, nbItemToDisplay).map((item, index) => {
                let bgColor;
                const number = index % 4;
                switch (number) {
                    case 0:
                        bgColor = '#FF008980';
                        break;
                    case 1:
                        bgColor = '#3DECCF80';
                        break;
                    case 2:
                        bgColor = '#458ff680';
                        break;
                    case 3:
                        bgColor = '#6B00AD80';
                        break;
                    default:
                        break;
                }
                return (
                    <Grid
                        item
                        xs
                        sx={{
                            flexGrow: '0 !important',
                            display: 'block'
                        }}
                        key={item.exercise.id}>
                        <ExerciseCards
                            onCardClicked={(value: string): void => setClickedCard(value)}
                            onLeaveCard={(): void => setClickedCard('')}
                            item={item}
                            bgColor={bgColor}
                            isSelected={clickedCard === item.exercise.id}
                            addExerciseToList={addExerciseToList}
                            delExerciseToList={delExerciseToList}
                        />
                    </Grid>
                );
            });
        }
        return null;
    };

    const getHeader = (): JSX.Element => {
        return (
            <Navbar
                leftIcon={backButton}
                centerIcon={stimulthequeLogo}
                leftIconLink={(): void => {
                    history.push('/home');
                }}
            />
        );
    };

    const titleAndIntroduction = (): JSX.Element | null => {
        if (user?.role === Roles.FAMILY) {
            return (
                <div style={{ width: '100%', margin: 'auto', paddingBottom: '5%' }}>
                    <div
                        className={classes.headerLayoutMobile}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                            margin: '0% 2%'
                        }}>
                        <div style={{ marginTop: '5%' }}>
                            <Typography variant="h4" color="#374653" sx={{ fontWeight: 'bold' }}>
                                {t('tryToEditPersonalizedExerciseFami')}
                            </Typography>
                        </div>
                    </div>
                    <SearchBox
                        placeholder={t('toSearch')}
                        onSearchChange={(value: string): void => setSearchText(value)}
                        hasSearchIcon
                    />
                </div>
            );
        }
        if (user?.role === Roles.ADMIN || user?.role === Roles.THERAPIST) {
            return (
                <div style={{ width: '100%', margin: 'auto', paddingBottom: '2%' }}>
                    <div
                        className={classes.headerLayoutMobile}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            justifyContent: 'space-between',
                            margin: '0% 2%'
                        }}>
                        <div style={{ marginTop: user?.role === Roles.ADMIN ? '10px' : '5%' }}>
                            <Typography
                                variant="h4"
                                color="#374653"
                                sx={{
                                    fontWeight: 'bold'
                                }}>
                                {user?.role === Roles.ADMIN || Roles.FAMILY
                                    ? t('modifyDeleteExercise')
                                    : t('tryToEditPersonalizedExerciseThera')}
                            </Typography>
                        </div>
                    </div>
                    <SearchBox
                        placeholder={t('toSearch')}
                        onSearchChange={(value: string): void => setSearchText(value)}
                        hasSearchIcon
                    />
                </div>
            );
        }
        return null;
    };

    const loadFuncItem = (): void => {
        if (exerciseList.length && nbItemToDisplay + 4 <= exerciseList.length) {
            setNbItemToDisplay(nbItemToDisplay + 4);
        } else {
            setNbItemToDisplay(exerciseList.length);
        }
    };

    const FamilyScreen = (
        <div>
            <Box sx={{ boxShadow: 'inset 0px -1px 0px #E3E6E8' }}>{getHeader()}</Box>
            <Box
                sx={{
                    backgroundColor: '#F8F9F9',
                    boxShadow: 'inset 0px -1px 0px rgba(172, 188, 199, 0.4)'
                }}>
                {titleAndIntroduction()}
            </Box>
            <InfiniteScroll
                pageStart={1}
                loadMore={(): void => {
                    if (exerciseList.length !== 0) loadFuncItem();
                }}
                hasMore={true || false}
                loader={
                    <div className="loader" key={0}>
                        {nbItemToDisplay !== exerciseList.length ? t('loading') : ''}
                    </div>
                }>
                <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={window.innerWidth < 520 ? 2 : 5}
                    sx={{ justifyContent: 'center' }}>
                    {NewPhotoCard()}
                    {getExercise()}
                    {exerciseList.length === 0 &&
                        Array.from(Array(nbItemToDisplay).keys()).map((item) => {
                            let bgColor;
                            const number = item % 4;
                            switch (number) {
                                case 0:
                                    bgColor = '#FF008980';
                                    break;
                                case 1:
                                    bgColor = '#3DECCF80';
                                    break;
                                case 2:
                                    bgColor = '#458ff680';
                                    break;
                                case 3:
                                    bgColor = '#6B00AD80';
                                    break;
                                default:
                                    break;
                            }

                            return (
                                <Grid
                                    item
                                    xs
                                    sx={{
                                        flexGrow: '0 !important',
                                        display: 'block'
                                    }}>
                                    <Box
                                        sx={{
                                            height:
                                                window.innerWidth < 520
                                                    ? 'calc(100vw / 2 + 20px)'
                                                    : '290px',
                                            width:
                                                window.innerWidth < 520
                                                    ? 'calc(100vw / 2 - 20px)'
                                                    : '220px',
                                            borderRadius: '9px',
                                            padding: '10px',
                                            boxShadow:
                                                '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
                                        }}>
                                        <Skeleton
                                            variant="rectangular"
                                            style={{
                                                backgroundColor: bgColor,
                                                borderRadius: '9px',
                                                width: '100%',
                                                height: '100%'
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            );
                        })}
                </Grid>
            </InfiniteScroll>
        </div>
    );

    const TherapistScreen = (
        <div>
            {user?.role === Roles.THERAPIST && (
                <Box sx={{ boxShadow: 'inset 0px -1px 0px #E3E6E8' }}>{getHeader()}</Box>
            )}
            {user?.role === Roles.ADMIN && (
                <Typography variant="h2" color="#1E2933" sx={{ textAlign: 'left', paddingLeft: 2 }}>
                    {t('edit')}
                </Typography>
            )}
            <Box
                sx={{
                    backgroundColor: '#F8F9F9',
                    width: '90%',
                    margin: 'auto',
                    marginTop: '2%'
                }}>
                {titleAndIntroduction()}
            </Box>
            {user?.role === Roles.ADMIN && (
                <Button
                    style={{
                        backgroundColor: '#FF0089',
                        marginBottom: '30px',
                        marginTop: '20px'
                    }}
                    onClick={(): void => {
                        const temp = exerciseList.map((item) => {
                            // setExercisesList([...exercisesList, item.exercise]);
                            return item.exercise;
                        });
                        // console.log('temp', temp);
                        setExercisesList(temp);
                        setSuccessModal(!successModal);
                    }}>
                    <Typography
                        style={{
                            color: '#FFF',
                            fontWeight: 'bold'
                        }}>
                        Envoyer tout les exercices à tout les établissement
                    </Typography>
                </Button>
            )}
            <InfiniteScroll
                pageStart={1}
                loadMore={(): void => {
                    if (exerciseList.length !== 0) loadFuncItem();
                }}
                hasMore={true || false}
                loader={
                    <div className="loader" key={0}>
                        {nbItemToDisplay !== exerciseList.length ? t('loading') : ''}
                    </div>
                }>
                <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={window.innerWidth < 520 ? 2 : 5}
                    sx={{ justifyContent: 'center' }}>
                    {NewPhotoCard()}
                    {getExercise()}
                    {/* HERE */}
                    {exerciseList.length === 0 &&
                        Array.from(Array(nbItemToDisplay).keys()).map((item) => {
                            let bgColor;
                            const number = item % 4;
                            switch (number) {
                                case 0:
                                    bgColor = '#FF008980';
                                    break;
                                case 1:
                                    bgColor = '#3DECCF80';
                                    break;
                                case 2:
                                    bgColor = '#458ff680';
                                    break;
                                case 3:
                                    bgColor = '#6B00AD80';
                                    break;
                                default:
                                    break;
                            }

                            return (
                                <Grid
                                    item
                                    xs
                                    sx={{
                                        flexGrow: '0 !important',
                                        display: 'block'
                                    }}>
                                    <Box
                                        sx={{
                                            height:
                                                window.innerWidth < 520
                                                    ? 'calc(100vw / 2 + 20px)'
                                                    : '290px',
                                            width:
                                                window.innerWidth < 520
                                                    ? 'calc(100vw / 2 - 20px)'
                                                    : '220px',
                                            borderRadius: '9px',
                                            padding: '10px',
                                            boxShadow:
                                                '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
                                        }}>
                                        <Skeleton
                                            variant="rectangular"
                                            style={{
                                                backgroundColor: bgColor,
                                                borderRadius: '9px',
                                                width: '100%',
                                                height: '100%'
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            );
                        })}
                </Grid>
            </InfiniteScroll>
            {user?.role === Roles.ADMIN && (
                <div
                    style={{
                        width: '100%',
                        textAlign: 'right'
                    }}>
                    <Button
                        disabled={exercisesList.length <= 0}
                        style={{
                            backgroundColor: '#E3E6E8',
                            marginRight: '30px',
                            marginTop: '20px'
                        }}
                        onClick={(): void => {
                            setSuccessModal(!successModal);
                        }}>
                        <Typography
                            style={{
                                color: exercisesList.length ? '#FF0089' : '#84919E',
                                fontWeight: 'bold'
                            }}>
                            {t('addExercisesToEstablishment')}
                        </Typography>
                    </Button>
                </div>
            )}
        </div>
    );

    return (
        <div>
            {user?.role === Roles.FAMILY && FamilyScreen}
            {user?.role === Roles.THERAPIST && TherapistScreen}
            {user?.role === Roles.ADMIN && <DashboardLayout>{TherapistScreen}</DashboardLayout>}
            {user?.role === Roles.ADMIN && (
                <AddExoToEstablishmentModal
                    isModelOpen={successModal}
                    setModelOpen={setSuccessModal}
                    onButtonClick={(): void => setSuccessModal(!successModal)}
                    exercisesList={exercisesList}
                />
            )}
        </div>
    );
}
