import React from 'react';
import { styled } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Header } from '../components/Header';

const RootStyle = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingBottom: theme.spacing(10)
}));

interface PatientLayoutProps {
    children: React.ReactElement[] | React.ReactElement;
    setActive: Function;
    active: boolean;
}

export function PatientLayout({
    children,
    setActive,
    active
}: PatientLayoutProps): React.ReactElement {
    const { pathname } = useLocation();
    const canGoback = pathname !== '/home';
    return (
        <RootStyle>
            <Header canGoBack={canGoback} hasIconImage setActive={setActive} active={active} />
            <MainStyle>{children}</MainStyle>
        </RootStyle>
    );
}
