import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminFormLayout } from '../../layouts/AdminFormLayout';
import addTherapist from '../../assets/addTherapist.png';
import { Therapist } from '../../models/Therapist';

export function AddTherapist(): React.ReactElement {
    const { t } = useTranslation();
    return (
        <AdminFormLayout
            subtitleDescription={t('addFormTherapistDescription')}
            data={new Therapist()}
            backgroundImage={addTherapist}
            columnSize="340px"
            formFor="therapist"
        />
    );
}
