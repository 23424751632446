import React, { useContext, useState } from 'react';
import { Box, CircularProgress, MobileStepper, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { ImageHeader } from '../../components/ImageHeader';
import { Roles } from '../../models/Roles';
import greyBackIcon from '../../assets/greyBack.png';
import greyNextIcon from '../../assets/greyNext.png';
import redNextIcon from '../../assets/redNextIcon.png';
// eslint-disable-next-line import/no-cycle
import { UserContext } from '../../providers/UserProvider';
import { UserDataProp } from '../../models/userDataProp';

const useStyles = makeStyles(() => ({
    root: {
        boxShadow: '0px -2px 20px 0px #76767633',
        borderRadius: '24px 24px 0px 0px',
        backgroundColor: '#ffff',
        padding: '25px 10px'
    },
    submitButton: {
        height: 'auto',
        minHeight: '48px',
        color: '#3387CC',
        border: '2px solid #3387CC',
        '&:hover': {
            backgroundColor: '#ffff',
            border: '2px solid #3387CC'
        }
    },
    validButton: {
        height: 'auto',
        minWidth: '20%',
        '@media (min-width:500px)': {
            minWidth: '50%'
        },
        minHeight: '48px',
        color: '#D00070',
        border: '2px solid #D00070',
        '&:hover': {
            backgroundColor: '#ffff',
            border: '2px solid #D00070'
        }
    },
    buttonBackNext: {
        '@media (min-width:500px)': {
            width: ''
        },
        width: '203px',
        height: 'auto',
        minHeight: '48px'
    }
}));

const Logo = styled.img`
    height: 18px;
    width: 18px;
    margin-right: 10px;
    object-fit: contain;
`;

const RightLogo = styled.img`
    height: 18px;
    width: 18px;
    margin-left: 10px;
    object-fit: contain;
`;

interface ImageFrameProps {
    activeStep: number;
    maxStep?: number;
    title: string;
    children: JSX.Element;
    onBackClick: Function;
    onNextClick: Function;
    isDisabled: boolean;
    isSubmitButton?: boolean;
    isloadButton?: boolean;
    isValidateButton?: boolean;
}

export function ImageFrame({
    activeStep,
    maxStep,
    title,
    children,
    onBackClick,
    onNextClick,
    isDisabled,
    isSubmitButton,
    isloadButton,
    isValidateButton
}: ImageFrameProps): JSX.Element {
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const isSmallWidth = !useMediaQuery('(min-width:500px)');
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const classes = useStyles();

    const click = (): void => {
        if (isloadButton) {
            setLoading(true);
        }
        onNextClick();
    };

    const getSubmitButton = (): JSX.Element => {
        return (
            <Button
                disabled={!isDisabled}
                sx={{ opacity: isDisabled ? '1' : '0.5' }}
                variant="outlined"
                className={classes.submitButton}
                onClick={(): void => click()}>
                <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                    {t('validateThumbnail')}
                </Typography>
            </Button>
        );
    };

    const getValidateButton = (): JSX.Element => {
        return (
            <Button
                disabled={!isDisabled}
                sx={{ opacity: isDisabled ? '1' : '0.5' }}
                variant="outlined"
                className={classes.validButton}
                onClick={(): void => click()}>
                <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                    {t('validate')}
                </Typography>
            </Button>
        );
    };

    const getBackButton = (text: string): JSX.Element => {
        return (
            <Button
                disabled={loading}
                variant="greyBorderButton"
                className={classes.buttonBackNext}
                sx={{
                    mr: '10px'
                }}
                onClick={(): void => onBackClick()}>
                <Logo src={greyBackIcon} alt="CloseButton" />
                <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                    {t(text)}
                </Typography>
            </Button>
        );
    };

    const getLoadButton = (): JSX.Element => {
        return <CircularProgress style={{ marginRight: '4%' }} />;
    };

    const getNextButton = (text: string): JSX.Element => {
        return (
            <Button
                disabled={!isDisabled}
                sx={{
                    opacity: isDisabled ? '1' : '0.5',
                    marginLeft: '10px',
                    color: user?.role === Roles.THERAPIST ? '#D00070' : '#374653',
                    borderColor: user?.role === Roles.THERAPIST ? '#D00070' : '#374653',
                    boxShadow:
                        user?.role === Roles.THERAPIST
                            ? '0px 0px 0px 2px rgba(92, 102, 112, 0.6)'
                            : ''
                }}
                variant="greyBorderButton"
                className={classes.buttonBackNext}
                onClick={(): void => {
                    click();
                }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                    {t(text)}
                </Typography>
                <RightLogo
                    src={user?.role === Roles.THERAPIST ? redNextIcon : greyNextIcon}
                    alt="CloseButton"
                />
            </Button>
        );
    };

    const whichButton = (): JSX.Element => {
        if (loading === true) {
            return getLoadButton();
        }
        if (isSubmitButton) {
            return getSubmitButton();
        }
        if (isValidateButton) {
            return getValidateButton();
        }
        return getNextButton(user?.role === Roles.THERAPIST ? 'nextStep' : 'following');
    };

    const getTabFooter = (): JSX.Element => {
        return (
            <MobileStepper
                className={classes.root}
                variant="dots"
                steps={maxStep ?? 3}
                position="static"
                activeStep={activeStep}
                nextButton={whichButton()}
                backButton={getBackButton('previous')}
            />
        );
    };

    const getFooter = (): JSX.Element => {
        return (
            <Box
                sx={{
                    width: '100wh',
                    boxShadow: '0px -4px 10px 0px #52525214',
                    borderRadius: '24px 24px 0px 0px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    py: '25px'
                }}>
                {getBackButton('previous')}
                {whichButton()}
            </Box>
        );
    };

    return (
        <Box display="flex" flexDirection="column" minHeight="100%">
            <ImageHeader text={title} />
            <Box display="flex" flexDirection="column" flexGrow={1} justifyContent="space-between">
                {children}
                {isSmallWidth ? getFooter() : getTabFooter()}
            </Box>
        </Box>
    );
}
