import React, { useContext, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { Box, Button, Typography } from '@mui/material';
import Slider from '@mui/material/Slider';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import screenfull from 'screenfull';
import BigPlayButton from '../assets/bigPlayButton.svg';
// import PlayButton from '../assets/PlayButton.svg';
import BigPauseButton from '../assets/bigPauseButton.svg';
import Close from '../assets/close.svg';

interface PlayerVideo {
    videoSrc: string;
    timeAutoPlay?: number;
    btnClose?: boolean;
    timebar?: boolean;
    closeFun?: () => void;
    setVideoFinished?: () => void;
    setHiddenTitle?: (arg0: boolean) => void;
}

export function VideoPlayer({
    videoSrc,
    timeAutoPlay,
    btnClose,
    timebar,
    closeFun,
    setVideoFinished,
    setHiddenTitle
}: PlayerVideo): JSX.Element {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const videoRef = React.useRef<any>();
    const [inPlay, setInPlay] = useState<boolean>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const refContainer = React.useRef<any>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const btnPlay = React.useRef<any>();
    const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
    const [displayPauseButton, setDisplayPauseButton] = useState<boolean>(false);
    const [videoTime, setVideoTime] = useState<number>(0);
    const [displayTimeBar, setDisplayTimeBar] = useState<boolean>(false);

    if (screenfull.isEnabled) {
        screenfull.on('change', () => {
            setIsFullScreen(screenfull.isFullscreen);
        });
    }

    if (timebar === undefined && (videoSrc.includes('youtu.be') || videoSrc.includes('youtube'))) {
        // eslint-disable-next-line no-param-reassign
        timebar = true;
    }

    const isApple = /iPhone|iPad|iPod|Macintosh/i.test(navigator.userAgent);

    const displayPauseBtn = (): void => {
        setTimeout(() => {
            setDisplayPauseButton(false);
        }, 2000);
        setDisplayPauseButton(true);
    };

    useEffect(() => {
        if (setHiddenTitle && (inPlay === false || inPlay === true)) {
            setHiddenTitle(inPlay);
        }
    }, [inPlay]);

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/mouse-events-have-key-events
        <div
            style={{
                alignItems: 'center',
                overflow: 'hidden',
                width: '100%',
                height: '100%',
                position: 'relative'
            }}
            ref={refContainer}
            onClick={(): void => displayPauseBtn()}>
            {/* onMouseMove={(): void => setDisplayPauseButton(true)}
            // onMouseEnter={(): void => setDisplayPauseButton(true)}
            onMouseLeave={(): void => setDisplayPauseButton(false)}> */}
            <ReactPlayer
                playsInline
                url={`${videoSrc}#t=0.01`}
                onProgress={(progress): void => {
                    if (progress.playedSeconds) {
                        setVideoTime(progress.playedSeconds);
                    }
                }}
                playing={inPlay}
                ref={videoRef}
                onEnded={(): void => {
                    setInPlay(false);
                    if (screenfull.isEnabled && isFullScreen) {
                        screenfull.exit();
                    }
                    if (setVideoFinished) {
                        setVideoFinished();
                    }
                }}
                onReady={(): void => {
                    if (timeAutoPlay && !isApple) {
                        setTimeout(() => {
                            setInPlay(true);
                        }, 2000);
                    } else {
                        setInPlay(false);
                    }
                }}
                config={{
                    youtube: {
                        playerVars: { controls: 0, disablekb: 1, rel: 0 }
                    }
                }}
                width="inherit"
                height="inherit"
                style={{ backgroundColor: '#000000', pointerEvents: 'none' }}
            />
            {!inPlay && (
                <Button
                    ref={btnPlay}
                    onClick={(): void => setInPlay(true)}
                    style={{
                        position: 'absolute',
                        height: '100px',
                        width: '100px',
                        top: 'calc(50% - 50px)',
                        left: 'calc(100% / 2 - 50px)'
                    }}>
                    <img
                        src={BigPlayButton}
                        alt="PlayButton"
                        style={{
                            height: '100px',
                            width: '100px'
                        }}
                    />
                </Button>
            )}
            {timebar && videoRef && videoRef.current && (
                <Box
                    style={{
                        display: displayPauseButton || !inPlay || displayTimeBar ? 'flex' : 'none',
                        alignItems: 'center',
                        width: '90%',
                        paddingLeft: '10px',
                        position: 'absolute',
                        zIndex: '3600',
                        bottom: '20px'
                    }}
                    onMouseOver={(): void => setDisplayTimeBar(true)}
                    onMouseLeave={(): void => setDisplayTimeBar(false)}>
                    <span
                        style={{
                            color: '#ffffff',
                            fontSize: '1.5rem',
                            fontWeight: 'bold',
                            marginRight: '10px',
                            marginLeft: '10px',
                            backgroundColor: 'black',
                            padding: '0 4px',
                            fontFamily: 'Luciole'
                        }}>
                        {Math.trunc(videoTime) < 60
                            ? `0:${
                                  Math.trunc(videoTime) < 10
                                      ? `0${Math.trunc(videoTime)}`
                                      : Math.trunc(videoTime)
                              }`
                            : `${Math.floor(Math.trunc(videoTime) / 60)}:${
                                  Math.floor(Math.trunc(videoTime) % 60) < 10
                                      ? `0${Math.floor(Math.trunc(videoTime) % 60)}`
                                      : Math.floor(Math.trunc(videoTime) % 60)
                              }`}
                    </span>
                    <Slider
                        // size="small"
                        min={0}
                        defaultValue={videoRef.current.getCurrentTime() * 1000}
                        onChange={(_, value): void => {
                            if (typeof value === 'number') {
                                const newValue = value / 1000;
                                videoRef.current.seekTo(newValue);
                                setVideoTime(newValue);
                            }
                        }}
                        value={videoTime * 1000}
                        max={videoRef.current.getDuration() * 1000}
                        aria-label="time bar"
                        valueLabelDisplay="auto"
                        valueLabelFormat={(value): string => {
                            const minutes = Math.floor(value / 1000 / 60);
                            const seconds = Math.floor((value / 1000) % 60);
                            return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
                        }}
                    />
                    {videoRef.current && videoRef.current.getDuration && (
                        <span
                            style={{
                                color: '#ffffff',
                                fontSize: '1.5rem',
                                fontWeight: 'bold',
                                marginRight: '10px',
                                marginLeft: '10px',
                                backgroundColor: 'black',
                                padding: '0 4px',
                                fontFamily: 'Luciole'
                            }}>
                            {Math.trunc(videoRef.current.getDuration()) < 60
                                ? `0:${
                                      Math.trunc(videoRef.current.getDuration()) < 10
                                          ? `0${Math.trunc(videoRef.current.getDuration())}`
                                          : Math.trunc(videoRef.current.getDuration())
                                  }`
                                : `${Math.floor(Math.trunc(videoRef.current.getDuration()) / 60)}:${
                                      Math.floor(Math.trunc(videoRef.current.getDuration()) % 60) <
                                      10
                                          ? `0${Math.floor(
                                                Math.trunc(videoRef.current.getDuration()) % 60
                                            )}`
                                          : Math.floor(
                                                Math.trunc(videoRef.current.getDuration()) % 60
                                            )
                                  }`}
                        </span>
                    )}
                </Box>
            )}
            {inPlay && (
                <Button
                    onClick={(): void => setInPlay(false)}
                    onMouseOver={(): void => setDisplayPauseButton(true)}
                    style={{
                        position: 'absolute',
                        height: '100px',
                        width: '100px',
                        top: 'calc(50% - 50px)',
                        left: 'calc(100% / 2 - 50px)',
                        // bottom: '10px',
                        display: displayPauseButton ? 'flex' : 'none'
                    }}>
                    <img
                        src={BigPauseButton}
                        alt="PauseButton"
                        style={{
                            height: '100px',
                            width: '100px'
                        }}
                    />
                </Button>
            )}
            {btnClose && closeFun && (
                <Button
                    onClick={(): void => closeFun()}
                    style={{
                        position: 'absolute',
                        height: '50px',
                        width: '50px',
                        zIndex: '3600',
                        left: '10px',
                        top: '10px',
                        background: '#FF0089',
                        borderRadius: '10px'
                    }}>
                    <img
                        src={Close}
                        alt="colseIcon"
                        style={{
                            height: '30px',
                            width: '30px'
                        }}
                    />
                </Button>
            )}
            {screenfull.isEnabled && (
                <Button
                    onClick={(): void => {
                        if (screenfull.isEnabled) {
                            if (!isFullScreen) {
                                screenfull.request(refContainer.current);
                                // setIsFullScreen(true);
                            } else {
                                screenfull.exit();
                                // setIsFullScreen(false);
                            }
                        }
                    }}
                    style={{
                        height: '50px',
                        width: '50px',
                        position: 'absolute',
                        zIndex: '3600',
                        right: '20px',
                        // left: 'calc(50% - 60px)',
                        bottom: '20px',
                        // right: '10px',
                        background: '#FF0089',
                        borderRadius: '10px'
                    }}>
                    {!isFullScreen && (
                        <FullscreenIcon sx={{ height: '50px', width: '50px', color: '#FFFFFF' }} />
                    )}
                    {isFullScreen && (
                        <FullscreenExitIcon
                            sx={{ height: '50px', width: '50px', color: '#FFFFFF' }}
                        />
                    )}
                </Button>
            )}
        </div>
    );
}
