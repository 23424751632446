import { Box, Card, Typography } from '@mui/material';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { UserContext } from '../providers/UserProvider';
import { UserDataProp } from '../models/userDataProp';
import { ThemeExamplesViewData } from '../models/ThemeExamplesViewData';
import { getThemeIcon } from '../utilities/utils';

const ImageInfoContainer = styled.div`
    @media (min-width: 1024px) {
        width: 95%;
    }
    position: absolute;
    z-index: 2;
    height: 25px;
    margin-top: 170px;
    background-color: rgba(55, 70, 83, 1);
    border-radius: 0px 5px 5px 0px;
    color: rgba(251, 251, 251, 1);
    display: flex;
    align-items: center;
    margin-left: 5px;
    width: 93%;
`;

interface ThemeCardProps {
    onCardClicked?: Function;
    item: ThemeExamplesViewData;
    classReceived?: string;
}

export function ThemeCard({ onCardClicked, item, classReceived }: ThemeCardProps): JSX.Element {
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;

    const ExerciseDetailCard = (
        <Card
            onClick={(): void => (onCardClicked ? onCardClicked(item) : '')}
            className={classReceived ?? ''}
            sx={{
                position: 'relative',
                width: '100%',
                boxShadow: 'none',
                cursor: 'pointer',
                height: 'auto !important',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
            }}>
            <ImageInfoContainer>
                <img
                    src={getThemeIcon(item.theme)}
                    alt="Exercise"
                    style={{
                        width: '15px',
                        height: '15px',
                        marginLeft: '5px'
                    }}
                />
                <Typography
                    variant="caption"
                    sx={{
                        margin: '0px 4px',
                        fontSize: { xs: '11px' },
                        fontWeight: 500,
                        justifyContent: 'space-between'
                    }}>
                    {item.theme}
                </Typography>
            </ImageInfoContainer>
            {typeof item.exerciseImage === 'string' && (
                <img
                    src={item.exerciseImage}
                    alt="SampleImg"
                    style={{
                        zIndex: 1,
                        width: '100%',
                        height: '200px',
                        padding: '5px'
                    }}
                />
            )}
        </Card>
    );

    return <Box>{ExerciseDetailCard}</Box>;
}
