import {
    Box,
    Button,
    CircularProgress,
    Collapse,
    Grid,
    List,
    ListItemButton,
    ListItemText,
    Typography
} from '@mui/material';
import React, {
    DetailedHTMLProps,
    LegacyRef,
    useContext,
    useEffect,
    useState,
    VideoHTMLAttributes
} from 'react';
import ReactPlayer from 'react-player';
import { useLongPress } from 'use-long-press';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import styled from 'styled-components';
import CloseIconMui from '@mui/icons-material/Close';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ExpandLess from '@mui/icons-material/ExpandLess';
// import SkipNextIcon from '@mui/icons-material/SkipNext';
// import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import ExpandMore from '@mui/icons-material/ExpandMore';
import WbIncandescentIcon from '@mui/icons-material/WbIncandescent';
import { VideoPlayer } from '../../components/VideoPlayer';
import closeIcon from '../../assets/closeIcon.png';
import skipPrevious from '../../assets/skip_previous.svg';
import skipNext from '../../assets/skip_next.svg';
import { UserContext } from '../../providers/UserProvider';
import { UserDataProp } from '../../models/userDataProp';
import {
    getImageKitUrlFrom,
    getThemeIcon,
    capitalizeFirstLetter,
    translateDateToString
} from '../../utilities/utils';
import { formatVideoUrl } from '../../services/formatFunctions';

const useStyles = makeStyles(() => ({
    screen: {
        width: '100vw',
        height: '100vh',
        display: 'inline-flex'
    },
    fullWidth: {
        width: '100%',
        height: '100%'
    },
    rightBox: {
        width: '100%',
        height: '100%'
    },
    leftBox: {
        width: '100%',
        height: '100%',
        backgroundColor: '#0F0046'
    },
    pictureDiv: {
        display: 'flex',
        justifyContent: 'center',
        margin: 'auto',
        position: 'relative'
    },
    leftTypography: {
        color: '#374653',
        textAlign: 'center',
        fontFamily: 'Luciole-Regular',
        marginTop: '10px'
    },
    informationButton: {
        minWidth: '40px',
        height: '40px',
        borderRadius: '20px',
        backgroundColor: '#5C6670',
        position: 'absolute',
        right: '50px',
        top: '30px'
    },
    closeButton: {
        minWidth: '40px',
        height: '40px',
        position: 'absolute',
        left: '50px',
        top: '30px'
    },
    itemInformation: {
        display: 'flex',
        flexFlow: 'column',
        // marginTop: '45vh',
        transform: 'translateY(-50%)',
        gap: '80px'
    },
    itemInformationDiv: {
        display: 'inline-flex',
        width: '90%',
        margin: 'auto'
    },
    iconItemInformation: {
        fontSize: '40px',
        color: '#FFFFFF'
    },
    textItemInformation: {
        alignSelf: 'center',
        marginLeft: '10px',
        color: '#FFFFFF',
        fontWeight: '520',
        fontSize: '1.3rem',
        textAlign: 'left',
        fontFamily: 'Luciole-Regular',
        '& span': {
            fontSize: '1.3rem',
            fontFamily: 'Luciole-Regular'
        }
    },
    listIdentification: {
        padding: '0 !important',
        alignSelf: 'center'
    },
    expend: {
        color: 'white',
        fontSize: '30px'
    },
    infoAnimation: {
        animationName: `$infoAnimation2`,
        animationDuration: '9s',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'linear',
        animationDelay: '5s'
        // animation: `$infoAnimation 5s ease-in-out 2s 1.5 both`
    },
    '@keyframes infoAnimation2': {
        '0%, 20%, 40%, 60%, 80%, 100%': {
            transform: 'scale(1)'
        },
        '10%, 30%, 50%': {
            transform: 'scale(0.7)'
        }
    }
}));

const ImageInfoContainer = styled.div`
    position: absolute;
    z-index: 2;
    height: 30px;
    width: 30px;
    bottom: 0;
    left: 0;
    background-color: rgba(55, 70, 83, 1);
    color: rgba(251, 251, 251, 1);
`;

const LogoClose = styled.img`
    position: absolute;
    height: 40px;
    background-color: #fbfbfb;
    border: 1px solid #e3e6e8;
    padding: 6px;
    border-radius: 5px;
    cursor: pointer;
    top: 30px;
    left: 50px;
`;

const ItemDiv = styled.img`
    min-width: 100%;
    min-height: 100%;
    object-fit: contain;
`;

interface ConsultationItemInterface {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    item?: any;
    setConsultationItem: Function;
    consultItem?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    itemList?: any[];
    stopTimer?: () => void;
}

export function ConsultationItem({
    item,
    setConsultationItem,
    consultItem = false,
    itemList,
    stopTimer
}: ConsultationItemInterface): JSX.Element {
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const { t } = useTranslation();
    const classes = useStyles();
    const [isExerciseItem, setIsExerciseItem] = useState<boolean>(false);
    const [ExerciseItemCheck, setExerciseItemCheck] = useState<boolean>(false);
    const [video, setVideo] = useState<string>('');
    const [picture, setPicture] = useState<string>('');
    const [iconTheme, setIconTheme] = useState<string>('');
    const [showGridTwo, setShowGridTwo] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const videoRef = React.useRef<any>();
    const [playing, setPlaying] = useState<boolean>(false);

    useEffect(() => {
        if (Object.prototype.hasOwnProperty.call(item, 'videoUrl')) {
            setTimeout(() => {
                if (stopTimer) {
                    stopTimer();
                }
            }, 1000);
            setIsExerciseItem(false);
            if (item.videoUrl.includes('firebase')) {
                setVideo(getImageKitUrlFrom(item.videoUrl));
            } else {
                setVideo(formatVideoUrl(item.videoUrl));
            }
        } else {
            setPicture(getImageKitUrlFrom(item.exerciseImage));
            setIconTheme(getThemeIcon(item.theme));
            setIsExerciseItem(true);
        }
        setExerciseItemCheck(true);
        // setTimeout(() => {
        //     videoRef.current?.play();
        // }, 2000);

        if ((item.imageDetails && item.imageDetails.length) || item.anecdote) {
            setShowGridTwo(true);
        } else {
            setShowGridTwo(false);
        }
    }, [item]);

    const pictureItem = (): JSX.Element | null => {
        if (picture !== '' && iconTheme !== '') {
            return (
                <div
                    className={classes.pictureDiv}
                    style={{
                        // width: showGridTwo ? '80%' : '53%',
                        height: 'calc(100vh - 150px)',
                        width: 'calc(100vh - 150px)',
                        top: '0px',
                        // width: 'fit-content'
                        display: 'flex',
                        margin: 'auto'
                    }}>
                    <ImageInfoContainer>
                        <div style={{ marginTop: '5px' }}>
                            <img
                                src={iconTheme}
                                alt="Exercise"
                                style={{
                                    width: '20px',
                                    height: '20px'
                                }}
                            />
                        </div>
                    </ImageInfoContainer>
                    <ItemDiv src={picture} alt="ItemChoose" />
                </div>
            );
        }
        return null;
    };

    const videoItem = (): JSX.Element | null => {
        if (item && item.videoUrl) {
            return (
                <div
                    className={classes.pictureDiv}
                    style={{
                        width: '80%',
                        height: 'calc(100vh - 120px)',
                        flexDirection: 'column'
                    }}>
                    {item.videoUrl.includes('firebase') ? (
                        <VideoPlayer
                            videoSrc={video}
                            timeAutoPlay={2000}
                            setVideoFinished={(): void => {
                                setTimeout(() => {
                                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                                    nextItem();
                                }, 1500);
                            }}
                        />
                    ) : (
                        <VideoPlayer videoSrc={video} timeAutoPlay={2000} />
                    )}
                </div>
            );
        }
        return null;
    };

    const bindLongPressShowGrid = useLongPress(
        (event, action) => {
            setShowGridTwo(action.context === 'true');
        },
        { threshold: 1000 }
    );

    const informationItem = (): JSX.Element => {
        return (
            <button
                className={classes.infoAnimation}
                type="button"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...bindLongPressShowGrid('true')}
                onClick={(): void => setShowGridTwo(true)}
                style={{
                    border: 'solid 5px white',
                    // display: showGridTwo ? 'none' : 'block',
                    minWidth: '70px',
                    height: '70px',
                    borderRadius: '35px',
                    backgroundColor: '#FF0089',
                    position: 'absolute',
                    right: '30px',
                    top: '15px'
                }}>
                <Typography
                    sx={{
                        color: 'white',
                        fontSize: '2rem !important',
                        fontWeight: '700'
                    }}
                    variant="body1">
                    {t('i')}
                </Typography>
            </button>
        );
    };

    const identificationDetails = (): JSX.Element => {
        return item.imageDetails.map(
            (value: { firstName: string; lastName: string; relation: string }, index: number) => {
                if (index !== 0) {
                    return (
                        <ListItemText
                            key={`${value.firstName} ${value.lastName} - ${value.relation}`}
                            className={classes.textItemInformation}
                            primary={`${value.firstName} ${value.lastName} - ${value.relation}`}
                        />
                    );
                }
                return null;
            }
        );
    };

    const previousItem = (): void => {
        setShowGridTwo(false);
        if (itemList) {
            const futureItem = itemList[itemList.indexOf(item) - 1];
            Object.prototype.hasOwnProperty.call(futureItem, 'videoUrl')
                ? setIsExerciseItem(false)
                : setIsExerciseItem(true);
            setConsultationItem(futureItem);
        }
    };
    const nextItem = (): void => {
        setShowGridTwo(false);
        if (itemList) {
            const futureItem = itemList[itemList.indexOf(item) + 1];
            Object.prototype.hasOwnProperty.call(futureItem, 'videoUrl')
                ? setIsExerciseItem(false)
                : setIsExerciseItem(true);
            setConsultationItem(futureItem);
        }
    };

    const bindLongPressClose = useLongPress(
        (event) => {
            setConsultationItem(null);
        },
        { threshold: 1000 }
    );

    const bindLongPressNav = useLongPress(
        (event, action) => {
            if (action.context === 'next') {
                nextItem();
            } else {
                previousItem();
            }
        },
        { threshold: 1000 }
    );

    const bindLongPressOpen = useLongPress(
        (event, value) => {
            setOpen(value.context === 'true');
        },
        { threshold: 1000 }
    );

    const screen = (): JSX.Element => {
        if (ExerciseItemCheck) {
            return (
                <div className={classes.screen}>
                    <Grid container className={classes.fullWidth}>
                        {isExerciseItem &&
                            !showGridTwo &&
                            ((item.imageDetails && item.imageDetails.length) || item.anecdote) &&
                            informationItem()}
                        {/* <LogoClose
                            onClick={(): void => setConsultationItem(null)}
                            src={closeIcon}
                            alt="CloseButton"
                        /> */}
                        <Button
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...bindLongPressClose()}
                            onClick={(): void => {
                                setConsultationItem(null);
                            }}
                            style={{
                                position: 'absolute',
                                height: '60px',
                                width: '60px',
                                backgroundColor: '#fbfbfb',
                                border: '1px solid #CDCDCD',
                                padding: '6px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                top: '15px',
                                left: '30px'
                            }}
                            fullWidth
                            variant="outlined"
                            size="large"
                            sx={{ fontFamily: 'Luciole-Regular' }}>
                            <CloseIconMui sx={{ color: '#FF0089', fontSize: '36px' }} />
                        </Button>
                        <Grid xs={showGridTwo ? 8 : 12} item className={classes.rightBox}>
                            <div
                                // className={classes.fullWidth}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}>
                                <div style={{ marginBottom: '40px' }}>
                                    <Typography
                                        variant="h2"
                                        className={classes.leftTypography}
                                        // here
                                        sx={{
                                            fontWeight: '600 !important',
                                            color: '#3387CC !important',
                                            width: '90%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto'
                                        }}>
                                        {isExerciseItem && item.answer && item.title
                                            ? capitalizeFirstLetter(item.answer)
                                            : capitalizeFirstLetter(item.title)}
                                    </Typography>
                                    {isExerciseItem &&
                                        item.photoCapturedOn &&
                                        item.photoCapturedPlace && (
                                            <Typography
                                                variant="h6"
                                                className={classes.leftTypography}
                                                sx={{
                                                    fontSize: '1.3rem',
                                                    color: '#0F0046 !important'
                                                }}>
                                                {/* {translateDateToString(item.photoCapturedOn)}{' '}
                                                {t('-')} {item.photoCapturedPlace} */}

                                                {/* {item.photoCapturedOn && item.photoCapturedPlace
                                                    ? `${translateDateToString(
                                                          item.photoCapturedOn
                                                      )} ${t('-')} ${item.photoCapturedPlace}`
                                                    : `g`} */}
                                                {`${
                                                    item.photoCapturedOn &&
                                                    item.photoCapturedPlace &&
                                                    item.photoCapturedPlace
                                                        .replaceAll(' ', '')
                                                        .replaceAll('-', '') !== ''
                                                        ? `${translateDateToString(
                                                              item.photoCapturedOn
                                                          )} ${t('-')} ${item.photoCapturedPlace}`
                                                        : `${
                                                              item.photoCapturedOn
                                                                  ? translateDateToString(
                                                                        item.photoCapturedOn
                                                                    )
                                                                  : `${
                                                                        item.photoCapturedPlace
                                                                            ? item.photoCapturedPlace
                                                                            : ''
                                                                    }`
                                                          }`
                                                }`}
                                            </Typography>
                                        )}
                                </div>
                                {isExerciseItem ? pictureItem() : videoItem()}
                                {consultItem && itemList && itemList.indexOf(item) > 0 && (
                                    <Button
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...bindLongPressNav('previous')}
                                        onClick={(): void => previousItem()}
                                        sx={{
                                            height: '125px',
                                            width: '125px',
                                            borderRadius: '50%',
                                            background:
                                                showGridTwo || item.videoUrl
                                                    ? 'rgba(255, 255, 255, 0.9)'
                                                    : 'transparent',
                                            border:
                                                showGridTwo || item.videoUrl
                                                    ? '1px solid #FF0089'
                                                    : 'none',
                                            position: 'absolute',
                                            left: '20px',
                                            top: 'calc(50% - (125px / 2))',
                                            '&:hover': {
                                                background:
                                                    showGridTwo || item.videoUrl
                                                        ? 'rgba(255, 255, 255, 0.9)'
                                                        : 'transparent'
                                            }
                                        }}>
                                        <img
                                            src={skipPrevious}
                                            alt="skipPrevious"
                                            style={{
                                                height: 'auto',
                                                width: 'auto',
                                                color: '#FF0089'
                                            }}
                                        />
                                    </Button>
                                )}
                                {consultItem &&
                                    itemList &&
                                    itemList.indexOf(item) < itemList.length - 1 && (
                                        <Button
                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                            {...bindLongPressNav('next')}
                                            onClick={(): void => nextItem()}
                                            sx={{
                                                height: '125px',
                                                width: '125px',
                                                borderRadius: '50%',
                                                background:
                                                    showGridTwo || item.videoUrl
                                                        ? 'rgba(255, 255, 255, 0.9)'
                                                        : 'transparent',
                                                border:
                                                    showGridTwo || item.videoUrl
                                                        ? '1px solid #FF0089'
                                                        : 'none',
                                                position: 'absolute',
                                                right: showGridTwo
                                                    ? 'calc(100vw / 12 * 4 + 20px)'
                                                    : '20px',
                                                top: 'calc(50% - (125px / 2))',
                                                '&:hover': {
                                                    background:
                                                        showGridTwo || item.videoUrl
                                                            ? 'rgba(255, 255, 255, 0.9)'
                                                            : 'transparent'
                                                }
                                            }}>
                                            <img
                                                src={skipNext}
                                                alt="skipPrevious"
                                                style={{
                                                    height: 'auto',
                                                    width: 'auto',
                                                    color: '#FF0089'
                                                }}
                                            />
                                        </Button>
                                    )}
                            </div>
                        </Grid>
                        {showGridTwo && (
                            <Grid xs={4} item className={classes.leftBox}>
                                <Box className={classes.itemInformation}>
                                    {/* style={{ width: '100%', display: 'flex' }}> */}
                                    <button
                                        type="button"
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...bindLongPressShowGrid('false')}
                                        onClick={(): void => setShowGridTwo(false)}
                                        style={{
                                            border: 'none',
                                            marginTop: '70px',
                                            marginLeft: '20px',
                                            backgroundColor: 'unset',
                                            textAlign: 'left'
                                        }}>
                                        {/* <Typography
                                            sx={{
                                                color: 'white',
                                                fontSize: '2rem !important',
                                                fontWeight: '700'
                                            }}
                                            variant="body1">
                                        </Typography> */}
                                        <KeyboardDoubleArrowRightIcon
                                            sx={{ color: '#FFF', fontSize: '3rem' }}
                                        />
                                    </button>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        height: 'calc(100% - 133px)'
                                    }}>
                                    <Box
                                        className={classes.itemInformation}
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            msTransform: 'translateY(-50%)',
                                            width: 'calc(100% / 12 * 4)',
                                            flexDirection: 'row'
                                        }}>
                                        {item.anecdote && (
                                            <div className={classes.itemInformationDiv}>
                                                {/* <WbIncandescentIcon
                                                className={classes.iconItemInformation}
                                                sx={{ transform: 'rotate(180deg)' }}
                                            /> */}
                                                <Typography
                                                    className={classes.textItemInformation}
                                                    variant="body1">
                                                    {capitalizeFirstLetter(item.anecdote)}
                                                </Typography>
                                            </div>
                                        )}
                                        {item.imageDetails &&
                                            // item.imageDetails.length > 0 &&
                                            item.imageDetails.length === 1 && (
                                                <div className={classes.itemInformationDiv}>
                                                    <AccountBoxIcon
                                                        className={classes.iconItemInformation}
                                                    />
                                                    <List
                                                        className={classes.listIdentification}
                                                        component="ul"
                                                        aria-labelledby="nested-list-subheader">
                                                        <ListItemText
                                                            className={classes.textItemInformation}
                                                            primary={`${item.imageDetails[0].firstName} ${item.imageDetails[0].lastName} - ${item.imageDetails[0].relation}`}
                                                        />
                                                    </List>
                                                </div>
                                            )}
                                        {item.imageDetails && item.imageDetails.length > 1 && (
                                            <div className={classes.itemInformationDiv}>
                                                <AccountBoxIcon
                                                    className={classes.iconItemInformation}
                                                />
                                                <List
                                                    className={classes.listIdentification}
                                                    component="ul"
                                                    sx={{ width: '100%' }}
                                                    aria-labelledby="nested-list-subheader">
                                                    <ListItemButton
                                                        className={classes.listIdentification}
                                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                                        {...bindLongPressOpen(
                                                            open ? 'false' : 'true'
                                                        )}
                                                        onClick={(): void => setOpen(!open)}>
                                                        <ListItemText
                                                            className={classes.textItemInformation}
                                                            primary={`${item.imageDetails[0].firstName} ${item.imageDetails[0].lastName} - ${item.imageDetails[0].relation}`}
                                                        />
                                                        {open ? (
                                                            <ExpandLess
                                                                className={classes.expend}
                                                            />
                                                        ) : (
                                                            <ExpandMore
                                                                className={classes.expend}
                                                            />
                                                        )}
                                                    </ListItemButton>
                                                    <Collapse
                                                        in={open}
                                                        timeout="auto"
                                                        unmountOnExit>
                                                        <List component="div" disablePadding>
                                                            {identificationDetails()}
                                                        </List>
                                                    </Collapse>
                                                </List>
                                            </div>
                                        )}
                                    </Box>
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </div>
            );
        }
        return (
            <div className={classes.screen}>
                <CircularProgress sx={{ margin: 'auto', width: '100%' }} />
            </div>
        );
    };

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
            onClick={(): void => {
                if (stopTimer) {
                    stopTimer();
                }
            }}
            onTouchStart={(): void => {
                if (stopTimer) {
                    stopTimer();
                }
            }}>
            {screen()}
        </div>
    );
}
