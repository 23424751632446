import { Box, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactApexChart from 'react-apexcharts';
import { getPatientReview, getReview } from '../../services/cloudFirestore';
import { User } from '../../models/User';

const chartOptions: ApexCharts.ApexOptions = {
    colors: ['#2A7000', '#ACBCC7', '#DCA926'],
    chart: {
        toolbar: { show: false },
        zoom: { enabled: false },
        animations: { enabled: false }
    },
    markers: { size: 5 },
    legend: {
        horizontalAlign: 'center'
    },
    labels: ['Ont aimé', 'Sans avis', 'N’ont pas aimé'],
    tooltip: {
        shared: true,
        intersect: false,
        y: {
            formatter: (y: number) => {
                if (typeof y !== 'undefined') {
                    return `${y}`;
                }
                return y;
            }
        }
    }
};

interface ExerciseAssessmentProps {
    heading?: string;
    user?: User;
}

export function ExerciseAssessment({ heading, user }: ExerciseAssessmentProps): React.ReactElement {
    const { t } = useTranslation();
    const [disliked, setDisliked] = useState<number>(0);
    const [satisfactory, setSatisfactory] = useState<number>(0);
    const [loved, setLoved] = useState<number>(0);

    useEffect(() => {
        const getUserList = async (): Promise<void> => {
            const data = user && user.id ? await getPatientReview(user.id) : await getReview();
            setLoved(data[0]);
            setSatisfactory(data[1]);
            setDisliked(data[2]);
        };
        getUserList();
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                textAlign: 'left',
                paddingTop: '30px',
                '& > :not(style)': {
                    m: 1,
                    height: 280,
                    width: 400
                }
            }}>
            <Paper elevation={1} sx={{ padding: '13px', width: '100%' }}>
                {heading ? <Typography variant="h5">{t(heading)}</Typography> : null}
                <Box sx={{ my: 2 }}>
                    <ReactApexChart
                        type="pie"
                        series={[loved, satisfactory, disliked]}
                        options={chartOptions}
                        width="100%"
                        height={260}
                    />
                </Box>
            </Paper>
        </Box>
    );
}
