import React from 'react';
import { Icon } from '@iconify/react';
import GridViewIcon from '@mui/icons-material/GridView';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import ApartmentIcon from '@mui/icons-material/Apartment';
import HikingIcon from '@mui/icons-material/Hiking';
import EditIcon from '@mui/icons-material/Edit';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import { TFunction } from 'react-i18next';

// ----------------------------------------------------------------------

export const sidebarConfig = (
    t: TFunction<'translation'>
): { title: string; path: string; icon: JSX.Element }[] => [
    {
        title: t('dashboard'),
        path: '/home',
        icon: <GridViewIcon />
    },
    {
        title: t('addMedia'),
        path: '/mediatheque',
        icon: <PermMediaIcon />
    },
    {
        title: t('createExercise'),
        path: '/exercise',
        icon: <CreateNewFolderIcon />
    },
    {
        title: t('editExercise'),
        path: '/edit',
        icon: <EditIcon />
    },
    {
        title: t('patients'),
        path: '/patient',
        icon: <HikingIcon />
    },
    {
        title: t('familles'),
        path: '/family',
        icon: <FamilyRestroomIcon />
    },
    {
        title: t('therapists'),
        path: '/therapist',
        icon: <LocalHospitalIcon />
    },
    {
        title: t('establishments'),
        path: '/establishment',
        icon: <ApartmentIcon />
    }
];
