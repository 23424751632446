import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { makeStyles, createStyles } from '@mui/styles';
import { blueGrey } from '@mui/material/colors';
import { Container, Box } from '@mui/material';
import { useLongPress } from 'use-long-press';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-cycle
import { UserContext } from '../../providers/UserProvider';
import '../../i18n/config';
import { getPatientModalDetails } from '../../services/viewFunctions';
import { ModalContainer } from '../../components/ModalContainer';
import { ExerciseType } from '../../models/ExerciseType';
import { PatientModalViewData } from '../../models/PatientModalViewData';
import { Exercise } from '../../models/Exercise';
// eslint-disable-next-line import/no-cycle
import {
    getFamilyUser,
    getPatientUser,
    getTherapistUser,
    increaseExerciseStarted
} from '../../services/cloudFirestore';
import { Therapist } from '../../models/Therapist';
import { ProfileImage } from '../../components/ProfileImage';
import { Roles } from '../../models/Roles';
import { UserDataProp } from '../../models/userDataProp';
import { Family } from '../../models/Family';
import { Patient } from '../../models/Patient';

const useStyles = makeStyles(() =>
    createStyles({
        profileImage: {
            height: '200px',
            width: '200px',
            margin: 'auto',
            marginTop: '0px',
            marginBottom: '0px'
        },
        startExerciseBtn: {
            width: '50%',
            margin: '0px auto',
            '@media (max-width: 598px)': {
                cursor: 'pointer',
                width: '100%',
                margin: '16px 5px 30px 5px'
            }
        },
        anotherExerciseBtnLayout: {
            width: '400px',
            marginTop: '5%',
            marginLeft: '0px',
            '@media (max-width: 598px)': {
                cursor: 'pointer',
                width: '100%',
                margin: '16px 10px'
            }
        },
        currentUserInitials: {
            display: 'flex',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            margin: '0'
        }
    })
);

const DefaultLogo = styled.div`
    border-radius: 50%;
    background: #efe7d7;
    position: relative;
`;

interface TryExerciseAlertsProps {
    isModalOpen?: boolean;
    closeModal: Function;
    exerciseSelected: Exercise;
    random?: number | null;
}

export function TryExerciseAlerts({
    closeModal,
    exerciseSelected,
    isModalOpen,
    random
}: TryExerciseAlertsProps): JSX.Element {
    const { t } = useTranslation();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const history = useHistory();
    const classes = useStyles();
    const [initials, setInitials] = useState<string>('');
    const [openIntroductionModal, setOpenIntroduction] = useState(false);
    const [patientData, setPatientData] = useState<Patient | null>();
    const [encouragementMessage, setEncouragementMessage] = useState<string>('');
    const [author, setAuthor] = useState<Therapist>({
        firstName: undefined,
        lastName: undefined,
        emailId: undefined,
        dob: undefined,
        fonction: undefined,
        establishmentName: undefined,
        mobile: undefined,
        firstStepFormFields: [],
        secondStepFormFields: []
    });
    const [familyAuthor, setFamilyAuthor] = useState<Family>({
        firstName: undefined,
        lastName: undefined,
        emailId: undefined,
        dob: undefined,
        relationship: undefined,
        establishmentName: undefined,
        firstStepFormFields: [],
        secondStepFormFields: []
    });
    const [modalDetails, setModalDetails] = useState<PatientModalViewData>({
        authorName: '',
        exerciseDetails: [],
        videoToAssist: '',
        videoLink: '',
        photo: '',
        authorPhoto: ''
    });

    const bindLongPress = useLongPress(
        (event) => {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            onExerciseSelect(exerciseSelected);
        },
        {
            threshold: 1000
        }
    );

    useEffect(() => {
        const getExerciseList = async (): Promise<void> => {
            if (
                exerciseSelected.authorId &&
                (exerciseSelected.authorType === Roles.THERAPIST ||
                    exerciseSelected.authorType === Roles.ADMIN)
            ) {
                if (user && user?.id && user.role === Roles.PATIENT) {
                    const data = await getPatientUser(user.id);
                    if (data) {
                        setPatientData(data);
                    }
                    const authorData = await getTherapistUser(exerciseSelected.authorId);
                    if (authorData) {
                        if (exerciseSelected && exerciseSelected.encouragementMessage) {
                            setEncouragementMessage(exerciseSelected.encouragementMessage);
                        }
                        setAuthor(authorData);
                        if (authorData.firstName && authorData.lastName) {
                            setInitials(
                                authorData.firstName.charAt(0).toUpperCase() +
                                    authorData.lastName.charAt(0).toUpperCase()
                            );
                        }
                    }
                } else if (user && user?.id && user.role === Roles.THERAPIST) {
                    const authorData = await getTherapistUser(exerciseSelected.authorId);
                    if (authorData) {
                        if (exerciseSelected && exerciseSelected.encouragementMessage) {
                            setEncouragementMessage(exerciseSelected.encouragementMessage);
                        }
                        setAuthor(authorData);
                        if (authorData.firstName && authorData.lastName) {
                            setInitials(
                                authorData.firstName.charAt(0).toUpperCase() +
                                    authorData.lastName.charAt(0).toUpperCase()
                            );
                        }
                    }
                }
            } else if (exerciseSelected.authorId && exerciseSelected.authorType === Roles.FAMILY) {
                const authorData = await getFamilyUser(exerciseSelected.authorId);
                if (authorData) {
                    if (exerciseSelected && exerciseSelected.encouragementMessage) {
                        setEncouragementMessage(exerciseSelected.encouragementMessage);
                    }
                    setFamilyAuthor(authorData);
                    if (authorData.firstName && authorData.lastName) {
                        setInitials(
                            authorData.firstName.charAt(0).toUpperCase() +
                                authorData.lastName.charAt(0).toUpperCase()
                        );
                    }
                }
            }
        };
        getExerciseList();
    }, [exerciseSelected]);

    useEffect(() => {
        if (openIntroductionModal === false) {
            closeModal();
        }
    });

    useEffect(() => {
        const details = getPatientModalDetails();
        if (details) {
            setModalDetails(details);
        }
    }, []);

    useEffect(() => {
        if (isModalOpen === true) {
            setOpenIntroduction(true);
        }
    }, [isModalOpen]);

    const handleCloseIntroduction = (): void => setOpenIntroduction(false);

    const onExerciseSelect = async (exercise: Exercise): Promise<void> => {
        if (user && user.role === Roles.PATIENT) {
            await increaseExerciseStarted(user, exercise);
        }
        switch (exercise.exerciseType) {
            case ExerciseType.MEMORY:
            case ExerciseType.MEMORIN:
                history.push({
                    pathname: `/${exercise.exerciseType.toLowerCase()}/${exercise.id}`,
                    state: { exercises: exercise, random }
                });
                break;
            case ExerciseType.PUZZLE:
                if (exercise.id) {
                    localStorage.setItem('reload', 'true');
                }
                history.push({ pathname: `/puzzl'in`, state: { exercises: exercise } });
                break;
            case ExerciseType.ORDER:
                history.push({ pathname: `/ordon'in`, state: { exercises: exercise } });
                break;
            default:
                history.push(`/404`);
        }
    };

    const getWidth = (): number => {
        const { innerWidth: width, innerHeight: height } = window;
        if (width < 550 || height < 550) {
            return 145;
        }
        return 200;
    };

    const exerciseIntroduction = (
        <Box
            sx={{
                minHeight: '75vh',
                maxHeight: '90vh',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly'
            }}>
            {exerciseSelected.authorType === Roles.FAMILY && !familyAuthor.profilePhoto && (
                <DefaultLogo className={classes.profileImage}>
                    <div style={{ height: '5vmin' }}>
                        <Typography
                            variant="h1"
                            sx={{ fontWeight: 'bold' }}
                            className={classes.currentUserInitials}>
                            {initials}
                        </Typography>
                    </div>
                </DefaultLogo>
            )}
            {exerciseSelected.authorType === Roles.THERAPIST && !author.profilePhoto && (
                <DefaultLogo className={classes.profileImage}>
                    <div style={{ height: '5vmin' }}>
                        <Typography
                            variant="h1"
                            sx={{ fontWeight: 'bold' }}
                            className={classes.currentUserInitials}>
                            {initials}
                        </Typography>
                    </div>
                </DefaultLogo>
            )}
            {exerciseSelected.authorType === Roles.FAMILY && familyAuthor.profilePhoto && (
                <ProfileImage
                    width={getWidth()}
                    height={getWidth()}
                    name={`${familyAuthor.firstName} ${familyAuthor.lastName}`}
                    url={familyAuthor.profilePhoto}
                />
            )}
            {exerciseSelected.authorType === Roles.THERAPIST && author.profilePhoto && (
                <ProfileImage
                    width={getWidth()}
                    height={getWidth()}
                    name={`${author.firstName} ${author.lastName}`}
                    url={author.profilePhoto}
                />
            )}
            <Typography
                variant="h3"
                sx={{
                    fontWeight: 500,
                    fontFamily: user?.role === Roles.PATIENT ? 'Luciole-Regular' : '',
                    color: '#004680',
                    textAlign: 'center'
                }}>
                {encouragementMessage}
            </Typography>
            <Button
                variant="outlined"
                size="large"
                color="primary"
                className={classes.anotherExerciseBtnLayout}
                style={{
                    marginTop: '0%',
                    border: '1px solid #8b004b',
                    boxShadow: '0px 0px 0px 2px rgba(208, 0, 112, 0.4)',
                    fontSize: '24px',
                    lineHeight: '29px',
                    fontWeight: 600,
                    fontFamily: user?.role === Roles.PATIENT ? 'Luciole-Regular' : '',
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...bindLongPress()}
                onClick={(): void => {
                    onExerciseSelect(exerciseSelected);
                }}>
                {t('startExercise')}
            </Button>
        </Box>
    );

    // useEffect(() => {
    //     if (exerciseSelected.authorType === 'ADMIN') {
    //         onExerciseSelect(exerciseSelected);
    //     }
    // }, [exerciseSelected]);

    // if (exerciseSelected.authorType !== 'ADMIN') {
    return (
        <Container>
            <ModalContainer
                handleClose={handleCloseIntroduction}
                openModal={openIntroductionModal}
                renderBody={exerciseIntroduction}
                hasCloseButton
            />
        </Container>
    );
    // }
}
