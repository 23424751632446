import { Box, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import styled from 'styled-components';
import { Roles } from '../../models/Roles';
import arrowHand from '../../assets/arrowHand.png';
// eslint-disable-next-line import/no-cycle
import { PatientSelector } from '../../components/PatientSelector';
import { CustomCheckbox } from '../../components/CustomCheckbox';
// eslint-disable-next-line import/no-cycle
import { ExerciseMessage } from './ExerciseMessage';
import { SearchBox } from '../../components/SearchBox';
// eslint-disable-next-line import/no-cycle
import { UserContext } from '../../providers/UserProvider';
// eslint-disable-next-line import/no-cycle
import {
    getEstablishmentList,
    getPatientUser,
    getEstablishment,
    getPatientFromEstablishment
} from '../../services/cloudFirestore';
import { Patient } from '../../models/Patient';
import { Exercise } from '../../models/Exercise';
import { ExitCustomizationModal } from '../../components/ExitCustomizationModal';
import { Establishment } from '../../models/Establishment';
import { UserDataProp } from '../../models/userDataProp';
import { ButtonsCreateAndEdit } from '../../components/CreateAndEdit/ButtonsCreateAndEdit';
import { TitleAndIntro } from '../../components/CreateAndEdit/TitleAndIntro';
import { Header } from '../../components/CreateAndEdit/Header';

const useStyles = makeStyles(() => ({
    secondStepContainer: {
        '@media (max-width: 598px)': {
            width: '95%',
            margin: 'auto'
        },
        '@media (min-width: 598px)': {
            width: '75%',
            margin: 'auto'
        }
    }
}));

const CustomBox = styled(Box)(() => ({
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0% 5%',
    '@media (max-width: 598px)': {
        padding: '5%'
    }
}));

const TherapistScreenContainer = styled.div`
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
`;

export interface StateInterface {
    patientsArray: Patient[];
    patientIdArray: (string | undefined)[];
}

interface StateInterfaceEstablishment {
    establishmentsArray: Establishment[];
    establishmentIdArray: (string | undefined)[];
}

interface SecondStepperProps {
    content: Exercise;
    currentStep: React.Dispatch<React.SetStateAction<number>>;
}

export function AssignExercise({ content, currentStep }: SecondStepperProps): JSX.Element {
    const { t } = useTranslation();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [openModal, setOpenModal] = useState<boolean>(false);
    const classes = useStyles();
    const [activeStep, setStep] = useState<number>(2);
    const [data, setData] = useState<Exercise>({
        id: content.id,
        exerciseType: content.exerciseType,
        patientList: content.patientList,
        exerciseName: content.exerciseName,
        authorId: content.authorId,
        exerciseImages: content.exerciseImages,
        review: content.review,
        encouragementMessage: content.encouragementMessage,
        congratulationsMessage: content.congratulationsMessage,
        rewardPhoto: content.rewardPhoto,
        rewardVideo: content.rewardVideo,
        establishmentCode: content.establishmentCode,
        establishmentList: content.establishmentList,
        familyCode: content.familyCode,
        theme: content.theme
    });
    const [searchText, setSearchText] = useState<string>('');
    const [allPatientsIdListArray, setAllPatientsIdList] = useState<string[]>([]);
    const [allPatientsListArray, setAllPatientsList] = useState<Patient[]>();
    const [allEstablishmentsIdListArray, setAllEstablishmentsIdList] = useState<string[]>([]);
    const [allEstablishmentsListArray, setAllEstablishmentsList] = useState<Establishment[]>();
    const [patientsIdListArray, setPatientsIdList] = useState<string[]>([]);
    const [patientsListArray, setPatientsList] = useState<Patient[]>();
    const [establishmentsIdListArray, setEstablishmentsIdList] = useState<string[]>([]);
    const [establishmentsListArray, setEstablishmentsList] = useState<Establishment[]>();
    const [disabled, setDisabled] = useState<boolean>(true);
    const [selectedPatients, setSelectCard] = useState<StateInterface>({
        patientsArray: [],
        patientIdArray: []
    });
    const [selectedEstablishments, setSelectEstablishment] = useState<StateInterfaceEstablishment>({
        establishmentsArray: [],
        establishmentIdArray: []
    });

    const [isKeybord, setIsKeybord] = useState<boolean>(false);
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    );

    useEffect(() => {
        const getPatientOrEstablishmentList = async (): Promise<void> => {
            let patients: Patient[] | null = [];
            if (user?.role === Roles.THERAPIST) {
                if (user && user.id && user.establishmentCode) {
                    patients = await getPatientFromEstablishment(user.establishmentCode);
                    if (patients) {
                        setPatientsList(patients);
                        setAllPatientsList(patients);
                    }
                }
                const list: string[] = [];
                if (patients) {
                    patients.forEach((patient) => {
                        if (patient.id) {
                            list.push(patient.id);
                        }
                    });
                }
                setPatientsIdList(list);
                setAllPatientsIdList(list);
            }
            if (user?.role === Roles.ADMIN) {
                const establishments = await getEstablishmentList();
                setEstablishmentsList(establishments);
                setAllEstablishmentsList(establishments);
                const estList: string[] = [];
                establishments.forEach((establishment) => {
                    if (establishment.id) {
                        estList.push(establishment.id);
                    }
                });
                setEstablishmentsIdList(estList);
                setAllEstablishmentsIdList(estList);
            }
        };
        getPatientOrEstablishmentList();
    }, []);

    useEffect(() => {
        if (
            selectedPatients.patientsArray.length >= 1 ||
            selectedEstablishments.establishmentsArray.length >= 1
        ) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [selectedPatients.patientsArray, selectedEstablishments.establishmentsArray]);

    useEffect(() => {
        setData({ ...data, patientList: selectedPatients.patientIdArray });
    }, [selectedPatients]);

    useEffect(() => {
        setData({ ...data, establishmentList: selectedEstablishments.establishmentIdArray });
    }, [selectedEstablishments]);

    useEffect(() => {
        if (searchText && allPatientsListArray) {
            const filteredData = allPatientsListArray.filter((element) => {
                if (element.firstName) {
                    return element.firstName.toLowerCase().includes(searchText.toLowerCase());
                }
                return null;
            });
            setPatientsList(filteredData);
            const list: string[] = [];
            filteredData.forEach((patient) => {
                if (patient.id) {
                    list.push(patient.id);
                }
            });
            setPatientsIdList(list);
        } else {
            setPatientsList(allPatientsListArray);
            setPatientsIdList(allPatientsIdListArray);
        }
        if (searchText && allEstablishmentsListArray) {
            const filteredEstablishmentData = allEstablishmentsListArray.filter((element) => {
                if (element.establishmentName) {
                    return element.establishmentName
                        .toLowerCase()
                        .includes(searchText.toLowerCase());
                }
                return null;
            });
            setEstablishmentsList(filteredEstablishmentData);
            const list: string[] = [];
            filteredEstablishmentData.forEach((establishment) => {
                if (establishment.id) {
                    list.push(establishment.id);
                }
            });
            setEstablishmentsIdList(list);
        } else {
            setEstablishmentsList(allEstablishmentsListArray);
            setEstablishmentsIdList(allEstablishmentsIdListArray);
        }
    }, [searchText]);

    useEffect(() => {
        const getPatientList = async (): Promise<void> => {
            if (content && content.patientList) {
                const list: Patient[] = [];
                await Promise.all(
                    content.patientList.map(async (element) => {
                        if (element) {
                            const item = await getPatientUser(element);
                            if (item && item.id) {
                                list.push(item);
                            }
                        }
                    })
                );
                setSelectCard({
                    patientsArray: list,
                    patientIdArray: content.patientList ?? []
                });
            }
        };
        const getEstList = async (): Promise<void> => {
            if (content && content.establishmentList) {
                const list: Establishment[] = [];
                await Promise.all(
                    content.establishmentList.map(async (element) => {
                        if (element) {
                            const item = await getEstablishment(element);
                            if (item && item.id) {
                                list.push(item);
                            }
                        }
                    })
                );
                setSelectEstablishment({
                    establishmentsArray: list,
                    establishmentIdArray: content.establishmentList ?? []
                });
            }
        };
        if (user?.role === Roles.THERAPIST) {
            getPatientList();
        }
        if (user?.role === Roles.ADMIN) {
            getEstList();
        }
    }, []);

    const selectPatient = (value: Patient): void => {
        if (selectedPatients.patientsArray.indexOf(value) === -1 && value.id) {
            setSelectCard({
                patientsArray: [...selectedPatients.patientsArray, value],
                patientIdArray: [...selectedPatients.patientIdArray, value.id]
            });
        } else {
            setSelectCard({
                patientsArray: selectedPatients.patientsArray.filter((e) => e !== value),
                patientIdArray: selectedPatients.patientIdArray.filter((e) => e !== value.id)
            });
        }
    };

    const selectEstablishment = (value: Establishment): void => {
        if (selectedEstablishments.establishmentsArray.indexOf(value) === -1 && value.id) {
            setSelectEstablishment({
                establishmentsArray: [...selectedEstablishments.establishmentsArray, value],
                establishmentIdArray: [...selectedEstablishments.establishmentIdArray, value.id]
            });
        } else {
            setSelectEstablishment({
                establishmentsArray: selectedEstablishments.establishmentsArray.filter(
                    (e) => e !== value
                ),
                establishmentIdArray: selectedEstablishments.establishmentIdArray.filter(
                    (e) => e !== value.id
                )
            });
        }
    };

    const selectAllPatients = (): void => {
        if (patientsListArray) {
            if (selectedPatients.patientsArray.length !== patientsListArray?.length) {
                setSelectCard({
                    patientsArray: patientsListArray,
                    patientIdArray: patientsIdListArray
                });
            } else {
                setSelectCard({
                    patientsArray: [],
                    patientIdArray: []
                });
            }
        }
    };

    const selectAllEstablishments = (): void => {
        if (establishmentsListArray) {
            if (
                selectedEstablishments.establishmentsArray.length !==
                establishmentsListArray?.length
            ) {
                setSelectEstablishment({
                    establishmentsArray: establishmentsListArray,
                    establishmentIdArray: establishmentsIdListArray
                });
            } else {
                setSelectEstablishment({
                    establishmentsArray: [],
                    establishmentIdArray: []
                });
            }
        }
    };

    const getPatients = (): JSX.Element[] | null => {
        if (patientsListArray && user?.role === Roles.THERAPIST) {
            if (patientsListArray.length > 0) {
                return patientsListArray.map((item) => {
                    return (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                                padding: '1%'
                            }}
                            key={item.id}>
                            <PatientSelector
                                patientsArray={item}
                                isChecked={selectedPatients.patientIdArray.indexOf(item.id) > -1}
                                selectPatient={selectPatient}
                            />
                        </Grid>
                    );
                });
            }
        }
        if (establishmentsListArray && user?.role === Roles.ADMIN) {
            if (establishmentsListArray.length > 0) {
                return establishmentsListArray.map((item) => {
                    return (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                                padding: '1%'
                            }}
                            key={item.id}>
                            <PatientSelector
                                patientsArray={item}
                                isChecked={
                                    selectedEstablishments.establishmentIdArray.indexOf(item.id) >
                                    -1
                                }
                                selectPatient={selectEstablishment}
                            />
                        </Grid>
                    );
                });
            }
        }
        return null;
    };

    const allItemsSelector = (): JSX.Element | null => {
        if (patientsListArray && user?.role === Roles.THERAPIST) {
            return (
                <Grid item xs={12} sm={6} sx={{ padding: '1%' }} key={2}>
                    <CustomBox>
                        <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                            <Typography variant="h6" color="#004680" sx={{ fontWeight: 500 }}>
                                {t('sendToAllPatients')}
                            </Typography>
                        </Box>
                        <Box sx={{ width: '20%' }}>
                            <CustomCheckbox
                                isChecked={
                                    selectedPatients.patientsArray.length ===
                                    patientsListArray.length
                                }
                                selectItem={selectAllPatients}
                                item={undefined}
                            />
                        </Box>
                    </CustomBox>
                </Grid>
            );
        }
        if (establishmentsListArray && user?.role === Roles.ADMIN) {
            return (
                <Grid item xs={12} sm={6} sx={{ padding: '1%' }} key={2}>
                    <CustomBox>
                        <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                            <Typography variant="h6" color="#004680" sx={{ fontWeight: 500 }}>
                                {t('sendToAllEstablishments')}
                            </Typography>
                        </Box>
                        <Box sx={{ width: '20%' }}>
                            <CustomCheckbox
                                isChecked={
                                    selectedEstablishments.establishmentsArray.length ===
                                    establishmentsListArray.length
                                }
                                selectItem={selectAllEstablishments}
                                item={undefined}
                            />
                        </Box>
                    </CustomBox>
                </Grid>
            );
        }
        return null;
    };

    const screen = (
        <TherapistScreenContainer>
            <div>
                <Box sx={{ boxShadow: 'inset 0px -1px 0px #E3E6E8' }}>
                    <Header
                        setOpenModal={setOpenModal}
                        type={data.exerciseType}
                        activeStep={activeStep}
                        user={user}
                    />
                </Box>
                <Box
                    sx={{
                        backgroundColor: '#F8F9F9',
                        boxShadow: 'inset 0px -1px 0px rgba(172, 188, 199, 0.4)',
                        padding: '10px'
                    }}>
                    <TitleAndIntro
                        type={data.exerciseType}
                        activeStep={activeStep}
                        user={user}
                        icon={arrowHand}
                        body={
                            user?.role === Roles.ADMIN
                                ? t('establishmentToAssignExercise')
                                : t('patientToAssignExercise')
                        }
                        selectedPatients={
                            user?.role === Roles.ADMIN
                                ? selectedEstablishments.establishmentsArray
                                : selectedPatients.patientsArray
                        }
                    />
                </Box>
                <Grid
                    container
                    sx={{ width: '100%', margin: 'auto' }}
                    className={activeStep === 1 ? classes.secondStepContainer : ''}>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                            padding: '1%'
                        }}
                        key={1}>
                        <SearchBox
                            placeholder={t('toSearch')}
                            onSearchChange={(value: string): void => setSearchText(value)}
                            hasSearchIcon
                            onBlur={(): void => setIsKeybord(false)}
                            onFocus={(): void => setIsKeybord(true)}
                        />
                    </Grid>
                    {allItemsSelector()}
                    {getPatients()}
                </Grid>
            </div>
            <div style={{ height: window.innerWidth < 600 ? '190px' : '81px', width: '100%' }} />
            <Box
                style={{
                    position: isMobile && isKeybord ? 'inherit' : 'fixed',
                    zIndex: '2',
                    bottom: '0',
                    width: '100%',
                    backgroundColor: '#FFFFFF'
                }}>
                <ButtonsCreateAndEdit
                    disabled={disabled}
                    nextText={t('validateChoiceOfPatients')}
                    nextClick={(): void => setStep(activeStep + 1)}
                    previousClick={(): void => {
                        setStep(activeStep - 1);
                        currentStep(activeStep - 1);
                    }}
                />
            </Box>
        </TherapistScreenContainer>
    );

    return (
        <div>
            {(user?.role === Roles.ADMIN || user?.role === Roles.THERAPIST) &&
                activeStep === 2 &&
                screen}
            {(user?.role === Roles.ADMIN || user?.role === Roles.THERAPIST) && activeStep === 3 && (
                <ExerciseMessage content={data} currentStep={setStep} />
            )}
            <ExitCustomizationModal openModal={openModal} setOpenModal={setOpenModal} />
        </div>
    );
}
