import { Box, Card, CardActionArea, Typography } from '@mui/material';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import mediaLittleIcon from '../../assets/mediaLittleIcon.png';
import personalVideoIcon from '../../assets/personalVideoIcon.png';
import youtubeLittleIcon from '../../assets/youtubeLittleIcon.png';
import personalVideoPicture from '../../assets/personalVideoPicture.png';
import youtubePicture2 from '../../assets/personalVideoPicture2.png';
import youtubePicture from '../../assets/youtubePicture.png';
import personalVideoPicture2 from '../../assets/youtubePicture2.png';
import backButton from '../../assets/backButton.png';
import pictureMedia from '../../assets/pictureMedia.png';
import pictureMedia2 from '../../assets/pictureMedia2.png';
import mediathequeLogo from '../../assets/mediathequeLogo.svg';
import { Navbar } from '../../components/Headers/Navbar';
import { UserContext } from '../../providers/UserProvider';
import { UserDataProp } from '../../models/userDataProp';
import { Roles } from '../../models/Roles';
import { DashboardLayout } from '../../layouts/DashboardLayout';

const useStyles = makeStyles(() => ({
    root: {
        margin: 'auto',
        justifyContent: 'center',
        display: 'flex',
        flexWrap: 'wrap'
    },
    headerTitle: {
        fontWeight: 700,
        margin: '40px',
        '@media (max-width: 541px)': {
            padding: '20px',
            margin: '0px'
        }
    },
    myExercises: {
        margin: '2%'
    }
}));

const ImageContainer = styled('img')({
    borderRadius: '9px',
    height: '182px',
    width: '300px',
    objectFit: 'contain'
});

const ImageBox = styled(Box)({
    borderRadius: '9px',
    position: 'relative',
    height: '182px',
    width: '326px',
    margin: 'auto',
    marginTop: '40px',
    marginBottom: '40px',
    '@media (max-width: 598px)': {
        margin: 'auto',
        marginTop: '25px',
        marginBottom: '25px'
    }
});

const Logo = styled.img`
    height: 32px;
    width: 32px;
    margin-right: 10px;
    object-fit: contain;
`;

export function Mediatheque(): JSX.Element {
    const { t } = useTranslation();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const history = useHistory();
    const classes = useStyles();

    const screen = (): JSX.Element => {
        return (
            <div>
                {user && user.role !== Roles.ADMIN && (
                    <Navbar
                        leftIcon={backButton}
                        centerIcon={mediathequeLogo}
                        leftIconLink={(): void => {
                            history.push('/home');
                        }}
                    />
                )}
                <Typography variant="h4" color="#374653" className={classes.headerTitle}>
                    {t('wichMediaToImport')}
                </Typography>
                <Box className={classes.root}>
                    <Card
                        className={classes.myExercises}
                        onClick={(): void => {
                            history.push(`mediatheque/thumbnails`);
                        }}>
                        <CardActionArea>
                            <div
                                style={{
                                    justifyContent: 'center',
                                    marginTop: '15px',
                                    marginBottom: '5px',
                                    display: 'flex'
                                }}>
                                <Logo src={mediaLittleIcon} alt="logo" />
                                <Typography
                                    variant="h4"
                                    color="secondary"
                                    sx={{
                                        fontWeight: '600'
                                    }}>
                                    {t('picture')}
                                </Typography>
                            </div>
                            <ImageBox>
                                <ImageContainer src={pictureMedia2} alt="media picture" />
                            </ImageBox>
                        </CardActionArea>
                    </Card>
                    <Card
                        className={classes.myExercises}
                        onClick={(): void => {
                            history.push(`mediatheque/video`);
                        }}>
                        <CardActionArea>
                            <div
                                style={{
                                    justifyContent: 'center',
                                    marginTop: '15px',
                                    marginBottom: '5px',
                                    display: 'flex'
                                }}>
                                <Logo src={personalVideoIcon} alt="logo" />
                                <Typography
                                    variant="h4"
                                    color="secondary"
                                    sx={{
                                        fontWeight: '600'
                                    }}>
                                    {t('videoTitle')}
                                </Typography>
                            </div>
                            <ImageBox>
                                <ImageContainer src={personalVideoPicture2} alt="puzzleThumbnail" />
                            </ImageBox>
                        </CardActionArea>
                    </Card>
                    <Card
                        className={classes.myExercises}
                        onClick={(): void => {
                            history.push(`mediatheque/youtubeLink`);
                        }}>
                        <CardActionArea>
                            <div
                                style={{
                                    justifyContent: 'center',
                                    marginTop: '15px',
                                    marginBottom: '5px',
                                    display: 'flex'
                                }}>
                                <Logo src={youtubeLittleIcon} alt="logo" />
                                <Typography
                                    variant="h4"
                                    color="secondary"
                                    sx={{
                                        fontWeight: '600'
                                    }}>
                                    {t('youtubeTitle')}
                                </Typography>
                            </div>
                            <ImageBox>
                                <ImageContainer src={youtubePicture2} alt="puzzleThumbnail" />
                            </ImageBox>
                        </CardActionArea>
                    </Card>
                </Box>
            </div>
        );
    };

    return (
        <div>
            {user && user.role === Roles.ADMIN ? (
                <DashboardLayout>{screen()}</DashboardLayout>
            ) : (
                <div>{screen()}</div>
            )}
        </div>
    );
}
