import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    sendPasswordResetEmail,
    updatePassword,
    UserCredential
} from 'firebase/auth';
import { Timestamp } from 'firebase/firestore';
import { fireAuth } from './firebase';
// eslint-disable-next-line import/no-cycle
import { addFamily, passwordChanged } from './cloudFirestore';
import { User } from '../models/User';
import { clickAnalytics } from './analyticsFunction';

export const signIn = async (email: string, password: string): Promise<UserCredential> => {
    return signInWithEmailAndPassword(fireAuth, email, password).then((res) => {
        return res;
    });
};

export const forgotPassword = async (email: string): Promise<void> => {
    return sendPasswordResetEmail(fireAuth, email);
};

export const signUp = async (
    email: string,
    password: string,
    code: string,
    endDate: Timestamp,
    startDate: Timestamp,
    establishmentCode: string,
    establishmentName: string,
    patientId: string
): Promise<UserCredential | null | string | void> => {
    return createUserWithEmailAndPassword(fireAuth, email, password).then(async (res) => {
        return addFamily({
            email,
            familyCode: code,
            userId: res.user.uid,
            legalReferent: false,
            isChangePasswordRequired: false,
            endDate,
            startDate,
            establishmentCode,
            establishmentName,
            patient: patientId,
            cguValidate: true,
            cguValidateDate: Timestamp.now(),
            lovedPassword: ''
        });
    });
};

export const changePassword = async (
    newPassword: string,
    userId?: string
): Promise<boolean | string | null> => {
    const { currentUser } = fireAuth;
    if (currentUser && userId) {
        await updatePassword(currentUser, newPassword).then(() => passwordChanged(userId));
        return true;
    }
    if (currentUser && !userId) {
        await updatePassword(currentUser, newPassword);
        return true;
    }
    return null;
};

export const logOut = async (user: User | undefined | null): Promise<void> => {
    if (user && user.role) {
        clickAnalytics('logout', user);
    }
    return signOut(fireAuth);
};
