import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import { makeStyles, createStyles } from '@mui/styles';
import { AppBar, Avatar, IconButton, Toolbar, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { getImageKitUrlFrom } from '../../utilities/utils';
import { UserContext } from '../../providers/UserProvider';
import { UserDataProp } from '../../models/userDataProp';

const useStyles = makeStyles(() =>
    createStyles({
        profileImage: {
            width: '54px',
            height: '54px',
            alignSelf: 'center',
            justifySelf: 'center'
        },
        currentUserInitials: {
            display: 'flex',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            margin: '0'
        }
    })
);

const Logo = styled.img`
    height: 27px;
`;
const LogoBack = styled.img`
    height: 40px;
    background-color: #fbfbfb;
    border: 1px solid #e3e6e8;
    padding: 12px;
    border-radius: 5px;
    cursor: pointer;
`;

const StimulinAppBar = styled(AppBar)({
    background: '#fff'
});

const AppToolBar = styled(Toolbar)({
    justifyContent: 'space-between'
});

const DefaultLogo = styled.div`
    border-radius: 50%;
    background: #efe7d7;
    height: 27px;
`;

interface NavbarProps {
    leftIcon: string;
    centerIcon: string;
    leftIconLink?: () => void;
}

export function Navbar({ leftIcon, centerIcon, leftIconLink }: NavbarProps): JSX.Element {
    const classes = useStyles();
    const history = useHistory();
    const goBack = (): void => history.goBack();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [initials, setInitials] = useState<string>('');

    useEffect(() => {
        const topOfPage = document.getElementById('top');
        if (topOfPage) {
            topOfPage.scrollIntoView();
        }
    }, []);

    useEffect(() => {
        if (user && user.firstName && user.lastName) {
            setInitials(
                user.firstName.charAt(0).toUpperCase() + user.lastName.charAt(0).toUpperCase()
            );
        }
    }, [user]);

    return (
        <Box id="top">
            <StimulinAppBar position="static">
                <AppToolBar>
                    <Box onClick={leftIconLink ?? goBack}>
                        <IconButton size="large" edge="start" color="secondary" aria-label="menu">
                            <LogoBack src={leftIcon} alt="BackButton" />
                        </IconButton>
                    </Box>
                    <Logo src={centerIcon} alt="logo" />
                    <Box onClick={(): void => history.push('/profile')}>
                        <IconButton size="large" edge="start" color="secondary" aria-label="menu">
                            {user && !user.profilePhoto && (
                                <DefaultLogo className={classes.profileImage}>
                                    <div style={{ height: '5vmin' }}>
                                        <Typography
                                            variant="subtitle1"
                                            sx={{ fontWeight: 'bold', color: '#374653' }}
                                            className={classes.currentUserInitials}>
                                            {initials}
                                        </Typography>
                                    </div>
                                </DefaultLogo>
                            )}
                            {user && user.profilePhoto && typeof user.profilePhoto === 'string' && (
                                <Avatar
                                    alt="userIcon"
                                    src={getImageKitUrlFrom(user.profilePhoto)}
                                    sx={{
                                        width: '54px',
                                        height: '54px',
                                        alignSelf: 'center',
                                        justifySelf: 'center'
                                    }}
                                />
                            )}
                        </IconButton>
                    </Box>
                </AppToolBar>
            </StimulinAppBar>
        </Box>
    );
}
