import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface EstablishmentCountProps {
    count: number;
}

export function EstablishmentCount({ count }: EstablishmentCountProps): React.ReactElement {
    const { t } = useTranslation();
    return (
        <Box
            sx={{
                display: 'flex',
                flexGrow: 0,
                flexWrap: 'wrap',
                textAlign: 'left',

                '& > :not(style)': {
                    m: 1,
                    width: 177,
                    height: 235
                }
            }}>
            <Paper elevation={1} sx={{ paddingTop: '29px', paddingLeft: '11px' }}>
                <Typography variant="subtitle1">{t('adminEstablishmentCount')}</Typography>
                <Typography variant="h1" sx={{ paddingTop: '8px' }}>
                    {count}
                </Typography>
            </Paper>
        </Box>
    );
}
