import React, { MouseEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CsvDownloader from 'react-csv-downloader';
import { Box, Button, Typography } from '@mui/material';
import { DataGrid, GridColumns, GridRowData } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';
import { DashboardLayout } from './DashboardLayout';
import { Status } from '../models/Status';
import { UpdateStatusModal } from '../components/Admin/UpdateStatusModal';
import { User } from '../models/User';
import { Establishment } from '../models/Establishment';
import { updateEstablishmentStatus, updateUserStatus } from '../services/cloudFirestore';
import { EstablishmentsExportData } from '../models/EstablishmentsExportData';
import { exportCsvData } from '../utilities/utils';

interface TableLayoutProps {
    title: string;
    addButtonTitle: string;
    onAddButtonClicked?: MouseEventHandler<Element> | undefined;
    columns: GridColumns;
    rows: GridRowData[];
    exportData: EstablishmentsExportData[];
    nameOfFile: string;
}

const useStyles = makeStyles(() => ({
    exportButton: {
        color: '#D00070',
        height: '48px',
        width: '159px',
        borderRadius: '8px',
        backgroundColor: '#FFFFFF',
        fontSize: '14px',
        fontWeight: 700,
        border: '2px solid #D00070',
        cursor: 'pointer'
    }
}));

export function TableLayout({
    title,
    addButtonTitle,
    onAddButtonClicked,
    columns,
    rows,
    exportData,
    nameOfFile
}: TableLayoutProps): JSX.Element {
    const classes = useStyles();
    const { t } = useTranslation();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [state, setState] = useState<Status>(Status.ACTIVE);
    const [userData, setUserData] = useState<User>();
    const [establishmentData, setEstablishmentData] = useState<Establishment>();

    const saveDetails = async (): Promise<void> => {
        const newState = state === Status.ACTIVE ? Status.INACTIVE : Status.ACTIVE;
        if (userData && userData.id) {
            await updateUserStatus(userData.id, newState);
        }
        if (establishmentData && establishmentData.id) {
            await updateEstablishmentStatus(establishmentData.id, newState);
        }
        setOpenModal(false);
        window.location.reload();
    };

    return (
        <DashboardLayout>
            <UpdateStatusModal
                isModelOpen={openModal}
                setModelOpen={setOpenModal}
                currentStatus={state}
                onCancel={(): void => {
                    setOpenModal(false);
                }}
                onChange={(): void => {
                    saveDetails();
                }}
            />
            <Box display="flex" p={2} justifyContent="space-between">
                <Typography variant="h2">{title}</Typography>
                <Box display="flex" justifyContent="space-between">
                    <Button variant="primaryContained" color="primary" onClick={onAddButtonClicked}>
                        {addButtonTitle}
                    </Button>
                    <Box sx={{ flexGrow: 1 }} width="64px" />
                    <CsvDownloader
                        className={classes.exportButton}
                        datas={exportCsvData(exportData)}
                        filename={nameOfFile}
                        extension=".csv"
                        separator=";"
                        wrapColumnChar=""
                        text={t('exportButton')}
                    />
                </Box>
            </Box>
            <Typography variant="body2" color="#ACBCC7" sx={{ textAlign: 'left' }}>
                {t('editField')}
            </Typography>
            <div style={{ height: 720, width: '100%' }}>
                <div style={{ display: 'flex', height: '100%' }}>
                    <div style={{ flexGrow: 1 }}>
                        <DataGrid
                            autoHeight
                            columns={columns}
                            rows={rows}
                            onCellClick={(params, event): void => {
                                if (params.row.role) {
                                    setUserData(params.row);
                                } else {
                                    setEstablishmentData(params.row);
                                }
                                if (
                                    params.field !== 'button' &&
                                    params.value.toUpperCase() === Status.ACTIVE
                                ) {
                                    setState(Status.ACTIVE);
                                    setOpenModal(true);
                                }
                                if (
                                    params.field !== 'button' &&
                                    params.value.toUpperCase() === Status.INACTIVE
                                ) {
                                    setState(Status.INACTIVE);
                                    setOpenModal(true);
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
}
